import React from 'react';
import { AlignDropdown } from './ToolbarItems/AlignSelect/AlignDropdown.component';
import { MoveLayerDropdown } from './ToolbarItems/MoveLayerSelect/MoveLayerDropdown.component';
import { InsertSpaceDropdown } from './ToolbarItems/InsertSpaceSelect/InsertSpaceDropdown.component';
import { MoveToDropdown } from './ToolbarItems/MoveToSelect/MoveToDropdown.component';
import { PickOutDropdown } from './ToolbarItems/PickOutSelect/PickOutDropdown.component';
import { InsertionButton } from './ToolbarItems/InsertionButton/InsertionDropdown.component';
import { EditModeButton } from './ToolbarItems/EditModeButton/EditModeButton';
import { SaveButton } from './ToolbarItems/SaveButton/SaveButton';
import { CopyButton } from './ToolbarItems/CopyButton/CopyButton.component';
import { CutButton } from './ToolbarItems/CutButton/CutButton.component';
import { PrintButton } from './ToolbarItems/PrintButton/PrintButton.component';
import { DeleteButton } from './ToolbarItems/DeleteButton/DeleteButton.component';
import { PropertyButton } from './ToolbarItems/PropertyButton/PropertyButton.component';
import { DecompositionButton } from './ToolbarItems/DecompositionButton/DecompositionButton.component';
import { UpdateButton } from './ToolbarItems/UpdateButton/UpdateButton.component';
import { SearchButton } from './ToolbarItems/SearchButton/SearchButton.component';
import { SwitchGridLayoutDropdown } from './ToolbarItems/SwitchGridLayout/SwitchGridLayoutDropdown.component';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { AddImageButton } from './ToolbarItems/AddImageBtn/AddImageButton.component';
import { AddTextButton } from './ToolbarItems/AddTextBtn/AddTextButton.component';
import { FiltrationDropdown } from './ToolbarItems/FiltrationBtn/FiltrationDropdown.component';
import { ResizableToolbar } from '../../../UIKit/components/Toolbar/ResizableToolbar/ResizableToolbar.component';
import { FilterInputButton } from './ToolbarItems/FilterInput/FilterInputButton.component';
import { FilterOutputButton } from './ToolbarItems/FilterOutputBtn/FilterOutputButton.compnent';
import { ClearFormatButton } from './ToolbarItems/ClearFormatButton/ClearFormatButton.component';
import { FormatButton } from './ToolbarItems/FormatButton/FormatButton.component';
import { ShapeDropdown } from './ToolbarItems/ShapeSelect/ShapeDropdown.component';
import { UndoButton } from './ToolbarItems/UndoBtn/UndoButton.component';
import { RedoButton } from './ToolbarItems/RedoBtn/RedoButton.component';
import { ModelTypes } from '../../../../models/ModelTypes';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { useSelector } from 'react-redux';

export const GraphGeneralToolbar = () => {
    const modelTypeId: string | undefined = useSelector(TabsSelectors.getActiveTabModelTypeid);
    const isWhiteBoard: boolean = modelTypeId === ModelTypes.MIND_MAP;

    return (
        <ResizableToolbar withFormatBtn={true}>
            <ButtonGroup>
                <EditModeButton />
            </ButtonGroup>
            <ButtonGroup>
                <UndoButton />
                <RedoButton />
            </ButtonGroup>
            <ButtonGroup>
                <UpdateButton />
                <SearchButton />
            </ButtonGroup>
            <ButtonGroup>
                <SaveButton />
            </ButtonGroup>
            <ButtonGroup>
                <InsertionButton />
                <CopyButton />
                <PrintButton />
                <CutButton />
                <DeleteButton />
            </ButtonGroup>
            <ButtonGroup>
                <SwitchGridLayoutDropdown />
                <InsertSpaceDropdown />
            </ButtonGroup>
            <ButtonGroup>
                {!isWhiteBoard ? <AlignDropdown /> : null}
                <MoveLayerDropdown />
            </ButtonGroup>
            <ButtonGroup>
                <PropertyButton />
                <DecompositionButton />
            </ButtonGroup>
            <ButtonGroup>
                <PickOutDropdown />
                <MoveToDropdown />
            </ButtonGroup>
            {!isWhiteBoard ? (
                <ButtonGroup>
                    <FiltrationDropdown />
                    <FilterInputButton />
                    <FilterOutputButton />
                </ButtonGroup>
            ) : null}
            <ButtonGroup>
                <AddImageButton />
                <AddTextButton />
            </ButtonGroup>
            <ButtonGroup>
                <ShapeDropdown />
            </ButtonGroup>
            <ButtonGroup>
                <FormatButton />
                <ClearFormatButton />
            </ButtonGroup>
        </ResizableToolbar>
    );
};

import React, { ChangeEvent, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { FormInstance } from 'antd';
import theme from './KanbanModelTypeGeneralTab.scss';
import messages from '../KanbanModelType.messages';
import { ModelTypeGroup, InternationalString, KanbanBoardType } from '../../../../../../../serverapi/api';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useDispatch } from 'react-redux';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { InputId } from '../../../../../../InputId/InputId.component';
import {
    changeKanbanModelTypeDescription,
    changeKanbanModelTypeGroupId,
    changeKanbanModelTypeId,
    changeKanbanModelTypeName,
} from '../../../../../../../actions/workspaceTab/editKanbanModelTypeWorkspaceTab.actions';
import { Select } from '@/modules/UIKit/components/Select/Select.component';

type TModelTypeGeneralTabProps = {
    modelType: KanbanBoardType;
    createMode: boolean;
    generalForm: FormInstance<any> | undefined;
    modelTypeGroups: ModelTypeGroup[];
};

const KanbanModelTypeGeneralTab: FC<TModelTypeGeneralTabProps> = (props) => {
    const dispatch = useDispatch();
    const { createMode, modelType, generalForm, modelTypeGroups } = props;
    const { presetId } = modelType;
    const modelTypeId = modelType.id;
    const intl = useIntl();

    const onChangeModelTypeName = useCallback((multilingualName: InternationalString) => {
        dispatch(changeKanbanModelTypeName({ multilingualName, presetId, modelTypeId }));
    }, []);

    const onChangeModelTypeId = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeKanbanModelTypeId({ presetId, modelTypeId, id: e.target.value.trim() }));
    }, []);

    const onChangeModelTypeDescription = useCallback((multilingualDescription: InternationalString) => {
        dispatch(changeKanbanModelTypeDescription({ presetId, modelTypeId, multilingualDescription }));
    }, []);

    const onChangeModelTypeGroupId = useCallback((groupId: string) => {
        dispatch(changeKanbanModelTypeGroupId({ presetId, modelTypeId: modelTypeId, groupId }));
    }, []);

    const modelTypeGroup: ModelTypeGroup | undefined = modelTypeGroups.find((group) => group.id === modelType.groupId);
    const modelTypeGroupName: string = LocalesService.internationalStringToString(modelTypeGroup?.multilingualName);

    return (
        <div data-test="kanban-model-type-general-tab" className={theme.form}>
            <MultiLangInputDialog
                data-test="kanban-model-type-name-input"
                placeholder={intl.formatMessage(messages.name)}
                multiLangValue={modelType.multilingualName}
                onChange={onChangeModelTypeName}
                label={intl.formatMessage(messages.name)}
                mainInputName="multilingualName"
                mainInputClassName={theme.input}
                generalForm={generalForm}
                required
                autoFocus
            />
            <InputId
                disabled={!createMode}
                value={modelType.id}
                required
                mainInputName="GUID"
                mainInputClassName={theme.input}
                onChange={onChangeModelTypeId}
            />
            <div className={theme.selectWrapper}>
                <div className={theme.customRequired}>
                    <Select
                        label={<span className={theme.selectLabel}>{intl.formatMessage(messages.modelTypeGroup)}</span>}
                        data-test="kanban-model-type-group-select"
                        onChange={onChangeModelTypeGroupId}
                        value={modelTypeGroupName}
                        originalTheme
                    >
                        {modelTypeGroups.map((group: ModelTypeGroup) => {
                            return (
                                <Select.Option
                                    key={group.id}
                                    value={group.id}
                                    label={LocalesService.internationalStringToString(group.multilingualName)}
                                />
                            );
                        })}
                    </Select>
                </div>
            </div>
            <div className={theme.selectWrapper}>
                <Select
                    onChange={() => {}}
                    value="kanbanBoard"
                    label={intl.formatMessage(messages.modelType)}
                    originalTheme
                    disabled
                >
                    {[intl.formatMessage(messages.kanbanBoard)].map((option) => (
                        <Select.Option value="kanbanBoard" label={option} />
                    ))}
                </Select>
            </div>
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.description)}
                multiLangValue={modelType.multilingualDescription}
                onChange={onChangeModelTypeDescription}
                textarea
                label={intl.formatMessage(messages.description)}
                mainInputName="multilingualDescription"
                mainInputClassName={theme.textArea}
                generalForm={generalForm}
                data-test="kanban-model-type-description-input"
            />
        </div>
    );
};

const withMemo = React.memo(KanbanModelTypeGeneralTab);

export { withMemo as KanbanModelTypeGeneralTab };

import { TReducer } from '@/utils/types';
import { TContainmentAllowedSymbolIdsState } from './containmentAllowedSymbolIds.reducer.types';
import {
    SET_CONTAINMENT_ALLOWED_SYMBOL_IDS,
    CLEAR_CONTAINMENT_ALLOWED_SYMBOL_IDS,
} from '@/actionsTypes/containmentAllowedSymbolIds.actionTypes';

export const containmentAllowedSymbolIdsReducer: TReducer<TContainmentAllowedSymbolIdsState> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case SET_CONTAINMENT_ALLOWED_SYMBOL_IDS: {
            const {
                payload: { symbolIds },
            } = action;

            return symbolIds;
        }

        case CLEAR_CONTAINMENT_ALLOWED_SYMBOL_IDS: {
            return null;
        }

        default:
            return state;
    }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActionButtons__iconContainer___7cG0{margin-left:4px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.ActionButtons__dropdownTriggerButton__mQ2wP{cursor:pointer;height:60px}.ActionButtons__dropdown____5Ix{position:absolute;z-index:9999}.ActionButtons__dropdown____5Ix .ant-dropdown-menu-item{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.ActionButtons__dropdown____5Ix .ant-dropdown-menu-title-content{padding-right:8px;font-size:14px;line-height:19px}", "",{"version":3,"sources":["webpack://./src/modules/UITemplates/components/UITemplatesTab/UITemplatesTable/ActionButtons/ActionButtons.scss"],"names":[],"mappings":"AAAA,qCACI,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEJ,6CACI,cAAA,CACA,WAAA,CAEJ,gCACI,iBAAA,CACA,YAAA,CAGI,wDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEJ,iEACI,iBAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".iconContainer {\r\n    margin-left: 4px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.dropdownTriggerButton {\r\n    cursor: pointer;\r\n    height: 60px;\r\n}\r\n.dropdown {\r\n    position: absolute;\r\n    z-index: 9999;\r\n\r\n    :global {\r\n        .ant-dropdown-menu-item {\r\n            display: flex;\r\n            flex-direction: row;\r\n            align-items: center;\r\n        }\r\n        .ant-dropdown-menu-title-content {\r\n            padding-right: 8px;\r\n            font-size: 14px;\r\n            line-height: 19px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": "ActionButtons__iconContainer___7cG0",
	"dropdownTriggerButton": "ActionButtons__dropdownTriggerButton__mQ2wP",
	"dropdown": "ActionButtons__dropdown____5Ix"
};
export default ___CSS_LOADER_EXPORT___;

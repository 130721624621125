import type { Locale } from '@/modules/Header/components/Header/header.types';
import type { TRootState } from '../reducers/root.reducer.types';
import type { INode } from '@/models/bpm/bpm-model-impl.types';
import type { DefaultGraph } from '@/mxgraph/DefaultGraph';
import type { TWorkspaceTab } from '@/models/tab.types';
import type { BPMMxGraph } from '../mxgraph/bpmgraph';
import type { MxCell } from '../mxgraph/mxgraph';
import type {
    EdgeInstance,
    NodeId,
    ObjectType,
    ModelNode,
    Node,
    DiagramElement,
    CloneNodeDTOModelCloneStrategyEnum,
    FullModelDefinition,
    ExcludeFields,
    DiagramElementAttributeStyle,
    LockInfoDTO,
    DiagramElementTypeEnum,
    ModelVersion,
    ShapeInstance,
    ObjectDefinitionNode,
    EdgeDefinitionNode,
} from '../serverapi/api';
import type GridModelGraph from '@/mxgraph/GridGraph/GridModelGraph';
import { objectDefinitionService } from './ObjectDefinitionService';
import { ApiBundle, apiBundle } from './api/api-bundle';
import { setServerIdToNodeInterface, setServerIdToNodeOriginal } from '../utils/nodeId.utils';
import { getStore } from '../store';
import { ModelSelectors } from '../selectors/model.selectors';
import { symbolService } from './SymbolsService';
import { GraphSerializer } from '@/mxgraph/codec/graphSerializer';
import { ModelTypes } from '@/models/ModelTypes';
import { sortCells } from '@/sagas/model/saveModelUtils';
import { cloneDeep } from 'lodash-es';
import { SaveModelLockTool, getLockingTool } from '@/modules/Editor/classes/SaveModelLockTool';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '@/models/editorMode';
import { LocalesService } from './LocalesService';
import messages from '../modules/App/messages/AppNotifications.messages';
import { LOW_SERVER } from '@/sagas/error.saga.types';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';

interface IModelService {
    linkedObjectTypes(edge: EdgeInstance, graph: BPMMxGraph): ObjectType[];

    loadModel(nodeId: NodeId): Promise<ModelNode>;

    loadModelDefinition(nodeId: NodeId, excludeFields?: ExcludeFields[]): Promise<FullModelDefinition>;

    loadModelVersion(nodeId: NodeId, version: number): Promise<ModelVersion>;

    cloneModel(
        modelId: NodeId,
        newParentId: NodeId,
        modelCloneStrategy: CloneNodeDTOModelCloneStrategyEnum,
    ): Promise<ModelNode>;

    restoreModel(nodeId: NodeId, version: number): Promise<ModelNode>;

    saveModel(model: ModelNode, forceSaveHistory: boolean): Promise<ModelNode>;

    loadModelFromStore(nodeId: NodeId | undefined): Node | undefined;

    prepareCellsForSave(cells: MxCell[], graph: BPMMxGraph): DiagramElement[];

    getDiagramElements(cells: MxCell[], graph: BPMMxGraph): DiagramElement[];

    lockModel(nodeId: NodeId): Promise<LockInfoDTO>;

    unlockModel(nodeId: NodeId): Promise<void>;

    prepareModelForSave(model: ModelNode, graph: BPMMxGraph): ModelNode;

    prepareAndSaveModel(
        graph: BPMMxGraph,
        isDefaultGraph: boolean,
        model: ModelNode,
        tab: TWorkspaceTab,
        locale: Locale,
        forceSave: boolean,
        forceSaveHistory: boolean,
    ): Promise<ModelNode | undefined>;
}

export const getNotEmptyStylesDiagramElements = (elements?: DiagramElement[]): DiagramElement[] => {
    return (
        elements?.map((element: DiagramElement) => {
            const { attributeStyles } = element;

            return {
                ...element,
                attributeStyles: attributeStyles?.filter(
                    (attributeStyle: DiagramElementAttributeStyle) => !!attributeStyle?.styles?.length,
                ),
            };
        }) || []
    );
};

// todo: 1647 keep by serverid and repositoryId
class ModelServiceImpl implements IModelService {
    loadModelFromStore(nodeId: NodeId | undefined): Node | undefined {
        if (!nodeId) {
            return undefined;
        }
        const store = <TRootState>getStore().getState();

        return ModelSelectors.byId(nodeId)(store) as Node;
    }

    linkedObjectTypes(edge: EdgeInstance, graph: BPMMxGraph): ObjectType[] {
        const obectTypes: { [id: string]: ObjectType } =
            graph.modelType && graph.modelType.objectTypes
                ? graph.modelType.objectTypes.reduce((a, oType) => ((a[oType.id] = oType), a), {})
                : {};
        const _objectType = (id?: string): ObjectType[] => {
            if (id) {
                const cell = graph.getModel().getCell(id);

                if (cell) {
                    const objectInstance = cell.getValue();

                    if (objectInstance?.type === 'object') {
                        const objectDefinition = objectDefinitionService().getObjectDefinitionByInstance(
                            objectInstance,
                            graph.id,
                        );

                        if (objectDefinition) {
                            return [obectTypes[objectDefinition.objectTypeId]];
                        }
                    }
                }
            }

            return [];
        };

        return [..._objectType(edge.source), ..._objectType(edge.target)];
    }

    loadModel(nodeId: NodeId): Promise<ModelNode> {
        return this.getAPI(nodeId.serverId)
            .model.getModel({ repositoryId: nodeId.repositoryId, modelId: nodeId.id })
            .then((model) => {
                setServerIdToNodeInterface(model, nodeId.serverId);

                return model;
            });
    }

    loadModelDefinition(nodeId: NodeId, excludeFields?: ExcludeFields[]): Promise<FullModelDefinition> {
        return this.getAPI(nodeId.serverId)
            .model.getModelDefinition({
                repositoryId: nodeId.repositoryId,
                modelId: nodeId.id,
                excludeFields,
            })
            .then((modelDefinition) => {
                setServerIdToNodeInterface(modelDefinition.model, nodeId.serverId);

                return modelDefinition;
            });
    }

    loadModelVersion(nodeId: NodeId, version: number): Promise<ModelVersion> {
        return this.getAPI(nodeId.serverId)
            .model.getModelVersion({
                repositoryId: nodeId.repositoryId,
                modelId: nodeId.id,
                version,
            })
            .then((modelDefinition) => {
                setServerIdToNodeInterface(modelDefinition.model, nodeId.serverId);
                modelDefinition.objects.forEach((obj: ObjectDefinitionNode) =>
                    setServerIdToNodeInterface(obj, nodeId.serverId),
                );
                modelDefinition.edges.forEach((edge: EdgeDefinitionNode) =>
                    setServerIdToNodeInterface(edge, nodeId.serverId),
                );

                return modelDefinition;
            });
    }

    cloneModel(
        modelId: NodeId,
        newParentId: NodeId,
        modelCloneStrategy: CloneNodeDTOModelCloneStrategyEnum,
    ): Promise<ModelNode> {
        const { serverId } = modelId;

        return this.getAPI(serverId)
            .model.clone({
                modelId: modelId.id,
                repositoryId: modelId.repositoryId,
                body: { modelCloneStrategy, newParentId: newParentId.id },
            })
            .then((model: ModelNode) => {
                setServerIdToNodeInterface(model, serverId);

                return model;
            });
    }

    restoreModel(nodeId: NodeId, version: number): Promise<ModelNode> {
        return this.getAPI(nodeId.serverId)
            .model.restore({ repositoryId: nodeId.repositoryId, modelId: nodeId.id, version })
            .then((model) => {
                setServerIdToNodeInterface(model, nodeId.serverId);

                return model;
            });
    }

    async saveModel(model: ModelNode, forceSaveHistory: boolean): Promise<ModelNode> {
        const savedModel = await this.getAPI(model.nodeId.serverId).model.advancedSave({
            body: model,
            forceSaveHistory,
        });

        setServerIdToNodeOriginal(savedModel as INode, model.nodeId.serverId);

        return savedModel;
    }

    prepareCellsForSave(cells: MxCell[], graph: BPMMxGraph): DiagramElement[] {
        const state = getStore().getState();
        const preparedElements: DiagramElement[] = [];

        for (const cell of cells) {
            const cellValue = cell.getValue();

            cellValue.parent = cell.getParent()?.id || null;

            if (!cellValue.parent) {
                continue;
            }

            if (cellValue.type === 'layout' && !cellValue.isFrame) {
                cellValue.metaInfo = JSON.stringify(cellValue.psdCellMetaInfo);
            }

            if (cell.isVertex()) {
                const object: ShapeInstance = cellValue;

                object.height = cell.geometry.height;
                object.width = cell.geometry.width;
                object.x = cell.geometry.x;
                object.y = cell.geometry.y;
                object.style = cell.getStyle();
            } else if (cell.isEdge()) {
                const edge: EdgeInstance = cellValue;

                if (
                    cell.source &&
                    cell.target &&
                    typeof cell.source.getValue() !== 'string' &&
                    typeof cell.target.getValue() !== 'string'
                ) {
                    edge.source = cell.source.getValue().id;
                    edge.target = cell.target.getValue().id;
                }

                const isEdgeTypeEditable =
                    UserProfileSelectors.isEdgeTypeEditable(
                        graph.id.serverId,
                        graph.id.repositoryId,
                        edge.edgeTypeId,
                    )(state) ?? true;

                if (isEdgeTypeEditable) {
                    edge.style = symbolService().deleteOpacity(cell.getStyle());
                }

                edge.labelXOffset = cell.geometry.x;
                edge.labelYOffset = cell.geometry.y;
                edge.waypoints = cell.geometry.points;
                edge.invisible = cell.value.invisible;
            }

            preparedElements.push(cellValue);
        }

        return preparedElements;
    }

    public getDiagramElements(cells: MxCell[], graph: BPMMxGraph): DiagramElement[] {
        const simpleCells: MxCell[] = cells.filter((cell) => !ComplexSymbolManager.isComplexSymbolCell(cell));
        const complexElements: DiagramElement[] = graph.complexSymbolManager.prepareDiagramElements(cells);
        const simpleDiagramElements: DiagramElement[] = this.prepareCellsForSave(simpleCells, graph);

        // TODO удалить вызов getNotEmptyStylesDiagramElements после перенесения всех символов в Manager
        return getNotEmptyStylesDiagramElements([...complexElements, ...simpleDiagramElements]);
    }

    private getAPI(serverId: string): ApiBundle {
        try {
            const api = apiBundle(serverId);

            return api;
        } catch (e) {
            throw new Error(`Error in ModelService, no existing API for server with id=[${serverId}]`);
        }
    }

    async lockModel(nodeId: NodeId): Promise<LockInfoDTO> {
        const lock = await apiBundle(nodeId.serverId).model.lockModel({
            repositoryId: nodeId.repositoryId,
            modelId: nodeId.id,
        });

        return lock;
    }

    async unlockModel(nodeId: NodeId): Promise<void> {
        const unlock = await apiBundle(nodeId.serverId).model.unlockModel({
            repositoryId: nodeId.repositoryId,
            modelId: nodeId.id,
        });

        return unlock;
    }

    prepareModelForSave(model: ModelNode, graph: BPMMxGraph): ModelNode {
        const modelClone = cloneDeep(model);
        const codec: GraphSerializer = new GraphSerializer();
        const isWhiteBoard: boolean = graph?.modelType?.id === ModelTypes.MIND_MAP;
        const isGridModel: boolean = [ModelTypes.PSD_MODEL, ModelTypes.EPC_MODEL].includes(
            graph?.modelType?.id as ModelTypes,
        );

        if (isWhiteBoard) {
            modelClone.graph = codec.stringify(graph);
        } else {
            const DIAGRAM_ELEMENT_TYPES: DiagramElementTypeEnum[] = ['object', 'edge', 'layout', 'shape'];
            const { cells } = graph.getModel();

            const filteredCells = Object.values<MxCell>(cells).filter((cell: MxCell) => {
                const value = cell.getValue();

                return !!value && typeof value !== 'string' && DIAGRAM_ELEMENT_TYPES.includes(value.type);
            });

            modelClone.elements = this.getDiagramElements(filteredCells, graph);
        }

        if (isWhiteBoard && modelClone.graph) {
            const obj = JSON.parse(codec.stringify(graph));
            obj.graph = sortCells(obj.graph, graph.getModel());
            modelClone.graph = JSON.stringify(obj);
        } else {
            modelClone.elements = sortCells(modelClone.elements, graph.getModel());
        }

        if (isGridModel) {
            modelClone.layout = (graph as GridModelGraph).serializeGrid();
        }

        return modelClone;
    }

    async prepareAndSaveModel(
        graph: BPMMxGraph,
        isDefaultGraph: boolean,
        model: ModelNode,
        tab: TWorkspaceTab,
        locale: Locale,
        forceSave: boolean,
        forceSaveHistory: boolean,
    ): Promise<ModelNode | undefined> {
        if (!tab || tab.type !== WorkSpaceTabTypes.EDITOR || tab.mode !== EditorMode.Edit) return;

        const graphId: NodeId = model.nodeId;
        const lock: SaveModelLockTool = getLockingTool();

        if (lock.isLocked(graphId.id)) {
            throw new Error(LOW_SERVER);
        }

        let savedModel: ModelNode | undefined;

        lock.addLock(graphId.id);

        try {
            if (forceSave || (isDefaultGraph && (graph as DefaultGraph).isGraphUpdated())) {
                const preparedModel = this.prepareModelForSave(model, graph);

                savedModel = await this.saveModel(preparedModel, forceSaveHistory);

                if (savedModel) graph.id = savedModel.nodeId;

                if (isDefaultGraph) (graph as DefaultGraph).resetPropertyIsGraphUpdated();
            }
        } catch (error) {
            error.message = LocalesService.useIntl(locale).formatMessage(messages.modelSaveFail, {
                name: tab.title,
            });
            throw error;
        } finally {
            graph.setDirty(false);
            lock.deleteLock(graphId.id);
        }

        return savedModel;
    }
}

let modelServiceInstance: IModelService;

export function modelService(): IModelService {
    if (!modelServiceInstance) {
        modelServiceInstance = new ModelServiceImpl();
    }

    return modelServiceInstance;
}

import { TReducer } from '../../utils/types';
import { WikiType } from '../../serverapi/api';
import { CLEAR_PRESET_REQUEST } from '../../actionsTypes/methodologySetting.actionTypes';
import {
    PRESET_SETTINGS_ADD_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_GET_ALL_WIKI_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_SUBMIT_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_WIKI_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsWikiModelType.actionTypes';
import {
    IPresetSettingsWikiModelTypeData,
    TMappingWikiModelTypes,
    TPresetSettingsWikiModelTypeState,
} from './presetSettingsWikiModelType.reducer.types';

export const PRESET_SETTINGS_INITIAL_WIKI_MODEL_TYPE_SERVER_STATE: IPresetSettingsWikiModelTypeData = {
    byId: {},
    wikiModelTypesForDelete: [],
    wikiModelTypesForSave: [],
};

const initial: TPresetSettingsWikiModelTypeState = {
    byPresetId: {},
};

const mappingWikiModelTypes = (wikiModelTypes: WikiType[]): TMappingWikiModelTypes => {
    const wikiModelTypesMap: TMappingWikiModelTypes = wikiModelTypes.reduce(
        (result: TMappingWikiModelTypes, item: WikiType) => {
            result[item.id] = item;

            return result;
        },
        {},
    );

    return wikiModelTypesMap;
};

const setNewData = (
    state: TPresetSettingsWikiModelTypeState,
    presetId: string,
    newData: Partial<IPresetSettingsWikiModelTypeData>,
): TPresetSettingsWikiModelTypeState => {
    return {
        ...state,
        byPresetId: {
            ...state.byPresetId,
            [presetId]: {
                ...PRESET_SETTINGS_INITIAL_WIKI_MODEL_TYPE_SERVER_STATE,
                ...state.byPresetId[presetId],
                ...newData,
            },
        },
    };
};

export const presetSettingsWikiModelTypeReducer: TReducer<TPresetSettingsWikiModelTypeState> = (
    state = initial,
    action,
) => {
    switch (action.type) {
        case PRESET_SETTINGS_GET_ALL_WIKI_MODEL_TYPES_SUCCESS: {
            const { wikiModelTypes, presetId } = action.payload;
            const wikiModelTypesMap: TMappingWikiModelTypes = mappingWikiModelTypes(wikiModelTypes);

            const newData: Partial<IPresetSettingsWikiModelTypeData> = {
                byId: wikiModelTypesMap,
                wikiModelTypesForDelete: [],
                wikiModelTypesForSave: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_ADD_WIKI_MODEL_TYPE: {
            const { wikiModelTypes, presetId } = action.payload;
            const wikiModelTypesMap: TMappingWikiModelTypes = mappingWikiModelTypes(wikiModelTypes);

            const newData: Partial<IPresetSettingsWikiModelTypeData> = {
                byId: {
                    ...state.byPresetId[presetId]?.byId,
                    ...wikiModelTypesMap,
                },
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_SUBMIT_WIKI_MODEL_TYPE: {
            const { presetId } = action.payload;

            const newData: Partial<IPresetSettingsWikiModelTypeData> = {
                wikiModelTypesForDelete: [],
                wikiModelTypesForSave: [],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_TRANSFER_WIKI_MODEL_TYPE: {
            const { wikiModelTypes, presetId } = action.payload;
            const wikiModelTypesMap: TMappingWikiModelTypes = mappingWikiModelTypes(wikiModelTypes);

            const newData: Partial<IPresetSettingsWikiModelTypeData> = {
                byId: {
                    ...state.byPresetId[presetId]?.byId,
                    ...wikiModelTypesMap,
                },
                wikiModelTypesForSave: [...state.byPresetId[presetId]?.wikiModelTypesForSave, ...wikiModelTypes],
            };

            return setNewData(state, presetId, newData);
        }

        case PRESET_SETTINGS_DELETE_WIKI_MODEL_TYPE: {
            const { wikiModelTypesForDelete, presetId } = action.payload;
            const allWikiModelTypes: TMappingWikiModelTypes = state.byPresetId[presetId]?.byId || {};
            const filteredKanbanModelTypes: TMappingWikiModelTypes = Object.keys(allWikiModelTypes).reduce(
                (res: { [id: string]: WikiType }, id) => {
                    const isFoundInDeleteList: boolean = wikiModelTypesForDelete.some(
                        (wikiModelType) => wikiModelType.id === id,
                    );
                    if (!isFoundInDeleteList) {
                        res[id] = allWikiModelTypes[id];
                    }

                    return res;
                },
                {},
            );

            const newData: Partial<IPresetSettingsWikiModelTypeData> = {
                byId: filteredKanbanModelTypes,
                wikiModelTypesForDelete: [
                    ...state.byPresetId[presetId]?.wikiModelTypesForDelete,
                    ...wikiModelTypesForDelete,
                ],
            };

            return setNewData(state, presetId, newData);
        }

        case CLEAR_PRESET_REQUEST: {
            const { presetId } = action.payload;
            delete state.byPresetId?.[presetId];

            return { ...state };
        }

        default: {
            return state;
        }
    }
};

import {
    KANBAN_CREATE,
    KANBAN_MOVE_ITEM,
    KANBAN_OPEN_BY_ID,
    KANBAN_REMOVE,
    KANBAN_ADD,
    KANBAN_SET_NODE,
    KANBAN_RENAME,
    KANBAN_CREATE_FAILURE,
    KANBAN_CREATE_SUCCESS,
} from '../../actionsTypes/entities/kanban.actionTypes';
import { NodeId, KanbanNode, Node } from '../../serverapi/api';
import {
    TKanbanDefaultAction,
    TKanbanMoveItemAction,
    TKanbanMoveItemPayload,
    TKanbanOpenByIdAction,
    TKanbanRemoveAction,
    TKanbanAddAction,
    TKanbanAddPayload,
    TKanbanSetNodeAction,
    TKanbanRenamePayload,
    TKanbanRenameAction,
    TKanbanCreateFailureAction,
    TKanbanCreateSuccessAction,
} from './kanban.actions.types';

export const kanbanCreate = (kanban: KanbanNode): TKanbanDefaultAction => ({
    type: KANBAN_CREATE,
    payload: {
        kanban,
    },
});

export const kanbanCreateFailure = (kanban: KanbanNode): TKanbanCreateFailureAction => ({
    type: KANBAN_CREATE_FAILURE,
    payload: {
        kanban,
    },
});

export const kanbanCreateSuccess = (kanban: KanbanNode): TKanbanCreateSuccessAction => ({
    type: KANBAN_CREATE_SUCCESS,
    payload: {
        kanban,
    },
});

export const kanbanAdd = (payload: TKanbanAddPayload): TKanbanAddAction => ({
    type: KANBAN_ADD,
    payload,
});

export const kanbanOpenById = (nodeId: NodeId): TKanbanOpenByIdAction => ({
    type: KANBAN_OPEN_BY_ID,
    payload: {
        nodeId,
    },
});

export const kanbanRemoveSuccess = (nodeId: NodeId): TKanbanRemoveAction => ({
    type: KANBAN_REMOVE,
    payload: {
        nodeId,
    },
});

export const kanbanMoveItem = (payload: TKanbanMoveItemPayload): TKanbanMoveItemAction => ({
    type: KANBAN_MOVE_ITEM,
    payload,
});

export const kanbanSetNode = (node: Node): TKanbanSetNodeAction => ({
    type: KANBAN_SET_NODE,
    payload: {
        node,
    },
});

export const kanbanRename = (payload: TKanbanRenamePayload): TKanbanRenameAction => ({
    type: KANBAN_RENAME,
    payload,
});

import { Editor } from '@monaco-editor/react';
import React from 'react';
import theme from './TxtFileEditor.component.scss';
import { TTabComponentProps } from '../Workspace/Workspace.types';
import { IWorkspaceTabTxtFileEditorParams } from '@/models/tab.types';

export const TxtFileEditor = (ownProps: TTabComponentProps) => {
    const { stringData } = ownProps.tab.params as IWorkspaceTabTxtFileEditorParams;

    return (
        <div
            className={theme.editorContainer}
            data-test="script-editor_container"
        >
            <Editor
                className={theme.editor}
                language={'plaintext'}
                value={stringData || ''}
                options={{
                    formatOnType: true,
                    readOnly: true,
                    autoIndent: 'full',
                    contextmenu: true,
                    fontSize: 13,
                    hideCursorInOverviewRuler: true,
                    matchBrackets: 'always',
                    minimap: {
                        enabled: true,
                    },
                    showDeprecated: true,
                }}
            />
        </div>
    );
}

import React, { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import theme from './Presets.scss';
import messages from '../../messages/Presets.messages';
import footerMessages from '../../../../Footer/FooterButtons.messages';
import { TreeNode } from '../../../../../models/tree.types';
import { GeneralSettingsTab } from './GeneralSettingsTab/GeneralSettings.component';
import { TPreset } from '../../../../../models/preset.types';
import { FooterButtons } from '../../../../Footer/FooterButtons.component';
import { InternationalString } from '../../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { PresetSelectors } from '../../../../../selectors/preset.selectors';
import { workspaceRemoveTabByNodeId } from '../../../../../actions/tabs.actions';
import { submitPresetCopy } from '../../../../../actions/methodologySetting.actions';
import { IWorkspaceTabItemModelEditParams, TWorkspaceTab } from '../../../../../models/tab.types';

type TPresetCopyProps = {
    tab: TWorkspaceTab;
};

type TPresetCopyFullProps = TPresetCopyProps;

export const PresetCopy: FC<TPresetCopyFullProps> = (props) => {
    const params = props.tab.params as IWorkspaceTabItemModelEditParams;
    const { serverNode } = params;
    const [preset, setPreset] = useState<TPreset>(params.preset);
    const intl = useIntl();
    const formRef = useRef<any>();
    const loading: boolean = useSelector(PresetSelectors.loading());
    const dispatch = useDispatch();
    const onCancel = () => dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId));
    const onSubmit = (recordPreset: TPreset, recordServerNode: TreeNode) => {
        dispatch(submitPresetCopy(recordPreset, recordServerNode, props.tab.nodeId));
    };
    const onChangeName = (multilingualName: InternationalString) => {
        setPreset({
            ...preset,
            multilingualName,
        });
    };
    const onChangeDescription = (multilingualDescription: InternationalString) => {
        setPreset({
            ...preset,
            multilingualDescription,
        });
    };

    return (
        <div className={theme.presetContainer}>
            <span className={theme.navigationTitle}>{intl.formatMessage(messages.generalSettings)}</span>
            <div className={theme.tabContent}>
                <GeneralSettingsTab
                    preset={preset}
                    onChangeDescription={onChangeDescription}
                    onChangeName={onChangeName}
                    disabled={false}
                    generalFormRef={formRef}
                />
                <FooterButtons
                    buttons={[
                        {
                            children: intl.formatMessage(footerMessages.cancel),
                            onClick: onCancel,
                            dataTest: 'copy_methodology_cancel_btn',
                            size: 'large',
                        },
                        {
                            children: intl.formatMessage(footerMessages.confirm),
                            visualStyle: 'primary',
                            disabled: loading,
                            onClick: () => {
                                const form = formRef.current;
                                if (form) {
                                    form.validateFields()
                                        .then(() => {
                                            if (!loading) {
                                                onSubmit(preset, serverNode);
                                            }
                                        })
                                        .catch(() => console.log('error'));
                                }
                            },
                            dataTest: 'copy-methodology_save-button',
                            size: 'large',
                        },
                    ]}
                />
            </div>
        </div>
    );
};

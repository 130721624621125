import { TReducer } from '../utils/types';
import { TFormatPanel } from './formatPanel.reducer.types';
import { FORMAT_PANEL_CLOSE, FORMAT_PANEL_OPEN } from '../actionsTypes/formatPanel.actionsTypes';
import { compareNodeIds } from '../utils/nodeId.utils';

const initial: TFormatPanel = { modelsWithOpenFormatPanel: [] };

export const formatPanelReducer: TReducer<TFormatPanel> = (state = initial, action) => {
    switch (action.type) {
        case FORMAT_PANEL_OPEN: {
            const {
                payload: { modelId },
            } = action;

            return { ...state, modelsWithOpenFormatPanel: [...state.modelsWithOpenFormatPanel, modelId] };
        }
        case FORMAT_PANEL_CLOSE: {
            const {
                payload: { modelId },
            } = action;

            return {
                ...state,
                modelsWithOpenFormatPanel: [
                    ...state.modelsWithOpenFormatPanel.filter((opneModelId) => !compareNodeIds(opneModelId, modelId)),
                ],
            };
        }

        default: {
            return state;
        }
    }
};

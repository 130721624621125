import { CHANGE_MODEL_DATA, MODEL_REQUEST_FAILURE, MODEL_REQUEST_SUCCESS } from '../actionsTypes/model.actionTypes';
import { ModelNode, NodeId } from '../serverapi/api';

export const modelRequestSuccess = (model: ModelNode) => ({
    type: MODEL_REQUEST_SUCCESS,
    payload: {
        model,
    },
});

export const changeModelData = (nodeId: NodeId, model: ModelNode) => ({
    type: CHANGE_MODEL_DATA,
    payload: {
        nodeId,
        model,
    },
});

export const modelRequestFailure = (serverId: string, error = 'unknown error') => ({
    type: MODEL_REQUEST_FAILURE,
    payload: { serverId, error },
});

import React, { memo } from 'react';
import cx from 'classnames';
import { Icon } from 'UIKit';
import icDivider from 'icons/toolbar/controls/ic-divider.svg';
import theme from '../../../Toolbar/Toolbar.scss';

export const DividerComponent = memo(({ compact = true }: { compact?: boolean }) => {
    return (
        <div className={cx(theme.divider, { [theme.dividerCompact]: compact })}>
            <Icon spriteSymbol={icDivider} className={theme.divider} />
        </div>
    );
});

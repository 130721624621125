import React, { FC, useState } from 'react';
import messages from './UITemplatesTable.messages';
import { useIntl } from 'react-intl';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { UITemplateDTO } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { dateTimeNoSecondsFormat, timestampToStringDate } from '@/utils/date.time.utils';
import { ActionButtons } from './ActionButtons/ActionButtons.component';

type TUITemplatesTableProps = {
    UITemplates: UITemplateDTO[];
    selectedTemplateId: string;
    setSelectedTemplateId: (templateId: string) => void;
};

export const UITemplatesTable: FC<TUITemplatesTableProps> = ({
    selectedTemplateId,
    UITemplates,
    setSelectedTemplateId,
}) => {
    const intl = useIntl();
    const [clickedDropdownId, setClickedDropdown] = useState<string>('');

    const tableData: TTableData[] = UITemplates.map((UITemplate) => {
        const name: string = LocalesService.internationalStringToString(UITemplate.name);

        return {
            id: UITemplate.id,
            name,
            createdAt: timestampToStringDate(UITemplate.updatedAt || UITemplate.createdAt, dateTimeNoSecondsFormat),
            updatedAt: timestampToStringDate(UITemplate.updatedAt || UITemplate.createdAt, dateTimeNoSecondsFormat),
            actionBtns: (
                <ActionButtons
                    templateId={UITemplate.id}
                    templateName={name}
                    clickedDropdownId={clickedDropdownId}
                    setClickedDropdown={setClickedDropdown}
                />
            ),
            selected: selectedTemplateId === UITemplate.id,
        };
    });

    const columns: TColumn[] = [
        {
            dataKey: 'name',
            title: intl.formatMessage(messages.name),
            withoutSorter: true,
        },
        {
            dataKey: 'updatedAt',
            title: intl.formatMessage(messages.updatedAt),
            withoutSorter: true,
        },
        {
            dataKey: 'actionBtns',
            title: '',
            width: 60,
            withoutSorter: true,
        },
    ];

    return <TableUIKit columns={columns} tableData={tableData} onRowClick={(data) => setSelectedTemplateId(data.id)} />;
};

import React, { FC } from 'react';
import theme from './SummarySourceInput.scss';
import { Col, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { setWidgetSource, summaryWidgetSetOperation } from '@/actions/dashboard.actions';
import { useIntl } from 'react-intl';
import messages from './SummarySourceInput.messages';
import { SourceTableSelect } from '../SourceTableSelect/SourceTableSelect';
import { SummaryOperations, TChartDataset, TDashboardWidgets, UpperCaseAlp } from '../../widgets/widget.types';
import { isSummaryWidget } from '../../dashboardUtils';

const initDataset: TChartDataset[] = [{ col1: '', col2: '', legend: '' }];

export const SummarySourceInput: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const currentWidget: TDashboardWidgets | undefined = useSelector(
        DashboardSelector.selectedWidget(activeTab?.nodeId),
    );

    if (!currentWidget || !isSummaryWidget(currentWidget) || !activeTab) {
        return null;
    }

    const { nodeId } = activeTab;

    const dataSet: TChartDataset = currentWidget.source?.dataset?.length
        ? currentWidget.source.dataset[0]
        : initDataset[0];

    const summaryOperation: SummaryOperations | undefined = currentWidget.summaryOperation;

    const setDataSourceHandler = (value: string, key: keyof TChartDataset) => {
        if (!currentWidget || !currentWidget.source) return;
        const isString = key === 'legend';
        const newValue = isString ? value : value.toUpperCase();
        if (Object.keys(UpperCaseAlp).includes(newValue) || isString) {
            const newDataSet = { ...dataSet };
            if (newDataSet) {
                //@ts-ignore
                newDataSet[key] = newValue;
                dispatch(
                    setWidgetSource(nodeId, currentWidget.id, {
                        ...currentWidget.source,
                        dataset: [newDataSet],
                    }),
                );
            }
        }
    };

    const changeSummaryOperation = (value: SummaryOperations) => {
        dispatch(summaryWidgetSetOperation(nodeId, currentWidget.id, value));
    };

    const options = (Object.keys(SummaryOperations) as SummaryOperations[]).map((key) => {
        return {
            value: key,
            label: intl.formatMessage(messages[key]),
        };
    });

    return (
        <>
            <SourceTableSelect nodeId={nodeId} widgetId={currentWidget.id} tableNodeId={currentWidget.source?.id} />
            {currentWidget.source?.id && (
                <div>
                    <Col className={theme.inputContainer}>
                        <div className={theme.label}>{intl.formatMessage(messages.sourceCol)}</div>
                        <Input
                            value={dataSet.col1 || ''}
                            onChange={(e) => setDataSourceHandler(e.currentTarget.value, 'col1')}
                            className={theme.input}
                            data-test="dashboard_widget-settings-panel_input-data-table-column"
                        />
                    </Col>
                    <Col className={theme.inputContainer}>
                        <div className={theme.label}>{intl.formatMessage(messages.operation)}</div>
                        <Select
                            onChange={changeSummaryOperation}
                            value={summaryOperation}
                            options={options}
                            className={theme.select}
                            data-test="dashboard_widget-settings-panel_select-operation"
                        />
                    </Col>
                    <Col className={theme.inputContainer}>
                        <div className={theme.label}>{intl.formatMessage(messages.legend)}</div>
                        <Input
                            value={dataSet.legend || ''}
                            onChange={(e) => setDataSourceHandler(e.currentTarget.value, 'legend')}
                            className={theme.input}
                            data-test="dashboard_widget-settings-panel_input-data-table-legend"
                        />
                    </Col>
                </div>
            )}
        </>
    );
};

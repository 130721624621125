import { closeDialog, openDialog } from '@/actions/dialogs.actions';
import {
    dropPastePreviewAction,
    pasteAction,
    pasteWhiteboardAction,
    setCopiedElementsAction,
} from '@/actions/editor.actions';
import {
    PasteMode,
    TEditorPasteAction,
    TEditorSelectSymbolToReplaceOnPasteSubmitAction,
} from '@/actions/editor.actions.types';
import { showNotificationByType } from '@/actions/notification.actions';
import { EDITOR_PASTE, EDITOR_SELECT_SYMBOL_TO_REPLACE_ON_PASTE_SUBMIT } from '@/actionsTypes/editor.actionTypes';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { ModelTypes } from '@/models/ModelTypes';
import { NotificationType } from '@/models/notificationType';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { instancesBPMMxGraphMap } from '@/mxgraph/bpm-mxgraph-instance-map';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { WhiteboardGraph } from '@/mxgraph/WhiteboardGraph';
import { TWhereCopiedFrom } from '@/reducers/copy.reducer.types';
import { EdgeTypeSelectors } from '@/selectors/edgeType.selectors';
import { getActiveGraph, getCopiedElements, getWhereCopiedFrom } from '@/selectors/editor.selectors';
import { FolderTypeSelectors } from '@/selectors/folderType.selectors';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { DefaultId, DiagramElement, EdgeType, NodeId, ObjectInstance, Symbol } from '@/serverapi/api';
import { PasteElementsBLL } from '@/services/bll/PasteElementsBLL';
import { TCheckElementsBeforePasteResult } from '@/services/bll/PasteElementsBLL.types';
import { getStore } from '@/store';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { MxCell, MxPoint, MxEvent } from 'MxGraph';
import { call, put, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { IModelContext } from './utils.types';
import { getActiveModelContext } from './utils';
import { getSymbolsFromModelType } from '@/utils/symbol.utils';
import { CompositeDragSource } from '@/mxgraph/util/CompositeDragSource';
import { SymbolType } from '@/models/Symbols.constants';
import { ObjectDefinitionSelectors } from '@/selectors/objectDefinition.selectors';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import isMouseEvent = MxEvent.isMouseEvent;
import isLeftMouseButton = MxEvent.isLeftMouseButton;
import { TreeItemType } from '@/modules/Tree/models/tree';

function* checkIfLeastOneObjDefNotAllowed(elements: DiagramElement[], graphId: NodeId) {
    const objectInstances: ObjectInstanceImpl[] = elements.filter(
        (element) => element.type === 'object',
    ) as ObjectInstanceImpl[];

    const ifLeastOneObjDefNotAllowed = yield select(
        FolderTypeSelectors.ifLeastOneObjDefNotAllowed(
            objectInstances.map((element) => element.objectDefinitionId as string),
            graphId,
        ),
    );

    return ifLeastOneObjDefNotAllowed;
}

function* getSymbolsForPreview(
    elements: DiagramElement[],
    symbols: Symbol[],
    graphId: NodeId,
): Generator<SelectEffect, Symbol[], ObjectDefinitionImpl | undefined> {
    const supportedSymbols: Symbol[] = [];

    for (const element of elements) {
        if (element.type === SymbolType.SHAPE) {
            supportedSymbols.push({
                ...element,
                presetId: '',
                color: '',
                graphical: '',
                name: '',
                objectType: '',
                icon: '',
                showLabel: false,
            });
        }

        if (element.type !== 'object') {
            continue;
        }

        const { objectDefinitionId } = <ObjectInstance>element;

        if (!objectDefinitionId) {
            continue;
        }

        const objectId: NodeId = { ...graphId, id: objectDefinitionId };
        const objectDefinition: ObjectDefinitionImpl | undefined = yield select(
            ObjectDefinitionSelectors.byId(objectId),
        );

        if (!objectDefinition) {
            continue;
        }

        symbols.forEach((symbol: Symbol) => {
            if (symbol.objectType.includes(objectDefinition.objectTypeId || '')) {
                supportedSymbols.push(symbol);
            }
        });

        if (objectDefinition.objectTypeId === PictureSymbolConstants.PICTURE_SYMBOL_TYPE) {
            supportedSymbols.push({
                id: PictureSymbolConstants.PICTURE_SYMBOL_ID,
                presetId: DefaultId.DEFAULT_PRESET_ID,
                graphical: objectDefinition.name,
                name: PictureSymbolConstants.PICTURE_SYMBOL_NAME,
                description: '',
                icon: '',
                objectType: PictureSymbolConstants.PICTURE_SYMBOL_TYPE,
                showLabel: true,
                color: PictureSymbolConstants.PICTURE_SYMBOL_COLOR,
                width: element.width,
                height: element.height,
            });
        }
    }

    return supportedSymbols;
}

function* showPastePreview(elements: DiagramElement[], onDrop: (dropPoint: MxPoint, target) => void) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (modelContext && elements.length > 0) {
        const presetId: string = yield select(TreeSelectors.presetById(modelContext.graph.id));
        const symbols: Symbol[] = yield select(
            SymbolSelectors.byServerIdPresetId(modelContext.graph.id.serverId, presetId),
        );
        const allowAnyObject: boolean | undefined = modelContext.graph.modelType?.allowAnyObject;
        const previewSymbols: Symbol[] = !allowAnyObject
            ? yield getSymbolsForPreview(
                  elements,
                  getSymbolsFromModelType(modelContext.graph.modelType),
                  modelContext.graph.id,
              )
            : symbols;

        const dropHandler = (graph: BPMMxGraph, evt: PointerEvent, target: MxCell, point: MxPoint) => {
            return isMouseEvent(evt) && isLeftMouseButton(evt) && onDrop?.(point, target);
        };

        const symbol: Symbol =
            previewSymbols.find((s) =>
                elements.some((el) => el.type === 'object' && (el as ObjectInstance).symbolId === s.id),
            ) || previewSymbols[0];

        const dragSource = new CompositeDragSource(symbol, dropHandler, modelContext.graph, undefined, elements);

        dragSource.emulatePointerEvent();
    }
}

function* handlePaste({ payload: { pasteMode } }: TEditorPasteAction) {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);

    if (!activeGraphId) return;

    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph || !graph.modelType) return;

    if (graph.modelType.id === ModelTypes.MIND_MAP) {
        yield put(pasteWhiteboardAction(graph as WhiteboardGraph));

        return;
    }

    const whereCopiedFrom: TWhereCopiedFrom | null = yield select(getWhereCopiedFrom);

    if (whereCopiedFrom?.nodeType === TreeItemType.Matrix) {
        yield put(showNotificationByType(NotificationType.CANNOT_PASTE_THIS_TYPE_OF_ELEMENTS));

        return;
    }

    let copiedElements: DiagramElement[] = yield select(getCopiedElements);

    if (!copiedElements?.length) {
        yield put(showNotificationByType(NotificationType.EMPTY_CLIPBOARD));

        return;
    }

    if (!whereCopiedFrom || !compareNodeIds({ ...whereCopiedFrom, id: '' }, { ...activeGraphId, id: '' })) {
        yield put(showNotificationByType(NotificationType.CANNOT_PASTE_FROM_ANOTHER_REPOSITORY));

        return;
    }

    const presetId: string | undefined = yield select(TreeSelectors.presetById(graph.id));
    const allMethodologySymbols: Symbol[] = yield select(
        SymbolSelectors.byServerIdPresetId(graph.id.serverId, presetId || ''),
    );
    const allMethodologyEdgeTypes: EdgeType[] = yield select(
        EdgeTypeSelectors.listByPresetId(graph.id.serverId, presetId || ''),
    ) || [];

    const checkElementsResult: TCheckElementsBeforePasteResult = PasteElementsBLL.checkElementsBeforePaste(
        copiedElements,
        allMethodologySymbols,
        allMethodologyEdgeTypes,
        graph.modelType,
        graph.id.serverId,
    );

    if (checkElementsResult.errorNotification) {
        yield put(showNotificationByType(checkElementsResult.errorNotification));

        return;
    }

    if (checkElementsResult.replaceElementsData.symbols.length === 1) {
        const symbol = checkElementsResult.replaceElementsData.symbols[0];
        copiedElements = PasteElementsBLL.replaceSymbolsInCopiedElements(
            symbol,
            copiedElements,
            checkElementsResult.replaceElementsData.elementIdsToBeReplaced,
        );

        const checkElements: TCheckElementsBeforePasteResult = PasteElementsBLL.checkElementsBeforePaste(
            copiedElements,
            allMethodologySymbols,
            allMethodologyEdgeTypes,
            graph.modelType,
            graph.id.serverId,
        );
        if (checkElements.errorNotification) {
            yield put(showNotificationByType(checkElements.errorNotification));
            return;
        }
    }

    if (checkElementsResult.replaceElementsData.symbols.length > 1) {
        yield put(
            openDialog(DialogType.SELECT_SYMBOL_TO_REPLACE_ON_PASTE_DIALOG, {
                symbols: checkElementsResult.replaceElementsData.symbols,
                copiedElements,
                copiedElementsIdsToReplace: checkElementsResult.replaceElementsData.elementIdsToBeReplaced,
                pasteMode,
            }),
        );
        return;
    }

    if (pasteMode === PasteMode.DEFINITION || pasteMode === PasteMode.VARIANT) {
        const ifLeastOneObjDefNotAllowed = yield checkIfLeastOneObjDefNotAllowed(copiedElements, graph.id);

        if (ifLeastOneObjDefNotAllowed) {
            yield put(showNotificationByType(NotificationType.INVALID_OBJECT_TYPE_FOR_THIS_FOLDER));

            return;
        }
    }

    const dropHandler = (dropPoint: MxPoint, target) =>
        getStore().dispatch(dropPastePreviewAction(copiedElements, dropPoint, target?.getId(), pasteMode));

    yield call(showPastePreview, copiedElements, dropHandler);
}

function* handleSelectSymbolToReplaceOnPaste({ payload }: TEditorSelectSymbolToReplaceOnPasteSubmitAction) {
    const { pasteMode, selectedSymbol, copiedElements, copiedElementsIdsToReplace } = payload;

    const copiedElementsNew: DiagramElement[] = PasteElementsBLL.replaceSymbolsInCopiedElements(
        selectedSymbol,
        copiedElements,
        copiedElementsIdsToReplace,
    );

    yield put(setCopiedElementsAction(copiedElementsNew));
    yield put(pasteAction(pasteMode));
    yield put(closeDialog(DialogType.SELECT_SYMBOL_TO_REPLACE_ON_PASTE_DIALOG));
}

export function* editorPasteSaga() {
    yield takeEvery(EDITOR_PASTE, handlePaste);
    yield takeEvery(EDITOR_SELECT_SYMBOL_TO_REPLACE_ON_PASTE_SUBMIT, handleSelectSymbolToReplaceOnPaste);
}

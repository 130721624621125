import React, { useEffect } from 'react';
import { NodeId } from '../../../../serverapi/api';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from './ObjectConnections.messages';
import theme from '@/modules/ObjectPropertiesDialog/components/ObjectPropertiesDialog.scss';
import { Table } from 'antd';
import { EditableText } from '../../../UIKit/components/EditableText/EditableText.component';
import { objectDecompositionOpen } from '../../../../actions/entities/objectDecomposition.actions';
import { TreeItemType } from '../../../Tree/models/tree';
import { ColumnProps } from 'antd/es/table';
import { TObjectConnectionsData } from './ObjectConnections.types';
import { ObjectConnectionsSelectors } from '../../../../selectors/objectConnections.selectors';
import { objectConnectionsDelete } from '../../../../actions/objectConnections.actions';

type TObjectConnectionsProps = {
    objectNodeId: NodeId;
};

type TDataSource = {
    modelName: JSX.Element;
    modelId: string;
    edgeType: string;
    object: string;
    objectType: string;
    outOrIn: string;
};

export const ObjectConnections = (props: TObjectConnectionsProps) => {
    const { objectNodeId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const tableData: TObjectConnectionsData[] = useSelector(
        ObjectConnectionsSelectors.getObjectConnectionsDataByNodeId(objectNodeId),
    );
    const onClickLink = (modelId: string) => {
        if (modelId) {
            dispatch(objectDecompositionOpen({ nodeId: { ...objectNodeId, id: modelId }, type: TreeItemType.Model }));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(objectConnectionsDelete(objectNodeId));
        };
    }, []);

    const dataSource: TDataSource[] = tableData.map((data) => ({
        ...data,
        modelName: (
            <EditableText
                className={theme.linkEditableTextContainer}
                text={data.modelName || ''}
                isEditing={false}
                disabled={false}
                onClickLink={() => onClickLink(data.modelId)}
                isUrlType={true}
            />
        ),
    }));

    const columns: ColumnProps<any>[] = [
        {
            dataIndex: 'modelName',
            key: 'modelName',
            title: intl.formatMessage(messages.model),
            width: '30%',
        },
        {
            dataIndex: 'edgeType',
            key: 'edgeType',
            title: intl.formatMessage(messages.edgeType),
        },
        {
            dataIndex: 'object',
            key: 'object',
            title: intl.formatMessage(messages.object),
        },
        {
            dataIndex: 'objectType',
            key: 'objectType',
            title: intl.formatMessage(messages.objectType),
        },
        {
            dataIndex: 'outOrIn',
            key: 'outOrIn',
            title: '',
            width: 50,
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            size="middle"
            bordered
            pagination={false}
            className={theme.table}
            scroll={{
                y: 'max-content',
            }}
        />
    );
};

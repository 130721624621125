import React from 'react';
import { useIntl } from 'react-intl';
import { PickOut, PickOutIcons } from '../../../../../../models/pick-out';
import messages from './PickOutDropdown.messages';
import { useDispatch, useSelector } from 'react-redux';
import { pickOutAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { ToolbarDropdown } from '../../../../../UIKit/components/Toolbar/ToolbarDropdown/ToolbarDropdown.component';

export const PickOutDropdown = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const tooltip: string = useSelector(EditorToolbarSelectors.getPickOutDropdownTooltip);

    const actions: JSX.Element[] = Object.getOwnPropertyNames(PickOut)
        .filter((prop) => isNaN(parseInt(prop)))
        .filter((key) => key !== 'PickDefault')
        .map((key) => {
            return (
                <ToolbarButton
                    icon={PickOutIcons[key]}
                    tooltip={intl.formatMessage(messages[key])}
                    onClick={() => dispatch(pickOutAction(PickOut[key]))}
                    dataTest={`graph-general-toolbar_select-${key}`}
                />
            );
        });

    return (
        <ToolbarDropdown
            options={actions}
            icon={PickOutIcons.PickDefault}
            size="small"
            tooltip={tooltip}
            dataTest="graph-general-toolbar_select-button"
        />
    );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tooltip__tooltip___ow95{min-width:-webkit-min-content;min-width:-moz-min-content;min-width:min-content}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Tooltip/Tooltip.scss"],"names":[],"mappings":"AAAA,yBACI,6BAAA,CAAA,0BAAA,CAAA,qBAAA","sourcesContent":[".tooltip {\r\n    min-width: min-content;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "Tooltip__tooltip___ow95"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import theme from './ButtonGroup.scss';
import classnames from 'classnames';
import { TButtonGroup } from './ButtonGroup.types';

export const ButtonGroup = (props: TButtonGroup) => {
    const { showBorder, withoutDividerSpace, children, onClickItem } = props;
    const buttonGroupClass: string = classnames(theme.container, {
        [theme.hideBorder]: !showBorder,
    });

    return (
        <div className={buttonGroupClass}>
            {Array.isArray(children) ? (
                children.map((child) => (
                    <div
                        className={classnames(withoutDividerSpace ? '' : theme.btnItemDivider, theme.btmItem)}
                        onClick={onClickItem}
                    >
                        {child}
                    </div>
                ))
            ) : (
                <div onClick={onClickItem} className={theme.btmItem}>
                    {children}
                </div>
            )}
        </div>
    );
};

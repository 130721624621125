// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FontAndFillingColor__container__Ayot9{padding:16px;border-bottom:1px solid var(--panelDivider)}.FontAndFillingColor__blockName__du6L1{font-size:14px;font-weight:400;font-family:\"Segoe UI\";line-height:22px;padding-bottom:8px}", "",{"version":3,"sources":["webpack://./src/modules/FormatPanel/components/Colors/FontAndFillingColor.scss"],"names":[],"mappings":"AAAA,uCACI,YAAA,CACA,2CAAA,CAGJ,uCACI,cAAA,CACA,eAAA,CACA,sBAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":[".container {\r\n    padding: 16px;\r\n    border-bottom: 1px solid var(--panelDivider);\r\n}\r\n\r\n.blockName {\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    font-family: 'Segoe UI';\r\n    line-height: 22px;\r\n    padding-bottom: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FontAndFillingColor__container__Ayot9",
	"blockName": "FontAndFillingColor__blockName__du6L1"
};
export default ___CSS_LOADER_EXPORT___;

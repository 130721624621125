import { defineMessages } from 'react-intl';

export default defineMessages({
    tab: {
        id: 'TabsList.tab',
        defaultMessage: 'Вкладка {number}',
    },
    tabName: {
        id: 'TabsList.tabName',
        defaultMessage: 'Название',
    },
    addTab: {
        id: 'TabsList.addTab',
        defaultMessage: 'Добавить вкладку',
    },
    tabNamePlaceholder: {
        id: 'TabsList.tabNamePlaceholder',
        defaultMessage: 'Введите название вкладки',
    },
    name: {
        id: 'TabsList.name',
        defaultMessage: 'Название',
    },
});

export const FETCH_GROUP_BYID_REQUEST = 'FETCH_GROUP_BYID_REQUEST';

export const FETCHED_GROUP_BYID_SUCCESS = 'FETCHED_GROUP_BYID_SUCCESS';

export const FETCH_ALL_GROUPS_REQUEST = 'FETCH_ALL_GROUPS_REQUEST';

export const FETCHED_ALL_GROUPS_SUCCESS = 'FETCHED_ALL_GROUPS_SUCCESS';

export const OPEN_EDITING_GROUPDATA_TAB = 'OPEN_EDITING_GROUPDATA_TAB';

export const SAVE_GROUP_MAINDATA_CHANGES = 'SAVE_GROUP_MAINDATA_CHANGES';

export const EDIT_GROUP_DATA_BEGIN = 'EDIT_GROUP_DATA_BEGIN';

export const EDIT_GROUP_DATA_END = 'EDIT_GROUP_DATA_END';

export const DELETE_GROUP = 'DELETE_GROUP';

export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';

export const SUBMIT_GROUP_DATA = 'SUBMIT_GROUP_DATA';

export const ADD_USERS_TO_GROUP_REQUEST = 'ADD_USERS_TO_GROUP_REQUEST';

export const REMOVE_USERS_FROM_GROUP = 'REMOVE_USERS_FROM_GROUP';

export const SET_IS_LOADING_GROUPS = 'SET_IS_LOADING_GROUPS';

import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import TreeItemIcon from '../../../Tree/components/TreeItem/TreeItemIcon.component';
import theme from './Workspace.scss';
import { IWorkspaceTabItemModelParams } from '../../../../models/tab.types';
import { convertNodeIdToString, getItemType, getSpecialIcon } from './Workspace.component.utils';
import { TreeItemType } from '../../../Tree/models/tree';
import { useIntl } from 'react-intl';
import modelTypeMessages from '../../../../models/modelType.messages';
import { WorkSpaceTabTypes } from '../../WorkSpaceTabTypesEnum';
import messages from '../../messages/HomePageTab.messages';
import { WorkspaceTabTitleContextMenu } from './WorkspaceTabTitleContextMenu.component';
import { TWorkspaceTabTitleProps } from '../../Workspace.types';
import { useContextMenuDublicateDelete } from '../../../../hooks/useContextMenuDublicateDelete';
import { Icon } from '../../../UIKit';
import NoIcon from '../../../../resources/icons/NoIcon.svg';
import closeIcon from '../../../../resources/icons/Close_Tabs.svg';
import { METHODOLOGY_SETTINGS_TYPES_ARR } from '../../workspaceTab.constants';
import { workspaceRemoveTabRequest } from '../../../../actions/tabs.actions';
import { useDispatch } from 'react-redux';
import { TOOLTIP_RC_ID } from '../../../UIKit/components/TooltipRC/TooltipRC.constants';

export const WorkspaceTabTitle = ({ tab, activeKey }: TWorkspaceTabTitleProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [isContextMenuVisible, setContextMenuVisible] = useContextMenuDublicateDelete(
        'WorkspaceTabTitle',
        tab.nodeId,
    );

    const showContextMenu = useCallback(() => {
        setContextMenuVisible(true);
    }, []);

    const hideContextMenu = useCallback(() => {
        setContextMenuVisible(false);
    }, []);

    useEffect(() => {
        return () => {
            const tabDropdown = document.querySelector('.ant-tabs-dropdown') as HTMLDivElement;
            if (tabDropdown) {
                tabDropdown.classList.add('ant-tabs-dropdown-hidden');
            }
        };
    }, []);

    const anyTypeContent = tab.content as any;
    const isActiveTab = convertNodeIdToString(activeKey) === convertNodeIdToString(tab.nodeId);
    let tooltipSuffix = '';

    if (anyTypeContent) {
        switch (anyTypeContent.type) {
            case TreeItemType.Matrix:
                tooltipSuffix = `[${intl.formatMessage(modelTypeMessages.MATRIX)}]`;
                break;
            case TreeItemType.Wiki:
                tooltipSuffix = `[${intl.formatMessage(modelTypeMessages.WIKI)}]`;
                break;
            case TreeItemType.Model:
                tooltipSuffix = anyTypeContent.modelTypeName ? `[${anyTypeContent.modelTypeName}]` : '';
                if ((tab?.params as IWorkspaceTabItemModelParams | undefined)?.modelType?.name) {
                    tooltipSuffix = `[${(tab.params as IWorkspaceTabItemModelParams).modelType?.name}]`;
                }
                break;
            case TreeItemType.Kanban:
                tooltipSuffix = `[${intl.formatMessage(modelTypeMessages.KANBAN)}]`;
                break;
            default:
                tooltipSuffix = '';
        }
    }

    const isHomePage = tab.type === WorkSpaceTabTypes.HOME_PAGE_TAB;
    const titleText = tab.title || intl.formatMessage(messages.homePageName);

    const onContextMenu = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        showContextMenu();
    }, []);

    const onHandleClick = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        dispatch(workspaceRemoveTabRequest(tab));
    };

    return (
        <WorkspaceTabTitleContextMenu tab={tab} visible={isContextMenuVisible} onVisibilityChange={hideContextMenu}>
            <div
                onContextMenu={onContextMenu}
                className={cx(theme.tabTitleContainer, isActiveTab && theme.activeTab, isHomePage && theme.homePageTab)}
                data-tooltip-id={TOOLTIP_RC_ID}
                data-tooltip-content={`${titleText} ${tooltipSuffix}`}
                data-tooltip-max-width={'none'}
                data-tooltip-place={'bottom'}
                data-tooltip-hidden={isContextMenuVisible}
            >
                <div className={theme.treeItemIcon}>
                    {METHODOLOGY_SETTINGS_TYPES_ARR.some(
                        (methodologySettingsTab: WorkSpaceTabTypes) => methodologySettingsTab === tab.type,
                    ) ? (
                        <Icon className={theme.entityIcon} spriteSymbol={NoIcon} />
                    ) : (
                        <TreeItemIcon
                            type={getItemType(tab.type, tab.content)}
                            nodeId={tab.nodeId}
                            specialIcon={getSpecialIcon(tab)}
                        />
                    )}
                </div>
                <div className={theme.tabTitleText} data-test="workspace-tab-name">
                    {titleText}
                </div>
                <div className={theme.closeIcon} data-test="close-tab_icon">
                    <Icon spriteSymbol={closeIcon} onClick={(e) => onHandleClick(e)} />
                </div>
            </div>
        </WorkspaceTabTitleContextMenu>
    );
};

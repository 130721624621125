import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icImage from '@/resources/icons/icImage.svg';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { openDialog } from '../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../DialogRoot/DialogRoot.constants';

export const AddImageButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getAddImageBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getaddImageBtnTooltip);

    const openImageDialog = () => {
        dispatch(openDialog(DialogType.IMAGE_UPLOAD_DIALOG));
    };

    return (
        <ToolbarButton
            icon={icImage}
            size="small"
            dataTest="general-panel-paste_image"
            disabled={disabled}
            onClick={openImageDialog}
            tooltip={tooltip}
        />
    );
};

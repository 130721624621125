import { InternationalString } from "@/serverapi/api";

export enum TAipPresetGroup {
    aliasTypes = 'aliasTypes',
    edgeTypes = 'edgeTypes',
    folderTypes = 'folderTypes',
    modelTypes = 'modelTypes',
    objectTypes = 'objectTypes',
    attributeTypes = 'attributeTypes'
};

export type TAipPresetItem = {
    id: string,
    multilingualName: InternationalString
};

export type TAipPresetData = Partial<Record<TAipPresetGroup, TAipPresetItem[]>>;

export type TFoundAipPresetItem = {
    group: TAipPresetGroup,
    item: TAipPresetItem,
    distance: number
};

export type TFoundInPreset = {
    items: TFoundAipPresetItem[],
    alternate?: TFoundAipPresetItem[],
    found: string,
    source: string
};

export type TSearchGroupsFilter = TAipPresetGroup[];

import React, { FC, memo, useEffect } from 'react';
import theme from './SearchResultWidget.scss';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'antd';
import { SearchListItem } from './SearchListItem.component';
import { TSearchResultWidgetProps } from '../widget.types';
import { searchWidgetSearchRequest } from '@/modules/UITemplates/actions/searchResultsWidget.actions';
import { SearchResultsWidgetSelectors } from '@/modules/UITemplates/selectors/searchResultsWidget.selectors';
import { TSearchDataListItem } from '@/reducers/search.reducer.types';
import cx from 'classnames';

export const SearchResultWidget: FC<TSearchResultWidgetProps> = memo(
    ({ templateId, widgetId, searchRequests, showElementType, showNodeType }) => {
        const dispatch = useDispatch();
        const searchData: TSearchDataListItem[] = useSelector(
            SearchResultsWidgetSelectors.filtredSearchData(
                templateId,
                widgetId,
                searchRequests?.[0]?.rootSearchNodeId || { id: '', repositoryId: '', serverId: '' },
            ),
        );

        const loading: boolean = useSelector(SearchResultsWidgetSelectors.loading(templateId, widgetId));

        useEffect(() => {
            dispatch(searchWidgetSearchRequest(templateId, widgetId, searchRequests || []));
        }, [searchRequests]);

        return (
            <div className={cx(theme.listContainer, { [theme.emptyData]: searchData.length === 0 })}>
                <List
                    itemLayout="vertical"
                    dataSource={searchData}
                    loading={loading}
                    renderItem={(item, index) => (
                        <List.Item>
                            <SearchListItem
                                index={index + 1}
                                path={item.path}
                                type={item.type}
                                multilingualName={item.multilingualName}
                                nodeId={item.nodeId}
                                elementType={item.elementType}
                                itemTypeName={item.itemTypeName}
                                deleted={item.deleted}
                                searchNodeId={{ id: '', repositoryId: '', serverId: '' }}
                                showElementType={showElementType}
                                showNodeType={showNodeType}
                            />
                        </List.Item>
                    )}
                />
            </div>
        );
    },
);

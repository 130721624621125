import type { MenuProps, DropDownProps } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import * as React from 'react';
import { Dropdown } from 'antd';
import theme from './contextMenu.scss';
import cn from 'classnames';
import { BPMMxPopupMenuType } from '@/mxgraph/components/BPMPopupMenu.types';

type TContextMenuProps = {
    visible: boolean;
    overlay: MenuProps['items'] | null;
    onClick?: (event: MenuInfo) => void;
    onEscape?: () => void;
    menuType?: BPMMxPopupMenuType;
} & Omit<DropDownProps, 'overlay'>;

export const ContextMenu = (props: TContextMenuProps) => {
    const {
        overlay,
        children,
        visible,
        placement,
        overlayClassName,
        trigger = ['click'],
        onClick,
        onEscape,
        onOpenChange,
        menuType,
    } = props;
    if (overlay === null) {
        return <div>{children}</div>;
    }

    const isEdgeTypeMenu = menuType === BPMMxPopupMenuType.EDGE_TYPE;

    return (
        <div className={theme.dropdownWrapper} onContextMenu={(e) => e.preventDefault()}>
            <Dropdown
                placement={placement}
                trigger={trigger}
                menu={{ items: overlay, onClick }}
                open={visible}
                onOpenChange={onOpenChange}
                className={theme.dropdownWrapper}
                overlayClassName={cn(theme.dropdownContainer, overlayClassName, {
                    [theme.edgeType]: isEdgeTypeMenu,
                })}
                dropdownRender={(menu) => {
                    return (
                        <div
                            className="tree-item_context-menu"
                            data-test="tree-item_context-menu"
                            onKeyDownCapture={(e) => {
                                if (isEdgeTypeMenu) {
                                    if (e.key === 'Escape') {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        onEscape?.();
                                    }
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (e.target instanceof HTMLElement) {
                                            e.target.click();
                                        }
                                    }
                                }
                            }}
                        >
                            {menu}
                        </div>
                    );
                }}
                destroyPopupOnHide
                align={{ overflow: { adjustY: true, shiftY: true, adjustX: true, shiftX: true } }}
            >
                {children}
            </Dropdown>
        </div>
    );
};

import React, { FC } from 'react';
import { ContextMenu } from '@/modules/ContextMenu/components/ContextMenu.component';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { MenuInfo } from 'rc-menu/es/interface';

type TMatrixContextMenuProps = {
    visible: boolean;
    menuItems: ItemType[];
    hideContextMenu: () => void;
    handleMenuAction: (event: MenuInfo) => void;
};

export const MatrixContextMenu: FC<TMatrixContextMenuProps> = ({
    visible,
    menuItems,
    hideContextMenu,
    handleMenuAction,
}) => {
    const onClickHandler = (event: MenuInfo) => {
        event.domEvent.stopPropagation();
        handleMenuAction(event);
        hideContextMenu();
    };

    return (
        <div>
            <ContextMenu
                trigger={['click']}
                overlay={menuItems}
                visible={visible}
                onOpenChange={hideContextMenu}
                onClick={onClickHandler}
            >
                <div />
            </ContextMenu>
        </div>
    );
};

import React, { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { SymbolConstants } from '../../../../../../../models/Symbols.constants';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { EdgeType, ModelEdgeDefinition, ModelType, ObjectType, Symbol } from '../../../../../../../serverapi/api';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import messages from '../ModelType.messages';
import style from './ModelEditEdgeDialog.component.scss';
import { useIntl } from 'react-intl';
import { Select } from '../../../../../../UIKit/components/Select/Select.component';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TEdgesTabProps = {
    modelType: ModelType;
    graph?: MethodologiesGraph;
    availableSymbols: Symbol[];
    availableEdgeType: EdgeType[];
    edgeDefinition?: ModelEdgeDefinition;
};

type TEdgesTabActionProps = {
    onSave: (modelTypeEdges: ModelEdgeDefinition) => void;
    onCancel: () => void;
};

type TModelEdgeDialogFullProps = TEdgesTabProps & TEdgesTabActionProps;

type TEndpointType = {
    SYMBOL: string;
    OBJECT_TYPE: string;
    ANY: string;
};

export const ModelEditEdgeDialog: FC<TModelEdgeDialogFullProps> = (props) => {
    const intl = useIntl();

    const { edgeDefinition, modelType, availableEdgeType, availableSymbols, onCancel, graph, onSave } = props;

    const EndpointType: TEndpointType = {
        SYMBOL: intl.formatMessage(messages.symbol),
        OBJECT_TYPE: intl.formatMessage(messages.objectType),
        ANY: intl.formatMessage(messages.any),
    };

    const sourceSymbol: string = edgeDefinition?.source || (availableSymbols && availableSymbols[0]?.id);
    const targetSymbol: string = edgeDefinition?.destination || (availableSymbols && availableSymbols[0]?.id);
    const sourceObject: string =
        edgeDefinition?.sourceObject || (modelType?.objectTypes && modelType.objectTypes[0]?.id);
    const targetObject: string =
        edgeDefinition?.destinationObject || (modelType?.objectTypes && modelType.objectTypes[0]?.id);
    const availableEdge: string = edgeDefinition?.edgeType || availableEdgeType[0]?.id;

    const [selectedSource, setSelectedSource] = useState<string>(sourceSymbol);
    const [selectedTarget, setSelectedTarget] = useState<string>(targetSymbol);
    const [selectedEdgeType, setSelectedEdgeType] = useState<string>(availableEdge);
    const [selectedSourceObject, setSelectedSourceObject] = useState<string>(sourceObject);
    const [selectedTargetObject, setSelectedTargetObject] = useState<string>(targetObject);

    const [sourceType, setSourceType] = useState<string>(
        edgeDefinition?.sourceObject
            ? EndpointType.OBJECT_TYPE
            : edgeDefinition?.anySourceAllowed
            ? EndpointType.ANY
            : EndpointType.SYMBOL,
    );
    const [targetType, setTargetType] = useState<string>(
        edgeDefinition?.destinationObject
            ? EndpointType.OBJECT_TYPE
            : edgeDefinition?.anyTargetAllowed
            ? EndpointType.ANY
            : EndpointType.SYMBOL,
    );

    const selectSource = (source: string) => {
        setSelectedSource(sourceType === EndpointType.SYMBOL ? source : selectedSource);
        setSelectedSourceObject(sourceType === EndpointType.OBJECT_TYPE ? source : selectedSourceObject);
    };

    const selectDestination = (destination: string) => {
        setSelectedTarget(targetType === EndpointType.SYMBOL ? destination : selectedTarget);
        setSelectedTargetObject(targetType === EndpointType.OBJECT_TYPE ? destination : selectedTargetObject);
    };

    const onChangeSelectEndPoint = (value: string) => {
        setSelectedEdgeType(value);
    };

    const symbols: Symbol[] = availableSymbols;
    const edgeTypes: EdgeType[] = availableEdgeType;

    const getSelectedObject = (id: string, isSource: boolean): Symbol | undefined => {
        const objectType: ObjectType | undefined = modelType.objectTypes.find((o) => o.id === id);

        if (!objectType && !edgeDefinition) return;

        if (!objectType && edgeDefinition)
            return {
                name: (isSource ? edgeDefinition.sourceObject : edgeDefinition.destinationObject) || '',
                ...SymbolConstants.ENDPOINT_SYMBOL,
            } as Symbol;

        return {
            ...objectType,
            ...SymbolConstants.ENDPOINT_SYMBOL,
            objectType: objectType?.id,
        } as Symbol;
    };

    const getSelectedSymbol = (id: string): Symbol | undefined => {
        const symbol: Symbol | undefined = symbols.find((o) => o.id === id);

        return symbol;
    };

    const getSelectedTargetBySelectedEndpointType = (): Symbol | undefined => {
        return targetType === EndpointType.OBJECT_TYPE
            ? getSelectedObject(selectedTargetObject, false)
            : getSelectedSymbol(selectedTarget);
    };

    const getSelectedSourceBySelectedEndpointType = (): Symbol | undefined => {
        return sourceType === EndpointType.OBJECT_TYPE
            ? getSelectedObject(selectedSourceObject, true)
            : getSelectedSymbol(selectedSource);
    };

    const renderEndpoint = (
        selectedTypeValue: string,
        selectedEndPointValue: Symbol | undefined,
        onChangeType: (value?: string) => void,
        onChangeEndpoint: (source: string) => void,
        title: string,
    ) => {
        return (
            <div className={style.selectEndPoint}>
                <Select label={title} value={selectedTypeValue} onChange={onChangeType} originalTheme>
                    {Object.keys(EndpointType).map((s) => (
                        <Select.Option key={EndpointType[s]} value={EndpointType[s]} label={EndpointType[s]} />
                    ))}
                </Select>
                <div className={style.selectedEndPointContainer}>
                    {selectedTypeValue !== EndpointType.ANY && (
                        <Select
                            value={
                                selectedEndPointValue
                                    ? SymbolToImageConverterGraph.convertSymbol(selectedEndPointValue, intl, graph)
                                    : ''
                            }
                            onChange={onChangeEndpoint}
                            originalTheme
                            wrapperClassName={style.svgSelect}
                            dropdownClassName={style.svgSelectDropdown}
                        >
                            {(selectedTypeValue === EndpointType.OBJECT_TYPE
                                ? modelType.objectTypes
                                      .reduce((acc: ObjectType[], ot) => {
                                          if (!acc.some((objectType) => objectType.id === ot.id)) {
                                              acc.push(ot);
                                          }

                                          return acc;
                                      }, [])
                                      .map((ot) => {
                                          return {
                                              ...ot,
                                              ...SymbolConstants.ENDPOINT_SYMBOL,
                                              objectType: ot.id,
                                          } as Symbol;
                                      })
                                : symbols
                            ).map((symbol: any) => (
                                <Select.Option
                                    value={
                                        selectedTypeValue === EndpointType.OBJECT_TYPE ? symbol.objectType : symbol.id
                                    }
                                    key={selectedTypeValue === EndpointType.OBJECT_TYPE ? symbol.objectType : symbol.id}
                                    label={SymbolToImageConverterGraph.convertSymbol(symbol, intl, graph)}
                                />
                            ))}
                        </Select>
                    )}
                </div>
            </div>
        );
    };

    const onOk = () => {
        const newEdge: ModelEdgeDefinition = {
            id: edgeDefinition?.id || uuid(),
            modelTypeId: modelType.id,
            edgeType: selectedEdgeType,
            destination: targetType === EndpointType.SYMBOL ? selectedTarget : undefined,
            source: sourceType === EndpointType.SYMBOL ? selectedSource : undefined,
            destinationObject: targetType === EndpointType.OBJECT_TYPE ? selectedTargetObject : undefined,
            sourceObject: sourceType === EndpointType.OBJECT_TYPE ? selectedSourceObject : undefined,
            anySourceAllowed: sourceType === EndpointType.ANY,
            anyTargetAllowed: targetType === EndpointType.ANY,
        };

        onSave(newEdge);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'ok',
                    onClick: onOk,
                    value: intl.formatMessage(messages.saveButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    const getSelectEdgeTypeValue = (): JSX.Element | '' => {
        const edgeType: EdgeType | undefined = edgeTypes.find((e) => e.id === selectedEdgeType);

        if (!edgeType) return '';

        return (
            <div className={style.selectEdge}>
                <div className={style.selectEdgeText} data-test={`edge-type-select-option-${edgeType.name}`}>
                    <span>{edgeType.name}</span>
                </div>
                <div>{SymbolToImageConverterGraph.convertEdge(edgeType, intl, graph)}</div>
            </div>
        );
    };

    return (
        <Dialog
            className={style.dialog}
            open
            onOk={onOk}
            onCancel={onCancel}
            title={intl.formatMessage(messages.editingEdge)}
            footer={footer}
        >
            <div className={style.edgeTypeDialogSelectRow}>
                {renderEndpoint(
                    sourceType,
                    getSelectedSourceBySelectedEndpointType(),
                    (value: string) => setSourceType(value),
                    selectSource,
                    intl.formatMessage(messages.source),
                )}
                <div className={style.selectEndPoint}>
                    <Select
                        label={intl.formatMessage(messages.edgeType)}
                        value={getSelectEdgeTypeValue()}
                        onChange={onChangeSelectEndPoint}
                        originalTheme
                        wrapperClassName={style.svgSelect}
                        dropdownClassName={style.svgSelectDropdown}
                    >
                        {edgeTypes.map((edgeType) => {
                            return (
                                <Select.Option
                                    key={edgeType.id}
                                    value={edgeType.id}
                                    label={
                                        <div className={style.selectOption}>
                                            {edgeType.name}
                                            {SymbolToImageConverterGraph.convertEdge(edgeType, intl, graph)}
                                        </div>
                                    }
                                />
                            );
                        })}
                    </Select>
                </div>
                {renderEndpoint(
                    targetType,
                    getSelectedTargetBySelectedEndpointType(),
                    (value: string) => setTargetType(value),
                    selectDestination,
                    intl.formatMessage(messages.target),
                )}
            </div>
        </Dialog>
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'MoveToDropdownDialog.title',
        defaultMessage: 'Перейти',
    },
    decomposition: {
        id: 'MoveToDropdownDialog.decomposition',
        defaultMessage: 'Декомпозиция',
    },
    objectInTree: {
        id: 'MoveToDropdownDialog.objectInTree',
        defaultMessage: 'Найти в Навигаторе',
    },
});

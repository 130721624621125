import { defineMessages } from 'react-intl';

export default defineMessages({
    model: {
        id: 'ObjectConnections.model',
        defaultMessage: 'Модель',
    },
    edgeType: {
        id: 'ObjectConnections.edgeType',
        defaultMessage: 'Тип связи',
    },
    object: {
        id: 'ObjectConnections.object',
        defaultMessage: 'Объект',
    },
    objectType: {
        id: 'ObjectConnections.objectType',
        defaultMessage: 'Тип объекта',
    },
    outGoingEdge: {
        id: 'ObjectConnections.outGoingEdge',
        defaultMessage: 'исх.',
    },
    inGoingEdge: {
        id: 'ObjectConnections.inGoingEdge',
        defaultMessage: 'вх.',
    },
    invisibleEdge: {
        id: 'ObjectConnections.invisibleEdge',
        defaultMessage: '(невидимая)',
    },
});

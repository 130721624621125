import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'SearchResultSidePanel.title',
        defaultMessage: 'Настройки поиска',
    },
    ID: {
        id: 'SearchResultSidePanel.ID',
        defaultMessage: 'ID',
    },
    widgetName: {
        id: 'SearchResultSidePanel.widgetName',
        defaultMessage: 'Имя виджета',
    },
    namePlaceholder: {
        id: 'SearchResultSidePanel.namePlaceholder',
        defaultMessage: 'Введите имя',
    },
    searchTerms: {
        id: 'SearchResultSidePanel.searchTerms',
        defaultMessage: 'Условия поиска',
    },
    clearSearchTerms: {
        id: 'SearchResultSidePanel.clearSearchTerms',
        defaultMessage: 'Очистить условия поиска',
    },
    clearSearchTermsQuestion: {
        id: 'SearchResultSidePanel.clearSearchTermsQuestion',
        defaultMessage: 'Очистить условия поиска?',
    },
    clear: {
        id: 'SearchResultSidePanel.clear',
        defaultMessage: 'Очистить',
    },
    showNodeType: {
        id: 'SearchResultSidePanel.showNodeType',
        defaultMessage: 'Показывать тип узла',
    },
    showElementType: {
        id: 'SearchResultSidePanel.showElementType',
        defaultMessage: 'Показывать тип элемента',
    },
});

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeId } from '../../../../../../serverapi/api';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { workspaceTabSetAction } from '../../../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';
import iconRedo from '@/resources/icons/ic-app-redo.svg';
import { redoAction } from '../../../../../../actions/undoRedo.actions';
import { UndoRedoSelectors } from '../../../../../../selectors/undoRedo.selectors';
import { useIntl } from 'react-intl';
import messages from '@/modules/MainMenu/messages/CommonToolbar.messages';

export const RedoButton = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const activeTabNodeId: NodeId | undefined = useSelector(TabsSelectors.getActiveTabId);
    const { redo: enabled } = useSelector(UndoRedoSelectors.getUndoRedoStatus(activeTabNodeId));

    const onRedoAction = (activeTabNodeId: NodeId) => {
        dispatch(redoAction(activeTabNodeId));
        dispatch(
            workspaceTabSetAction({
                nodeId: activeTabNodeId,
                name: 'redo',
                value: true,
            }),
        );
    };
    const onRedo = useCallback(() => {
        if (activeTabNodeId) onRedoAction(activeTabNodeId);
    }, [activeTabNodeId, onRedoAction]);

    return (
        <ToolbarButton
            disabled={!enabled}
            icon={iconRedo}
            size="small"
            onClick={onRedo}
            dataTest="header_action-buttons-redo"
            tooltip={enabled ? intl.formatMessage(messages.redo) : ''}
        />
    );
};

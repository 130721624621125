// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MessageContentDialog__dialogContainer__mc6QL .ant-modal-title{font-size:20px;font-weight:600;line-height:28px;color:var(--grey-dark-UIKit)}.MessageContentDialog__dialogContainer__mc6QL .ant-modal-body{font-size:14px}.MessageContentDialog__dialogContainer__mc6QL .MessageContentDialog__subject__XnbO5{font-weight:bold;margin-bottom:10px}.MessageContentDialog__dialogContainer__mc6QL .MessageContentDialog__template__gr5_Z{white-space:pre-wrap}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/MessageContentDialog/MessageContentDialog.scss"],"names":[],"mappings":"AAEM,+DACI,cAAA,CACA,eAAA,CACA,gBAAA,CACA,4BAAA,CAEJ,8DACE,cAAA,CAGN,oFACE,gBAAA,CACA,kBAAA,CAEF,qFACE,oBAAA","sourcesContent":[".dialogContainer {\r\n  :global {\r\n      .ant-modal-title {\r\n          font-size: 20px;\r\n          font-weight: 600;\r\n          line-height: 28px;\r\n          color: var(--grey-dark-UIKit);\r\n      }\r\n      .ant-modal-body {\r\n        font-size: 14px;\r\n      }\r\n  }\r\n  .subject {\r\n    font-weight: bold;\r\n    margin-bottom: 10px;\r\n  }\r\n  .template {\r\n    white-space: pre-wrap;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": "MessageContentDialog__dialogContainer__mc6QL",
	"subject": "MessageContentDialog__subject__XnbO5",
	"template": "MessageContentDialog__template__gr5_Z"
};
export default ___CSS_LOADER_EXPORT___;

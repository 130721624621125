import React, { FC, useRef, useState } from 'react';
import theme from './MultiLangStringDialogComponent.scss';
import { Form, Input, InputRef, Modal, Tooltip } from 'antd';
import footerMessages from '../../Footer/FooterButtons.messages';
import messages from '../messages/ObjectPropertiesDialog.messages';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { useIntl } from 'react-intl';
import { TAttributeUrlValues } from './AttributeTab.types';
import { AttributeValue, AttributeValueUrl, InternationalString } from '../../../serverapi/api';
import { shouldCallModalSubmitHandler } from '../../../services/utils/ModalHelper';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import icAttention from '@/resources/icons/properties.svg';
import { Icon } from '@/modules/UIKit';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { LocalesService } from '@/services/LocalesService';

type TUrlValidateFieldValues = {
    url: Required<InternationalString>;
    name: Required<InternationalString>;
};

type TMultiLangStringDialogProps = {
    handleOk: (changedValues: TAttributeUrlValues) => void;
    record: AttributeValue;
};

export const MultiLangUrlDialogComponent: FC<TMultiLangStringDialogProps> = ({ handleOk, record }) => {
    const currentLocale = useSelector(getCurrentLocale);
    const inputRef = useRef<InputRef>(null);
    const intl = useIntl();
    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const onValidateFields = () => {
        form.validateFields().then((formValues: TUrlValidateFieldValues) => {
            const { name, url } = formValues;

            const changedValues: TAttributeUrlValues = {
                name: { ru: name.ru?.trim(), en: name.en?.trim(), es: name.es?.trim() },
                url: { ru: url.ru, en: url.en, es: url.es },
            };
            handleOk(changedValues);
            setIsModalVisible(false);
        });
    };

    const showModal = (attrValue: AttributeValueUrl) => {
        const { url, name } = attrValue;

        form.resetFields();
        form.setFieldsValue({
            url,
            name,
        });

        setIsModalVisible(true);
        setTimeout(() => inputRef.current?.focus(), 10);
    };

    const cancelModal = () => {
        setIsModalVisible(false);
    };

    const customFooter = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'back',
                    onClick: () => setIsModalVisible(false),
                    value: intl.formatMessage(footerMessages.cancel),
                    dataTest: 'translation-link-window_button-cancel',
                },
                {
                    key: 'ok',
                    onClick: onValidateFields,
                    value: intl.formatMessage(footerMessages.save),
                    visualStyle: 'primary',
                    dataTest: 'translation-link-window_button-ok',
                },
            ]}
        />
    );

    const tooltipTitle = (
        <>
            <div>{intl.formatMessage(messages.linkHintAllowedText)}</div>
            <div>{intl.formatMessage(messages.linkHint)}</div>
            <br />
            <div>- file:///...</div>
            <div>- C:\...</div>
            <div>- \root...</div>
            <div>- Macintosh HD/...</div>
            <div>{intl.formatMessage(messages.others)}</div>
        </>
    );

    const inputSuffix = (
        <Tooltip title={tooltipTitle} mouseLeaveDelay={0}>
            <span className={theme.iconContainer}>
                <Icon spriteSymbol={icAttention} />
            </span>
        </Tooltip>
    );

    const getNameInputLabel = (locale: Locale) => LocalesService.getMessagesByLocale(locale)[messages.multiLangUrlDialogName?.id];
    const getUrlInputLabel = (locale: Locale) => LocalesService.getMessagesByLocale(locale)[messages.multiLangUrlDialogUrl?.id];

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    onValidateFields();
                }
            }}
            onKeyDown={(e) => {
                if (isModalVisible && e.key === 'Tab') {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }}
        >
            <span
                aria-selected="true"
                tabIndex={0}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        showModal(record);
                        e.stopPropagation();
                    }
                }}
                className={theme.attribute_openMultiLangBtn}
                onClick={() => showModal(record)}
            >
                <u data-test="URL_change-links-for-two-locale">({currentLocale.toUpperCase()})</u>
            </span>
            <Modal
                title={intl.formatMessage(messages.multiLangUrlDialogHeader)}
                open={isModalVisible}
                onCancel={cancelModal}
                className={theme.dialog}
                footer={customFooter}
            >
                <Form autoComplete="off" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={form}>
                    {Object.values(Locale).map((locale) => (
                        <div key={locale}>
                            <Form.Item
                                name={['name', locale]}
                                label={getNameInputLabel(locale)}
                                rules={[{ required: false }]}
                            >
                                <Input
                                    className={theme.input}
                                    data-test={`attribute-LINK_name-${locale.toUpperCase()}_input`}
                                    suffix={inputSuffix}
                                    ref={locale === Locale.ru ? inputRef : undefined}
                                />
                            </Form.Item>
                            <Form.Item
                                name={['url', locale]}
                                label={getUrlInputLabel(locale)}
                                rules={[{ required: false }]}
                            >
                                <Input
                                    className={theme.input}
                                    data-test={`attribute-LINK_URL-${locale.toUpperCase()}_input`}
                                    suffix={inputSuffix}
                                />
                            </Form.Item>
                        </div>
                    ))}
                </Form>
            </Modal>
        </div>
    );
};

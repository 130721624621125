import { Form, FormInstance, Input, Row, Select, Tooltip } from 'antd';
import React, { FC } from 'react';
import messages from './AddEventDialog.messages';
import actionTypesMessages from '../../AdminTools/ActionsAudit/actionTypes.messages';
import theme from './AddEventDialog.scss';
import { useIntl } from 'react-intl';
import { TEvent, TEventDialogNodeParams } from '../../../reducers/eventsAccess.reducer.types';
import { PrincipalDescriptor } from '../../../serverapi/api';
import { EventActions, EventRunStrategy } from '../../../modules/consts/movelEvents';
import { PrincipalSelect } from '../../UIKit/components/PrincipalSelect/PrincipalSelect.component';
import { EventFormItemsNames } from './AddEvent.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import icEdit from '@/resources/icons/ic-edit.svg';
import icDelete from '@/resources/icons/Deleted.svg';
import icInfo from '@/resources/icons/ic-info.svg';
import { Icon } from '@/modules/UIKit';

type TEventDialogFormProps = {
    form: FormInstance<any>;
    editing: boolean;
    event: TEvent;
    userList: PrincipalDescriptor[];
    groupList: PrincipalDescriptor[];
    nodeParams: TEventDialogNodeParams;
    onAddNode: (paramName: string) => void;
    onAddScript: () => void;
    onDeleteNodeId: () => void;
    onDeleteParentNodeId: () => void;
    onDeleteScriptNodeId: () => void;
};

export const EventDialogForm: FC<TEventDialogFormProps> = (props) => {
    const {
        form,
        editing,
        event,
        userList,
        groupList,
        nodeParams,
        onAddNode,
        onAddScript,
        onDeleteParentNodeId,
        onDeleteNodeId,
        onDeleteScriptNodeId,
    } = props;
    const intl = useIntl();

    const getLabel = (label: string, tooltipText: string) => {
        return (
            <div className={theme.labelContainer}>
                <span>{label}</span>

                <Tooltip mouseLeaveDelay={0} title={tooltipText}>
                    <span>
                        <Icon spriteSymbol={icInfo} />
                    </span>
                </Tooltip>
            </div>
        );
    };

    return (
        <>
            <Form autoComplete="off" form={form} layout="vertical" className={theme.eventFormWrapper}>
                <Form.Item
                    label={getLabel(intl.formatMessage(messages.eventNameLabel), intl.formatMessage(messages.nameHint))}
                    name={EventFormItemsNames.name}
                    initialValue={editing ? event.name : ''}
                    rules={[
                        { required: true, whitespace: true, message: intl.formatMessage(messages.eventRequiredFields) },
                    ]}
                    data-test="add-event-dialog_name"
                >
                    <Input placeholder={intl.formatMessage(messages.eventNamePlaceholder)} />
                </Form.Item>
                <Form.Item
                    initialValue={editing ? event.eventRunStrategy : EventRunStrategy.BEFORE}
                    label={getLabel(
                        intl.formatMessage(messages.eventStrategyLabel),
                        intl.formatMessage(messages.strategyHint),
                    )}
                    name={EventFormItemsNames.eventRunStrategy}
                    rules={[
                        {
                            whitespace: true,
                            message: intl.formatMessage(messages.eventRunStrategyRequiredError),
                        },
                    ]}
                    data-test="add-event-dialog_strategy"
                >
                    <Select>
                        {(Object.keys(EventRunStrategy) as Array<keyof typeof EventRunStrategy>).map((strategy) => {
                            return (
                                <Select.Option key={strategy} value={strategy}>
                                    {messages[strategy] ? intl.formatMessage(messages[strategy]) : strategy}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    initialValue={editing ? event.eventActions : EventActions.READ_TREE_NODE}
                    label={getLabel(
                        intl.formatMessage(messages.eventActionsLabel),
                        intl.formatMessage(messages.actionHint),
                    )}
                    name={EventFormItemsNames.eventActions}
                    rules={[
                        {
                            whitespace: true,
                            message: intl.formatMessage(messages.eventActionsRequiredError),
                        },
                    ]}
                    data-test="add-event-dialog_action"
                >
                    <Select>
                        {(Object.keys(EventActions) as Array<keyof typeof EventActions>).map((actions) => {
                            return (
                                <Select.Option key={actions} value={actions}>
                                    {actionTypesMessages[actions]
                                        ? intl.formatMessage(actionTypesMessages[actions])
                                        : actions}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <PrincipalSelect
                    initPrincipal={editing ? event.userId : ''}
                    label={getLabel(
                        intl.formatMessage(messages.eventUserIdLabel),
                        intl.formatMessage(messages.userHint),
                    )}
                    principalList={userList}
                    formItemName={EventFormItemsNames.userId}
                    dataTest="add-event-dialog_user"
                />
                <PrincipalSelect
                    initPrincipal={editing ? event.groupId : ''}
                    label={getLabel(
                        intl.formatMessage(messages.eventGroupIdLabel),
                        intl.formatMessage(messages.groupHint),
                    )}
                    principalList={groupList}
                    formItemName={EventFormItemsNames.groupId}
                    dataTest="add-event-dialog_group"
                />
                <Form.Item
                    className={theme.field}
                    label={getLabel(
                        intl.formatMessage(messages.eventNodeIdLabel),
                        intl.formatMessage(messages.elementHint),
                    )}
                    name={EventFormItemsNames.nodeId}
                    data-test="add-event-dialog_node"
                >
                    <Input.Group>
                        <Row justify="space-between" className={theme.row}>
                            <Input
                                className={theme.node}
                                type="text"
                                disabled
                                defaultValue={editing ? event.eventNodeElementName : nodeParams.nodeId?.path}
                                value={nodeParams.nodeId?.path}
                            />
                            <Button icon={icEdit} onClick={() => onAddNode('nodeId')} />
                            {(event?.nodeId || nodeParams.nodeId?.path) && (
                                <Button icon={icDelete} onClick={onDeleteNodeId} />
                            )}
                        </Row>
                    </Input.Group>
                </Form.Item>
                <Form.Item
                    className={theme.field}
                    label={getLabel(
                        intl.formatMessage(messages.eventParentNodeIdLabel),
                        intl.formatMessage(messages.elementParentHint),
                    )}
                    name={EventFormItemsNames.parentNodeId}
                    data-test="add-event-dialog_parent-node"
                >
                    <Row justify="space-between" className={theme.row}>
                        <Input
                            className={theme.node}
                            disabled
                            type="text"
                            defaultValue={editing ? event.eventParentNodeElementName : nodeParams.parentNodeId?.path}
                            value={nodeParams.parentNodeId?.path}
                        />
                        <Button icon={icEdit} onClick={() => onAddNode('parentNodeId')} />

                        {(event?.parentNodeId || nodeParams.parentNodeId?.path) && (
                            <Button icon={icDelete} onClick={onDeleteParentNodeId} />
                        )}
                    </Row>
                </Form.Item>
                <Form.Item
                    label={getLabel(
                        intl.formatMessage(messages.eventClassNameLabel),
                        intl.formatMessage(messages.eventHandlerHint),
                    )}
                    name={EventFormItemsNames.className}
                    initialValue={editing ? event.className : ''}
                    data-test="add-event-dialog_handler"
                >
                    <Input placeholder={intl.formatMessage(messages.eventClassNameLabel)} />
                </Form.Item>
                <Form.Item
                    label={getLabel(
                        intl.formatMessage(messages.eventParamsLabel),
                        intl.formatMessage(messages.parametersHint),
                    )}
                    name={EventFormItemsNames.params}
                    initialValue={editing ? event.params : ''}
                    data-test="add-event-dialog_parameter"
                >
                    <Input placeholder={intl.formatMessage(messages.eventParamsPlaceholder)} />
                </Form.Item>
                <Form.Item
                    className={theme.field}
                    label={getLabel(
                        intl.formatMessage(messages.eventScriptIdLabel),
                        intl.formatMessage(messages.scriptHint),
                    )}
                    name={EventFormItemsNames.scriptId}
                    data-test="add-event-dialog_script"
                >
                    <Row justify="space-between" className={theme.row}>
                        <Input
                            className={theme.node}
                            disabled
                            type="text"
                            defaultValue={editing ? event.eventScriptName : nodeParams.scriptId?.path}
                            value={nodeParams.scriptId?.path}
                        />
                        <Button icon={icEdit} onClick={() => onAddScript()} />

                        {(event?.scriptId || nodeParams.scriptId?.path) && (
                            <Button icon={icDelete} onClick={onDeleteScriptNodeId} />
                        )}
                    </Row>
                </Form.Item>
                <PrincipalSelect
                    initPrincipal={editing ? event.launchingScriptUserId : ''}
                    label={getLabel(
                        intl.formatMessage(messages.eventlaunchingScriptUserIdLabel),
                        intl.formatMessage(messages.userLaunchingScriptHint),
                    )}
                    formItemName={EventFormItemsNames.launchingScriptUserId}
                    principalList={userList}
                />
            </Form>
        </>
    );
};

import React from 'react';
import { NavigatorPanelButton } from '../NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { SeachIcon } from '../NavigatorPanel/icons/SearchIcon.component';
import { Tooltip } from 'antd';
import messages from '../../messages/Navigator.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeNavigatorSymbolsSearchStatus } from '@/actions/navigatorSymbolsSearch.actions';
import { NavigatorSymbolsSearchSelector } from '@/selectors/navigatorSymbolsSearch.selectors';

type TSymbolsSearchBtnProps = {
    repositoryId: string;
    id: string;
};

export const SymbolsSearchBtn = (props: TSymbolsSearchBtnProps) => {
    const { repositoryId, id } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const isActive: boolean = useSelector(NavigatorSymbolsSearchSelector.getIsSearchActive(repositoryId, id));

    const handleClick = () => {
        dispatch(
            changeNavigatorSymbolsSearchStatus({
                repositoryId,
                id,
                isActive: !isActive,
            }),
        );
    };

    return (
        <Tooltip mouseLeaveDelay={0} placement="top" title={intl.formatMessage(messages.searchSymbols)}>
            <>
                <NavigatorPanelButton
                    dataTest="navigator-panel_symbols_search_icon"
                    IconComponent={SeachIcon}
                    active={isActive}
                    onClick={handleClick}
                />
            </>
        </Tooltip>
    );
};


import { LocalesService } from '@/services/LocalesService';
import textBlock from '@/resources/icons/textBlock.svg';
import { TSidePanelWidget, WidgetTypes } from './WidgetsListSidePanel.types';
import messages from './WidgetsListSidePanel.messages';

const intl = LocalesService.useIntl();

export const sidePanelWidgets: TSidePanelWidget[] = [
    {
        id: 1,
        name: intl.formatMessage(messages.searchResults),
        type: WidgetTypes.SearchResult,
        icon: textBlock,
        widgetWidth: 20,
        widgetHeight: 10,
    },
];

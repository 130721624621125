import React, { useId } from 'react';
import classnames from 'classnames';
import theme from './Tooltip.scss';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { TTooltipeSettings } from './Tooltip.types';

type TTooltipProps = {
    tooltip?: string | TTooltipeSettings;
    children: JSX.Element;
};

/**
 * @param tooltip текст подсказки, либо объект с текстом подсказки, смещением и направлением
 * @param children оборачиваемый элемент
 */

export const Tooltip = (props: TTooltipProps) => {
    const { children, tooltip } = props;
    const id = useId();

    const hasTooltipStyle: boolean = typeof tooltip !== 'string';
    const tooltipPlacement: string =
        hasTooltipStyle && (tooltip as TTooltipeSettings)?.position
            ? (tooltip as TTooltipeSettings).position || 'top'
            : 'top';
    const tooltipOffset: number | undefined = hasTooltipStyle ? (tooltip as TTooltipeSettings)?.offset : undefined;

    const onVisibleChange = (isVisible: boolean) => {
        const element = document.getElementsByClassName(id)[0] as HTMLElement;

        if (element) {
            const tooltipeStyle: CSSStyleDeclaration = element.style;
            tooltipeStyle.top = `${parseFloat(tooltipeStyle.top) + (tooltipOffset || 0)}px`;
            tooltipeStyle.zIndex = isVisible ? '9999' : '-1';
            tooltipeStyle.whiteSpace = (tooltip as TTooltipeSettings).nowrap ? 'nowrap' : 'normal';
        }
    };

    return (
        <AntdTooltip
            mouseLeaveDelay={0}
            placement={tooltipPlacement as TooltipPlacement}
            afterOpenChange={onVisibleChange}
            overlayStyle={{ zIndex: tooltipOffset ? '-1' : '9999' }}
            autoAdjustOverflow={typeof tooltip === 'string'}
            title={hasTooltipStyle ? (tooltip as TTooltipeSettings)?.title : (tooltip as string)}
            overlayClassName={classnames(id, theme.tooltip)}
        >
            {children}
        </AntdTooltip>
    );
};

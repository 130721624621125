import React from 'react';
import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { StartScreen } from '../components/StartScreen.component';
import { TStartScreenProps } from '../StartScreen.types';
import { deleteServer } from '../../../actions/entities/servers.actions';
import { DialogRootContainer } from '../../DialogRoot/DialogRoot.container';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { login, loginFailed } from '../../../actions/authorization.actions';
import { getAuthorizationState } from '../../../selectors/authorization.selectors';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { HeaderContainer } from '../../Header/containers/Header.container';
import { TMessage } from '../../../utils/forms.types';
import { LocalesService } from '../../../services/LocalesService';
import { KeycloakSettingsSelectors } from '@/selectors/keycloakSettings.selectors';
import { KeycloakSettings } from '@/serverapi/api';

const mapStateToProps = (state: TRootState, props: Partial<TStartScreenProps>): Partial<TStartScreenProps> => {
    const availableServers = ServerSelectors.serverList(state);
    const authState = getAuthorizationState(state);
    const keycloakSettings: KeycloakSettings | undefined = KeycloakSettingsSelectors.getKeycloakSettings(state);

    return {
        header: <HeaderContainer />,
        availableServers,
        error: authState.error,
        pending: authState.pending,
        dialogRoot: <DialogRootContainer />,
        keycloakSettings,
    };
};

const mapDispatchToProps = (dispatch): Partial<TStartScreenProps> => ({
    onServerEdit: (id) =>
        dispatch(
            openDialog(DialogType.SERVER_EDIT, {
                entityId: id,
            }),
        ),
    onServerAdd: () => dispatch(openDialog(DialogType.SERVER_EDIT)),
    onSubmit: (form) =>
        dispatch(login(form.id, form.url, form.serverName, form.username, form.password, form.remember, false)),
    onDelete: (id) => dispatch(deleteServer(id)),
    setErrorMessage: (error: TMessage) => {
        const intl = LocalesService.useIntl();
        dispatch(loginFailed(intl.formatMessage(error)));
    },
});

export const StartScreenContainer = connect(mapStateToProps, mapDispatchToProps)(StartScreen);

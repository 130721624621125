export const KANBAN_CREATE = 'KANBAN_CREATE';
export const KANBAN_CREATE_FAILURE = 'KANBAN_CREATE_FAILURE';
export const KANBAN_CREATE_SUCCESS = 'KANBAN_CREATE_SUCCESS';
export const CHANGE_KANBAN_DATA = 'CHANGE_KANBAN_DATA';
export const KANBAN_ADD = 'KANBAN_ADD';
export const KANBAN_OPEN_BY_ID = 'KANBAN_OPEN_BY_ID';
export const KANBAN_REMOVE = 'KANBAN_REMOVE';
export const KANBAN_MOVE_ITEM = 'KANBAN_MOVE_ITEM';
export const KANBAN_SET_NODE = 'KANBAN_SET_NODE';
export const KANBAN_RENAME = 'KANBAN_RENAME';

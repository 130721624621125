import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { GroupDTO } from '../serverapi/api';
import { MetaDataSelectors } from './admintools.selectors';
import { TGroupsState } from '../reducers/groups.reducer.types';

export const groupsState = (state: TRootState) => state.groups;

export namespace GroupsSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(groupsState, (state) => state.byServerId && state.byServerId[serverId]);

    export const byId = (serverId: string, id: number) =>
        createSelector(byServerId(serverId), (state) => (state && state.byId && state.byId[id]) || <GroupDTO>{});

    export const getAll = (serverId: string) =>
        createSelector(byServerId(serverId), (state) =>
            state && state.byId ? (Object.values(state.byId) as Array<GroupDTO>) : Array<GroupDTO>(),
        );

    export const getAllGroups = createSelector<TRootState, string, TGroupsState, GroupDTO[]>(
        MetaDataSelectors.getCurrentServerId,
        groupsState,
        (serverId, state) => {
            const groups = state.byServerId && state.byServerId[serverId];

            return groups && groups.byId ? (Object.values(groups.byId) as Array<GroupDTO>) : Array<GroupDTO>();
        },
    );

    export const getIsLoadingGroups = createSelector<TRootState, TGroupsState, boolean>(groupsState, (state) => {
        return !!state.isLoading;
    });

    export const getEditing = (userId: string) =>
        createSelector(groupsState, (state) => (state && state.groupEdit.byId[userId]) || <GroupDTO>{});
}

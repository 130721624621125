export const PRESET_SETTINGS_CREATE_WIKI_MODEL_TYPE = 'PRESET_SETTINGS_CREATE_WIKI_MODEL_TYPE';

export const PRESET_SETTINGS_SUBMIT_WIKI_MODEL_TYPE = 'PRESET_SETTINGS_SUBMIT_WIKI_MODEL_TYPE';

export const PRESET_SETTINGS_GET_ALL_WIKI_MODEL_TYPES_SUCCESS = 'PRESET_SETTINGS_GET_ALL_WIKI_MODEL_TYPES_SUCCESS';

export const PRESET_SETTINGS_ADD_WIKI_MODEL_TYPE = 'PRESET_SETTINGS_ADD_WIKI_MODEL_TYPE';

export const PRESET_SETTINGS_DELETE_WIKI_MODEL_TYPE = 'PRESET_SETTINGS_DELETE_WIKI_MODEL_TYPE';

export const PRESET_SETTINGS_TRANSFER_WIKI_MODEL_TYPE = 'PRESET_SETTINGS_TRANSFER_WIKI_MODEL_TYPE';

export const PRESET_SETTINGS_EDIT_WIKI_MODEL_TYPE = 'PRESET_SETTINGS_EDIT_WIKI_MODEL_TYPE';

import { NodeId, UserDTO, ModelType, ObjectDefinitionNode, ModelNode } from '../serverapi/api';
import { cancelled, put, select, take, takeEvery } from 'redux-saga/effects';
import { EDITOR_INIT } from '../actionsTypes/editor.actionTypes';
import { TEditorInitAction } from '../actions/editor.actions.types';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { MxCell, MxPopupMenu } from '../mxgraph/mxgraph';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { eventChannel } from 'redux-saga';
import { TAction } from '../actions/index.actions.types';
import { BPMMxPopupMenuHandler } from '../mxgraph/BPMGraphClasses';
import { modelContextByGraphId } from './utils';
import { IModelContext } from './utils.types';
import { getUser } from '../selectors/authorization.selectors';
import { ACTIVE_CONTEXT_MENU_CHANGE } from '../actionsTypes/contextMenu.actionTypes';
import { TContextMenuChangeAction } from '../actions/contextMenu.actions.types';
import { getActiveGraph } from '../selectors/editor.selectors';
import { treeItemSelect } from '../actions/tree.actions';
import { getStore } from '../store';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { Locale } from '../modules/Header/components/Header/header.types';
import { ModelSelectors } from '../selectors/model.selectors';
import { createDefaultMenu, createEdgeTypesMenu } from './editorPopupMenu.saga.utils';
import { SequenceGraph } from '@/mxgraph/SequenceGraph/SequenceGraph';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';

type TPopupMenuParams = {
    graph: BPMMxGraph;
    user: UserDTO;
    emitter: any; // tslint:disable-line:no-any
    modelType: ModelType;
    getObjectNode: (nodeId: NodeId) => ObjectDefinitionNode | undefined;
    locale: Locale;
    parentNodeId?: NodeId;
    printable?: boolean;
};

function createPopupMenuHandler({
    graph,
    user,
    emitter,
    modelType,
    getObjectNode,
    locale,
    parentNodeId,
    printable,
}: TPopupMenuParams): BPMMxPopupMenuHandler {
    const createPopupMenu = (graph: BPMMxGraph | SequenceGraph, menu: MxPopupMenu, cell: MxCell, evt: Event) => {
        graph.hidePopupMenuHandler(menu);

        if (evt instanceof KeyboardEvent) {
            if ((evt.ctrlKey || evt.metaKey) && evt.altKey && evt.code === 'KeyR') {
                createEdgeTypesMenu(menu, graph, cell, emitter);
                return;
            }
        }

        createDefaultMenu(menu, graph, cell, user, emitter, modelType, getObjectNode, locale, parentNodeId, printable);
    };

    return graph.createPopupMenuHandler(createPopupMenu);
}

function* handleEditorPopupMenuInit({ payload: { nodeId } }: TEditorInitAction) {
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(nodeId);
    if (!graph) {
        console.error(`Graph doesn't exist`);

        return;
    }
    const modelContext: IModelContext = yield modelContextByGraphId(nodeId);
    const parentNodeId = modelContext.schema.content && modelContext.schema.content.parentNodeId;
    const user: UserDTO | undefined = yield select(getUser);

    if (!user) return;

    const model: ModelNode | undefined = yield select(ModelSelectors.byId(nodeId));
    const getNode = (objectNodeId: NodeId) => ObjectDefinitionSelectors.byId(objectNodeId)(getStore().getState());
    const locale: Locale = yield select(getCurrentLocale);

    const channel = eventChannel<TAction>((emitter) => {
        const popupMenuHandler = createPopupMenuHandler({
            graph,
            user,
            emitter,
            modelType: graph.modelType!,
            getObjectNode: getNode,
            locale,
            parentNodeId,
            printable: model?.printable,
        });

        return () => {
            popupMenuHandler.destroy();
        };
    });
    try {
        while (true) {
            const action = yield take(channel);
            yield put(action);
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

function* handleActiveContextMenuChange({ payload: { selectedNode, treeName } }: TContextMenuChangeAction) {
    const isNodeSelected: boolean = yield select(SelectedNodesSelector.isNodeSelected(selectedNode?.nodeId));

    if (selectedNode && selectedNode.nodeId) {
        if (!isNodeSelected) {
            yield put(treeItemSelect(selectedNode, treeName));
        }
    }

    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (graph && graph.popupMenuHandler && graph.popupMenuHandler.isMenuShowing()) {
        graph.popupMenuHandler.hideMenu();
    }
}

export function* editorPopupMenuSaga() {
    yield takeEvery(EDITOR_INIT, handleEditorPopupMenuInit);
    yield takeEvery(ACTIVE_CONTEXT_MENU_CHANGE, handleActiveContextMenuChange);
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UITemplatesPreview__widgetsContainer__dQecr{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;height:100%}.UITemplatesPreview__scrollContainer__OeV5o{position:relative;width:100%;height:100%;overflow-y:scroll;overflow-x:hidden}", "",{"version":3,"sources":["webpack://./src/modules/UITemplates/components/UITemplatesTab/UITemplatesPreview/UITemplatesPreview.scss"],"names":[],"mappings":"AAAA,6CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,WAAA,CAGJ,4CACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":[".widgetsContainer {\r\n    display: flex;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.scrollContainer {\r\n    position: relative;\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow-y: scroll;\r\n    overflow-x: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetsContainer": "UITemplatesPreview__widgetsContainer__dQecr",
	"scrollContainer": "UITemplatesPreview__scrollContainer__OeV5o"
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC, useEffect, useRef, useState } from 'react';
import theme from './Dropdown.scss';
import Portal from '../../Portal/Portal.component';
import { TDropdownItem } from './Dropdown.types';
import icon from '@/resources/icons/dotsGrey.svg';
import { Icon } from '../../Icon/Icon.component';

type TDropdownProps = {
    items: TDropdownItem[];
};

export const Dropdown: FC<TDropdownProps> = ({ items }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => {
        setIsVisible(true);
        updatePosition();
    };

    const handleMouseLeave = () => {
        setIsVisible(false);
    };

    const updatePosition = () => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            setPosition({
                top: rect.bottom,
                left: Math.min(rect.left, window.innerWidth - 200),
            });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            setIsVisible(false);
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div
            className={theme.dropdownContainer}
            ref={dropdownRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className={theme.dropdownTrigger}>
                <Icon spriteSymbol={icon} />
            </div>

            <Portal container={document.body}>
                {isVisible && (
                    <div
                        className={theme.dropdownList}
                        style={{ position: 'fixed', top: position.top, left: position.left }}
                    >
                        {items.map(({ key, label, onClick }) => (
                            <div className={theme.dropdownItem} key={key} onClick={onClick}>
                                {label}
                            </div>
                        ))}
                    </div>
                )}
            </Portal>
        </div>
    );
};

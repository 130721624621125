import { GroupDTO } from '../serverapi/api';
import { ApiBundle, apiBundle } from './api/api-bundle';

export class GroupService {
    getByAccess(params: { access: string; serverId: string }): Promise<GroupDTO[]> {
        return this.getAPI(params.serverId)
            .group.getByAccess({ access: params.access })
            .then((groups) => (groups?.forEach((g) => this.setGroupId(g)), groups));
    }

    getByLicense(params: { license: string; serverId: string }): Promise<GroupDTO[]> {
        return this.getAPI(params.serverId)
            .group.getByLicense({ license: params.license })
            .then((groups) => (groups?.forEach((g) => this.setGroupId(g)), groups));
    }

    get(params: { id: number; serverId: string }): Promise<GroupDTO> {
        return this.getAPI(params.serverId)
            .group.get({ id: params.id })

            .then((u) => {
                return this.setGroupId(u), u;
            });
    }

    save(params: { group: GroupDTO; serverId: string }): Promise<GroupDTO> {
        return this.getAPI(params.serverId)
            .group.save({ body: params.group })
            .then((u) => (this.setGroupId(u), u));
    }

    create(params: { group: GroupDTO; serverId: string }): Promise<GroupDTO> {
        return this.getAPI(params.serverId)
            .group.create({ body: params.group })
            .then((u) => (this.setGroupId(u), u));
    }

    getAll(params: { serverId: string }): Promise<GroupDTO[]> {
        return this.getAPI(params.serverId)
            .group.getAll()
            .then(
                (group) => (
                    group?.forEach((u) => {
                        this.setGroupId(u);
                    }),
                    group
                ),
            );
    }

    delete(params: { id: number; serverId: string }): Promise<void> {
        return this.getAPI(params.serverId).group._delete({ id: params.id });
    }

    setGroupId(group: GroupDTO) {
        if (group && !isNaN(group.id)) {
            group.id = Number(group.id);
        }
    }

    private getAPI(serverId: string): ApiBundle {
        try {
            const api = apiBundle(serverId);

            return api;
        } catch (e) {
            throw new Error(`Error in GroupService, no existing API for server with id=[${serverId}]`);
        }
    }
}

let service: GroupService;

export function groupService(): GroupService {
    if (!service) {
        service = new GroupService();
    }

    return service;
}

import {
    MatrixCell,
    MatrixData,
    MatrixLane,
    MatrixStyle,
    MatrixStyleCustom,
    MatrixStyleIcon,
    MatrixStyleUserIcon,
    ObjectConnection,
    ObjectModelConnections,
    Node,
    ModelType,
    NodeId,
} from '@/serverapi/api';
import { v4 as uuid } from 'uuid';
import { EdgeSymbolType, IconType, TCellSymbol, TCheckForbiddenByProfile } from '../MatrixEditor/Matrix.types';
import React from 'react';
import { CheckOutlined, CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { LocalesService } from '../../../services/LocalesService';
import { nvlAcl } from '../../../services/bll/ProfileBllService';
import { cellIdDivider } from './Matrix.constants';
import { GeneralModelTypeDiscriminator } from '@/models/ModelTypes';
import { TTreeEntityState } from '@/models/tree.types';

export const completHeadersArray = (headers: MatrixLane[] = []) => {
    if (headers.length === 0 || headers[headers.length - 1].text) {
        headers.push({ id: uuid() });
    }
};

export const getCells = (matrixData?: MatrixData): MatrixCell[] => {
    if (!matrixData) return [];

    const cells = matrixData.cells.filter(
        (cell) =>
            matrixData.rows.some((row) => row.id === cell.rowId) &&
            matrixData.columns.some((col) => col.id === cell.columnId),
    );

    if (matrixData.rows && matrixData.columns) {
        matrixData.rows.forEach(({ id: rowId }) => {
            matrixData.columns.forEach(({ id: columnId }) => {
                const isCellExists = cells.some((cell) => cell.rowId === rowId && cell.columnId === columnId);
                if (!isCellExists) cells.push({ rowId, columnId, styleIds: [] });
            });
        });
    }
    matrixData.cells = cells;

    return cells;
};

export const getCellIdByRowIdAndColId = (rowId: string, colId: string): string => {
    return `${rowId}${cellIdDivider}${colId}`;
};

export const getRowIdAndColIdByCellId = (
    cellId: string,
): {
    rowId: string;
    colId: string;
} => {
    const res = cellId.split(cellIdDivider);
    return {
        rowId: res[0],
        colId: res[1],
    };
};

export const renderCellSymbol = (style?: MatrixStyle, antdIconsClassName?: string): TCellSymbol => {
    let symbol: string | JSX.Element = '';
    let color = '';

    if (!style)
        return {
            symbol,
            color,
            description: undefined,
        };

    const { type, description } = style;

    if (type === EdgeSymbolType.custom) {
        symbol = (style as MatrixStyleCustom).text;
        color = (style as MatrixStyleCustom).color;
    }

    if (type === EdgeSymbolType.icon) {
        symbol = renderIcon(
            (style as MatrixStyleIcon).icon as IconType,
            (style as MatrixStyleIcon).color,
            antdIconsClassName,
        );
    }

    if (type === EdgeSymbolType.userIcon) {
        symbol = (
            <img
                src={(style as MatrixStyleUserIcon).iconData}
                style={{
                    maxWidth: '30px',
                    maxHeight: '24px',
                    minWidth: '30px',
                    minHeight: '24px',
                }}
            />
        );
    }

    return {
        symbol,
        color,
        description,
    };
};

export const renderIcon = (iconType: IconType, color: string, className?: string) => {
    const style: React.CSSProperties = {
        color: color,
        maxWidth: '30px',
        maxHeight: '24px',
        minWidth: '30px',
        minHeight: '24px',
    };
    switch (iconType) {
        case IconType.plus:
            return <PlusOutlined style={style} className={className} />;
        case IconType.minus:
            return <MinusOutlined style={style} className={className} />;
        case IconType.check:
            return <CheckOutlined style={style} className={className} />;
        case IconType.cross:
            return <CloseOutlined style={style} className={className} />;
    }
};

export const haveChildren = (id: string, lanes: MatrixLane[]) => {
    return lanes.some((lane) => lane.parentId === id);
};

export const getCurrentLvl = (id: string, lanes: MatrixLane[]) => {
    let lvl = 0;
    const currentLane = lanes.find((lane) => lane.id === id);
    let parentId: string | undefined = currentLane?.parentId;
    while (parentId) {
        const parent = lanes.find((lane) => lane.id === parentId);
        parentId = parent?.parentId;
        lvl += 1;
    }
    return lvl;
};

export const checkSameLvls = (selectedLanesIds: string[], allLanes: MatrixLane[]) => {
    const lvl = getCurrentLvl(selectedLanesIds[0], allLanes);
    return !selectedLanesIds.some((id) => getCurrentLvl(id, allLanes) !== lvl);
};

export const checkLanesSpaces = (selectedLanesIds: string[], allLanes: MatrixLane[]): boolean => {
    let minIndex = allLanes.length - 1;
    let maxIndex = 0;
    selectedLanesIds.forEach((selectedLaneId) => {
        const currentIndex = allLanes.findIndex((lane) => lane.id === selectedLaneId);
        minIndex = Math.min(minIndex, currentIndex);
        maxIndex = Math.max(maxIndex, currentIndex);
    });

    return selectedLanesIds.length - maxIndex + minIndex === 1;
};

export const checkLanesCanUpLvl = (selectedLanesIds: string[], allLanes: MatrixLane[]) => {
    return !allLanes.some((lane) => selectedLanesIds.includes(lane.id) && getCurrentLvl(lane.id, allLanes) === 0);
};

export const getLastChildIndex = (parentLane: MatrixLane, lanes: MatrixLane[], excludeId: string): number => {
    let currentIndex = lanes.findIndex((lane) => lane.id === parentLane.id);
    let currentLane: MatrixLane | undefined = parentLane;
    while (currentLane) {
        const lastChild = lanes.findLast((lane) => lane.parentId === currentLane?.id && lane.id !== excludeId);
        const lastChildIndex = lanes.findLastIndex(
            (lane) => lane.parentId === currentLane?.id && lane.id !== excludeId,
        );
        currentIndex = Math.max(currentIndex, lastChildIndex);
        currentLane = lastChild;
    }

    return currentIndex;
};

export const fixLanes = (lanes: MatrixLane[]) => {
    lanes.forEach((currentLane) => {
        if (!currentLane.parentId) return;

        const isParentFinded = lanes.some((lane) => lane.id === currentLane.parentId);
        if (!isParentFinded) {
            currentLane.parentId = undefined;
        }
    });
};

export const reorderLanes = (currentLane: MatrixLane, lanes: MatrixLane[], newLanesOrder: MatrixLane[]) => {
    const parent = lanes.find((lane) => lane.id === currentLane.parentId);

    if (parent) {
        const parentIndex = newLanesOrder.findIndex((lane) => lane.id === parent.id);
        const lastParentsChildIndex = getLastChildIndex(parent, newLanesOrder, currentLane.id);
        if (parentIndex !== -1) {
            if (lastParentsChildIndex !== -1) {
                newLanesOrder.splice(lastParentsChildIndex + 1, 0, currentLane);
            } else {
                newLanesOrder.splice(parentIndex + 1, 0, currentLane);
            }
        } else {
            reorderLanes(parent, lanes, newLanesOrder);
        }
    } else {
        newLanesOrder.push(currentLane);
    }

    const remainingLanes: MatrixLane[] = lanes.filter(
        (lane) => !newLanesOrder.some((orderedLane) => orderedLane.id === lane.id),
    );
    if (remainingLanes[0]) reorderLanes(remainingLanes[0], lanes, newLanesOrder);
};

export const getParentForLvlDown = (
    lanes: MatrixLane[],
    selectedIds: string[],
    currentLvl: number,
): MatrixLane | undefined => {
    let minIndex = lanes.length - 1;
    selectedIds.forEach((id) => {
        const selectedHederCellIndex = lanes.findIndex((lane) => lane.id === id);
        minIndex = Math.min(minIndex, selectedHederCellIndex);
    });
    const parent = lanes.findLast((lane, index) => {
        const laneLvl = getCurrentLvl(lane.id, lanes);
        return index < minIndex && laneLvl === currentLvl && lane.text;
    });

    return parent;
};

export const excludeChildIds = (selectedIds: string[], lanes: MatrixLane[]): string[] => {
    const filteredSelectedIds: string[] = [];
    selectedIds.forEach((selectedId) => {
        const currentLane = lanes.find((lane) => lane.id === selectedId);
        if (!currentLane) return;

        if (!currentLane.parentId || !selectedIds.includes(currentLane.parentId)) {
            filteredSelectedIds.push(selectedId);
        }
    });

    return filteredSelectedIds;
};

export const getParentsChildren = (parent: MatrixLane, lanesForSearch: MatrixLane[], result: MatrixLane[]) => {
    lanesForSearch.forEach((currentLane) => {
        if (currentLane.parentId === parent.id) {
            result.push(currentLane);
            if (haveChildren(currentLane.id, lanesForSearch)) {
                getParentsChildren(currentLane, lanesForSearch, result);
            }
        }
    });
};

export const isLaneExist = (searchingLane: MatrixLane, lanes: MatrixLane[]) => {
    return lanes.some((lane) => searchingLane.id === lane.id);
};

export const getSelectedLanesWithChildren = (
    selectedIds: string[],
    lanes: MatrixLane[],
    withEmpty?: boolean,
): MatrixLane[] => {
    const notEmptySelectedLanes: MatrixLane[] = lanes.filter(
        (lane) => selectedIds.includes(lane.id) && (lane.text || withEmpty),
    );
    const selectedLanesWithChildern: MatrixLane[] = [];
    notEmptySelectedLanes.forEach((selectedLane) => {
        const parentsChildern: MatrixLane[] = [];
        getParentsChildren(selectedLane, lanes, parentsChildern);
        if (!isLaneExist(selectedLane, selectedLanesWithChildern)) {
            selectedLanesWithChildern.push(selectedLane);
        }
        parentsChildern.forEach((child) => {
            if (!isLaneExist(child, selectedLanesWithChildern)) {
                selectedLanesWithChildern.push(child);
            }
        });
    });

    return selectedLanesWithChildern;
};

export const fillCellsWithSameLinkedNodeIds = (cell: MatrixCell, matrixData: MatrixData) => {
    const { columnId, rowId } = cell;
    const rowsHeaders = matrixData.rows;
    const colsHeaders = matrixData.columns;

    const rowLinkedNodeId = rowsHeaders.find((row) => row.id === rowId)?.linkedNodeId;
    const colLinkedNodeId = colsHeaders.find((col) => col.id === columnId)?.linkedNodeId;

    if (!rowLinkedNodeId || !colLinkedNodeId) return;

    colsHeaders.forEach((col) => {
        rowsHeaders.forEach((row) => {
            if (
                (col.linkedNodeId === colLinkedNodeId && row.linkedNodeId === rowLinkedNodeId) ||
                (col.linkedNodeId === rowLinkedNodeId && row.linkedNodeId === colLinkedNodeId)
            ) {
                const cellWithSameLinkedNodeIds = matrixData.cells.find(
                    (cell) => cell.columnId === col.id && cell.rowId === row.id,
                );
                if (cellWithSameLinkedNodeIds) {
                    cellWithSameLinkedNodeIds.styleIds = [...cell.styleIds];
                }
            }
        });
    });
};

export const getStyleIdsFromCellWithSameLinkedNodeIds = (
    currentCell: MatrixCell,
    matrixData: MatrixData,
    excludeLaneId: string = '',
) => {
    const { columnId, rowId } = currentCell;
    const rowsHeaders = matrixData.rows;
    const colsHeaders = matrixData.columns;

    const rowLinkedNodeId = rowsHeaders.find((row) => row.id === rowId)?.linkedNodeId;
    const colLinkedNodeId = colsHeaders.find((col) => col.id === columnId)?.linkedNodeId;

    if (!rowLinkedNodeId || !colLinkedNodeId) return;

    let finded = false;

    colsHeaders.forEach((col) => {
        if (finded) return;
        rowsHeaders.forEach((row) => {
            if (finded) return;
            if (
                (col.linkedNodeId === colLinkedNodeId && row.linkedNodeId === rowLinkedNodeId) ||
                (col.linkedNodeId === rowLinkedNodeId && row.linkedNodeId === colLinkedNodeId)
            ) {
                const findedCell = matrixData.cells.find(
                    (cell) =>
                        cell.columnId === col.id &&
                        cell.rowId === row.id &&
                        cell.columnId !== excludeLaneId &&
                        cell.rowId !== excludeLaneId,
                );

                if (findedCell && (findedCell.columnId !== columnId || findedCell.rowId !== rowId)) {
                    currentCell.styleIds = [...findedCell.styleIds];
                    finded = true;
                }
            }
        });
    });
};

export const findConnectedEdgeInstances = (
    sourceObjectDefinition: ObjectDefinitionImpl,
    targetObjectDefinitionId: string,
): ObjectConnection[] => {
    const allConnectionsBetweenObjects: ObjectConnection[] = [];
    const allObjectModelConnections: ObjectModelConnections[] = sourceObjectDefinition.objectModelConnections || [];

    allObjectModelConnections.forEach((objectModelConnections: ObjectModelConnections) => {
        objectModelConnections.connections?.forEach((objectConnection: ObjectConnection) => {
            if (
                objectConnection.edgeInstanceId &&
                !allConnectionsBetweenObjects.some(
                    (connetion) => connetion.edgeInstanceId === objectConnection.edgeInstanceId,
                ) &&
                objectConnection.connectedObjectDefinitionId === targetObjectDefinitionId
            ) {
                allConnectionsBetweenObjects.push(objectConnection);
            }
        });
    });

    return allConnectionsBetweenObjects;
};

export const hasDeletedHeaderNode = (
    objectDefinition: ObjectDefinitionImpl | TTreeEntityState | undefined,
    cellHeader: MatrixLane | undefined,
): boolean => {
    if (!cellHeader) return false;
    if (!cellHeader.linkedNodeId) return true;

    if (!objectDefinition) return false;
    if ((objectDefinition as Node).deleted) return false;

    return true;
};

export const getHeaderCellText = (
    objectDefinition: ObjectDefinitionImpl | TTreeEntityState | undefined,
    cellHeader: MatrixLane,
    isForbiddenToRead?: boolean,
): string => {
    if (!cellHeader.linkedNodeId) {
        return LocalesService.internationalStringToString(cellHeader.text);
    }

    const isDeletedItem: boolean = !!(objectDefinition as Node)?.deleted || !objectDefinition;

    if (isDeletedItem || isForbiddenToRead) {
        return cellHeader.linkedNodeId;
    }

    return LocalesService.internationalStringToString(objectDefinition?.multilingualName);
};

export const isForbiddenByProfile = ({
    selectedHeaders,
    userProfile,
    objectDefinitions,
    rights,
}: TCheckForbiddenByProfile): boolean => {
    if (!userProfile) return false;

    return selectedHeaders.some((header) => {
        if (!header.linkedNodeId) return false;

        const objectDefinition: ObjectDefinitionImpl | TTreeEntityState | undefined =
            objectDefinitions[header.linkedNodeId || ''];
        if (!objectDefinition || (objectDefinition as Node).deleted) return false;

        return rights.some((right) => {
            return (
                nvlAcl(header.linkedNodeId, userProfile.symbolAcls[objectDefinition?.idSymbol || ''])[right] ===
                    false ||
                nvlAcl(header.linkedNodeId, userProfile.objectTypeAcls[objectDefinition?.objectTypeId || ''])[right] ===
                    false
            );
        });
    });
};

export const findSelectedHeadersByIds = (allHeaders: MatrixLane[], selectedIds: string[]): MatrixLane[] => {
    const selectedHeaders: MatrixLane[] = selectedIds
        .map((id) => allHeaders.find((header) => header.id === id))
        .filter((header): header is MatrixLane => !!header);

    return selectedHeaders;
};

export const setNewIds = (
    lanes: MatrixLane[],
): { lanesWithNewIds: MatrixLane[]; oldIdToNewIdMap: { [oldId: string]: string } } => {
    const lanesWithNewIds: MatrixLane[] = lanes.map((lane) => ({ ...lane }));
    const idsMap: { [oldId: string]: string } = {};

    lanesWithNewIds.forEach((copiedElement) => {
        const oldId = copiedElement.id;
        const newId = uuid();
        copiedElement.id = newId;
        idsMap[oldId] = newId;
        lanesWithNewIds.forEach((childElement) => {
            if (childElement.parentId === oldId) {
                childElement.parentId = newId;
            }
        });
    });

    return { lanesWithNewIds, oldIdToNewIdMap: idsMap };
};

export const filterToggledHeaderCells = (lanes: MatrixLane[], toggledIds: string[]) => {
    let result: MatrixLane[] = lanes;

    const fillter = (lanesForFillter: MatrixLane[], ids: string[]) => {
        const childIds: string[] = [];
        const filtredLanes: MatrixLane[] = [];
        lanesForFillter.forEach((lane) => {
            if (!lane.parentId || !ids.includes(lane.parentId)) {
                filtredLanes.push(lane);
            } else {
                childIds.push(lane.id);
            }
        });

        if (childIds.length !== 0) {
            fillter(filtredLanes, childIds);
        } else {
            result = filtredLanes;
        }
    };

    fillter(lanes, toggledIds);

    return result;
};

export const replaceIdenticalIds = (lanesForChange: MatrixLane[], lanesForComparison: MatrixLane[]) => {
    const res: { [oldId: string]: string } = {};
    const existingIds: string[] = lanesForComparison.map((lane) => lane.id);
    lanesForChange.forEach((laneForChange) => {
        if (existingIds.includes(laneForChange.id)) {
            const oldId = laneForChange.id;
            const newId = uuid();
            res[oldId] = newId;
            laneForChange.id = newId;
            lanesForChange.forEach((childLane) => {
                if (childLane.parentId === oldId) {
                    childLane.parentId = newId;
                }
            });
        }
    });

    return res;
};

export const checkMatrixAutofilled = (matrixData?: MatrixData): boolean => {
    return !!matrixData?.cellSettings.isAutomatic;
};

export const isMatrixModelType = (modelType: ModelType) => {
    return modelType.discriminator === GeneralModelTypeDiscriminator.MATRIX;
};

export const getMatrixContainerId = (matrixNodeId: NodeId) => `matrixEditor-${matrixNodeId.id}`;

export const getMatrixBottomRightCellId = (matrixNodeId: NodeId) => `matrix-bottom-right-cell-${matrixNodeId.id}`;

export const getMatrixMainHeaderId = (matrixNodeId: NodeId) => `matrix-main-header-${matrixNodeId.id}`;

export const removeDefaultStylesFromSVG = (svgDataString: string, matrixNodeId: NodeId) => {
    // Список свойств со значениями по умолчанию
    const defaultStyles = {
        // Блочные свойства
        display: ['inline', 'table-cell', 'block'],
        position: 'static',
        float: 'none',
        clear: 'none',
        overflow: 'visible',
        'overflow-x': 'visible',
        'overflow-y': 'visible',
        clip: 'auto',
        visibility: 'visible',
        'z-index': 'auto',
        'vertical-align': 'baseline',
        'white-space': 'normal',
        'text-transform': 'none',
        'letter-spacing': 'normal',
        'word-spacing': ['normal', '0px'],
        'line-height': ['normal', '18.858px'],
        'tab-size': '8',

        // Размеры и отступы
        margin: '0px',
        'margin-top': '0px',
        'margin-right': '0px',
        'margin-bottom': '0px',
        'margin-left': '0px',
        padding: '0px',
        'padding-top': '0px',
        'padding-right': '0px',
        'padding-bottom': '0px',
        'padding-left': '0px',
        border: 'none',
        'border-width': '0px',
        'border-style': 'none',
        'border-color': ['currentColor', 'rgba(0, 0, 0, 0)'],
        'border-top': 'none',
        'border-right': 'none',
        'border-bottom': 'none',
        'border-left': 'none',
        'border-radius': '0px',
        width: 'auto',
        'min-width': ['0px', 'auto'],
        'max-width': ['none', 'auto'],
        height: 'auto',
        'min-height': ['0px', 'auto'],
        'max-height': ['none', 'auto'],

        // Позиционирование
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: 'auto',

        // Фон
        background: ['transparent', 'none 0% 0% / auto repeat scroll padding-box border-box rgba(0, 0, 0, 0)'],
        'background-color': 'transparent',
        'background-image': 'none',
        'background-repeat': 'repeat',
        'background-position': '0% 0%',
        'background-size': 'auto',
        'background-origin': 'padding-box',
        'background-clip': 'border-box',

        // Шрифты и текст
        'font-weight': ['normal', '400'],
        'font-style': 'normal',
        'text-align': 'start',
        'text-indent': '0px',
        'text-shadow': 'none',
        'text-overflow': 'clip',
        hyphens: 'manual',

        // Списки
        'list-style': ['disc outside none', 'outside none none'],
        'list-style-type': 'disc',
        'list-style-position': 'outside',
        'list-style-image': 'none',

        // Таблицы
        'table-layout': 'auto',
        'border-collapse': 'separate',
        'border-spacing': '0px',
        'caption-side': 'top',
        'empty-cells': 'show',

        // Маски и фильтры
        mask: 'none',
        'mask-type': 'luminance',
        'mask-mode': 'match-source',
        'mask-repeat': 'repeat',
        'mask-position': '0% 0%',
        'mask-size': 'auto',
        'mask-origin': 'border-box',
        'mask-clip': 'border-box',
        filter: 'none',

        // Анимации и переходы
        transition: ['none', 'all 0s ease 0s', 'all'],
        animation: ['0s ease 0s 1 normal none running none', 'none'],
        'animation-name': 'none',
        'animation-duration': '0s',
        'animation-timing-function': 'ease',
        'animation-delay': '0s',
        'animation-iteration-count': '1',
        'animation-direction': 'normal',
        'animation-fill-mode': 'none',
        'animation-play-state': 'running',
        'transition-property': 'all',
        'transition-duration': '0s',
        'transition-timing-function': 'ease',
        'transition-delay': '0s',

        // Прочее
        cursor: ['auto', 'default'],
        'pointer-events': 'auto',
        resize: 'none',
        opacity: '1',
        transform: 'none',
        'backface-visibility': 'visible',
        perspective: 'none',
        isolation: 'auto',
        'mix-blend-mode': 'normal',
        'object-fit': 'fill',
        'object-position': '50% 50%',
        'accent-color': 'auto',
        'place-content': ['normal flex-start', 'normal'],
        'place-items': 'normal',
        'place-self': 'auto',
        'alignment-baseline': 'auto',
        'anchor-name': 'none',
        'anchor-scope': 'none',
        'animation-composition': 'replace',
        'app-region': 'none',
        appearance: 'none',
        'backdrop-filter': 'none',
        'background-blend-mode': 'normal',
        'baseline-shift': '0px',
        'baseline-source': 'auto',
        'border-image': 'none 100% / 1 / 0 stretch',
        'box-decoration-break': 'slice',
        'box-shadow': 'none',
        'break-after': 'auto',
        'break-before': 'auto',
        'break-inside': 'auto',
        'buffered-rendering': 'auto',
        'clip-path': 'none',
        'clip-rule': 'nonzero',
        'color-interpolation': 'srgb',
        'color-interpolation-filters': 'linearrgb',
        'color-rendering': 'auto',
        columns: 'auto',
        'column-span': 'none',
        'contain-intrinsic-block-size': 'none',
        'contain-intrinsic-size': 'none',
        'contain-intrinsic-inline-size': 'none',
        container: 'none',
        content: 'normal',
        cx: '0px',
        cy: '0px',
        d: 'none',
        direction: 'ltr',
        'dominant-baseline': 'auto',
        'field-sizing': 'fixed',
        fill: 'rgb(0, 0, 0)',
        'fill-opacity': '1',
        'fill-rule': 'nonzero',
        flex: '0 1 auto',
        'flex-flow': 'row',
        'flood-color': 'rgb(0, 0, 0)',
        'flood-opacity': '1',
        'font-kerning': 'auto',
        'font-optical-sizing': 'auto',
        'font-palette': 'normal',
        'font-size-adjust': 'none',
        'font-stretch': ['100%', 'normal'],
        'font-synthesis': 'weight style small-caps',
        'font-variant': 'tabular-nums',
        grid: 'none',
        'grid-area': 'auto',
        'hyphenate-character': 'auto',
        'hyphenate-limit-chars': 'auto',
        'image-orientation': 'from-image',
        'image-rendering': 'auto',
        'initial-letter': 'normal',
        'interpolate-size': 'numeric-only',
        'lighting-color': 'rgb(255, 255, 255)',
        'line-break': 'auto',
        marker: 'none',
        'math-depth': '0',
        'math-shift': 'normal',
        'math-style': 'normal',
        'object-view-box': 'none',
        offset: 'normal',
        orphans: '2',
        'outline-offset': '0px',
        'overflow-anchor': 'auto',
        'overflow-wrap': 'normal',
        overlay: 'none',
        'overscroll-behavior-block': 'auto',
        'overscroll-behavior-inline': 'auto',
        'paint-order': 'normal',
        'position-anchor': 'auto',
        'position-area': 'none',
        'position-try': 'none',
        'position-visibility': 'always',
        r: '0px',
        rotate: 'none',
        'ruby-align': 'space-around',
        'ruby-position': 'over',
        rx: 'auto',
        ry: 'auto',
        scale: 'none',
        'scroll-behavior': 'auto',
        'scroll-margin-block': '0px',
        'scroll-margin-inline': '0px',
        'scroll-padding-block': 'auto',
        'scroll-padding-inline': 'auto',
        'scroll-timeline': 'none',
        'scrollbar-color': 'auto',
        'scrollbar-gutter': 'auto',
        'scrollbar-width': 'auto',
        'shape-image-threshold': '0',
        'shape-margin': '0px',
        'shape-outside': 'none',
        'shape-rendering': 'auto',
        speak: 'normal',
        'stop-color': 'rgb(0, 0, 0)',
        'stop-opacity': '1',
        stroke: 'none',
        'stroke-dasharray': 'none',
        'stroke-dashoffset': '0px',
        'stroke-linecap': 'butt',
        'stroke-linejoin': 'miter',
        'stroke-miterlimit': '4',
        'stroke-opacity': '1',
        'stroke-width': '1px',
        'text-align-last': 'auto',
        'text-anchor': 'start',
        'text-decoration-skip-ink': 'auto',
        'text-emphasis-position': 'over',
        'text-size-adjust': '100%',
        'text-spacing-trim': 'normal',
        'text-underline-position': 'auto',
        'timeline-scope': 'none',
        'touch-action': 'auto',
        translate: 'none',
        'vector-effect': 'none',
        'view-timeline': 'none',
        'view-transition-class': 'none',
        'view-transition-name': 'none',
        'white-space-collapse': 'collapse',
        widows: '2',
        'will-change': 'auto',
        'word-break': 'normal',
        'writing-mode': 'horizontal-tb',
        x: '0px',
        y: '0px',
        zoom: '1',
        order: '0',
        gap: 'normal',
        'transform-style': 'flat',
        inset: 'auto',
    };

    // Шаг 1: Расшифровать строку, если она в формате data URI
    let svgXml: string;
    if (svgDataString.startsWith('data:image/svg+xml;charset=utf-8,')) {
        svgXml = decodeURIComponent(svgDataString.split(',')[1]);
    } else {
        return svgDataString;
    }

    const removeWebkitPrefixes = (styleString: string) => {
        return styleString.replace(/-webkit-[^;]+;/g, '');
    };

    svgXml = removeWebkitPrefixes(svgXml);

    const parser: DOMParser = new DOMParser();
    const doc: Document = parser.parseFromString(svgXml, 'image/svg+xml');

    const foreignObjects: NodeListOf<
        | HTMLDivElement
        | HTMLTableElement
        | HTMLTableRowElement
        | HTMLTableCellElement
        | HTMLSpanElement
        | HTMLImageElement
        | HTMLOrSVGImageElement
    > = doc.querySelectorAll(
        `foreignObject table, 
      foreignObject tr, 
      foreignObject th, 
      foreignObject td, 
      foreignObject thead, 
      foreignObject tbody, 
      foreignObject div, 
      foreignObject img, 
      foreignObject svg, 
      foreignObject span`,
    );

    const commonFontFamily: string = `
      -apple-system, 
      BlinkMacSystemFont, 
      "Segoe UI", Roboto, 
      "Helvetica Neue", 
      Arial, 
      "Noto Sans", 
      sans-serif, 
      "Apple Color Emoji", 
      "Segoe UI Emoji", 
      "Segoe UI Symbol", 
      "Noto Color Emoji"`;

    const styleElement: HTMLElement = doc.createElementNS('http://www.w3.org/1999/xhtml', 'style');

    styleElement.textContent = `
      * {
          font-family:${commonFontFamily};
          font-size:11.9px;color:rgba(0, 0, 0, 0.65);
          box-sizing:border-box;
          line-height: 18.858px;
        }
      td div {
          font-size:13.9px;color: rgb(0, 0, 0);
        }
      thead tr > :first-child div {
          font-size:11.9px;color: rgb(0, 0, 0);
        }`;

    const foreignObject = doc.querySelector('foreignObject');
    if (foreignObject) {
        foreignObject.insertBefore(styleElement, foreignObject.firstChild);
    }

    foreignObjects.forEach((el) => {
        if (el.style.cssText) {
            const styleAttr = el.getAttribute('style');
            if (styleAttr) {
                const styles = styleAttr.split(';').reduce<{ [key: string]: string }>((acc, pair) => {
                    const [prop, value] = pair
                        .trim()
                        .split(':')
                        .map((s) => s.trim());
                    if (prop && value) {
                        acc[prop] = value;
                    }
                    return acc;
                }, {});

                let stylesToDelete = [
                    'transform-origin',
                    'perspective-origin',
                    'min-inline-size',
                    'max-inline-size',
                    'inline-size',
                    'outline',
                    'font-size',
                    'user-select',
                    'block-size',
                    'max-block-size',
                    'min-block-size',
                    'color',
                    'caret-color',
                    'column-rule',
                    'text-decoration',
                    'text-emphasis',
                    'text-rendering',
                    'unicode-bidi',
                    'border-block-end',
                    'border-block-start',
                    'border-inline-end',
                    'border-inline-start',
                    'box-sizing',
                    'border-end-end-radius',
                    'border-end-start-radius',
                    'border-start-end-radius',
                    'border-start-start-radius',
                    'inset-block',
                    'inset-inline',
                    'padding-inline',
                    'padding-block',
                    'margin-inline',
                    'margin-block',
                    'overflow-clip-margin',
                    'font-family',
                ];

                for (let style of stylesToDelete) {
                    delete styles[style];
                }

                // Удаляем свойства со значениями по умолчанию
                for (const prop in styles) {
                    let defaultValue = defaultStyles[prop];
                    let currentValue = styles[prop];

                    // Проверка на массив дефолтных значений
                    if (Array.isArray(defaultValue)) {
                        if (defaultValue.includes(currentValue)) {
                            delete styles[prop];
                            continue;
                        }
                    } else if (
                        defaultValue !== undefined &&
                        String(defaultValue).toLowerCase() === String(currentValue).toLowerCase()
                    ) {
                        delete styles[prop];
                    }
                }

                const rgbToHex = (rgb) => {
                    const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
                    if (match) {
                        const r = parseInt(match[1], 10).toString(16).padStart(2, '0');
                        const g = parseInt(match[2], 10).toString(16).padStart(2, '0');
                        const b = parseInt(match[3], 10).toString(16).padStart(2, '0');
                        return `#${r}${g}${b}`;
                    }
                    return rgb; // Если цвет не RGB, возвращаем как есть
                };

                for (const prop in styles) {
                    const value = styles[prop];
                    if (value.includes('rgb(')) {
                        styles[prop] = value.replace(/rgb\(\d+,\s*\d+,\s*\d+\)/g, (rgb) => rgbToHex(rgb));
                    }
                }

                // Удаление всех стилей связанных с бордерами для div элементов

                if (
                    (el.tagName.toLowerCase() === 'div' ||
                        el.tagName.toLowerCase() === 'img' ||
                        el.tagName.toLowerCase() === 'svg' ||
                        el.tagName.toLowerCase() === 'span' ||
                        el.tagName.toLowerCase() === 'thead' ||
                        el.tagName.toLowerCase() === 'tr') &&
                    el.parentElement?.id !== getMatrixMainHeaderId(matrixNodeId)
                ) {
                    const borderProperties = [
                        'border',
                        'border-width',
                        'border-style',
                        'border-color',
                        'border-top',
                        'border-right',
                        'border-bottom',
                        'border-left',
                        'border-radius',
                        'border-top-width',
                        'border-right-width',
                        'border-bottom-width',
                        'border-left-width',
                        'border-top-style',
                        'border-right-style',
                        'border-bottom-style',
                        'border-left-style',
                        'border-top-color',
                        'border-right-color',
                        'border-bottom-color',
                        'border-left-color',
                        'border-image',
                        'border-image-source',
                        'border-image-slice',
                        'border-image-width',
                        'border-image-outset',
                        'border-image-repeat',
                    ];

                    borderProperties.forEach((prop) => {
                        if (styles[prop]) {
                            delete styles[prop];
                        }
                    });
                }

                if (el.tagName === 'td' || el.tagName === 'tr') {
                    delete styles['width'];
                    delete styles['min-width'];
                    delete styles['max-width'];
                    delete styles['height'];
                    delete styles['line-height'];
                    delete styles['border-radius'];
                }

                if (styles['width'] === styles['max-width'] && styles['width'] === styles['min-width']) {
                    delete styles['width'];
                }

                // Удаление border-spacing для всех элементов кроме table
                if (el.tagName.toLowerCase() !== 'table') {
                    delete styles['border-spacing'];
                }

                if (el.tagName !== 'span' && el.tagName !== 'div') {
                    delete styles['font-weight'];
                    delete styles['text-align'];
                    delete styles['text-wrap'];
                }

                // Объединение всех border-стилей в один общий стиль border
                const borderProps = ['border-width', 'border-style', 'border-color'];

                const [borderWidth, borderStyle, borderColor] = borderProps.map((props) => styles[props]);

                if (borderWidth && borderColor && borderStyle && borderColor.split(' ').length === 1) {
                    styles['border'] = `${borderWidth} ${borderStyle} ${borderColor}`;
                    borderProps.forEach((prop) => delete styles[prop]);
                }

                if (Object.keys(styles).length > 0) {
                    el.setAttribute(
                        'style',
                        Object.entries(styles)
                            .map(([key, value]) => `${key}:${value}`)
                            .join(';'),
                    );
                } else {
                    // Если стилей не осталось, удаляем атрибут style
                    el.removeAttribute('style');
                }
            }
        }

        const attributesToRemove = [
            'draggable',
            'aria-label',
            'aria-labelledby',
            'aria-describedby',
            'role',
            'name',
            'width',
            'height',
            'align',
            'valign',
            'colspan',
            'rowspan',
            'tabindex',
            'contenteditable',
            'spellcheck',
            'title',
            'alt',
            'lang',
            'xmlns',
            'version',
            'baseProfile',
            'preserveAspectRatio',
        ];

        attributesToRemove.forEach((attr) => {
            if (el.hasAttribute(attr)) {
                el.removeAttribute(attr);
            }
        });

        if (el.hasAttributes()) {
            Array.from(el.attributes).forEach((attr) => {
                if (/^data-/.test(attr.name)) {
                    el.removeAttribute(attr.name);
                }
            });
        }
    });

    const serializer = new XMLSerializer();
    const optimizedSvgXml = serializer.serializeToString(doc);

    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(optimizedSvgXml)}`;
};

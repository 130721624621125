import { NodeId, WidgetDTO } from '@/serverapi/api';
import { chartColors } from '../DashboardsConsts';

export type TChartData = {
    labels: string[];
    datasets: {
        label: string;
        data: (number | null)[];
        borderColor: (typeof chartColors)[number];
        backgroundColor: (typeof chartColors)[number][];
    }[];
};

export type TSummaryData = {
    data: string;
    legend: string;
};

export type TDataMappers = TChartData | TSummaryData;

export type TWidgetProps = {
    data: TChartData | TSummaryData;
    title: string;
    widget: TDashboardWidgets;
    dashboardNodeId: NodeId;
    selected: boolean;
};

export type TWidgetData = {
    value: number | null;
    name: string;
    backgroundColor: (typeof chartColors)[number];
}[];

//описание типов виджетов
export enum SummaryOperations {
    Count = 'Count',
    Sum = 'Sum',
    Avg = 'Avg',
}
export type TChartDataset = {
    col1: TUpperCaseAlpValues;
    col2: TUpperCaseAlpValues;
    legend: string;
};

export type TWidgetSource = {
    id?: NodeId;
    dataset?: TChartDataset[];
};
export interface IChartWidget extends WidgetDTO {
    source?: TWidgetSource;
}

export interface ISummaryWidget extends WidgetDTO {
    source?: TWidgetSource;
    summaryOperation?: SummaryOperations;
}

export interface ITextBlockWidget extends WidgetDTO {
    text: string;
    bgColor?: string;
    border?: boolean;
}

export type TDashboardWidgets = IChartWidget | ITextBlockWidget | ISummaryWidget;

export type TUpperCaseAlpValues = keyof typeof UpperCaseAlp;
export enum UpperCaseAlp {
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '',
}

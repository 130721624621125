// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExportRepositoryDialog__form__DSdq8 .ExportRepositoryDialog__formItem__sMwCm{margin-bottom:12px}.ExportRepositoryDialog__form__DSdq8 .ExportRepositoryDialog__formItem__sMwCm label{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.ExportRepositoryDialog__form__DSdq8 .ExportRepositoryDialog__formItem__sMwCm label span{margin-right:8px}.ExportRepositoryDialog__form__DSdq8 .ExportRepositoryDialog__formItem__sMwCm label:hover{cursor:pointer}.ExportRepositoryDialog__form__DSdq8 .ExportRepositoryDialog__formItem__sMwCm label>div{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}", "",{"version":3,"sources":["webpack://./src/modules/dialogs/ExportRepositoryDialog/ExportRepositoryDialog.scss"],"names":[],"mappings":"AACI,8EACI,kBAAA,CACA,oFACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,yFACI,gBAAA,CAEJ,0FACI,cAAA,CAEJ,wFACI,yBAAA,CAAA,sBAAA,CAAA,iBAAA","sourcesContent":[".form {\r\n    .formItem {\r\n        margin-bottom: 12px;\r\n        label {\r\n            display: flex;\r\n            align-items: center;\r\n            span {\r\n                margin-right: 8px;\r\n            }\r\n            &:hover {\r\n                cursor: pointer;\r\n            }\r\n            & > div {\r\n                width: fit-content;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "ExportRepositoryDialog__form__DSdq8",
	"formItem": "ExportRepositoryDialog__formItem__sMwCm"
};
export default ___CSS_LOADER_EXPORT___;

import { Locale } from '../../modules/Header/components/Header/header.types';
import { TObjectConnectionsData } from '../../modules/ObjectPropertiesDialog/components/ObjectConnections/ObjectConnections.types';
import { EdgeType, ObjectModelConnections, ObjectType } from '../../serverapi/api';
import { LocalesService } from '../LocalesService';
import messages from '@/modules/ObjectPropertiesDialog/components/ObjectConnections/ObjectConnections.messages';

export const getObjectConnectionsData = (
    connections: ObjectModelConnections[] | undefined,
    edgeTypeList: EdgeType[],
    objTypes: ObjectType[],
): TObjectConnectionsData[] => {
    const intl = LocalesService.useIntl();
    const currentLocale: Locale = LocalesService.currentLocale;

    const data: TObjectConnectionsData[] = connections
        ? connections
              .flatMap((modelConections) =>
                  modelConections.connections?.map((connection) => ({
                      connection,
                      modelId: modelConections.modelId,
                      modelName: modelConections.modelName,
                  })),
              )
              .map((edge) => ({
                  modelId: edge?.modelId || '',
                  modelName: edge?.modelName || '',
                  edgeType: `${
                      edgeTypeList.find((edgeType) => edgeType.id === edge?.connection.edgeTypeId)?.localizableName ||
                      ''
                  } ${!!edge?.connection.invisible ? intl.formatMessage(messages.invisibleEdge) : ''}`.trim(),
                  object: edge?.connection.connectedObjectName || '',
                  objectType: LocalesService.internationalStringToString(
                      objTypes.find((type) => type.id === edge?.connection.connectedObjectTypeId)?.multilingualName,
                  ),
                  outOrIn: edge?.connection.isOutgoingEdge
                      ? intl.formatMessage(messages.outGoingEdge)
                      : intl.formatMessage(messages.inGoingEdge),
              }))
              .sort((a, b) => a.modelName.localeCompare(b.modelName, currentLocale))
        : [];

    return data;
};

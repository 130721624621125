import { Locale } from '@/modules/Header/components/Header/header.types';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { LifelineSymbolMainClass } from '@/mxgraph/ComplexSymbols/symbols/LifeLine/LifelineSymbolMain.class';
import SequenceUtils from '@/mxgraph/ComplexSymbols/symbols/Sequence/sequence.utils';
import { LocalesService } from '@/services/LocalesService';
import { MxCell, MxPopupMenu } from 'MxGraph';
import messages from '../modules/Editor/messages/EditorPopupMenu.messages';
import {
    DiagramElement,
    EdgeInstance,
    EdgeType,
    ModelType,
    NodeId,
    ObjectDefinitionNode,
    ObjectInstance,
    ShapeInstance,
    Symbol,
    UserDTO,
} from '@/serverapi/api';
import {
    addBpmnTableRow,
    addTableColumn,
    addTableRow,
    changeSymbolForCell,
    copyAction,
    cutAction,
    handleDeleteSelectedCellsFromActiveGraphAction,
    moveToAction,
    removeBpmnTableRow,
} from '@/actions/editor.actions';
import { EditorMode } from '@/models/editorMode';
import { CommentMarker, EdgeInstanceImpl, LayoutInstanceImpl, ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { getStore } from '@/store';
import { sortByEdgeTypeName } from '@/modules/AdminTools/Methodology/components/Presets/ModelType/utils/modelTypes.utils';
import { getGeneralAvailableEdgeTypeSelector } from '@/selectors/generalMenu.selectors';
import { BPMMxPopupMenuType } from '@/mxgraph/components/BPMPopupMenu.types';
import { showNotification } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { ModelTypes } from '@/models/ModelTypes';
import { BPMMxConstants } from '@/mxgraph/bpmgraph.constants';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { hasModelEditorLicense } from '@/selectors/authorization.selectors';
import { copyLinkAction } from '@/actions/copyLinkDialog.actions';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { scriptSelectDialogInit } from '@/actions/scriptSelectDialog.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { isNotAccessSymbolStyle, isNotReadableSymbolStyle, isSymbolHasNotDefinition } from '@/services/SymbolsService';
import { SymbolType } from '@/models/Symbols.constants';
import { getCopyableCells } from '@/services/bll/BpmMxEditorBLLService';
import LifeLineUtils from '@/mxgraph/ComplexSymbols/symbols/LifeLine/LifeLine.utils';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { TCurrentUserProfile } from '@/reducers/userProfile.reducer.types';
import { getSymbolsFromModelType } from '@/utils/symbol.utils';
import { AvailableConnectionForSymbolsBLLService } from '@/services/bll/AvailableConnectionForSymbolsBLLService';
import { createDiagramElement } from '@/actions/navigatorSymbol.actions';
import { UML_DESTROY_ATTRIBUTE_TYPE_ID } from '@/mxgraph/ComplexSymbols/symbols/Sequence/sequence.constants';
import { objectDecompositionDialogInit } from '@/actions/entities/objectDecomposition.actions';
import { SequenceSymbolTypeId } from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { upsertAttributeValue } from '@/actions/attribute.actions';
import { MoveTo } from '@/models/move-to';
import { copyToClipboard } from '@/actions/tabs.actions';
import { currentTreeDepth } from '@/mxgraph/ComplexSymbols/utils';
import { objectPropertyView } from '@/actions/objectProperty.actions';
import { TScriptSelectDialogInitPayload } from '@/actions/scriptSelectDialog.actions.types';
import { initEdgeDefinitionCreation } from '@/actions/entities/edgeDefinition.actions';
import { deleteCommentMarker } from '@/actions/comments.actions';
import { psdCellType } from '@/mxgraph/psdDiagram/psdTable';
import { v4 as uuid } from 'uuid';
import {
    addDecompositionItem,
    addEdgeTypesMenu,
    addLinkMenuItem,
    addMenuEdgeTypesForSequence,
    addMoveMenu,
    addScriptMenu,
    generatePasteMenu,
    getMode,
    getTooltipMessage,
    isAllowForkConnection,
    isEdgeEnabled,
} from '@/utils/editorPopupMenu.utils';

export const createEdgeTypesMenu = (menu: MxPopupMenu, graph: BPMMxGraph, cell: MxCell, emitter: any): void => {
    const mode = getMode(graph);
    const currentEdgeType = cell.getValue().edgeTypeId;

    const availableTypes = sortByEdgeTypeName(
        getGeneralAvailableEdgeTypeSelector(getStore().getState()).filter((type): type is EdgeType => !!type),
    );
    const edgesTypes = [
        ...availableTypes.filter((el: EdgeType) => el.id == currentEdgeType),
        ...availableTypes.filter((el: EdgeType) => el.id !== currentEdgeType),
    ];

    const { enabled, reason }: { enabled: boolean; reason?: string } = isEdgeEnabled(graph, cell, edgesTypes, mode);
    if (enabled) {
        addEdgeTypesMenu(menu, emitter, edgesTypes);
        menu.type = BPMMxPopupMenuType.EDGE_TYPE;
    } else {
        emitter(
            showNotification({
                id: uuid(),
                type: NotificationType.EDGE_TYPE_CHANGING_WARNING,
                data: { message: reason },
            }),
        );
    }
};

export const createDefaultMenu = (
    menu: MxPopupMenu,
    graph: BPMMxGraph,
    cell: MxCell,
    user: UserDTO,
    emitter: any,
    modelType: ModelType,
    getObjectNode: (nodeId: NodeId) => ObjectDefinitionNode | undefined,
    locale: Locale,
    parentNodeId?: NodeId,
    printable?: boolean,
): void => {
    const mode = getMode(graph);
    const isWhiteBoard: boolean = graph?.modelType?.id === ModelTypes.MIND_MAP;

    const availableTypes = sortByEdgeTypeName(
        getGeneralAvailableEdgeTypeSelector(getStore().getState()).filter((type): type is EdgeType => !!type),
    );

    if (graph.connectionHandler?.edgeState) {
        const { connectionHandler } = graph;
        sortByEdgeTypeName(connectionHandler.availableEdgeTypes).forEach((edgeType: EdgeType, index: number) => {
            const title =
                edgeType.id === BPMMxConstants.AUTO_EDGE_TYPE_ID
                    ? graph.intl.formatMessage(messages.edgeTypeAuto)
                    : LocalesService.internationalStringToString(edgeType.multilingualName, locale);

            menu.addItem(
                title,
                null,
                () => {
                    connectionHandler.changeEdgeType(edgeType);
                },
                null,
                '',
                undefined,
                undefined,
                undefined,
                `${index}`,
            );
        });

        return;
    }

    if (cell != null) {
        const isNotAccessSymbol: boolean = isNotAccessSymbolStyle(cell.style);
        const isNotReadableSymbol: boolean = isNotReadableSymbolStyle(cell.style);

        if (isNotAccessSymbol || isNotReadableSymbol) return;

        const { serverId } = graph.bpmMxGraphContext;
        const state = getStore().getState();
        const presetId: string | undefined = TreeSelectors.presetById(graph.id)(state);
        const value: DiagramElement | undefined = cell.getValue();
        const edgeDefinitionId: string | undefined = (value as EdgeInstance | undefined)?.edgeDefinitionId;
        const objectDefinitionId: string | undefined = (value as ObjectInstance | undefined)?.objectDefinitionId;
        const objectDefinitionNodeId: NodeId | undefined = objectDefinitionId
            ? ({ ...parentNodeId, id: objectDefinitionId } as NodeId)
            : undefined;

        const isShape: boolean = !value?.type || !objectDefinitionId || value?.type === SymbolType.SHAPE;
        const isStyleEditableCell = ComplexSymbolManager.isCellStyleEditable(cell);
        const hasCopyableCells = getCopyableCells(graph, graph.getSelectionCells()).length > 0;
        const isSequenceExecutionSymbol: boolean = SequenceUtils.isSequenceExecutionSymbol(cell);
        const isSequenceDiagramCell: boolean = SequenceUtils.isSequenceDiagramCell(cell);
        const isUmlMessage: boolean = SequenceUtils.isUmlMessage(cell);
        const destroySymbol: MxCell | undefined = LifeLineUtils.getDestroySymbol(cell);
        const isDestroySymbol = LifeLineUtils.getDestroySymbolId(cell.parent) === cell.id;
        const isDefaultSymbol: boolean = isSymbolHasNotDefinition(cell.style);
        const isSymbolEditable: boolean = UserProfileSelectors.isSymbolEditable(
            objectDefinitionNodeId,
            (value as ObjectInstance)?.symbolId,
        )(state);
        const isEditableNotDefaultSymbol: boolean = isSymbolEditable && !isDefaultSymbol;
        const complexSymbol = ComplexSymbolManager.getComplexSymbolInstance(cell) as LifelineSymbolMainClass | null;
        const objectInstance = cell.value as ObjectInstance;
        const profile: TCurrentUserProfile | undefined =
            UserProfileSelectors.selectUserProfileByNodeId(objectDefinitionNodeId)(state);
        const isModelEditor: boolean = hasModelEditorLicense(state);
        let symbols: Symbol[] = [];
        let availableSymbols: Symbol[] = [];

        const selectedElementsIds: string[] = [];
        const selectedElementsDefinitionsIds: NodeId[] = [];
        graph.getSelectionCells().forEach((selectedCell) => {
            const cellValue = selectedCell.getValue();
            if (cellValue && cellValue.id) {
                selectedElementsIds.push(cellValue.id);
                if (selectedCell.isEdge() && (cellValue as EdgeInstance)?.edgeDefinitionId) {
                    selectedElementsDefinitionsIds.push({
                        ...parentNodeId,
                        id: cellValue.edgeDefinitionId,
                    } as NodeId);
                }
                if (!selectedCell.isEdge() && (cellValue as ObjectInstance)?.objectDefinitionId) {
                    selectedElementsDefinitionsIds.push({
                        ...parentNodeId,
                        id: cellValue.objectDefinitionId,
                    } as NodeId);
                }
            }
        });

        if (objectDefinitionNodeId) {
            const obj = getObjectNode(objectDefinitionNodeId);
            if (obj) {
                const objectInstanceValue: ObjectInstanceImpl = value as ObjectInstanceImpl;
                symbols = getSymbolsFromModelType(modelType)
                    .filter((sym) => sym.objectType && sym.objectType === obj.objectTypeId)
                    .filter((sym) => !objectInstanceValue || sym.id !== objectInstanceValue.symbolId);
                availableSymbols = AvailableConnectionForSymbolsBLLService.getSymbolsForConnection(
                    modelType,
                    objectInstanceValue?.symbolId,
                    obj.objectTypeId,
                    profile?.symbolAcls,
                    profile?.edgeTypeAcls,
                );
            }
        }

        if (!cell.isEdge()) {
            if (!((value instanceof LayoutInstanceImpl && value.isPSDCell) || value instanceof CommentMarker)) {
                if (!isShape && !isSequenceDiagramCell && !isUmlMessage && availableSymbols.length > 0) {
                    const connectedElementMenu = menu.addItem(
                        graph.intl.formatMessage(messages.createConnectedElement),
                        null,
                        () => {},
                        null,
                        '',
                        mode === EditorMode.Edit && availableSymbols.length > 0 && isSymbolEditable,
                        undefined,
                        'graph-element_context-menu_create-connected-element',
                    );
                    availableSymbols.forEach((sym) => {
                        menu.addItem(
                            sym.name,
                            sym.icon,
                            () => {
                                emitter(createDiagramElement(sym, cell));
                            },
                            connectedElementMenu,
                            '',
                        );
                    });
                }

                if (!isShape && isStyleEditableCell) {
                    const symbolsAvailableToReplace = graph.getAvailableToReplace(cell, symbols);
                    const symbolChangeSubMenu = menu.addItem(
                        graph.intl.formatMessage(messages.replaceSymbol),
                        null,
                        () => {},
                        null,
                        '',
                        mode === EditorMode.Edit && symbolsAvailableToReplace.length > 0 && !isDefaultSymbol,
                        undefined,
                        'graph-element_context-menu_change-symbol',
                    );
                    if (symbolsAvailableToReplace.length > 0) {
                        symbolsAvailableToReplace.forEach((sym) => {
                            menu.addItem(
                                sym.name,
                                sym.icon,
                                () => {
                                    emitter(changeSymbolForCell(cell.getId(), graph.id, sym));
                                },
                                symbolChangeSubMenu,
                                '',
                                isSymbolEditable,
                            );
                        });
                    }
                }
                if (isUmlMessage) {
                    addMenuEdgeTypesForSequence(menu, graph, cell, locale, emitter, mode);
                }

                if (isSequenceDiagramCell) {
                    const createElement = menu.addItem(
                        graph.intl.formatMessage(messages.createElement),
                        null,
                        () => {},
                        null,
                        '',
                        mode === EditorMode.Edit,
                    );

                    addMenuEdgeTypesForSequence(menu, graph, cell, locale, emitter, mode);

                    if (!isSequenceExecutionSymbol)
                        menu.addItem(
                            graph.intl.formatMessage(messages.createExecutionSymbol),
                            null,
                            () => complexSymbol?.addExecutionSymbol && complexSymbol.addExecutionSymbol(cell),
                            createElement,
                            '',
                        );

                    const { attributes } = objectInstance;
                    const destructionAttribute = attributes?.find(
                        (attr) => attr.typeId === UML_DESTROY_ATTRIBUTE_TYPE_ID,
                    );

                    addDecompositionItem(
                        menu,
                        graph,
                        () => {
                            if (objectDefinitionNodeId) {
                                emitter(
                                    objectDecompositionDialogInit({
                                        objectDefinitionId: objectDefinitionNodeId,
                                        graphId: graph.id,
                                    }),
                                );
                            }
                        },
                        isEditableNotDefaultSymbol && isModelEditor,
                    );

                    menu.addSeparator(null, true);

                    if (cell.complexSymbolTypeId !== SequenceSymbolTypeId.LIFE_LINE_ACTOR && complexSymbol) {
                        if (destroySymbol) {
                            menu.addItem(
                                graph.intl.formatMessage(messages.deleteDestroySymbol),
                                null,
                                () => {
                                    if (complexSymbol.deleteDestroySymbol) {
                                        complexSymbol.deleteDestroySymbol(cell);
                                    }
                                    if (destructionAttribute) {
                                        emitter(
                                            upsertAttributeValue({
                                                graphId: graph.id,
                                                cellId: cell.id,
                                                presetId,
                                                newAttributeValue: {
                                                    ...destructionAttribute,
                                                    value: 'false',
                                                },
                                            }),
                                        );
                                    }
                                },
                                null,
                                '',
                                mode === EditorMode.Edit,
                            );
                        } else {
                            menu.addItem(
                                graph.intl.formatMessage(messages.createDestroySymbol),
                                null,
                                () => {
                                    // eslint-disable-next-line no-unused-expressions
                                    complexSymbol.createDestroySymbol && complexSymbol.createDestroySymbol(cell);

                                    if (destructionAttribute) {
                                        emitter(
                                            upsertAttributeValue({
                                                graphId: graph.id,
                                                cellId: cell.id,
                                                presetId,
                                                newAttributeValue: {
                                                    ...destructionAttribute,
                                                    value: 'true',
                                                },
                                            }),
                                        );
                                    }
                                },
                                null,
                                '',
                                mode === EditorMode.Edit,
                            );
                        }
                        menu.addSeparator(null, true);
                    }
                } else if (!isShape) {
                    addDecompositionItem(
                        menu,
                        graph,
                        () => {
                            if (objectDefinitionNodeId) {
                                emitter(
                                    objectDecompositionDialogInit({
                                        objectDefinitionId: objectDefinitionNodeId,
                                        graphId: graph.id,
                                    }),
                                );
                            }
                        },
                        isEditableNotDefaultSymbol && isModelEditor,
                    );

                    menu.addSeparator(null, true);
                }

                graph.loadPopupMenu(menu, cell, mode !== EditorMode.Edit);

                if (
                    cell.complexSymbolTypeId &&
                    Object.values(SequenceSymbolTypeId).includes(cell.complexSymbolTypeId as SequenceSymbolTypeId)
                ) {
                    menu.addItem(
                        graph.intl.formatMessage(messages.renameObject),
                        null,
                        () => {
                            emitter(openDialog(DialogType.RENAME_OBJECT_DIALOG, { graph, cell }));
                        },
                        null,
                        '',
                        true,
                    );
                }

                menu.addItem(
                    graph.intl.formatMessage(messages.objectInTree),
                    null,
                    () => {
                        emitter(moveToAction(MoveTo.ObjectInTree));
                    },
                    null,
                    '',
                    !isShape || !!(cell?.value as ShapeInstance)?.imageId,
                    undefined,
                    'graph-element_context-menu_find-in-tree',
                );

                menu.addItem(
                    graph.intl.formatMessage(messages.copy),
                    null,
                    () => {
                        emitter(copyAction());
                    },
                    null,
                    '',
                    hasCopyableCells,
                    undefined,
                    'graph-element_context-menu_copy',
                );

                menu.addItem(
                    graph.intl.formatMessage(messages.cut),
                    null,
                    () => {
                        emitter(cutAction());
                    },
                    null,
                    '',
                    hasCopyableCells && mode === EditorMode.Edit,
                    undefined,
                    'graph-element_context-menu_cut',
                );

                addLinkMenuItem(menu, graph, () => emitter(copyLinkAction(graph.id, TreeItemType.Model, value?.id)));

                if (!isWhiteBoard && !isShape) {
                    menu.addItem(
                        graph.intl.formatMessage(messages.copyID),
                        null,
                        () => {
                            emitter(
                                copyToClipboard({
                                    modelId: graph.id,
                                    objectDiagramElement: value as ObjectInstanceImpl,
                                }),
                            );
                        },
                        null,
                        '',
                        !isDefaultSymbol,
                        undefined,
                        'graph-element_context-menu_copy-ID',
                    );
                }

                // запуск скрипта для Shape
                const onShapeExecute = () =>
                    emitter(
                        scriptSelectDialogInit({
                            serverId,
                            nodeId: undefined,
                            modelId: graph.id,
                            element: value,
                            elementsIdsList: selectedElementsIds,
                            nodesIdsList: selectedElementsDefinitionsIds,
                            rootType: TreeItemType.ScriptFolder,
                            scriptType: TreeItemType.Script,
                        }),
                    );

                // планирование скрипта для Shape
                const onShapeSchedule = () =>
                    emitter(
                        openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, {
                            serverId,
                            nodeId: undefined,
                            modelId: graph.id,
                            element: value,
                            elementsIdsList: selectedElementsIds,
                            nodesIdsList: selectedElementsDefinitionsIds,
                            rootType: TreeItemType.ScriptFolder,
                            scriptType: TreeItemType.Script,
                        }),
                    );

                // запуск скрипта для объекта
                const onObjectExecute = () => {
                    if (objectDefinitionNodeId) {
                        emitter(
                            scriptSelectDialogInit({
                                serverId,
                                nodeId: objectDefinitionNodeId,
                                modelId: graph.id,
                                element: value,
                                elementsIdsList: selectedElementsIds,
                                nodesIdsList: selectedElementsDefinitionsIds,
                                rootType: TreeItemType.ScriptFolder,
                                scriptType: TreeItemType.Script,
                            }),
                        );
                    }
                };

                // планирование скрипта для объекта
                const onObjectSchedule = () => {
                    if (objectDefinitionNodeId) {
                        emitter(
                            openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, {
                                serverId,
                                nodeId: objectDefinitionNodeId,
                                modelId: graph.id,
                                element: value,
                                elementsIdsList: selectedElementsIds,
                                nodesIdsList: selectedElementsDefinitionsIds,
                                rootType: TreeItemType.ScriptFolder,
                                scriptType: TreeItemType.Script,
                            }),
                        );
                    }
                };

                const onExecute = isShape ? onShapeExecute : onObjectExecute;
                const onSchedule = isShape ? onShapeSchedule : onObjectSchedule;
                addScriptMenu(menu, graph, onExecute, onSchedule);

                addMoveMenu(menu, graph, emitter, mode);

                if (isSequenceExecutionSymbol && !isDestroySymbol && complexSymbol) {
                    menu.addSeparator(null, true);
                    if (currentTreeDepth(cell) < 3) {
                        const createElement = menu.addItem(
                            graph.intl.formatMessage(messages.createElement),
                            null,
                            () => {},
                            null,
                            '',
                            mode === EditorMode.Edit,
                        );

                        menu.addItem(
                            graph.intl.formatMessage(messages.createExecutionSymbol),
                            null,
                            () => complexSymbol.addExecutionSymbol && complexSymbol.addExecutionSymbol(cell),
                            createElement,
                            '',
                        );
                    }

                    addMenuEdgeTypesForSequence(menu, graph, cell, locale, emitter, mode);

                    menu.addItem(
                        graph.intl.formatMessage(messages.splitExecutionSymbol),
                        null,
                        () => complexSymbol.splitExecutionSymbol && complexSymbol.splitExecutionSymbol(cell),
                        null,
                        '',
                    );
                }

                const isSymbolDeletable = UserProfileSelectors.isSymbolDeletable(
                    objectDefinitionNodeId,
                    (value as ObjectInstance)?.symbolId,
                )(state);

                const isCellDeletable = graph.isCellDeletable(cell);

                menu.addSeparator(null, true);

                menu.addItem(
                    graph.intl.formatMessage(messages.delete),
                    null,
                    () => {
                        emitter(handleDeleteSelectedCellsFromActiveGraphAction());
                    },
                    null,
                    '',
                    mode === EditorMode.Edit &&
                        isSymbolDeletable &&
                        isCellDeletable &&
                        isSymbolEditable &&
                        !isDestroySymbol,
                    undefined,
                    `graph-element_context-menu_delete`,
                    'delete',
                );

                if (!isShape) {
                    menu.addSeparator(null, true);

                    const isSymbolReadable = UserProfileSelectors.isSymbolReadable(
                        objectDefinitionNodeId,
                        (value as ObjectInstance)?.symbolId,
                    )(state);

                    menu.addItem(
                        graph.intl.formatMessage(messages.properties),
                        null,
                        () => {
                            emitter(objectPropertyView(cell.id));
                        },
                        null,
                        '',
                        isSymbolReadable,
                        undefined,
                        'properties_graph-element_context-menu',
                        undefined,
                        graph.intl.formatMessage(messages.hotKeys),
                    );
                }
            }
        }

        if (cell.isEdge()) {
            const isEdgeTypeEditable =
                !!presetId &&
                UserProfileSelectors.isEdgeTypeEditable(
                    serverId,
                    presetId,
                    (value as EdgeInstanceImpl).edgeTypeId,
                )(state);
            const isEdgeTypeCreateable =
                !!presetId &&
                UserProfileSelectors.isEdgeTypeCreateable(
                    serverId,
                    presetId,
                    (value as EdgeInstanceImpl).edgeTypeId,
                )(state);

            if (edgeDefinitionId) {
                addDecompositionItem(
                    menu,
                    graph,
                    () =>
                        emitter(
                            objectDecompositionDialogInit({
                                graphId: graph.id,
                                edgeDefinitionId: {
                                    id: edgeDefinitionId!,
                                    serverId: graph.id.serverId,
                                    repositoryId: graph.id.repositoryId,
                                },
                            }),
                        ),
                    isEdgeTypeEditable && isModelEditor && !!(value as EdgeInstanceImpl)?.edgeTypeId,
                );

                menu.addSeparator(null, true);
            }

            menu.addItem(
                graph.intl.formatMessage(messages.objectInTree),
                null,
                () => {
                    emitter(moveToAction(MoveTo.ObjectInTree));
                },
                null,
                '',
                !!edgeDefinitionId,
                undefined,
                'graph-element_context-menu_find-in-tree',
            );

            const isEdgeTypeDeletable =
                !!presetId &&
                UserProfileSelectors.isEdgeTypeDeletable(
                    serverId,
                    presetId,
                    (value as EdgeInstanceImpl)?.edgeTypeId,
                )(state);
            menu.addSeparator(null, true);

            // запуск скрипта для связи
            const onExecute = () =>
                emitter(
                    scriptSelectDialogInit({
                        nodeId: edgeDefinitionId
                            ? {
                                  ...graph.id,
                                  id: edgeDefinitionId,
                              }
                            : undefined,
                        serverId,
                        modelId: graph.id,
                        element: value,
                        elementsIdsList: selectedElementsIds,
                        nodesIdsList: selectedElementsDefinitionsIds,
                        rootType: TreeItemType.ScriptFolder,
                        scriptType: TreeItemType.Script,
                    }),
                );
            // планирование скрипта для связи
            const onSchedule = () => {
                const payload: TScriptSelectDialogInitPayload = {
                    serverId,
                    modelId: graph.id,
                    element: value,
                    elementsIdsList: selectedElementsIds,
                    nodesIdsList: selectedElementsDefinitionsIds,
                    rootType: TreeItemType.ScriptFolder,
                    scriptType: TreeItemType.Script,
                };

                if (edgeDefinitionId)
                    payload.nodeId = {
                        ...graph.id,
                        id: edgeDefinitionId,
                    };

                emitter(openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, payload));
            };
            addScriptMenu(menu, graph, onExecute, onSchedule);

            addMoveMenu(menu, graph, emitter, mode);

            menu.addSeparator(null, true);

            menu.addItem(
                // cоздание определения связи
                graph.intl.formatMessage(messages.createEdgeDefinition),
                null,
                () => {
                    emitter(initEdgeDefinitionCreation({ graphId: graph.id, cell }));
                },
                null,
                '',
                mode === EditorMode.Edit &&
                    !edgeDefinitionId &&
                    isEdgeTypeEditable &&
                    isEdgeTypeCreateable &&
                    value instanceof EdgeInstanceImpl,
                () => {
                    let tooltip = '';
                    if (mode !== EditorMode.Edit) {
                        tooltip = graph.intl.formatMessage(messages.onlyInEditModeAvailable);
                    } else if (edgeDefinitionId) {
                        tooltip = graph.intl.formatMessage(messages.edgeDefinitionExist);
                    } else if (!(isEdgeTypeEditable && isEdgeTypeCreateable)) {
                        tooltip = graph.intl.formatMessage(messages.deniedByProfile);
                    } else if (!(cell.value instanceof EdgeInstanceImpl)) {
                        tooltip = graph.intl.formatMessage(messages.deniedByEdgeType);
                    }

                    return tooltip;
                },
                `graph-element_context-menu_create-definition`,
            );

            addLinkMenuItem(menu, graph, () => emitter(copyLinkAction(graph.id, TreeItemType.Model, value?.id)));

            menu.addItem(
                graph.intl.formatMessage(messages.delete),
                null,
                () => {
                    emitter(handleDeleteSelectedCellsFromActiveGraphAction());
                },
                null,
                '',
                mode === EditorMode.Edit && isEdgeTypeDeletable && !isDestroySymbol,
                undefined,
                `graph-element_context-menu_delete`,
            );
            menu.addSeparator(null, true);
            const { source } = cell;
            const sourceComplexSymbol = ComplexSymbolManager.getComplexSymbolInstance(source);
            menu.addItem(
                graph.intl.formatMessage(messages.edgeFork),
                null,
                () => {
                    (sourceComplexSymbol as LifelineSymbolMainClass).startForkConnection(cell);
                },
                null,
                '',
                mode === EditorMode.Edit && isAllowForkConnection(cell),
                undefined,
                `graph-element_context-menu_edge-fork`,
            );

            const tooltipMessage: string = getTooltipMessage(graph, availableTypes, mode);
            const { enabled }: { enabled: boolean } = isEdgeEnabled(graph, cell, availableTypes, mode);
            const Submenu = menu.addItem(
                graph.intl.formatMessage(messages.typeEdge),
                null,
                () => {},
                null,
                '',
                enabled,
                () => tooltipMessage,
                `graph-element_context-menu_edge-type`,
            );

            if (enabled) {
                addEdgeTypesMenu(menu, emitter, availableTypes, Submenu);
            }

            menu.addSeparator(null, true);

            const isEdgeTypeReadable =
                !!presetId &&
                UserProfileSelectors.isEdgeTypeReadable(
                    serverId,
                    presetId,
                    (value as EdgeInstanceImpl)?.edgeTypeId,
                )(state);

            menu.addItem(
                graph.intl.formatMessage(messages.properties),
                null,
                () => {
                    emitter(objectPropertyView(cell.id));
                },
                null,
                '',
                // для whiteboard не работают свойства связи если добавить к связи текст BPM-2544
                !isWhiteBoard && isEdgeTypeReadable,
                undefined,
                'properties_graph-element_context-menu',
            );
        }

        if (value instanceof CommentMarker) {
            const isAuthor = value.comment.author === user.login;
            menu.addItem(
                graph.intl.formatMessage(messages.deleteCommentMarker),
                null,
                () => {
                    emitter(deleteCommentMarker(value.comment.commentId));
                },
                null,
                '',
                isAuthor,
                undefined,
                'graph-element_context-menu_delete-marker',
            );
        }

        if (value instanceof LayoutInstanceImpl) {
            if (
                value.isPSDCell &&
                value.psdCellMetaInfo!.type !== psdCellType.MAIN_TABLE &&
                value.psdCellMetaInfo!.type !== psdCellType.BPMN_POOL &&
                value.psdCellMetaInfo!.type !== psdCellType.BPMN_LANE
            ) {
                menu.addSeparator(null, true);
                menu.addItem(
                    graph.intl.formatMessage(messages.psdAddColumn),
                    null,
                    () => {
                        emitter(addTableColumn(cell.getId()));
                    },
                    null,
                    '',
                    mode === EditorMode.Edit,
                );
                menu.addItem(
                    graph.intl.formatMessage(messages.psdAddRow),
                    null,
                    () => {
                        emitter(addTableRow(cell.getId()));
                    },
                    null,
                    '',
                    mode === EditorMode.Edit,
                );
                menu.addItem(
                    graph.intl.formatMessage(messages.psdRemoveColumn),
                    null,
                    () => {
                        const isChildren = graph.psdDiagramHandler.isChildElementsInVector(cell, false);
                        const index = graph.psdDiagramHandler.findIndexForCell(cell, false);
                        if (index > 0) {
                            emitter(
                                openDialog(DialogType.PSD_TABLE_DELETE_CONFIRMATION_DIALOG, {
                                    isChildren,
                                    cellId: cell.getId(),
                                    isRow: false,
                                }),
                            );
                        } else {
                            emitter(
                                showNotification({
                                    id: uuid(),
                                    type: NotificationType.PSD_DELETE_FIRST_COLUMN_ERROR,
                                }),
                            );
                        }
                    },
                    null,
                    '',
                    mode === EditorMode.Edit,
                );
                menu.addItem(
                    graph.intl.formatMessage(messages.psdRemoveRow),
                    null,
                    () => {
                        const isChildren = graph.psdDiagramHandler.isChildElementsInVector(cell, true);
                        const index = graph.psdDiagramHandler.findIndexForCell(cell, true);
                        if (index > 0) {
                            emitter(
                                openDialog(DialogType.PSD_TABLE_DELETE_CONFIRMATION_DIALOG, {
                                    isChildren,
                                    cellId: cell.getId(),
                                    isRow: true,
                                }),
                            );
                        } else {
                            emitter(
                                showNotification({
                                    id: uuid(),
                                    type: NotificationType.PSD_DELETE_FIRST_ROW_ERROR,
                                }),
                            );
                        }
                    },
                    null,
                    '',
                    mode === EditorMode.Edit,
                );
            }
            if (
                value.isPSDCell &&
                (value.psdCellMetaInfo!.type === psdCellType.BPMN_POOL ||
                    value.psdCellMetaInfo!.type === psdCellType.BPMN_LANE)
            ) {
                menu.addItem(
                    graph.intl.formatMessage(messages.bpmnAddRow),
                    null,
                    () => {
                        emitter(addBpmnTableRow(cell.getId()));
                    },
                    null,
                    '',
                    mode === EditorMode.Edit,
                );
            }
            if (value.isPSDCell && value.psdCellMetaInfo!.type === psdCellType.BPMN_LANE) {
                menu.addItem(
                    graph.intl.formatMessage(messages.bpmnRemoveRow),
                    null,
                    () => {
                        emitter(removeBpmnTableRow(cell.getId()));
                    },
                    null,
                    '',
                    mode === EditorMode.Edit,
                );
            }
        }
    } else if (graph.bpmMxGraphContext.objectDefinitionCopyPasteContext) {
        generatePasteMenu(menu, graph, emitter, mode, printable);
    }

    menu.type = BPMMxPopupMenuType.DEFAULT;
};

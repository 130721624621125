import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import atributeSelectIcon from '@/resources/icons/atributeSelect.svg';
import deleteIcon from '@/resources/icons/Deleted.svg';
import { NodeId, ReportColumnData } from '@/serverapi/api';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from './ColumnSettings.messages';
import theme from './ColumnSettings.scss';
import {
    reportChangeColumnFilterVisibility,
    reportChangeColumnVisibility,
    reportDeleteColumn,
} from '../../../actions/report.actions';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TAttributeSelectDialog } from './AttributeSelectDialog/AttributeSelectDialog.types';

type TColumnSettingsProps = {
    reportNodeId: NodeId;
    disableDelete: boolean;
    currentColumn: ReportColumnData;
};

export const ColumnSettings: FC<TColumnSettingsProps> = ({ disableDelete, reportNodeId, currentColumn }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const deleteColumnHandler = () => {
        if (currentColumn?.columnId) {
            dispatch(reportDeleteColumn(reportNodeId, currentColumn.columnId));
        }
    };

    const changeVisibilityHandler = () => {
        dispatch(reportChangeColumnVisibility(reportNodeId, currentColumn.columnId));
    };

    const changeFilterHandler = () => {
        dispatch(reportChangeColumnFilterVisibility(reportNodeId, currentColumn.columnId));
    };

    const openDialogHandler = () => {
        const props: TAttributeSelectDialog = {
            reportNodeId,
            currentColumn,
        };
        dispatch(openDialog(DialogType.REPORT_SELECT_COLUMN_ATTRIBUTE_DIALOG, props));
    };

    return (
        <>
            <div className={theme.group}>
                <div>
                    <Checkbox
                        children={intl.formatMessage(messages.visibility)}
                        status={currentColumn.visibilityEnabled}
                        onChange={changeVisibilityHandler}
                    />

                    <Checkbox
                        children={intl.formatMessage(messages.filter)}
                        status={currentColumn.filterEnabled}
                        onChange={changeFilterHandler}
                    />
                </div>
                <div className={theme.attributeInput}>
                    <Input
                        label={intl.formatMessage(messages.attribute)}
                        className={theme.input}
                        name="attributeInput"
                        placeholder={intl.formatMessage(messages.attributePlaceholder)}
                        disabled
                        value={currentColumn?.columnName || ''}
                    />
                    <Button icon={atributeSelectIcon} width={30} onClick={openDialogHandler} />
                </div>
            </div>
            <div className={theme.container}>
                <Button disabled={disableDelete} icon={deleteIcon} onClick={deleteColumnHandler}>
                    {intl.formatMessage(messages.deleteColumn)}
                </Button>
            </div>
        </>
    );
};

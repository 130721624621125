// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertySettings__modelLinkWrapper__PwdCP{padding:10px 0 10px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.PropertySettings__modelLink__qId8c{border:1px solid #8295a3;padding:5px;width:320px;margin-right:10px}.PropertySettings__copyLinkIcon__Ih73H{cursor:pointer}.PropertySettings__publishedInfo___Cfjg{padding:10px 0 10px}.PropertySettings__publishedInfo___Cfjg span{color:var(--ActiveRed700)}.PropertySettings__checkBoxItem__dTLa1 input[type=checkbox]:checked{background-color:var(--ActiveRed700) !important;border-color:var(--ActiveRed700) !important}.PropertySettings__checkBoxItem__dTLa1 .PropertySettings__checkboxTextLabel__JqWCG{color:var(--ActiveRed700)}", "",{"version":3,"sources":["webpack://./src/modules/ObjectPropertiesDialog/components/PropertySettings/PropertySettings.scss"],"names":[],"mappings":"AAAA,2CACI,mBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAEJ,oCACI,wBAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CAEJ,uCACI,cAAA,CAEJ,wCACI,mBAAA,CACA,6CACI,yBAAA,CAIJ,oEACI,+CAAA,CACA,2CAAA,CAEJ,mFACI,yBAAA","sourcesContent":[".modelLinkWrapper {\r\n    padding: 10px 0 10px;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n.modelLink {\r\n    border: 1px solid #8295a3;\r\n    padding: 5px;\r\n    width: 320px;\r\n    margin-right: 10px;\r\n}\r\n.copyLinkIcon {\r\n    cursor: pointer;\r\n}\r\n.publishedInfo {\r\n    padding: 10px 0 10px;\r\n    span {\r\n        color: var(--ActiveRed700);\r\n    }\r\n}\r\n.checkBoxItem {\r\n    input[type='checkbox']:checked {\r\n        background-color: var(--ActiveRed700) !important;\r\n        border-color: var(--ActiveRed700) !important;\r\n    }\r\n    .checkboxTextLabel {\r\n        color: var(--ActiveRed700); \r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelLinkWrapper": "PropertySettings__modelLinkWrapper__PwdCP",
	"modelLink": "PropertySettings__modelLink__qId8c",
	"copyLinkIcon": "PropertySettings__copyLinkIcon__Ih73H",
	"publishedInfo": "PropertySettings__publishedInfo___Cfjg",
	"checkBoxItem": "PropertySettings__checkBoxItem__dTLa1",
	"checkboxTextLabel": "PropertySettings__checkboxTextLabel__JqWCG"
};
export default ___CSS_LOADER_EXPORT___;

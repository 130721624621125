export const MIN_HEADER_SIZE = 60;

export const MIN_LANE_SIZE = 40;

export const CELL_ICON_WIDTH = 32;

export const CELL_TEXT_WIDTH = 24;

export const cellIdDivider = '_______';

export const DEFAULT_OBJECT_SYMBOL_ICON =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiI+DQogICAgPHBhdGggZmlsbD0iI0ZGQkEwMCIgZmlsbC1ydWxlPSJldmVub2RkIg0KICAgICAgICAgIGQ9Ik04IDBoMjBhOCA4IDAgMCAxIDggOHYyMGE4IDggMCAwIDEtOCA4SDhhOCA4IDAgMCAxLTgtOFY4YTggOCAwIDAgMSA4LTh6Ii8+DQogICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIg0KICAgICAgICAgIGQ9Ik0yMyAyMXY1SDEzdi01aC0ydi0yaDE0djJoLTJ6bS0yIDBoLTZ2M2g2di0zem0tMi45OTgtNS45OTRDMjEuMDQgMTUuMDA2IDIyIDE2Ljc1IDIyIDE4aC04YzAtMS4zNzUgMS4xNDgtMi45OTQgNC4wMDItMi45OTR6TTE4IDE0YTIgMiAwIDEgMS0uMDAxLTMuOTk5QTIgMiAwIDAgMSAxOCAxNHoiLz4NCjwvc3ZnPg0K';

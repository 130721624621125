import { call, put, select } from 'redux-saga/effects';
import { TServerEntity } from '../../models/entities.types';
import { ServerSelectors } from '../../selectors/entities/server.selectors';
import { isUndefined } from 'is-what';
import { EdgeDefinitionNode, FullModelDefinition, ModelNode, ModelType, NodeId, Symbol } from '../../serverapi/api';
import {
    workspaceActivateTab,
    workspaceAddTab,
    workspaceRemoveTab,
} from '../../actions/tabs.actions';
import { getContentLoadingPageTab, modelContextByGraphId } from '../utils';
import { IModelContext } from '../utils.types';
import { EDITOR_CREATED } from '../../actionsTypes/editor.actionTypes';
import { editorDestroy, editorInit, moveToDirectAction } from '../../actions/editor.actions';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../../models/bpm/bpm-model-impl';
import { IModelNode } from '../../models/bpm/bpm-model-impl.types';
import { LOAD_MODEL_BY_ID, RELOAD_MODEL, RESTORE_MODEL_VERSION } from '../../actionsTypes/loadModel.actionTypes';
import {
    TLoadModelByIdAction,
    TReloadModelAction,
    TRestoreModelVersionAction,
} from '../../actions/loadModel.actions.types';
import { TabsSelectors } from '../../selectors/tabs.selectors';
import { objectDefinitionService } from '../../services/ObjectDefinitionService';
import { recentAddModel } from '../../actions/recent.actions';
import { modelRequestSuccess } from '../../actions/model.actions';
import { TWorkspaceTab, IWorkspaceTabItemModelParams } from '../../models/tab.types';
import { EditorMode } from '../../models/editorMode';
import { ModelTypeSelectors } from '../../selectors/modelType.selectors';
import { TreeSelectors } from '../../selectors/tree.selectors';
import { clearGraph, addElementsToGraph } from '../../mxgraph/util/BpmMxEditorUtils';
import { showNotificationByType } from '../../actions/notification.actions';
import { NotificationType } from '../../models/notificationType';
import { modelService } from '../../services/ModelService';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { updateAllCellsOverlays } from '../../actions/overlay.actions';
import { objectDefinitionsAdd } from '../../actions/entities/objectDefinition.actions';
import { TabsBusActions } from '../../actionsTypes/tabsBus.actionTypes';
import { BPMMxGraph } from '../../mxgraph/bpmgraph';
import { instancesBPMMxGraphMap } from '../../mxgraph/bpm-mxgraph-instance-map';
import { MxCell } from '../../mxgraph/mxgraph';
import { onCentered, onFoundVisible } from '../../services/bll/SearchByModelBllService';
import { TreeDaoService } from '../../services/dao/TreeDaoService';
import { treeItemAdd } from '../../actions/tree.actions';
import { TreeNode } from '../../models/tree.types';
import messages from '../../modules/Workspace/components/Workspace/Workspace.messages';
import { LoadModelDAOService } from '../../services/dao/LoadModelDAOService';
import { edgeDefinitionsAdd } from '../../actions/entities/edgeDefinition.actions';
import { LocalesService } from '../../services/LocalesService';
import { presetLoadModelTypes } from '../notation.saga';
import { LocalStorageDaoService } from '../../services/dao/LocalStorageDaoService';
import { SymbolSelectors } from '../../selectors/symbol.selectors';
import { loadComments } from '../../actions/comments.actions';
import { CommentsSelectors } from '../../selectors/comments.selectors';
import { takeEverySafely } from '../sagaHelpers';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { clearContainmentAllowedSymbolIds } from '@/actions/containmentAllowedSymbolIds.actions';

function* handleRestoreModelVersion({ payload: { nodeId, version } }: TRestoreModelVersionAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));

    if (isUndefined(server)) {
        throw new Error(`Cannot find server with ID=${nodeId.id}`); // tslint:disable-line:no-console
    }

    const modelData: IModelNode = yield call(restoreModel, nodeId, version);

    if (isUndefined(modelData) || isUndefined(modelData.modelTypeId)) {
        yield put(showNotificationByType(NotificationType.MODEL_RESTORE_VERSION_ERROR));

        return;
    }
    // todo грузить модель вместе с объектами
    yield call(fetchModelDefinitions, server.id, nodeId.repositoryId, modelData);

    const isGraphHasCommentPanel: boolean = yield select(CommentsSelectors.isGraphHasCommentPanel(nodeId));
    yield put(loadComments(nodeId, isGraphHasCommentPanel));

    yield updateModelGraph(nodeId, modelData, server.url);
    yield put(showNotificationByType(NotificationType.MODEL_RESTORE_VERSION_SUCCESS));
}

function* handleReloadModel({ payload: { nodeId } }: TReloadModelAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));

    if (isUndefined(server)) {
        throw new Error(`Cannot find server with ID=${nodeId.id}`); // tslint:disable-line:no-console
    }

    const { model }: FullModelDefinition = yield call(loadModelRequest, nodeId);

    if (isUndefined(model) || isUndefined(model.modelTypeId)) {
        yield put(showNotificationByType(NotificationType.MODEL_REFRESH_ERROR));

        return;
    }

    const isGraphHasCommentPanel: boolean = yield select(CommentsSelectors.isGraphHasCommentPanel(nodeId));
    yield put(loadComments(nodeId, isGraphHasCommentPanel));

    yield updateModelGraph(nodeId, model, server.url);
    yield put(showNotificationByType(NotificationType.MODEL_REFRESH_SUCCESS));
}

function* handleLoadModelById({ payload: { nodeId, elementIds } }: TLoadModelByIdAction) {
    yield put(clearContainmentAllowedSymbolIds());
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    if (isUndefined(server)) {
        throw new Error(`Cannot find server with ID=${nodeId.id}`); // tslint:disable-line:no-console
    }

    // проверка если модель уже открыта
    const schema = yield select(TabsSelectors.byId(nodeId));
    if (!isUndefined(schema)) {
        yield put(workspaceActivateTab(schema));

        selectModelElements(nodeId, elementIds);

        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);

        return;
    }

    const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);
    const presetId: string = yield select(TreeSelectors.presetById(nodeId));

    let fullModelDefinition: FullModelDefinition;
    try {
        yield put(workspaceAddTab(contentLoadingPageTab));
        yield presetId && presetLoadModelTypes(nodeId.serverId, presetId);

        fullModelDefinition = yield call(loadModelRequest, nodeId);

    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    } finally {
        yield put(workspaceRemoveTab(contentLoadingPageTab));
    }

    const model = fullModelDefinition.model;

    const modelType: ModelType | undefined = yield select(
        ModelTypeSelectors.byId({ modelTypeId: model.modelTypeId || '', serverId: server.id }, presetId),
    );
    if (model.modelTypeId === 'bpmn2') {
        yield put(showNotificationByType(NotificationType.BPMN_DATA_WARNING));
    }
    const intl = LocalesService.useIntl();

    const symbols: Symbol[] = yield select(SymbolSelectors.byServerIdPresetId(server.id, presetId));
    const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
        title: model.name || intl.formatMessage(messages.unknownModel),
        type: 'Editor',
        nodeId,
        content: model,
        mode: EditorMode.Read,
        params: {
            closable: true,
            serverId: server.id,
            modelType,
            symbols,
            filters: {},
            graph: model.graph ? JSON.parse(model.graph).graph : [], // todo возможно ли с бэка передавать объект а не строку?
            selectedIds: elementIds,
        } as IWorkspaceTabItemModelParams,
    };

    yield put(workspaceAddTab(workspaceTab));
    yield put(loadComments(nodeId));
}

function* handleEditorInit({ payload: { nodeId } }) {


    try {
        const modelContext: IModelContext | undefined = yield call(fillGraphWithData, nodeId);

        yield put(editorInit({ nodeId }));

        if (modelContext) {
            modelContext.graph.undoManager.clear();
            modelContext.graph.setDirty(false);
            yield afterModelOpenActions(modelContext);
        }

        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        yield put(editorDestroy({ nodeId }));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

function* afterModelOpenActions(modelContext: IModelContext) {

    const model: ModelNode | undefined = modelContext?.schema.content;

    const nodeId = modelContext.nodeId;
    const modelTypeId = model?.modelTypeId || '';
    const parentNodeId = model?.parentNodeId;

    const presetId: string = yield select(TreeSelectors.presetById(nodeId));
    const modelType: ModelType | undefined = yield select(
        ModelTypeSelectors.byId({ modelTypeId, serverId: nodeId.serverId }, presetId),
    );



    yield put(
        recentAddModel({
            nodeId: nodeId,
            type: TreeItemType.Model,
            parentId: (modelContext?.schema.content as IModelNode).parentNodeId,
            createdAt: new Date().toISOString(),
            title: modelContext?.schema?.content?.name,
            modelTypeId,
            modelTypeName: (modelType && (modelType.description || modelType.name)) || '',
        }),
    );

    yield put(
        moveToDirectAction(
            {
                hasChildren: false,
                nodeId,
                name: '',
                type: TreeItemType.Model,
                countChildren: 0,
            },
            NAVIGATOR_STRUCTURE,
        ),
    );

    // При открытии модели подгружаем в state.tree родительскую папку, если она не загружена
    // Нужно для вставки копии определения объекта
    if (parentNodeId) {
        const parentTreeNode: TreeNode = yield select(TreeSelectors.itemById(parentNodeId));
        if (!parentTreeNode) {
            const parentFolderType: TreeNode = yield call(() =>
                TreeDaoService.getNode(parentNodeId.serverId, parentNodeId)
                    .then((res) => res)
                    .catch(() => undefined),
            );

            if (parentFolderType) {
                yield put(treeItemAdd(parentFolderType));
            }
        }
    }


}

function* fillGraphWithData(modelNodeId: NodeId) {
    const modelContext: IModelContext | null = yield modelContextByGraphId(modelNodeId);

    if (!modelContext || !modelContext.graph) return;

    const { graph } = modelContext;
    graph.id = modelNodeId;

    return modelContext;
}

function selectModelElements(nodeId: NodeId, elementIds?: Array<string | undefined>) {
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(nodeId);
    const actualIds = elementIds?.filter((id?: string) => !!id);

    if (graph && actualIds?.length) {
        const cells: MxCell[] = actualIds.map((id: string) => graph.getModel()?.getCell(id));

        onCentered(cells[0], graph);

        if (graph.mode === EditorMode.Edit) {
            graph.setSelectionCells(cells);
        } else {
            onFoundVisible(cells, graph);
        }
    }
}

function* fetchModelDefinitions(serverId: string, repositoryId: string, modelData: IModelNode) {
    if (modelData.elements) {
        const objectInstances = modelData.elements
            .filter((e) => e.type === 'object')
            .map((e) => new ObjectInstanceImpl(e));
        const objectDefinitionOnlyStringIDs = objectInstances
            .map((instance): string => instance.objectDefinitionId || '')
            .filter((s) => s);

        yield objectDefinitionService().loadObjects(serverId, repositoryId, objectDefinitionOnlyStringIDs);
    }
}

function* loadModelRequest(modelId: NodeId) {
    const modelDefinition: FullModelDefinition = yield LoadModelDAOService.loadFullModelData(modelId);

    if (isUndefined(modelDefinition.model)) {
        throw new Error();
    }

    yield put(modelRequestSuccess(modelDefinition.model));
    yield put(objectDefinitionsAdd(<ObjectDefinitionImpl[]>modelDefinition.objects));
    yield put(edgeDefinitionsAdd(<EdgeDefinitionNode[]>modelDefinition.edges));

    return modelDefinition;
}

function* restoreModel(modelId: NodeId, version: number) {
    const data: IModelNode = yield modelService().restoreModel(modelId, version);

    yield put(modelRequestSuccess(data));

    return data;
}

function* updateModelGraph(nodeId: NodeId, modelData: ModelNode, serverURL: string) {
    yield put(clearContainmentAllowedSymbolIds());
    const modelContext: IModelContext | null = yield call(fillGraphWithData, nodeId);

    if (!modelContext) return;

    clearGraph(modelContext.graph);

    const modelGraph: MxCell[] = modelData.graph ? JSON.parse(modelData.graph).graph : []; // todo возможно ли с бэка передавать объект а не строку?

    const { graph } = modelContext;
    try {
        graph.beginRefresh();
        addGridLayout(graph, modelData);
        addElementsToGraph(graph, modelGraph, modelData.elements, serverURL);
    } catch (e) {
        console.error(e);
    } finally {
        graph?.endRefresh();
    }

    yield put(updateAllCellsOverlays(modelContext.graph.id));

    modelContext.graph.setDirty(false);
}

function addGridLayout(graph: BPMMxGraph, modelData: ModelNode) {
    const { layout, name } = modelData;

    if (!layout) {
        return;
    }

    graph?.createGrid(layout, name);
}

export function* loadModelSaga() {
    yield takeEverySafely(LOAD_MODEL_BY_ID, handleLoadModelById);
    yield takeEverySafely(EDITOR_CREATED, handleEditorInit);
    yield takeEverySafely(RELOAD_MODEL, handleReloadModel);
    yield takeEverySafely(RESTORE_MODEL_VERSION, handleRestoreModelVersion);
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edgeStyles__container___3i80{padding:16px;border-bottom:1px solid var(--panelDivider)}.edgeStyles__blockName__bKrdy{font-size:14px;font-weight:400;font-family:\"Segoe UI\";line-height:22px;padding-bottom:8px}.edgeStyles__valueContainer___RFjf{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.edgeStyles__typeContainer___w_so{margin-left:8px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:calc(100% - 24px)}", "",{"version":3,"sources":["webpack://./src/modules/FormatPanel/components/EdgeStyles/edgeStyles.scss"],"names":[],"mappings":"AAAA,8BACI,YAAA,CACA,2CAAA,CAGJ,8BACI,cAAA,CACA,eAAA,CACA,sBAAA,CACA,gBAAA,CACA,kBAAA,CAGJ,mCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CAGJ,kCACI,eAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,uBAAA","sourcesContent":[".container {\r\n    padding: 16px;\r\n    border-bottom: 1px solid var(--panelDivider);\r\n}\r\n\r\n.blockName {\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    font-family: 'Segoe UI';\r\n    line-height: 22px;\r\n    padding-bottom: 8px;\r\n}\r\n\r\n.valueContainer {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.typeContainer {\r\n    margin-left: 8px;\r\n    white-space: nowrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    width: calc(100% - 24px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "edgeStyles__container___3i80",
	"blockName": "edgeStyles__blockName__bKrdy",
	"valueContainer": "edgeStyles__valueContainer___RFjf",
	"typeContainer": "edgeStyles__typeContainer___w_so"
};
export default ___CSS_LOADER_EXPORT___;

import { defineMessages } from 'react-intl';

export default defineMessages({
    InsertHorizontal: {
        id: 'EditorBtns.InsertHorizontal',
        defaultMessage: 'По горизонтали',
    },
    InsertVertical: {
        id: 'EditorBtns.InsertVertical',
        defaultMessage: 'По вертикали',
    },
    DeleteHorizontal: {
        id: 'EditorBtns.DeleteHorizontal',
        defaultMessage: 'Удалить по горизонтали',
    },
    DeleteVertical: {
        id: 'EditorBtns.DeleteVertical',
        defaultMessage: 'Удалить по вертикали',
    },
});

import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../../../Toolbar/ResizableToolbar/ButtonGroups/groups.scss';
import { Select } from '../../../Select/Select.component';
import { fontSizeOptions } from '../../../../../../utils/configuration';
import classNames from 'classnames';
import { TValue } from '../../../Toolbar/ResizableToolbar/ButtonGroups/toolbarSelect.types';
import { ClearValueButton } from '../../../Toolbar/ClearValuesBtn/ClearValuesButton.component';
import { AddNewValueButton } from '../../../Toolbar/AddNewValueBtn/AddNewValueButton.component';
import { DEFAULT_FONT_SIZE_OPTION } from '../../../TipTapTextEditor/Toolbar/controls/Controls.constants';

const getFocusedBlock = (editorState) => {
    const fontFamilyStyle = getSelectionCustomInlineStyle(editorState, ['FONTSIZE']);
    if (fontFamilyStyle) {
        return fontFamilyStyle.FONTSIZE?.replace('fontsize-', '');
    }

    return undefined;
};

const getChangedBlock = (editorState, value) => toggleCustomInlineStyle(editorState, 'fontSize', value);

const validateNumber = (rule: {}, value: string, callback: (msg?: string) => void) => {
    const pattern: RegExp = /^\d+$/;
    if (!pattern.test(value) || value === '0') {
        callback('not number');
    } else {
        callback();
    }
};

const FontSizeComponent = () => {
    const intl = useIntl();
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const [additionalValues, setAdditionalValue] = useState<TValue[]>([]);

    const handleAdd = (value: string) => {
        setAdditionalValue([
            ...additionalValues,
            {
                value: value,
                label: value,
            },
        ]);
    };

    const onChangeHandler = (value: string) => {
        if (['del', 'add'].includes(value)) return;
        setCurrentState(value);
    };

    return (
        <Select
            onChange={onChangeHandler}
            value={value || DEFAULT_FONT_SIZE_OPTION.label}
            placeholder=""
            originalTheme
            data-test="wiki-toolbar-group_select-font-size-button"
            wrapperClassName={classNames(theme.fontSizeContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            tooltip={intl.formatMessage(messages.fontSize)}
        >
            {[
                ...fontSizeOptions,
                ...additionalValues,
                !!additionalValues.length && {
                    value: 'del',
                    label: <ClearValueButton onClicked={() => setAdditionalValue([])} />,
                },
                {
                    value: 'add',
                    label: (
                        <AddNewValueButton
                            onAddClicked={(value) => handleAdd(value)}
                            title={intl.formatMessage(messages.formLabel)}
                            validationRules={[
                                { required: true, message: intl.formatMessage(messages.formValidationMessageRequired) },
                                {
                                    validator: validateNumber,
                                    message: intl.formatMessage(messages.formValidationMessageNumberOnly),
                                },
                            ]}
                        />
                    ),
                },
            ]
                .filter((option): option is TValue => !!option)
                .map((style) => {
                    return <Select.Option value={style.value} label={style.label} />;
                })}
        </Select>
    );
};

export default FontSizeComponent;

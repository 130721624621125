import React, { FC } from 'react';
import theme from './DropdownButton.scss';
import { Select } from '../../UIKit/components/Select/Select.component';
import { TDropdownButtonProps } from './DropdownButton.types';
import { OptionItem } from './OptionItem.component';
import classNames from 'classnames';

type TItem = {
    key: string;
    label: JSX.Element;
    optionDisaibled: boolean | undefined;
    value: string;
    onclick: () => void;
};

export const DropdownButton: FC<TDropdownButtonProps> = (props) => {
    const { actions, disabled, spriteSymbol, dropdownAlignment, dataTest, size = 'middle', tooltip, className } = props;

    const items: TItem[] = actions.map((action) => ({
        key: action.title,
        label: <OptionItem title={action.title} icon={action.icon} />,
        optionDisaibled: action.actionDisabled,
        value: action.title,
        onclick: action.onClick,
    }));

    return (
        <div className={classNames(theme.selectContainer, className)}>
            <Select
                placeholder=""
                dropdownClassName={theme.drbContainer}
                dropdownWidth={160}
                disabled={disabled}
                wrapperClassName={classNames(theme.wrapperContainer, size === 'small' ? theme.small : undefined)}
                onChange={(value) => {
                    items.find((item) => item.value === value)?.onclick();
                }}
                dropdownAlignment={dropdownAlignment || 'right'}
                iconBtnView={{ icon: spriteSymbol }}
                data-test={dataTest}
                tooltip={tooltip}
            >
                {items.map((item) => (
                    <Select.Option
                        value={item.value}
                        key={item.key}
                        label={item.label}
                        optionDisaibled={item.optionDisaibled}
                    />
                ))}
            </Select>
        </div>
    );
};

import { connect } from 'react-redux';
import { Header } from '../components/Header/Header.component';
import { TRootState } from '../../../reducers/root.reducer.types';
import { appCloseRequest, appMinimizeRequest, appSizeToggle } from '../../../actions/app.actions';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { localeSelect } from '../../../actions/locale.actions';
import electron from '../../../electron';
import { Locale, THeaderProps } from '../components/Header/header.types';
import { LocalStorageDaoService } from '../../../services/dao/LocalStorageDaoService';
import { getIsEnLocaleAllowed } from '@/selectors/app.selector';
import { openUserAccountTab } from '../../../actions/userAccount.actions';

const mapStateToProps = (state: TRootState, props: Partial<THeaderProps>) => {
    const clientIcon: string | null = LocalStorageDaoService.getGreetingLogo();

    return {
        isVisibleButtonActions: !!electron,
        isMaximized: state.app.isMaximized,
        locale: getCurrentLocale(state),
        isStarted: state.app.isStarted,
        isEnLocaleAllowed: getIsEnLocaleAllowed(state),
        clientIcon,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClose: () => {
        dispatch(appCloseRequest());
    },
    onToggleSize: () => {
        dispatch(appSizeToggle());
    },
    onMinimize: () => {
        dispatch(appMinimizeRequest());
    },
    onLocaleSelect: (selected: Locale) => {
        LocalStorageDaoService.setLocale(selected);
        dispatch(localeSelect(selected));
    },
    onClickInitialsCircle: () => {
        dispatch(openUserAccountTab());
    },
});

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

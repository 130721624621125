import { defineMessages } from 'react-intl';

export default defineMessages({
    USERS_EDITOR: {
        id: 'UserAccesses.USERS_EDITOR',
        defaultMessage: 'Управление пользователями',
    },
    DB_EDITOR: {
        id: 'UserAccesses.DB_EDITOR',
        defaultMessage: 'Создавать БД',
    },
    DB_ADMIN: {
        id: 'UserAccesses.DB_ADMIN',
        defaultMessage: 'Администрировать БД ',
    },
    SCRIPT_EDITOR: {
        id: 'UserAccesses.SCRIPT_EDITOR',
        defaultMessage: 'Создавать/редактировать скрипты',
    },
    SCRIPT_RUNNER: {
        id: 'UserAccesses.SCRIPT_RUNNER',
        defaultMessage: 'Запускать скрипты',
    },
    META_EDITOR: {
        id: 'UserAccesses.META_EDITOR',
        defaultMessage: 'Создавать/редактировать методологию',
    },
    LICENSE_EDITOR: {
        id: 'UserAccesses.LICENSE_EDITOR',
        defaultMessage: 'Управлять лицензиями',
    },
    SECURITY_ADMIN: {
        id: 'UserAccesses.SECURITY_ADMIN',
        defaultMessage: 'Управление сессиями',
    },
    LOG_READER: {
        id: 'UserAccesses.LOG_READER',
        defaultMessage: 'Просмотр журналов аудита',
    },
    SERVER_ADMIN: {
        id: 'UserAccesses.SERVER_ADMIN',
        defaultMessage: 'Управление настройками сервера',
    },
    PROFILE_EDITOR: {
        id: 'UserAccesses.PROFILE_EDITOR',
        defaultMessage: 'Редактирование профилей сервера',
    },
    EVENT_ACCESS: {
        id: 'UserAccesses.EVENT_ACCESS',
        defaultMessage: 'Настройка обработки событий',
    },
    ACCESS_KEY_ADMIN: {
        id: 'UserAccesses.ACCESS_KEY_ADMIN',
        defaultMessage: 'Управление ключами доступа',
    },
    SHOW_DELETED_OBJECT: {
        id: 'UserAccesses.SHOW_DELETED_OBJECT',
        defaultMessage: 'Видимость удаленных объектов',
    },
    APPROVAL_ADMIN: {
        id: 'UserAccesses.APPROVAL_ADMIN',
        defaultMessage: 'Управление согласованиями',
    },
    DB_AUDIT: {
        id: 'UserAccesses.DB_AUDIT',
        defaultMessage: 'Аудит БД',
    },
    SERVER_AUDIT: {
        id: 'UserAccesses.SERVER_AUDIT',
        defaultMessage: 'Аудит сервера',
    },
    LOCK_ADMIN: {
        id: 'UserAccesses.LOCK_ADMIN',
        defaultMessage: 'Администратор блокировок',
    },
    PUBLISHER: {
        id: 'UserAccesses.PUBLISHER',
        defaultMessage: 'Публикация моделей',
    },
    TS_PD_EDITOR: {
        id: 'UserAccesses.TS_PD_EDITOR',
        defaultMessage: 'Управление КТ/ПД в интерфейсе',
    },
    SIMULATION_EXECUTE: {
        id: 'UserAccesses.SIMULATION_EXECUTE',
        defaultMessage: 'Запуск имитационного моделирования',
    },
});

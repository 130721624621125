import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { SearchResultWidgetDTO, UITemplateDTO } from '../../../serverapi/api';
import { ApiBundle } from '@/services/api/api-bundle';
import { TSearchResultWidget } from '../components/Widgets/widget.types';

function widgetsDataToString(UITemplate: UITemplateDTO): UITemplateDTO {
    const preparedUITemplate: UITemplateDTO = {
        ...UITemplate,
        widgets: UITemplate.widgets.map((widget) => {
            if (widget.type === 'SEARCH_RESULT') {
                const { searchRequests, showElementType, showNodeType } = widget as TSearchResultWidget;
                const searchResultWidgetData: string = JSON.stringify({
                    searchRequests,
                    showElementType,
                    showNodeType,
                });
                const searchResultWidget: SearchResultWidgetDTO = { ...widget, widgetData: searchResultWidgetData };

                return searchResultWidget;
            }

            return widget;
        }),
    };

    return preparedUITemplate;
}

function widgetsDataToObject(UITemplate: UITemplateDTO): UITemplateDTO {
    const preparedUITemplate: UITemplateDTO = {
        ...UITemplate,
        widgets: UITemplate.widgets.map((widget) => {
            if (widget.type === 'SEARCH_RESULT') {
                try {
                    const { widgetData } = widget as SearchResultWidgetDTO;
                    const searchResultWidgetData = widgetData ? JSON.parse(widgetData) : {};
                    const searchResultWidget: TSearchResultWidget = { ...widget, ...searchResultWidgetData };

                    return searchResultWidget;
                } catch {
                    return widget;
                }
            }

            return widget;
        }),
    };

    return preparedUITemplate;
}

export class UITemplatesDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getUITemplates() {
        const api: ApiBundle = UITemplatesDaoService.getApi();
        const UITemplates: UITemplateDTO[] = await api.UITemplates.getAll();

        return UITemplates.map(widgetsDataToObject);
    }

    public static async saveUITemplate(template: UITemplateDTO) {
        const api: ApiBundle = UITemplatesDaoService.getApi();
        const preparedUITemplate: UITemplateDTO = widgetsDataToString(template);
        await api.UITemplates.save({ body: preparedUITemplate });
    }

    public static async deleteUITemplate(templateId: string) {
        const api: ApiBundle = UITemplatesDaoService.getApi();
        await api.UITemplates._delete({ id: templateId });
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FontBlock__fontContainer___ngPy{display:-webkit-box;display:-ms-flexbox;display:flex}.FontBlock__fontFamilyContainer__v0rwT{-webkit-box-flex:2;-ms-flex-positive:2;flex-grow:2}.FontBlock__fontSizeContainer___ugI4{width:60px;margin-left:8px}.FontBlock__container__EBMAK{padding:16px;border-bottom:1px solid var(--panelDivider)}.FontBlock__blockName__nagEE{font-size:14px;font-weight:400;font-family:\"Segoe UI\",Tahoma,Geneva,Verdana,sans-serif;line-height:22px;padding-bottom:8px}.FontBlock__alignContainer__Qxd2A{display:-webkit-box;display:-ms-flexbox;display:flex;margin-top:8px;width:-webkit-max-content;width:-moz-max-content;width:max-content}", "",{"version":3,"sources":["webpack://./src/modules/FormatPanel/components/FontBlock/FontBlock.scss"],"names":[],"mappings":"AAAA,iCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGJ,uCACI,kBAAA,CAAA,mBAAA,CAAA,WAAA,CAGJ,qCACI,UAAA,CACA,eAAA,CAGJ,6BACI,YAAA,CACA,2CAAA,CAGJ,6BACI,cAAA,CACA,eAAA,CACA,uDAAA,CACA,gBAAA,CACA,kBAAA,CAGJ,kCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,cAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA","sourcesContent":[".fontContainer {\r\n    display: flex;\r\n}\r\n\r\n.fontFamilyContainer {\r\n    flex-grow: 2;\r\n}\r\n\r\n.fontSizeContainer {\r\n    width: 60px;\r\n    margin-left: 8px;\r\n}\r\n\r\n.container {\r\n    padding: 16px;\r\n    border-bottom: 1px solid var(--panelDivider);\r\n}\r\n\r\n.blockName {\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    line-height: 22px;\r\n    padding-bottom: 8px;\r\n}\r\n\r\n.alignContainer {\r\n    display: flex;\r\n    margin-top: 8px;\r\n    width: max-content;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontContainer": "FontBlock__fontContainer___ngPy",
	"fontFamilyContainer": "FontBlock__fontFamilyContainer__v0rwT",
	"fontSizeContainer": "FontBlock__fontSizeContainer___ugI4",
	"container": "FontBlock__container__EBMAK",
	"blockName": "FontBlock__blockName__nagEE",
	"alignContainer": "FontBlock__alignContainer__Qxd2A"
};
export default ___CSS_LOADER_EXPORT___;

import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TConfirmationDialogProps } from '@/modules/dialogs/ConfirmationDialog/ConfirmationDialog.types';
import { LocalesService } from '@/services/LocalesService';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { UITemplateEditorDeleteWidget } from '../actions/UITemplateEditor.actions';
import { NodeId, WidgetDTO } from '@/serverapi/api';
import messages from '../components/CreateUITemplateTab/CreateUITemplateTab.messages';
import { DialogsSelectors } from '@/selectors/dialogs.selectors';
import { TabsSelectors } from '@/selectors/tabs.selectors';

type TUseKeydownHandlerParams = {
    nodeId: NodeId;
    selectedWidget: WidgetDTO | undefined;
};

export const useKeydownHandler = ({ nodeId, selectedWidget }: TUseKeydownHandlerParams) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const isVisibleDialog: boolean = useSelector(DialogsSelectors.isVisibleDialog);
    const isTabActive: boolean = useSelector(TabsSelectors.isTabActive(nodeId));

    useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
            if (
                (event.key === 'Delete' || event.key === 'Backspace') &&
                selectedWidget &&
                isTabActive &&
                !isVisibleDialog
            ) {
                const dialogProps: TConfirmationDialogProps = {
                    title: '',
                    OKButtonText: intl.formatMessage(messages.delete),
                    question: intl.formatMessage(messages.deleteWidgetQuestion, {
                        name: LocalesService.internationalStringToString(selectedWidget.name),
                    }),
                    onSubmit: () => dispatch(UITemplateEditorDeleteWidget(nodeId.id, selectedWidget.id)),
                };

                dispatch(openDialog(DialogType.CONFIRMATION, dialogProps));
            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [selectedWidget, isTabActive, isVisibleDialog]);
};

import type { NodeId } from '@/serverapi/api';

export const DEPRECATED_WIKI_CONTENT_PROP_NAME = 'source';

export const WIKI_CONTENT_PROP_NAME = 'content';

export const EMPTY_WIKI_CONTENT = { content: [], type: 'doc' };

export const getWikiImageSrc = (fileId: NodeId, modelId: NodeId) =>
    `${window.location.origin}/api/wiki/${fileId.repositoryId}/${modelId.id}/image/${fileId.id}/`;

export const getModelImageSrc = (modelId: NodeId) =>
    `${window.location.origin}/api/model/${modelId.repositoryId}/${modelId.id}/image/png`;

export const getFileImageSrc = (fileNodeId: NodeId) =>
    `${window.location.origin}/api/file/download/${fileNodeId.repositoryId}/${fileNodeId.id}/`;

import React, { FC, useCallback, useEffect, useState } from 'react';
import theme from './ActionButtons.scss';
import messages from './ActionButtons.messages';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Dropdown, MenuProps } from 'antd';
import { Icon } from '@/modules/UIKit';
import editIco from '@/resources/icons/edit.svg';
import deletedIco from '@/resources/icons/Deleted.svg';
import dotsIco from '@/resources/icons/dots.svg';
import dotsGreyIco from '@/resources/icons/dotsGrey.svg';
import { getMenuItem } from '@/utils/antdMenuItem.utils';
import { MenuInfo } from 'rc-menu/lib/interface';
import { deleteUITemplate, openUITemplateEditorTab } from '@/modules/UITemplates/actions/UITemplates.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TConfirmationDialogProps } from '@/modules/dialogs/ConfirmationDialog/ConfirmationDialog.types';

type TActionButtonsProps = {
    templateId: string;
    templateName: string;
    clickedDropdownId: string;
    setClickedDropdown: (id: string) => void;
};

export const ActionButtons: FC<TActionButtonsProps> = ({
    templateId,
    templateName,
    clickedDropdownId,
    setClickedDropdown,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (clickedDropdownId !== templateId) {
            setOpen(false);
        }
    }, [clickedDropdownId]);

    const toggleOpen = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const deleteTemplateHandler = () => {
        const props: TConfirmationDialogProps = {
            OKButtonText: intl.formatMessage(messages.delete),
            question: intl.formatMessage(messages.deleteQuestion, { name: templateName }),
            title: '',
            onSubmit: () => dispatch(deleteUITemplate(templateId)),
        };

        dispatch(openDialog(DialogType.CONFIRMATION, props));
    };

    const editTemplateHandler = () => {
        dispatch(openUITemplateEditorTab(templateId));
    };

    const onClick = (param: MenuInfo) => {
        param.domEvent.stopPropagation();

        switch (param.key) {
            case `${templateId}_edit`:
                editTemplateHandler();
                break;
            case `${templateId}_delete`:
                deleteTemplateHandler();
                break;
            default:
                break;
        }

        setOpen(false);
    };

    const items: MenuProps['items'] = [
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.edit)}</div>,
            `${templateId}_edit`,
            false,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={editIco} />
            </div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.delete)}</div>,
            `${templateId}_delete`,
            false,
            <div className={theme.iconContainer}>
                <Icon spriteSymbol={deletedIco} />
            </div>,
        ),
    ];

    return (
        <Dropdown
            onOpenChange={toggleOpen}
            open={open}
            menu={{ onClick, items }}
            trigger={['click']}
            destroyPopupOnHide
            overlayClassName={theme.dropdown}
        >
            <Icon
                onClick={(e) => {
                    setClickedDropdown(templateId);
                    e?.stopPropagation();
                }}
                className={theme.dropdownTriggerButton}
                spriteSymbol={open ? dotsIco : dotsGreyIco}
            />
        </Dropdown>
    );
};

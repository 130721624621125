import type { TCrossFunctionalFlowchartSymbolCustomProps } from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.class.types';
import React, { useMemo, useState } from 'react';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import theme from './CreateGraphSymbolDialog.scss';
import { WrappedComponentProps, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import messages from './CreateGraphSymbolDialog.messages';
import { Table, Form } from 'antd';
import { Symbol } from '../../serverapi/api';
import { ControledValue } from './ControledValue.component';
import { Rule } from 'rc-field-form/es/interface';
import { getNumberInputValidateCondition } from './getNumberInputValidateCondition';
import { DialogFooterButtons } from '../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { Checkbox } from '../UIKit/components/Checkbox/Checkbox.component';

const DEFAULT_COUNT_VALUE = '3';

export const MAX_ROW_COL_COUNTS = 20;

const MAX_TITLE_LENGTH = 100;

type TCreateCrossSymbolDialogProps = WrappedComponentProps & {
    open: boolean;
    link: string;
    symbol: Symbol;
    submit: (resultParams: TCrossFunctionalFlowchartSymbolCustomProps) => void;
    cancel: () => void;
};

export const CreateCrossSymbolDialog: React.FC<TCreateCrossSymbolDialogProps> = ({ open, submit, cancel }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const onCancelHandler = () => {
        if (cancel) {
            cancel();
        }
        dispatch(closeDialog(DialogType.CROSS_SYMBOL_CREATE_DIALOG));
    };
    const [hideHeaders, setHideHeaders] = useState<boolean>(false);
    const [form] = Form.useForm();

    const countValidator = async (_, val: string) => {
        if (getNumberInputValidateCondition(val, MAX_ROW_COL_COUNTS)) {
            throw new Error(intl.formatMessage(messages.countError));
        }
    };
    const rules: Rule[] = useMemo(
        () => [
            {
                required: true,
                whitespace: true,
                message: intl.formatMessage(messages.requiredField),
            },
        ],
        [],
    );
    const countRules: Rule[] = useMemo(() => [...rules, () => ({ validator: countValidator })], []);
    const titleRules: Rule[] = useMemo(() => {
        return !hideHeaders
            ? [
                  ...rules,
                  {
                      max: MAX_TITLE_LENGTH,
                      message: intl.formatMessage(messages.nameError),
                  },
              ]
            : [];
    }, [hideHeaders]);
    const dataSource = [
        {
            key: 'row',
            title: (
                <>
                    {intl.formatMessage(messages.crossSybolRowCount)}
                    <span className={theme.required}>*</span>
                </>
            ),
            value: (
                <ControledValue
                    valuesKey="row"
                    initialValue={DEFAULT_COUNT_VALUE}
                    rules={countRules}
                    inputType="number"
                />
            ),
        },
        {
            key: 'col',
            title: (
                <>
                    {intl.formatMessage(messages.crossSybolColumnCount)}
                    <span className={theme.required}>*</span>
                </>
            ),
            value: (
                <ControledValue
                    valuesKey="col"
                    initialValue={DEFAULT_COUNT_VALUE}
                    rules={countRules}
                    inputType="number"
                />
            ),
        },
        {
            key: 'hideHeaders',
            title: intl.formatMessage(messages.crossSybolRowHideHeaders),
            value: (
                <Form.Item name="hideHeaders" valuePropName="checked">
                    <Checkbox onChange={() => setHideHeaders(!hideHeaders)} />
                </Form.Item>
            ),
        },
        {
            key: 'colTitle',
            title: (
                <>
                    {intl.formatMessage(messages.crossSybolColumnName)}
                    {!hideHeaders && <span className={theme.required}>*</span>}
                </>
            ),
            value: (
                <ControledValue
                    valuesKey="colTitle"
                    initialValue={intl.formatMessage(messages.crossSybolColumnTitleValue)}
                    disabled={hideHeaders}
                    rules={titleRules}
                />
            ),
        },
        {
            key: 'rowTitle',
            title: (
                <>
                    {intl.formatMessage(messages.crossSybolRowName)}
                    {!hideHeaders && <span className={theme.required}>*</span>}
                </>
            ),
            value: (
                <ControledValue
                    valuesKey="rowTitle"
                    initialValue={intl.formatMessage(messages.crossSybolRowTitleValue)}
                    disabled={hideHeaders}
                    rules={titleRules}
                />
            ),
        },
    ];
    const columns = [
        {
            title: intl.formatMessage(messages.keyColumn),
            dataIndex: 'title',
            key: 'key',
            width: 150,
        },
        {
            title: intl.formatMessage(messages.valueColumn),
            dataIndex: 'value',
            key: 'value',
        },
    ];

    const onSubmitHandler = () => {
        form.validateFields().then(() => {
            const values: TCrossFunctionalFlowchartSymbolCustomProps = form.getFieldsValue();

            submit({ ...values, row: Number(values.row), col: Number(values.col) });
            dispatch(closeDialog(DialogType.CROSS_SYMBOL_CREATE_DIALOG));
        });
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancelHandler,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onSubmitHandler,
                    value: intl.formatMessage(messages.insert),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            title={intl.formatMessage(messages.title)}
            onCancel={onCancelHandler}
            open={open}
            footer={footer}
            closable={false}
        >
            <Form autoComplete='off' form={form}>
                <Table dataSource={dataSource} columns={columns} pagination={false} size="middle" bordered />
            </Form>
        </Dialog>
    );
};

import React, { FC } from 'react';
import { IKanbanNode } from '../../models/bpm/bpm-model-impl.types';
import { IWorkspaceTabItemKanbanParams, TWorkspaceTab } from '../../models/tab.types';
import { KanbanColumn } from './KanbanColumn';
import theme from './Kanban.scss';
import { KanbanEditorToolbar } from '../MainMenu/components/KanbanEditorToolbar/KanbanEditorToolbar.component';

type TKanbanProps = {
    tab: TWorkspaceTab;
};

export const Kanban: FC<TKanbanProps> = ({ tab }) => {
    const content = tab.content as IKanbanNode;
    const params = tab.params as IWorkspaceTabItemKanbanParams;
    const kanbanBoardType = params.modelType;
    const { nodeId } = content;

    return kanbanBoardType ? (
        <div className={theme.kanbanEditorContainer}>
            <KanbanEditorToolbar />
            <div
                style={{
                    display: 'flex',
                    boxSizing: 'border-box',
                    overflow: 'auto',
                    height: '100%',
                }}
            >
                {kanbanBoardType.columnsSettings.columns.map((col) => {
                    return (
                        <KanbanColumn
                            columnsSettings={kanbanBoardType.columnsSettings}
                            columnId={col.id}
                            key={col.id}
                            presetId={kanbanBoardType.presetId}
                            kanbanNodeId={nodeId}
                        />
                    );
                })}
            </div>
        </div>
    ) : null;
};

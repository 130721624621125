// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contextMenu__dropdownWrapper__nNEwu{height:inherit}.contextMenu__dropdownContainer__LGogP{-webkit-app-region:no-drag}.contextMenu__edgeType__ZftA_.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.contextMenu__focus-visible__RzC_n{outline:none}.contextMenu__edgeType__ZftA_.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:focus-visible{outline:none}", "",{"version":3,"sources":["webpack://./src/modules/ContextMenu/components/contextMenu.scss"],"names":[],"mappings":"AAAA,qCACI,cAAA,CAGJ,uCACI,0BAAA,CAIA,wHACI,YAAA,CADJ,oGACI,YAAA","sourcesContent":[".dropdownWrapper {\r\n    height: inherit;\r\n}\r\n\r\n.dropdownContainer {\r\n    -webkit-app-region: no-drag;\r\n}\r\n\r\n.edgeType {\r\n    &:global(.ant-dropdown) :global(.ant-dropdown-menu) :global(.ant-dropdown-menu-item):focus-visible {\r\n        outline: none;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownWrapper": "contextMenu__dropdownWrapper__nNEwu",
	"dropdownContainer": "contextMenu__dropdownContainer__LGogP",
	"edgeType": "contextMenu__edgeType__ZftA_",
	"focus-visible": "contextMenu__focus-visible__RzC_n"
};
export default ___CSS_LOADER_EXPORT___;

import { defineMessages } from 'react-intl';

export default defineMessages({
    save: {
        id: 'CreateUITemplateTab.save',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'CreateUITemplateTab.cancel',
        defaultMessage: 'Отменить',
    },
    delete: {
        id: 'CreateUITemplateTab.delete',
        defaultMessage: 'Удалить',
    },
    deleteWidgetQuestion: {
        id: 'CreateUITemplateTab.deleteWidgetQuestion',
        defaultMessage: 'Удалить виджет "{name}"? ',
    },
});

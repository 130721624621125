import React from 'react';
import { ToolbarButton } from '../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import icUnderline from '@/resources/icons/ic-ribbon-text-underline.svg';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';
import { LabelStyle } from '../../../../models/labelStyle';
import { FormatPanelButtonsState } from '../../../../selectors/generalMenu.selectors';
import { MxConstants } from '../../../../mxgraph/mxgraph';
import messages from '../../FormatPanel.messages';
import { FormatPanelSelectors } from '../../../../selectors/formatPanel.selectors';

export const Underline = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(FormatPanelSelectors.getFormatButtonsDisabled);
    const tooltip: string = useSelector(
        FormatPanelSelectors.getFormatButtonsTooltip(intl.formatMessage(messages.textUnderline)),
    );
    const isFontUnderlineSelected: boolean = useSelector(FormatPanelButtonsState.getIsFontUnderlineSelected);
    const onChange = (value: any, isActive: boolean) => {
        dispatch(generalMenuLabelStyleChangeAction(LabelStyle.decorationStyle, value, isActive));
    };

    return (
        <ToolbarButton
            disabled={disabled}
            isActive={isFontUnderlineSelected}
            icon={icUnderline}
            dataTest="object-toolbar_font-text-underline"
            tooltip={tooltip}
            onClick={() => onChange(MxConstants.FONT_UNDERLINE, isFontUnderlineSelected)}
        />
    );
};

import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { TabsSelectors } from './tabs.selectors';
import { NodeId } from '../serverapi/api';
import { FormatParamValues, TAddedFormatParamsReducerState } from '../reducers/addedModelFormatParams.reducer.types';
import { TValue } from '../modules/UIKit/components/Toolbar/ResizableToolbar/ButtonGroups/toolbarSelect.types';

const getState = (state: TRootState) => state.addedFormatParams;

export namespace AddedModelParamsSelectors {
    export const getAddedParamsByActiveModelId = (paramType: FormatParamValues) =>
        createSelector<TRootState, NodeId, TAddedFormatParamsReducerState, TValue[]>(
            TabsSelectors.getActiveTabId,
            getState,
            (modelId, state) => {
                return state.get(modelId)?.[paramType]?.map((value) => ({ value: value, label: value })) || [];
            },
        );
}

import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '@/serverapi/api';
import { TUndoRedoData, TUndoRedoState } from '@/reducers/undoRedo.reducer.types';
import { TabsSelectors } from './tabs.selectors';

const matrixUndoRedoSelector = (state: TRootState): TUndoRedoState => state.undoRedo;

export namespace UndoRedoSelectors {
    export const getUndoRedoStates = (nodeId: NodeId) =>
        createSelector<TRootState, TUndoRedoState, TUndoRedoData | undefined>(matrixUndoRedoSelector, (state) =>
            state.byNodeId.get(nodeId),
        );

    export const getUndoRedoStatus = (nodeId: NodeId) =>
        createSelector<
            TRootState,
            TUndoRedoState,
            boolean,
            boolean,
            boolean,
            {
                redo: boolean;
                undo: boolean;
            }
        >(
            matrixUndoRedoSelector,
            TabsSelectors.getActiveTabParamUndo,
            TabsSelectors.getActiveTabParamRedo,
            TabsSelectors.checkIsReadModeInActiveTab,
            (state, undo, redo, isReadMode) => {
                const status = {
                    redo: false,
                    undo: false,
                };
                if ((undo || redo) && !isReadMode) {
                    status.undo = undo;
                    status.redo = redo;
                }

                const MatrixUndoRedoData: TUndoRedoData | undefined = state.byNodeId.get(nodeId);
                if (!MatrixUndoRedoData || isReadMode) return status;

                const { states, currentIndex } = MatrixUndoRedoData;

                status.redo = !!states[currentIndex - 1];
                status.undo = !!states[currentIndex + 1];

                return status;
            },
        );
}

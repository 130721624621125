import { takeEverySafely } from '@/sagas/sagaHelpers';
import { SEARCH_WIDGET_REQUEST_DATA } from '../actionTypes/searchResultsWidget.actionTypes';
import { TSearchWidgetRequestDataAction } from '../actions/searchResultsWidget.actions.types';
import { call, put, select } from 'redux-saga/effects';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { searchWidgetSearchRequestSuccess, searchWidgetSetSearchData } from '../actions/searchResultsWidget.actions';
import { SearchDaoService } from '@/services/dao/SearchDAOService';
import { TSeveralExtendedSearchResponse } from '@/services/types/SearchDaoService.types';
import { TSearchDataListItem } from '@/reducers/search.reducer.types';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { batchActions } from '@/actions/rootReducer.actions';

function* searchWidgetRequestDataHandler({ payload }: TSearchWidgetRequestDataAction) {
    const { templateId, widgetId, searchRequests } = payload;

    const serverId: string = yield select(ServerSelectors.serverId);

    if (searchRequests?.length === 0) {
        yield put(
            batchActions([
                searchWidgetSetSearchData(templateId, widgetId, []),
                searchWidgetSearchRequestSuccess(templateId, widgetId),
            ]),
        );
        return;
    }

    try {
        const { resultList }: TSeveralExtendedSearchResponse = yield call(
            SearchDaoService.getSeveralExtendedSearchResponse,
            serverId,
            {
                requests: searchRequests,
                includeCount: true,
            },
        );

        const searchResult: TSearchDataListItem[] = resultList.map((item) => ({
            multilingualName: item.multilingualName,
            path: `${item.path}`,
            type: item.nodeType as TreeItemType,
            elementType: item.elementTypeId || '',
            nodeId: {
                ...item.nodeId,
                serverId,
            },
            deleted: item.deleted,
        }));

        yield put(searchWidgetSetSearchData(templateId, widgetId, searchResult));
    } catch (e) {
        yield put(showNotificationByType(NotificationType.WIDGET_DATA_REQUEST_ERROR));
        throw e;
    } finally {
        yield put(searchWidgetSearchRequestSuccess(templateId, widgetId));
    }
}

export function* searchResultsWidgetSaga() {
    yield takeEverySafely(SEARCH_WIDGET_REQUEST_DATA, searchWidgetRequestDataHandler);
}

import React, { CSSProperties, forwardRef } from 'react';
import classnames from 'classnames';
import theme from './ExtraButton.scss';
import { TButtonProps, TSVGIcon, TTextButtonStyle } from './ExtraButton.types';
import { Tooltip } from '../Tooltip/Tooltip.component';
import { GREY_COLOR_MATRIX } from '../constants';
import icDefaultImage from '../../../../resources/icons/ic-default-image.svg';
import { EllipsisText } from '../../../AdminTools/Methodology/components/MethodologySettingPage/EllipsisText/EllipsisText.component';

const DEFAULT_SIZE = 16;

/**
 * ссылка на фигму (https://www.figma.com/design/QwNP21XezszK7TasNIoDQf/SDS-Design-KIT?node-id=9-167&t=aUiHP2EDE6s7uZY2-0)
 *
 * @param {'large' | 'normal' | 'small'} size большая, средняя или маленькая кнопка
 * @param {'primary' | 'secondary' | TTextButtonStyle} visualStyle сплошная заливка, простая кнопка без заливки, только текст кнопки без заливки и рамок (можно передать цвет)
 * @param {boolean | undefined} danger кнопка красного цвета
 * @param {React.ReactNode} children текст в кнопке
 * @param {boolean | undefined} disabled дизейбл кнопки
 * @param {string | undefined} key React key кнопки
 * @param {string | undefined} dataTest идентификатор для тестов
 * @param {string | { title: string, position: 'top' | 'bottom' | 'right' | 'left', offset: number } | undefined} tooltip текст подсказки, либо объект с текстом подсказки, смещением и направлением
 * @param {"button" | "submit" | "reset" | undefined} type html тип кнопки
 * @param {boolean | undefined} autoFocus автофокус
 * @param {TExtraButton | undefined} extra кнопка из HomePage, может содержать imageSrc, которая заменяет иконку
 * @param {MouseEventHandler<HTMLButtonElement> | undefined} onClick функция, которая будет вызвана при нажатии на кнопку
 * @param {FocusEventHandler<HTMLButtonElement>} onBlur функция, которая будет вызвана при потере фокуса
 * @param {TSVGIcon | undefined} icon svg иконка с размерами
 * @param {React.KeyboardEventHandler<HTMLButtonElement> | undefined} onKeyDown функция, которая будет вызвана при нажатии клавиши на клавиатуре
 * @param {number | undefined} width ширина конпки в px.
 */

export const ExtraButton = forwardRef<HTMLButtonElement, TButtonProps>((props, ref) => {
    const {
        children,
        danger,
        size = 'normal',
        visualStyle = 'secondary',
        disabled,
        key,
        dataTest,
        tooltip,
        extra,
        type,
        autoFocus,
        onClick,
        onBlur,
        icon,
        width,
        tabIndex,
        onKeyDown,
    } = props;

    const buttonClass: string = classnames({
        [theme.button]: !disabled && visualStyle === 'secondary',
        [theme.buttonFilled]: !disabled && visualStyle === 'primary',
        [theme.textOnly]: !disabled && [(visualStyle as TTextButtonStyle)?.type, visualStyle].includes('text'),
        [theme.buttonDisabled]: disabled && visualStyle === 'secondary',
        [theme.buttonFilledDisabled]: disabled && visualStyle === 'primary',
        [theme.textOnlyDisabled]: disabled && [(visualStyle as TTextButtonStyle)?.type, visualStyle].includes('text'),
        [theme.big]: size === 'large',
        [theme.middle]: size === 'normal',
        [theme.small]: size === 'small',
        [theme.extra]: !!extra,
        [theme.red]: danger,
        [theme.iconButton]: !disabled && !('children' in props),
        [theme.iconButtonDisabled]: disabled && !('children' in props),
        [theme.white]: (visualStyle as TTextButtonStyle)?.color === 'white',
        [theme.grey]: (visualStyle as TTextButtonStyle)?.color === 'grey',
        [theme.iconButton]: !disabled && !('children' in props),
        [theme.iconButtonDisabled]: disabled && !('children' in props),
    });

    const buttonStyle: CSSProperties = { width };

    const imageIcon = (imageSrc?: string): JSX.Element | null => {
        return imageSrc ? (
            <div className={extra ? theme.imageExtraContainer : theme.imageContainer}>
                <img className={theme.image} src={imageSrc} alt="extraButton" />
            </div>
        ) : icon ? (
            svgIcon(icon)
        ) : (
            svgIcon(icDefaultImage)
        );
    };

    const svgIcon = (icon?: TSVGIcon): JSX.Element | null => {
        return icon ? (
            <div className={extra ? theme.iconExtraContainer : theme.iconContainer}>
                <svg width={icon?.width || DEFAULT_SIZE} height={icon?.height || DEFAULT_SIZE} viewBox={icon.viewBox}>
                    <defs>
                        <filter id="grayscale">
                            <feColorMatrix type="matrix" values={GREY_COLOR_MATRIX} />
                        </filter>
                    </defs>
                    <use xlinkHref={`#${icon.id}`} filter={disabled ? 'url(#grayscale)' : undefined} />
                </svg>
            </div>
        ) : null;
    };

    return (
        <Tooltip tooltip={tooltip}>
            <button
                ref={ref}
                tabIndex={disabled ? -1 : tabIndex}
                type={type || 'button'}
                autoFocus={autoFocus}
                style={buttonStyle}
                className={buttonClass}
                key={key}
                onClick={!disabled ? onClick : undefined}
                onBlur={onBlur}
                data-test={dataTest}
                onKeyDown={onKeyDown}
            >
                {extra ? imageIcon(extra.imageSrc) : svgIcon(icon)}
                {typeof children === 'string' ? (
                    <EllipsisText
                        text={children}
                        linesQuantity={!extra?.isNotOneRowText ? 1 : undefined}
                        fontSize={size === 'small' ? 12 : undefined}
                        lineHeight={size === 'small' ? 18 : undefined}
                    />
                ) : (
                    children
                )}
            </button>
        </Tooltip>
    );
});

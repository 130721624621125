import { TAdminToolsParams, AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';
import { TreeNode } from '../models/tree.types';
import { openUserManagementTab } from './users.actions';
import { openLicensesTab } from './licenses.actions';
import { openSafetyTab } from './safety.actions';
import { openContenLoadingTab } from './tabs.actions';
import { openMethodologySettingTab } from './methodologySetting.actions';
import { openSystemProperiesTab } from './systemProperties.actions';
import { openMonitoringTab } from './monitoring.actions';
import { ADMINTOOL_SELECTED, ADMINTOOL_SET_PARAMS } from '../actionsTypes/admintools.actionTypes';
import {
    TAdminToolsActions,
    TAdminToolSelect,
    TAdminToolSelectParams,
    TAdminToolSetParamsAction,
} from './admintools.actions.types';
import { openEventAccessTab } from './eventAccess.actions';
import { openApprovalsTab } from './approval.actions';
import { openNotificationsTab } from './notifications.actions';
import { openPublishedModelsTab } from './publishedModels.actions';
import { openUITemplatesTab } from '@/modules/UITemplates/actions/UITemplates.actions';

export const adminToolSetParams = (params: TAdminToolsParams): TAdminToolSetParamsAction => ({
    type: ADMINTOOL_SET_PARAMS,
    payload: { params },
});

export const admintoolSelect: (payload: TAdminToolSelectParams) => TAdminToolSelect = (payload) => ({
    type: ADMINTOOL_SELECTED,
    payload,
});

export const loadAdminToolByTreeNode = (node: TreeNode): TAdminToolsActions => {
    switch (node.nodeId.id) {
        case AdminToolTreeType.SAFETY:
            return openSafetyTab(node);
        case AdminToolTreeType.LICENSES:
            return openLicensesTab(node);
        case AdminToolTreeType.USER_MANAGMENT:
            return openUserManagementTab(node);
        case AdminToolTreeType.METHODOLOGY_SETTINGS:
            return openMethodologySettingTab(node);
        case AdminToolTreeType.SERVER_SETTINGS:
            return openSystemProperiesTab(node);
        case AdminToolTreeType.MONITORING_TAB:
            return openMonitoringTab(node);
        case AdminToolTreeType.NOTIFICATIONS:
            return openNotificationsTab(node);
        case AdminToolTreeType.EVENT_ACCESS:
            return openEventAccessTab(node);
        case AdminToolTreeType.APPROVALS_TAB:
            return openApprovalsTab();
        case AdminToolTreeType.PUBLISHED_MODELS:
            return openPublishedModelsTab();
        case AdminToolTreeType.UI_TEMPLATES:
            return openUITemplatesTab(node);
        default:
            // Временно, пока не будут реализованы все пункты Администрирования
            return openContenLoadingTab(node);
    }
};

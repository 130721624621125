import React, { FC, useEffect, useRef, useState } from 'react';
import { LocalesService } from '@/services/LocalesService';
import { Tab } from './UIKitTabs.types';
import theme from './UIKitTabs.scss';
import cx from 'classnames';
import { EllipsisText } from '@/modules/AdminTools/Methodology/components/MethodologySettingPage/EllipsisText/EllipsisText.component';
import { useIntl } from 'react-intl';
import messages from './UIKitTabs.messages';
import { Dropdown } from './Dropdown/Dropdown.component';
import { TDropdownItem } from './Dropdown/Dropdown.types';

type TUIKitTabsProps = {
    tabs: Tab[];
    activeTabId?: string;
    orientation?: 'vertical' | 'horizontal';
};

export const UIKitTabs: FC<TUIKitTabsProps> = ({ tabs, activeTabId, orientation = 'horizontal' }) => {
    const locale = LocalesService.getLocale();
    const intl = useIntl();
    const currentTab: Tab | undefined = tabs.find((tab) => tab.id === activeTabId);

    const horizontalContainerRef = useRef<HTMLDivElement>(null);
    const childrenRefs = useRef<{ [key: string]: HTMLDivElement }>({});
    const [overflowingIds, setOverflowingIds] = useState<string[]>([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const id = (entry.target as HTMLDivElement).id;
                    if (id) {
                        if (!entry.isIntersecting) {
                            setOverflowingIds((prev) => {
                                if (!prev.includes(id)) {
                                    return [...prev, id];
                                }
                                return prev;
                            });
                        } else {
                            setOverflowingIds((prev) => prev.filter((i) => i !== id));
                        }
                    }
                });
            },
            {
                root: horizontalContainerRef.current,
                threshold: 0.9,
            },
        );

        Object.values(childrenRefs.current).forEach((child) => {
            if (child) {
                observer.observe(child);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [tabs]);

    if (orientation === 'vertical')
        return (
            <div className={theme.uikitTabsVertical}>
                <div className={theme.tabsSidePanel}>
                    {tabs.map(({ id, title, onClick }) => (
                        <div
                            key={id}
                            className={cx(theme.tabTitle, { [theme.active]: activeTabId === id })}
                            onClick={() => onClick(id)}
                        >
                            <EllipsisText
                                text={
                                    LocalesService.internationalStringToString(title, locale) ||
                                    intl.formatMessage(messages.withoutName)
                                }
                                linesQuantity={1}
                            />
                        </div>
                    ))}
                </div>
                {currentTab && <div className={theme.tabsContent}>{currentTab.content}</div>}
            </div>
        );

    const dropDownItemsClickHandler = (tabId: string) => {
        childrenRefs.current[tabId].scrollIntoView({ behavior: 'smooth' });
    };

    const dropDownItems: TDropdownItem[] = tabs
        .filter(({ id }) => overflowingIds.includes(id))
        .map(({ id, title, onClick }) => ({
            key: id,
            label:
                LocalesService.internationalStringToString(title, locale) || intl.formatMessage(messages.withoutName),
            onClick: () => {
                dropDownItemsClickHandler(id);
                onClick(id);
            },
        }));

    return (
        <div className={theme.uikitTabsHorizontal}>
            <div style={{ display: 'flex' }}>
                <div className={theme.tabsHeader} ref={horizontalContainerRef}>
                    {tabs.map(({ id, title, onClick }) => (
                        <div
                            id={id}
                            key={id}
                            className={cx(theme.tabTitle, { [theme.active]: activeTabId === id })}
                            onClick={() => onClick(id)}
                            ref={(el) => {
                                if (el) {
                                    childrenRefs.current[id] = el;
                                }
                            }}
                        >
                            {LocalesService.internationalStringToString(title, locale) ||
                                intl.formatMessage(messages.withoutName)}
                        </div>
                    ))}
                </div>
                {dropDownItems.length !== 0 && <Dropdown items={dropDownItems} />}
            </div>
            {currentTab && <div className={theme.tabsContent}>{currentTab.content}</div>}
        </div>
    );
};

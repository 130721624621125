import { put, select } from 'redux-saga/effects';
import {
    ADD_MODEL_FORMAT_PARAM_VALUE,
    DELETE_ALL_MODEL_FORMAT_PARAM_VALUES,
} from '../actionsTypes/addedModelFormatParams.actionTypes';
import {
    TAddModelFormatParamValueAction,
    TFetchDeleteAllModelFormatParamValuesAction,
} from '../actions/addedModelFormatParams.actions.types';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { NodeId } from '../serverapi/api';
import {
    fetchDeleteAllModelFormatParamValues,
    fetchModelFormatParamValue,
} from '../actions/addedModelFormatParams.actions';
import { takeEverySafely } from './sagaHelpers';

function* handleAddModelFormatParamValue({ payload }: TAddModelFormatParamValueAction) {
    const { value, paramType } = payload;
    const activeModelId: NodeId = yield select(TabsSelectors.getActiveTabId);
    yield put(fetchModelFormatParamValue(value, activeModelId, paramType));
}

function* handleDeleteAllModelFormatParamValues({ payload }: TFetchDeleteAllModelFormatParamValuesAction) {
    const { paramType } = payload;
    const activeModelId: NodeId = yield select(TabsSelectors.getActiveTabId);
    yield put(fetchDeleteAllModelFormatParamValues(activeModelId, paramType));
}

export function* addeModelFormatParamsSaga() {
    yield takeEverySafely(ADD_MODEL_FORMAT_PARAM_VALUE, handleAddModelFormatParamValue);
    yield takeEverySafely(DELETE_ALL_MODEL_FORMAT_PARAM_VALUES, handleDeleteAllModelFormatParamValues);
}

import React from 'react';
import { CellMeasurer, TableCellProps } from 'react-virtualized';
import theme from './TableUIKitComponent.scss';
import { Checkbox } from '../Checkbox/Checkbox.component';
import { CellMeasurerCacheInterface } from 'react-virtualized/dist/es/CellMeasurer';
import classNames from 'classnames';
import { TCheckboxStatus } from '../Checkbox/Checkbox.types';

type TColumnCellWithCheckBoxProps = {
    cellProps: TableCellProps;
    cache: CellMeasurerCacheInterface;
    checked: boolean;
    disabled: boolean;
    handleChange: (status: TCheckboxStatus) => void;
};

export const ColumnCellWithCheckBox = (props: TColumnCellWithCheckBoxProps) => {
    const { cache, checked, disabled, cellProps, handleChange } = props;
    const { dataKey, parent, rowIndex } = cellProps;

    return (
        <CellMeasurer cache={cache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={0}>
            <div className={classNames(theme.checkBoxContainer, !!disabled ? theme.disabledCheckBox : '')}>
                <Checkbox
                    onChange={handleChange}
                    status={checked}
                    disabled={!!disabled}
                />
            </div>
        </CellMeasurer>
    );
};

import type { TRichTextEditorToolbarProps } from './RichTextEditorToolbar.types';
import React, { FC } from 'react';
import { getStateObserver } from '../common/sharedState.class';
import { ControlsContext } from './Controls.context';
import { TextAlign } from './controls/TextAlign.component';
import { BGColor } from './controls/BGColor.component';
import { FontColor } from './controls/FontColor.component';
import { FontFamily } from './controls/FontFamily.component';
import { FontSize } from './controls/FontSize.component';
import { Images } from './controls/Images.component';
import { Table } from './controls/Table.component';
import { Links } from './controls/Links.component';
import { Eraser } from './controls/Eraser.component';
import { LineHeight } from './controls/LineHeight.component';
import { Indent } from './controls/Indent.component';
import { ParagraphStyle } from './controls/ParagraphStyle.component';
import { Macros } from './controls/Macros.component';
import { BulletList } from './controls/BulletList.component';
import { OrderedList } from './controls/OrderedList.component';
import { TaskList } from './controls/TaskList.component';
import { SinkListItem } from './controls/SinkListItem.component';
import { LiftListItem } from './controls/LiftListItem.component';
import { BoldText } from './controls/BoldText.component';
import { ItalicText } from './controls/ItalicText.component';
import { MoreInlineStyles } from './controls/MoreInlineStyles.component';
import { ButtonGroup } from '../../Toolbar/ButtonGroup/ButtonGroup.component';
import { ResizableToolbar } from '../../Toolbar/ResizableToolbar/ResizableToolbar.component';
import { UnderlineText } from './controls/UnderlineText.component';

export const RichTextEditorToolbar: FC<TRichTextEditorToolbarProps> = ({ id, handlers, hideIndent }) => {
    const stateObserverStateObserver = getStateObserver(id);
    const { openLinkDialog, openImageDialog, openInsertImageLinkDialog, openChooseModelDialog, uploadImage } = handlers;

    return (
        <ControlsContext.Provider value={stateObserverStateObserver}>
            <ResizableToolbar>
                <ButtonGroup>
                    <ParagraphStyle />
                </ButtonGroup>
                <ButtonGroup>
                    <FontFamily />
                </ButtonGroup>
                <ButtonGroup>
                    <FontSize />
                </ButtonGroup>
                <ButtonGroup>
                    <LineHeight />
                </ButtonGroup>
                <ButtonGroup>
                    <FontColor />
                    <BGColor />
                </ButtonGroup>
                <ButtonGroup>
                    <BoldText />
                    <ItalicText />
                    <UnderlineText />
                    <MoreInlineStyles />
                </ButtonGroup>
                <ButtonGroup>
                    <TextAlign />
                    <Indent />
                </ButtonGroup>
                <ButtonGroup>
                    <BulletList />
                    <OrderedList />
                    <TaskList />
                    {!hideIndent ? <SinkListItem /> : null}
                    {!hideIndent ? <LiftListItem /> : null}
                </ButtonGroup>
                <ButtonGroup>
                    <Macros />
                </ButtonGroup>
                <ButtonGroup>
                    <Links openDialog={openLinkDialog} />
                    <Images
                        uploadImage={uploadImage}
                        openImageFileDialog={openImageDialog}
                        openExternalImageDialog={openInsertImageLinkDialog}
                        openModelPreviewDialog={openChooseModelDialog}
                    />
                    <Table />
                </ButtonGroup>
                <ButtonGroup>
                    <Eraser />
                </ButtonGroup>
            </ResizableToolbar>
        </ControlsContext.Provider>
    );
};

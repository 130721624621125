import type { NodeId } from '@/serverapi/api';
import type { Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Slice, Fragment, Node } from 'prosemirror-model';
import { generateJSON, getNodeType } from '@tiptap/core';

// https://discuss.prosemirror.net/t/modify-specific-node-on-copy-and-paste-in-clipboard/4901/4

export const mapFragment = (fragment: Fragment, callback: (node: Node) => Node | Node[] | Fragment | null): Fragment =>
    Fragment.fromArray(
        (fragment as any).content.map((node: Node) => {
            if (node.content.childCount > 0) {
                return node.type.create(node.attrs, mapFragment(node.content, callback));
            }

            return callback(node);
        }),
    );

export const mapSlice = (slice: Slice, callback: (node: Node) => Node | Node[] | Fragment | null): Slice => {
    const fragment = mapFragment(slice.content, callback);

    return new Slice(fragment, slice.openStart, slice.openEnd);
};

type TImageCopyPastePluginProps = {
    sourceId: NodeId;
    originalNodeName: string;
    bufferedNodeName: string;
    onPaste: (imageIds: string[], sourceId: NodeId) => void;
    editor: Editor;
};

export const ImageCopyPastePlugin = ({
    sourceId,
    originalNodeName,
    bufferedNodeName,
    onPaste,
    editor,
}: TImageCopyPastePluginProps) =>
    new Plugin({
        key: new PluginKey('imageCopyPastePlugin'),
        props: {
            transformCopied: (slice: Slice, view) => {
                const addCopyPasteDataToDataFields = (node: Node) => {
                    if (node.type.name === originalNodeName) {
                        const type = getNodeType(bufferedNodeName, view.state.schema);
                        const newNode = type.create({ ...node.attrs, ...(sourceId && { sourceId }) }, node.content);

                        return newNode;
                    }

                    return node.copy(node.content);
                };

                return mapSlice(slice, addCopyPasteDataToDataFields);
            },
            transformPasted: (slice: Slice, view) => {
                const fetchAndClearCopyPasteData = (node: Node) => {
                    if (node.type.name === bufferedNodeName && node.attrs.sourceId) {
                        const { sourceId, ...attrs } = node.attrs;
                        const type = getNodeType(originalNodeName, view.state.schema);

                        return type.create({ ...attrs }, node.content);
                    }

                    return node.copy(node.content);
                };

                return mapSlice(slice, fetchAndClearCopyPasteData);
            },
            handlePaste: (view, _event) => {
                if (!onPaste) {
                    return;
                }

                const html = _event.clipboardData?.getData('text/html') || '';
                const json = generateJSON(html, editor.extensionManager.extensions);
                const fragment = Fragment.fromJSON(view.state.schema, json.content);

                const pastedImages: string[] = [];
                // TODO sourceId
                let sourceModelId: NodeId | null = null;

                fragment.descendants((node) => {
                    if (node.type.name === bufferedNodeName && node.attrs.sourceId) {
                        pastedImages.push(node.attrs.id);

                        if (!sourceModelId) {
                            sourceModelId = JSON.parse(node.attrs.sourceId) as NodeId;
                        }
                    }
                });

                if (pastedImages.length > 0 && sourceModelId) {
                    onPaste(pastedImages, sourceModelId);
                }
            },
        },
    });


import { TRootState } from '../../../reducers/root.reducer.types';
import { navigatorTabDeselect } from '../../../actions/navigator.actions';
import { connect } from 'react-redux';
import { NavigatorSymbols } from '../components/NavigatorSymbols/NavigatorSymbols.component';
import { TNavigatorSymbolsProps } from '../components/NavigatorSymbols/NavigatorSymbols.types';
import { createDiagramElement } from '../../../actions/navigatorSymbol.actions';
import { ModelNode, Symbol } from '../../../serverapi/api';
import { getSymbolsByModelId } from '../../../selectors/navigator.selectors';
import { TNavigatorTab } from '../../../reducers/navigator.reducer.types';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { editorModeChangedPrepareAction } from '../../../actions/editor.actions';
import { EditorMode } from '../../../models/editorMode';
import { instancesBPMMxGraphMap } from '../../../mxgraph/bpm-mxgraph-instance-map';
import { UserProfileSelectors } from '../../../selectors/userProfile.selectors';

const panelType = TNavigatorTab.Symbols;

const mapStateToProps = (state: TRootState): Partial<TNavigatorSymbolsProps> => {
    const activeScheme = TabsSelectors.getActiveTab(state);
    const modelTypeId = (activeScheme?.content as ModelNode)?.modelTypeId;
    const nodeId = activeScheme?.nodeId;
    const graph = nodeId ? instancesBPMMxGraphMap.get(nodeId) : undefined;

    const symbolsFilter = (s: Symbol): boolean =>
        UserProfileSelectors.isSymbolCreatable(nodeId, s?.id)(state) &&
        UserProfileSelectors.isObjectCreatable(nodeId, s?.objectType)(state);

    const isReadOnly = !UserProfileSelectors.isModelUpdatable(nodeId, modelTypeId)(state);

    const modelTypeSymbols = getSymbolsByModelId(graph?.id)(state);
    const symbols = modelTypeSymbols.filter(symbolsFilter);

    const isDashboard = activeScheme?.type === 'Dashboard';
    return {
        symbols,
        content: activeScheme?.content,
        editorMode: activeScheme?.mode,
        graph,
        isReadOnly,
        isDashboard,
        nodeId: activeScheme?.nodeId || {
            id: '',
            repositoryId: '',
            serverId: '',
        },
    };
};

const mapDispatchToProps = (dispatch): Partial<TNavigatorSymbolsProps> => ({
    onClose: () => dispatch(navigatorTabDeselect(panelType)),
    onEditModeEnable: () => dispatch(editorModeChangedPrepareAction(EditorMode.Edit)),
    onSymbolDrag: (draggedSymbol: Symbol) => dispatch(createDiagramElement(draggedSymbol)),
});

export const NavigatorSymbolsContainer = connect(mapStateToProps, mapDispatchToProps)(NavigatorSymbols);

import React from 'react';
import { WidgetTypes } from '../CreateUITemplateTab/WidgetsListSidePanel/WidgetsListSidePanel.types';
import { SearchResultWidget } from './SearchResults/SearchResultWidget.component';
import { SearchResultSidePanel } from './SearchResults/SidePanel/SearchResultSidePanel.component';
import { WidgetDTO, WidgetDTOTypeEnum } from '@/serverapi/api';
import { TSearchResultWidget, TSearchResultWidgetProps, TSidePanelProps, TWidgetsProps } from './widget.types';

type TWidgetsMap = {
    [id: string]: {
        renderWidget: (props: TWidgetsProps) => JSX.Element;
        renderSidePanel: (props: TSidePanelProps) => JSX.Element;
    };
};

export const widgetsMap: TWidgetsMap = {
    [WidgetTypes.SearchResult]: {
        renderWidget: (props: TWidgetsProps) => <SearchResultWidget {...props} />,
        renderSidePanel: (props: TSidePanelProps) => <SearchResultSidePanel {...props} />,
    },
};

export const renderWidget = (templateId: string, widget: WidgetDTO) => {
    const { type, id } = widget;
    if (type === 'SEARCH_RESULT') {
        const { searchRequests, showElementType, showNodeType } = widget as TSearchResultWidget;
        const widgetProps: TSearchResultWidgetProps = {
            templateId,
            widgetId: id,
            searchRequests,
            showElementType,
            showNodeType,
        };
        return widgetsMap[type].renderWidget(widgetProps);
    }

    return <div />;
};

export const renderWidgetSidePanel = (widgetType: WidgetDTOTypeEnum, props: TSidePanelProps) => {
    if (widgetsMap[widgetType]) {
        return widgetsMap[widgetType].renderSidePanel(props);
    }

    return <div />;
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    matrixModel: {
        id: 'MatrixEditor.matrixModel',
        defaultMessage: 'Матричная модель',
    },
    gettingMatrixImage: {
        id: 'MatrixEditor.gettingMatrixImage',
        defaultMessage: 'Получение изображения матрицы "{name}"',
    },
});

import React, { FC } from 'react';
import theme from './DashboardWidgetContainer.scss';

type TResizersProps = {
    resizeHandle: (resizerClickEvent: React.MouseEvent<HTMLDivElement, MouseEvent>, sizerId: number) => void;
};

export const Resizers: FC<TResizersProps> = ({ resizeHandle }) => {
    return (
        <>
            <div
                id="sizer"
                onMouseDown={(e) => resizeHandle(e, 1)}
                className={theme.dashboardSizer}
                style={{ top: '-4px', left: '-4px', cursor: 'se-resize' }}
            />
            <div
                id="sizer"
                onMouseDown={(e) => resizeHandle(e, 2)}
                className={theme.dashboardSizer}
                style={{ top: '-4px', right: '-4px', cursor: 'sw-resize' }}
            />
            <div
                id="sizer"
                onMouseDown={(e) => resizeHandle(e, 3)}
                className={theme.dashboardSizer}
                style={{ bottom: '-4px', left: '-4px', cursor: 'sw-resize' }}
            />
            <div
                id="sizer"
                onMouseDown={(e) => resizeHandle(e, 4)}
                onClick={(e) => e.stopPropagation()}
                className={theme.dashboardSizer}
                style={{ bottom: '-4px', right: '-4px', cursor: 'se-resize' }}
            />
        </>
    );
};

import React, { FC } from 'react';
import theme from './SettingsSidePanel.scss';
import { NavigatorPanel } from '@/modules/Navigator/components/NavigatorPanel/NavigatorPanel.component';
import { TNavigatorTab } from '@/reducers/navigator.reducer.types';
import { useIntl } from 'react-intl';
import messages from './SettingsSidePanel.messages';
import { useDispatch } from 'react-redux';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import {
    InternationalString,
    UITemplateDTO,
    UITemplateDTOTabsTypeEnum,
    UITemplateTabDTO,
    WidgetDTO,
} from '@/serverapi/api';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { Form, FormInstance } from 'antd';
import {
    UITemplateEditorChangeActiveTab,
    UITemplateEditorUpdateTemplate,
} from '@/modules/UITemplates/actions/UITemplateEditor.actions';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import { TemplateTabTypes } from '@/modules/UITemplates/types/UITemplates.types';
import { TSettingsPanelFormValues } from '../CreateUITemplateTab.types';
import { v4 as uuid } from 'uuid';
import { TabsList } from './TabsList/TabsList.component';
import { UI_TEMPLATE_SETTINGS_PANEL_WIDTH } from '@/modules/UITemplates/utils/consts';

type TSettingsSidePanelProps = {
    hide: boolean;
    generalForm: FormInstance<TSettingsPanelFormValues>;
    UITemplate: UITemplateDTO;
};

export const SettingsSidePanel: FC<TSettingsSidePanelProps> = ({ hide, generalForm, UITemplate }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { id: UITemplateId, name, description, widgets, tabsType, tabs } = UITemplate;

    const onChangeTemplateName = (name: InternationalString) => {
        dispatch(UITemplateEditorUpdateTemplate(UITemplateId, { name }));
    };

    const onChangeTemplateDescription = (description: InternationalString) => {
        dispatch(UITemplateEditorUpdateTemplate(UITemplateId, { description }));
    };

    const onChangeTemplateTabsType = (selectedTabsType: TemplateTabTypes) => {
        const prevTabsType: UITemplateDTOTabsTypeEnum | undefined = tabsType;
        const newTabsType: UITemplateDTOTabsTypeEnum | undefined =
            selectedTabsType === TemplateTabTypes.NONE ? undefined : selectedTabsType;

        //при появлении первой вкладки, виджеты переезжают в нее
        if (!prevTabsType && newTabsType) {
            const widgetsIds: string[] = widgets.map((widget) => widget.id);
            const newTabId: string = uuid();
            const newTabs: UITemplateTabDTO[] = [{ id: newTabId, name: {}, widgetsIds }];
            dispatch(UITemplateEditorChangeActiveTab(UITemplateId, newTabId));
            dispatch(UITemplateEditorUpdateTemplate(UITemplateId, { tabsType: newTabsType, tabs: newTabs }));
        }

        //при выключении вкладок, переносим виджеты из первой вкладки
        if (prevTabsType && !newTabsType) {
            const newWidgets: WidgetDTO[] = tabs?.[0]
                ? widgets.filter((widget) => tabs[0].widgetsIds.includes(widget.id))
                : [];
            dispatch(
                UITemplateEditorUpdateTemplate(UITemplateId, { widgets: newWidgets, tabsType: newTabsType, tabs: undefined }),
            );
            dispatch(UITemplateEditorChangeActiveTab(UITemplateId, ''));
        }

        if (prevTabsType && newTabsType) {
            dispatch(UITemplateEditorUpdateTemplate(UITemplateId, { tabsType: newTabsType }));
        }
    };

    const getOptionMessage = (tabsType?: TemplateTabTypes) => {
        return tabsType && messages[tabsType]
            ? intl.formatMessage(messages[tabsType])
            : intl.formatMessage(messages.NONE);
    };

    const templateTabsOptions: JSX.Element[] = Object.values(TemplateTabTypes).map((templateType) => (
        <Select.Option key={templateType} label={getOptionMessage(templateType)} value={templateType} />
    ));

    return (
        <NavigatorPanel
            titleProps={{
                title: intl.formatMessage(messages.title),
            }}
            type={TNavigatorTab.Widgets}
            className={hide ? theme.hidePanel : theme.settingsSidePanel}
            hideCloseButton
            width={UI_TEMPLATE_SETTINGS_PANEL_WIDTH}
        >
            <div className={theme.sidePanelBody}>
                <Input name="templateId" label={intl.formatMessage(messages.ID)} disabled value={UITemplateId} />
                <Form autoComplete="off" form={generalForm} layout="vertical" className={theme.form}>
                    <MultiLangInputDialog
                        generalForm={generalForm}
                        mainInputName="templateName"
                        placeholder={intl.formatMessage(messages.namePlaceholder)}
                        multiLangValue={name}
                        maxLength={150}
                        onChange={onChangeTemplateName}
                        label={intl.formatMessage(messages.templateName)}
                        required
                    />
                    <MultiLangInputDialog
                        generalForm={generalForm}
                        mainInputName="templateDescription"
                        placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
                        multiLangValue={description}
                        maxLength={150}
                        onChange={onChangeTemplateDescription}
                        label={intl.formatMessage(messages.description)}
                    />

                    <Select
                        wrapperClassName={theme.select}
                        label={intl.formatMessage(messages.tab)}
                        onChange={onChangeTemplateTabsType}
                        value={getOptionMessage(tabsType as TemplateTabTypes)}
                    >
                        {templateTabsOptions}
                    </Select>
                    <TabsList templateId={UITemplateId} generalForm={generalForm} />
                </Form>
            </div>
        </NavigatorPanel>
    );
};

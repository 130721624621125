import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_LINE_HEIGHT_OPTION } from './Controls.constants';
import messages from '../CommonToolbar.messages';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../../../Toolbar/ResizableToolbar/ButtonGroups/groups.scss';
import { Select } from '../../../Select/Select.component';
import { lineHeightOptions } from '../../../../../../utils/configuration';
import classNames from 'classnames';
import { TValue } from '../../../Toolbar/ResizableToolbar/ButtonGroups/toolbarSelect.types';
import { ClearValueButton } from '../../../Toolbar/ClearValuesBtn/ClearValuesButton.component';
import { AddNewValueButton } from '../../../Toolbar/AddNewValueBtn/AddNewValueButton.component';
import {
    addModelFormatParamValue,
    deleteAllModelFormatParamValues,
} from '../../../../../../actions/addedModelFormatParams.actions';
import { FormatParamValues } from '../../../../../../reducers/addedModelFormatParams.reducer.types';
import { useDispatch, useSelector } from 'react-redux';
import { AddedModelParamsSelectors } from '../../../../../../selectors/addedModelFormatParams.selectors';

// TODO getAttributes('paragraph') работает только для типа 'paragraph'
// нужно предусмотреть возможность задавать lineHeight для всех типов
const getFocusedBlock = (editor: Editor) =>
    editor.getAttributes('paragraph').lineHeight || DEFAULT_LINE_HEIGHT_OPTION.value;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setLineHeight(value).run();

const validateNumber = (rule: {}, value: string, callback: (msg?: string) => void) => {
    if (isNaN(+value) || +value < 0) {
        callback('not number');
    } else {
        callback();
    }
};

export const LineHeight = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const addedFontSizers: TValue[] = useSelector(
        AddedModelParamsSelectors.getAddedParamsByActiveModelId(FormatParamValues.lineHeights),
    );

    const handleAdd = (value: string) => {
        dispatch(addModelFormatParamValue(value, FormatParamValues.lineHeights));
    };

    const handleDeleteAll = () => {
        dispatch(deleteAllModelFormatParamValues(FormatParamValues.lineHeights));
    };

    const onChangeHandler = (value: string) => {
        if (['del', 'add'].includes(value)) return;
        setCurrentState(value);
    };

    return (
        <Select
            onChange={onChangeHandler}
            value={value || DEFAULT_LINE_HEIGHT_OPTION.label}
            placeholder=""
            originalTheme
            data-test="wiki-toolbar-group_select-line-interval"
            wrapperClassName={classNames(theme.lineHeightContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            tooltip={intl.formatMessage(messages.lineHeight)}
        >
            {[
                ...lineHeightOptions,
                ...addedFontSizers,
                !!addedFontSizers.length && {
                    value: 'del',
                    label: <ClearValueButton onClicked={handleDeleteAll} />,
                },
                {
                    value: 'add',
                    label: (
                        <AddNewValueButton
                            onAddClicked={(value) => handleAdd(value)}
                            title={intl.formatMessage(messages.lineHeight)}
                            validationRules={[
                                { required: true, message: intl.formatMessage(messages.lineHeightMessageRequired) },
                                {
                                    validator: validateNumber,
                                    message: intl.formatMessage(messages.lineHeightMessageNumberOnly),
                                },
                            ]}
                        />
                    ),
                },
            ]
                .filter((option): option is TValue => !!option)
                .map((style) => {
                    return <Select.Option value={style.value} label={style.label} />;
                })}
        </Select>
    );
};

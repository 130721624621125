import { LocalesService } from '@/services/LocalesService';
import messages from '../messages/systemAttributes.messages';
import { TSystemAttributeType } from '../types/systemAttributes.types';

const intl = LocalesService.useIntl();

export const systemAttributeTypes: TSystemAttributeType[] = [
    {
        id: 'nodeId',
        presetId: '',
        name: 'ID',
        valueType: 'STRING',
    },
    {
        id: 'updatedAt',
        presetId: '',
        name: intl.formatMessage(messages.UpdatedAt),
        valueType: 'DATE_TIME',
    },
    {
        id: 'createdAt',
        presetId: '',
        name: intl.formatMessage(messages.CreatedAt),
        valueType: 'DATE_TIME',
    },
    {
        id: 'deletedAt',
        presetId: '',
        name: intl.formatMessage(messages.DeletedAt),
        valueType: 'DATE_TIME',
    },
    {
        id: 'userEditDisabled',
        presetId: '',
        name: intl.formatMessage(messages.UserEditDisabled),
        valueType: 'BOOLEAN',
    },
    {
        id: 'scriptEngineEditDisabled',
        presetId: '',
        name: intl.formatMessage(messages.ScriptEngineEditDisabled),
        valueType: 'BOOLEAN',
    },
    {
        id: 'updatedBy',
        presetId: '',
        name: intl.formatMessage(messages.UpdatedBy),
        valueType: 'PRINCIPAL',
    },
    {
        id: 'tradeSecret',
        presetId: '',
        name: intl.formatMessage(messages.TradeSecret),
        valueType: 'BOOLEAN',
    },
    {
        id: 'confidential',
        presetId: '',
        name: intl.formatMessage(messages.Confidential),
        valueType: 'BOOLEAN',
    },
    {
        id: 'name',
        presetId: '',
        name: intl.formatMessage(messages.Name),
        valueType: 'STRING',
    },
    {
        id: 'personalData',
        presetId: '',
        name: intl.formatMessage(messages.PersonalData),
        valueType: 'BOOLEAN',
    },
    {
        id: 'createdBy',
        presetId: '',
        name: intl.formatMessage(messages.CreatedBy),
        valueType: 'PRINCIPAL',
    },
    {
        id: 'modelTypeId',
        presetId: '',
        name: intl.formatMessage(messages.ModelType),
        valueType: 'STRING',
    },
    {
        id: 'objectTypeId',
        presetId: '',
        name: intl.formatMessage(messages.ObjectType),
        valueType: 'STRING',
    },
    {
        id: 'folderTypeId',
        presetId: '',
        name: intl.formatMessage(messages.FolderType),
        valueType: 'STRING',
    },
    {
        id: 'edgeTypeId',
        presetId: '',
        name: intl.formatMessage(messages.EdgeType),
        valueType: 'STRING',
    },
    {
        id: 'symbolId',
        presetId: '',
        name: intl.formatMessage(messages.SymbolType),
        valueType: 'STRING',
    },
    {
        id: 'deletedBy',
        presetId: '',
        name: intl.formatMessage(messages.DeletedBy),
        valueType: 'PRINCIPAL',
    },
    {
        id: 'nodeType',
        presetId: '',
        name: intl.formatMessage(messages.NodeType),
        valueType: 'STRING',
    },
];

export const modelSystemAttributeTypes: TSystemAttributeType[] = systemAttributeTypes.filter(
    ({ id }) => id !== 'objectTypeId' && id !== 'symbolId',
);

import { put, select, takeEvery } from 'redux-saga/effects';
import { AttributeValueNode, AttributeValueUrl } from '../serverapi/api';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { LocalesService } from '../services/LocalesService';
import { appOpenUri } from '../actions/app.actions';
import { openNode } from '../actions/openNode.actions';
import { parseTreeItemType, TreeItemType } from '../modules/Tree/models/tree';
import { TOpenAttributeLinkAction } from '../actions/openAttributeLink.actions.types';
import { OPEN_ATTRIBUTE_LINK } from '../actionsTypes/openAttributeLink.actionTypes';

function* openAttributeLink({ payload }: TOpenAttributeLinkAction) {
    const { attributeValue, graphId } = payload;
    const locale = yield select(getCurrentLocale);

    if (attributeValue?.valueType === 'NODE' && graphId) {
        const { nodeType, linkedNodeId } = attributeValue as AttributeValueNode;
        if (nodeType && linkedNodeId) {
            const type: TreeItemType = yield parseTreeItemType(nodeType);
            const nodeId = { ...graphId, id: linkedNodeId };

            yield put(openNode({ nodeId, type }));
        }
    }

    if (attributeValue?.valueType === 'URL') {
        const { url, name } = attributeValue as AttributeValueUrl;

        // берем либо url если там пусто то имя
        let uri: string =
            LocalesService.internationalStringToString(url, locale) ||
            LocalesService.internationalStringToString(name, locale);

        if (uri.includes('@')) uri = `mailto:${uri}`;

        yield put(appOpenUri(uri));
    }
}

export function* openAttributeLinkSaga() {
    yield takeEvery(OPEN_ATTRIBUTE_LINK, openAttributeLink);
}

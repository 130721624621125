import React from 'react';
import { ScriptEditModeButton } from './Buttons/ScriptEditorMode.component';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { RunScriptButton } from './Buttons/RunScript.component';
import { ResizableToolbar } from '../../../UIKit/components/Toolbar/ResizableToolbar/ResizableToolbar.component';

export const ScriptGeneralToolbarComponent = () => {
    return (
        <ResizableToolbar>
            <ButtonGroup>
                <ScriptEditModeButton />
            </ButtonGroup>
            <ButtonGroup>
                <RunScriptButton />
            </ButtonGroup>
        </ResizableToolbar>
    );
};

import {
    UI_TEMPLATE_EDITOR_CLOSE_TAB,
    UI_TEMPLATE_EDITOR_ADD_WIDGET,
    UI_TEMPLATE_EDITOR_CHANGE_ACTIVE_TAB,
    UI_TEMPLATE_EDITOR_DELETE_WIDGET,
    UI_TEMPLATE_EDITOR_SELECT_WIDGET,
    UI_TEMPLATE_EDITOR_UPDATE_WIDGET,
    UI_TEMPLATE_EDITOR_ADD_NEW_TEMPLATE,
    UI_TEMPLATE_EDITOR_UPDATE_TEMPLATE,
    UI_TEMPLATE_EDITOR_UPDATE_TABS,
} from '../actionTypes/UITemplateEditor.actionTypes';
import { TReducer } from '@/utils/types';
import { UITemplateTabDTO, WidgetDTO } from '@/serverapi/api';
import { TSearchResultWidget } from '../components/Widgets/widget.types';
import { SEARCH_WIDGET_SET_SEARCH_REQUESTS } from '../actionTypes/searchResultsWidget.actionTypes';
import { TUITemplateEditor, TUITemplateEditorState } from './UITemplateEditor.reducer.types';

const INITIAL_UI_TEMPLATE_EDITORS_STATE: TUITemplateEditorState = {};

export const UITemplateEditorReducer: TReducer<TUITemplateEditorState> = (
    state = INITIAL_UI_TEMPLATE_EDITORS_STATE,
    action,
) => {
    switch (action.type) {
        case UI_TEMPLATE_EDITOR_ADD_NEW_TEMPLATE: {
            const {
                payload: { UITemplate },
            } = action;

            return {
                ...state,
                [UITemplate.id]: {
                    template: UITemplate,
                    selectedWidgetId: '',
                    activeTabId: UITemplate.tabs?.[0].id,
                },
            };
        }

        case UI_TEMPLATE_EDITOR_UPDATE_TEMPLATE: {
            const {
                payload: { UITemplateId, UITemplateUpdates },
            } = action;
            const newTemplateState: TUITemplateEditor | undefined = state[UITemplateId];

            return {
                ...state,
                [UITemplateId]: {
                    ...(newTemplateState || {}),
                    template: { ...(newTemplateState?.template || {}), ...UITemplateUpdates },
                },
            };
        }

        case UI_TEMPLATE_EDITOR_UPDATE_TABS: {
            const {
                payload: { UITemplateId, newTabs },
            } = action;

            return {
                ...state,
                [UITemplateId]: {
                    ...state.newTemplates[UITemplateId],
                    template: {
                        ...state.newTemplates[UITemplateId].template,
                        tabs: newTabs,
                    },
                },
            };
        }

        case UI_TEMPLATE_EDITOR_CLOSE_TAB: {
            const {
                payload: { UITemplateId },
            } = action;

            const newState = { ...state };

            delete newState[UITemplateId];

            return {
                ...newState,
            };
        }

        case UI_TEMPLATE_EDITOR_ADD_WIDGET: {
            const {
                payload: { UITemplateId: templateId, widget },
            } = action;

            const currentTemplate: TUITemplateEditor | undefined = state[templateId];

            if (!currentTemplate) return state;

            const newTabs: UITemplateTabDTO[] | undefined = currentTemplate.template.tabs?.map((tab) => {
                if (tab.id === currentTemplate.activeTabId) {
                    return { ...tab, widgetsIds: [...tab.widgetsIds, widget.id] };
                }

                return tab;
            });

            return {
                ...state,
                [templateId]: {
                    ...currentTemplate,
                    template: {
                        ...currentTemplate.template,
                        widgets: [...currentTemplate.template.widgets, widget],
                        tabs: newTabs,
                    },
                },
            };
        }

        case UI_TEMPLATE_EDITOR_DELETE_WIDGET: {
            const {
                payload: { UITemplateId: templateId, widgetId },
            } = action;

            const currentTemplate: TUITemplateEditor | undefined = state[templateId];

            if (!currentTemplate) return state;

            const updatedWidgets: WidgetDTO[] = currentTemplate.template.widgets.filter(
                (widget) => widget.id !== widgetId,
            );

            return {
                ...state,
                [templateId]: {
                    ...currentTemplate,
                    template: {
                        ...currentTemplate.template,
                        widgets: updatedWidgets,
                    },
                },
            };
        }

        case UI_TEMPLATE_EDITOR_SELECT_WIDGET: {
            const {
                payload: { UITemplateId: templateId, widgetId },
            } = action;

            const currentTemplate: TUITemplateEditor | undefined = state[templateId];

            if (!currentTemplate) return state;

            return {
                ...state,
                [templateId]: {
                    ...currentTemplate,
                    selectedWidgetId: widgetId,
                },
            };
        }

        case UI_TEMPLATE_EDITOR_CHANGE_ACTIVE_TAB: {
            const {
                payload: { UITemplateId: templateId, newActiveTabId },
            } = action;

            const currentTemplate: TUITemplateEditor | undefined = state[templateId];

            if (!currentTemplate) return state;

            return {
                ...state,
                [templateId]: {
                    ...currentTemplate,
                    activeTabId: newActiveTabId,
                },
            };
        }

        case UI_TEMPLATE_EDITOR_UPDATE_WIDGET: {
            const {
                payload: { UITemplateId: templateId, widget },
            } = action;

            const currentTemplate: TUITemplateEditor | undefined = state[templateId];

            if (!currentTemplate) return state;

            const updatedWidgets: WidgetDTO[] = currentTemplate.template.widgets.map((currentWidget) =>
                currentWidget.id === widget.id ? widget : currentWidget,
            );

            return {
                ...state,
                [templateId]: {
                    ...currentTemplate,
                    template: {
                        ...currentTemplate.template,
                        widgets: updatedWidgets,
                    },
                },
            };
        }

        case SEARCH_WIDGET_SET_SEARCH_REQUESTS: {
            const {
                payload: { templateId, widgetId, searchRequests },
            } = action;

            const currentTemplate: TUITemplateEditor | undefined = state[templateId];

            if (!currentTemplate) return state;

            const updatedWidgets: WidgetDTO[] = currentTemplate.template.widgets.map((currentWidget) => {
                if (currentWidget.id === widgetId) {
                    return { ...currentWidget, searchRequests } as TSearchResultWidget;
                }

                return currentWidget;
            });

            return {
                ...state,
                [templateId]: {
                    ...currentTemplate,
                    template: {
                        ...currentTemplate.template,
                        widgets: updatedWidgets,
                    },
                },
            };
        }

        default:
            return state;
    }
};

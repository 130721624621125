import { INIT_HISTORY_TAB } from '../../actionsTypes/historyTab.actionTypes';
import { TInitHistoryTabAction } from '../../actions/historyTab.actions.types';
import { put, select, takeEvery } from 'redux-saga/effects';
import { ModelVersionInfo, NodeId } from '../../serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { getContentLoadingPageTab } from '../utils';
import {
    workspaceActivateTab,
    workspaceAddTab,
    workspaceRemoveTab,
    workspaceRemoveTabRequest,
} from '@/actions/tabs.actions';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { TWorkspaceTab, TWorkspaceTabItemParams } from '@/models/tab.types';
import { EditorMode } from '@/models/editorMode';
import { defaultWorkspaceTabActions } from '@/models/tab';
import messages from '../../modules/Models/components/ModelHistory/ModelHistory.messages';
import { ModelDaoService } from '@/services/dao/ModelDaoService';
import { generateCustomNodeId } from '@/utils/nodeId.utils';
import { ModelHistoryTabPrefix } from './modelHistory.const';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { isUndefined } from 'is-what';

function* openHistoryTab({ payload: { nodeId } }: TInitHistoryTabAction) {
    const historyTabNodeId: NodeId = generateCustomNodeId(nodeId, ModelHistoryTabPrefix);

    const currentHistoryTab: TWorkspaceTab | undefined = yield select(TabsSelectors.byId(historyTabNodeId));
    if (!isUndefined(currentHistoryTab)) {
        yield put(workspaceActivateTab(currentHistoryTab));
        return;
    }

    const intl = LocalesService.useIntl();
    const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);

    try {
        yield put(workspaceAddTab(contentLoadingPageTab));
        const modelVersions: ModelVersionInfo[] = yield ModelDaoService.getModelVersions(nodeId);
        const modelHistoryTab: TWorkspaceTab = {
            title: intl.formatMessage(messages.tabTitle),
            type: WorkSpaceTabTypes.MODEL_HISTORY,
            nodeId: historyTabNodeId,
            mode: EditorMode.Read,
            actions: {
                ...defaultWorkspaceTabActions,
            },
            params: {
                nodeId,
                modelVersions,
            } as TWorkspaceTabItemParams,
        };
        yield put(workspaceRemoveTab(contentLoadingPageTab));
        yield put(workspaceAddTab(modelHistoryTab));
    } catch (error) {
        yield put(workspaceRemoveTabRequest(contentLoadingPageTab));
        throw error;
    }
}

export function* modelHistorySaga() {
    yield takeEvery(INIT_HISTORY_TAB, openHistoryTab);
}

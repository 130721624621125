import { defineMessages } from 'react-intl';

export default defineMessages({
    shapes: {
        id: 'ShapeDropdown.shapes',
        defaultMessage: 'Фигуры',
    },
    ellipse: {
        id: 'ShapeDropdown.ellipse',
        defaultMessage: 'Эллипс',
    },
    triangle: {
        id: 'ShapeDropdown.triangle',
        defaultMessage: 'Треугольник',
    },
    cloud: {
        id: 'ShapeDropdown.cloud',
        defaultMessage: 'Облако',
    },
    hexagon: {
        id: 'ShapeDropdown.hexagon',
        defaultMessage: 'Шестиугольник',
    },
    cylinder: {
        id: 'ShapeDropdown.cylinder',
        defaultMessage: 'Цилиндр',
    },
    swimlane: {
        id: 'ShapeDropdown.swimlane',
        defaultMessage: 'Плавательная дорожка',
    },
    rhombus: {
        id: 'ShapeDropdown.rhombus',
        defaultMessage: 'Ромб',
    },
    doubleEllipse: {
        id: 'ShapeDropdown.doubleEllipse',
        defaultMessage: 'Двойной эллипс',
    },
    rectangle: {
        id: 'ShapeDropdown.rectangle',
        defaultMessage: 'Прямоугольник',
    },
});

export const OPEN_UI_TEMPLATES_TAB = 'OPEN_UI_TEMPLATES_TAB';

export const OPEN_UI_TEMPLATE_EDITOR_TAB = 'OPEN_UI_TEMPLATE_EDITOR_TAB';

export const LOAD_UI_TEMPLATES_SUCCESS = 'LOAD_UI_TEMPLATES_SUCCESS';

export const SAVE_UI_TEMPLATE = 'SAVE_UI_TEMPLATE';

export const SAVE_UI_TEMPLATE_SUCCESS = 'SAVE_UI_TEMPLATE_SUCCESS';

export const SAVE_UI_TEMPLATE_ERROR = 'SAVE_UI_TEMPLATE_ERROR';

export const DELETE_UI_TEMPLATE = 'DELETE_UI_TEMPLATE';

import type { Editor } from '@tiptap/react';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_FONT_FAMILY_OPTION } from './Controls.constants';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
import theme from '../../../Toolbar/ResizableToolbar/ButtonGroups/groups.scss';
import { Select } from '../../../Select/Select.component';
import { StateObserver } from '../../common/sharedState.class';
import { fontFamilyOptions } from '../../../../../../utils/configuration';
import classNames from 'classnames';
import messages from '../CommonToolbar.messages';

const getFocusedBlock = (editor: Editor) =>
    editor.getAttributes('textStyle').fontFamily || DEFAULT_FONT_FAMILY_OPTION.value;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setFontFamily(value).run();

type TFontFamilyProps = {
    className?: string;
};

export const FontFamily: FC<TFontFamilyProps> = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <Select
            onChange={setCurrentState}
            value={
                fontFamilyOptions.find((option) => option.value === value)?.label || DEFAULT_FONT_FAMILY_OPTION.label
            }
            placeholder=""
            originalTheme
            data-test="wiki-toolbar-group_font-family-select"
            wrapperClassName={classNames(theme.fontFamilyContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            tooltip={intl.formatMessage(messages.fontFamily)}
        >
            {fontFamilyOptions.map((style) => {
                return <Select.Option value={style.value} label={<span style={style.style}>{style.label}</span>} />;
            })}
        </Select>
    );
};

import {
    ADD_STORE_EDIT_WIKI_MODEL_TYPE_WORKSPACE_TAB,
    CHANGE_WIKI_MODEL_TYPE_ALLOW_APPROVALS,
    CHANGE_WIKI_MODEL_TYPE_DESCRIPTION,
    CHANGE_WIKI_MODEL_TYPE_ENABLE_MODEL,
    CHANGE_WIKI_MODEL_TYPE_GROUP,
    CHANGE_WIKI_MODEL_TYPE_ID,
    CHANGE_WIKI_MODEL_TYPE_NAME,
    WIKI_MODEL_TYPE_ADD_ATTRIBUTE_TYPES,
    WIKI_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES,
    WIKI_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS,
} from '../../actionsTypes/workspaceTab/editWikiModelTypeWorkspaceTab.actionTypes';
import {
    TAddAttributeTypesAction,
    TAddAttributeTypesPayload,
    TAddStoreEditWikiModelTypeWorkspaceTabAction,
    TAddStoreEditWikiModelTypeWorkspaceTabPayload,
    TChangeModelTypeAllowApprovalsAction,
    TChangeWikiModelTypeAllowApprovalsPayload,
    TChangeWikiModelTypeDescriptionAction,
    TChangeWikiModelTypeDescriptionPayload,
    TChangeWikiModelTypeEnableModelAction,
    TChangeWikiModelTypeEnableModelPayload,
    TChangeWikiModelTypeGroupAction,
    TChangeWikiModelTypeGroupPayload,
    TChangeWikiModelTypeIdAction,
    TChangeWikiModelTypeIdPayload,
    TChangeWikiModelTypeNameAction,
    TChangeWikiModelTypeNamePayload,
    TRemoveAttributeTypesAction,
    TRemoveAttributeTypesGroupsAction,
    TRemoveAttributeTypesGroupsPayload,
    TRemoveAttributeTypesPayload,
} from './editWikiModelTypeWorkspaceTab.actions.types';

export const addStoreEditWikiModelTypeWorkspaceTab = (
    payload: TAddStoreEditWikiModelTypeWorkspaceTabPayload,
): TAddStoreEditWikiModelTypeWorkspaceTabAction => ({
    type: ADD_STORE_EDIT_WIKI_MODEL_TYPE_WORKSPACE_TAB,
    payload,
});

export const changeWikiModelTypeName = (payload: TChangeWikiModelTypeNamePayload): TChangeWikiModelTypeNameAction => ({
    type: CHANGE_WIKI_MODEL_TYPE_NAME,
    payload,
});

export const changeWikiModelTypeId = (payload: TChangeWikiModelTypeIdPayload): TChangeWikiModelTypeIdAction => ({
    type: CHANGE_WIKI_MODEL_TYPE_ID,
    payload,
});

export const changeWikiModelTypeDescription = (
    payload: TChangeWikiModelTypeDescriptionPayload,
): TChangeWikiModelTypeDescriptionAction => ({
    type: CHANGE_WIKI_MODEL_TYPE_DESCRIPTION,
    payload,
});

export const changeWikiModelTypeGroup = (
    payload: TChangeWikiModelTypeGroupPayload,
): TChangeWikiModelTypeGroupAction => ({
    type: CHANGE_WIKI_MODEL_TYPE_GROUP,
    payload,
});

export const changeWikiModelTypeEnableModel = (
    payload: TChangeWikiModelTypeEnableModelPayload,
): TChangeWikiModelTypeEnableModelAction => ({
    type: CHANGE_WIKI_MODEL_TYPE_ENABLE_MODEL,
    payload,
});

export const wikiModelTypeAddAttributeTypes = (payload: TAddAttributeTypesPayload): TAddAttributeTypesAction => ({
    type: WIKI_MODEL_TYPE_ADD_ATTRIBUTE_TYPES,
    payload,
});

export const wikiModelTypeRemoveAttributeTypes = (
    payload: TRemoveAttributeTypesPayload,
): TRemoveAttributeTypesAction => ({
    type: WIKI_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES,
    payload,
});

export const wikiModelTypeRemoveAttributeTypesGroups = (
    payload: TRemoveAttributeTypesGroupsPayload,
): TRemoveAttributeTypesGroupsAction => ({
    type: WIKI_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS,
    payload,
});

export const changeWikiModelTypeAllowApprovals = (
    payload: TChangeWikiModelTypeAllowApprovalsPayload,
): TChangeModelTypeAllowApprovalsAction => ({
    type: CHANGE_WIKI_MODEL_TYPE_ALLOW_APPROVALS,
    payload,
});

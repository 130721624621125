import { TReducer } from '../utils/types';
import {
    WORKSPACE_TABS_ACTIVATE,
    WORKSPACE_TABS_ADD,
    WORKSPACE_TABS_CHANGE,
    WORKSPACE_TABS_CHANGE_TITLE,
    WORKSPACE_TABS_COPY_TO_CLIPBOARD,
    WORKSPACE_TABS_ORDER_CHANDGED,
    WORKSPACE_TABS_REMOVE,
    WORKSPACE_TABS_REMOVE_BY_NODE_ID,
} from '../actionsTypes/tabs.actionTypes';
import { TWorkspaceTab } from '../models/tab.types';
import { EDITOR_MODE_CHANGED } from '../actionsTypes/editor.actionTypes';
import { NodeId } from '../serverapi/api';
import { TNodeState } from './entities/TNodeState';
import { TabsState } from './tabs.reducer.types';
import { compareNodeIds } from '../utils/nodeId.utils';

const INITIAL_TABS_STATE: TabsState = {
    data: new TNodeState(),
    activeId: undefined,
    clipboard: undefined,
};

export const nextTab = (state: TabsState, removeSchemaId: NodeId): NodeId | undefined => {
    const { activeId } = state;
    const tabsIds = state.data.keys();
    if (!compareNodeIds(activeId, removeSchemaId)) {
        return activeId;
    }
    if (tabsIds.length <= 1) {
        return undefined;
    }
    const index = tabsIds.findIndex((id) => compareNodeIds(id, activeId));

    if (index < 0) {
        return activeId;
    }
    if (index === 0) {
        return tabsIds[1];
    }

    return tabsIds[index - 1];
};

export const tabsReducer: TReducer<TabsState> = (tabsState = INITIAL_TABS_STATE, action) => {
    switch (action.type) {
        case EDITOR_MODE_CHANGED: {
            const { mode } = action.payload;
            if (!tabsState.activeId) {
                return tabsState;
            }

            const currentTab = tabsState.data.get(tabsState.activeId);

            if (!currentTab) {
                return tabsState;
            }

            return {
                ...tabsState,
                data: tabsState.data.set(tabsState.activeId, { ...currentTab, mode }),
            };
        }

        case WORKSPACE_TABS_ACTIVATE: {
            const { workspaceTab } = action.payload;

            return {
                ...tabsState,
                activeId: workspaceTab.nodeId,
            };
        }

        case WORKSPACE_TABS_ADD: {
            const tab: TWorkspaceTab = action.payload.workspaceTab;
            const { nodeId } = tab;

            if (!nodeId?.id || !nodeId?.serverId || !nodeId?.repositoryId) {
                return tabsState;
            }

            return {
                ...tabsState,
                activeId: nodeId,
                data: tabsState.data.set(nodeId, { ...tab }),
            };
        }

        case WORKSPACE_TABS_REMOVE: {
            const { workspaceTab } = action.payload;

            return {
                ...tabsState,
                activeId: nextTab(tabsState, workspaceTab.nodeId),
                data: tabsState.data.delete(workspaceTab.nodeId),
            };
        }

        case WORKSPACE_TABS_REMOVE_BY_NODE_ID: {
            const { nodeId } = action.payload;

            return {
                ...tabsState,
                activeId: nextTab(tabsState, nodeId),
                data: tabsState.data.delete(nodeId),
            };
        }

        case WORKSPACE_TABS_CHANGE_TITLE: {
            const { workspaceTab, title } = action.payload;
            const { nodeId } = workspaceTab;
            if (!nodeId?.id || !nodeId?.serverId || !nodeId?.repositoryId) {
                return tabsState;
            }

            const currentTab = tabsState.data.get(nodeId);

            if (!currentTab) {
                return tabsState;
            }

            return {
                ...tabsState,
                data: tabsState.data.set(nodeId, { ...currentTab, title }),
            };
        }

        case WORKSPACE_TABS_COPY_TO_CLIPBOARD: {
            const { value } = action.payload;

            return {
                ...tabsState,
                clipboard: value,
            };
        }

        case WORKSPACE_TABS_CHANGE: {
            const { workspaceTab } = action.payload;
            const { nodeId } = workspaceTab;

            if (!nodeId?.id || !nodeId?.serverId || !nodeId?.repositoryId) {
                return tabsState;
            }

            if (!tabsState.data.get(nodeId)) {
                return tabsState;
            }

            return {
                ...tabsState,
                data: tabsState.data.set(nodeId, workspaceTab),
            };
        }

        // todo странный метод, он не меняет порядок он добавляет элементы, из DnDTabs надо перенести логику
        case WORKSPACE_TABS_ORDER_CHANDGED: {
            const { workspaceTabs } = action.payload;

            let data: TNodeState<TWorkspaceTab> = new TNodeState();
            workspaceTabs.forEach((tab) => {
                data = data.set(tab.nodeId, tab);
            });

            return {
                ...tabsState,
                data,
            };
        }

        default: {
            return tabsState;
        }
    }
};

import React from 'react';
import { EditorMode } from '../../../../../../models/editorMode';
import icLock from '@/resources/icons/MainMenuLock.svg';
import icUnLock from '@/resources/icons/MainMenuUnLock.svg';
import { NodeId } from '../../../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { editorModeChangedPrepareAction } from '../../../../../../actions/editor.actions';
import { workspaceTabSetParams } from '../../../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';

export const EditModeButton = () => {
    const dispatch = useDispatch();
    const activeTabId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const isReadMode: boolean = useSelector(TabsSelectors.checkIsReadModeInActiveTab);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getEditorModeDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getEditorModeTooltip);

    const onModeChange = (mode: EditorMode) => {
        dispatch(editorModeChangedPrepareAction(mode));
    };

    const isBlockNavigationArrows = (workspaceId: NodeId, undoManager: { undo: boolean; redo: boolean }) => {
        dispatch(workspaceTabSetParams(workspaceId, { undoManager }));
    };
    const handleModeChange = () => {
        if (isReadMode) {
            onModeChange(EditorMode.Edit);
        } else {
            onModeChange(EditorMode.Read);
            isBlockNavigationArrows(activeTabId, { undo: false, redo: false });
        }
    };

    return (
        <ToolbarButton
            isActive={!isReadMode}
            icon={isReadMode ? icLock : icUnLock}
            size="small"
            dataTest={isReadMode ? 'graph-general-toolbar_mode-button_read' : 'graph-general-toolbar_mode-button_edit'}
            disabled={disabled}
            onClick={handleModeChange}
            tooltip={tooltip}
        />
    );
};

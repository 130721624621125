import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { UITemplateDTO, UITemplateDTOTabsTypeEnum, UITemplateTabDTO, WidgetDTO } from '../../../serverapi/api';
import { TUITemplateEditorState } from '../reducers/UITemplateEditor.reducer.types';

const UITemplateEditorStateSelector = (state: TRootState): TUITemplateEditorState => state.UITemplateEditor;

export namespace UITemplateEditorSelectors {
    export const UITemplateById = (templateId: string) =>
        createSelector<TRootState, TUITemplateEditorState, UITemplateDTO | undefined>(
            UITemplateEditorStateSelector,
            (state) => state[templateId].template,
        );

    export const widgets = (templateId: string) =>
        createSelector<TRootState, UITemplateDTO | undefined, WidgetDTO[]>(
            UITemplateById(templateId),
            (template) => template?.widgets || [],
        );

    export const widgetsByTabId = (templateId: string, tabId: string) =>
        createSelector<TRootState, UITemplateDTO | undefined, WidgetDTO[]>(
            UITemplateById(templateId),
            (template) => {
                const tab: UITemplateTabDTO | undefined = template?.tabs?.find((tab) => tab.id === tabId);

                if (!tab) return [];

                const widgets: WidgetDTO[] =
                    template?.widgets.filter((widget) => tab.widgetsIds.includes(widget.id)) || [];

                return widgets;
            },
        );

    const selectedWidgetId = (templateId: string) =>
        createSelector<TRootState, TUITemplateEditorState, string>(
            UITemplateEditorStateSelector,
            (state) => state[templateId].selectedWidgetId,
        );

    export const selectedWidget = (templateId: string) =>
        createSelector<TRootState, string, UITemplateDTO | undefined, WidgetDTO | undefined>(
            selectedWidgetId(templateId),
            UITemplateById(templateId),
            (selectedWidgetId, template) => (template?.widgets || []).find((widget) => widget.id === selectedWidgetId),
        );

    export const tabs = (templateId: string) =>
        createSelector<TRootState, UITemplateDTO | undefined, UITemplateTabDTO[]>(
            UITemplateById(templateId),
            (template) => template?.tabs || [],
        );

    export const tabsType = (templateId: string) =>
        createSelector<TRootState, UITemplateDTO | undefined, UITemplateDTOTabsTypeEnum | undefined>(
            UITemplateById(templateId),
            (template) => template?.tabsType,
        );

    export const activeTabId = (templateId: string) =>
        createSelector<TRootState, TUITemplateEditorState, string | undefined>(
            UITemplateEditorStateSelector,
            (state) => state[templateId].activeTabId,
        );
}

import React from 'react';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { ResizableToolbar } from '../../../UIKit/components/Toolbar/ResizableToolbar/ResizableToolbar.component';
import { SpreadsheetEditModeButton } from './Buttons/SpreadsheetEditModeButton.component';

export const SpreadsheetEditorToolbar = () => {
    return (
        <ResizableToolbar>
            <ButtonGroup>
                <SpreadsheetEditModeButton />
            </ButtonGroup>
        </ResizableToolbar>
    );
};

import React, { FC } from 'react';
import theme from './SearchResultSidePanel.scss';
import { NavigatorPanel } from '@/modules/Navigator/components/NavigatorPanel/NavigatorPanel.component';
import { TNavigatorTab } from '@/reducers/navigator.reducer.types';
import { useIntl } from 'react-intl';
import messages from './SearchResultSidePanel.messages';
import { useDispatch } from 'react-redux';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { Input } from '@/modules/UIKit/components/Input/Input.component';
import { Form } from 'antd';
import { InternationalString, NodeId } from '@/serverapi/api';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { TSearchResultSidePanelProps, TSearchResultWidget } from '../../widget.types';
import { UITemplateEditorSelectWidget, UITemplateEditorUpdateWidget } from '@/modules/UITemplates/actions/UITemplateEditor.actions';
import { SearchDialogType, TDbSearchDialogProps } from '@/modules/Search/DbSearchDialog/DbSearchDialog.types';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { openSearchInDialog } from '@/actions/search.actions';
import { generateCustomNodeId } from '@/utils/nodeId.utils';
import { searchWidgetSetSearchRequests } from '@/modules/UITemplates/actions/searchResultsWidget.actions';
import { TConfirmationDialogProps } from '@/modules/dialogs/ConfirmationDialog/ConfirmationDialog.types';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

export const SearchResultSidePanel: FC<TSearchResultSidePanelProps> = ({
    serverId,
    templateId,
    widget,
    widget: { id, name, searchRequests = [], showElementType, showNodeType },
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [generalForm] = Form.useForm();

    const nodeId: NodeId = { id, repositoryId: templateId, serverId };
    const searchNodeId: NodeId = generateCustomNodeId(nodeId, SearchDialogType.Widget);

    const searchTermsHandler = () => {
        const dialogProps: TDbSearchDialogProps = {
            nodeId,
            type: SearchDialogType.Widget,
        };
        dispatch(openDialog(DialogType.DB_SEARCH_DIALOG, dialogProps));
        dispatch(openSearchInDialog(searchNodeId, nodeId, searchRequests));
    };

    const clearSearchTermsHandler = () => {
        const dialogProps: TConfirmationDialogProps = {
            title: '',
            OKButtonText: intl.formatMessage(messages.clear),
            question: intl.formatMessage(messages.clearSearchTermsQuestion),
            onSubmit: () => dispatch(searchWidgetSetSearchRequests(templateId, id, [])),
        };
        dispatch(openDialog(DialogType.CONFIRMATION, dialogProps));
    };

    const onChangeWidgetName = (name: InternationalString) => {
        const updatedWidget: TSearchResultWidget = { ...widget, name };
        dispatch(UITemplateEditorUpdateWidget(templateId, updatedWidget));
    };

    const onChangeShowNodeTypeStatus = () => {
        const updatedWidget: TSearchResultWidget = { ...widget, showNodeType: !showNodeType };
        dispatch(UITemplateEditorUpdateWidget(templateId, updatedWidget));
    };

    const onChangeShowElementTypeStatus = () => {
        const updatedWidget: TSearchResultWidget = { ...widget, showElementType: !showElementType };
        dispatch(UITemplateEditorUpdateWidget(templateId, updatedWidget));
    };

    const closeHandler = () => {
        dispatch(UITemplateEditorSelectWidget(templateId, ''));
    };

    return (
        <NavigatorPanel
            titleProps={{
                title: intl.formatMessage(messages.title),
            }}
            type={TNavigatorTab.Widgets}
            className={theme.searchResultSidePanel}
            onClose={closeHandler}
        >
            <div className={theme.sidePanelBody}>
                <div className={theme.colContainer}>
                    <Input name="templateId" label={intl.formatMessage(messages.ID)} disabled value={id} />
                    <Form autoComplete="off" form={generalForm} layout="vertical" className={theme.form}>
                        <MultiLangInputDialog
                            generalForm={generalForm}
                            mainInputName="templateName"
                            placeholder={intl.formatMessage(messages.namePlaceholder)}
                            multiLangValue={name}
                            maxLength={150}
                            onChange={onChangeWidgetName}
                            label={intl.formatMessage(messages.widgetName)}
                        />
                    </Form>
                </div>
                <div className={theme.colContainer}>
                    <Button onClick={searchTermsHandler}>{intl.formatMessage(messages.searchTerms)}</Button>
                    <Button disabled={!searchRequests?.length} onClick={clearSearchTermsHandler}>
                        {intl.formatMessage(messages.clearSearchTerms)}
                    </Button>
                </div>
                <div className={theme.colContainer}>
                    <Checkbox status={!!showNodeType} onChange={onChangeShowNodeTypeStatus}>
                        {intl.formatMessage(messages.showNodeType)}
                    </Checkbox>
                    <Checkbox status={!!showElementType} onChange={onChangeShowElementTypeStatus}>
                        {intl.formatMessage(messages.showElementType)}
                    </Checkbox>
                </div>
            </div>
        </NavigatorPanel>
    );
};

import type { TGeneralToolbarProps } from '@/modules/MainMenu/components/items/GeneralMenuItem/GeneralMenuItem.types';
import type { TImageAttributes } from '@/modules/UIKit/components/TipTapTextEditor/Editor/extensions/image.types';
import type {
    TWikiEditorToolbarDispatchProps,
    TWikiEditorToolbarProps,
    TWikiEditorToolbarStateProps,
} from '../components/WikiEditor.types';
import type { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import type { TRootState } from '@/reducers/root.reducer.types';
import type { TWikiLink } from '@/models/tab.types';
import type { TWorkspaceTab } from '@/models/tab.types';
import type { Node, NodeId } from '@/serverapi/api';
import type { TDispatch } from '@/utils/types';
import { connect } from 'react-redux';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { v4 as uuid } from 'uuid';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { wikiUploadImage } from '@/actions/entities/wiki.actions';
import { fileUpload } from '@/actions/uploader.actions';
import WikiEditorToolbar from '../components/WikiEditorToolbar.component';

const mapStateToProps = (state: TRootState, ownProps: TGeneralToolbarProps): TWikiEditorToolbarStateProps => {
    const tab: TWorkspaceTab | undefined = TabsSelectors.byId(ownProps.nodeId)(state);
    const content = tab?.content as IWikiNode;
    const nodeId = tab?.nodeId as NodeId;
    const server = ServerSelectors.server(content.serverId)(state);
    const baseUrl = server?.url ? `${server.url}/${PictureSymbolConstants.DOWNLOAD_LINK}` : '';

    return {
        id: 'WikiModel',
        baseUrl,
        modelId: nodeId,
    };
};

const mapDispatchToProps = (dispatch: TDispatch): TWikiEditorToolbarDispatchProps => {
    return {
        handlers: {
            // TODO удалить лишние хэндлеры при удалении старого движка
            imageLinkMapper: (src: string, baseUrl: string) =>
                src?.startsWith('data:image/') ? src : `${baseUrl}/${src}/`,
            readFromClipboard: (callback) => {
                navigator.clipboard.readText().then(callback);
            },
            copyToClipboard: (text: string) => {
                navigator.clipboard.writeText(text);
            },
            openImageDialog: (modelId: NodeId, onSubmit: (file: File, attrs: Partial<TImageAttributes>) => void) => {
                dispatch(
                    openDialog(DialogType.IMAGE_UPLOAD_DIALOG_WIKI, {
                        id: uuid(),
                        workspaceId: modelId,
                        onSubmit,
                    }),
                );
            },
            openInsertImageLinkDialog: (onSubmit: (attrs: Partial<TImageAttributes>) => void) => {
                dispatch(openDialog(DialogType.IMAGE_LINK_DIALOG, { onSubmit }));
            },
            openChooseModelDialog: (modelId: NodeId, onSubmit: (image: Partial<TImageAttributes>) => void) => {
                dispatch(
                    openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                        serverId: modelId.serverId,
                        repositoryId: modelId.repositoryId,
                        disableContextMenu: true,
                        includeTypes: [TreeItemType.Repository, TreeItemType.Folder, TreeItemType.Model],
                        typesAllowedToSelect: [TreeItemType.Model],
                        onSubmit: (treeNodeId: NodeId, node: Node) => onSubmit({ id: treeNodeId.id, title: node.name }),
                    }),
                );
            },
            openLinkDialog: (
                modelId: NodeId,
                onSubmit: (link: TWikiLink) => void,
                options: { url: string; text: string; external: boolean },
            ) => {
                const { url, text, external } = options || {};
                const { serverId } = modelId;

                dispatch(openDialog(DialogType.LINK_DIALOG, { serverId, onSubmit, url, text, external }));
            },
            uploadFile: (file: File, fileId: string, modelId: NodeId) => {
                dispatch(fileUpload(file, { ...modelId, id: fileId }, modelId));

                return {
                    src: `${modelId.repositoryId}/${fileId}`,
                };
            },
            uploadImage: (file: File, fileId: string, modelId: NodeId) => {
                const fileNodeId = { ...modelId, id: fileId };

                dispatch(wikiUploadImage({ file, fileId: fileNodeId, modelId }));

                return { id: fileId };
            },
        },
    };
};

const mergeProps = (
    stateProps: TWikiEditorToolbarStateProps,
    dispatchProps: TWikiEditorToolbarDispatchProps,
): TWikiEditorToolbarProps => {
    const { baseUrl, modelId } = stateProps;
    const { handlers } = dispatchProps;

    const newDispatchProps = {
        ...dispatchProps,
        handlers: {
            ...handlers,
            imageLinkMapper: (src: string) => {
                return handlers.imageLinkMapper(src, baseUrl);
            },
            openImageDialog: (onSubmit: (file: File, attrs: Partial<TImageAttributes>) => void) => {
                return handlers.openImageDialog(modelId, onSubmit);
            },
            openChooseModelDialog: (onSubmit: (image: Partial<TImageAttributes>) => void) => {
                return handlers.openChooseModelDialog(modelId, onSubmit);
            },
            openLinkDialog: (
                onSubmit: (link: TWikiLink) => void,
                options: { url: string; text: string; external: boolean },
            ) => {
                return handlers.openLinkDialog(modelId, onSubmit, options);
            },
            uploadFile: (file: File, fileId: string) => {
                return handlers.uploadFile(file, fileId, modelId);
            },
            uploadImage: (file: File, fileId: string) => {
                return handlers.uploadImage(file, fileId, modelId);
            },
        },
    };

    return { ...stateProps, ...newDispatchProps };
};

export const WikiEditorToolbarContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(WikiEditorToolbar);

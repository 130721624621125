import React, { useRef, useState } from 'react';
import theme from './EllipsisText.scss';
import { getTextBlockRenderedSize } from '../../../../../../utils/render.utils';
import classNames from 'classnames';
import { TOOLTIP_RC_ID } from '../../../../../UIKit/components/TooltipRC/TooltipRC.constants';

const DEFAULT_LINE_HEIGHT = 22;

const DEFAULT_FONT_SIZE = 14;

type TEllipsisTextProps = {
    text: string;
    fontSize?: number;
    lineHeight?: number;
    linesQuantity?: number;
};

export const EllipsisText = React.memo(
    ({
        text,
        linesQuantity = 2,
        lineHeight = DEFAULT_LINE_HEIGHT,
        fontSize = DEFAULT_FONT_SIZE,
    }: TEllipsisTextProps) => {
        const textRef = useRef<HTMLDivElement>(null);
        const [showTooltip, setShowTooltip] = useState(false);
        const isOneRowNext: boolean = linesQuantity === 1;

        const handleMouseEnter = () => {
            if (!textRef.current) return;

            const parentWidth = textRef.current.offsetWidth;
            const { height, oneRowWidth } = getTextBlockRenderedSize(text, {
                width: `${parentWidth}px`,
                overflowWrap: 'wrap',
                fontFamily: 'Segoe UI',
                wordBreak: isOneRowNext ? 'break-all' : 'break-word',
                fontSize: `${fontSize}px`,
                lineHeight: `${lineHeight}px`,
            });
            if (isOneRowNext) {
                setShowTooltip(oneRowWidth > parentWidth);
                return;
            }

            setShowTooltip(height > linesQuantity * lineHeight);
        };

        return (
            <div
                ref={textRef}
                onMouseEnter={handleMouseEnter}
                className={classNames(theme.ellipsis, isOneRowNext && theme.oneRowEllipsis)}
                style={{
                    WebkitLineClamp: linesQuantity,
                }}
                data-tooltip-id={TOOLTIP_RC_ID}
                data-tooltip-content={showTooltip ? text : ''}
            >
                {text}
            </div>
        );
    },
);

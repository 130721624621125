import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'NewSimulationNodeDialog.name',
        defaultMessage: 'Имя имитационной модели',
    },
    cancel: {
        id: 'NewSimulationNodeDialog.cancel',
        defaultMessage: 'Отмена',
    },
    create: {
        id: 'NewSimulationNodeDialog.create',
        defaultMessage: 'Создать',
    },
    simulationAddTitle: {
        id: 'Simulation.simulationAddTitle',
        defaultMessage: 'Новое имитационное моделирование',
    },
    requiredField: {
        id: 'Simulation.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    clearResults: {
        id: 'Simulation.clearResults',
        defaultMessage: 'При изменении параметров запуска результаты выполнения имитационного моделирования будут очищены. Продолжить?',
    },
    saveParamsLaunch: {
        id: 'Simulation.saveParamsLaunch',
        defaultMessage: 'Сохранение параметров запуска',
    },
    continueSave: {
        id: 'Simulation.continueSave',
        defaultMessage: 'Продолжить',
    },
    namePlaceholder: {
        id: 'Simulation.namePlaceholder',
        defaultMessage: 'Имя новой имитационной модели'
    }
});

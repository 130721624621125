import {
    OBJECT_CONNECTIONS_DELETE,
    OBJECT_CONNECTIONS_LOAD_SUCCESS,
} from '../actionsTypes/objectConnections.actionTypes';
import { NodeId, ObjectModelConnections } from '../serverapi/api';
import { TObjectConnectionsDeleteAction, TObjectConnectionsLoadSuccessAction } from './objectConnections.actions.types';

export const objectConnectionsLoadSuccess = (
    connections: ObjectModelConnections[],
    nodeId: NodeId,
): TObjectConnectionsLoadSuccessAction => ({
    type: OBJECT_CONNECTIONS_LOAD_SUCCESS,
    payload: {
        connections,
        nodeId,
    },
});

export const objectConnectionsDelete = (nodeId: NodeId): TObjectConnectionsDeleteAction => ({
    type: OBJECT_CONNECTIONS_DELETE,
    payload: {
        nodeId,
    },
});

import { Form, Select, InputNumber, Input, FormInstance } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import { AuthorizationMethod } from '../../../../../models/security/authorizationMethod.types';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { SystemPropertiesFormItemNames, TPropsWithFormRef } from '../../SystemProperties.types';
import { convertToNumber } from '../../../../../utils/ConvertToNumber.utils';
import { SystemPropertiesActiveAuthorizationMethodsEnum } from '../../../../../serverapi/api';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

export const MIN_PASSWORD_LENGTH = 6;

export const minPasswordLengthValidator = (_, value: string | null) => {
    if (value === null || Number(value) >= MIN_PASSWORD_LENGTH) {
        return Promise.resolve();
    }

    return Promise.reject();
};

export const passwordDurationTimeValidator = (form: FormInstance | null) => {
    const min: string | undefined = form?.getFieldValue('passwordMinDurationTime');
    const max: string | undefined = form?.getFieldValue('passwordMaxDurationTime');

    if (!min || !max || Number(min) <= Number(max)) {
        return Promise.resolve();
    }

    return Promise.reject();
};

export const normalizeNumberInput = (value: string) => {
    const passwordDurationTime = Number(convertToNumber(value.slice(0, 9)));

    return passwordDurationTime === 0 ? '' : passwordDurationTime;
};

export const SecuritySettings = (props: TPropsWithFormRef) => {
    const intl = useIntl();

    const { formRef } = props;
    const form: FormInstance | null = formRef.current;

    const isActiveAuthorizationSettings: boolean | undefined = useSelector(
        SystemPropertiesSelectors.getIsActiveAuthorizationSettings,
    );
    const activeAuthorizationMethods: SystemPropertiesActiveAuthorizationMethodsEnum[] | undefined = useSelector(
        SystemPropertiesSelectors.getActiveAuthorizationMethods,
    );
    const blockAuthTime: number | undefined = useSelector(SystemPropertiesSelectors.geBlockAuthTime);
    const maxFailAuthAttempts: number | undefined = useSelector(SystemPropertiesSelectors.getMaxFailAuthAttempts);
    const minPasswordLength: number | undefined = useSelector(SystemPropertiesSelectors.minPasswordLength);
    const isActiveUpperAndLowerCase: boolean | undefined = useSelector(
        SystemPropertiesSelectors.isActiveUpperAndLowerCase,
    );
    const isActiveNumbers: boolean | undefined = useSelector(SystemPropertiesSelectors.isActiveNumbers);
    const isActiveSpecialCharacters: boolean | undefined = useSelector(
        SystemPropertiesSelectors.isActiveSpecialCharacters,
    );
    const passwordMinDurationTime: number | undefined = useSelector(SystemPropertiesSelectors.passwordMinDurationTime);
    const passwordMaxDurationTime: number | undefined = useSelector(SystemPropertiesSelectors.passwordMaxDurationTime);
    const countOfLastUsedPasswords: number | undefined = useSelector(
        SystemPropertiesSelectors.countOfLastUsedPasswords,
    );
    const passwordChangePermission: boolean | undefined = useSelector(
        SystemPropertiesSelectors.getPasswordChangePermission,
    );

    /**
     * минимальный и максимальный срок действия пароля валидируются с учетом значений друг друга
     * эта функция нужна чтобы при изменении значения в одном поле сразу проводилась проверка валидации во втором
     */
    const onChangePasswordDurationTime = () => form?.validateFields();

    return (
        <div className={theme.formTab}>
            <Form.Item
                initialValue={isActiveAuthorizationSettings}
                name={SystemPropertiesFormItemNames.isActiveAuthorizationSettings}
                className={theme.formItem}
                label={intl.formatMessage(messages.isActiveAuthorizationSettings)}
                valuePropName="checked"
            >
                <Checkbox dataTest="activate-authorization-settings_check-box" />
            </Form.Item>
            <Form.Item
                name={SystemPropertiesFormItemNames.activeAuthorizationMethods}
                className={theme.formItem}
                label={intl.formatMessage(messages.activeAuthorizationMethods)}
                initialValue={activeAuthorizationMethods}
            >
                <Select data-test="list-of-authorization-methods" mode="multiple">
                    {Object.keys(AuthorizationMethod).map((method: AuthorizationMethod) => (
                        <Select.Option data-test={`method-authorization_${method}`} key={method} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name={SystemPropertiesFormItemNames.blockAuthTime}
                initialValue={blockAuthTime}
                className={theme.formItem}
                label={intl.formatMessage(messages.blockAuthTime)}
            >
                <InputNumber parser={(value) => Math.round(Number(value))} min={0} placeholder="60" />
            </Form.Item>
            <Form.Item
                name={SystemPropertiesFormItemNames.maxFailAuthAttempts}
                initialValue={maxFailAuthAttempts}
                className={theme.formItem}
                label={intl.formatMessage(messages.maxFailAuthAttempts)}
            >
                <InputNumber parser={(value) => Math.round(Number(value))} min={0} placeholder="3" />
            </Form.Item>
            <Form.Item
                name={SystemPropertiesFormItemNames.minPasswordLength}
                initialValue={minPasswordLength}
                className={theme.formItem}
                label={intl.formatMessage(messages.minPasswordLength)}
                normalize={normalizeNumberInput}
                rules={[
                    {
                        required: false,
                        message: intl.formatMessage(messages.minPasswordLengthValidation),
                        validator: minPasswordLengthValidator,
                    },
                ]}
            >
                <Input data-test="minimum-password-length_input" placeholder="6" />
            </Form.Item>
            <Form.Item
                initialValue={isActiveUpperAndLowerCase}
                name={SystemPropertiesFormItemNames.isActiveUpperAndLowerCase}
                className={theme.formItem}
                label={intl.formatMessage(messages.isActiveUpperAndLowerCase)}
                valuePropName="checked"
            >
                <Checkbox dataTest="upper-and-lowerCase_check-box" />
            </Form.Item>
            <Form.Item
                initialValue={isActiveNumbers}
                name={SystemPropertiesFormItemNames.isActiveNumbers}
                className={theme.formItem}
                label={intl.formatMessage(messages.isActiveNumbers)}
                valuePropName="checked"
            >
                <Checkbox dataTest="numbers-in-the-password_check-box" />
            </Form.Item>
            <Form.Item
                initialValue={isActiveSpecialCharacters}
                name={SystemPropertiesFormItemNames.isActiveSpecialCharacters}
                className={theme.formItem}
                label={intl.formatMessage(messages.isActiveSpecialCharacters)}
                valuePropName="checked"
            >
                <Checkbox dataTest="special-characters-password_check-box" />
            </Form.Item>
            <Form.Item
                name={SystemPropertiesFormItemNames.passwordMinDurationTime}
                initialValue={passwordMinDurationTime}
                className={theme.formItem}
                label={intl.formatMessage(messages.passwordMinDurationTime)}
                // todo: сейчас это поле может быть 0, это уберут на бэке и надо заменить на normalizeNumberInput (макс длина разная!)
                normalize={(value: string) => Number(convertToNumber(value.slice(0, 11)))}
                rules={[
                    {
                        required: false,
                        message: intl.formatMessage(messages.passwordDurationTimeValidation),
                        validator: () => passwordDurationTimeValidator(form),
                    },
                ]}
            >
                <Input onChange={onChangePasswordDurationTime} placeholder="3" />
            </Form.Item>
            <Form.Item
                name={SystemPropertiesFormItemNames.passwordMaxDurationTime}
                initialValue={passwordMaxDurationTime}
                className={theme.formItem}
                label={intl.formatMessage(messages.passwordMaxDurationTime)}
                // todo: сейчас это поле может быть 0, это уберут на бэке и надо заменить на normalizeNumberInput (макс длина разная!)
                normalize={(value: string) => Number(convertToNumber(value.slice(0, 11)))}
                rules={[
                    {
                        required: false,
                        message: intl.formatMessage(messages.passwordDurationTimeValidation),
                        validator: () => passwordDurationTimeValidator(form),
                    },
                ]}
            >
                <Input onChange={onChangePasswordDurationTime} placeholder="3" />
            </Form.Item>
            <Form.Item
                name={SystemPropertiesFormItemNames.countOfLastUsedPasswords}
                initialValue={countOfLastUsedPasswords}
                className={theme.formItem}
                label={intl.formatMessage(messages.countOfLastUsedPasswords)}
                normalize={normalizeNumberInput}
            >
                <Input onChange={onChangePasswordDurationTime} placeholder="3" />
            </Form.Item>
            <Form.Item
                initialValue={passwordChangePermission}
                name={SystemPropertiesFormItemNames.passwordChangePermission}
                className={theme.formItem}
                label={intl.formatMessage(messages.passwordChangePermission)}
                valuePropName="checked"
            >
                <Checkbox dataTest="password-change-permission_check-box" />
            </Form.Item>
        </div>
    );
};

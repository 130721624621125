import { v4 as uuid } from 'uuid';
import { TAppColor } from '../../reducers/app.reducer.types';
import { TRecentModels } from '../../models/recentModel.types';
import { Locale } from '../../modules/Header/components/Header/header.types';
import { TServerEntity } from '../../models/entities.types';
import { ApiBundle } from '../api/api-bundle';
import { TabsBusActions } from '../../actionsTypes/tabsBus.actionTypes';
import { DEFAULT_LOCALE } from '../../config/config';
import { InternationalString } from '@/serverapi/api';

export enum LocalStorageKeys {
    RECENT_DATA = 'user.recent.data',
    APP_COLOR = 'appColor',
    GREETING_TEXT = 'greetingText',
    GREETING_LOGO = 'greetingLogo',
    LOCALE = 'locale',
    SERVERS = 'user.data.servers',
    TABS_BUS_ACTION = 'TABS_BUS',
    COMMENTS_TAB_SIZE = 'commentsTabSize',
    IS_EN_LOCALE_ALLOWED = 'isEnLocaleAllowed',
    GRAY_SCALE = 'settingsDownloadingImage.grayscale',
    HIDE_DECOMPOSITION = 'settingsDownloadingImage.hideDecomposition',
}

export class LocalStorageDaoService {
    public static getRecentData() {
        return localStorage.getItem(LocalStorageKeys.RECENT_DATA);
    }

    public static setRecentData(data: TRecentModels) {
        localStorage.setItem(LocalStorageKeys.RECENT_DATA, JSON.stringify(data));
    }

    public static getAppColor(): TAppColor | null {
        const jsonColor: string | null = localStorage.getItem(LocalStorageKeys.APP_COLOR);
        if (!jsonColor) {
            return null;
        }

        const appColor: TAppColor | null = JSON.parse(jsonColor);

        return appColor;
    }

    public static setAppColor(appColor: TAppColor) {
        localStorage.setItem(LocalStorageKeys.APP_COLOR, JSON.stringify(appColor));
    }

    public static getGreetingText(): InternationalString | null {
        const jsonGreetingText: string | null = localStorage.getItem(LocalStorageKeys.GREETING_TEXT);
        if (!jsonGreetingText) {
            return null;
        }

        const greetingText: InternationalString | null = JSON.parse(jsonGreetingText);

        return greetingText;
    }

    public static setGreetingText(greetingText: InternationalString) {
        localStorage.setItem(LocalStorageKeys.GREETING_TEXT, JSON.stringify(greetingText));
    }
    public static setGreetingLogo(greetingLogo: string | undefined) {
        localStorage.setItem(LocalStorageKeys.GREETING_LOGO, JSON.stringify(greetingLogo || ''));
    }

    public static getGreetingLogo(): string | null {
        const jsonGreetingLogo: string | null = localStorage.getItem(LocalStorageKeys.GREETING_LOGO);
        if (!jsonGreetingLogo) {
            return null;
        }

        const greetingLogo: string = JSON.parse(jsonGreetingLogo);

        return greetingLogo;
    }

    public static getLocale() {
        const locale = localStorage.getItem(LocalStorageKeys.LOCALE);
        let res = DEFAULT_LOCALE;

        if (locale) {
            res = Locale[locale.toLocaleLowerCase()];
        }
        // Если локаль не установлена, делаем автоопределение языка, если он английский устанавливается английская локаль
        // Закомментировано в рамках задачи https://jira.silaunion.ru/browse/BPM-8248
        // else {
        //     const language = window.navigator?.language;
        //     if (language) res = Locale[language.substring(0, 2).toLowerCase()];
        // }

        return res;
    }

    public static setLocale(locale: Locale) {
        localStorage.setItem(LocalStorageKeys.LOCALE, Locale[locale].toUpperCase());
    }

    public static getServers() {
        return localStorage.getItem(LocalStorageKeys.SERVERS);
    }

    public static setServers(servers: TServerEntity[]) {
        const serverListForStoring = servers.map<TServerEntity>((server) => ({
            id: server.id,
            name: server.name,
            url: server.url,
            api: {} as ApiBundle,
            lastLoginTimestamp: server.lastLoginTimestamp ? server.lastLoginTimestamp : 0,
        }));
        localStorage.setItem(LocalStorageKeys.SERVERS, JSON.stringify(serverListForStoring));
    }

    public static getTabsBusAction() {
        return localStorage.getItem(LocalStorageKeys.TABS_BUS_ACTION);
    }

    public static getTabsBusActionType(): TabsBusActions | null {
        const tabBusActionJson: string | null = LocalStorageDaoService.getTabsBusAction();
        if (!tabBusActionJson) return null;

        const tabBusAction: null | { actionType: TabsBusActions; id: string; payload: string } =
            JSON.parse(tabBusActionJson);
        if (!tabBusAction) return null;

        return tabBusAction.actionType;
    }

    public static setTabsBusAction(actionType: TabsBusActions, payload: string = '') {
        localStorage.setItem(
            LocalStorageKeys.TABS_BUS_ACTION,
            JSON.stringify({
                actionType,
                payload,
                id: uuid(),
            }),
        );
    }

    public static getCommentsTabSize(): number | undefined {
        return Number(localStorage.getItem(LocalStorageKeys.COMMENTS_TAB_SIZE)) || undefined;
    }

    public static setCommentsTabSize(size: number): void {
        localStorage.setItem(LocalStorageKeys.COMMENTS_TAB_SIZE, JSON.stringify(size));
    }

    public static isGrayScaleActive() {
        return localStorage.getItem(LocalStorageKeys.GRAY_SCALE) === 'true';
    }

    public static setGrayScale(checked: boolean) {
        localStorage.setItem(LocalStorageKeys.GRAY_SCALE, String(checked));
    }

    public static isHiddenDecompositionActive() {
        return localStorage.getItem(LocalStorageKeys.HIDE_DECOMPOSITION) === 'true';
    }

    public static setHideDecomposition(checked: boolean) {
        localStorage.setItem(LocalStorageKeys.HIDE_DECOMPOSITION, String(checked));
    }
}

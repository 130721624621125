export enum RawDraftBlockType {
    UNSTYLED = 'unstyled',
    HEADER_ONE = 'header-one',
    HEADER_TWO = 'header-two',
    HEADER_THREE = 'header-three',
    HEADER_FOUR = 'header-four',
    HEADER_FIVE = 'header-five',
    HEADER_SIX = 'header-six',
    CHECKABLE = 'checkable-list-item',
    UNORDERED = 'unordered-list-item',
    ORDERED = 'ordered-list-item',
    ATOMIC = 'atomic',
    TABLE = 'TABLE',
}

export enum RawDraftStyleType {
    BOLD = 'BOLD',
    CODE = 'CODE',
    UNDERLINE = 'UNDERLINE',
    ITALIC = 'ITALIC',
    STRIKETHROUGH = 'STRIKETHROUGH',
    SUBSCRIPT = 'SUBSCRIPT',
    SUPERSCRIPT = 'SUPERSCRIPT',
    bgcolor = 'bgcolor',
    color = 'color',
    fontsize = 'fontsize',
    fontfamily = 'fontfamily',
}

export enum RawDraftEntityType {
    LINK_CUSTOM = 'LINK_CUSTOM',
    COMMENT = 'COMMENT',
}

export type TInlineStyleRange = {
    offset: number;
    length: number;
    style: string;
};

export type TEntityRange = {
    offset: number;
    length: number;
    key: number;
};

export type TRawDraftTableCellShape = {
    element: 'th' | 'td';
    style: Record<string, string>;
    rowspan?: number;
    colspan?: number;
};

export type TRawDraftContentBlock = {
    key: string;
    text: string;
    type: RawDraftBlockType;
    depth: number;
    inlineStyleRanges: TInlineStyleRange[];
    entityRanges: TEntityRange[];
    comments: {};
    data: {
        tableKey?: string;
        tableShape?: TRawDraftTableCellShape[][];
        tablePosition?: string;
        checked?: boolean;
        type?: RawDraftBlockType;
    };
};

export type TRawDraftEntity = {
    type: string;
    mutability: string;
    data: Record<string, string>;
};

export type TRawDraftEntityMap = { [key: string]: TRawDraftEntity };

export type TRawDraftContentState = { blocks: TRawDraftContentBlock[]; entityMap: TRawDraftEntityMap };


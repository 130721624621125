import React, { Component } from 'react';
import { FormInstance, Col, Row } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import messages from '../messages/userManagment.messages';
import formMessages from '../../../../models/formDefault.messages';
import accessTypesMessages from '../../../../models/userAccesses.messages';
import { UserDTO, GroupDTO, UserDTOAccessesEnum, UserDTOLicensesEnum } from '../../../../serverapi/api';
import { TWorkspaceTab } from '../../../../models/tab.types';
import UserPermisionDataBlock from './UserPermisionDataBlock';
import accessTypes from '../../../../models/userAccessRightTypes';
import licenseTypes from '../../../../models/licenseTypes';
import licenseTypesMsg from '../../../../models/licenseTypes.messages';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import UserMainDataEditingComponent from './UserMainDataEditing.component';
import theme from './UserDataEditing.scss';
import { TUserDataEditingActionProps } from './UserDataEditing.types';
import allowIcon from '../../../../resources/icons/ic-checkbox-allow-color.svg';
import denyIcon from '../../../../resources/icons/ic-checkbox-deny-color.svg';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { TButtonProps } from '@/modules/UIKit/components/Button/Button.types';
import { FooterButtons } from '../../../Footer/FooterButtons.component';

type TUserDataEditingProps = {
    userData: UserDTO;
    tab: TWorkspaceTab;
    licenses: UserDTOLicensesEnum[];
    accesses: UserDTOAccessesEnum[];
    getAccessibleRepositories: () => Promise<string[]>;
} & TUserDataEditingActionProps &
    WrappedComponentProps;

type TUserDataEditingState = {
    editingUserData: UserDTO;
    isNewUser: boolean;
    isTechnical: boolean | undefined;
    accessibleRepos: string[];
};

type TFormValues = {
    login: string;
    password: string;
    lastName: string;
    firstName: string;
    middleName: string;
    department: string;
    email: string;
    position: string;
    company: string;
    description: string;
    technical: boolean | undefined;
    groups: GroupDTO[] | undefined;
    blocked: string;
};

class UserDataEditing extends Component<TUserDataEditingProps> {
    state: TUserDataEditingState = {
        editingUserData: this.props.userData || ({} as UserDTO),
        isNewUser: !this.props.userData || !this.props.userData.id || !this.props.userData.login,
        isTechnical: this.props.userData.technical,
        accessibleRepos: [],
    };

    formRef = React.createRef<FormInstance>();

    async componentDidMount() {
        const { userData, fetchUserFullData, getAccessibleRepositories } = this.props;
        if (userData.id) {
            fetchUserFullData(userData.id);
        }
        const accessibleRepos = await getAccessibleRepositories();
        this.setState({ accessibleRepos });
    }

    validateFields = (callBack: Function) => {
        const { userData } = this.props;
        const form = this.formRef.current;
        if (form) {
            form.validateFields()
                .then((formValues: TFormValues) => {
                    this.setState(
                        {
                            editingUserData: {
                                ...userData,
                                login: formValues.login.trim(),
                                password: formValues.password?.trim(),
                                lastName: formValues.lastName?.trim(),
                                firstName: formValues.firstName?.trim(),
                                middleName: formValues.middleName?.trim(),
                                department: formValues.department?.trim(),
                                email: formValues.email?.trim(),
                                position: formValues.position?.trim(),
                                company: formValues.company?.trim(),
                                description: formValues.description?.trim(),
                                technical: this.state.isTechnical,
                                groups: userData.groups || Array<GroupDTO>(),
                                blocked: formValues.blocked === 'true',
                            },
                        },
                        () => callBack(this.state.editingUserData, this.state.isNewUser),
                    );
                })
                .catch((error) => console.error(error));
        }
    };

    handleSubmit = () => {
        this.validateFields(this.props.onSubmit);
    };

    handleChangeUserRight = (dialogTypeId: string) => {
        const userId = this.props.userData.id;
        this.props.onUserPermissionsEdit({
            userId: userId,
            dialogTypeId,
        });
    };

    prepareGroupData = (srcData?: GroupDTO[]) => srcData && srcData.map((g) => g.groupName);

    prepareAcceses = (srcData?: UserDTOAccessesEnum[]) => {
        return (
            srcData &&
            srcData.map((ac: UserDTOAccessesEnum) => {
                const accessType: string = accessTypes[ac];

                if (!accessTypesMessages[accessType]) return ac;

                return this.props.intl.formatMessage(accessTypesMessages[accessType]);
            })
        );
    };

    prepareLicenses = (srcData?: UserDTOLicensesEnum[], userData?: UserDTO) => {
        const groupLicenses = userData
            ? userData.groups?.reduce((acc, group: GroupDTO) => {
                  if (group.licenses) {
                      const licenseGroupsMap = group.licenses.reduce(
                          (groupMap, license: UserDTOLicensesEnum) => ({
                              ...groupMap,
                              [license]: `${groupMap[license] || ''}${group?.groupName}, `,
                          }),
                          acc,
                      );

                      return licenseGroupsMap;
                  }

                  return acc;
              }, {} as Record<UserDTOLicensesEnum, string>)
            : ({} as Record<UserDTOLicensesEnum, string>);

        return (
            srcData &&
            srcData.map((ac) => {
                const groupsStr =
                    groupLicenses && groupLicenses[licenseTypes[ac]]
                        ? `(${groupLicenses[licenseTypes[ac]].slice(0, -2)})`
                        : '';

                return `${this.props.intl.formatMessage(licenseTypesMsg[licenseTypes[ac]])} ${groupsStr}`;
            })
        );
    };

    onChangeIsTechnical = (val: boolean) => {
        this.setState({ isTechnical: val });
    };

    render() {
        const { userData, intl, tab, accesses, licenses, onShowSessions } = this.props;

        const buttons: TButtonProps[] = [
            {
                children: intl.formatMessage(formMessages.cancelButton),
                size: 'large',
                dataTest: 'user-data-editing_cancel-button',
                key: 'cancel',
                onClick: () => this.props.onClose(tab),
            },
            {
                children: intl.formatMessage(formMessages.saveButtonLabel),
                size: 'large',
                visualStyle: 'primary',
                dataTest: 'user-data-editing_save-button',
                key: 'ok',
                onClick: this.handleSubmit,
            },
        ];

        return (
            <div className={theme.tabContent}>
                <div className={theme.content}>
                    <div className={theme.leftBlock}>
                        <UserMainDataEditingComponent
                            userData={userData}
                            isNewUser={this.state.isNewUser}
                            formRef={this.formRef}
                            onShowSessions={onShowSessions}
                            onChangeIsTechnical={this.onChangeIsTechnical}
                            isTechnical={this.state.isTechnical}
                        />
                    </div>
                    <div className={theme.rightBlock}>
                        {this.props.userData.id ? (
                            <Row>
                                <Col span={21} style={{ marginBottom: '15px' }}>
                                    <h3 className={theme.label}>{intl.formatMessage(messages.confirmedAgreements)}</h3>
                                    <div className={theme.licensWrapper}>
                                        {intl.formatMessage(messages.agreement)}
                                        {userData?.agreementConfirmed ? (
                                            <Icon spriteSymbol={allowIcon} />
                                        ) : (
                                            <Icon spriteSymbol={denyIcon} />
                                        )}
                                    </div>
                                    <div className={theme.licensWrapper}>
                                        {intl.formatMessage(messages.silaAgreement)}
                                        {userData.silaAgreementConfirmed ? (
                                            <Icon spriteSymbol={allowIcon} />
                                        ) : (
                                            <Icon spriteSymbol={denyIcon} />
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                        <UserPermisionDataBlock
                            id={DialogType.EDIT_ASSIGN_USER_LICENSE}
                            onEdit={this.handleChangeUserRight}
                            data={this.prepareLicenses(licenses, userData)}
                            label={intl.formatMessage(messages.assignedLicenses)}
                        />
                        <UserPermisionDataBlock
                            id={DialogType.EDIT_USER_FUNCTIONAL_PERMISSIONS}
                            onEdit={this.handleChangeUserRight}
                            data={this.prepareAcceses(accesses)}
                            label={intl.formatMessage(messages.accessesRights)}
                        />
                        <UserPermisionDataBlock
                            id={DialogType.EDIT_ASSIGN_USER_GROUPS}
                            onEdit={this.handleChangeUserRight}
                            data={this.prepareGroupData(userData.groups)}
                            label={intl.formatMessage(messages.inGroups)}
                        />
                        <UserPermisionDataBlock
                            id={DialogType.EDIT_USER_DBACCESS_PERMISSIONS}
                            onEdit={this.handleChangeUserRight}
                            data={this.state.accessibleRepos}
                            label={intl.formatMessage(messages.dbAccess)}
                            editable={false}
                        />
                    </div>
                </div>
                <FooterButtons buttons={buttons} />
            </div>
        );
    }
}

const withIntl = injectIntl(UserDataEditing);

export default withIntl;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeId } from '../../../../../../serverapi/api';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { workspaceTabSetAction } from '../../../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';
import iconUndo from '@/resources/icons/ic-app-undo.svg';
import { undoAction } from '../../../../../../actions/undoRedo.actions';
import { UndoRedoSelectors } from '../../../../../../selectors/undoRedo.selectors';
import { useIntl } from 'react-intl';
import messages from '@/modules/MainMenu/messages/CommonToolbar.messages';

export const UndoButton = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const activeTabNodeId: NodeId | undefined = useSelector(TabsSelectors.getActiveTabId);
    const { undo: enabled } = useSelector(UndoRedoSelectors.getUndoRedoStatus(activeTabNodeId));

    const onUndoAction = (activeTabNodeId: NodeId) => {
        dispatch(undoAction(activeTabNodeId));
        dispatch(
            workspaceTabSetAction({
                nodeId: activeTabNodeId,
                name: 'undo',
                value: true,
            }),
        );
    };
    const onUndo = useCallback(() => {
        if (activeTabNodeId) onUndoAction(activeTabNodeId);
    }, [activeTabNodeId, onUndoAction]);

    return (
        <ToolbarButton
            disabled={!enabled}
            icon={iconUndo}
            size="small"
            onClick={onUndo}
            dataTest="header_action-buttons-undo"
            tooltip={enabled ? intl.formatMessage(messages.undo) : ''}
        />
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    sourceCol: {
        id: 'WidgetSourceInput.sourceCol',
        defaultMessage: 'Столбец данных',
    },
    operation: {
        id: 'WidgetSourceInput.operation',
        defaultMessage: 'Операция',
    },
    legend: {
        id: 'WidgetSourceInput.legend',
        defaultMessage: 'Легенда',
    },
    Count: {
        id: 'WidgetSourceInput.Count',
        defaultMessage: 'Количество строк',
    },
    Sum: {
        id: 'WidgetSourceInput.Sum',
        defaultMessage: 'Сумма значений',
    },
    AvgSum: {
        id: 'WidgetSourceInput.AvgSum',
        defaultMessage: 'Среднее значение',
    },
    Avg: {
        id: 'WidgetSourceInput.Avg',
        defaultMessage: 'Среднее значение',
    },
});

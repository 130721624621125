// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventDialogs__guidInput___oiLq{margin-bottom:12px;font-family:\"Segoe UI\";font-size:14px;color:var(--Neutral800);line-height:32px}.EventDialogs__eventRow__gsDys{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex}.EventDialogs__eventColumn__g2_qB{margin:0 24px 12px 0;font-family:\"Segoe UI\";font-size:14px;color:var(--Neutral800);line-height:32px}.EventDialogs__checkboxWrapper__kWtGS{display:-webkit-box;display:-ms-flexbox;display:flex}.EventDialogs__checkboxLabelText__HL8fh{text-wrap:nowrap}.EventDialogs__inputNumber___IIiO{width:106px}", "",{"version":3,"sources":["webpack://./src/modules/SimulationModeling/components/SimulationModeling/SettingsSidebar/Dialog/EventDialogs.scss"],"names":[],"mappings":"AACA,gCACI,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,uBAAA,CACA,gBAAA,CAGJ,+BACI,0BAAA,CAAA,0BAAA,CAAA,mBAAA,CAGJ,kCACI,oBAAA,CACA,sBAAA,CACA,cAAA,CACA,uBAAA,CACA,gBAAA,CAGJ,sCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGJ,wCACI,gBAAA,CAGJ,kCACI,WAAA","sourcesContent":["\r\n.guidInput {\r\n    margin-bottom: 12px;\r\n    font-family: 'Segoe UI';\r\n    font-size: 14px;\r\n    color: var(--Neutral800);\r\n    line-height: 32px;\r\n}\r\n\r\n.eventRow {\r\n    display: inline-flex;\r\n}\r\n\r\n.eventColumn {\r\n    margin: 0 24px 12px 0;\r\n    font-family: 'Segoe UI';\r\n    font-size: 14px;\r\n    color: var(--Neutral800);\r\n    line-height: 32px;\r\n}\r\n\r\n.checkboxWrapper {\r\n    display: flex;\r\n}\r\n\r\n.checkboxLabelText {\r\n    text-wrap: nowrap;\r\n}\r\n\r\n.inputNumber {\r\n    width: 106px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guidInput": "EventDialogs__guidInput___oiLq",
	"eventRow": "EventDialogs__eventRow__gsDys",
	"eventColumn": "EventDialogs__eventColumn__g2_qB",
	"checkboxWrapper": "EventDialogs__checkboxWrapper__kWtGS",
	"checkboxLabelText": "EventDialogs__checkboxLabelText__HL8fh",
	"inputNumber": "EventDialogs__inputNumber___IIiO"
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import messages from './GetImage.messages';
import theme from './GetImage.scss';
import { MatrixImageFormat } from '@/modules/Matrix/actions/matrixEditor.actions.types';
import icGetImage from '@/resources/icons/toolbar/donwloadModel.svg';
import { useDispatch, useSelector } from 'react-redux';
import { MatrixEditorSelectors } from '@/modules/Matrix/selectors/matrixEditor.selectors';
import { TButtonAction } from '@/modules/AdminTools/DropdownButton/DropdownButton.types';
import { matrixGetImage } from '@/modules/Matrix/actions/matrixEditor.actions';
import { NodeId } from '@/serverapi/api';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { DropdownButton } from '@/modules/AdminTools/DropdownButton/DropdownButton';

export const GetImage: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const activeTabNodeId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const tooltip: string = useSelector(MatrixEditorSelectors.getGetImageTooltip);

    const onSelect = (format: MatrixImageFormat) => {
        dispatch(matrixGetImage(activeTabNodeId, format));
    };

    const actions: TButtonAction[] = [
        {
            title: intl.formatMessage(messages.PNG),
            onClick: () => onSelect(MatrixImageFormat.PNG),
        },
        {
            title: intl.formatMessage(messages.SVG),
            onClick: () => onSelect(MatrixImageFormat.SVG),
        },
        {
            title: intl.formatMessage(messages.JPG),
            onClick: () => onSelect(MatrixImageFormat.JPG),
        },
        {
            title: intl.formatMessage(messages.PDF),
            onClick: () => onSelect(MatrixImageFormat.PDF),
        },
        {
            title: intl.formatMessage(messages.inBuffer),
            onClick: () => onSelect(MatrixImageFormat.BUFFER),
        },
    ];

    return (
        <DropdownButton
            spriteSymbol={icGetImage}
            actions={actions}
            dropdownAlignment="left"
            tooltip={tooltip}
            size="small"
            className={theme.container}
        />
    );
};

import { put, takeEvery, select } from 'redux-saga/effects';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { workspaceAddTab } from '../actions/tabs.actions';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTab, IWorkspaceTabServerSettingsParams } from '../models/tab.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TServerEntity } from '../models/entities.types';
import { ServerProfile, SystemProperties } from '../serverapi/api';
import { OPEN_SYSTEM_PROPERTIES_TAB, SAVE_SYSTEM_PROPERTIES } from '../actionsTypes/systemProperties.actionTypes';
import { TOpenSystemPropertiesTabAction, TSaveSystemPropertiesAction } from '../actions/systemProperties.actions.types';
import { showNotification } from '../actions/notification.actions';
import { v4 as uuid } from 'uuid';
import { NotificationType } from '../models/notificationType';
import { appLoadViewColorScheme, appLoadViewMainColor, setLocaleAllowed } from '../actions/app.actions';
import { SystemPropertiesDaoService } from '../services/dao/SystemPropertiesDaoService';
import { serverProfileSetState } from '../../src/actions/serverProfile.actions';
import { fetchSystemPropertiesState } from '../../src/actions/systemProperties.actions';
import { ServerProfilesDaoService } from '../services/dao/ServerProfilesDaoService';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';

function* openSystemProperties({ payload }: TOpenSystemPropertiesTabAction) {
    const { serverNode } = payload;
    const serverId: string = yield select(ServerSelectors.serverId);

    if (serverNode) {
        const systemProperties: SystemProperties = yield SystemPropertiesDaoService.getAllSystemProperties();
        const serverProfiles: Array<ServerProfile> = yield ServerProfilesDaoService.getAll();
        yield put(serverProfileSetState(serverProfiles, serverId));
        yield put(fetchSystemPropertiesState(systemProperties));
        const methodologySettingTab: TWorkspaceTab = {
            title: serverNode.name,
            nodeId: serverNode.nodeId,
            type: WorkSpaceTabTypes.SERVER_SETTINGS,
            mode: EditorMode.Read,
            params: {} as IWorkspaceTabServerSettingsParams,
            actions: defaultWorkspaceTabActions,
        };

        yield put(workspaceAddTab(methodologySettingTab));
    }
}

function* saveSystemProperties({ payload }: TSaveSystemPropertiesAction) {
    const { properties } = payload;
    const serverId: string = yield select(ServerSelectors.serverId);
    const { mainColor, secondColor, selectedRowColor, appColorScheme, isEnLocaleAllowed } = properties;

    if (mainColor && secondColor && selectedRowColor) {
        LocalStorageDaoService.setAppColor({ mainColor, secondColor, selectedRowColor });
        yield put(appLoadViewMainColor({ mainColor, secondColor, selectedRowColor }));
    }
    if (appColorScheme) {
        yield put(appLoadViewColorScheme(appColorScheme));
    }
    if (isEnLocaleAllowed !== undefined) {
        yield put(setLocaleAllowed(isEnLocaleAllowed));
    }

    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    if (server) {
        yield SystemPropertiesDaoService.saveSystemProperties(properties, serverId);
    }

    yield put(
        showNotification({
            id: uuid(),
            type: NotificationType.SUCCESS_SAVING_SYSTEM_PROPERTY,
        }),
    );
}

export function* systemPropertiesSaga() {
    yield takeEvery(OPEN_SYSTEM_PROPERTIES_TAB, openSystemProperties);
    yield takeEvery(SAVE_SYSTEM_PROPERTIES, saveSystemProperties);
}

import { TReducer } from '../utils/types';
import { WikiType } from '../serverapi/api';
import { IWikiModelTypeData, TWikiModelTypeState } from './wikiModelType.reducer.types';
import { WIKI_MODEL_TYPE_REQUEST_SUCCESS } from '../actionsTypes/wikiModelType.actionsTypes';

const initial: TWikiModelTypeState = {};

export const INITIAL_WIKI_MODEL_TYPE_SERVER_STATE: IWikiModelTypeData = {
    byId: {},
};

export const wikiModelTypeReducer: TReducer<TWikiModelTypeState> = (state = initial, action) => {
    switch (action.type) {
        case WIKI_MODEL_TYPE_REQUEST_SUCCESS: {
            const {
                payload: { wikiModelTypes, presetId },
            } = action;
            const wikiModelTypesMap = wikiModelTypes.reduce(
                (result: {}, item: WikiType) => ({ ...result, [item.id]: item }),
                {},
            );

            return {
                ...state,
                [presetId]: {
                    byId: {
                        ...wikiModelTypesMap,
                    },
                },
            };
        }

        default:
            return state;
    }
};

import React, { Component } from 'react';
import { Form, FormInstance } from 'antd';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import theme from './DecompositionDialog.scss';
import messages from './messages/DecompositionDialog.messages';
import { ModelFormContainer } from '../../../ModelDialog/containers/ModelForm.container';
import { ModelType, NodeId, ModelAssignmentNodeTypeEnum } from '../../../../serverapi/api';
import { TreeNode } from '../../../../models/tree.types';
import { TDecompositionDialogProps } from './DecompositionDialog.types';
import { allowedDecompositionNodeType } from '../../../../services/consts/TreeConsts';
import { Tree } from '../../../Tree/components/Tree/Tree.component';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';

type TDecompositionDialogState = {
    modelState: TTypeSelectorState;
    existingModel?: TreeNode;
    modelType?: ModelType;
    parentNodeId?: NodeId;
    isSubmitEnabled: boolean;
    template?: TreeNode;
    errors: {
        modelType: boolean;
        existingModel: boolean;
    };
};

enum TTypeSelectorState {
    NEW,
    EXISTING,
}

type TFormValues = {
    modelName: string;
};

class DecompositionDialog extends Component<TDecompositionDialogProps> {
    formRef = React.createRef<FormInstance>();
    state: TDecompositionDialogState = {
        modelState: TTypeSelectorState.NEW,
        modelType: undefined,
        template: undefined,
        parentNodeId: {
            repositoryId: this.props.repositoryId,
            id: this.props.repositoryId,
            serverId: this.props.serverId,
        },
        isSubmitEnabled: true,
        errors: {
            modelType: false,
            existingModel: false,
        },
    };

    handleSubmit = () => {
        const {
            onSubmit,
            objectDefinitionId,
            edgeDefinitionId,
            serverId,
            activeGraphId,
            setStateAssignments,
            stateAssignments,
        } = this.props;
        const { modelState, modelType, parentNodeId } = this.state;
        const { existingModel } = this.props;
        const isNewModel = modelState === TTypeSelectorState.NEW;
        const isExistingModel = modelState === TTypeSelectorState.EXISTING;

        const form = this.formRef.current;
        if (form) {
            form.validateFields().then((formValues: TFormValues) => {
                const alwaysData = {
                    isNewModel,
                    serverId,
                    objectDefinitionId,
                    edgeDefinitionId,
                    setStateAssignments,
                    stateAssignments,
                    oldGraphId: activeGraphId,
                };
                if (isNewModel) {
                    if (!modelType) {
                        this.setState({
                            errors: {
                                modelType: true,
                            },
                        });
                    } else {
                        onSubmit({
                            ...alwaysData,
                            modelName: formValues.modelName,
                            modelTypeId: modelType!.id,
                            parentNodeId: parentNodeId!,
                            templateId: this.state.template?.nodeId.id,
                        });
                        this.setState({ isSubmitEnabled: false });
                    }
                } else if (isExistingModel) {
                    if (!existingModel) {
                        this.setState({
                            errors: {
                                existingModel: true,
                            },
                        });
                    } else if (allowedDecompositionNodeType(existingModel.type)) {
                        onSubmit({
                            ...alwaysData,
                            modelName: existingModel.name,
                            modelTypeId: existingModel.modelTypeId!,
                            parentNodeId: existingModel.parentNodeId!,
                            modelNodeId: existingModel.nodeId,
                            nodeType: existingModel.type! as ModelAssignmentNodeTypeEnum,
                        });
                        this.setState({ isSubmitEnabled: false });
                    } else {
                        this.setState({
                            errors: {
                                existingModel: true,
                            },
                        });
                    }
                }
            });
        }
    };

    onParentNodeIdChange = (parentNode: TreeNode): void => {
        this.setState({ parentNodeId: parentNode.nodeId });
        this.props.onTreeItemSelect(parentNode);
    };

    handleRadioChange = (value: TTypeSelectorState) => {
        this.setState({
            modelState: value,
        });
        this.props.onTreeItemSelect(undefined);
    };

    onSelectType = (modelType: ModelType, template?: TreeNode) => {
        this.setState((state: TDecompositionDialogState) => ({
            modelType: state.modelType === modelType && state.template === template ? undefined : modelType,
            template,
            errors: {
                modelType: false,
            },
        }));
    };

    render() {
        const { intl, open, objectName, treeData, storeName, onCancel, serverId } = this.props;

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: onCancel,
                        value: intl.formatMessage(messages.decompositionDialogCancelButton),
                        dataTest: 'cancel-creation-decomposition_button',
                    },
                    {
                        key: 'ok',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.decompositionDialogSaveButton),
                        visualStyle: 'primary',
                        disabled: !this.state.isSubmitEnabled || !this.props.isModelEditor,
                        dataTest: 'create-decomposition_button',
                        tooltip: this.props.isModelEditor ? '' : intl.formatMessage(messages.needLicense),
                    },
                ]}
            />
        );

        const radioOptions: TRadioOption<TTypeSelectorState>[] = [
            {
                title: intl.formatMessage(messages.decompositionDialogRadioNew),
                value: TTypeSelectorState.NEW,
                dataTest: 'decomposition-dialog_radio_new-model',
            },
            {
                title: intl.formatMessage(messages.decompositionDialogRadioExisting),
                value: TTypeSelectorState.EXISTING,
                dataTest: 'decomposition-dialog_radio_existing-model',
            },
        ];

        return (
            <Dialog
                className={theme.dialog}
                open={open}
                onCancel={onCancel}
                title={intl.formatMessage(messages.decompositionDialogTitle)}
                onOk={this.handleSubmit}
                footer={footer}
            >
                <RadioGroup<TTypeSelectorState>
                    value={this.state.modelState}
                    onChange={this.handleRadioChange}
                    options={radioOptions}
                    margin={8}
                    direction="Row"
                />
                <div className={theme.content}>
                    {this.state.modelState === TTypeSelectorState.NEW && (
                        <ModelFormContainer
                            serverId={serverId}
                            parentNodeId={this.state.parentNodeId}
                            availableModelTypes={this.props.validDecompositionModelTypesIds}
                            allowAnyModelType={this.props.allowAnyDecomposition}
                            selectedTemplate={this.state.template}
                            onParentNodeIdChange={this.onParentNodeIdChange}
                            onSelectType={this.onSelectType}
                            defaultModelName={intl.formatMessage(messages.decompositionDialogNewModelName, {
                                objectName: objectName || intl.formatMessage(messages.edgeText),
                            })}
                            formRef={this.formRef}
                            modelType={this.state.modelType}
                            errors={this.state.errors}
                            openedSelectNode
                        />
                    )}
                    {this.state.modelState === TTypeSelectorState.EXISTING && (
                        <Form autoComplete='off' ref={this.formRef} layout="vertical" className={theme.tree}>
                            <Form.Item
                                data-test="decomposition-dialog_existing-model_tree"
                                required
                                name="existingModel"
                                label={intl.formatMessage(messages.decompositionDialogExistingModelLabel)}
                            >
                                <>
                                    {this.state.errors.existingModel && (
                                        <div className="ant-form-item-explain ant-form-item-explain-error">
                                            <div role="alert">{intl.formatMessage(messages.requiredExistingModel)}</div>
                                        </div>
                                    )}
                                    <Tree data={treeData} treeName={storeName} />
                                </>
                            </Form.Item>
                        </Form>
                    )}
                </div>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(DecompositionDialog);

export { IntlComponent as DecompositionDialog };

import type { WikiContentBlock } from '@/serverapi/api';
import type {
    TRawDraftContentBlock,
    TRawDraftContentState,
    TRawDraftEntityMap,
    TRawDraftTableCellShape,
} from './draftJsToTipTapWiki.types';
import { isNumber } from 'lodash-es';
import {
    WIKI_IMAGE_DEFAULT_HEIGHT,
    WIKI_IMAGE_DEFAULT_WIDTH,
} from '@/modules/UIKit/components/TipTapTextEditor/Editor/Editor.constants';
import {
    DEFAULT_INDENT,
    DEFAULT_LINE_HEIGHT_OPTION,
    DEFAULT_TEXT_ALIGN,
} from '@/modules/UIKit/components/TipTapTextEditor/Toolbar/controls/Controls.constants';
import { fontFamilyOptions, fontSizeOptions, lineHeightOptions } from '@/utils/configuration';
import { RawDraftStyleType, RawDraftEntityType, RawDraftBlockType } from './draftJsToTipTapWiki.types';

enum MarkType {
    TEXT_STYLE = 'textStyle',
    BOLD = 'bold',
    CODE = 'code',
    UNDERLINE = 'underline',
    ITALIC = 'italic',
    STRIKE = 'strike',
    SUBSCRIPT = 'subscript',
    SUPERSCRIPT = 'superscript',
    LINK = 'link',
    COMMENT = 'comment',
}

enum TextStyleType {
    BACKGROUND_COLOR = 'backgroundColor',
    COLOR = 'color',
    FONT_SIZE = 'fontSize',
    FONT_FAMILY = 'fontFamily',
}

type TStyledCharElement = { type: 'text'; text: string; styles: string[]; entities: string[] };

type TMapper = (
    block: TRawDraftContentBlock | TRawDraftContentBlock[],
    entityMap: TRawDraftEntityMap,
    mapperProps?: { imageMapper: (str: string) => string },
) => WikiContentBlock;

type THardBreakBlock = { type: 'hardBreak' };

type TMarkObject = { type: MarkType; attrs?: Record<string, string | null> };

type TParagraphBlockAttrs = {
    lineHeight: string;
    indent: number;
    textAlign: string;
};

type TTextBlock = {
    type: 'text';
    marks?: TMarkObject[];
    text: string;
};

type TParagraphBlockContent = TTextBlock | THardBreakBlock;

type TParagraphBlock = {
    type: 'paragraph';
    attrs: TParagraphBlockAttrs;
    content: TParagraphBlockContent[];
};

type THeadingBlock = {
    type: 'heading';
    attrs: TParagraphBlockAttrs & { level: number };
    content: TParagraphBlockContent[];
};

type TListItemBlockContent = TParagraphBlock | TBulletListBlock | TOrderedListBlock;

type TListItemBlock = {
    type: 'listItem';
    content: TListItemBlockContent[];
};

type TBulletListBlock = {
    type: 'bulletList';
    content: TListItemBlock[];
};

type TOrderedListBlock = {
    type: 'orderedList';
    attrs: {
        start: number;
    };
    content: TListItemBlock[];
};

type TTableRowAttrs = {
    rowspan?: number;
    colspan?: number;
    colwidth?: number[] | null;
    rowheight?: number[] | null;
};

type TTableRowContent = {
    type: string;
    attrs: TTableRowAttrs;
    content: TParagraphBlock[];
};

type TTableRow = { type: 'tableRow'; content: TTableRowContent[] };

type TTableBlock = {
    type: 'table';
    content: TTableRow[];
};

const LINE_HEIGHT = 'lineHeight';

const INDENT = 'indent';

const TEXT_ALIGN = 'textAlign';

const PARAGRAPH_DEFAULT_ATTRS = {
    [LINE_HEIGHT]: DEFAULT_LINE_HEIGHT_OPTION.value,
    [INDENT]: DEFAULT_INDENT,
    [TEXT_ALIGN]: DEFAULT_TEXT_ALIGN,
};

const DEFAULT_TEXT_STYLE_ATTRS = {
    [TextStyleType.BACKGROUND_COLOR]: '',
    [TextStyleType.COLOR]: '',
    [TextStyleType.FONT_FAMILY]: '',
    [TextStyleType.FONT_SIZE]: '',
};

const textAlignOptions = ['left', 'center', 'right', 'justify'];

const keyMap = {
    'line-height': LINE_HEIGHT,
    'text-align': TEXT_ALIGN,
    'text-indent': INDENT,
};

const valueMapper = {
    'line-height': (val: string) => lineHeightOptions.find((el) => el.value === val)?.value || null,
    'text-align': (val: string) => textAlignOptions.find((el) => el === val) || null,
    'text-indent': (val: string) => (typeof val === 'number' && val >= 0 ? val : null),
};

const styleTypeMap = {
    [RawDraftStyleType.BOLD]: MarkType.BOLD,
    [RawDraftStyleType.CODE]: MarkType.CODE,
    [RawDraftStyleType.UNDERLINE]: MarkType.UNDERLINE,
    [RawDraftStyleType.ITALIC]: MarkType.ITALIC,
    [RawDraftStyleType.STRIKETHROUGH]: MarkType.STRIKE,
    [RawDraftStyleType.SUBSCRIPT]: MarkType.SUBSCRIPT,
    [RawDraftStyleType.SUPERSCRIPT]: MarkType.SUPERSCRIPT,
    [RawDraftStyleType.bgcolor]: TextStyleType.BACKGROUND_COLOR,
    [RawDraftStyleType.color]: TextStyleType.COLOR,
    [RawDraftStyleType.fontfamily]: TextStyleType.FONT_FAMILY,
    [RawDraftStyleType.fontsize]: TextStyleType.FONT_SIZE,
    [RawDraftEntityType.LINK_CUSTOM]: MarkType.LINK,
    [RawDraftEntityType.COMMENT]: MarkType.COMMENT,
};

const mapUnstyledBlockData = (data) => {
    if (!data) {
        return PARAGRAPH_DEFAULT_ATTRS;
    }

    const result = {};
    const keys = Object.keys(data);

    keys.forEach((key) => {
        const newKey = keyMap[key];
        const newValue = valueMapper[key]?.(data[key]);

        if (newValue) {
            result[newKey] = newValue;
        }
    });

    return { ...PARAGRAPH_DEFAULT_ATTRS, ...result };
};

const isInRage = (position: number, { offset, length }: { offset: number; length: number }) => {
    return position >= offset && offset + length > position;
};

const getMappedElement = (element: TStyledCharElement, text: string, entityMap: TRawDraftEntityMap): TTextBlock => {
    const styles = [...element.styles.map((st) => ({ type: st })), ...element.entities.map((en) => ({ type: en }))];
    let marks: TMarkObject[] = [];
    const textStyleMarks: Record<string, string>[] = [];

    styles.forEach((style) => {
        const [type, value] = style.type.split('-');

        switch (type) {
            case RawDraftStyleType.BOLD:
            case RawDraftStyleType.CODE:
            case RawDraftStyleType.ITALIC:
            case RawDraftStyleType.UNDERLINE:
            case RawDraftStyleType.STRIKETHROUGH:
                marks.push({ type: styleTypeMap[type] });

                break;
            case RawDraftStyleType.SUPERSCRIPT:
                marks = marks.filter(({ type }) => type !== MarkType.SUBSCRIPT);
                marks.push({ type: styleTypeMap[type] });

                break;
            case RawDraftStyleType.SUBSCRIPT:
                marks = marks.filter(({ type }) => type !== MarkType.SUPERSCRIPT);
                marks.push({ type: styleTypeMap[type] });

                break;
            case RawDraftStyleType.bgcolor:
            case RawDraftStyleType.color:
                if (value) {
                    textStyleMarks.push({ [styleTypeMap[type]]: value });
                }

                break;
            case RawDraftStyleType.fontfamily:
                const fontFamily =
                    fontFamilyOptions.find((el) => el.value.toLowerCase().indexOf(value.toLowerCase()) !== -1)?.value ||
                    null;

                if (fontFamily) {
                    textStyleMarks.push({ [styleTypeMap[type]]: fontFamily });
                }

                break;
            case RawDraftStyleType.fontsize:
                const fontSize = fontSizeOptions.find((el) => el.value === value)?.value || null;

                if (fontSize) {
                    textStyleMarks.push({ [styleTypeMap[type]]: fontSize });
                }

                if (!fontSize && isNumber(parseFloat(value))) {
                    textStyleMarks.push({ [styleTypeMap[type]]: value });
                }

                break;
            case RawDraftEntityType.LINK_CUSTOM:
                const linkEntity = entityMap[value];

                if (linkEntity) {
                    marks.push({
                        type: styleTypeMap[type],
                        attrs: {
                            href: linkEntity.data.url,
                            target: linkEntity.data.targetOption,
                            rel: 'noopener noreferrer nofollow',
                            class: null,
                        },
                    });
                }

                break;
            case RawDraftEntityType.COMMENT:
                const commentEntity = entityMap[value];

                if (commentEntity.data.commentId) {
                    marks.push({
                        type: styleTypeMap[type],
                        attrs: { threadId: commentEntity.data.threadId, commentId: commentEntity.data.commentId },
                    });
                }

                break;
            default:
                break;
        }
    });

    if (textStyleMarks.length > 0) {
        marks.push({
            type: MarkType.TEXT_STYLE,
            attrs: Object.assign({ ...DEFAULT_TEXT_STYLE_ATTRS }, ...textStyleMarks),
        });
    }

    return {
        type: element.type,
        ...(marks.length > 0 ? { marks } : {}),
        text: text,
    };
};

export const getTextBlocks = (elements: TStyledCharElement[], entityMap: TRawDraftEntityMap): TTextBlock[] => {
    const textBlocks: TTextBlock[] = [];
    let preText = '';

    // получаем массив result, в котором идущие подряд символы объединены в строку, если их стили и сущности совпадают
    elements.forEach((el: TStyledCharElement, index, arr) => {
        const prevEl = index !== 0 ? arr[index - 1] : null;
        const isLast = index === arr.length - 1;

        if (index === 0) {
            preText = `${preText}${el.text}`;

            if (isLast) {
                textBlocks.push(getMappedElement(el, preText, entityMap));

                return;
            }
        }

        if (prevEl && index !== 0 && JSON.stringify({ ...el, text: '' }) !== JSON.stringify({ ...prevEl, text: '' })) {
            textBlocks.push(getMappedElement(prevEl, preText, entityMap));

            preText = el.text;
        } else if (index !== 0) {
            preText = `${preText}${el.text}`;
        }

        if (isLast) {
            textBlocks.push(getMappedElement(el, preText, entityMap));

            preText = '';

            return;
        }
    });

    return textBlocks;
};

export const mapUnstyledBlockText = (
    block: TRawDraftContentBlock,
    entityMap: TRawDraftEntityMap,
): TParagraphBlockContent[] => {
    const { text, inlineStyleRanges, entityRanges } = block;
    const content: TParagraphBlockContent[] = [];
    let splittedStyledChars: TStyledCharElement[] = [];

    const initPreResultElement = (char: string): TStyledCharElement => ({
        type: 'text',
        text: char,
        styles: [],
        entities: [],
    });

    const fillStyle = (item: TStyledCharElement, index: number) => {
        inlineStyleRanges.forEach((style) => {
            return isInRage(index, style) && item.styles.push(style.style);
        });

        return item;
    };

    const fillEntities = (item: TStyledCharElement, index: number) => {
        entityRanges.forEach((entity) => {
            return isInRage(index, entity) && item.entities.push(`${entityMap[String(entity.key)].type}-${entity.key}`);
        });

        return item;
    };

    // получаем styledChars:
    //
    // {
    //      type: 'text',
    //      text: <символ строки>,
    //      styles: <массив стилей для символа>,
    //      entities: <массив сущностей для символа>,
    // }[]
    splittedStyledChars = text.split('').map(initPreResultElement).map(fillStyle).map(fillEntities);

    let styledChars: TStyledCharElement[] = [];

    // группируем все символы между каждым символом переноса строки - '\n'
    // каждую группу преобразуем в текстовые блоки со стилями
    // символ переноса строки заменяем на блок 'hardBreak'
    //
    // { text: 'QWE\nRTY' } -> [{ text: 'QWE' }, { type: 'hardBreak' }, { text: 'RTY' }]
    splittedStyledChars.forEach((styledChar: TStyledCharElement, index: number, arr: TStyledCharElement[]) => {
        const isLast = index === arr.length - 1;

        if (styledChar.text === '\n') {
            if (styledChars.length !== 0) {
                const textBlocks = getTextBlocks(styledChars, entityMap);

                content.push(...textBlocks);

                styledChars = [];
            }

            content.push({ type: 'hardBreak' });
        } else if (isLast) {
            if (styledChars.length !== 0) {
                styledChars.push(styledChar);

                const textBlocks = getTextBlocks(styledChars, entityMap);

                content.push(...textBlocks);

                styledChars = [];
            }
        } else {
            styledChars.push(styledChar);
        }
    });

    return content;
};

export const mapUnstyledBlock = (block: TRawDraftContentBlock, entityMap: TRawDraftEntityMap): TParagraphBlock => {
    const mappedBlock: TParagraphBlock = {
        type: 'paragraph',
        attrs: mapUnstyledBlockData(block?.data || {}),
        content: [],
    };

    if (block?.text?.length > 0) {
        mappedBlock.content = mapUnstyledBlockText(block, entityMap);
    }

    return mappedBlock;
};

export const mapHeaderBlock =
    (level: number) =>
    (block: TRawDraftContentBlock, entityMap: TRawDraftEntityMap): THeadingBlock => {
        const mappedBlock: THeadingBlock = {
            type: 'heading',
            attrs: { level, ...mapUnstyledBlockData(block?.data || {}) },
            content: [],
        };

        if (block?.text?.length > 0) {
            mappedBlock.content = mapUnstyledBlockText(block, entityMap);
        }

        return mappedBlock;
    };

export const mapCheckableList = (blocks: TRawDraftContentBlock[], entityMap: TRawDraftEntityMap) => {
    return {
        type: 'taskList',
        content: blocks.map((taskItem) => {
            return {
                type: 'taskItem',
                attrs: {
                    checked: !!taskItem.data?.checked,
                },
                content: [mapUnstyledBlock(taskItem, entityMap)],
            };
        }),
    };
};

export const groupNestedBlocks = (blocks: TRawDraftContentBlock[]) => {
    const baseLevel = blocks[0].depth;
    let itemBlocks: TRawDraftContentBlock[] = [];
    const listItemBlocks: TRawDraftContentBlock[][] = [];

    blocks.forEach((item, index, arr) => {
        const isLast = index === arr.length - 1;

        if (index === 0) {
            itemBlocks.push(item);

            if (isLast) {
                listItemBlocks.push(itemBlocks);
            }

            return;
        }

        const blockLevel = item.depth;

        if (blockLevel !== baseLevel) {
            itemBlocks.push(item);

            if (isLast) {
                listItemBlocks.push(itemBlocks);
            }

            return;
        }

        if (blockLevel === baseLevel) {
            listItemBlocks.push(itemBlocks);
            itemBlocks = [item];

            if (isLast) {
                listItemBlocks.push(itemBlocks);
            }

            return;
        }
    });

    return listItemBlocks;
};

export const mapUnorderedList = (blocks: TRawDraftContentBlock[], entityMap: TRawDraftEntityMap): TBulletListBlock => {
    const groupedBlocks = groupNestedBlocks(blocks);

    return {
        type: 'bulletList',
        content: groupedBlocks.map((listItemBlocks) => {
            const paragraph = mapUnstyledBlock(listItemBlocks[0], entityMap);
            const internalBlocks = listItemBlocks.length > 1 ? listItemBlocks.slice(1) : [];
            const content: TListItemBlockContent[] = [paragraph];

            if (internalBlocks.length > 0) {
                content.push(mapUnorderedList(internalBlocks, entityMap));
            }

            return {
                type: 'listItem',
                content,
            };
        }),
    };
};

export const mapOrderedList = (blocks: TRawDraftContentBlock[], entityMap: TRawDraftEntityMap): TOrderedListBlock => {
    const groupedBlocks = groupNestedBlocks(blocks);

    return {
        type: 'orderedList',
        attrs: {
            start: 1,
        },
        content: groupedBlocks.map((listItemBlocks) => {
            const paragraph = mapUnstyledBlock(listItemBlocks[0], entityMap);
            const internalBlocks = listItemBlocks.length > 1 ? listItemBlocks.slice(1) : [];
            const content: TListItemBlockContent[] = [paragraph];

            if (internalBlocks.length > 0) {
                content.push(mapOrderedList(internalBlocks, entityMap));
            }

            return {
                type: 'listItem',
                content,
            };
        }),
    };
};

export const mapAtomicBlock = (
    block: TRawDraftContentBlock,
    entityMap: TRawDraftEntityMap,
    mapperProps?: { imageMapper: (str: string) => string },
) => {
    const entityKey = block.entityRanges[0]?.key;
    const entity = entityMap?.[entityKey];

    if (!entity) {
        return;
    }

    if (entity.type === 'IMAGE' && mapperProps?.imageMapper) {
        return {
            type: 'extendedImage',
            attrs: {
                indent: 0,
                textAlign: 'left',
                src: entity.data.src ? mapperProps?.imageMapper(entity.data.src) : '',
                alt: entity.data.alt || '',
                title: entity.data.title || '',
                width: entity.data.width ? String(entity.data.width) : WIKI_IMAGE_DEFAULT_WIDTH,
                height: entity.data.height ? String(entity.data.height) : WIKI_IMAGE_DEFAULT_HEIGHT,
            },
        };
    }

    return;
};

const DEFAULT_TABLE_CELL_ATTRS = { colspan: 1, rowspan: 1 };

export const mapTable = (blocks: TRawDraftContentBlock[], entityMap: TRawDraftEntityMap): TTableBlock | undefined => {
    const tableShape = blocks.find((el: TRawDraftContentBlock) => el.data?.tableShape)?.data?.tableShape;
    const tableContent: TTableRow[] = [];

    if (!tableShape) {
        return;
    }

    tableShape.forEach((rowShape: TRawDraftTableCellShape[], rowIndex: number) => {
        const tableRow: TTableRowContent[] = [];

        rowShape.forEach((cellShape: TRawDraftTableCellShape, cellIndex: number) => {
            const blockKey = `${blocks[0]?.data?.tableKey}-${rowIndex}-${cellIndex}`;
            const cellBlock = blocks.find((el) => el.data?.tablePosition === blockKey);

            if (!cellBlock) {
                return;
            }

            const cellAttrs: TTableRowAttrs = { ...DEFAULT_TABLE_CELL_ATTRS };

            if (cellShape.colspan) {
                cellAttrs.colspan = cellShape.colspan;
            }

            if (cellShape.rowspan) {
                cellAttrs.rowspan = cellShape.rowspan;
            }

            cellAttrs.colwidth = cellShape.style?.width ? [Number(cellShape.style?.width.replace('px', ''))] : null;
            cellAttrs.rowheight = cellShape.style?.height ? [Number(cellShape.style?.height.replace('px', ''))] : null;

            tableRow.push({
                type: cellShape.element === 'th' ? 'tableHeader' : 'tableCell',
                attrs: cellAttrs,
                content: [mapUnstyledBlock(cellBlock, entityMap)],
            });
        });

        tableContent.push({ type: 'tableRow', content: tableRow });
    });

    return {
        type: 'table',
        content: tableContent,
    };
};

const blockMappers = {
    [RawDraftBlockType.UNSTYLED]: mapUnstyledBlock,
    [RawDraftBlockType.HEADER_ONE]: mapHeaderBlock(1),
    [RawDraftBlockType.HEADER_TWO]: mapHeaderBlock(2),
    [RawDraftBlockType.HEADER_THREE]: mapHeaderBlock(3),
    [RawDraftBlockType.HEADER_FOUR]: mapHeaderBlock(4),
    [RawDraftBlockType.HEADER_FIVE]: mapHeaderBlock(5),
    [RawDraftBlockType.HEADER_SIX]: mapHeaderBlock(6),
    [RawDraftBlockType.CHECKABLE]: mapCheckableList,
    [RawDraftBlockType.UNORDERED]: mapUnorderedList,
    [RawDraftBlockType.ORDERED]: mapOrderedList,
    [RawDraftBlockType.ATOMIC]: mapAtomicBlock,
    [RawDraftBlockType.TABLE]: mapTable,
};

/**
 * Группирует блоки контента по принадлежности к одному элементу редакторе
 *
 * / ------- /
 *
 * Стейт draftjs имеет плоскую структуру
 *
 * Сложные блоки (таблицы, списки и т. д.) представлены несколькими объектами, лежащими последовательно и на одном уровне
 */
export const getGroupedBlocks = (blocks: TRawDraftContentBlock[]) => {
    if (!blocks.length) {
        return [];
    }

    let currentType = blocks[0]?.type;
    let currentTableKey = blocks[0]?.data?.tableKey;
    let group: TRawDraftContentBlock[] = [];
    const grouped: TRawDraftContentBlock[][] = [];

    blocks.forEach((block: TRawDraftContentBlock, index: number, arr: TRawDraftContentBlock[]) => {
        const isLast = index === arr.length - 1;

        if (index === 0) {
            group.push(block);

            if (isLast) {
                grouped.push(group);
            }

            return;
        }

        // Старая модель вики не поддерживает корректно вложенные в таблицу списки
        // Поэтому заменяем такие элементы на простые ячейки таблицы
        const isTableList =
            block.data?.type === RawDraftBlockType.TABLE &&
            [RawDraftBlockType.CHECKABLE, RawDraftBlockType.ORDERED, RawDraftBlockType.UNORDERED].includes(block.type);
        const blockType = (isTableList && RawDraftBlockType.TABLE) || block.type;
        const tableKey = block.data?.tableKey;

        if (
            blockType !== currentType ||
            tableKey !== currentTableKey ||
            (blockType === RawDraftBlockType.TABLE && !!block?.data?.tableShape)
        ) {
            grouped.push(group);
            currentType = blockType;
            currentTableKey = tableKey;
            group = [block];

            if (isLast) {
                grouped.push(group);
            }

            return;
        }

        if (blockType === currentType) {
            group.push(block);

            if (isLast) {
                grouped.push(group);
            }

            return;
        }
    });

    return grouped;
};

export const mapDeprecatedEditorState = (
    { blocks, entityMap }: TRawDraftContentState,
    { imageMapper }: { imageMapper: (str: string) => string },
) => {
    const result: WikiContentBlock[] = [];

    const stateBlockTypes = [
        RawDraftBlockType.UNSTYLED,
        RawDraftBlockType.HEADER_ONE,
        RawDraftBlockType.HEADER_TWO,
        RawDraftBlockType.HEADER_THREE,
        RawDraftBlockType.HEADER_FOUR,
        RawDraftBlockType.HEADER_FIVE,
        RawDraftBlockType.HEADER_SIX,
        RawDraftBlockType.CHECKABLE,
        RawDraftBlockType.UNORDERED,
        RawDraftBlockType.ORDERED,
        RawDraftBlockType.ATOMIC,
        RawDraftBlockType.TABLE,
    ];
    const filtered = (blocks || []).filter((el) => stateBlockTypes.includes(el.type));
    const grouped: TRawDraftContentBlock[][] = getGroupedBlocks(filtered);

    grouped.forEach((elementBlocks) => {
        const groupType = elementBlocks[0].type;

        if (groupType === RawDraftBlockType.UNSTYLED) {
            elementBlocks.forEach((el) => {
                const mapper: TMapper = blockMappers[groupType];
                const mapped = mapper(el, entityMap);

                result.push(mapped);
            });
        }

        if (
            [
                RawDraftBlockType.HEADER_ONE,
                RawDraftBlockType.HEADER_TWO,
                RawDraftBlockType.HEADER_THREE,
                RawDraftBlockType.HEADER_FOUR,
                RawDraftBlockType.HEADER_FIVE,
                RawDraftBlockType.HEADER_SIX,
            ].includes(groupType)
        ) {
            elementBlocks.forEach((el) => {
                const mapper: TMapper = blockMappers[groupType] as TMapper;
                const mapped = mapper(el, entityMap);

                result.push(mapped);
            });
        }

        if ([RawDraftBlockType.CHECKABLE, RawDraftBlockType.UNORDERED, RawDraftBlockType.ORDERED].includes(groupType)) {
            const mapper: TMapper = blockMappers[groupType] as TMapper;
            const mapped = mapper(elementBlocks, entityMap);

            result.push(mapped);
        }

        if (groupType === RawDraftBlockType.ATOMIC) {
            elementBlocks.forEach((el) => {
                const mapper: TMapper = blockMappers[groupType] as TMapper;
                const mapped = mapper(el, entityMap, { imageMapper });

                result.push(mapped);
            });
        }

        if (groupType === RawDraftBlockType.TABLE) {
            const mapper: TMapper = blockMappers[groupType] as TMapper;
            const mapped = mapper(elementBlocks, entityMap, { imageMapper });

            result.push(mapped);
        }
    });

    return { type: 'doc', content: result.filter(Boolean) };
};

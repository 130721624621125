import { AipPreset } from './AipPreset';
import { sourceQueryProcessor} from './SourceQueryProcessor';
import { TAipPresetGroup, TFoundAipPresetItem } from "./types/AipPreset.types";
import {
    AipLinkedSearch,
    AipSearch,
    NodeId,
    SearchRequestNodeTypesEnum
} from "@/serverapi/api";
import { TProcessedItemGroup, TProcessedSourceQueryItem } from "./types/SourceQueryProcessor.types";
import { TAipConfigTokenId } from "./types/AipConfig.types";
import { searchRuleBuilder } from './SearchRuleBuilder';
import { TBuildSearchRuleReturnValue, TStems } from './types/SearchRuleBuilder.types';


class AipSearchRequestBuilder {

    build(sourceQuery: string, nodeId: NodeId, aipPreset: AipPreset, stems: TStems): AipSearch | null {
        const processedQuery: TProcessedSourceQueryItem[] = sourceQueryProcessor.process(sourceQuery);
        if (!processedQuery.length) return null;

        // часть запроса ДО первого блока связанности. В этой части будет информация о том что мы ищем
        const targetObjectDescriptionPart : TProcessedSourceQueryItem[] = this.getNextQueryPart(processedQuery);
        const textQuery: string = this.restoreSourceQuery(targetObjectDescriptionPart);
        const aipSearchRequest: AipSearch | null = this.buildBaseAipSearch(textQuery, targetObjectDescriptionPart, nodeId, aipPreset, stems);

        if (!aipSearchRequest) return null;

        let rest: TProcessedSourceQueryItem[] = processedQuery.slice(targetObjectDescriptionPart.length);
        if (!rest.length) return aipSearchRequest; // больше ничего нету, возвращаем то что есть
        // поскольку запрос переработан не полностью, у AipSearch обязано быть свойство linkedWith
        aipSearchRequest.linkedWith = [];
        // в этом цикле обрабатываются блоки запроса, в которых указывается с чем связан искомый элемент
        do {
            const linkedWithPart: TProcessedSourceQueryItem[] = this.getNextQueryPart(rest);
            const partSource: string = this.restoreSourceQuery(linkedWithPart);
            const item: AipLinkedSearch | null = this.buildLinkedWithItem(partSource, linkedWithPart, nodeId, aipPreset, stems);
            // отрезаем от запроса только что обработанную часть
            rest = rest.slice(linkedWithPart.length);
            // item может быть null. В таком случае в описании связанности какая-то ошибка. Пока такую ситуацию мы
            // просто проигнорируем, но со временем её неплохо бы обработать и сообщить пользователю что именно не так в этой части запроса
            if (item) aipSearchRequest.linkedWith.push(item);
        } while(rest.length);

        return aipSearchRequest;
    }


    private getAllAccessibleTypes(): SearchRequestNodeTypesEnum[] {
        return [ 'FOLDER', 'MODEL', 'OBJECT' ];
    }


    private getNodeTypesByConfigToken(token: TAipConfigTokenId): SearchRequestNodeTypesEnum[] {
        const tokenNameToNodeTypeMapping: Partial<Record<TAipConfigTokenId, SearchRequestNodeTypesEnum[]>> = {
            OBJECT_DEFINITION: [ 'OBJECT' ],
            ELEMENT: [ 'FOLDER', 'MODEL', 'OBJECT' ]
        };
        return (token in tokenNameToNodeTypeMapping ? tokenNameToNodeTypeMapping[token] : [ token ]) as SearchRequestNodeTypesEnum[];
    }


    private restoreSourceQuery(processedQuery: TProcessedSourceQueryItem[]): string {
        return processedQuery.map(val => val?.value?.source).filter(val => val).join(' ');
    }


    private isSearchTargetExceptAlias(itemGroup: TAipPresetGroup): boolean {
        const types: TAipPresetGroup[] = [TAipPresetGroup.folderTypes, TAipPresetGroup.modelTypes, TAipPresetGroup.objectTypes];
        return types.includes(itemGroup);
    }


    private findSearchTargetInAlternate(alternate: TFoundAipPresetItem[] | undefined): TFoundAipPresetItem | undefined {
        const searchTargetGroups: TAipPresetGroup[] = [ TAipPresetGroup.folderTypes, TAipPresetGroup.modelTypes, TAipPresetGroup.objectTypes ];
        return alternate && alternate.find((val: TFoundAipPresetItem) => {
            return searchTargetGroups.includes(val.group);
        });
    }


    private makeBasicAipSearch(textQuery: string, nodeId: NodeId, queryItem: TProcessedSourceQueryItem, aipPreset: AipPreset, stems: TStems): AipSearch | null {
        const request: AipSearch = {
            filter: {
                rootSearchNodeId: nodeId,
                limit: -1
            },
            textQuery: textQuery
        };

        if (!queryItem.value) return null;

        switch(queryItem.type) {
            case TProcessedItemGroup.CONFIG: {
                request.filter.nodeTypes = this.getNodeTypesByConfigToken(queryItem.value.tokens[0]);
                return request;
            }
            case TProcessedItemGroup.PRESET: {
                const queryItemGroup: TAipPresetGroup = queryItem.value.items[0].group;

                // это может быть алиас, и тогда всё что нужно сделать, это записать его ID в специальное поле
                if (queryItemGroup === TAipPresetGroup.aliasTypes) {
                    request.aliasId = queryItem.value.items[0].item.id;
                    return request;
                }

                const alternateTarget: TFoundAipPresetItem | undefined = this.findSearchTargetInAlternate(queryItem.value.alternate);
                const isTarget = this.isSearchTargetExceptAlias(queryItemGroup);

                // queryItem может содержать тип целевого элемента поиска, либо может НЕ содержать тип целевого элемента поиска в основном поле,
                // но содержать какой-то из них в массиве alternate.
                if (isTarget || alternateTarget) {
                    const grp: TAipPresetGroup = !isTarget && alternateTarget ? alternateTarget.group : queryItemGroup;
                    request.filter.nodeTypes = this.getNodeTypesByPresetGroup(grp);
                    if (!isTarget && alternateTarget) {
                        queryItem.value.items = [ alternateTarget ];
                    }
                    const res: TBuildSearchRuleReturnValue = searchRuleBuilder.build(request, [ queryItem ], aipPreset, stems);
                    // TODO по идее тут не может возникнуть ошибка, но в будущем это всё равно нужно будет проверить 
                    request.filter.searchRules = res.rules ? res.rules : [];
                    return request;
                }

                // Если мы дошли до этого места, значит то что мы восприняли как данные из пресета, на самом деле было именем или частью имени
                const res: TBuildSearchRuleReturnValue = searchRuleBuilder.build(request, [ queryItem ], aipPreset, stems);
                // TODO по идее тут не может возникнуть ошибка, но в будущем это всё равно нужно будет проверить 
                request.filter.searchRules = res.rules ? res.rules : [];
                return request;
            }
            case TProcessedItemGroup.NOWHERE: {
                request.filter.nodeTypes = this.getAllAccessibleTypes();
                const res: TBuildSearchRuleReturnValue = searchRuleBuilder.build(request, [ queryItem ], aipPreset, stems);
                // TODO по идее тут не может возникнуть ошибка, но в будущем это всё равно нужно будет проверить 
                request.filter.searchRules = res.rules ? res.rules : [];
                return request;
            }
            default: return null;
        }
    }


    private getNodeTypesByPresetGroup(group: TAipPresetGroup): SearchRequestNodeTypesEnum[] | undefined{
        const mapping: Partial<Record<TAipPresetGroup, SearchRequestNodeTypesEnum>> = {
            [ TAipPresetGroup.aliasTypes ] : 'OBJECT',
            [ TAipPresetGroup.folderTypes ] : 'FOLDER',
            [ TAipPresetGroup.modelTypes ] : 'MODEL',
            [ TAipPresetGroup.objectTypes ] : 'OBJECT'
        };
        const nodeType: SearchRequestNodeTypesEnum | undefined = mapping[group];
        return nodeType ? [ nodeType ] : undefined;
    }


    private buildBaseAipSearch(textQuery: string, queryPart: TProcessedSourceQueryItem[], nodeId: NodeId, aipPreset: AipPreset, stems: TStems): AipSearch | null {
        const queryPartLength = queryPart.length;
        if (!queryPartLength) return null;

        // создаём базовую часть объекта AipSearch где описан целевой искомый элемент. Для этого нам достаточно первого элемента запроса
        const request: AipSearch | null = this.makeBasicAipSearch(textQuery, nodeId, queryPart[0], aipPreset, stems);

        // здесь впоследствии нужно будет обработать ошибку в запросе
        if (!request) return request;
        // это возврат результата в случае если всё прошло хорошо и описаний searchRules в запросе не будет
        if (queryPartLength === 1) return request;

        // раз мы здесь, значит в запросе присутствуют элементы уточняющие цель поиска, которые нам необходимо обработать и добавить в searchRules.

        if (!request.filter.searchRules) request.filter.searchRules = [];

        // обрабатываем оставшуюся часть запроса и заполняем результатами массив searchRules
        queryPart = queryPart.slice(1);

        while (queryPart.length) {
            const { rules, wereUsed } = searchRuleBuilder.build(request, queryPart, aipPreset, stems);
            queryPart = queryPart.slice(wereUsed);
            if (rules) request.filter.searchRules = request.filter.searchRules.concat(rules);
            // TODO rule может быть null. В таком случае в описании дополнительного правила какая-то ошибка. Пока такую ситуацию мы
            // просто проигнорируем, но со временем её неплохо бы обработать и сообщить пользователю что именно не так в этой части запроса
        }

        return request;
    }


    // Возвращает очередную часть запроса для дальнейшей обработки. Это может быть или часть запроса задающая основной объект поиска
    // или часть описывающая с чем связан искомый объект. Части запроса ограничиваются указанием того с чем связан элемент. Это может быть
    // либо указание типа связи из пресета либо явное написание строки "связанный с" или её синонимов
    private getNextQueryPart(processedQueryPart: TProcessedSourceQueryItem[]): TProcessedSourceQueryItem[] {
        const closestLinkedWithItemIndex = processedQueryPart.findIndex((val: TProcessedSourceQueryItem, index: number) => {
            return (index >= 1)
                && ((val.type === TProcessedItemGroup.PRESET && val.value.items[0].group === TAipPresetGroup.edgeTypes)
                    || (val.type === TProcessedItemGroup.CONFIG && val.value?.aipConfigGroup === 'LINKED_WITH'));
        });
        return processedQueryPart.slice(0, closestLinkedWithItemIndex === -1 ? processedQueryPart.length : closestLinkedWithItemIndex);
    }


    private buildLinkedWithItem(textQuery: string, queryPart: TProcessedSourceQueryItem[], nodeId: NodeId, aipPreset: AipPreset, stems: TStems): AipLinkedSearch | null {
        const firstItem: TProcessedSourceQueryItem = queryPart[0];
        if (!firstItem.value) return null;
        if (firstItem.type === TProcessedItemGroup.PRESET && !(firstItem.value.items[0].group === TAipPresetGroup.edgeTypes)) {
            return null; // по идее не должно такого быть раз это элемент с указанием связи
        }
        if (queryPart.length < 2) return null; // мало данных, невозможно понять с чем должен быть связан объект
        // первый элемент характеризует связь, поэтому в обработку в общую функцию мы его не отправляем, добавим её куда надо несколькими строками ниже если сам запрос будет успешный
        const aipSearchRequest: AipSearch | null = this.buildBaseAipSearch(textQuery, queryPart.slice(1), nodeId, aipPreset, stems);
        if (!aipSearchRequest) return null;
        const linkedWithItem: AipLinkedSearch = {
            connected: aipSearchRequest
        };

        if (firstItem.type === TProcessedItemGroup.PRESET) {
            linkedWithItem.edgeTypeId = firstItem.value.items[0].item.id;
        }

        return linkedWithItem;
    }
};

export const aipSearchRequestBuilder = new AipSearchRequestBuilder();

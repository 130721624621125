import pieChartIcon from '../../../../resources/icons/pieChart.svg';
import lineChartIcon from '../../../../resources/icons/lineChart.svg';
import barChartIcon from '../../../../resources/icons/barChart.svg';
import summary from '../../../../resources/icons/summary.svg';
import textBlock from '../../../../resources/icons/textBlock.svg';
import messages from './DashboardSymbols.messages';
import { TDashboardSymbolType } from '@/modules/Dashboard/Dashboard.types';
import { useIntl } from 'react-intl';

export const getSymbols = (): TDashboardSymbolType[] => {
    const intl = useIntl();
    return [
        {
            id: 1,
            name: intl.formatMessage(messages.pieChart),
            type: 'PIE_CHART',
            icon: pieChartIcon,
        },
        {
            id: 2,
            name: intl.formatMessage(messages.lineChart),
            type: 'LINE_CHART',
            icon: lineChartIcon,
        },
        {
            id: 3,
            name: intl.formatMessage(messages.barChart),
            type: 'BAR_CHART',
            icon: barChartIcon,
        },
        {
            id: 4,
            name: intl.formatMessage(messages.summary),
            type: 'SUMMARY',
            icon: summary,
        },
        {
            id: 5,
            name: intl.formatMessage(messages.textBlock),
            type: 'TEXT_BLOCK',
            icon: textBlock,
        },
    ];
};

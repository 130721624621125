import React from 'react';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { DashboardEditModeButton } from './Buttons/DashboardEditMode.component';
import { GridButton } from './Buttons/GridButton.component';
import { ResizableToolbar } from '../../../UIKit/components/Toolbar/ResizableToolbar/ResizableToolbar.component';

export const DashboardEditorToolbar = () => {
    return (
        <ResizableToolbar>
            <ButtonGroup>
                <DashboardEditModeButton />
            </ButtonGroup>
            <ButtonGroup>
                <GridButton />
            </ButtonGroup>
        </ResizableToolbar>
    );
};

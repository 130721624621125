import {
    PRESET_SETTINGS_ADD_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_CREATE_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_GET_ALL_WIKI_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_SUBMIT_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_WIKI_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsWikiModelType.actionTypes';
import {
    TAddWikiModelTypeAction,
    TAddWikiModelTypePayload,
    TCreateWikiModelTypeAction,
    TCreateWikiModelTypePayload,
    TDeleteWikiModelTypeAction,
    TDeleteWikiModelTypePayload,
    TEditWikiModelTypeAction,
    TEditWikiModelTypePayload,
    TGetAllWikiModelTypesSuccessAction,
    TGetAllWikiModelTypesSuccessPayload,
    TSubmitWikiModelTypeAction,
    TSubmitWikiModelTypePayload,
    TTransferWikiModelTypeAction,
    TTransferWikiModelTypePayload,
} from './presetSettingsWikiModelType.actions.types';

export const createWikiModelType = (payload: TCreateWikiModelTypePayload): TCreateWikiModelTypeAction => ({
    type: PRESET_SETTINGS_CREATE_WIKI_MODEL_TYPE,
    payload,
});

export const submitWikiModelType = (payload: TSubmitWikiModelTypePayload): TSubmitWikiModelTypeAction => ({
    type: PRESET_SETTINGS_SUBMIT_WIKI_MODEL_TYPE,
    payload,
});

export const getAllWikiModelTypesSuccess = (
    payload: TGetAllWikiModelTypesSuccessPayload,
): TGetAllWikiModelTypesSuccessAction => ({
    type: PRESET_SETTINGS_GET_ALL_WIKI_MODEL_TYPES_SUCCESS,
    payload,
});

export const addWikiModelType = (payload: TAddWikiModelTypePayload): TAddWikiModelTypeAction => ({
    type: PRESET_SETTINGS_ADD_WIKI_MODEL_TYPE,
    payload,
});

export const deleteWikiModelType = (payload: TDeleteWikiModelTypePayload): TDeleteWikiModelTypeAction => ({
    type: PRESET_SETTINGS_DELETE_WIKI_MODEL_TYPE,
    payload,
});

export const editWikiModelType = (payload: TEditWikiModelTypePayload): TEditWikiModelTypeAction => ({
    type: PRESET_SETTINGS_EDIT_WIKI_MODEL_TYPE,
    payload,
});

export const transferWikiModelType = (payload: TTransferWikiModelTypePayload): TTransferWikiModelTypeAction => ({
    type: PRESET_SETTINGS_TRANSFER_WIKI_MODEL_TYPE,
    payload,
});

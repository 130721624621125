import icTransition from '@/resources/icons/MainMenuGoTo.svg';
import icObject from '@/resources/icons/object.svg';
import icDecomposition from '@/resources/icons/MainMenuDecomposition.svg';

export enum MoveTo {
    Noop,
    Decomposition,
    ObjectInTree,
}

export const MoveToIcons = {
    Noop: icTransition,
    Decomposition: icObject,
    ObjectInTree: icDecomposition,
};

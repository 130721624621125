import React, { ChangeEvent } from 'react';
import { Input, Form } from 'antd';
import theme from './SelectLocalizedValues.scss';
import messages from '../../../messages/Presets.messages';
import { TSelectDataTypeValue } from './SelectLocalizedValues.types';
import { AttributeTypeSelectPropertyValue } from '../../../../../../serverapi/api';
import { useIntl } from 'react-intl';
import { Locale } from '@/modules/Header/components/Header/header.types';

type TColumnProps = {
    value?: string;
    selectValue: TSelectDataTypeValue;
    dataSource: AttributeTypeSelectPropertyValue[];
    dataIndex: 'id' | Locale;
    setDataSource: (dataSource: AttributeTypeSelectPropertyValue[]) => void;
};

const getOtherLocations = (dataIndex: 'id' | Locale): string[] => {
    if(dataIndex === 'id') return [];

    return [Locale.ru, Locale.en, Locale.es].filter(str => str !== dataIndex);
}

const getDependencies = (dataIndex: 'id' | Locale, otherLocals: string[], idx: number, dataSource: AttributeTypeSelectPropertyValue[]): string[] => {
    if(dataIndex === 'id') {
        return dataSource.map((_, id) => `id:${id}`);
    } 

    return otherLocals.map(d => `${d}:${idx}`);
}

export const ColumnRender = (props: TColumnProps) => {
    const { value, selectValue, dataSource, dataIndex, setDataSource } = props;
    const intl = useIntl();
    const isIdValue = dataIndex === 'id';
    const otherLocals: string[] = getOtherLocations(dataIndex);
    const dependencies: string[] = getDependencies(dataIndex, otherLocals, selectValue.idx, dataSource);

    const idValidator = (_, val) => {
        const previousIds = dataSource.filter((attrValue, id) => id !== selectValue.idx).map((d) => d.id);

        if (val && previousIds.includes(val)) {
            return Promise.reject(intl.formatMessage(messages.attributeUniqueValueId));
        }

        return Promise.resolve();
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDataSource(
            dataSource.map((data, id) => {
                if (id === selectValue.idx) {
                    return isIdValue
                        ? { ...data, id: e.target.value }
                        : { ...data, value: { ...data.value, [dataIndex]: e.target.value } };
                }

                return data;
            }),
        );
    };

    const requiredValue: boolean = isIdValue ? true : !otherLocals.some(otherLocal => !!selectValue[otherLocal]);

    return (
        <Form.Item
            name={`${dataIndex}:${selectValue.idx}`}
            className={theme.margin0}
            initialValue={value}
            dependencies={dependencies}
            rules={[
                {
                    whitespace: true,
                    required: requiredValue,
                    message: intl.formatMessage(messages.requiredField),
                },
                () => ({validator: isIdValue ? idValidator : undefined}),
            ]}
        >
            <Input
                data-test="attribute_multi-select_cell-value_input"
                onChange={onChange}
                className={theme.selectInput}
            />
        </Form.Item>
    );
};

import React, { FC, useCallback, useMemo } from 'react';
import theme from './WidgetsContainer.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCellContainerId,
    getWidgetsByTab,
    getWidgetsContainerHeight,
} from '@/modules/UITemplates/utils/UITemplate.utils';
import {
    UITemplateEditorChangeActiveTab,
    UITemplateEditorSelectWidget,
} from '@/modules/UITemplates/actions/UITemplateEditor.actions';
import { UITemplateDTOTabsTypeEnum, UITemplateTabDTO, WidgetDTO } from '@/serverapi/api';
import { Widget } from '../Widget/Widget.component';
import { Tab } from '@/modules/UIKit/components/Tabs/UIKitTabs.types';
import { UIKitTabs } from '@/modules/UIKit/components/Tabs/UIKitTabs.component';
import { UITemplateEditorSelectors } from '@/modules/UITemplates/selectors/UITemplateEditor.selectors';
import { UI_TEMPLATE_SETTINGS_PANEL_WIDTH, WIDGETS_PANEL_WIDTH } from '@/modules/UITemplates/utils/consts';

type TWidgetsContainerProps = {
    templateId: string;
    cellSize: number;
    cellsContainer: React.RefObject<HTMLDivElement>;
};

export const WidgetsContainer: FC<TWidgetsContainerProps> = ({ templateId, cellSize, cellsContainer }) => {
    const dispatch = useDispatch();
    const templateTabs: UITemplateTabDTO[] = useSelector(UITemplateEditorSelectors.tabs(templateId));
    const widgets: WidgetDTO[] = useSelector(UITemplateEditorSelectors.widgets(templateId));
    const selectedWidget: WidgetDTO | undefined = useSelector(UITemplateEditorSelectors.selectedWidget(templateId));
    const tabsType: UITemplateDTOTabsTypeEnum | undefined = useSelector(UITemplateEditorSelectors.tabsType(templateId));
    const activeTabId: string | undefined = useSelector(UITemplateEditorSelectors.activeTabId(templateId));

    const onContainerClickHandler = () => {
        dispatch(UITemplateEditorSelectWidget(templateId, ''));
    };

    const onTabClickHandler = (tabId: string) => {
        dispatch(UITemplateEditorChangeActiveTab(templateId, tabId));
        dispatch(UITemplateEditorSelectWidget(templateId, ''));
    };

    const getContent = useCallback(
        (widgets: WidgetDTO[]) => (
            <div className={theme.scrollContainer}>
                <div
                    ref={cellsContainer}
                    id={getCellContainerId(templateId)}
                    className={`${theme.cellsContainer} ${theme.cellsBackground}`}
                    style={{
                        backgroundSize: `${cellSize}px ${cellSize}px`,
                        height: `${getWidgetsContainerHeight(cellSize, widgets)}px`,
                    }}
                    onClick={onContainerClickHandler}
                >
                    {widgets.map((widget) => {
                        return (
                            <Widget
                                key={widget.id}
                                templateId={templateId}
                                widget={widget}
                                cellSize={cellSize}
                                selected={selectedWidget?.id === widget.id}
                            />
                        );
                    })}
                </div>
            </div>
        ),
        [cellSize, selectedWidget, cellsContainer.current],
    );

    const tabs: Tab[] = useMemo(
        () =>
            templateTabs.map((templateTab) => {
                const widgetsByTab: WidgetDTO[] = getWidgetsByTab(widgets, templateTab);

                return {
                    id: templateTab.id,
                    title: templateTab.name,
                    content: getContent(widgetsByTab),
                    onClick: onTabClickHandler,
                };
            }),
        [templateTabs, widgets, getContent],
    );

    const sidePanelsWidth: number = WIDGETS_PANEL_WIDTH + UI_TEMPLATE_SETTINGS_PANEL_WIDTH;

    return (
        <div className={theme.widgetsContainer} style={{ width: `calc(100% - ${sidePanelsWidth}px)` }}>
            {tabsType ? (
                <UIKitTabs
                    orientation={tabsType === 'HORIZONTAL' ? 'horizontal' : 'vertical'}
                    tabs={tabs}
                    activeTabId={activeTabId}
                />
            ) : (
                getContent(widgets)
            )}
        </div>
    );
};

import { createSelector } from 'reselect';
import { TabsSelectors } from './tabs.selectors';
import { TRootState } from '../reducers/root.reducer.types';
import { LocalesService } from '../services/LocalesService';
import messages from '@/modules/MainMenu/messages/CommonToolbar.messages';

export namespace DashboardToolbarSelectors {
    export const getGridButtonDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            return isReadMode;
        },
    );

    export const getGridButtonTooltip = createSelector<TRootState, boolean, string>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const intl = LocalesService.useIntl();

            return isReadMode
                ? intl.formatMessage(messages.onlyInEditModeAvailable)
                : intl.formatMessage(messages.dashboardGridTooltip);
        },
    );
}

import React, { FC, useEffect, useState } from 'react';
import style from './DbSearch.component.scss';
import { SearchPath } from '../SearchPath/SearchPath.component';
import { useDispatch, useSelector } from 'react-redux';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { TSearchDataListItem } from '../../../reducers/search.reducer.types';
import { SearchField } from '../SearchField/SearchField.component';
import { SearchFilter } from '../SearchFilter/SearchFilter.component';
import { getSearchPaths, resetSearchData, setSearchNodeTypes, setSearchRules } from '../../../actions/search.actions';
import { AttributeType, AttributeTypeValueTypeEnum, NodeId, SearchRequestNodeTypesEnum } from '../../../serverapi/api';
import { presetMetaDataRequestWithPresetId } from '../../../actions/notation.actions';
import { SearchElementTypeSelectors } from '../selectors/searchElementType.selectors';
import icFilterActive from '../../../resources/icons/icFilterActive.svg';
import cx from 'classnames';
import icFilter from '../../../resources/icons/icFilter.svg';
import { AttributeFilter } from '../AttributeFilter/AttributeFilter.component';
import { ISearchRuleWithValueId } from '../AttributeFilter/AttributeFilter.types';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import icDelete from '../../../resources/icons/Deleted.svg';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import messages from './DbSearch.messages';
import { useIntl } from 'react-intl';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { TCurrentUserProfile } from '@/reducers/userProfile.reducer.types';
import { ProfileBllService } from '@/services/bll/ProfileBllService';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { AttributeValueType } from '@/modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import { systemAttributeTypes } from '@/utils/constants/systemAttributes.const';
import { IWorkspaceDbSearchTabItemParams, TWorkspaceTab } from '@/models/tab.types';
import { checkIsDialogSearch } from '@/utils/nodeId.utils';
import { DbSearchList } from './DbSearchList.component';

/* 
  Компонент поиска может быть вызван как отедльная вкладка
  либо, как диалоговое окно для справочника.
  Из-за этого отличается структура пропсов.
  searchNodeId - для идентификации вкладки поиска и как ключ для данных в сторе
  originalNodeId - элемент на котором вызван поиск, либо 
  NodeId справочника, если это диалоговое окно в справочнике
*/

type TDbSearchProps =
    | {
          searchNodeId: NodeId;
          originalNodeId: NodeId;
          tab?: never;
      }
    | {
          tab: TWorkspaceTab;
          searchNodeId?: never;
          originalNodeId?: never;
      };

export const DbSearch: FC<TDbSearchProps> = (props) => {
    const searchNodeId: NodeId = props.searchNodeId || props.tab.nodeId;
    const originalNodeId: NodeId =
        props.originalNodeId || (props.tab.params as IWorkspaceDbSearchTabItemParams).originalNodeId;

    const dispatch = useDispatch();
    const intl = useIntl();
    const isLoading: boolean = useSelector(SearchSelectors.getLoadingStatus(searchNodeId));
    const searchRules: ISearchRuleWithValueId[] = useSelector(SearchSelectors.getSearchRules(searchNodeId));
    const searchNodeTypes: SearchRequestNodeTypesEnum[] = useSelector(SearchSelectors.getSearchNodeTypes(searchNodeId));
    const elementsCount: number | undefined = useSelector(SearchSelectors.getFoundElementsCount(searchNodeId));
    const rootSearchNodeIds: NodeId[] = useSelector(SearchSelectors.getRootSearchNodeIds(searchNodeId));
    const filterSearchResult: TSearchDataListItem[] = useSelector(
        SearchElementTypeSelectors.getSearchResult(searchNodeId, originalNodeId, rootSearchNodeIds),
    );
    const attributeTypes: AttributeType[] = useSelector(
        AttributeTypeSelectors.allInPresetsSortedByNodeIds(rootSearchNodeIds),
    );
    const profile: TCurrentUserProfile | undefined = useSelector(
        UserProfileSelectors.selectUserProfileByNodeId(originalNodeId),
    );
    const accessibleAttributeTypes = attributeTypes.filter((attrType) =>
        ProfileBllService.isAttributeViewable(profile, attrType.id),
    );

    const isDialogSearch: boolean = checkIsDialogSearch(searchNodeId);

    const [isAttributeFilterOpen, setIsAttributeFilterOpen] = useState<boolean>(false);

    const setSearchRulesHandler = (searchRules: ISearchRuleWithValueId[]) => {
        dispatch(setSearchRules(searchNodeId, searchRules));
    };

    const setSearchNodeTypesHandler = (searchNodeTypes: SearchRequestNodeTypesEnum[]) => {
        dispatch(setSearchNodeTypes(searchNodeId, searchNodeTypes));
    };

    useEffect(() => {
        if (originalNodeId) {
            dispatch(presetMetaDataRequestWithPresetId(originalNodeId));
            dispatch(getSearchPaths(searchNodeId));
        }

        return () => {
            if (!isDialogSearch) dispatch(resetSearchData(searchNodeId));
        };
    }, []);

    const handleAttributeFilterToggle = () => setIsAttributeFilterOpen(!isAttributeFilterOpen);

    const onDeleteAllConditions = () => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    setSearchRulesHandler([]);
                },
                title: intl.formatMessage(messages.deleteConfirmTitle),
                question: intl.formatMessage(messages.deleteConfirmQuestion),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    const getFiltersCount = () => {
        let count: number = searchRules.length;
        if (count === 0) return count;

        searchRules.forEach((rule) => {
            if (rule.values.length > 1) {
                const attributeValueType: AttributeTypeValueTypeEnum | undefined = [
                    ...accessibleAttributeTypes,
                    ...systemAttributeTypes,
                ].find((attrType) => attrType.id === rule.attributeTypeId)?.valueType;
                if (
                    attributeValueType !== AttributeValueType.MULTI_SELECT &&
                    attributeValueType !== AttributeValueType.PRINCIPAL
                ) {
                    count = count + rule.values.length - 1;
                }
            }
        });

        return count;
    };

    return (
        <div className={style.container}>
            <SearchPath searchNodeId={searchNodeId} />
            <div className={style.flexCenter}>
                <SearchField searchNodeId={searchNodeId} searchRules={searchRules} searchNodeTypes={searchNodeTypes} />
                <div className={cx(style.filterBtn, { [style.filterBtnActive]: isAttributeFilterOpen })}>
                    <Button
                        onClick={handleAttributeFilterToggle}
                        icon={isAttributeFilterOpen ? icFilterActive : icFilter}
                    />
                </div>

                {searchRules.length ? (
                    <div className={style.deleteConditionBtn}>
                        <div>{intl.formatMessage(messages.filters, { number: getFiltersCount() })}</div>
                        <Button visualStyle="text" size="small" onClick={onDeleteAllConditions} icon={icDelete} />
                    </div>
                ) : null}

                <SearchFilter searchNodeTypes={searchNodeTypes} setSearchNodeTypes={setSearchNodeTypesHandler} />
            </div>

            <AttributeFilter
                searchRules={searchRules}
                setSearchRules={setSearchRulesHandler}
                attributeTypes={accessibleAttributeTypes}
                nodeId={originalNodeId}
                isHidden={!isAttributeFilterOpen}
            />

            {elementsCount ? (
                <div className={style.elementsCount}>
                    {intl.formatMessage(messages.elementsFound)} {elementsCount}
                </div>
            ) : null}

            <DbSearchList isLoading={isLoading} searchNodeId={searchNodeId} searchResult={filterSearchResult} />
        </div>
    );
};

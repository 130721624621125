import { Input } from '@/modules/UIKit/components/Input/Input.component';
import React from 'react';
import { FieldErrors, Path, PathValue, UseFormRegister } from 'react-hook-form';

import theme from './SymbolEditorHeader.scss';
import { useIntl } from 'react-intl';
import { InputId } from '@/modules/UIKit/components/FormElements/InputId/InputId.component';
import { InputSynonymsIds } from '@/modules/UIKit/components/FormElements/InputSynonymsIds/InputSynonymsIds.component';
import objectTypeMessages from '@/modules/AdminTools/Methodology/components/Presets/ObjectType/ObjectType.messages';
import { MultiLangInputDialog } from '@/modules/UIKit/components/FormElements/MultiLangInputDialog/MultiLangInputDialog.component';
import { InternationalString } from '@/serverapi/api';
import classNames from 'classnames';
import { TSymbolHeaderForm } from '../../SymbolEditorTab.types';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

interface ISymbolEditorHeaderProps {
    id: PathValue<TSymbolHeaderForm, Path<TSymbolHeaderForm>>;
    synonymsIds: string[] | undefined;
    multilingualName: InternationalString | undefined;
    disableId: boolean;
    containmentBlocked: boolean | undefined;
    isComplexSymbol: boolean;
    register: UseFormRegister<TSymbolHeaderForm>;
    errors: FieldErrors<TSymbolHeaderForm>;
    onChangeId: (id: string) => void;
    onChangeSynonymsIds: (id: string[]) => void;
    onChangeName: (multiLangValue: InternationalString) => void;
    onChangeContainmentBlocked: (containmentBlocked: boolean) => void;
}

export const SymbolEditorHeader = ({
    id,
    synonymsIds,
    onChangeId,
    disableId,
    containmentBlocked,
    onChangeSynonymsIds,
    onChangeName,
    onChangeContainmentBlocked,
    multilingualName,
    isComplexSymbol,
    register,
    errors,
}: ISymbolEditorHeaderProps) => {
    const intl = useIntl();

    return (
        <div className={theme.headerInputs}>
            <MultiLangInputDialog
                formItemClassName={classNames(theme.inputId, theme.requiredInput)}
                placeholder={intl.formatMessage(objectTypeMessages.newSymbol)}
                onChange={onChangeName}
                label={intl.formatMessage(objectTypeMessages.name)}
                required
                name={'multilingualName'}
                data-test="symbol-name-input"
                register={register}
                value={multilingualName}
                errors={errors}
            />
            <InputId
                wrapperClassName={theme.requiredInput}
                value={id}
                register={register}
                onChange={onChangeId}
                required
                disabled={disableId}
                name={'id'}
                errors={errors}
            />
            <InputSynonymsIds
                value={synonymsIds}
                name={'synonymsIds'}
                onChange={onChangeSynonymsIds}
                register={register}
            />

            <Input
                {...register('symbolType')}
                label={intl.formatMessage(objectTypeMessages.symbolType)}
                disabled
                readOnly
            />
            {!isComplexSymbol ? (
                <div className={theme.checkboxWrapper}>
                    <Checkbox
                        status={containmentBlocked}
                        onChange={() => onChangeContainmentBlocked(!containmentBlocked)}
                    >
                        {intl.formatMessage(objectTypeMessages.containmentBlocked)}
                    </Checkbox>
                </div>
            ) : null}
        </div>
    );
};

import React, { useEffect } from 'react';
import { Menu } from 'antd';
import theme from './SymbolGeneratorDialog.scss';
import { ObjectParametersSelector } from './ObjectParametersSelector.component';
import { SymbolGeneratorBllService } from './SymbolGeneratorService/SymbolGeneratorBllService';
import { TSymbolsSettings } from './SymbolGenerator.types';
import { Icon } from '../../../../../../../UIKit/components/Icon/Icon.component';
import { getSymbolAmountOfBorders } from './utils/defaultSymbolSettings';
import { SymbolSettingsTypeEnum } from '../../../../../../../../serverapi/api';

type TFrontMenuItemProps = {
    currentStyles: TSymbolsSettings;
    setCurrentStyles: React.Dispatch<React.SetStateAction<TSymbolsSettings>>;
    symbolType: SymbolSettingsTypeEnum;
    src: {
        content: string;
        id: string;
        node: Node;
        viewBox: string;
    };
    bordersVisibility: 'strokeWidthVisibility' | 'roundingVisibility';
    bordersType: 'strokeWidth' | 'rounding';
    onlyInteger?: boolean;
    maxValue: number;
};

export const FrontMenuItem = ({
    currentStyles,
    symbolType,
    setCurrentStyles,
    src,
    bordersVisibility,
    bordersType,
    onlyInteger,
    maxValue,
}: TFrontMenuItemProps) => {
    const borders = currentStyles?.[symbolType]?.borders;
    const allBordersAreEqual = borders?.every(
        (el) => currentStyles?.[symbolType]?.borders?.[0]?.[bordersType] === el?.[bordersType],
    );
    const allZero = borders?.every((b) => b?.[bordersType] === 0);
    const objectBorderActive = currentStyles?.[symbolType]?.symbolSettings?.objectBorderActive;
    const [allBorders, setAllBorders] = React.useState({
        RECTANGLE: {
            value: 0,
        },
        RECTANGLE_FIXED_ICON: {
            value: 0,
        },
        HEXAGON: {
            value: 0,
        },
        CIRCLE: {
            value: 0,
        },
        RHOMBUS: {
            value: 0,
        },
    });

    const setAllbordersMenuValue = (val) => {
        setAllBorders((prev) => {
            return {
                ...prev,
                [symbolType]: {
                    value: val,
                },
            };
        });
    };

    useEffect(() => {
        if (allBordersAreEqual || (allBorders[symbolType]?.value !== 0 && !objectBorderActive)) {
            setAllbordersMenuValue(currentStyles?.[symbolType]?.borders?.[0]?.[bordersType]);
        }
    }, [symbolType, objectBorderActive]);

    useEffect(() => {
        if (allBordersAreEqual) {
            setAllbordersMenuValue(currentStyles?.[symbolType]?.borders?.[0]?.[bordersType]);
        }
    }, [allBordersAreEqual]);

    const onClickItem = () => {
        if (!allZero && allBorders[symbolType]?.value !== 0) {
            setAllbordersMenuValue(1);
            borders?.forEach((_, i) => {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            [bordersType]: allBorders[symbolType]?.value,
                            [bordersVisibility]: true,
                        },
                        'borders',
                        symbolType,
                        i,
                    ),
                );
            });
        }
        if (allBordersAreEqual && !allZero) {
            setAllbordersMenuValue(0);
            borders?.forEach((_, i) => {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            [bordersType]: 0,
                            [bordersVisibility]: false,
                        },
                        'borders',
                        symbolType,
                        i,
                    ),
                );
            });
        } else if (allBorders[symbolType]?.value === 0) {
            setAllbordersMenuValue(1);
            borders?.forEach((_, i) => {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            [bordersType]: 1,
                            [bordersVisibility]: true,
                        },
                        'borders',
                        symbolType,
                        i,
                    ),
                );
            });
        }
    };

    const onChangeParametersSelector = (val: number) => {
        setAllbordersMenuValue(val);
        borders?.forEach((_, i) => {
            setCurrentStyles((prev) =>
                SymbolGeneratorBllService.changeBorderStyle(
                    prev,
                    {
                        [bordersType]: val,
                    },
                    'borders',
                    symbolType,
                    i,
                ),
            );
        });
        if (val > 0) {
            borders?.forEach((_, i) => {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            [bordersVisibility]: true,
                        },
                        'borders',
                        symbolType,
                        i,
                    ),
                );
            });
        } else {
            borders?.forEach((_, i) => {
                setCurrentStyles((prev) =>
                    SymbolGeneratorBllService.changeBorderStyle(
                        prev,
                        {
                            [bordersType]: 0,
                            [bordersVisibility]: false,
                        },
                        'borders',
                        symbolType,
                        i,
                    ),
                );
            });
        }
    };

    const symbolAmountOfBorders = getSymbolAmountOfBorders(symbolType);
    const nonStrokeWidthAndMultipleBorders = bordersType !== 'strokeWidth' && symbolAmountOfBorders !== 1;
    const paramSelectorValue =
        symbolAmountOfBorders === 1 && bordersType === 'strokeWidth'
            ? currentStyles?.[symbolType]?.borders?.[0].strokeWidth
            : allBorders?.[symbolType]?.value;
    const isSingleRoundBorder = symbolAmountOfBorders === 1 && bordersType === 'rounding';

    return (
        <div className={theme.frontMenuItemWrapper}>
            <div
                className={
                    nonStrokeWidthAndMultipleBorders
                        ? theme.frontMenuItemWrapper
                        : theme.frontMenuItemWrapperNotAvailable
                }
            >
                <Menu>
                    <Menu.Item
                        className={allBordersAreEqual && !allZero && objectBorderActive ? theme.selected : ''}
                        onClick={onClickItem}
                        disabled={!objectBorderActive || isSingleRoundBorder}
                    >
                        <div className={theme.bordersSettingWrapper}>
                            <Icon className={theme.svgIcon} spriteSymbol={src} />
                            <ObjectParametersSelector
                                editingAllowed={objectBorderActive && !isSingleRoundBorder}
                                value={paramSelectorValue}
                                onChange={onChangeParametersSelector}
                                maxValue={maxValue}
                                onlyInteger={onlyInteger}
                            />
                        </div>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    moveLevelUp: {
        id: 'MoveLayerDropdown.moveLevelUp',
        defaultMessage: 'Переместить вперед',
    },
    moveLevelTop: {
        id: 'MoveLayerDropdown.moveLevelTop',
        defaultMessage: 'На передний план',
    },
    moveLevelDown: {
        id: 'MoveLayerDropdown.moveLevelDown',
        defaultMessage: 'Переместить назад',
    },
    moveLevelBottom: {
        id: 'MoveLayerDropdown.moveLevelBottom',
        defaultMessage: 'На задний план',
    },
    selectElementInEditMode: {
        id: 'MoveLayerDropdown.selectElementInEditMode',
        defaultMessage: 'Выберите элемент в режиме редактирования',
    },
    selectElementOnCanvas: {
        id: 'MoveLayerDropdown.selectElementOnCanvas',
        defaultMessage: 'Выберите элемент на холсте',
    },
});

import { call, put, select, takeEvery } from 'redux-saga/effects';
import { FileFolderType, TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { FILE_DOWNLOAD, FILE_OPEN } from '../actionsTypes/uploader.actionTypes';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { FileNodeDTO, Node } from '../serverapi/api';
import { TreeSelectors } from '../selectors/tree.selectors';
import { v4 as uuid } from 'uuid';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { nodeService } from '../services/NodeService';
import { treeItemAdd } from '../actions/tree.actions';
import { TreeNode } from '../models/tree.types';
import { fileDownload } from '../actions/file.actions';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { BrowserDaoService } from '../services/dao/BrowserDaoService';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import {
    IWorkspaceTabFileEditorParams,
    IWorkspaceTabImageFileViewerParams,
    IWorkspaceTabTxtFileEditorParams,
    TWorkspaceTab,
} from '@/models/tab.types';
import { EditorMode } from '@/models/editorMode';
import {
    workspaceActivateTab,
    workspaceAddTab,
    workspaceRemoveTab,
    workspaceRemoveTabRequest,
} from '@/actions/tabs.actions';
import { IMAGE_VIEWER_EXTENSIONS, TXT_EDITOR_EXTENSIONS } from '@/modules/FileEditor/FileEditor.types';
import { getContentLoadingPageTab } from './utils';
import { FileDaoService } from '@/services/dao/FileDaoService';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { isUndefined } from 'is-what';
import { FILE_DOWNLOAD_PATH } from '@/services/api/api-bundle';
import { replaceLastSlash } from '@/utils/url.utils';

const ALLOWABLE_FILE_PARENT = [TreeItemType.FileFolder, TreeItemType.Repository, TreeItemType.Folder];

function* handleTreeItemsFileAdd({ payload: { nodeId, type, action, treeName } }: TTreeItemContextMenuAction) {
    if (ALLOWABLE_FILE_PARENT.includes(type)) {
        if (action === TreeItemContextMenuAction.ADD_FILE) {
            yield put(
                openDialog(DialogType.FILE_UPLOAD_DIALOG, { nodeId: { ...nodeId, id: uuid() }, parentNodeId: nodeId }),
            );
        }
    }
    if (type === TreeItemType.FileFolder) {
        if (action === TreeItemContextMenuAction.ADD_FILE_FOLDER) {
            yield put(
                openDialog(DialogType.FOLDER_DIALOG, {
                    type,
                    parentNodeId: nodeId,
                    extraProperties: { fileFolderType: FileFolderType.NONE },
                    treeName,
                }),
            );
        }
    }
    if (type === TreeItemType.File) {
        if (action === TreeItemContextMenuAction.REPLACE) {
            // загрузка через контекстное меню дерева так что можно брать ноду из стора
            const file: Node = yield select(TreeSelectors.itemById(nodeId));
            yield put(openDialog(DialogType.FILE_UPLOAD_DIALOG, { parentNodeId: file.parentNodeId, nodeId }));
        }
        if (action === TreeItemContextMenuAction.DOWNLOAD_FILE) {
            yield put(fileDownload(nodeId));
        }
    }
}

function* handleFileDownload({ payload: { nodeId } }: TTreeItemContextMenuAction) {
    let fileNode: Node | undefined = yield select(TreeSelectors.itemById(nodeId));

    try {
        if (!fileNode) {
            fileNode = yield nodeService().loadNodeFromServer(nodeId);
            yield put(treeItemAdd({ ...fileNode } as TreeNode));
        }
        if (!fileNode) {
            LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);

            return;
        }
        const file: Blob = yield FileDaoService.downloadFile(nodeId);
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
        BrowserDaoService.downloadFile(file, { defaultPath: fileNode.name || nodeId.id });
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

function* handleFileOpen({ payload: { nodeId } }: TTreeItemContextMenuAction) {
    try {
        const fileEditorExtensions = IMAGE_VIEWER_EXTENSIONS.concat(TXT_EDITOR_EXTENSIONS);
        let fileNode: Node | undefined = yield select(TreeSelectors.itemById(nodeId));

        if (!fileNode) {
            fileNode = yield nodeService().loadNodeFromServer(nodeId);
            yield put(treeItemAdd({ ...fileNode } as TreeNode));
        }
        if (!fileNode) {
            LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);

            return;
        }

        const fileNodeDTO: FileNodeDTO = fileNode as FileNodeDTO;
        const { extension } = fileNodeDTO;

        if (fileEditorExtensions.includes(extension)) {
            yield openFileTab(fileNodeDTO);
        } else {
            yield put(fileDownload(nodeId));
        }
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

function* openFileTab(fileNodeDTO: FileNodeDTO) {
    const { extension, name, nodeId } = fileNodeDTO;
    const { serverId } = nodeId;
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const defaultParams: IWorkspaceTabFileEditorParams = {
        serverId,
        closable: true,
        zoomLevel: 100,
        extension,
    } as IWorkspaceTabFileEditorParams;
    const schema = yield select(TabsSelectors.byId(nodeId));
    if (!isUndefined(schema)) {
        yield put(workspaceActivateTab(schema));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);

        return;
    }
    const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);

    try {
        yield put(workspaceAddTab(contentLoadingPageTab));

        let fileTab: TWorkspaceTab;

        if (IMAGE_VIEWER_EXTENSIONS.includes(extension)) {
            const src: string = `${replaceLastSlash(server.url)}/${FILE_DOWNLOAD_PATH}/${nodeId.repositoryId}/${
                nodeId.id
            }`;
            const params: IWorkspaceTabImageFileViewerParams = {
                ...defaultParams,
                src,
            } as IWorkspaceTabImageFileViewerParams;
            fileTab = {
                title: name,
                nodeId,
                type: WorkSpaceTabTypes.IMAGE_FILE_VIEWER,
                mode: EditorMode.Read,
                params,
            } as TWorkspaceTab;
        } else {
            const file: Blob = yield FileDaoService.downloadFile(nodeId);
            const stringData: string = yield call(() => file.text());
            const params: IWorkspaceTabTxtFileEditorParams = {
                ...defaultParams,
                stringData,
            } as IWorkspaceTabTxtFileEditorParams;
            fileTab = {
                title: name,
                nodeId,
                type: WorkSpaceTabTypes.TXT_FILE_EDITOR,
                mode: EditorMode.Read,
                params,
            } as TWorkspaceTab;
        }

        yield put(workspaceRemoveTab(contentLoadingPageTab));
        yield put(workspaceAddTab(fileTab));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        yield put(workspaceRemoveTabRequest(contentLoadingPageTab));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

export function* fileSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleTreeItemsFileAdd);
    yield takeEvery(FILE_DOWNLOAD, handleFileDownload);
    yield takeEvery(FILE_OPEN, handleFileOpen);
}

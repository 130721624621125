import { getStore } from '../../../store';
import { put } from 'redux-saga/effects';

import {
    nodePropertiesUpdate,
    treeItemAdd,
    treeItemDelete,
    treeItemUpdate
} from '../../../actions/tree.actions';
import { TreeNode, TTreeEntityState } from '../../../models/tree.types';
import {
    setServerIdToNodeInterface,
    setServerIdToNodeOriginal
} from '../../../utils/nodeId.utils';
import {
    Node,
    TreeUpdateNotificationDTO,
    TreeUpdateNotificationDTOUserActionEnum
} from '../../../serverapi/api';
import { moveNodesInTree } from '../../../sagas/nodeMove.saga';
import { showNotification } from '../../../actions/notification.actions';
import { NotificationType } from '../../../models/notificationType';
import { v4 as uuid } from 'uuid';
import { WSService } from '../WSService';
import { LocalesService } from '@/services/LocalesService';

export class TreeSubscriptionService {

    private static acceptIncomingDBUpdate = (serverId: string) => (dto: TreeUpdateNotificationDTO) => {
        const changedNodeId = {...dto.nodeId, serverId};
        const userAction: TreeUpdateNotificationDTOUserActionEnum = dto.userAction;
        if (userAction === 'RENAME_TREE_NODE') {
            getStore().dispatch(treeItemUpdate({
                nodeId: changedNodeId,
                data: {
                    multilingualName: dto.object,
                    name: LocalesService.internationalStringToString(dto.object),
                }
            }));
        } else if (userAction === 'DELETE_TREE_NODE') {
            getStore().dispatch(treeItemDelete(changedNodeId));
        } else if (userAction === 'SAVE_TREE_NODE') {
            setServerIdToNodeOriginal(dto.object, serverId);
            getStore().dispatch(treeItemAdd(dto.object as TreeNode));
        } else if (userAction === 'CHANGE_TREE_NODE_PROPERTIES') {
            setServerIdToNodeOriginal(dto.object, serverId);
            getStore().dispatch(nodePropertiesUpdate(changedNodeId, dto.object.type, dto.object));
        } else if (userAction === 'MOVE_TREE_NODE') {
            const updatedNodes: Node[] = dto.object;
            updatedNodes.forEach((n) => setServerIdToNodeInterface(n, serverId));
            moveNodesInTree(updatedNodes, updatedNodes as TTreeEntityState[], changedNodeId);
        }
    }

    public static* subscribeToDatabaseUpdates(repositoryId: string, serverId: string) {
        const subscribe = WSService.client().subscribe(repositoryId, TreeSubscriptionService.acceptIncomingDBUpdate(serverId));
        if (subscribe) {
            yield put(showNotification({
                id: uuid(),
                type: NotificationType.SUBSCRIBE_DB_SUCCESS,
            }));
        }
    }

    public static* unsubscribeFromDatabaseUpdates(repositoryId: string) {
        WSService.client().unsubscribe(repositoryId);
        yield put(showNotification({
            id: uuid(),
            type: NotificationType.UNSUBSCRIBE_DB_SUCCESS,
        }));
    }
}

import { WidgetDTOTypeEnum } from '@/serverapi/api';
import { TDashboardWidgets } from './widgets/widget.types';

export type TDashboard = {
    cellSize: number;
    verticalCount: number;
    horizontalCount: number;
    widgets: TDashboardWidgets[];
    selectedWidgetId?: string;
};

export enum DashboardWidgetTypes {
    PieChart = 'PieChart',
    LineChart = 'LineChart',
    BarChart = 'BarChart',
    Summary = 'Summary',
    TextBlock = 'TextBlock',
}

export type TDashboardCellsCount = {
    verticalCount: number;
    horizontalCount: number;
};

export type TDashboardSymbolType = {
    id: number;
    name: string;
    type: WidgetDTOTypeEnum;
    icon: {
        content: string;
        id: string;
        node: Node;
        viewBox: string;
    };
};

import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { InternationalString, UITemplateDTOTabsTypeEnum, UITemplateTabDTO } from '@/serverapi/api';
import {
    UITemplateEditorChangeActiveTab,
    UITemplateEditorUpdateTemplate,
} from '@/modules/UITemplates/actions/UITemplateEditor.actions';
import theme from './TabsList.scss';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { useIntl } from 'react-intl';
import messages from './TabsList.messages';
import { v4 as uuid } from 'uuid';
import { FormInstance } from 'antd';
import { TSettingsPanelFormValues } from '../../CreateUITemplateTab.types';
import { Icon } from '@/modules/UIKit';
import closeIco from '@/resources/icons/ic-ribbon-close.svg';
import { UITemplateEditorSelectors } from '@/modules/UITemplates/selectors/UITemplateEditor.selectors';

type TabsListProps = {
    templateId: string;
    generalForm: FormInstance<TSettingsPanelFormValues>;
};

export const TabsList: FC<TabsListProps> = ({ templateId, generalForm }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const tabs: UITemplateTabDTO[] = useSelector(UITemplateEditorSelectors.tabs(templateId));
    const tabsType: UITemplateDTOTabsTypeEnum | undefined = useSelector(UITemplateEditorSelectors.tabsType(templateId));
    const activeTabId: string | undefined = useSelector(UITemplateEditorSelectors.activeTabId(templateId));

    const handleTabNameChange = (tabId: string, name: InternationalString) => {
        const updatedTabs = tabs.map((tab) => {
            if (tab.id === tabId) {
                return { ...tab, name };
            }

            return tab;
        });
        dispatch(UITemplateEditorUpdateTemplate(templateId, { tabs: updatedTabs }));
    };

    const handleAddTab = () => {
        const newTab: UITemplateTabDTO = { id: uuid(), name: {}, widgetsIds: [] };
        const updatedTabs = [...tabs, newTab];
        dispatch(UITemplateEditorUpdateTemplate(templateId, { tabs: updatedTabs }));
    };

    const handleDeleteTab = (tabId: string) => {
        let index = 0;
        const updatedTabs = tabs.filter(({ id }, i) => {
            if (tabId === id) {
                index = i;
            }
            return tabId !== id;
        });
        if (!updatedTabs.length) {
            dispatch(UITemplateEditorUpdateTemplate(templateId, { tabsType: undefined, tabs: undefined }));
        }
        dispatch(UITemplateEditorUpdateTemplate(templateId, { tabs: updatedTabs }));

        const newActiveTabIndex: number = Math.max(0, index - 1);
        if (activeTabId === tabId && updatedTabs[newActiveTabIndex]) {
            const newActiveTabId: string = updatedTabs[newActiveTabIndex].id;
            dispatch(UITemplateEditorChangeActiveTab(templateId, newActiveTabId));
        }
    };

    return (
        <div className={theme.tabsList}>
            {tabs.map(({ id, name }, index) => (
                <div key={id}>
                    <div className={theme.tabHeader}>
                        {intl.formatMessage(messages.tab, { number: index + 1 })}
                        <Icon spriteSymbol={closeIco} onClick={() => handleDeleteTab(id)} className={theme.icon} />
                    </div>
                    <MultiLangInputDialog
                        generalForm={generalForm}
                        mainInputName={id}
                        placeholder={intl.formatMessage(messages.tabNamePlaceholder)}
                        multiLangValue={name}
                        maxLength={150}
                        onChange={(name) => handleTabNameChange(id, name)}
                        label={intl.formatMessage(messages.name)}
                        required
                    />
                </div>
            ))}
            {tabsType && <Button onClick={handleAddTab}>{intl.formatMessage(messages.addTab)}</Button>}
        </div>
    );
};

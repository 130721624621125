import { Select } from '@/modules/UIKit/components/Select/Select.component';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from './CommonSettings.messages';
import theme from './CommonSettings.scss';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { reportChangeFillingType, reportClearSearchRequest } from '../../../actions/report.actions';
import { NodeId, ReportDataFillingTypeEnum, SearchRequest } from '@/serverapi/api';
import { ReportSelectors } from '../../../selectors/report.selectors';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { SearchDialogType, TDbSearchDialogProps } from '@/modules/Search/DbSearchDialog/DbSearchDialog.types';
import { openSearchInDialog } from '@/actions/search.actions';
import { generateCustomNodeId } from '@/utils/nodeId.utils';

type TCommonSettingsProps = {
    reportNodeId: NodeId;
};

enum FillingType {
    MANUAL = 'MANUAL',
    AUTO = 'AUTO',
}

export const CommonSettings: FC<TCommonSettingsProps> = ({ reportNodeId }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const searchNodeId: NodeId = generateCustomNodeId(reportNodeId, SearchDialogType.Report);

    const fillingType: ReportDataFillingTypeEnum = useSelector(ReportSelectors.fillingType(reportNodeId));
    const searchRequests: SearchRequest[] = useSelector(ReportSelectors.searchRequests(reportNodeId));

    const selectReportType = (fillingType: ReportDataFillingTypeEnum) => {
        dispatch(reportChangeFillingType(reportNodeId, fillingType));
    };

    const searchRequestClick = () => {
        const dialogProps: TDbSearchDialogProps = {
            type: SearchDialogType.Report,
            nodeId: reportNodeId,
        };
        dispatch(openDialog(DialogType.DB_SEARCH_DIALOG, dialogProps));
        dispatch(openSearchInDialog(searchNodeId, reportNodeId, searchRequests));
    };

    const cleatSearchRequest = () => {
        dispatch(reportClearSearchRequest(reportNodeId));
    };

    const openConfirmationDialog = () => {
        const props = {
            question: intl.formatMessage(messages.clearMessage),
            OKButtonText: intl.formatMessage(messages.onClear),
            onSubmit: cleatSearchRequest,
        };
        dispatch(openDialog(DialogType.CONFIRMATION, props));
    };

    return (
        <>
            <div className={theme.group}>
                <Select
                    label={intl.formatMessage(messages.fillingTypeTitle)}
                    wrapperClassName={theme.select}
                    onChange={selectReportType}
                    value={intl.formatMessage(messages[fillingType])}
                >
                    <Select.Option
                        value={FillingType.MANUAL}
                        label={intl.formatMessage(messages[FillingType.MANUAL])}
                    />
                    <Select.Option value={FillingType.AUTO} label={intl.formatMessage(messages[FillingType.AUTO])} />
                </Select>
            </div>
            {fillingType === 'AUTO' && (
                <>
                    <div className={theme.container}>
                        <Button onClick={searchRequestClick}>{intl.formatMessage(messages.searchRequests)}</Button>
                    </div>
                    <div className={theme.container}>
                        <Button disabled={searchRequests.length === 0} onClick={openConfirmationDialog}>
                            {intl.formatMessage(messages.clear)}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

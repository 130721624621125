import React, { FC } from 'react';
import theme from './DbSearch.component.scss';
import { DbSearchListItem } from '../DbSearchListItem/DbSearchListItem.component';
import { List } from 'antd';
import { TSearchDataListItem } from '@/reducers/search.reducer.types';
import { NodeId } from '@/serverapi/api';

type TDbSearchListProps = {
    searchResult: TSearchDataListItem[];
    searchNodeId: NodeId;
    isLoading: boolean;
};

export const DbSearchList: FC<TDbSearchListProps> = ({ searchResult, isLoading, searchNodeId }) => {
    return (
        <div className={theme.listContainer}>
            <List
                itemLayout="vertical"
                dataSource={searchResult}
                loading={isLoading}
                renderItem={(item, index) => (
                    <List.Item>
                        <DbSearchListItem
                            index={index + 1}
                            path={item.path}
                            type={item.type}
                            multilingualName={item.multilingualName}
                            nodeId={item.nodeId}
                            elementType={item.elementType}
                            itemTypeName={item.itemTypeName}
                            deleted={item.deleted}
                            searchNodeId={searchNodeId}
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};

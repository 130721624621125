import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icDel from '@/resources/icons/icBin.svg';
import { handleDeleteSelectedCellsFromActiveGraphAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';

export const DeleteButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getDeleteBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getDeleteBtnTooltip);

    return (
        <ToolbarButton
            icon={icDel}
            size="small"
            dataTest="general-toolbar_delete-btn"
            disabled={disabled}
            onClick={() => dispatch(handleDeleteSelectedCellsFromActiveGraphAction())}
            tooltip={tooltip}
        />
    );
};

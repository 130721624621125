import React from 'react';
import { Popover } from 'antd';
import theme from './ToolbarButton.scss';
import classnames from 'classnames';
import { TToolbarButton } from '../ToolbarElements.types';
import { NORMAL_TOOLBAR_BUTTON_SIZE, SMALL_TOOLBAR_BUTTON_SIZE } from './ToolbarButton.constants';
import { TOOLTIP_RC_ID } from '../../TooltipRC/TooltipRC.constants';

const DEFAULT_ICON_SIZE = 16;

const GREY_COLOR_MATRIX = '0.4 0.4 0.4 0 0 0.4 0.4 0.4 0 0 0.4 0.4 0.4 0 0 0 0 0 1 0';

export const ToolbarButton = (props: TToolbarButton) => {
    const { icon, onClick, dataTest, tooltip, disabled, key, isActive, size = 'normal', overlay } = props;
    const buttonClass: string = classnames(theme.button, {
        [theme.active]: isActive,
        [theme.disabled]: disabled,
    });
    const isSmallButton: boolean = size === 'small';
    const buttonCSSStyle: React.CSSProperties = {
        width: isSmallButton ? `${SMALL_TOOLBAR_BUTTON_SIZE}px` : `${NORMAL_TOOLBAR_BUTTON_SIZE}px`,
        height: isSmallButton ? `${SMALL_TOOLBAR_BUTTON_SIZE}px` : `${NORMAL_TOOLBAR_BUTTON_SIZE}px`,
    };

    return (
        <Popover content={overlay} trigger="click" placement="bottom">
            <button
                type={'button'}
                key={key}
                onClick={!disabled ? onClick : undefined}
                data-test={dataTest}
                className={buttonClass}
                onMouseDown={(e) => {
                    e.preventDefault();
                }}
                style={buttonCSSStyle}
                data-tooltip-id={TOOLTIP_RC_ID}
                data-tooltip-content={tooltip}
                data-tooltip-max-width={'350px'}
            >
                {icon ? (
                    <svg
                        width={icon?.width || DEFAULT_ICON_SIZE}
                        height={icon?.height || DEFAULT_ICON_SIZE}
                        viewBox={icon.viewBox}
                    >
                        <defs>
                            <filter id="grayscale">
                                <feColorMatrix type="matrix" values={GREY_COLOR_MATRIX} />
                            </filter>
                        </defs>
                        <use xlinkHref={`#${icon.id}`} filter={disabled ? 'url(#grayscale)' : undefined} />
                    </svg>
                ) : (
                    ''
                )}
            </button>
        </Popover>
    );
};

import { NodeId } from '../serverapi/api';
import { CustomMap } from '../utils/map';

export enum FormatParamValues {
    fontSizes = 'fontSizes',
    lineHeights = 'lineHeights',
}

export type TFormatParams = Partial<Record<FormatParamValues, string[]>>;

export type TAddedFormatParamsReducerState = CustomMap<NodeId, TFormatParams>;

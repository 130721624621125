import { RefObject, useEffect, useState } from 'react';
import { calcTextWidth } from '@/utils/textWidth.utils';

type TEllipsisTextProps = {
    text: string;
    font: string;
    lanesCount: number;
    ref: RefObject<HTMLDivElement>;
};

export const useEllipsisText = ({ text, font, lanesCount, ref }: TEllipsisTextProps) => {
    const [truncedText, setTruncedText] = useState<string>(text);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        if (!ref.current || !text) return;

        const observer = new ResizeObserver((entries) => {
            const { width } = entries[0].contentRect;
            const containerWidth = Math.floor(width) - 5;

            const textWidth = Math.ceil(calcTextWidth(text, font));
            const overflow = Math.ceil(textWidth / lanesCount - containerWidth);
            const letterWidth = Math.ceil(textWidth / text.length);

            if (overflow > 0) {
                let newTextOverflow = 0;
                let sliceCount = Math.ceil(overflow / letterWidth);
                let newText = text;
                while (text.length > sliceCount && newTextOverflow >= 0) {
                    newText = text.slice(0, -sliceCount).trim() + '...';
                    const newTextWidth = Math.ceil(calcTextWidth(newText, font));
                    newTextOverflow = Math.ceil(newTextWidth / lanesCount - containerWidth);
                    sliceCount++;
                }
                setTruncedText(newText);
                setShowTooltip(true);
            } else {
                setTruncedText(text);
                setShowTooltip(false);
            }
        });

        observer.observe(ref.current);

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
                observer.disconnect();
            }
        };
    }, [text, lanesCount, ref.current]);

    return { truncedText, showTooltip };
};

import React from 'react';
import addBtnIcon from '@/resources/icons/icAdd.svg';
import theme from './AddNewValueButton.scss';
import { Rule } from 'antd/es/form';
import { useIntl } from 'react-intl';
import messages from './AddNewValueButton.messages';
import { Button } from '../../Button/Button.component';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useDispatch } from 'react-redux';

type TAddNewValueButtonProps = {
    onAddClicked: (value: string) => void;
    title: string;
    validationRules: Rule[] | undefined;
};

export const AddNewValueButton = (props: TAddNewValueButtonProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { onAddClicked, title, validationRules } = props;
    const handleClick = () => {
        dispatch(
            openDialog(DialogType.ADD_NEW_VALUE_DIALOG, {
                title: title,
                handleOk: onAddClicked,
                validationRules: validationRules,
            }),
        );
    };

    return (
        <div className={theme.btnContainer}>
            <Button
                dataTest={'user-managment_add-user-button'}
                onClick={handleClick}
                icon={addBtnIcon}
                size="small"
                tooltip={intl.formatMessage(messages.add)}
            />
        </div>
    );
};

import { Form, FormInstance, Input, InputNumber, InputRef, Menu } from 'antd';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { KanbanCardsViewInColumn, KanbanSizeType } from '../../../../../../../models/kanban.types';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import theme from './ColumnsSettingsTab.scss';
import icArrowUp from '../../../../../../../resources/icons/ic-arrow-full-up.svg';
import icArrowDown from '../../../../../../../resources/icons/ic-arrow-full-down.svg';
import icDelete from '../../../../../../../resources/icons/ic-delete-blue.svg';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
// import isColumnView from '../../../../../../../resources/icons/ic-column-view.svg';
// import isRowView from '../../../../../../../resources/icons/ic-row-view.svg';

import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { FontColorPickerButton } from '../../../../../../UIKit/components/FontSettings/FontSettings.component';
import {
    addKanbanModelTypeColumn,
    changeKanbanModelTypeColumnColor,
    changeKanbanModelTypeColumnHasCardsLimitInColumn,
    changeKanbanModelTypeColumnHasHeaderIcon,
    changeKanbanModelTypeColumnHeaderColor,
    changeKanbanModelTypeColumnHeaderIcon,
    changeKanbanModelTypeColumnId,
    changeKanbanModelTypeColumnMaxCardsInColumn,
    changeKanbanModelTypeColumnName,
    changeKanbanModelTypeColumnsSettings,
    changeKanbanModelTypeColumnWidthType,
    changeKanbanModelTypeColumnWidthValue,
    deleteKanbanModelTypeColumn,
    moveKanbanModelTypeColumn,
} from '../../../../../../../actions/workspaceTab/editKanbanModelTypeWorkspaceTab.actions';
import {
    InternationalString,
    KanbanBoardColumnsSettingsWidthTypeEnum,
    PresetImage,
    KanbanBoardColumnsSettings,
    KanbanBoardSingleColumnSettings,
    KanbanBoardType,
} from '../../../../../../../serverapi/api';
import { debounce } from 'lodash-es';
import { AttributeImageSelector } from '../../../../../../FloatingAttributes/components/AttributesEditor/AttributeStyleEditor/AttributeImageStyleEditor/AttributeImageSelector.component';
import { PresetImageSelectors } from '../../../../../../../selectors/presetSettings/presetImage.selectors';
import messages from '../KanbanModelType.messages';
import { useIntl } from 'react-intl';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { LocalesService } from '@/services/LocalesService';

type TColumnsSettingsTabProps = {
    modelType: KanbanBoardType;
    serverId: string;
    presetId: string;
    setIsSaveDisabled: (isDisabled: boolean) => void;
    isSaveDisabled: boolean;
};

const ColumnsSettingsTab = (props: TColumnsSettingsTabProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);
    const formRef = useRef<FormInstance>(null);
    const inputIdReference = useRef<InputRef>(null);
    const { modelType, serverId, presetId, setIsSaveDisabled, isSaveDisabled } = props;
    const columnsSettings = modelType.columnsSettings;
    const columns = modelType.columnsSettings.columns;
    const modelTypeId = modelType.id;
    const [currentColumn, setCurrentColumn] = useState<KanbanBoardSingleColumnSettings | undefined>(columns?.[0]);
    const presetImages: PresetImage[] = useSelector(PresetImageSelectors.listAllByPreset(serverId, presetId));

    const onChangeColumnsGeneralSettings = (property: string, value: boolean) => {
        const newColumnSettings = { ...columnsSettings, [property]: value };

        dispatch(
            changeKanbanModelTypeColumnsSettings({
                presetId,
                modelTypeId,
                columnsSettings: newColumnSettings,
            }),
        );
    };

    const onChangeHasHeaderIcon = (status: boolean) => {
        if (!currentColumn || !currentColumn.innerId) return;

        dispatch(
            changeKanbanModelTypeColumnHasHeaderIcon({
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
                hasHeaderIcon: status,
            }),
        );
    };

    const onChangeHasCardsLimitInColumn = (status: boolean) => {
        if (!currentColumn || !currentColumn.innerId) return;

        dispatch(
            changeKanbanModelTypeColumnHasCardsLimitInColumn({
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
                hasCardsLimitInColumn: status,
            }),
        );
    };

    // const onChangeCardsViewInColumn = (e) => {
    //     const id = e.target.id;
    //     const newType: KanbanBoardSingleColumnSettingsCardsViewInColumnEnum = e.target.value;

    //     const newColumns = columns.map((col) => {
    //         if (col.innerId === currentColumn?.innerId) {
    //             const newCol = { ...col };
    //             newCol.cardsViewInColumn = newType;
    //             if (newType === KanbanCardsViewInColumn.ROW) {
    //                 newCol.minCardWidthForRowView = +(document.querySelector(`[data-radio=${id}]`) as HTMLInputElement)?.value!;
    //             } else {
    //                 delete newCol.minCardWidthForRowView;
    //             }

    //             return newCol;
    //         }

    //         return col;
    //     });

    //     dispatch(
    //         changeKanbanModelTypeColumnCardsViewInColumn({
    //             presetId,
    //             modelTypeId,
    //             columns: newColumns,
    //         }),
    //     );
    // };

    // const onChangeColumnMinCardWidthForRowView = (val: number) => {
    //     dispatch(
    //         changeKanbanModelTypeColumnMinCardWidthForRowView({
    //             width: val,
    //             presetId,
    //             modelTypeId,
    //             columnId: currentColumn?.innerId!,
    //         }),
    //     );
    // };

    const onChangeColumnsGeneralWidthType = (value: KanbanSizeType) => {
        const newColumnSettings: KanbanBoardColumnsSettings = {
            columnsControlAllowed: columnsSettings.columnsControlAllowed,
            columnsTitleHidden: columnsSettings.columnsTitleHidden,
            sameColumnsWidth: columnsSettings.sameColumnsWidth,
            columns: [...columnsSettings.columns],
            widthType: value,
        };

        dispatch(
            changeKanbanModelTypeColumnsSettings({
                presetId,
                modelTypeId,
                columnsSettings: newColumnSettings,
            }),
        );
    };

    const onChangeColumnWidthType = (value: KanbanBoardColumnsSettingsWidthTypeEnum) => {
        if (!currentColumn || !currentColumn.innerId) return;

        dispatch(
            changeKanbanModelTypeColumnWidthType({
                widthValue: currentColumn.widthValue,
                widthType: value,
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
            }),
        );
    };

    const onChangeColumnWidthValue = (val: number) => {
        if (!currentColumn || !currentColumn.innerId) return;

        dispatch(
            changeKanbanModelTypeColumnWidthValue({
                widthValue: val,
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
            }),
        );
    };

    const onChangeColumnsGeneralWidthValue = (value: number) => {
        const newColumnSettings = { ...columnsSettings, widthValue: value };
        dispatch(
            changeKanbanModelTypeColumnsSettings({
                presetId,
                modelTypeId,
                columnsSettings: newColumnSettings,
            }),
        );
    };

    const getNewColumnId = (ids: string[]): string => {
        let newIdNumber = ids.length + 1;
        let isUnique = false;
        while (!isUnique) {
            if (ids.some((id) => id === `column${newIdNumber}`)) {
                newIdNumber++;
            } else {
                isUnique = true;
            }
        }

        return `column${newIdNumber}`;
    };

    const onColumnAdd = () => {
        const newColumnId = getNewColumnId(columns.map((col) => col.id));

        const newColumn: KanbanBoardSingleColumnSettings = {
            multilingualName: { [currentLocale]: intl.formatMessage(messages.column) },
            id: newColumnId,
            innerId: uuid(),
            widthValue: 20,
            widthType: KanbanSizeType.RELATIVE,
            headerColor: '#95D9A8',
            columnColor: 'gray',
            hasHeaderIcon: false,
            cardsViewInColumn: KanbanCardsViewInColumn.COLUMN,
            hasCardsLimitInColumn: false,
        };

        const index = columns.findIndex((col) => col.innerId === currentColumn?.innerId);

        setCurrentColumn(newColumn);
        dispatch(
            addKanbanModelTypeColumn({
                presetId,
                modelTypeId,
                column: newColumn,
                index,
            }),
        );
    };

    const onColumnDelete = () => {
        if (!currentColumn || !currentColumn.innerId) return;

        const indexOfCurrentColumn = columns.findIndex((col) => col.innerId === currentColumn.innerId);
        const indexOfNewCurrentColumn = indexOfCurrentColumn === 0 ? 1 : 0;
        if (isSaveDisabled) {
            setIsSaveDisabled(false);
        }
        setCurrentColumn(columns[indexOfNewCurrentColumn]);
        dispatch(
            deleteKanbanModelTypeColumn({
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
            }),
        );
    };

    const onMoveColumn = (isDirectionUp: boolean): void => {
        if (!currentColumn || !currentColumn.innerId) return;

        dispatch(
            moveKanbanModelTypeColumn({
                presetId,
                columnId: currentColumn.innerId,
                modelTypeId,
                isDirectionUp,
            }),
        );
    };

    const onChangeColumnName = (multilingualName: InternationalString) => {
        if (!currentColumn || !currentColumn.innerId) return;

        const isValidId = currentColumn.id.length && columns.length === new Set(columns.map(({ id }) => id)).size;
        if (!multilingualName[currentLocale]?.length && !isSaveDisabled) {
            setIsSaveDisabled(true);
        } else if (isSaveDisabled && isValidId) {
            setIsSaveDisabled(false);
        }
        dispatch(
            changeKanbanModelTypeColumnName({
                multilingualName,
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
            }),
        );
    };

    const changeIdHandler = (e) => {
        if (!currentColumn || !currentColumn.innerId) return;

        const indexOfCurrentColumn = columns.findIndex((col) => col.innerId === currentColumn.innerId);
        const newId: string = e.target.value;
        const isValidId = newId.length && !columns.some(({ id }) => id === newId);
        if (!isValidId && !isSaveDisabled) {
            setIsSaveDisabled(true);
        } else if (isSaveDisabled && currentColumn.multilingualName[currentLocale]?.length && isValidId) {
            setIsSaveDisabled(false);
        }
        dispatch(
            changeKanbanModelTypeColumnId({
                newColumnId: newId,
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
            }),
        );
        setCurrentColumn({ ...columns[indexOfCurrentColumn], id: e.target.value });
        inputIdReference?.current?.focus();
    };

    const debouncedChangeIdHanlder = debounce(changeIdHandler, 250);

    const onChangeColumnId = (e) => {
        e.persist();
        debouncedChangeIdHanlder(e);
    };

    const onChangeColumnHeaderColor = (headerColor: string) => {
        if (!currentColumn || !currentColumn.innerId) return;

        dispatch(
            changeKanbanModelTypeColumnHeaderColor({
                headerColor,
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
            }),
        );
    };

    const onChangeColumnColor = (columnColor: string) => {
        if (!currentColumn || !currentColumn.innerId) return;

        dispatch(
            changeKanbanModelTypeColumnColor({
                columnColor,
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
            }),
        );
    };

    const onChangeImageId = (imageId: string) => {
        if (!currentColumn || !currentColumn.innerId) return;

        dispatch(
            changeKanbanModelTypeColumnHeaderIcon({
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
                headerIcon: imageId,
            }),
        );
    };

    const onChangeMaxCardsInColumn = (val: number) => {
        if (!currentColumn || !currentColumn.innerId) return;

        dispatch(
            changeKanbanModelTypeColumnMaxCardsInColumn({
                presetId,
                modelTypeId,
                columnId: currentColumn.innerId,
                maxCardsCountInColumn: val,
            }),
        );
    };

    const onSelectColumnsMenu = (val) => {
        if (isSaveDisabled) return;
        const selectedColumn = columns.find((col) => col.innerId === val.key);
        setCurrentColumn(selectedColumn);
    };

    const columnIdValidator = (_, value: string) => {
        if (!columns.some(({ id }) => id === value)) {
            return Promise.resolve();
        }
        return Promise.reject();
    };

    const absoluteOptionInputDisabled =
        !columnsSettings.sameColumnsWidth || columnsSettings.widthType === KanbanSizeType.RELATIVE;

    const generalSettingsAbsoluteOption = (
        <div className={theme.radioOption}>
            <span className={theme.radioOptionLabel}>{intl.formatMessage(messages.fixedColumnWidth)}</span>
            <InputNumber
                min={1}
                max={500}
                disabled={absoluteOptionInputDisabled}
                defaultValue={columnsSettings.widthValue || 200}
                onChange={onChangeColumnsGeneralWidthValue}
                className={absoluteOptionInputDisabled ? theme.inputDisabled : theme.input}
            />
        </div>
    );

    const generalSettingsOptions: TRadioOption<KanbanSizeType>[] = [
        {
            title: intl.formatMessage(messages.automaticWindowWidthSize),
            value: KanbanSizeType.RELATIVE,
        },
        {
            title: generalSettingsAbsoluteOption,
            value: KanbanSizeType.ABSOLUTE,
        },
    ];

    const getColumnWidthAdaptive = (column: KanbanBoardSingleColumnSettings) => {
        const inputDisabled = column.widthType === KanbanSizeType.ABSOLUTE || columnsSettings.sameColumnsWidth;

        return (
            <div className={theme.radioOption}>
                <span className={theme.radioOptionLabel}>{intl.formatMessage(messages.columnWidthAdaptive)} (%)</span>
                <InputNumber
                    min={1}
                    max={100}
                    disabled={inputDisabled}
                    defaultValue={column.widthType === KanbanSizeType.RELATIVE ? column.widthValue : 20}
                    onChange={onChangeColumnWidthValue}
                    className={inputDisabled ? theme.inputDisabled : theme.input}
                />
            </div>
        );
    };

    const getColumnWidthFixed = (column: KanbanBoardSingleColumnSettings) => {
        const inputDisabled = column.widthType === KanbanSizeType.RELATIVE || columnsSettings.sameColumnsWidth;
        return (
        <div className={theme.radioOption}>
            <span className={theme.radioOptionLabel}>{intl.formatMessage(messages.columnWidthFixed)} (px)</span>
            <InputNumber
                min={1}
                max={500}
                disabled={inputDisabled}
                defaultValue={column.widthType === KanbanSizeType.ABSOLUTE ? column.widthValue : 200}
                onChange={onChangeColumnWidthValue}
                className={inputDisabled ? theme.inputDisabled : theme.input}
            />
        </div>
    )};

    const getColumnSettingsOptions = (column: KanbanBoardSingleColumnSettings): TRadioOption<KanbanSizeType>[] => [
        {
            title: getColumnWidthAdaptive(column),
            value: KanbanSizeType.RELATIVE,
        },
        {
            title: getColumnWidthFixed(column),
            value: KanbanSizeType.ABSOLUTE,
        },
    ];

    return (
        <div className={theme.columnSettingsWrapper}>
            <div className={theme.generalSettingWrapper}>
                <div className={theme.settingsTitle}>{intl.formatMessage(messages.generalColumnsSettings)}</div>
                <div className={theme.settingsContent}>
                    <Checkbox
                        status={columnsSettings.columnsTitleHidden}
                        onChange={(status: boolean) => {
                            onChangeColumnsGeneralSettings('columnsTitleHidden', status);
                        }}
                    >
                        {intl.formatMessage(messages.hideColumnHeaders)}
                    </Checkbox>
                    <div className={theme.sameColumnsWidthSettings}>
                        <Checkbox
                            status={columnsSettings.sameColumnsWidth}
                            onChange={(status: boolean) => {
                                onChangeColumnsGeneralSettings('sameColumnsWidth', status);
                            }}
                        >
                            {intl.formatMessage(messages.sameColumnsWidth)}
                        </Checkbox>

                        <RadioGroup<KanbanBoardColumnsSettingsWidthTypeEnum>
                            disabled={!columnsSettings.sameColumnsWidth}
                            value={columnsSettings.widthType}
                            onChange={onChangeColumnsGeneralWidthType}
                            options={generalSettingsOptions}
                        />
                    </div>
                </div>
            </div>

            <div className={theme.columnsListWrapper}>
                <div className={theme.settingsTitle}>{intl.formatMessage(messages.columnsList)}</div>

                <div className={theme.flexWrapper}>
                    <div className={theme.leftSideBar}>
                        <div className={theme.addColumn}>
                            <Button size="small" onClick={onColumnAdd}>
                                {intl.formatMessage(messages.addColumn)}
                            </Button>
                        </div>

                        <div className={theme.menu}>
                            <Menu
                                mode="inline"
                                onSelect={onSelectColumnsMenu}
                                selectedKeys={[currentColumn?.innerId || '']}
                            >
                                {columnsSettings.columns.map(({ innerId, multilingualName }) => (
                                    <Menu.Item key={innerId}>
                                        <span
                                            className={cx(
                                                isSaveDisabled &&
                                                    innerId !== currentColumn?.innerId &&
                                                    theme.menuDisabled,
                                            )}
                                        >
                                            {LocalesService.internationalStringToString(multilingualName)}
                                        </span>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </div>
                    </div>

                    <div className={theme.centerBar}>
                        {currentColumn
                            ? columns.map((col) => {
                                  return (
                                      <div
                                          key={col.innerId}
                                          className={cx(
                                              theme.columnTab,
                                              col.innerId !== currentColumn.innerId && theme.hidden,
                                          )}
                                      >
                                          <div className={theme.columnControls}>
                                              <Button
                                                  onClick={() => {
                                                      onMoveColumn(true);
                                                  }}
                                                  visualStyle="text"
                                                  icon={icArrowUp}
                                              />
                                              <Button
                                                  onClick={() => {
                                                      onMoveColumn(false);
                                                  }}
                                                  icon={icArrowDown}
                                                  visualStyle="text"
                                              />
                                              <Button onClick={onColumnDelete} visualStyle="text" icon={icDelete} />
                                          </div>

                                          <Form autoComplete="off" layout="vertical">
                                              <MultiLangInputDialog
                                                  multiLangValue={col.multilingualName}
                                                  onChange={onChangeColumnName}
                                                  label={intl.formatMessage(messages.columnName)}
                                                  mainInputName="columnMultilingualName"
                                                  mainInputClassName={theme.input}
                                                  generalForm={formRef.current!}
                                                  required
                                              />
                                              <Form.Item
                                                  className={theme.columnTextInput}
                                                  label={intl.formatMessage(messages.columnID)}
                                                  name="columnID"
                                                  initialValue={col.id}
                                                  rules={[
                                                      {
                                                          required: true,
                                                          whitespace: true,
                                                          message: intl.formatMessage(messages.requiredFieldError),
                                                      },
                                                      {
                                                          required: true,
                                                          message: intl.formatMessage(messages.sameColumnIDError),
                                                          validator: columnIdValidator,
                                                      },
                                                  ]}
                                              >
                                                  <Input
                                                      onChange={onChangeColumnId}
                                                      ref={inputIdReference}
                                                      className={theme.input}
                                                  />
                                              </Form.Item>
                                          </Form>
                                          <RadioGroup<KanbanBoardColumnsSettingsWidthTypeEnum>
                                              disabled={columnsSettings.sameColumnsWidth}
                                              value={col.widthType}
                                              onChange={onChangeColumnWidthType}
                                              options={getColumnSettingsOptions(col)}
                                              direction="Row"
                                              margin={18}
                                          />

                                          <div className={theme.columnHeader}>
                                              <div
                                                  style={{ alignItems: 'center', height: '42px' }}
                                                  className={theme.flex}
                                              >
                                                  <div className={theme.headerColorSelector}>
                                                      <div className={theme.headerColorSelectorLabel}>
                                                          {intl.formatMessage(messages.headerColor)}
                                                      </div>
                                                      <FontColorPickerButton
                                                          value={col.headerColor}
                                                          onChange={onChangeColumnHeaderColor}
                                                      />
                                                  </div>
                                                  <div className={theme.imageSelector}>
                                                      <Checkbox
                                                          status={col.hasHeaderIcon}
                                                          onChange={onChangeHasHeaderIcon}
                                                          fixedWidth
                                                      >
                                                          <div className={theme.imageSelectorText}>
                                                              {intl.formatMessage(messages.iconInHeader)}
                                                          </div>
                                                      </Checkbox>
                                                      {col.hasHeaderIcon ? (
                                                          <AttributeImageSelector
                                                              images={presetImages}
                                                              imageId={col.headerIcon}
                                                              onChange={onChangeImageId}
                                                              hasPreview={false}
                                                          />
                                                      ) : null}
                                                  </div>
                                              </div>
                                          </div>

                                          <div className={theme.columnColorSelector}>
                                              <div className={theme.columnColorSelectorLabel}>
                                                  {intl.formatMessage(messages.columnColor)}
                                              </div>
                                              <FontColorPickerButton
                                                  value={col.columnColor}
                                                  onChange={onChangeColumnColor}
                                              />
                                          </div>

                                          {/* эта опция будет реализована в другой итерации */}
                                          {/* <div className={theme.cardsViewInColumn}>
                                              <div className={theme.cardsViewInColumnTitle}>
                                                  Вид размещения карточек в столбце
                                              </div>
                                              <div className={theme.cardsViewInColumnContent}>
                                                  <Radio.Group
                                                      onChange={onChangeCardsViewInColumn}
                                                      value={col.cardsViewInColumn}
                                                  >
                                                      <Radio
                                                          value={KanbanCardsViewInColumn.COLUMN}
                                                          id={`model-${modelTypeId}-column-cards-view-column`}
                                                      >
                                                          <label
                                                              style={{ width: '70px' }}
                                                              className={theme.cardsViewLabel}
                                                              htmlFor={`model-${modelTypeId}-column-cards-view-column`}
                                                          >
                                                              <Icon spriteSymbol={isColumnView} />
                                                          </label>
                                                      </Radio>
                                                      <Radio
                                                          value={KanbanCardsViewInColumn.ROW}
                                                          id={`model-${modelTypeId}-column-cards-view-row`}
                                                      >
                                                          <label
                                                              className={theme.cardsViewLabel}
                                                              htmlFor={`model-${modelTypeId}-column-cards-view-row`}
                                                          >
                                                              <Icon spriteSymbol={isRowView} />
                                                          </label>
                                                          <div className={theme.widthForCardViewLabel}>
                                                              Минимальная ширина карточки/объекта для размещения в ряд:
                                                          </div>
                                                          <InputNumber
                                                              min={1}
                                                              max={500}
                                                              disabled={col.cardsViewInColumn === KanbanCardsViewInColumn.COLUMN}
                                                              defaultValue={col.minCardWidthForRowView || 20}
                                                              onChange={onChangeColumnMinCardWidthForRowView}
                                                              data-radio={`model-${modelTypeId}-column-cards-view-row`}
                                                          />
                                                      </Radio>
                                                  </Radio.Group>
                                              </div>
                                          </div> */}

                                          <div className={theme.cardsCountLimit}>
                                              <Checkbox
                                                  status={col.hasCardsLimitInColumn}
                                                  onChange={onChangeHasCardsLimitInColumn}
                                              >
                                                  {intl.formatMessage(messages.limitCardsObjectsCountInColumn)}
                                              </Checkbox>
                                              <InputNumber
                                                  min={1}
                                                  max={500}
                                                  disabled={!col.hasCardsLimitInColumn}
                                                  defaultValue={col.maxCardsCountInColumn || 20}
                                                  onChange={onChangeMaxCardsInColumn}
                                                  formatter={(v) => String(v || 1)}
                                                  className={
                                                      !col.hasCardsLimitInColumn ? theme.inputDisabled : theme.input
                                                  }
                                              />
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

const withMemo = React.memo(ColumnsSettingsTab);

export { withMemo as ColumnsSettingsTab };

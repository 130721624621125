// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageFileViewer-component__image__zxutA{max-width:100%;max-height:100%}.ImageFileViewer-component__container__wnwsH{height:100%}", "",{"version":3,"sources":["webpack://./src/modules/FileEditor/ImageFileViewer.component.scss"],"names":[],"mappings":"AAAA,yCACI,cAAA,CACA,eAAA,CAGJ,6CACI,WAAA","sourcesContent":[".image {\r\n    max-width: 100%;\r\n    max-height: 100%;\r\n}\r\n\r\n.container {\r\n    height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "ImageFileViewer-component__image__zxutA",
	"container": "ImageFileViewer-component__container__wnwsH"
};
export default ___CSS_LOADER_EXPORT___;

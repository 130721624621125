import { EditorMode } from '@/models/editorMode';
import { MethodologiesGraph } from '@/mxgraph/MethodologiesGraph';
import { Symbol } from '@/serverapi/api';
import { MxCell, MxConstants } from 'MxGraph';
import React, { ChangeEvent, useEffect } from 'react';
import messages from '../../../ObjectType.messages';
import theme from './LabelTab.scss';
import { useIntl } from 'react-intl';
import { Input, Button as AntButton } from 'antd';
import { FontFamilySelect } from '@/modules/MainMenu/components/FontFamilySelect/FontFamilySelect.component';
import { FontSizeSelect } from '@/modules/MainMenu/components/FontSizeSelect/FontSizeSelect.component';
import classnames from 'classnames';
import { NUIButton, Icon as UIKitIcon } from '@/modules/UIKit';
import icBold from '@/resources/icons/ic-ribbon-text-bold.svg';
import icItalic from '@/resources/icons/ic-ribbon-text-italic.svg';
import icUnderline from '@/resources/icons/ic-ribbon-text-underline.svg';
import icLeft from '@/resources/icons/ic-ribbon-text-left.svg';
import icCenter from '@/resources/icons/ic-ribbon-text-center.svg';
import icRight from '@/resources/icons/ic-ribbon-text-right.svg';
import { TextDirectionSelect } from '@/modules/MainMenu/components/TextDirectionSelect/TextDirectionSelect.component';
import { ColorResult, SketchPicker } from 'react-color';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

const ButtonGroup = AntButton.Group;

interface ILabelTabProps {
    tmpSymbol: Symbol;
    symbol: Symbol;
    graph: MethodologiesGraph | undefined;
    cell: MxCell | undefined;
    setCell: React.Dispatch<React.SetStateAction<MxCell | undefined>>;
    switchToEditTextTab: () => void;
    updateSymbol: (symbol: Symbol) => void;
    onChangeStyle: (key: string, value: any) => void;
    getStyleValue: (key: string, style?: string) => string | undefined;
}

export const LabelTab = (props: ILabelTabProps) => {
    const { switchToEditTextTab, tmpSymbol, updateSymbol, graph, cell, setCell, symbol, onChangeStyle, getStyleValue } =
        props;

    const intl = useIntl();

    const unlockGraph = (): void => {
        graph?.setMode(EditorMode.Edit);
    };

    useEffect((): void => {
        switchToEditTextTab();
    }, []);

    const editLabelStyle = (labelStyle: string): void => {
        const newTmpSymbol = { ...tmpSymbol, labelStyle };
        updateSymbol(newTmpSymbol);
        switchToEditTextTab();
    };

    const resetLabelStyle = (): void => {
        unlockGraph();
        if (cell) graph?.removeCells([cell], true);
        setCell(undefined);
        updateSymbol({ ...tmpSymbol, labelStyle: symbol.labelStyle });
        switchToEditTextTab();
    };

    const overlapChanged = (checked: boolean): void => {
        onChangeStyle(MxConstants.STYLE_OVERFLOW, checked ? 'overlap' : undefined);
    };

    const isOvelapChecked = getStyleValue(MxConstants.STYLE_OVERFLOW, tmpSymbol.labelStyle)?.includes('overlap');

    const style = getStyleValue(MxConstants.STYLE_FONTSTYLE);
    const fontStyleValue = style ? Number(style) : 0;
    const alignValue = getStyleValue(MxConstants.STYLE_ALIGN);

    const fontStyleBtnsData = {
        bold: fontStyleValue === 1 || fontStyleValue === 3 || fontStyleValue === 5 || fontStyleValue === 7,
        italic: fontStyleValue === 2 || fontStyleValue === 3 || fontStyleValue === 6 || fontStyleValue === 7,
        underline: fontStyleValue === 4 || fontStyleValue === 5 || fontStyleValue === 6 || fontStyleValue === 7,
    };

    const textDirectionValue: string | undefined = getStyleValue(MxConstants.STYLE_HORIZONTAL);

    const checkboxChanged = (checked: boolean): void => {
        onChangeStyle(MxConstants.STYLE_NOLABEL, checked ? '1' : undefined);
    };

    return (
        <div>
            <div className={theme.fullStyleEdit}>
                <span>{intl.formatMessage(messages.labelFullStyle)}</span>
                <br />
                <Input
                    value={tmpSymbol.labelStyle}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => editLabelStyle(event.target.value.trim())}
                />
            </div>
            <div className={theme.textSettings}>
                <div className={theme.group}>
                    <div className={theme.groupRow}>
                        <FontFamilySelect
                            onChange={(event) => {
                                onChangeStyle(MxConstants.STYLE_FONTFAMILY, event);
                            }}
                            value={getStyleValue(MxConstants.STYLE_FONTFAMILY) || MxConstants.DEFAULT_FONTFAMILY}
                            className={theme.selectFont}
                            renderNewOption={false}
                        />
                        <FontSizeSelect
                            onChange={(event) => {
                                onChangeStyle(MxConstants.STYLE_FONTSIZE, event);
                            }}
                            value={getStyleValue(MxConstants.STYLE_FONTSIZE) || MxConstants.DEFAULT_FONTSIZE}
                            className={theme.selectFontSize}
                        />
                    </div>
                    <div className={theme.groupRow}>
                        <ButtonGroup className={theme.buttonGroup}>
                            <span
                                className={classnames(theme.buttonWrapper, {
                                    [theme.buttonDisabled]: false,
                                })}
                            >
                                <NUIButton
                                    onClick={() =>
                                        onChangeStyle(
                                            MxConstants.STYLE_FONTSTYLE,
                                            fontStyleBtnsData.bold
                                                ? fontStyleValue - MxConstants.FONT_BOLD
                                                : fontStyleValue + MxConstants.FONT_BOLD,
                                        )
                                    }
                                    data-test="bold_button"
                                    selected={fontStyleBtnsData.bold}
                                >
                                    <UIKitIcon spriteSymbol={icBold} />
                                </NUIButton>
                            </span>
                            <span
                                className={classnames(theme.buttonWrapper, {
                                    [theme.buttonDisabled]: false,
                                })}
                            >
                                <NUIButton
                                    onClick={() =>
                                        onChangeStyle(
                                            MxConstants.STYLE_FONTSTYLE,
                                            fontStyleBtnsData.italic
                                                ? fontStyleValue - MxConstants.FONT_ITALIC
                                                : fontStyleValue + MxConstants.FONT_ITALIC,
                                        )
                                    }
                                    selected={fontStyleBtnsData.italic}
                                    data-test="italic_button"
                                >
                                    <UIKitIcon spriteSymbol={icItalic} />
                                </NUIButton>
                            </span>
                            <span
                                className={classnames(theme.buttonWrapper, {
                                    [theme.buttonDisabled]: false,
                                })}
                            >
                                <NUIButton
                                    onClick={() =>
                                        onChangeStyle(
                                            MxConstants.STYLE_FONTSTYLE,
                                            fontStyleBtnsData.underline
                                                ? fontStyleValue - MxConstants.FONT_UNDERLINE
                                                : fontStyleValue + MxConstants.FONT_UNDERLINE,
                                        )
                                    }
                                    selected={fontStyleBtnsData.underline}
                                    data-test="underline_button"
                                >
                                    <UIKitIcon spriteSymbol={icUnderline} />
                                </NUIButton>
                            </span>
                        </ButtonGroup>
                        <ButtonGroup className={theme.buttonGroupAlign}>
                            <span
                                className={classnames(theme.buttonWrapper, {
                                    [theme.buttonDisabled]: false,
                                })}
                            >
                                <NUIButton
                                    onClick={() => onChangeStyle(MxConstants.STYLE_ALIGN, MxConstants.ALIGN_LEFT)}
                                    selected={alignValue === 'left'}
                                    data-test="align-left_button"
                                >
                                    <UIKitIcon spriteSymbol={icLeft} />
                                </NUIButton>
                            </span>
                            <span
                                className={classnames(theme.buttonWrapper, {
                                    [theme.buttonDisabled]: false,
                                })}
                            >
                                <NUIButton
                                    onClick={() => onChangeStyle(MxConstants.STYLE_ALIGN, MxConstants.ALIGN_CENTER)}
                                    selected={alignValue === 'center'}
                                    data-test="align-center_button"
                                >
                                    <UIKitIcon spriteSymbol={icCenter} />
                                </NUIButton>
                            </span>
                            <span
                                className={classnames(theme.buttonWrapper, {
                                    [theme.buttonDisabled]: false,
                                })}
                            >
                                <NUIButton
                                    onClick={() => onChangeStyle(MxConstants.STYLE_ALIGN, MxConstants.ALIGN_RIGHT)}
                                    selected={alignValue === 'right'}
                                    data-test="align-right_button"
                                >
                                    <UIKitIcon spriteSymbol={icRight} />
                                </NUIButton>
                            </span>
                        </ButtonGroup>
                    </div>
                    <div className={theme.groupRow}>
                        <TextDirectionSelect
                            value={textDirectionValue === undefined || textDirectionValue === 'none'}
                            buttonGroupMode={false}
                            onSelect={(value: boolean) =>
                                onChangeStyle(MxConstants.STYLE_HORIZONTAL, value ? 'none' : value)
                            }
                            dataTest="text-direction_button"
                        />
                    </div>
                    <Checkbox
                        onChange={checkboxChanged}
                        status={Number(getStyleValue(MxConstants.STYLE_NOLABEL, tmpSymbol.labelStyle)) === 1}
                        dataTest="hide-label_checkbox"
                    >
                        {intl.formatMessage(messages.disableLabel)}
                    </Checkbox>
                    <div className={theme.groupRow}>
                        <Checkbox onChange={overlapChanged} status={isOvelapChecked}>
                            {intl.formatMessage(messages.overlapLabel)}
                        </Checkbox>
                    </div>
                    <div className={theme.groupRow}>
                        <Button onClick={resetLabelStyle} dataTest="reset-label_button">
                            {intl.formatMessage(messages.reset)}
                        </Button>
                    </div>
                </div>
                <div className={theme.group}>
                    <SketchPicker
                        color={getStyleValue(MxConstants.STYLE_FONTCOLOR)}
                        onChange={(color: ColorResult) => onChangeStyle(MxConstants.STYLE_FONTCOLOR, color.hex)}
                        disableAlpha
                    />
                </div>
            </div>
        </div>
    );
};

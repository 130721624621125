import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icRefresh from '@/resources/icons/ic-update.svg';
import { ToolbarButton } from '../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { reportRefresh } from '../../../actions/report.actions';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { useIntl } from 'react-intl';
import messages from '../../ReportEditorFilterBar/ReportEditorFilterBar.messages';
import { NodeId } from '../../../../../serverapi/api';

export const RefreshButton = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const activeTabId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const refreshHandler = () => {
        dispatch(reportRefresh(activeTabId));
    };

    return (
        <ToolbarButton
            icon={icRefresh}
            size="small"
            onClick={refreshHandler}
            tooltip={intl.formatMessage(messages.refresh)}
        />
    );
};

import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '../../serverapi/api';
import { TDashboardState } from '../../reducers/entities/dashboard.reducer.types';
import { TDashboard, TDashboardCellsCount } from '@/modules/Dashboard/Dashboard.types';
import { defaultCellCount, defaultCellSize } from '@/modules/Dashboard/DashboardsConsts';
import { homePageTabId } from '@/models/home-page';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { filterTreeIncludeTypes, getTreeItems, treeNestedChildrenMap } from '../tree.selectors';
import { TTreeEntityState } from '@/models/tree.types';
import { TDashboardWidgets } from '@/modules/Dashboard/widgets/widget.types';

const dashboardStateSelector = (state: TRootState): TDashboardState => state.dashboard;

export namespace DashboardSelector {
    export const dashboardById = (nodeId?: NodeId) =>
        createSelector<TRootState, TDashboardState, TDashboard | undefined>(
            dashboardStateSelector,
            (state) => nodeId && state.get(nodeId),
        );

    export const widgetsbyId = (nodeId: NodeId) =>
        createSelector<TRootState, TDashboard | undefined, TDashboardWidgets[]>(
            dashboardById(nodeId),
            (dashboard) => dashboard?.widgets || [],
        );

    export const selectedWidget = (nodeId?: NodeId) =>
        createSelector<TRootState, TDashboard | undefined, TDashboardWidgets | undefined>(
            dashboardById(nodeId),
            (dashboard) => {
                const selectedId = dashboard?.selectedWidgetId || '';

                return (dashboard?.widgets || []).find((widget) => widget.id === selectedId);
            },
        );

    export const cellSizeById = (nodeId: NodeId) =>
        createSelector<TRootState, TDashboard | undefined, number>(
            dashboardById(nodeId),
            (dashboard) => dashboard?.cellSize || defaultCellSize,
        );

    export const cellsCountById = (nodeId: NodeId) =>
        createSelector<TRootState, TDashboard | undefined, TDashboardCellsCount>(dashboardById(nodeId), (dashboard) => {
            return {
                verticalCount: dashboard?.verticalCount || defaultCellCount,
                horizontalCount: dashboard?.horizontalCount || defaultCellCount,
            };
        });

    export const getSpreadsheetTreeNodes = (repositoryId: string, serverId: string) => {
        const INCLUDE_TYPES: TreeItemType[] = [TreeItemType.Spreadsheet, TreeItemType.Repository, TreeItemType.Folder];

        return createSelector(
            getTreeItems(serverId, repositoryId),
            (treeItemsById: { [id: string]: TTreeEntityState }) => {
                if (repositoryId !== homePageTabId.repositoryId) {
                    const nodes = treeNestedChildrenMap(treeItemsById, repositoryId);

                    return filterTreeIncludeTypes(nodes, INCLUDE_TYPES);
                }

                return [];
            },
        );
    };
}

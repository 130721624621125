export const UI_TEMPLATE_EDITOR_ADD_NEW_TEMPLATE = 'UI_TEMPLATE_EDITOR_ADD_NEW_TEMPLATE';

export const UI_TEMPLATE_EDITOR_UPDATE_TEMPLATE = 'UI_TEMPLATE_EDITOR_UPDATE_TEMPLATE';

export const UI_TEMPLATE_EDITOR_UPDATE_TABS = 'UI_TEMPLATE_EDITOR_UPDATE_TABS';

export const UI_TEMPLATE_EDITOR_CLOSE_TAB = 'UI_TEMPLATE_EDITOR_CLOSE_TAB';

export const UI_TEMPLATE_EDITOR_ADD_WIDGET = 'UI_TEMPLATE_EDITOR_ADD_WIDGET';

export const UI_TEMPLATE_EDITOR_DELETE_WIDGET = 'UI_TEMPLATE_EDITOR_DELETE_WIDGET';

export const UI_TEMPLATE_EDITOR_UPDATE_WIDGET = 'UI_TEMPLATE_EDITOR_UPDATE_WIDGET';

export const UI_TEMPLATE_EDITOR_SELECT_WIDGET = 'UI_TEMPLATE_EDITOR_SELECT_WIDGET';

export const UI_TEMPLATE_EDITOR_CHANGE_ACTIVE_TAB = 'UI_TEMPLATE_EDITOR_CHANGE_ACTIVE_TAB';

import icStrikeThrough from 'icons/toolbar/controls/ic-font-strikethrough.svg';
import icAdditionalFont from '@/resources/icons/MainMenuFont-Additional.svg';
import icSubscript from 'icons/toolbar/controls/ic-font-subscript.svg';
import icSuperscript from 'icons/toolbar/controls/ic-font-superscript.svg';
import icMonospace from 'icons/toolbar/controls/ic-font-monospace.svg';
import React, { useContext } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import type { Editor } from '@tiptap/react';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { StateObserver } from '../../common/sharedState.class';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';
import { ToolbarDropdown } from '../../../Toolbar/ToolbarDropdown/ToolbarDropdown.component';

type TInlineStyleValue = {
    [key: string]: boolean;
};

type TActionDefinition = {
    action: string;
    tooltip: { id: string; defaultMessage: string };
    icon: any;
};

type TExtraActionsDefinitions = {
    [name: string]: TActionDefinition;
};

const getFocusedBlock = (editor: Editor) => {
    return {
        STRIKETHROUGH: editor.isActive('strike'),
        SUPERSCRIPT: editor.isActive('superscript'),
        SUBSCRIPT: editor.isActive('subscript'),
        MONOSPACE: editor.isActive('code'),
    };
};

const getChangedBlock = (editor: Editor, action: string) => {
    switch (action) {
        case 'STRIKETHROUGH':
            return editor.chain().focus().toggleStrike().run();
        case 'SUPERSCRIPT':
            return editor.chain().focus().toggleSuperscript().unsetSubscript().run();
        case 'SUBSCRIPT':
            return editor.chain().focus().toggleSubscript().unsetSuperscript().run();
        case 'MONOSPACE':
            return editor.chain().focus().toggleCode().run();
        case 'ADDITIONALS':
            return editor.chain().focus().run();
        default:
            return false;
    }
};

const extraActionsDefinitions: TExtraActionsDefinitions = {
    STRIKETHROUGH: {
        action: 'STRIKETHROUGH',
        tooltip: messages.textStrikeThrough,
        icon: icStrikeThrough,
    },
    SUPERSCRIPT: {
        action: 'SUPERSCRIPT',
        tooltip: messages.superscript,
        icon: icSuperscript,
    },
    SUBSCRIPT: {
        action: 'SUBSCRIPT',
        tooltip: messages.subscript,
        icon: icSubscript,
    },
    MONOSPACE: {
        action: 'MONOSPACE',
        tooltip: messages.monospace,
        icon: icMonospace,
    },
};

export const MoreInlineStyles = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);

    const { setCurrentState, value } = useSharedState<TInlineStyleValue, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const onChange = (activeActionId: string) => () => {
        setCurrentState(activeActionId);
    };

    const selectButtons: JSX.Element[] = Object.keys(extraActionsDefinitions).map((key) => {
        return (
            <ToolbarButton
                icon={extraActionsDefinitions[key].icon}
                tooltip={intl.formatMessage(extraActionsDefinitions[key].tooltip)}
                onClick={onChange(extraActionsDefinitions[key].action)}
                isActive={!!value?.[key]}
                dataTest={`wiki-toolbar-group_font-inline-style_${key}`}
            />
        );
    });

    return (
        <ToolbarDropdown
            icon={icAdditionalFont}
            dataTest="wiki-toolbar-group_font-inline-style-button_popover"
            tooltip={intl.formatMessage(messages.moreInlineStyles)}
            size="small"
            options={selectButtons}
        />
    );
};

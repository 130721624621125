import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import type { FileNodeDTO, NodeId, StubResponse } from '../../serverapi/api';
import { ApiBundle, apiBundle } from '../api/api-bundle';

export class FileDaoService {
    public static async uploadTmpFile(serverId: string, file: File): Promise<StubResponse> {
        return apiBundle(serverId).fileTmpStorage.uploadBigTmpFile({ body: { file } });
    }

    public static async uploadTmpFileWithProgress(
        file: File,
        onUploadProgress: Function,
        URL: string,
        setXhr: Function,
    ): Promise<StubResponse> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            setXhr(xhr);
            xhr.upload.onprogress = function (e) {
                onUploadProgress(e);
            };

            xhr.onloadend = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    const response = JSON.parse(xhr.response);
                    resolve(response);
                } else {
                    reject(xhr.response);
                }
            };

            xhr.open('POST', URL, true);

            xhr.setRequestHeader('Accept', 'application/json');
            xhr.withCredentials = true;

            const data = new FormData();

            if (file instanceof File || typeof file === 'string') {
                data.append('file', file);
            } else {
                data.append('file', JSON.stringify(file));
            }

            xhr.send(data);
        });
    }

    public static async getFileWindowUrl(serverId: string, repositoryId: string, fileId: string) {
        try {
            const response: Response = await (apiBundle(serverId).file.downloadFile({
                repositoryId,
                fileId,
            }) as unknown as Promise<Response>);
            const blob: Blob = await response.blob();
            const url: string = window.URL.createObjectURL(blob);

            return url;
        } catch (e) {
            console.error(e);

            return '';
        }
    }

    public static async downloadFile(nodeId: NodeId): Promise<Blob> {
        const api = this.getApi();
        const response: Response = await (api.file.downloadFile({
                fileId: nodeId.id,
                repositoryId: nodeId.repositoryId,
            }) as unknown as Promise<Response>);
        
        return await response.blob();

    }

    public static async uploadFile(serverId: string, file: FileNodeDTO): Promise<FileNodeDTO> {
        return apiBundle(serverId).file.uploadFile({ body: file });
    }

    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

}

import { defineMessages } from 'react-intl';

export default defineMessages({
    linkHintAllowedText: {
        id: 'EditableText.linkHintAllowedText',
        defaultMessage: 'Поддерживаются только ссылки http/https и email-адреса.',
    },
    linkHint: {
        id: 'EditableText.linkHint',
        defaultMessage: 'Не поддерживаются ссылки, скопированные из файлового менеджера:',
    },
    others: {
        id: 'EditableText.others',
        defaultMessage: 'и иные',
    },
});

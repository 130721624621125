import React from 'react';
import { ToolbarButton } from '../../../ToolbarButton/ToolbarButton.component';
import icOpenFormat from '@/resources/icons/formatPanel.svg';
import { useDispatch, useSelector } from 'react-redux';
import { EditorToolbarSelectors } from '../../../../../../../selectors/editorToolbar.selectors';
import { formatPanelClose, formatPanelOpen } from '../../../../../../../actions/formatPanel.actions';
import { closeCommentsPanel } from '../../../../../../../actions/comments.actions';
import { FormatPanelSelectors } from '../../../../../../../selectors/formatPanel.selectors';
import { TabsSelectors } from '../../../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../../../serverapi/api';

export const OpenFormatBtn = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getOpenFormatBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getOpenFormatBtnTooltip);
    const modelId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const isFormatPanelOpen: boolean = useSelector(FormatPanelSelectors.isFormatPanelOpenByModelId(modelId));

    const handleClick = () => {
        if (isFormatPanelOpen) {
            dispatch(formatPanelClose(modelId));
        } else {
            dispatch(formatPanelOpen(modelId));
            dispatch(closeCommentsPanel({ modelId }));
        }
    };

    return (
        <ToolbarButton
            isActive={isFormatPanelOpen}
            icon={icOpenFormat}
            size="small"
            disabled={disabled}
            tooltip={tooltip}
            onClick={handleClick}
            dataTest="header_tab_FORMAT"
        />
    );
};

import { call, delay, put, select } from 'redux-saga/effects';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../../actionsTypes/tabs.actionTypes';
import { workspaceAddTab, workspaceRemoveTab } from '../../actions/tabs.actions';
import { TWorkspaceTabsRemoveRequestAction } from '../../actions/tabs.actions.types';
import { TabsSelectors } from '../../selectors/tabs.selectors';
import { MODEL_CREATE, SAVE_MODEL } from '../../actionsTypes/save.actionTypes';
import { TModelDiagramCreateAction, TSaveModelAction, TSaveModelPayload } from '../../actions/save.actions.types';
import {
    EdgeDefinitionNode,
    FullModelDefinition,
    ModelNode,
    ModelType,
    NodeByTemplateRequest,
    NodeId,
} from '../../serverapi/api';
import { IModelNode } from '../../models/bpm/bpm-model-impl.types';
import { navigatorClearProperties } from '../../actions/navigatorProperties.actions';
import { getLockingTool, SaveModelLockTool } from '../../modules/Editor/classes/SaveModelLockTool';
import { modelRequestSuccess } from '../../actions/model.actions';
import { EditorMode } from '../../models/editorMode';
import { unlock } from '../../actions/lock.actions';
import { clearStateComments, closeCommentsPanel, deleteEditingComment } from '../../actions/comments.actions';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { ModelSelectors } from '../../selectors/model.selectors';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import { modelService } from '../../services/ModelService';
import { IWorkspaceTabItemModelParams, TWorkspaceTab, TWorkspaceTabItemParams } from '@/models/tab.types';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { instancesBPMMxGraphMap } from '@/mxgraph/bpm-mxgraph-instance-map';
import { DefaultGraph } from '@/mxgraph/DefaultGraph';
import { NotificationType } from '@/models/notificationType';
import { showNotificationByType } from '@/actions/notification.actions';
import { formatPanelClose } from '../../actions/formatPanel.actions';
import { takeEverySafely } from '../sagaHelpers';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { defaultWorkspaceTabActions } from '@/models/tab';
import { LoadModelDAOService } from '@/services/dao/LoadModelDAOService';
import { modelDialogSubmitResult } from '@/actions/modelDialog.actions';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { edgeDefinitionsAdd } from '@/actions/entities/edgeDefinition.actions';
import { objectDefinitionsAdd } from '@/actions/entities/objectDefinition.actions';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';
import { MxCell } from 'MxGraph';
import { treeItemChildAdd } from '@/actions/tree.actions';
import { TreeNode } from '@/models/tree.types';
import { continueDecompositionSaga, saveModelFail } from '@/actions/save.actions';
import { LocalesService } from '@/services/LocalesService';
import { lockService } from '@/services/LockService';

function* handleTabCloseRequest(action: TWorkspaceTabsRemoveRequestAction) {
    const tabNodeId = action.payload.workspaceTab.nodeId;
    const workspaceTab: TWorkspaceTab = yield select(TabsSelectors.byId(tabNodeId));

    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(tabNodeId); // todo keep by nodeId

    // Завершаем редактирование имени у текстового блока и символа на графе
    if (graph) {
        graph.stopEditing(false);
    }

    if (workspaceTab?.content?.type === TreeItemType.Model) {
        const lock: SaveModelLockTool = getLockingTool();

        if (!lock.isLocked(tabNodeId.id)) {
            yield call(saveWithRetry, {
                graphId: tabNodeId,
                forceSave: true,
                forceSaveHistory: true,
                attempts: 0,
                showNotification: true,
            });
        }

        yield put(navigatorClearProperties());

        yield put(deleteEditingComment(tabNodeId));
        yield put(closeCommentsPanel({ modelId: tabNodeId }));
        yield put(formatPanelClose(tabNodeId));
        yield put(clearStateComments(tabNodeId));

        if (workspaceTab.mode === EditorMode.Edit) {
            yield put(unlock(tabNodeId, 'MODEL'));
        }

        yield put(workspaceRemoveTab(workspaceTab));
    }
}

function* saveWithRetry({ graphId, forceSave, forceSaveHistory, attempts, showNotification }: TSaveModelPayload) {
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(graphId);

    if (!graph) {
        return;
    }

    const tab: TWorkspaceTab = yield select(TabsSelectors.byId(graphId));
    const locale: Locale = yield select(getCurrentLocale);
    const model: IModelNode = yield select(ModelSelectors.byId(graphId));
    const isDefaultGraph = graph instanceof DefaultGraph;
    const lock: SaveModelLockTool = getLockingTool();

    // может быть ситуация, когда пользователь нажал ctrl+s параллельно с автосохранением, не хотелось бы ему сразу показывать ошибку
    // поэтому при нажатии ctrl+s у модели будет две попытки сохраниться
    //
    // если модель заблокирована (не путать с блокровкой модели на изменение, значек замка), значит идет процесс сохранения
    // если попыток сохранения несколько, ждем 1,5с и запускаем сохранение повторно
    // если попытка сохранения должна быть одна, то показываем сообщение об ошибке и завершаем выполнение
    if (lock.isLocked(graphId.id)) {
        if (attempts > 0) {
            yield delay(1500);
            yield saveWithRetry({
                graphId,
                forceSave,
                forceSaveHistory,
                attempts: attempts - 1,
                showNotification,
            });
            return;
        } else {
            yield put(showNotificationByType(NotificationType.LOW_SERVER));
            return;
        }
    }

    const savedModel: ModelNode | undefined = yield call(() =>
        modelService().prepareAndSaveModel(graph, isDefaultGraph, model, tab, locale, forceSave, forceSaveHistory),
    );

    if (savedModel) {
        yield put(modelRequestSuccess(savedModel));
    }

    if (savedModel && showNotification) {
        yield put(showNotificationByType(NotificationType.MODEL_SAVING_SUCCESS));
    }

    return savedModel;
}

function* handleSaveModelRequest({ payload }: TSaveModelAction) {
    const { graphId, forceSave, forceSaveHistory, attempts, showNotification } = payload;
    yield call(saveWithRetry, {
        graphId,
        forceSave,
        forceSaveHistory,
        attempts,
        showNotification,
    });
}

export function* modelDiagramCreate({ payload }: TModelDiagramCreateAction) {
    const { modelTypeId, modelName, parentNodeId, presetId, templateId } = payload;
    const { serverId } = parentNodeId;
    const locale = LocalesService.getLocale();

    const contentLoadingPageTab: TWorkspaceTab = {
        title: modelName,
        nodeId: parentNodeId,
        type: WorkSpaceTabTypes.CONTENT_LOADING_PAGE_TAB,
        mode: EditorMode.Read,
        params: {
            closable: false,
        } as TWorkspaceTabItemParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(contentLoadingPageTab));

    const templateRequest: NodeByTemplateRequest = {
        parentNodeId,
        templateId,
        type: TreeItemType.Model,
        typeId: modelTypeId,
        name: { [locale]: modelName },
    };


    let modelDefinition: FullModelDefinition;
    try {
        const newModelId: NodeId = yield TreeDaoService.createByTemplate(templateRequest);
        modelDefinition = yield LoadModelDAOService.loadFullModelData(newModelId);

        yield lockService().lock(TreeItemType.Model, newModelId);

        yield put(objectDefinitionsAdd(<ObjectDefinitionImpl[]>modelDefinition.objects));
        yield put(edgeDefinitionsAdd(<EdgeDefinitionNode[]>modelDefinition.edges));
        yield put(modelRequestSuccess(modelDefinition.model));
    } catch (error) {
        yield put(saveModelFail());
        throw error;
    } finally {
        yield put(workspaceRemoveTab(contentLoadingPageTab));
    }
    const newModel = modelDefinition.model;



    const modelType: ModelType | undefined = yield select(
        ModelTypeSelectors.byId({ modelTypeId, serverId }, presetId),
    );
    const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
        title: newModel.name,
        type: 'Editor',
        nodeId: newModel.nodeId,
        content: newModel,
        mode: EditorMode.Edit,
        params: {
            closable: true,
            serverId,
            modelType,
            symbols: modelType?.symbols || [],
            filters: {},
            graph: [] as MxCell[],
        } as IWorkspaceTabItemModelParams,
    };
    yield put(
        treeItemChildAdd({
            parentNodeId: newModel.parentNodeId!,
            child: [newModel as TreeNode],
        }),
    );
    yield put(modelDialogSubmitResult('success'));

    yield put(workspaceAddTab(workspaceTab));

    // Если декомпозиция будет создана, но при открытии диаграммы произойдет ошибка, диалог создания декомпозиции будет считать что все хорошо
    yield put(continueDecompositionSaga(newModel));

}

export function* saveModelSagaInit() {
    yield takeEverySafely(WORKSPACE_TABS_REMOVE_REQUEST, handleTabCloseRequest);
    yield takeEverySafely(SAVE_MODEL, handleSaveModelRequest);
    yield takeEverySafely(MODEL_CREATE, modelDiagramCreate);
}

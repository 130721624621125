import { takeEvery, call, put, all, select } from 'redux-saga/effects';
import { TAppExit, TAppLoadInitialViewSetting, TAppOpenUriAction } from '../actions/app.actions.types';
import { bootstrapSuccess, appStop, appLoaded } from '../actions/app.actions';
import {
    APP_BOOTSTRAP_REQUEST,
    APP_CLOSE_REQUEST,
    APP_EXIT,
    APP_LOAD_INITIAL_VIEW_SETTING,
    APP_LOADED,
    APP_MINIMIZE_REQUEST,
    APP_OPEN_URI,
    APP_SIZE_TOGGLE,
    DOWNLOAD_DOCX,
} from '../actionsTypes/app.actionTypes';
import { Bootstrapper } from '../services/Bootstrapper';
import electron, { remote } from '../electron';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { find } from 'lodash-es';
import { TServerEntity } from '../models/entities.types';
import { clearServersSession } from '../actions/entities/servers.actions';
import { localeSelect } from '../actions/locale.actions';
import { TNavigatorTab } from '../reducers/navigator.reducer.types';
import { getActiveTabs } from '../selectors/navigator.selectors';
import { navigatorTabDeselect, navigatorTabSelect } from '../actions/navigator.actions';
import { AppExitMethod, AppStatus } from '../models/app';
import { clearStore } from '../actions/rootReducer.actions';
import { getOrigin, replaceLastSlash, urlClick } from '../utils/url.utils';
import { urlDataTextCheck } from '../modules/UIKit/components/EditableText/editableText.utils';
import { tabsBusUnsubscribeAction } from '../actions/tabsBus.actions';
import { ExternalLinkBLLService } from '../services/bll/ExternalLinkBLLService';
import {
    ACCEPT_AGREEMENT,
    ACCEPT_SILA_AGREEMENT,
    DELETE_AGREEMENT,
    DELETE_SILA_AGREEMENT,
} from '../actionsTypes/agreementActionTypes';
import {
    TAcceptAgreementAction,
    TAcceptSilaAgreementAction,
    TDownloadFileAction,
} from '../actions/agreement.actions.types';
import { UserAgreementsDaoService } from '../services/dao/UserAgreementsDaoService';
import { openNode } from '../actions/openNode.actions';
import { WSService } from '../services/ws/WSService';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';
import { saveAndUnlockModels } from './saveAndUnlockModels.saga';
import { BrowserDaoService } from '@/services/dao/BrowserDaoService';
import { ExperimentalFeatures } from '@/models/ExperimentalFeatures';

function handleAppCloseRequest() {
    if (electron !== null) {
        remote.app.quit();
    }
}

function handleAppMinimizeRequest() {
    if (electron !== null) {
        remote.BrowserWindow.getFocusedWindow().minimize();
    }
}

function handleAppSizeToggle() {
    if (electron !== null) {
        if (remote.BrowserWindow.getFocusedWindow().isMaximized()) {
            remote.BrowserWindow.getFocusedWindow().unmaximize();
        } else {
            remote.BrowserWindow.getFocusedWindow().maximize();
        }
    }
}

function* bootstrap() {
    /*
    // handle scheme links
    if (electron !== null) {
        electron.remote.globalShortcut.register('CommandOrControl+Shift+K', () => {
            if (electron !== null) {
                electron.remote.BrowserWindow.getFocusedWindow().webContents.openDevTools()
            }
        })

        window.addEventListener('beforeunload', () => {
            if (electron !== null) {
                electron.remote.globalShortcut.unregisterAll()
            }
        });

        electron.remote.protocol.registerStringProtocol('bpm', (request, callback) => {
            let url = request.url.substr(4);

            console.log('url: %s', url, 'need use appOpenUri');
        },
        error => {
            if(!error) {
                console.error(error);
            }
        })

    } */

    yield put(bootstrapSuccess());
}

function* handleAppLoad() {
    yield call(new Bootstrapper().initApplication);

    const savedLocale = LocalStorageDaoService.getLocale();
    yield put(localeSelect(savedLocale));
}

function* handleAppExit({ payload }: TAppExit) {
    const { method } = payload;

    ExperimentalFeatures.clearExperimentalFeatures();

    if (method === AppExitMethod.Logout) {
        yield saveAndUnlockModels();
        yield put(clearServersSession());
        yield put(appStop(AppStatus.Logout));
        yield put(clearStore());
    } else if (method === AppExitMethod.Unauthorized) {
        yield put(clearStore());
        yield put(appStop(AppStatus.Kicked));
    }
    WSService.client().disconnect();
    yield put(tabsBusUnsubscribeAction());
    yield put(appLoaded());
}

function* handleAppOpenUri(action: TAppOpenUriAction) {
    const { uri } = action.payload;

    const servers: TServerEntity[] = yield select(ServerSelectors.serverList);
    const connectedServers: string[] = yield select(ServerSelectors.connected);
    const existServer = find(servers, { id: connectedServers[0] });
    if (ExternalLinkBLLService.isShortUri(uri)) {
        urlClick(urlDataTextCheck(uri, Boolean(electron)));
    } else {
        const nodePayload = ExternalLinkBLLService.getNodePayloadFromUri(uri, existServer);
        if (nodePayload) {
            yield put(openNode({ nodeId: nodePayload.nodeId, type: nodePayload.type }));
        } else {
            urlClick(urlDataTextCheck(uri, Boolean(electron)));
        }
    }

    yield all([]);
}

function* handleAppLoadInitialViewSetting({ payload }: TAppLoadInitialViewSetting) {
    const { isOpenNavigator } = payload.properties;

    if (isOpenNavigator) {
        const activeTabs: Array<TNavigatorTab> = yield select(getActiveTabs);
        if (!activeTabs.includes(TNavigatorTab.Tree)) {
            yield put(navigatorTabSelect(TNavigatorTab.Tree));
        }
    } else {
        yield put(navigatorTabDeselect(TNavigatorTab.Tree));
    }
}

function* handleAcceptAgreement({ payload }: TAcceptAgreementAction) {
    const { serverId } = payload;

    yield UserAgreementsDaoService.confirmAgriment(serverId);
}

function* handleAcceptSilaAgreement({ payload }: TAcceptSilaAgreementAction) {
    const { serverId } = payload;

    yield UserAgreementsDaoService.confirmSilaAgriment(serverId);
}

function* handleDeleteSilaAgreement({ payload }: TAcceptSilaAgreementAction) {
    const { serverId } = payload;

    yield UserAgreementsDaoService.deleteSilaAgreement(serverId);
}

function* handleDeleteAgreement({ payload }: TAcceptSilaAgreementAction) {
    const { serverId } = payload;

    yield UserAgreementsDaoService.deleteAgreement(serverId);
}

function* handleDownloadFile({ payload }: TDownloadFileAction) {
    const { url } = payload;
    const hostName: string = replaceLastSlash(getOrigin());

    yield BrowserDaoService.downloadFile(`${hostName}${url}`, {});
}

export function* appSaga() {
    yield takeEvery(APP_BOOTSTRAP_REQUEST, bootstrap);
    yield takeEvery(APP_CLOSE_REQUEST, handleAppCloseRequest);
    yield takeEvery(APP_MINIMIZE_REQUEST, handleAppMinimizeRequest);
    yield takeEvery(APP_SIZE_TOGGLE, handleAppSizeToggle);
    yield takeEvery(APP_LOADED, handleAppLoad);
    yield takeEvery(APP_EXIT, handleAppExit);
    yield takeEvery(APP_OPEN_URI, handleAppOpenUri);
    yield takeEvery(APP_LOAD_INITIAL_VIEW_SETTING, handleAppLoadInitialViewSetting);
    yield takeEvery(ACCEPT_AGREEMENT, handleAcceptAgreement);
    yield takeEvery(ACCEPT_SILA_AGREEMENT, handleAcceptSilaAgreement);
    yield takeEvery(DELETE_SILA_AGREEMENT, handleDeleteSilaAgreement);
    yield takeEvery(DELETE_AGREEMENT, handleDeleteAgreement);
    yield takeEvery(DOWNLOAD_DOCX, handleDownloadFile);
}

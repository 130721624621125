import type { Editor } from '@tiptap/core';
import type { Node } from '@tiptap/pm/model';

export type THeadline = {
    node: Node;
    pos: number;
};

export type THandleTableOfContentUpdateFunction = (data: TTableOfContentData, isCreate?: boolean) => void;

export type TGetTableOfContentLevelFunction = (
    headline: THeadline,
    previousItems: TTableOfContentDataItem[],
    levels: number[],
) => number;

export type TGetTableOfContentIndexFunction = (
    headline: THeadline,
    previousItems: TTableOfContentDataItem[],
    currentLevel?: number,
) => string;

export type TTableOfContentsOptions = {
    getId?: (textContent: string) => string;
    onUpdate?: THandleTableOfContentUpdateFunction;
    scrollParent?: (() => HTMLElement | Window) | HTMLElement | Window;
    anchorTypes: Array<string>;
    emptyContentTitle?: string;
};

export type TTableOfContentsStorage = {
    content: TTableOfContentData;
    anchors: Array<HTMLHeadingElement | HTMLElement>;
    scrollHandler: () => void;
    scrollPosition: number;
};

export type TTableOfContentData = Array<TTableOfContentDataItem>;

export type TTableOfContentDataItem = {
    dom: HTMLHeadingElement;
    editor: Editor;
    id: string;
    isActive: boolean;
    isScrolledOver: boolean;
    itemIndex?: string;
    level?: number;
    node: Node;
    originalLevel: number;
    pos: number;
    textContent: string;
};

export type TTableOfContentsAttributes = {
    levels: number[];
    index: TocIndex;
    view: TocView;
};

export enum TocIndex {
    HIDE = 'hide',
    ALL_LEVELS = 'alllevels',
    ONE_LEVEL = 'onelevel',
}

export enum TocView {
    TREE = 'tree',
    FLAT = 'flat',
}

import React from 'react';
import clearIcon from '@/resources/icons/icBin.svg';
import theme from '../AddNewValueBtn/AddNewValueButton.scss';
import { Button } from '../../Button/Button.component';
import { useIntl } from 'react-intl';
import messages from '../AddNewValueBtn/AddNewValueButton.messages';

type TAddNewValueButtonProps = { onClicked: () => void };

export const ClearValueButton = (props: TAddNewValueButtonProps) => {
    const { onClicked } = props;
    const intl = useIntl();

    return (
        <div className={theme.btnContainer}>
            <Button
                tooltip={intl.formatMessage(messages.clear)}
                dataTest={'user-managment_delete-user-buttons'}
                onClick={onClicked}
                icon={clearIcon}
                size="small"
            />
        </div>
    );
};

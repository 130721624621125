import React from 'react';
import { useIntl } from 'react-intl';
import { MoveTo, MoveToIcons } from '../../../../../../models/move-to';
import messages from './MoveToDropdown.messages';
import { useDispatch, useSelector } from 'react-redux';
import { moveToAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { ToolbarDropdown } from '../../../../../UIKit/components/Toolbar/ToolbarDropdown/ToolbarDropdown.component';

export const MoveToDropdown = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const tooltip: string = useSelector(EditorToolbarSelectors.getMoveToDropdownTooltip);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getMoveToDropdownDisabled);
    const goToDecompositionDisabled: boolean = useSelector(EditorToolbarSelectors.getGoToDecompositionBtnDisabled);
    const decompositionTooltip: string = useSelector(EditorToolbarSelectors.getGoToDecompositionBtnTooltip);
    const goToObjectInTreeDisabled: boolean = useSelector(EditorToolbarSelectors.getGoToObjectInTreeBtnDisabled);

    const goToButtons: JSX.Element[] = [
        <ToolbarButton
            icon={MoveToIcons.Decomposition}
            tooltip={decompositionTooltip}
            onClick={() => dispatch(moveToAction(MoveTo.Decomposition))}
            dataTest="graph-general-toolbar_goto_Decomposition"
            disabled={goToDecompositionDisabled}
        />,
        <ToolbarButton
            icon={MoveToIcons.ObjectInTree}
            tooltip={intl.formatMessage(messages.objectInTree)}
            onClick={() => dispatch(moveToAction(MoveTo.ObjectInTree))}
            dataTest="graph-general-toolbar_goto_ObjectInTtee"
            disabled={goToObjectInTreeDisabled}
        />,
    ];

    return (
        <ToolbarDropdown
            options={goToButtons}
            icon={MoveToIcons.Noop}
            size="small"
            tooltip={tooltip}
            disabled={disabled}
            dataTest="main-panel_button-go-to"
        />
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    newTemplate: {
        id: 'UITemplatesTab.newTemplate',
        defaultMessage: 'Новый шаблон',
    },
    preview: {
        id: 'UITemplatesTab.preview',
        defaultMessage: 'Просмотр',
    },
});

import { useDispatch, useSelector } from 'react-redux';
import { MatrixEditorSelectors } from '../../../../Matrix/selectors/matrixEditor.selectors';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../serverapi/api';
import { ToolbarButton } from '../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import React from 'react';
import { matrixPastObjects } from '../../../../Matrix/actions/matrixEditor.actions';
import icPaste from '@/resources/icons/insert.svg';

export const Paste = () => {
    const dispatch = useDispatch();
    const activeTabNodeId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const disabled: boolean = useSelector(MatrixEditorSelectors.getPasteDisabled(activeTabNodeId));
    const tooltip: string = useSelector(MatrixEditorSelectors.getPasteTooltip(activeTabNodeId));

    const paste = () => {
        dispatch(matrixPastObjects(activeTabNodeId));
    };

    return <ToolbarButton icon={icPaste} size="small" disabled={disabled} onClick={paste} tooltip={tooltip} />;
};

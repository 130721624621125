import { TNodeState } from '@/reducers/entities/TNodeState';
import { NodeId, ReportColumnData, ReportNode } from '@/serverapi/api';
import { TReducer } from '@/utils/types';
import {
    REPORT_ADD_COLUMN,
    REPORT_ADD_NODES,
    REPORT_CHANGE_COLUMN_FILTER_VISIBILITY,
    REPORT_CHANGE_COLUMN_VISIBILITY,
    REPORT_CHANGE_COLUMN_ORDER,
    REPORT_CHANGE_FILLING_TYPE,
    REPORT_CLEAR_SEARCH_REQUEST,
    REPORT_CLEAR_SELECT_COLUMN,
    REPORT_DELETE_COLUMN,
    REPORT_DELETE_NODES,
    REPORT_REQUEST_SUCCESS,
    REPORT_SAVE_REQUEST_SUCCESS,
    REPORT_SET_COLUMN_DATA,
    REPORT_SET_SEARCH_REQUESTS,
} from '../actions/report.actionTypes';
import { TReportState } from './report.reducer.types';
import { unionWith } from 'lodash-es';
import { compareNodeIds } from '@/utils/nodeId.utils';

const INITIAL_REPORT_STATE: TReportState = new TNodeState();

export const reportReducer: TReducer<TReportState> = (state = INITIAL_REPORT_STATE, action) => {
    switch (action.type) {
        case REPORT_SAVE_REQUEST_SUCCESS:
        case REPORT_REQUEST_SUCCESS: {
            const {
                payload: {
                    report,
                    report: { nodeId, version },
                },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(nodeId);
            if (existReport && version) {
                return state.set(nodeId, { ...existReport, version });
            }
            if (!existReport) {
                return state.set(nodeId, report);
            }

            return state;
        }

        case REPORT_ADD_NODES: {
            const {
                payload: { reportNodeId, addedNodeIds },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                const existNodeIds = existReport.reportData?.manuallyFilledNodes || [];
                const manuallyFilledNodes: NodeId[] = unionWith(existNodeIds, addedNodeIds, compareNodeIds);

                const updatedReport: ReportNode = {
                    ...existReport,
                    reportData: { ...existReport.reportData, manuallyFilledNodes },
                };

                return state.set(reportNodeId, updatedReport);
            }
            return state;
        }

        case REPORT_SET_COLUMN_DATA: {
            const {
                payload: { reportNodeId, columnId, data },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                const columns: ReportColumnData[] = [...(existReport.reportData?.columns || [])];
                let columnIndex: number = columns.findIndex((column) => column.columnId === columnId);

                if (columnIndex !== -1) {
                    columns[columnIndex] = { ...columns[columnIndex], ...data };
                }

                const updatedReport: ReportNode = {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns },
                };
                return state.set(reportNodeId, updatedReport);
            }
            return state;
        }

        case REPORT_DELETE_COLUMN: {
            const {
                payload: { reportNodeId, columnId },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                const columns: ReportColumnData[] = existReport.reportData?.columns.filter(
                    (column) => column.columnId !== columnId,
                );

                const updatedReport: ReportNode = {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns },
                };
                return state.set(reportNodeId, updatedReport);
            }
            return state;
        }

        case REPORT_ADD_COLUMN: {
            const {
                payload: { reportNodeId, newColumn },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                const columns: ReportColumnData[] = existReport.reportData?.columns || [];

                const leftPart: ReportColumnData[] = columns.filter((col) => col.orderNumber < newColumn.orderNumber);
                const rightPart: ReportColumnData[] = columns.filter((col) => col.orderNumber >= newColumn.orderNumber);
                const newColumns: ReportColumnData[] = [...leftPart, newColumn, ...rightPart].map((col, index) => ({
                    ...col,
                    orderNumber: index,
                }));

                const updatedReport: ReportNode = {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns: newColumns },
                };

                return state.set(reportNodeId, updatedReport);
            }

            return state;
        }

        case REPORT_CHANGE_COLUMN_ORDER: {
            const {
                payload: { reportNodeId, columnOrder },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                const columns: ReportColumnData[] = existReport.reportData?.columns || [];

                const orderedColumns: ReportColumnData[] = columns
                    .sort((col1, col2) => {
                        const index1 = columnOrder.findIndex((columnId) => columnId === col1.columnId);
                        const index2 = columnOrder.findIndex((columnId) => columnId === col2.columnId);
                        return index1 - index2;
                    })
                    .map((col, index) => ({ ...col, orderNumber: index }));

                const updatedReport: ReportNode = {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns: orderedColumns },
                };

                return state.set(reportNodeId, updatedReport);
            }

            return state;
        }

        case REPORT_CLEAR_SELECT_COLUMN: {
            const {
                payload: { reportNodeId },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                const columns: ReportColumnData[] = existReport.reportData.columns.filter(
                    (column) => column.columnName,
                );
                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns },
                });
            }

            return state;
        }

        case REPORT_CHANGE_FILLING_TYPE: {
            const {
                payload: { reportNodeId, fillingType },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                if (fillingType === existReport.reportData.fillingType) return state;

                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, fillingType },
                });
            }

            return state;
        }

        case REPORT_SET_SEARCH_REQUESTS: {
            const {
                payload: { reportNodeId, searchRequests },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, searchRequests },
                });
            }

            return state;
        }

        case REPORT_CLEAR_SEARCH_REQUEST: {
            const {
                payload: { reportNodeId },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, searchRequests: [] },
                });
            }

            return state;
        }

        case REPORT_DELETE_NODES: {
            const {
                payload: { reportNodeId, nodeIds },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                const existNodeIds = existReport.reportData?.manuallyFilledNodes || [];
                const manuallyFilledNodes: NodeId[] = existNodeIds.filter(
                    (existNodeId) => !nodeIds.some((nodeId) => compareNodeIds(nodeId, existNodeId)),
                );

                const updatedReport: ReportNode = {
                    ...existReport,
                    reportData: { ...existReport.reportData, manuallyFilledNodes },
                };

                return state.set(reportNodeId, updatedReport);
            }
            return state;
        }

        case REPORT_CHANGE_COLUMN_VISIBILITY: {
            const {
                payload: { reportNodeId, columnId },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                const columns: ReportColumnData[] = existReport.reportData.columns.map((column) => {
                    if (column.columnId === columnId) {
                        return { ...column, visibilityEnabled: !column.visibilityEnabled };
                    }
                    return column;
                });

                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns },
                });
            }

            return state;
        }

        case REPORT_CHANGE_COLUMN_FILTER_VISIBILITY: {
            const {
                payload: { reportNodeId, columnId },
            } = action;

            const existReport: ReportNode | undefined = state.byNodeId.get(reportNodeId);

            if (existReport?.reportData) {
                const columns: ReportColumnData[] = existReport.reportData.columns.map((column) => {
                    if (column.columnId === columnId) {
                        return { ...column, filterEnabled: !column.filterEnabled };
                    }
                    return column;
                });

                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns },
                });
            }

            return state;
        }

        default:
            return state;
    }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddNewValueButton__btnContainer__oYNZI{width:100%;margin-left:-4px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Toolbar/AddNewValueBtn/AddNewValueButton.scss"],"names":[],"mappings":"AAAA,wCACI,UAAA,CACA,gBAAA","sourcesContent":[".btnContainer {\r\n    width: 100%;\r\n    margin-left: -4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnContainer": "AddNewValueButton__btnContainer__oYNZI"
};
export default ___CSS_LOADER_EXPORT___;

import { all, call, CallEffect, put, select } from 'redux-saga/effects';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import { LocalesService } from '../../services/LocalesService';
import messages from '../../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import { workspaceAddTab, workspaceRemoveTabByNodeId } from '../../actions/tabs.actions';
import { defaultWorkspaceTabActions } from '../../models/tab';
import { EditorMode } from '../../models/editorMode';
import { WorkSpaceTabTypes } from '../../modules/Workspace/WorkSpaceTabTypesEnum';
import { IWorkspaceTabItemModelEditParams, TWorkspaceTab } from '../../models/tab.types';
import { v4 as uuid } from 'uuid';
import { WikiType } from '../../serverapi/api';
import { TPresetId } from '../../modules/AdminTools/Methodology/components/Presets/TPresetId.types';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode } from '@/models/tree.types';
import { PresetSelectors } from '@/selectors/preset.selectors';
import {
    TCreateWikiModelTypeAction,
    TEditWikiModelTypeAction,
    TSubmitWikiModelTypeAction,
} from '@/actions/presetSettings/presetSettingsWikiModelType.actions.types';
import { addWikiModelType } from '@/actions/presetSettings/presetSettingsWikiModelType.actions';
import { GeneralModelTypeDiscriminator } from '@/models/ModelTypes';
import {
    PRESET_SETTINGS_CREATE_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_EDIT_WIKI_MODEL_TYPE,
    PRESET_SETTINGS_SUBMIT_WIKI_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsWikiModelType.actionTypes';
import { addStoreEditWikiModelTypeWorkspaceTab } from '../../actions/workspaceTab/editWikiModelTypeWorkspaceTab.actions';
import { IPresetSettingsWikiModelTypeData } from '../../reducers/presetSettings/presetSettingsWikiModelType.reducer.types';
import { PresetSettingsWikiModelTypeSelectors } from '../../selectors/presetSettings/presetSettingsWikiModelType.selectors';
import { WikiDaoService } from '../../services/dao/WikiDAOService';
import { takeEverySafely } from '../sagaHelpers';

export function* saveWikiModelTypeChanges(presetId: string) {
    const wikiModelTypesData: IPresetSettingsWikiModelTypeData = yield select(
        PresetSettingsWikiModelTypeSelectors.byPresetId(presetId),
    );

    const effects: CallEffect<void>[] = [];

    if (wikiModelTypesData.wikiModelTypesForSave.length) {
        wikiModelTypesData.wikiModelTypesForSave.forEach((type) => {
            if (!wikiModelTypesData.wikiModelTypesForDelete.some((typeToDelete) => typeToDelete.id === type.id)) {
                effects.push(call(() => WikiDaoService.saveWikiType(type)));
            }
        });
    }

    if (wikiModelTypesData.wikiModelTypesForDelete.length) {
        wikiModelTypesData.wikiModelTypesForDelete.forEach((type) => {
            effects.push(call(() => WikiDaoService.deleteWikiType(presetId, type.id)));
        });
    }

    yield all(effects);
}

function* handleCreateWikiModelType(action: TCreateWikiModelTypeAction) {
    const { serverNodeId, presetId } = action.payload;

    const serverNode: TreeNode = yield select(TreeSelectors.itemById(serverNodeId));
    const preset = yield select(PresetSelectors.byId({ presetId, serverId: serverNodeId.serverId }));

    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const modelType: WikiType = {
        id: uuid(),
        attributes: [],
        groupId: '',
        presetId: preset.id,
        enableModel: true,
        discriminator: GeneralModelTypeDiscriminator.WIKI,
    };

    const wikiTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelTitle),
        nodeId: {
            ...serverNodeId,
            id: `${serverNodeId.serverId}_${presetId}_${modelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_WIKI_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId,
        params: {
            serverNode,
            preset,
            createMode: true,
            modelTypeId: modelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(
        addStoreEditWikiModelTypeWorkspaceTab({
            presetId,
            modelType,
        }),
    );

    yield put(workspaceAddTab(wikiTypeTab));
}

function* handleSubmitWikiModelType(action: TSubmitWikiModelTypeAction) {
    const { presetId, modelType, needTabClose, tabNodeId, createMode } = action.payload;

    if (createMode) {
        yield WikiDaoService.createWikiType(modelType);
    } else {
        yield WikiDaoService.saveWikiType(modelType);
    }

    if (needTabClose && tabNodeId) {
        yield put(workspaceRemoveTabByNodeId(tabNodeId));
    }

    yield put(addWikiModelType({ presetId, wikiModelTypes: [modelType] }));
}

function* handleEditWikiModelType(action: TEditWikiModelTypeAction) {
    const { serverNodeId, presetId, wikiModelType } = action.payload;

    const serverNode: TreeNode = yield select(TreeSelectors.itemById(serverNodeId));
    const preset = yield select(PresetSelectors.byId({ presetId, serverId: serverNodeId.serverId }));

    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);
    const multilingualName = wikiModelType.multilingualName!;

    const createModelTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newModelType, { type: multilingualName[currentLocale] }),
        nodeId: {
            ...serverNodeId,
            id: `${serverNodeId.serverId}_${presetId}_${wikiModelType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_WIKI_MODEL_TYPE_TAB,
        mode: EditorMode.Read,
        presetId,
        params: {
            serverNode,
            preset,
            createMode: false,
            modelTypeId: wikiModelType.id,
        } as IWorkspaceTabItemModelEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(
        addStoreEditWikiModelTypeWorkspaceTab({
            presetId,
            modelType: wikiModelType,
        }),
    );
    yield put(workspaceAddTab(createModelTypeTab));
}

export function* presetSettingsWikiModelTypesSaga() {
    yield takeEverySafely(PRESET_SETTINGS_CREATE_WIKI_MODEL_TYPE, handleCreateWikiModelType);
    yield takeEverySafely(PRESET_SETTINGS_SUBMIT_WIKI_MODEL_TYPE, handleSubmitWikiModelType);
    yield takeEverySafely(PRESET_SETTINGS_EDIT_WIKI_MODEL_TYPE, handleEditWikiModelType);
}

import { put, select, takeEvery } from 'redux-saga/effects';
import { openDialog } from '../actions/dialogs.actions';
import {
    TCheckInvisibleEdgeTypesAction,
    TDeleteEdgeTypeAction,
    TEditEdgeTypeAction,
    TSubmitEdgeTypeAction,
    TDeleteEdgeTypeAndGroupAction,
} from '../actions/edgeType.actions.types';
import {
    CHECK_INVISIBLE_EDGE_TYPE,
    CREATE_EDGE_TYPE,
    DELETE_EDGE_TYPE,
    EDIT_EDGE_TYPE,
    SUBMIT_EDGE_TYPE,
    DELETE_EDGE_TYPE_AND_GROUP,
} from '../actionsTypes/edgeType.actionTypes';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { ModelTypeSelectors } from '../selectors/modelType.selectors';
import { TreeSelectors } from '../selectors/tree.selectors';
import { EdgeType, ModelType } from '../serverapi/api';
import { NotationHelper } from '../services/utils/NotationHelper';
import {
    deleteEdgeType,
    edgeTypeDeleteRequestSuccess,
    edgeTypeRequesFailure,
    edgeTypeRequestSuccess,
} from '../actions/edgeType.actions';
import { EdgeTypesDaoService } from '../services/dao/EdgeTypesDaoService';
import { TDeleteEdgeTypeGroupRequestAction } from '../actions/edgeTypeGroup.actions.types';
import { EdgeTypeSelectors } from '../selectors/edgeType.selectors';
import { deleteEdgeTypeGroupSuccess } from '../actions/edgeTypeGroup.actions';
import { DELETE_EDGE_TYPE_GROUP_REQUEST } from '../actionsTypes/edgeTypeGroup.actionTypes';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import messages from '../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import { workspaceAddTab, workspaceRemoveTabByNodeId } from '../actions/tabs.actions';
import { PresetSettingsModelTypeSelectors } from '../selectors/presetSettings/presetSettingsModelType.selectors';
import { IWorkspaceTabItemEdgeTypeEditorParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TCreateObjectTypeAction } from '../actions/objectType.actions.types';
import { v4 as uuid } from 'uuid';
import { TPresetId } from '../modules/AdminTools/Methodology/components/Presets/TPresetId.types';
import { ServerErrorType } from '../models/serverErrorType';
import { TCellIntersection, TInvivsibleEdgeCellIntersection } from '../mxgraph/bpmgraph.types';

function* getInvisibleEdgesTypes(graph: BPMMxGraph, intersectedCells: TCellIntersection[]) {
    const presetId: string = yield select(TreeSelectors.presetById(graph.id));
    const { serverId } = graph.id;
    const modelTypeId = graph.modelType?.id;
    if (!modelTypeId) {
        return [];
    }
    const modelType: ModelType = yield select(ModelTypeSelectors.byId({ modelTypeId, serverId }, presetId));
    const invivsibleEdgesData: TInvivsibleEdgeCellIntersection[] = intersectedCells.map((cellIntersection) => {
        const { movedCell, intersectedCell } = cellIntersection;

        const movedToIntersectTypes: EdgeType[] = NotationHelper.getEdgeTypeBySourceAndDestination(
            movedCell.value,
            intersectedCell.value,
            serverId,
            modelType,
        ).filter((type) => type?.canBeInvisible);

        const intersectToMovedTypes: EdgeType[] = NotationHelper.getEdgeTypeBySourceAndDestination(
            intersectedCell.value,
            movedCell.value,
            serverId,
            modelType,
        ).filter((type) => type?.canBeInvisible);

        return {
            movedToIntersectTypes,
            intersectToMovedTypes,
            cellIntersection,
        };
    });

    return invivsibleEdgesData.filter((data) => data.intersectToMovedTypes.length || data.movedToIntersectTypes.length);
}

function* handleCheckInvisibleEdgeTypes(action: TCheckInvisibleEdgeTypesAction) {
    const { graph, intersectedCells } = action.payload;

    const invivsibleEdgesData: TInvivsibleEdgeCellIntersection[] = yield getInvisibleEdgesTypes(
        graph,
        intersectedCells,
    );

    if (invivsibleEdgesData.length) {
        yield put(
            openDialog(DialogType.CREATE_INVISIBLE_EDGE_DIALOG, {
                invivsibleEdgesData,
                graph,
            }),
        );
    }
}

function* handleDeleteEdgeType({ payload }: TDeleteEdgeTypeAction) {
    const { serverNode, edgeTypes } = payload;
    const { serverId } = serverNode.nodeId;
    yield EdgeTypesDaoService.edgeTypeBulkDelete(serverId, edgeTypes);
    yield put(edgeTypeDeleteRequestSuccess(serverNode.nodeId.serverId, edgeTypes));
}

function* handleDeleteEdgeTypeGroup({ payload }: TDeleteEdgeTypeGroupRequestAction) {
    const { serverNode, edgeTypeGroups } = payload;
    const { serverId } = serverNode.nodeId;

    if (edgeTypeGroups?.length > 0) {
        const { presetId } = edgeTypeGroups[0];
        const edgeTypesByPresetId = yield select(EdgeTypeSelectors.listByPresetId(serverId, presetId));
        const edgeTypes: EdgeType[] = edgeTypesByPresetId.filter((et) =>
            edgeTypeGroups.some(
                (etg) => etg.id === et.edgeTypeGroup?.id && etg.presetId === et.edgeTypeGroup?.presetId,
            ),
        );
        yield handleDeleteEdgeType(deleteEdgeType({ serverNode, edgeTypes }));
        yield EdgeTypesDaoService.edgeTypeGroupBulkDelete(serverId, edgeTypeGroups);
    }
    yield put(deleteEdgeTypeGroupSuccess(payload));
}

function* handleDeleteEdgeTypeAndGroup({ payload }: TDeleteEdgeTypeAndGroupAction) {
    const { serverNode, edgeTypes, edgeTypeGroups } = payload;
    const { serverId } = serverNode.nodeId;
    yield EdgeTypesDaoService.deleteEdgeTypesAndGroupsRequest(serverId, edgeTypes, edgeTypeGroups);
    yield put(deleteEdgeTypeGroupSuccess({ serverNode, edgeTypeGroups }));
    yield put(edgeTypeDeleteRequestSuccess(serverId, edgeTypes));
}

function* handleSubmitEdgeType({ payload }: TSubmitEdgeTypeAction) {
    const { serverNode, preset, edgeTypes, preventTabClose, createMode, tabNodeId } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (serverId) {
        if (createMode) {
            try {
                yield EdgeTypesDaoService.createEdgeType(serverId, edgeTypes[0]);
            } catch (err) {
                yield put(edgeTypeRequesFailure());
                if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                    throw Error(intl.formatMessage(messages.idAlreadyTaken));
                } else {
                    throw err;
                }
            }
        } else {
            yield EdgeTypesDaoService.setEdgeTypesSave(serverId, edgeTypes);
        }
        if (preset) {
            if (!preventTabClose && tabNodeId) {
                yield put(workspaceRemoveTabByNodeId(tabNodeId));
            }
            yield put(edgeTypeRequestSuccess(serverId, edgeTypes, preset.id));
        }
    }
}

function* handleEditEdgeType(action: TEditEdgeTypeAction) {
    const { edgeType, serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const modelTypes = yield select(
        PresetSettingsModelTypeSelectors.getAllByServerIdPresetId(preset?.id, serverNode.nodeId.serverId),
    );

    const editEdgeTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.edgeType, { name: edgeType.name }),
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${edgeType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_EDGE_TYPE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            edgeType,
            preset,
            serverNode,
            createMode: false,
            modelTypes: modelTypes?.byId ? Object.values(modelTypes.byId) : [],
        } as IWorkspaceTabItemEdgeTypeEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(editEdgeTypeTab));
}

function* handleCreateEdgeType(action: TCreateObjectTypeAction) {
    const { serverNode, preset } = action.payload;
    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);

    const edgeType: EdgeType = {
        id: uuid(),
        name: intl.formatMessage(messages.newEdge),
        multilingualName: { [currentLocale]: intl.formatMessage(messages.newEdge) },
        presetId: preset.id,
        localizableName: '',
        edgeStyle: '',
        direction: 'NO_DIRECTION',
        description: '',
        sprite: '',
        attributeTypes: [],
        diagramElementAttributes: [],
        edgeTypeGroup: undefined,
        allowAnyDecomposition: true,
    };

    const modelTypes = yield select(
        PresetSettingsModelTypeSelectors.getAllByServerIdPresetId(preset?.id, serverNode.nodeId.serverId),
    );

    const createEdgeTypeTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newEdgeType),
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${edgeType.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_EDGE_TYPE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            serverNode,
            edgeType,
            preset,
            createMode: true,
            modelTypes: modelTypes?.byId ? Object.values(modelTypes.byId) : [],
        } as IWorkspaceTabItemEdgeTypeEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(createEdgeTypeTab));
}

export function* edgeSaga() {
    yield takeEvery(CHECK_INVISIBLE_EDGE_TYPE, handleCheckInvisibleEdgeTypes);
    yield takeEvery(DELETE_EDGE_TYPE, handleDeleteEdgeType);
    yield takeEvery(DELETE_EDGE_TYPE_GROUP_REQUEST, handleDeleteEdgeTypeGroup);
    yield takeEvery(SUBMIT_EDGE_TYPE, handleSubmitEdgeType);
    yield takeEvery(CREATE_EDGE_TYPE, handleCreateEdgeType);
    yield takeEvery(EDIT_EDGE_TYPE, handleEditEdgeType);
    yield takeEvery(DELETE_EDGE_TYPE_AND_GROUP, handleDeleteEdgeTypeAndGroup);
}

import icHightLight from '@/resources/icons/highlight.svg';
import icHightLightAll from '@/resources/icons/highlight_all.svg';
import icHightLightInverse from '@/resources/icons/highlight_inverse.svg';
import icHightLightType from '@/resources/icons/highlight_all_of_type.svg';
import icHightLightForm from '@/resources/icons/highlight_all_of_form.svg';
import { TSVGIcon } from '../modules/UIKit/components/Button/Button.types';

export enum PickOut {
    PickDefault,
    PickOutAll,
    ReversePickOut,
    PickOutByObjectType,
    PickOutBySymbol,
}

export const PickOutIcons: {
    [key in keyof typeof PickOut]: TSVGIcon;
} = {
    PickDefault: icHightLight,
    PickOutAll: icHightLightAll,
    ReversePickOut: icHightLightInverse,
    PickOutByObjectType: icHightLightType,
    PickOutBySymbol: icHightLightForm,
};

import React from 'react';
import { MoveLayer, MoveLayerIcons } from '../../../../../../models/movelayer';
import { useIntl } from 'react-intl';
import messages from './MoveLayerDropdown.messages';
import { useDispatch, useSelector } from 'react-redux';
import { moveLayerAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { ToolbarDropdown } from '../../../../../UIKit/components/Toolbar/ToolbarDropdown/ToolbarDropdown.component';

export const MoveLayerDropdown = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const tooltip: string = useSelector(EditorToolbarSelectors.getMoveLayerDropdownTooltip);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getMoveLayerDropdowntDisabled);

    const moveButtons: JSX.Element[] = Object.getOwnPropertyNames(MoveLayer)
        .filter((prop) => isNaN(parseInt(prop)))
        .map((key) => {
            return (
                <ToolbarButton
                    icon={MoveLayerIcons[key]}
                    tooltip={intl.formatMessage(messages[`moveLevel${key}`])}
                    onClick={() => dispatch(moveLayerAction(MoveLayer[key]))}
                    dataTest={`general-toolbar_move-layer-btn${key}`}
                />
            );
        });

    return (
        <ToolbarDropdown
            options={moveButtons}
            icon={MoveLayerIcons.Up}
            size="small"
            disabled={disabled}
            tooltip={tooltip}
            dataTest="general-toolbar_move-layer-btn"
        />
    );
};

import type { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import type { NodeId, WikiImage, WikiImageCopyRequest, WikiImageRequest, WikiType } from '@/serverapi/api';
import type { TRawDraftContentState } from '@/sagas/mappers/draftJsToTipTapWiki.types';
import { ApiBundle, apiBundle } from '../api/api-bundle';
import { setServerIdToNodeOriginal } from '@/utils/nodeId.utils';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { DEPRECATED_WIKI_CONTENT_PROP_NAME, WIKI_CONTENT_PROP_NAME } from '@/modules/Wiki/WikiEditor.utils';
import { mapDeprecatedEditorState } from '@/sagas/mappers/draftJsToTipTapWiki';
import { markdownToDraft } from '@/modules/UIKit/components/RichTextEditor/Editor/utils/markdownToDraft';
import { blockEntities, getBlockStyleItems } from '@/modules/UIKit/components/RichTextEditor/Editor/entities';

export class WikiDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }
    public static async saveWiki(serverId: string, body: IWikiNode): Promise<IWikiNode> {
        const wiki = (await apiBundle(serverId).wiki.save({ body })) as IWikiNode;
        setServerIdToNodeOriginal(wiki, serverId);
        return wiki;
    }

    public static async getWikiById(serverId: string, repositoryId: string, wikiId: string): Promise<IWikiNode> {
        const wiki = (await apiBundle(serverId).wiki.get({ wikiId, repositoryId })) as IWikiNode;

        const { [WIKI_CONTENT_PROP_NAME]: content, [DEPRECATED_WIKI_CONTENT_PROP_NAME]: deprecatedContent } = wiki;
        const updatedWiki = { ...wiki, [WIKI_CONTENT_PROP_NAME]: content };

        if (!content) {
            const baseUrl = `${getOrigin()}/${PictureSymbolConstants.DOWNLOAD_LINK}`;
            const imageMapper = (src: string) => `${baseUrl}/${repositoryId}/${src.split('/')[1]}/`;

            const stringifyedContent = deprecatedContent || '{}';
            let parsedContent: TRawDraftContentState;

            try {
                parsedContent = JSON.parse(stringifyedContent);
            } catch (e) {
                parsedContent = markdownToDraft(stringifyedContent, {
                    remarkablePlugins: [],
                    blockEntities,
                    blockStyles: getBlockStyleItems(),
                }) as TRawDraftContentState;
            }

            updatedWiki[WIKI_CONTENT_PROP_NAME] = mapDeprecatedEditorState(parsedContent, {
                imageMapper,
            });
        }

        setServerIdToNodeOriginal(updatedWiki, serverId);
        return updatedWiki;
    }

    public static async downloadWikiImage(wikiNode: NodeId, imageId: string): Promise<void> {
        return apiBundle(wikiNode.serverId).wiki.downloadWikiImage({
            repositoryId: wikiNode.repositoryId,
            wikiId: wikiNode.id,
            imageId,
        });
    }

    public static async uploadWikiImage(wikiNode: NodeId, body: WikiImageRequest): Promise<WikiImage> {
        return apiBundle(wikiNode.serverId).wiki.uploadWikiImage({
            repositoryId: wikiNode.repositoryId,
            wikiId: wikiNode.id,
            body,
        });
    }

    public static async copyWikiImage(requestParams: WikiImageCopyRequest): Promise<WikiImage> {
        return apiBundle(requestParams.sourceModelId.serverId).wiki.copyWikiImage({
            body: requestParams,
        });
    }

    public static getWikiDocxExportUrl(wikiNode: NodeId) {
        return `/api/wiki/${wikiNode.repositoryId}/${wikiNode.id}/docx`;
    }

    public static async exportWikiDocx(wikiNode: NodeId) {
        return await apiBundle(wikiNode.serverId).wiki.getWikiDocx({
            repositoryId: wikiNode.repositoryId,
            wikiId: wikiNode.id,
        });
    }

    public static async createWikiType(wikiType: WikiType) {
        const api = this.getApi();
        await api.wikiType.create({ body: wikiType });
    }

    public static async saveWikiType(wikiType: WikiType) {
        const api = this.getApi();
        await api.wikiType.save({ body: wikiType });
    }

    public static async deleteWikiType(presetId: string, id: string) {
        const api = this.getApi();
        await api.wikiType._delete({ presetId, id });
    }
}

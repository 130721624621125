import type { NodeId } from '@/serverapi/api';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { getStore } from '@/store';
import { ContainmentAllowedSymbolIdsSelectors } from '@/selectors/containmentAllowedSymbolIds.selector';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { setContainmentAllowedSymbolIds } from '@/actions/containmentAllowedSymbolIds.actions';

export const isSymbolEditable = (symbolId: string, nodeId: NodeId) => {
    const state = getStore().getState();

    return UserProfileSelectors.isSymbolEditable(nodeId, symbolId)(state);
};

export const getAllContainmentAllowedSymbolIds = (): string[] | null => {
    const state = getStore().getState();
    return ContainmentAllowedSymbolIdsSelectors.getAll(state);
};

export const getContainmentAllowedSymbolIdsByIds = (nodeId: NodeId, symbolIds: string[]): string[] => {
    const state = getStore().getState();
    const presetId: string | undefined = TreeSelectors.presetById(nodeId)(state);

    const allowedSymbolIds: string[] = SymbolSelectors.getContainmentAllowedSymbolIds(
        nodeId.serverId,
        presetId,
        symbolIds,
    )(state);

    return allowedSymbolIds;
};

export const dispatchSetContainmentAllowedSymbolIds = (allowedSymbolIds: string[]) => {
    getStore().dispatch(setContainmentAllowedSymbolIds(allowedSymbolIds));
};

export const hasContainmentAllowedSymbol = (): boolean => {
    const state = getStore().getState();
    return ContainmentAllowedSymbolIdsSelectors.hasContainmentAllowedSymbolIds(state);
};

import icBold from 'icons/toolbar/controls/ic-font-bold.svg';
import React, { SyntheticEvent, useContext } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import type { Editor } from '@tiptap/react';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { StateObserver } from '../../common/sharedState.class';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';

const getFocusedBlock = (editor: Editor) => {
    return editor.isActive('bold');
};

const getChangedBlock = (editor: Editor) => {
    return editor.chain().focus().toggleBold().run();
};

export const BoldText = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);

    const { setCurrentState, value } = useSharedState<boolean, SyntheticEvent<HTMLElement, Event>>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <ToolbarButton
            icon={icBold}
            dataTest="wiki-toolbar-group_font-inline-style_BOLD"
            tooltip={intl.formatMessage(messages.textBold)}
            onClick={setCurrentState}
            size="small"
            isActive={value}
        />
    );
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Table } from 'antd';
import messages from './GeneratorImageSelector.messages';
import theme from './GeneratorImageSelector.scss';
import { useIntl } from 'react-intl';
import { uniqBy } from 'lodash-es';
import { PresetImageGroupSelectors } from '../../../../../../../../selectors/presetSettings/presetImageGroup.selectors';
import { LocalesService } from '../../../../../../../../services/LocalesService';
import { getCurrentLocale } from '../../../../../../../../selectors/locale.selectors';
import { PresetImage } from '../../../../../../../../serverapi/api';
import { DialogFooterButtons } from '../../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TGeneratorImageSelectorProps = {
    presetImages: PresetImage[];
    onSvgModalVisibleChange: () => void;
    onSaveSelectedIconToSymbolGenerator: (image: PresetImage | undefined) => void;
    serverId: string;
    presetId: string;
};

export const GeneratorImageSelector = ({
    onSvgModalVisibleChange,
    presetImages,
    serverId,
    presetId,
    onSaveSelectedIconToSymbolGenerator,
}: TGeneratorImageSelectorProps) => {
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);
    const [selectedIcon, setSelectedIcon] = useState<PresetImage | undefined>(
        presetImages.length ? presetImages[0] : undefined,
    );

    const renderDefaultColumn = (value, record): React.ReactNode => (
        <span>
            <span>{LocalesService.internationalStringToString(value, currentLocale)}</span>
        </span>
    );

    const presetImageGroups = useSelector(
        PresetImageGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId,
        }),
    );

    const types = presetImages?.map((type) => ({
        ...type,
        groupId: type.presetImageGroup?.id,
        name: LocalesService.internationalStringToString(type.multilingualName),
    }));

    const getGroupElements = (group) => {
        const elements = types.filter((type) => type.groupId === group.id);

        return uniqBy(elements, 'id');
    };

    const getDataSource = presetImageGroups.map((group) => {
        return {
            ...group,
            expandable: true,
            children: getGroupElements(group),
        };
    });

    const columns = [
        {
            dataIndex: 'multilingualName',
            title: intl.formatMessage(messages.iconLib),
            render: renderDefaultColumn,
        },
        {
            title: intl.formatMessage(messages.image),
            dataIndex: 'graphical',
            render: (value: string) => value && <img width={50} height={50} src={value} />,
        },
    ];

    const onOkHandler = () => {
        onSaveSelectedIconToSymbolGenerator(selectedIcon);
        onSvgModalVisibleChange();
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onSvgModalVisibleChange,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onOkHandler,
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                    dataTest: 'symbol-generator_save-image-btn',
                },
            ]}
        />
    );

    return (
        <div>
            {!!presetImages.length && (
                <>
                    <Modal
                        width={380}
                        open
                        bodyStyle={{ height: '300px' }}
                        onCancel={onSvgModalVisibleChange}
                        onOk={onOkHandler}
                        footer={footer}
                    >
                        <div>
                            <Table
                                className={theme.table}
                                rowKey={(record: any) => `${record.id}`}
                                dataSource={getDataSource}
                                rowClassName={(record) => (record.id === selectedIcon?.id ? theme.highligh : '')}
                                onRow={(row: PresetImage) => ({
                                    onClick: () => {
                                        setSelectedIcon(row);
                                    },
                                })}
                                expandRowByClick
                                columns={columns}
                                pagination={false}
                                size="small"
                                bordered
                                scroll={{ y: 250 }}
                                // expandIcon={() => <></>}
                            />
                        </div>
                    </Modal>
                </>
            )}
            {!presetImages.length && <div>{intl.formatMessage(messages.noImages)}</div>}
        </div>
    );
};

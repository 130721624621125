import type { TRestoreDeletedNodeDialogSubmitAction } from '@/actions/restoreDeletedNodeDialog.actions.types';
import { call, put, select } from 'redux-saga/effects';
import { closeDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { RESTORE_DELETED_NODE_DIALOG_SUBMIT } from '@/actionsTypes/restoreDeletedNodeDialog.actionTypes';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { treeItemRefresh } from '@/actions/tree.actions';
import { showNotification, showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { SearchResult } from '@/serverapi/api';
import { getSearchedElementsByRepositoryIdById } from '@/selectors/searchedDeletedElements.selectors';
import { SearchDeletedElementsBllService } from '@/services/bll/SearchDeletedElementsBLLService';
import { eraseSearchedDeletedElementsSuccess } from '@/actions/searchedDeletedElements.actions';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import ProcessIndicatorMessages from '@/modules/StatusBar/components/ProcessIndicator/ProcessIndicator.messages';
import { ProcessType, TExecutingProcess } from '@/reducers/statusBar.reducer.types';
import { v4 as uuid } from 'uuid';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { setProcessIndicator } from '@/actions/statusBar.actions';
import { takeEverySafely } from './sagaHelpers';

function* handlRestoreDeletedNodeDialogSubmit({ payload }: TRestoreDeletedNodeDialogSubmitAction) {
    const {
        nodeId,
        nodeId: { serverId, repositoryId },
        newParentNodeId,
        restoreParent,
        restoreChildren,
        restoreOnlySameDeleteOperation,
        restoreModelElements,
    } = payload;

    const locale = yield select(getCurrentLocale);
    const nodeName: string = yield select(TreeSelectors.getNodeNameById(nodeId));
    const intl = LocalesService.useIntl(locale);
    const processName: string = `${intl.formatMessage(ProcessIndicatorMessages.processRestore)} "${nodeName}"`;
    const process: TExecutingProcess = { id: uuid(), name: processName, type: ProcessType.RESTORE };
    yield put(setProcessIndicator(true, process));

    try {
        yield call(() =>
            TreeDaoService.restore([nodeId], {
                newParentId: newParentNodeId?.id,
                restoreParent,
                restoreChildren,
                restoreOnlySameDeleteOperation,
                restoreModelElements,
            }),
        );

        yield put(closeDialog(DialogType.RESTORE_DELETED_NODE_DIALOG));
        yield put(treeItemRefresh({ serverId, repositoryId, id: repositoryId }));

        const searchResult: SearchResult[] = yield select(
            getSearchedElementsByRepositoryIdById(nodeId.repositoryId, nodeId.repositoryId),
        );
        if (searchResult.length > 0) {
            const idsToDeleteFromStore = SearchDeletedElementsBllService.getIdsToDeleteOnRestoreNodes(
                nodeId.repositoryId,
                [nodeId.id],
                searchResult,
            );

            yield put(
                eraseSearchedDeletedElementsSuccess({
                    repositoryId: nodeId.repositoryId,
                    id: nodeId.repositoryId,
                    idsToDelete: idsToDeleteFromStore,
                }),
            );
        }

        if (newParentNodeId && newParentNodeId.repositoryId !== repositoryId) {
            yield put(
                treeItemRefresh({
                    serverId,
                    repositoryId: newParentNodeId.repositoryId,
                    id: newParentNodeId.repositoryId,
                }),
            );
        }

        yield put(
            showNotification({
                id: uuid(),
                data: { message: nodeName || '' },
                type: NotificationType.RESTORE_NODE_SUCCESS,
            }),
        );
    } catch (e) {
        yield put(showNotificationByType(NotificationType.RESTORE_NODE));
    } finally {
        yield put(setProcessIndicator(false, process));
    }
}

export function* restoreDeletedNodeDialogSaga() {
    yield takeEverySafely(RESTORE_DELETED_NODE_DIALOG_SUBMIT, handlRestoreDeletedNodeDialogSubmit);
}

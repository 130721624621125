import { FORMAT_BUTTON_LEFT_OFFSET } from '../../../ResizableToolbar/ResizableToolbar.constants';
import { SMALL_TOOLBAR_BUTTON_SIZE } from '../../../ToolbarButton/ToolbarButton.constants';

const FORMAT_BUTTON_WIDTH = SMALL_TOOLBAR_BUTTON_SIZE;

const DROPDOWN_BUTTON_WIDTH = SMALL_TOOLBAR_BUTTON_SIZE;

export const getChildrenToHide = (childrenWidths: number[], toolbarWidth: number, withFormatBtn: boolean): number => {
    let childrenWidth = childrenWidths.reduce(function (a, b) {
        return a + b;
    }, 0);

    let childrenToHide: number = 0;
    const formatButtonWidth: number = withFormatBtn ? FORMAT_BUTTON_WIDTH : 0;
    const allAditionalButtonsWidth: number = withFormatBtn
        ? FORMAT_BUTTON_WIDTH + DROPDOWN_BUTTON_WIDTH + FORMAT_BUTTON_LEFT_OFFSET
        : DROPDOWN_BUTTON_WIDTH;

    if (childrenWidth < toolbarWidth - formatButtonWidth) return childrenToHide;
    if (Math.round(childrenWidth) > Math.round(toolbarWidth - allAditionalButtonsWidth)) {
        while (childrenWidth && childrenWidth > toolbarWidth - allAditionalButtonsWidth) {
            childrenToHide = childrenToHide + 1;
            childrenWidth = childrenWidths
                .filter((el, index) => index < childrenWidths.length - childrenToHide * 2)
                .reduce(function (a, b) {
                    return a + b;
                }, 0);
        }
    }

    return childrenToHide;
};

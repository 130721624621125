import { useIntl } from 'react-intl';
import React from 'react';
import messages from '../../FormatPanel.messages';
import { useDispatch, useSelector } from 'react-redux';
import theme from './FontAndFillingColor.scss';
import { ColorResult } from 'react-color';
import { changeEdgeColor, editorCellColorPickedAction } from '../../../../actions/editor.actions';
import { MxCell } from '../../../../mxgraph';
import { ColorPicker } from '../../../UIKit/components/ColorPicker/ColorPicker.component';
import { FormatPanelButtonsState } from '../../../../selectors/generalMenu.selectors';
import { FormatPanelSelectors } from '../../../../selectors/formatPanel.selectors';
import { EditorToolbarSelectors } from '../../../../selectors/editorToolbar.selectors';
import { TSelectedCellElements } from '../../../../services/bll/ToolbarButtonsBllService.types';
import { filterSelectedCells } from '../../utils/format.utils';

export const FillingColorBlock = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { rawSelectedCells }: TSelectedCellElements = useSelector(
        EditorToolbarSelectors.getSelectedCellsInActiveGraph,
    );
    const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);

    const currentColor: string | undefined = useSelector(FormatPanelButtonsState.getFillColor);

    const hasSelectedEdge: boolean = selectedCells.some((cell) => cell.isEdge());
    const hasSelectedObject: boolean = selectedCells.some((cell) => cell.isVertex());

    const handleFontColorChange = (color: ColorResult) => {
        if (hasSelectedEdge) dispatch(changeEdgeColor(color.hex));
        if (hasSelectedObject) dispatch(editorCellColorPickedAction(color.hex));
    };

    const disabled: boolean = useSelector(FormatPanelSelectors.getFormatButtonsDisabled);
    const tooltip: string = useSelector(FormatPanelSelectors.getFormatButtonsTooltip(''));

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.fillingColor)}</div>
            <ColorPicker
                currentColor={currentColor}
                onChange={handleFontColorChange}
                disabled={disabled}
                dataTest={'object-toolbar_font-text-format'}
                tooltip={tooltip}
            />
        </div>
    );
};

import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TreeItemContext, TreeItemType } from '../../models/tree';
import { TreeNode } from '@/models/tree.types';

export const LEVEL_PADDING = 9;

export const getTreeItemContextByCountOfSelectedElements = (moreThanOneElementSelected?: boolean, twoModelsSelected?: boolean): TreeItemContext | undefined =>{
    if(moreThanOneElementSelected){
        if(twoModelsSelected){
            return TreeItemContext.TwoModelsSelected;                
        }
        return TreeItemContext.MoreThanOneElementSelected;
        
    }
    return;
}


export const getTreeItemContextByLastOpenDialogType = (lastOpenDialogType?: DialogType): TreeItemContext => {
    if (!lastOpenDialogType) {
        return TreeItemContext.Default;
    }

    const context: Partial <Record <DialogType, TreeItemContext>> = {        
        [DialogType.OBJECT_DECOMPOSITION_CREATE]: TreeItemContext.CreateDecompositionDialog,        
        [DialogType.MODEL_DIALOG]: TreeItemContext.CreateModelDialog,
        [DialogType.EVENT_SCRIPT_DIALOG]: TreeItemContext.CreateScriptDialog,
    };

    return context[lastOpenDialogType] || TreeItemContext.Default;
};

export const allowedNodeSelection = (
    selectedTreeItem: TreeNode,
    includeFilterForSelect?: TreeItemType[],
    excludeFilterForSelect?: TreeItemType[],
): boolean => {
    let allowedSelection = true;
    if (includeFilterForSelect && excludeFilterForSelect) {
        allowedSelection =
            includeFilterForSelect.includes(selectedTreeItem.type) &&
            !excludeFilterForSelect.includes(selectedTreeItem.type);
    } else {
        if (includeFilterForSelect) allowedSelection = includeFilterForSelect.includes(selectedTreeItem.type);
        if (excludeFilterForSelect) allowedSelection = !excludeFilterForSelect.includes(selectedTreeItem.type);
    }

    return allowedSelection;
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';
import { LabelStyle } from '../../../../models/labelStyle';
import { FormatPanelButtonsState } from '../../../../selectors/generalMenu.selectors';
import { FormatPanelSelectors } from '../../../../selectors/formatPanel.selectors';
import { Select } from '../../../UIKit/components/Select/Select.component';
import { fontSizeOptions } from '../../../../utils/configuration';
import messages from '../../FormatPanel.messages';
import { useIntl } from 'react-intl';
import { TValue } from '../../../UIKit/components/Toolbar/ResizableToolbar/ButtonGroups/toolbarSelect.types';
import { ClearValueButton } from '../../../UIKit/components/Toolbar/ClearValuesBtn/ClearValuesButton.component';
import { AddNewValueButton } from '../../../UIKit/components/Toolbar/AddNewValueBtn/AddNewValueButton.component';
import {
    addModelFormatParamValue,
    deleteAllModelFormatParamValues,
} from '../../../../actions/addedModelFormatParams.actions';
import { FormatParamValues } from '../../../../reducers/addedModelFormatParams.reducer.types';
import { AddedModelParamsSelectors } from '../../../../selectors/addedModelFormatParams.selectors';

const validateNumber = (rule: {}, value: string, callback: (msg?: string) => void) => {
    if (isNaN(+value) || +value < 0) {
        callback('not number');
    } else {
        callback();
    }
};

export const FontSize = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const disabled: boolean = useSelector(FormatPanelSelectors.getFormatButtonsDisabled);
    const tooltip: string = useSelector(
        FormatPanelSelectors.getFormatButtonsTooltip(intl.formatMessage(messages.fontSize)),
    );

    const fontSize: string = useSelector(FormatPanelButtonsState.getFontSize);

    const addedFontSizers: TValue[] = useSelector(
        AddedModelParamsSelectors.getAddedParamsByActiveModelId(FormatParamValues.fontSizes),
    );

    const onChange = (labelStyle: LabelStyle) => (value: string) => {
        if (['del', 'add'].includes(value)) return;
        dispatch(generalMenuLabelStyleChangeAction(labelStyle, value, false));
    };

    const handleAdd = (value: string) => {
        dispatch(addModelFormatParamValue(value, FormatParamValues.fontSizes));
    };

    const handleDeleteAll = () => {
        dispatch(deleteAllModelFormatParamValues(FormatParamValues.fontSizes));
    };

    const additonalBtns: TValue[] = new Array().concat(
        !!addedFontSizers.length
            ? {
                  value: 'del',
                  label: <ClearValueButton onClicked={handleDeleteAll} />,
              }
            : [],
        {
            value: 'add',
            label: (
                <AddNewValueButton
                    onAddClicked={(value) => handleAdd(value)}
                    title={intl.formatMessage(messages.fontSizePt)}
                    validationRules={[
                        { required: true, message: intl.formatMessage(messages.formValidationMessageRequired) },
                        {
                            validator: validateNumber,
                            message: intl.formatMessage(messages.formValidationMessageNumberOnly),
                        },
                    ]}
                />
            ),
        },
    );

    return (
        <Select
            onChange={onChange(LabelStyle.fontSize)}
            value={fontSize}
            disabled={disabled}
            placeholder=""
            originalTheme
            data-test="object-toolbar_font-size-select"
            tooltip={tooltip}
        >
            {[...fontSizeOptions, ...addedFontSizers, ...additonalBtns].map((fontSize) => {
                return <Select.Option value={fontSize.value} label={fontSize.label} />;
            })}
        </Select>
    );
};

export enum Locale {
    ru = 'ru',
    en = 'en',
    es = 'es',
}

export type THeaderProps = {
    locale: Locale;
    onClose: () => void;
    isMaximized: boolean;
    isVisibleButtonActions: boolean;
    onToggleSize: () => void;
    onMinimize: () => void;
    onCopyAction?: () => void;
    onPasteAction?: () => void;
    onClickInitialsCircle?: () => void;
    onLocaleSelect: (selected: Locale) => void;
    isStarted: boolean;
    isEnLocaleAllowed: boolean;
    clientIcon: string | null;
} & JSX.IntrinsicAttributes;

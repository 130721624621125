export const FILE_UPLOAD = 'FILE_UPLOAD';

export const IMAGE_FILE_UPLOAD = 'IMAGE_FILE_UPLOAD';

export const FILE_DOWNLOAD = 'FILE_DOWNLOAD';

export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';

export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL';

export const FILE_OPEN = 'FILE_OPEN';

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardWidgetContainer__dashboardWidget__Xoq7I{background-color:var(--BgNeutral10);color:#000;position:absolute;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-box-sizing:border-box;box-sizing:border-box}.DashboardWidgetContainer__dashboardWidget__Xoq7I>div:first-child{border:2px solid var(--BorderSecondaryNeutral200)}.DashboardWidgetContainer__dashboardWidgetHover___L70y{cursor:pointer;border:2px solid var(--Main600)}.DashboardWidgetContainer__dashboardSizer__XC9LW{position:absolute;width:8px;height:8px;background-color:var(--Main600);cursor:se-resize;border-radius:0}", "",{"version":3,"sources":["webpack://./src/modules/Dashboard/DashboardWidgetContainer/DashboardWidgetContainer.scss"],"names":[],"mappings":"AAAA,kDACI,mCAAA,CACA,UAAA,CACA,iBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,oBAAA,CAAA,gBAAA,CACA,6BAAA,CAAA,qBAAA,CACA,kEACI,iDAAA,CAIR,uDACI,cAAA,CACA,+BAAA,CAGJ,iDACI,iBAAA,CACA,SAAA,CACA,UAAA,CACA,+BAAA,CACA,gBAAA,CACA,eAAA","sourcesContent":[".dashboardWidget {\r\n    background-color: var(--BgNeutral10);\r\n    color: black;\r\n    position: absolute;\r\n    user-select: none;\r\n    box-sizing: border-box;\r\n    > div:first-child {\r\n        border: 2px solid var(--BorderSecondaryNeutral200);\r\n    }\r\n}\r\n\r\n.dashboardWidgetHover {\r\n    cursor: pointer;\r\n    border: 2px solid var(--Main600);\r\n}\r\n\r\n.dashboardSizer {\r\n    position: absolute;\r\n    width: 8px;\r\n    height: 8px;\r\n    background-color: var(--Main600);\r\n    cursor: se-resize;\r\n    border-radius: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardWidget": "DashboardWidgetContainer__dashboardWidget__Xoq7I",
	"dashboardWidgetHover": "DashboardWidgetContainer__dashboardWidgetHover___L70y",
	"dashboardSizer": "DashboardWidgetContainer__dashboardSizer__XC9LW"
};
export default ___CSS_LOADER_EXPORT___;

import { AllowedScriptContext } from './../serverapi/api';
import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '../serverapi/api';
import { TScriptState } from '../reducers/entities/script.reducer.types';
import { TabsSelectors } from './tabs.selectors';
import { LocalesService } from '../services/LocalesService';
import messages from '@/modules/MainMenu/messages/CommonToolbar.messages';

const scriptStateSelector = (state: TRootState): TScriptState => state.script;

export namespace ScriptSelectors {
    export const byId = (scriptId: NodeId) => createSelector(scriptStateSelector, (state) => state.get(scriptId));

    export const context = (scriptId: NodeId) =>
        createSelector<TRootState, TScriptState, AllowedScriptContext>(
            scriptStateSelector,
            (state) => state.get(scriptId)?.allowedScriptContext || {},
        );

    export const getEditModeButtonTooltip = createSelector<TRootState, boolean, string>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const intl = LocalesService.useIntl();

            return isReadMode ? intl.formatMessage(messages.toEditMode) : intl.formatMessage(messages.toReadMode);
        },
    );
}

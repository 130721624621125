import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icPrint from '@/resources/icons/MainMenuPrinter.svg';
import { printModel } from '../../../../../../actions/image.actions';
import { NodeId } from '../../../../../../serverapi/api';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';

export const PrintButton = () => {
    const dispatch = useDispatch();
    const modelId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getPrintBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getPrintBtnTooltip);

    return (
        <ToolbarButton
            icon={icPrint}
            size="small"
            dataTest="general-toolbar_print-btn"
            disabled={disabled}
            onClick={() => dispatch(printModel(modelId))}
            tooltip={tooltip}
        />
    );
};

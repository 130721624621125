import { Form, Table } from 'antd';
import React, { FC, useState } from 'react';
import { ModelLite, NodeDefinitionInstanceInfo, NodeId } from '../../../serverapi/api';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';
import theme from './ObjectPropertiesDialog.scss';
import messages from '../messages/ObjectPropertiesDialog.messages';
import { useIntl } from 'react-intl';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { TreeItemType } from '../../Tree/models/tree';
import { objectDecompositionOpen } from '../../../actions/entities/objectDecomposition.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';
import { TModelTypes } from '@/services/types/NavigatorEdgePropertyBll.types';

type TConnectedModelsProps = {
    parentObjectsInfo: NodeDefinitionInstanceInfo[];
    toNodeId: (id: string) => NodeId;
    nodeId: NodeId;
};

type TTableRow = {
    key: string;
    modelName: string;
    modelTypeName: string;
    modelNodeId: NodeId;
};

export const ConnectedModels: FC<TConnectedModelsProps> = (props) => {
    const { parentObjectsInfo, toNodeId, nodeId } = props;

    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const modelTypes: TModelTypes =
        useSelector(ModelTypeSelectors.byServerIdPresetId(nodeId.serverId, presetId)).byId || [];
    const [selectedRowId, setSelectedRowId] = useState<number | undefined>(undefined);
    const dispatch = useDispatch();
    const intl = useIntl();

    const tableData: TTableRow[] = parentObjectsInfo?.length
        ? parentObjectsInfo
              .reduce((acc: ModelLite[], parent: NodeDefinitionInstanceInfo) => {
                  parent.nodeAllocations?.forEach((currentModel) => {
                      if (!acc.some((model) => model.modelId === currentModel.modelId)) {
                          acc.push(currentModel);
                      }
                  });

                  return acc;
              }, [])
              .map((model) => {
                  const modelTypeName: string = model.modelTypeId
                      ? modelTypes[model.modelTypeId]?.name || model.modelTypeId || ''
                      : '';
                  const modelNodeId: NodeId = toNodeId(model.modelId || '');

                  return {
                      key: uuid(),
                      modelName: model.modelName || '',
                      modelTypeName,
                      modelNodeId,
                  };
              })
        : [];

    const rowSelect = (rowId: number) => () => {
        const setArgument = selectedRowId === rowId ? undefined : rowId;

        setSelectedRowId(setArgument);
    };

    const connectedModelsColumns = [
        {
            title: intl.formatMessage(messages.modelName),
            dataIndex: 'modelName',
            width: '50%',
        },
        {
            title: intl.formatMessage(messages.modelTypeTitle),
            dataIndex: 'modelTypeName',
            width: '50%',
        },
    ];

    const handleOpenModel = () => {
        if (selectedRowId !== undefined) {
            const id: string | undefined = tableData[selectedRowId]?.modelNodeId?.id;

            if (id) {
                dispatch(
                    objectDecompositionOpen({
                        nodeId: toNodeId(id),
                        type: TreeItemType.Model,
                    }),
                );
            }
        }
    };

    return (
        <FormGroup className={theme.formGroup}>
            <Form.Item>
                <Table
                    dataSource={tableData}
                    className={theme.table}
                    columns={connectedModelsColumns}
                    size="middle"
                    bordered
                    pagination={false}
                    onRow={(row: TTableRow) => ({
                        onClick: rowSelect(tableData.indexOf(row)),
                    })}
                    rowClassName={(row: TTableRow) =>
                        cx(theme.attribute, selectedRowId === tableData.indexOf(row) && theme.attribute_selected)
                    }
                    scroll={{
                        y: 'max-content',
                        x: 'max-content',
                    }}
                />
                <div>
                    <div className={theme.detailingActionsInner}>
                        <Button size="large" disabled={selectedRowId === undefined} onClick={handleOpenModel}>
                            {intl.formatMessage(messages.open)}
                        </Button>
                    </div>
                </div>
            </Form.Item>
        </FormGroup>
    );
};

import React, { FC, useEffect, useId, useRef } from 'react';
import theme from './EllipsisText.scss';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';

type TEllipsisTextProps = {
    text: string;
    maxLinesCount?: number;
    isHeader?: boolean;
    dndListeners?: SyntheticListenerMap;
    dndAttributes?: DraggableAttributes;
};

const ACCEPTABLE_RIGHT_SPACE = 150;
const TOOLTIP_MAX_WIDTH = 300;
const TOOLTIP_MIN_WIDTH = 150;
const TOOLTIP_MARGIN = 5;

export const EllipsisText: FC<TEllipsisTextProps> = ({
    text,
    isHeader,
    maxLinesCount = 6,
    dndAttributes,
    dndListeners,
}) => {
    const tooltipId = useId();
    const tmp = useRef(null);

    useEffect(() => {
        return () => {
            document.getElementById(tooltipId)?.remove();
        };
    }, []);

    const onMouseEnterHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        const target = event.currentTarget;
        const spanElement = target.querySelector('span') as HTMLSpanElement;
        const { offsetHeight, scrollHeight } = spanElement;

        const bounds: DOMRect = target.getBoundingClientRect();

        let top = bounds.top + bounds.height / 2;
        let left = bounds.right + TOOLTIP_MARGIN;
        const rightSpace = window.innerWidth - bounds.right;

        if (rightSpace < ACCEPTABLE_RIGHT_SPACE) {
            left = bounds.left - TOOLTIP_MAX_WIDTH;
            top = bounds.top + bounds.height;
        }

        if (offsetHeight !== scrollHeight) {
            const tooltip: HTMLDivElement = document.createElement('div');
            tooltip.id = tooltipId;
            tooltip.classList.add(theme.tooltip);
            tooltip.style.top = `${top}px`;
            tooltip.style.left = `${left}px`;
            tooltip.style.maxWidth = `${TOOLTIP_MAX_WIDTH}px`;
            tooltip.style.minWidth = `${TOOLTIP_MIN_WIDTH}px`;
            tooltip.innerText = text;
            document.body.append(tooltip);
        }
    };

    const onMouseLeaveHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        document.getElementById(tooltipId)?.remove();
    };

    return (
        <div
            ref={tmp}
            className={isHeader ? theme.headerTooltipContainer : theme.tooltipContainer}
            onMouseOver={onMouseEnterHandler}
            onMouseOut={onMouseLeaveHandler}
            {...dndAttributes}
            {...dndListeners}
        >
            <span className={theme.textContainer} style={{ WebkitLineClamp: maxLinesCount }}>
                {text}
            </span>
        </div>
    );
};

import { TextBlockStyle } from './types';

export const getTextBlockRenderedSize = (text: string, blockStyle?: Partial<TextBlockStyle>) => {
    const div: HTMLDivElement = document.createElement('div');

    if (blockStyle) {
        Object.keys(blockStyle).forEach((key) => {
            div.style[key] = blockStyle[key];
        });
    }
    div.innerHTML = String(text);

    document.body.append(div);
    const height = div.offsetHeight;
    const width = div.offsetWidth;

    const oneRowDiv: HTMLDivElement = document.createElement('div');

    if (blockStyle) {
        Object.keys(blockStyle).forEach((key) => {
            oneRowDiv.style[key] = blockStyle[key];
        });
    }
    oneRowDiv.style.width = 'min-content';
    oneRowDiv.style.whiteSpace = 'nowrap';

    oneRowDiv.innerHTML = String(text);
    document.body.append(oneRowDiv);

    const oneRowWidth = oneRowDiv.offsetWidth;

    div.remove();
    oneRowDiv.remove();

    return { width, height, oneRowWidth };
};

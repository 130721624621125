import { defineMessages } from 'react-intl';

export default defineMessages({
    PNG: {
        id: 'GetImage.PNG',
        defaultMessage: 'PNG',
    },
    SVG: {
        id: 'GetImage.SVG',
        defaultMessage: 'SVG',
    },
    JPG: {
        id: 'GetImage.JPG',
        defaultMessage: 'JPG',
    },
    PDF: {
        id: 'GetImage.PDF',
        defaultMessage: 'PDF',
    },
    inBuffer: {
        id: 'GetImage.inBuffer',
        defaultMessage: 'В буфер обмена',
    },
});

import {
    CHANGE_NAVIGATOR_SYMBOLS_SEARCH_STATUS,
    CHANGE_NAVIGATOR_SYMBOLS_SEARCH_STRING,
} from '@/actionsTypes/navigatorSymbolsSearch.actionTypes';
import {
    TChangeNavigatorSymbolsSearchStatusAction,
    TChangeNavigatorSymbolsSearchStatusPayload,
    TChangeNavigatorSymbolsSearchStringAction,
    TChangeNavigatorSymbolsSearchStringPayload,
} from './navigatorSymbolsSearch.actions.types';

export const changeNavigatorSymbolsSearchStatus = (
    payload: TChangeNavigatorSymbolsSearchStatusPayload,
): TChangeNavigatorSymbolsSearchStatusAction => ({
    type: CHANGE_NAVIGATOR_SYMBOLS_SEARCH_STATUS,
    payload,
});

export const changeNavigatorSymbolsSearchString = (
    payload: TChangeNavigatorSymbolsSearchStringPayload,
): TChangeNavigatorSymbolsSearchStringAction => ({
    type: CHANGE_NAVIGATOR_SYMBOLS_SEARCH_STRING,
    payload,
});

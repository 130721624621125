import React, { useEffect } from 'react';
import saveIcon from '@/resources/icons/save.svg';
import { NodeId } from '../../../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { saveModel } from '@/actions/save.actions';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';

export const SaveButton = () => {
    const disabled: boolean = useSelector(EditorToolbarSelectors.getSaveButtonDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getSaveButtonBtnTooltip);
    const activeTabNodeId: NodeId | undefined = useSelector(TabsSelectors.getActiveTabId);

    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(
            saveModel({
                graphId: activeTabNodeId,
                attempts: 1,
                showNotification: true,
                forceSave: true,
                forceSaveHistory: true,
            }),
        );
    };

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            // ctrl + s
            if ((e.metaKey || e.ctrlKey) && e.code === 'KeyS') {
                e.preventDefault();
                e.stopPropagation();

                if (activeTabNodeId.id !== 'homePageTab' && !disabled) {
                    dispatch(
                        saveModel({
                            graphId: activeTabNodeId,
                            showNotification: true,
                            forceSave: true,
                            attempts: 1,
                            forceSaveHistory: true,
                        }),
                    );
                }
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [activeTabNodeId, disabled]);

    return (
        <ToolbarButton
            icon={saveIcon}
            size="small"
            dataTest="general-toolbar_save-button"
            disabled={disabled}
            onClick={onClick}
            tooltip={tooltip}
        />
    );
};

import { processError } from '@/actions/error.actions';
import { getStore } from '@/store';
import { Task } from 'redux-saga';
import { fork, spawn, take } from 'redux-saga/effects';

export const takeEverySafely = (pattern, worker) =>
    fork(function* () {
        while (true) {
            try {
                const action = yield take(pattern);
                const task: Task = yield spawn(worker, action);
                task.toPromise().catch((e) => {
                    getStore().dispatch(processError(e, ''));
                });
            } catch (e) {
                console.log('error: ', e);
            }
        }
    });

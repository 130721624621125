import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, MenuProps } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import theme from './InstancePermissionsTable.scss';
import messages from '../../messages/InstancePermissionTable.messages';
import ArrowDown from '@/resources/icons/UIKit/Arrow_Down.svg';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { getMenuItem } from '@/utils/antdMenuItem.utils';

enum MenuActions {
    allowAll = 'allowAll',
    forbidAll = 'forbidAll',
    removeSelection = 'removeSelection',
}

type TInstancePermissionsDropsownButtonProps = {
    onAllow: () => void;
    onForbid: () => void;
    onRemove: () => void;
};

export const InstancePermissionsDropsownButton = (props: TInstancePermissionsDropsownButtonProps) => {
    const { onAllow, onForbid, onRemove } = props;
    const intl = useIntl();
    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    const onClick = (param: MenuInfo) => {
        switch (param.key) {
            case MenuActions.allowAll:
                onAllow();
                toggleOpen();
                break;
            case MenuActions.forbidAll:
                onForbid();
                toggleOpen();
                break;
            case MenuActions.removeSelection:
                onRemove();
                toggleOpen();
                break;
            default:
                break;
        }
    };

    const items: MenuProps['items'] = [
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.allowAll)}</div>,
            MenuActions.allowAll,
            false,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.forbidAll)}</div>,
            MenuActions.forbidAll,
            false,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.removeSelection)}</div>,
            MenuActions.removeSelection,
            false,
        ),
    ];

    return (
        <Dropdown
            onOpenChange={toggleOpen}
            open={open}
            menu={{ onClick, items }}
            trigger={['click']}
            destroyPopupOnHide
            overlayClassName={theme.dropdown}
            placement="bottomRight"
        >
            <Button icon={ArrowDown} size="small" visualStyle="text" />
        </Dropdown>
    );
};

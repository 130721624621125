import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { WikiType } from '../serverapi/api';
import { IReportModelTypeData } from '@/reducers/reportModelType.reducer.types';
import { INITIAL_WIKI_MODEL_TYPE_SERVER_STATE } from '../reducers/wikiModelType.reducer';
import { IWikiModelTypeData, TWikiModelTypeState } from '../reducers/wikiModelType.reducer.types';

const wikiModelTypeStateSelector = (state: TRootState) => state.wikiModelType;

const toWikiModelTypeArray = (srcData: IReportModelTypeData): WikiType[] => {
    return Object.keys(srcData.byId).map((k) => srcData.byId[k]);
};

export namespace WikiModelTypeSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector<TRootState, TWikiModelTypeState, IWikiModelTypeData>(wikiModelTypeStateSelector, (state) => {
            const serverModelTypeState = state[presetId];
            if (serverModelTypeState) {
                return { ...serverModelTypeState };
            }

            return { ...INITIAL_WIKI_MODEL_TYPE_SERVER_STATE };
        });

    export const getAllWikiModelTypes = (presetId: string) =>
        createSelector<TRootState, IWikiModelTypeData, WikiType[]>(byPresetId(presetId), (state) =>
            toWikiModelTypeArray(state),
        );

    export const byId = (modelTypeId: string, presetId: string) =>
        createSelector<TRootState, IWikiModelTypeData, WikiType | undefined>(byPresetId(presetId), (state) => {
            return state.byId[modelTypeId];
        });
}

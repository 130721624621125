import React, { FC, memo, useMemo, useRef } from 'react';
import { ColumnDef, getCoreRowModel, OnChangeFn, RowSelectionState, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import theme from './ReportEditorTable.scss';
import { TReportTableData } from '../ReportEditor.types';
import { DndContext, MouseSensor, closestCenter, type DragEndEvent, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { DraggableTableHeader } from './DraggableTableHeader.component';
import { TableCell } from './TableCell.component';
import { ADD_COLUMN_ID, CHECKBOX_COLUMN_ID, OPEN_ELEMENT_COLUMN_ID } from './ReportEditorTable.const';
import { getRowClassName } from './ReportEditorTable.utils';

type TReportEditorTableProps = {
    isReadMode: boolean;
    data: TReportTableData[];
    columns: ColumnDef<TReportTableData, string>[];
    columnOrder: string[];
    setColumnOrder: React.Dispatch<React.SetStateAction<string[]>>;
    rowSelection?: RowSelectionState;
    setRowSelection?: OnChangeFn<RowSelectionState>;
    onColumnClick: (columnId: string) => void;
    onColumnOrderChange: (columnOrder: string[]) => void;
};

export const ReportEditorTable: FC<TReportEditorTableProps> = memo(
    ({
        isReadMode,
        data,
        columns,
        rowSelection,
        columnOrder,
        setColumnOrder,
        setRowSelection,
        onColumnClick,
        onColumnOrderChange,
    }) => {
        const tableContainerRef = useRef<HTMLDivElement>(null);

        const table = useReactTable({
            data,
            columns,
            getCoreRowModel: getCoreRowModel(),
            enableColumnResizing: true,
            columnResizeMode: 'onChange',
            enableRowSelection: true,
            onRowSelectionChange: setRowSelection,
            state: {
                columnOrder,
                rowSelection,
            },
            defaultColumn: {
                minSize: 100,
                maxSize: 500,
                size: 150,
            },
            onColumnOrderChange: setColumnOrder,
        });

        const { rows } = table.getRowModel();

        const virtualizer = useVirtualizer({
            count: rows.length,
            overscan: 5,
            getScrollElement: () => tableContainerRef.current,
            measureElement: (element) => element?.getBoundingClientRect().height,
            estimateSize: () => 38,
        });

        const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { delay: 200, tolerance: 100 } }));

        const handleDragEnd = (event: DragEndEvent) => {
            const { active, over } = event;
            if (active && over && active.id !== over.id) {
                setColumnOrder((columnOrder) => {
                    const oldIndex = columnOrder.indexOf(active.id as string);
                    const newIndex = columnOrder.indexOf(over.id as string);
                    const newColumnOrder: string[] = arrayMove(columnOrder, oldIndex, newIndex);
                    onColumnOrderChange(newColumnOrder);
                    return newColumnOrder;
                });
            }
        };

        const tableWidth: number = table.getCenterTotalSize() + table.getLeftTotalSize();
        const tableStyle: React.CSSProperties = useMemo(() => ({ width: tableWidth }), [tableWidth]);

        const totalSize: number = virtualizer.getTotalSize();
        const tbodyStyle: React.CSSProperties = useMemo(
            () => ({
                height: totalSize,
            }),
            [totalSize],
        );

        return (
            <div ref={tableContainerRef} className={theme.tableContainer}>
                <DndContext
                    collisionDetection={closestCenter}
                    modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
                    onDragEnd={handleDragEnd}
                    sensors={sensors}
                    autoScroll={{
                        threshold: {
                            x: 0.2,
                            y: 0,
                        },
                        acceleration: 60,
                    }}
                >
                    <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                        <table style={tableStyle}>
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            const { column } = header;
                                            const isPinned = column.getIsPinned();
                                            if (
                                                (column.id === ADD_COLUMN_ID || column.id === OPEN_ELEMENT_COLUMN_ID) &&
                                                !isPinned
                                            ) {
                                                column.pin('right');
                                            }

                                            if (column.id === CHECKBOX_COLUMN_ID && !isPinned) {
                                                column.pin('left');
                                            }

                                            return (
                                                <DraggableTableHeader
                                                    key={header.id}
                                                    isReadMode={isReadMode}
                                                    header={header}
                                                    onColumnClick={onColumnClick}
                                                />
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody style={tbodyStyle}>
                                {virtualizer.getVirtualItems().map((virtualRow, index) => {
                                    const row = rows[virtualRow.index];

                                    return (
                                        <tr
                                            data-index={virtualRow.index}
                                            ref={(node) => virtualizer.measureElement(node)}
                                            key={row.id}
                                            className={getRowClassName(row)}
                                            style={{
                                                transform: `translateY(${virtualRow.start}px)`,
                                            }}
                                        >
                                            {row.getVisibleCells().map((cell) => {
                                                const isLastRow: boolean = index === rows.length - 1;

                                                return (
                                                    <TableCell
                                                        cell={cell}
                                                        isLastRow={isLastRow}
                                                        onColumnClick={onColumnClick}
                                                    />
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </SortableContext>
                </DndContext>
            </div>
        );
    },
);

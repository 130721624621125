import React from 'react';
import { NavigatorPanelButton } from '../NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { CollapseIcon } from '../NavigatorPanel/icons/CollapseIcon.component';
import { useDispatch } from 'react-redux';
import { treeCollapse } from '@/actions/tree.actions';
import { useIntl } from 'react-intl';
import messages from '../../messages/Navigator.messages';
import { Tooltip } from '../../../UIKit/components/Tooltip/Tooltip.component';

export const TreeCollapse = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClick = () => {
        dispatch(treeCollapse());
    };

    return (
        <Tooltip tooltip={{ title: intl.formatMessage(messages.collapseAll), position: 'top', offset: 5 }}>
            <>
                <NavigatorPanelButton
                    dataTest="navigator-panel_collapse_icon"
                    IconComponent={CollapseIcon}
                    onClick={handleClick}
                />
            </>
        </Tooltip>
    );
};

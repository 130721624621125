import React, { FC, useState } from 'react';
import { Form } from 'antd';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import { SMALL_DIALOG_WIDTH } from '../../config/config';
import { useDispatch } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { useIntl } from 'react-intl';
import messages from '../Editor/messages/EditorPopupMenu.messages';
import { saveGrayScaleToLocalStorage, saveHideDecompositionToLocalStorage } from '@/actions/image.actions';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';
import theme from './ImageDownloadSettingsDialog.scss';
import { DialogFooterButtons } from '../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { Checkbox } from '../UIKit/components/Checkbox/Checkbox.component';

export const ImageDownloadSettingsDialog: FC = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [form] = Form.useForm();
    const [grayScaleChecked, setGrayScaleChecked] = useState<boolean>(LocalStorageDaoService.isGrayScaleActive());
    const [decompositionHiddenChecked, setDecompositionHiddenChecked] = useState<boolean>(
        LocalStorageDaoService.isHiddenDecompositionActive(),
    );

    const handleSubmit = () => {
        dispatch(saveGrayScaleToLocalStorage(grayScaleChecked));
        dispatch(saveHideDecompositionToLocalStorage(decompositionHiddenChecked));
        dispatch(closeDialog(DialogType.IMAGE_DOWNLOAD_SETTINGS_DIALOG));
    };

    const onClose = () => {
        dispatch(closeDialog(DialogType.IMAGE_DOWNLOAD_SETTINGS_DIALOG));
    };

    const handleChangeGrayScale = (checked: boolean) => {
        setGrayScaleChecked(checked);
    };

    const handleChangeDecompositionVisability = (checked: boolean) => {
        setDecompositionHiddenChecked(checked);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.settingsDownloadingImage)}
            open
            width={SMALL_DIALOG_WIDTH}
            footer={footer}
        >
            <Form autoComplete='off' form={form} layout="vertical">
                <Form.Item className={theme.itemCheckboxWrapper}>
                    <Checkbox status={grayScaleChecked} disabled={false} onChange={handleChangeGrayScale}>
                        {intl.formatMessage(messages.grayScale)}
                    </Checkbox>
                </Form.Item>
                <Form.Item className={theme.item}>
                    <Checkbox
                        status={decompositionHiddenChecked}
                        disabled={false}
                        onChange={handleChangeDecompositionVisability}
                    >
                        {intl.formatMessage(messages.hideDecomposition)}
                    </Checkbox>
                </Form.Item>
            </Form>
        </Dialog>
    );
};

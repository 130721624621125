import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import theme from './ModelHistory.scss';
import { edgeDefinitionsAddOnlyNew } from '@/actions/entities/edgeDefinition.actions';
import { objectDefinitionsAddOnlyNew } from '@/actions/entities/objectDefinition.actions';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { EditorMode } from '@/models/editorMode';
import { ModelTypes } from '@/models/ModelTypes';
import { IWorkspaceHistoryTabParams, TWorkspaceTab } from '@/models/tab.types';
import { Spinner } from '@/modules/Spinner/Spinner.component';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { TBPMMxGraphOptions } from '@/mxgraph/bpmgraph.types';
import { DefaultGraph } from '@/mxgraph/DefaultGraph';
import EPCModelGraph from '@/mxgraph/GridGraph/EPC/EPCModelGraph.class';
import PSDModelGraph from '@/mxgraph/GridGraph/PSD/PSDModelGraph.class';
import { PSDGraph } from '@/mxgraph/psdDiagram/PSDGraph';
import { SequenceGraph } from '@/mxgraph/SequenceGraph/SequenceGraph';
import { addElementsToGraph } from '@/mxgraph/util/BpmMxEditorUtils';
import { WhiteboardGraph } from '@/mxgraph/WhiteboardGraph';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import messages from './ModelHistory.messages';
import { FullModelDefinition, ModelNode, ModelType, ModelVersion, ModelVersionInfo } from '@/serverapi/api';
import { modelService } from '@/services/ModelService';
import { SymbolsService } from '@/services/SymbolsService';
import { getSymbolsFromModelType } from '@/utils/symbol.utils';
import { formatDate } from '@/utils/date.time.utils';
import { useIntl } from 'react-intl';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import cx from 'classnames';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { TCheckboxStatus } from '@/modules/UIKit/components/Checkbox/Checkbox.types';
import { restoreModelVersionAction } from '@/actions/loadModel.actions';
import { workspaceRemoveTabByNodeId } from '@/actions/tabs.actions';
import { openCompareModelsTab } from '@/actions/compareModels.actions';
import { TModelTypes } from '@/services/types/NavigatorEdgePropertyBll.types';

type TModelHistoryProps = {
    tab: {
        params: IWorkspaceHistoryTabParams;
    } & TWorkspaceTab;
};

export const ModelHistory: FC<TModelHistoryProps> = (props) => {
    const {
        tab: {
            params: { nodeId, modelVersions },
        },
    } = props;

    const dispatch = useDispatch();
    const intl = useIntl();
    const locale: Locale = useSelector(getCurrentLocale);
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const serverUrl: string = useSelector(ServerSelectors.server(nodeId.serverId)).url;
    const modelTypes: TModelTypes =
        useSelector(ModelTypeSelectors.byServerIdPresetId(nodeId.serverId, presetId)).byId || [];
    const [activeVersion, setActiveVersion] = useState<number | undefined>(+modelVersions[0]?.version || undefined);
    const [checkedVersion1, setCheckedVersion1] = useState<number | undefined>(undefined);
    const [checkedVersion2, setCheckedVersion2] = useState<number | undefined>(undefined);
    const [svgGraph, setSvgGraph] = useState<string | undefined>(undefined);
    const graphRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        renderModel();
    }, [activeVersion]);

    const loadVersion = async (version: number): Promise<FullModelDefinition | undefined> => {
        const modelVersion: ModelVersion = await modelService().loadModelVersion(nodeId, version);
        const modelType: ModelType | undefined = modelTypes[modelVersion.model.modelTypeId || ''];
        const symbols = getSymbolsFromModelType(modelType);

        if (!modelType || !symbols) {
            return;
        }

        return {
            model: { ...modelVersion.model, nodeId },
            modelType,
            symbols,
            objects: modelVersion.objects,
            edges: modelVersion.edges,
        } as FullModelDefinition;
    };

    const drawSvg = (graph: BPMMxGraph) => {
        const svg: string = graph.getSvgStrAllGraph().svgString;
        setSvgGraph(svg);
    };

    const graphFactory = (
        modelNode: ModelNode,
        options: TBPMMxGraphOptions & { mode?: EditorMode } = {},
    ): BPMMxGraph => {
        switch (options.modelType?.id) {
            case ModelTypes.MIND_MAP:
                return new WhiteboardGraph(options);
            case ModelTypes.PSD_MODEL: {
                const { layout: gridLayout } = modelNode;

                return new PSDModelGraph({
                    ...options,
                    gridLayout,
                });
            }
            case ModelTypes.EPC_MODEL: {
                const { layout: gridLayout } = modelNode;

                return new EPCModelGraph({
                    ...options,
                    gridLayout,
                });
            }
            case ModelTypes.PSD_CHART:
                return new PSDGraph(options);
            case ModelTypes.SEQUENCE_DIAGRAM:
                return new SequenceGraph(options);
            default:
                return new DefaultGraph(options);
        }
    };

    const createGraph = (modelData: FullModelDefinition): BPMMxGraph => {
        const graph = graphFactory(modelData.model, {
            id: modelData.model.nodeId,
            modelType: modelData.modelType,
            container: graphRef.current || undefined,
            mode: 1,
        });

        const symbolsService = new SymbolsService();
        const modelGraph = modelData.model.graph ? JSON.parse(modelData.model.graph).graph : [];

        symbolsService.applyStylesToGraph(graph, modelData.symbols);
        addElementsToGraph(graph, modelGraph, modelData.model.elements, serverUrl, modelData.objects);

        return graph;
    };

    const renderModel = async () => {
        if (activeVersion) {
            const modelData = await loadVersion(activeVersion);

            if (modelData && graphRef) {
                modelData.objects && dispatch(objectDefinitionsAddOnlyNew(modelData.objects as ObjectDefinitionImpl[]));
                modelData.edges && dispatch(edgeDefinitionsAddOnlyNew(modelData.edges));

                const graph = createGraph(modelData);

                drawSvg(graph);
            }
        }
    };

    const getCreatedAtToDate = (version: ModelVersionInfo) => {
        return !version.createdAt
            ? ''
            : formatDate(
                  intl.formatMessage(messages.todayTimePrefix),
                  intl.formatMessage(messages.yesterdayTimePrefix),
                  locale,
                  new Date(version.createdAt),
              );
    };

    const onRestore = () => {
        const version: number | undefined = checkedVersion1 !== undefined ? checkedVersion1 : checkedVersion2;
        if (version !== undefined) {
            dispatch(restoreModelVersionAction(nodeId, version));
            dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId));
        }
    };

    const onCompare = () => {
        if (checkedVersion1 !== undefined && checkedVersion2 !== undefined) {
            dispatch(
                openCompareModelsTab({
                    comparedModelNodeId1: nodeId,
                    comparedModelNodeId2: nodeId,
                    element1Version: checkedVersion1,
                    element2Version: checkedVersion2,
                }),
            );
        }
    };

    const getCheckboxStatus = (version: number) => {
        if (checkedVersion1 === undefined && checkedVersion2 === undefined) return false;
        return version === checkedVersion1 || version === checkedVersion2;
    };

    const onChangeCheckboxStatus = (status: TCheckboxStatus, version: number) => {
        if (status === true) {
            if (checkedVersion1 === undefined) {
                setCheckedVersion1(version);
            } else {
                setCheckedVersion2(version);
            }
        } else if (status === false) {
            if (checkedVersion2 === version) {
                setCheckedVersion2(undefined);
            } else if (checkedVersion1 === version) {
                if (checkedVersion2 !== undefined) {
                    setCheckedVersion1(checkedVersion2);
                    setCheckedVersion2(undefined);
                } else {
                    setCheckedVersion1(undefined);
                }
            }
        }
    };

    const onChangeActiveVersion = (version: number) => {
        if (version !== activeVersion) {
            setSvgGraph(undefined);
            setActiveVersion(version);
        }
    };

    const isRestoreDisabled: boolean =
        (checkedVersion1 === undefined && checkedVersion2 === undefined) ||
        (checkedVersion1 !== undefined && checkedVersion2 !== undefined);
    const isCompareDisabled: boolean = checkedVersion1 === undefined || checkedVersion2 === undefined;

    return (
        <div className={theme.historyContainer}>
            <div className={theme.historyModelContainer}>
                <div ref={graphRef} className={theme.hidden} />
                {activeVersion ? (
                    <Spinner loading={!svgGraph}>
                        <div className={theme.svgGraph} dangerouslySetInnerHTML={{ __html: svgGraph! }} />
                    </Spinner>
                ) : null}
            </div>
            <div className={theme.historyPanel}>
                <div className={theme.historyTitle}>{intl.formatMessage(messages.tabTitle)}</div>
                <div className={theme.historyList}>
                    {modelVersions.map((version) => {
                        return (
                            <div
                                key={version.version}
                                className={cx(
                                    theme.historyListItem,
                                    activeVersion === +version.version && theme.historyVersionSelected,
                                )}
                                data-test={'model_version_history_container'}
                            >
                                <div className={theme.checkboxWrapper}>
                                    <Checkbox
                                        status={getCheckboxStatus(+version.version)}
                                        onChange={(status: TCheckboxStatus) =>
                                            onChangeCheckboxStatus(status, +version.version)
                                        }
                                    />
                                </div>

                                <div
                                    className={theme.historyVersion}
                                    onClick={() => onChangeActiveVersion(+version.version)}
                                >
                                    <div className={theme.historyDate}>{getCreatedAtToDate(version)}</div>
                                    <div className={theme.historyLogin}>{version.createdBy}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={theme.historyButtons}>
                    <Button size="large" onClick={onCompare} visualStyle="primary" disabled={isCompareDisabled}>
                        {intl.formatMessage(messages.compare)}
                    </Button>
                    <Button size="large" onClick={onRestore} visualStyle="primary" disabled={isRestoreDisabled}>
                        {intl.formatMessage(messages.restore)}
                    </Button>
                </div>
            </div>
        </div>
    );
};

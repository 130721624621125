import { Menu, Select, Table, Typography } from 'antd';
import { SelectInfo } from 'rc-menu/lib/interface';
import React, { useEffect, useState } from 'react';
import messages from '../../../messages/SimulationModeling.messages';
import errorMessages from '../../../messages/SimulationModelingErrors.messages';
import theme from './SimulationResultBoard.scss';
import { ReportFilterTypes, TabId, TSimulationResultBoardProps } from '../../../SimulationModeling.types';
import { SimulationReport } from 'src/serverapi/api';
import { useIntl } from 'react-intl';

export const SimulationResultBoard = (props: TSimulationResultBoardProps) => {
    const intl = useIntl();
    const { activeSimulation } = props;

    const [tabId, setTabId] = useState(TabId.FUNCTIONS);
    const [reportFilterType, setReportFilterType] = useState(ReportFilterTypes.REPORTFULL);

    const handleSelect = (param: SelectInfo) => {
        if (param.key !== tabId) {
            setTabId(param.key as TabId);
        }
    };

    const handleSelectReportFilterTypes = (filterType) => {
        setReportFilterType(filterType);
    };

    useEffect(() => {
        if (activeSimulation?.success === false) {
            setTabId(TabId.REPORTS);
        }
        if (activeSimulation?.errorMessage) {
            console.error(activeSimulation?.errorMessage);
        }
    }, [activeSimulation]);

    const renderMenu = (): JSX.Element[] =>
        Object.keys(TabId).map((key) => (
            <Menu.Item key={key} data-test={`simulation_result_tab-${key}`}>
                {intl.formatMessage(messages[key])}
            </Menu.Item>
        ));

    const renderInnerTabs = () => {
        const result: SimulationReport | undefined = activeSimulation?.[reportFilterType];
        switch (tabId) {
            case TabId.FUNCTIONS: {
                return (
                    <Table
                        columns={[
                            'name',
                            'symbolName',
                            'count',
                            'sumWaitTime',
                            'maxWaitTime',
                            'avgWaitTime',
                            'avgProcessTime',
                            'sumProcessTime',
                        ].map((column) => ({
                            title: intl.formatMessage(messages[column]),
                            dataIndex: column,
                        }))}
                        dataSource={result?.functions}
                        className={theme.table}
                        bordered
                        pagination={false}
                    />
                );
            }
            case TabId.RESOURCES: {
                return (
                    <Table
                        columns={['name', 'symbolName', 'count'].map((column) => ({
                            title: intl.formatMessage(messages[column]),
                            dataIndex: column,
                        }))}
                        dataSource={result?.resources}
                        className={theme.table}
                        bordered
                        pagination={false}
                    />
                );
            }
            case TabId.EVENTS: {
                return (
                    <Table
                        columns={['name', 'symbolName', 'count'].map((column) => ({
                            title: intl.formatMessage(messages[column]),
                            dataIndex: column,
                        }))}
                        dataSource={result?.events}
                        className={theme.table}
                        bordered
                        pagination={false}
                    />
                );
            }
            case TabId.REPORTS: {
                if (activeSimulation?.success) {
                    return (
                        <Table
                            columns={['type', 'modelName', 'elementName', 'message'].map((column) => ({
                                title: intl.formatMessage(messages[column]),
                                dataIndex: column,
                            }))}
                            dataSource={result?.semanticErrors?.map((semanticError) => {
                                return {
                                    ...semanticError,
                                    type:
                                        semanticError.type &&
                                        intl.formatMessage(errorMessages[semanticError.type.toString()]),
                                };
                            })}
                            className={theme.table}
                            bordered
                            pagination={false}
                        />
                    );
                }

                return (
                    <div className={theme.errorText}>
                        <Typography.Title level={3}>
                            {activeSimulation?.criticalErrorType &&
                                intl.formatMessage(errorMessages[activeSimulation?.criticalErrorType.toString()])}
                        </Typography.Title>
                    </div>
                );
            }
            default: {
                return '';
            }
        }
    };

    return (
        <div className={theme.tabsContainer}>
            {activeSimulation && (
                <>
                    <div data-test="simulation_result-filter">
                        <Select
                            style={{ width: 350 }}
                            defaultValue={intl.formatMessage(messages[ReportFilterTypes.REPORTFULL])}
                            onChange={handleSelectReportFilterTypes}
                        >
                            {Object.keys(ReportFilterTypes).map((item) => (
                                <Select.Option key={item} value={ReportFilterTypes[item]}>
                                    {intl.formatMessage(messages[ReportFilterTypes[item]])}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className={theme.tabs}>
                        <Menu
                            defaultSelectedKeys={[TabId.FUNCTIONS]}
                            mode="horizontal"
                            selectedKeys={[tabId]}
                            onSelect={handleSelect}
                        >
                            {renderMenu()}
                        </Menu>
                        {renderInnerTabs()}
                    </div>
                </>
            )}
        </div>
    );
};

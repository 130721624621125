import { TPreset } from '../models/preset.types';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { NodeId, PresetDTO, PresetMetaData } from '../serverapi/api';
import { symbolsRequestSuccess } from '../actions/symbol.actions';
import {
    ALL_PRESETS_META_DATA_REQUEST,
    PRESET_META_DATA_REQUEST,
    PRESET_META_DATA_REQUEST_WITH_PRESET_ID,
} from '../actionsTypes/notation.actionTypes';
import {
    presetMetaDataRequest,
    presetMetaDataRequestFail,
    presetMetaDataRequestSuccess,
} from '../actions/notation.actions';
import {
    TPresetMetaDataRequestAction,
    TPresetMetaDataRequestWithPresetIdAction,
} from '../actions/notation.actions.types';
import { objectTypeRequestSuccess } from '../actions/objectType.actions';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { attributeTypesRequestSuccess } from '../actions/attributeType.actions';
import { ModelTypeSelectors } from '../selectors/modelType.selectors';
import { getPresetFromIndexedDb, setPresetToIndexedDb } from '../utils/indexedDb';
import { TCacheDb } from '../utils/indexedDb.types';
import { getEdgeTypes, getSymbols } from './mappers/notations';
import { currentUserProfileRequestSuccess } from '../actions/userProfile.actions';
import { successRequestFolderType } from '../actions/folderType.actions';
import { presetImageRequestSuccess } from '../actions/presetSettings/presetImage.actions';
import { modelTypeRequestSuccess } from '../actions/modelType.actions';
import { edgeTypeRequestSuccess } from '../actions/edgeType.actions';
import { kanbanModelTypeRequestSuccess } from '../actions/kanbanModelType.actions';
import { kanbanCardTypeRequestSuccess } from '../actions/kanbanCardType.actions';
import { TreeNode } from '../models/tree.types';
import { addModelTypeGroups, loadPresetsSuccess } from '../actions/methodologySetting.actions';
import { addObjectTypeGroups } from '../actions/objectTypeGroup.actions';
import { addEdgeTypeGroups } from '../actions/edgeTypeGroup.actions';
import { MethodologySettingDaoService } from '../../src/services/dao/MethodologySettingDaoService';
import { batchActions } from '../actions/rootReducer.actions';
import { PresetDAOService } from '../services/dao/PresetDaoService';
import { IProfile, TPresetVersions } from './types/notation.types';
import { ProfileDaoService } from '../services/dao/ProfileDaoService';
import { principalRequest } from '../actions/principal.actions';
import { setScriptContextLoadingStatus } from '../actions/scriptContext.actions';
import { TreeSelectors } from '../selectors/tree.selectors';
import { ObjectTypeSelectors } from '../selectors/objectType.selectors';
import { matrixModelTypeRequestSuccess } from '@/actions/matrixModelType.actions';
import { userProfileStateSelector } from '@/selectors/userProfile.selectors';
import { TUserProfileState } from '@/reducers/userProfile.reducer.types';
import { reportModelTypeRequestSuccess } from '@/actions/reportModelType.actions';
import { wikiModelTypeRequestSuccess } from '../actions/wikiModelType.actions';

function* presetMetaDataRequestHandler({ payload: { presetIds } }: TPresetMetaDataRequestAction) {
    const serverId = yield select(ServerSelectors.serverId);
    let currentUserProfiles: IProfile[] = [];
    let loadedMetaData: PresetMetaData[] = [];
    let notEmptyCurrentUserProfiles: IProfile[] = [];
    const cashedMetaData: PresetMetaData[] = [];
    let presetsVersion: TPresetVersions = {};

    const userProfilesState: TUserProfileState | undefined = yield select(userProfileStateSelector);
    const notLoadedPresetIds = presetIds.filter((presetId) => !userProfilesState?.get({ serverId, presetId }));

    if (!notLoadedPresetIds.length) {
        yield put(presetMetaDataRequestSuccess());
        return;
    }

    try {
        currentUserProfiles = yield all(
            presetIds.map((presetId) => call(() => ProfileDaoService.getCurrentUserProfileAcls(presetId))),
        );

        notEmptyCurrentUserProfiles = currentUserProfiles.filter(({ profile }) => profile);
        presetsVersion = yield PresetDAOService.getPresetVersions(presetIds);
        const cachedPresets: (TCacheDb | undefined)[] = yield all(
            presetIds.map((presetId) => getPresetFromIndexedDb(presetId)),
        );
        const uncashedPresetsIds: string[] = [];

        for (let i = 0; i < presetIds.length; i++) {
            const cachedPreset: TCacheDb | undefined = cachedPresets.find(
                (preset) => preset?.preset.presetId === presetIds[i],
            );
            if (cachedPreset && presetsVersion[presetIds[i]] === cachedPreset.version) {
                cashedMetaData.push(cachedPreset.preset);
            } else {
                uncashedPresetsIds.push(presetIds[i]);
            }
        }

        loadedMetaData = yield all(
            uncashedPresetsIds.map((presetId) => call(() => PresetDAOService.getPresetMetaData(presetId))),
        );
    } catch (e) {
        yield put(setScriptContextLoadingStatus(false));
        yield put(presetMetaDataRequestFail());
        throw e;
    }

    yield all(
        loadedMetaData.map((data) =>
            fork(setPresetToIndexedDb, data.presetId, data, presetsVersion[data.presetId] || -1),
        ),
    );

    const fullPresetsMetaData: PresetMetaData[] = [...cashedMetaData, ...loadedMetaData];
    const metaDataActions = fullPresetsMetaData
        .map(
            ({
                presetId,
                modelTypes,
                kanbanBoardTypes,
                kanbanCardTypes,
                objectTypes,
                attributeTypes,
                folderTypes,
                images,
                objectTypeGroups,
                edgeTypeGroups,
                modelTypeGroups,
                matrixTypes,
                reportTypes,
                wikiTypes,
            }) => [
                symbolsRequestSuccess(serverId, getSymbols(modelTypes), presetId),
                modelTypeRequestSuccess(serverId, modelTypes, presetId, modelTypeGroups || []),
                kanbanModelTypeRequestSuccess({ presetId, kanbanModelTypes: kanbanBoardTypes || [] }),
                matrixModelTypeRequestSuccess({ presetId, matrixModelTypes: matrixTypes || [] }),
                reportModelTypeRequestSuccess({ presetId, reportModelTypes: reportTypes || [] }),
                wikiModelTypeRequestSuccess({ presetId, wikiModelTypes: wikiTypes || [] }),
                kanbanCardTypeRequestSuccess({ presetId, kanbanCardTypes: kanbanCardTypes || [] }),
                objectTypeRequestSuccess({ serverId, presetId, objectTypes }),
                edgeTypeRequestSuccess(serverId, getEdgeTypes(modelTypes), presetId),
                attributeTypesRequestSuccess(serverId, presetId, attributeTypes),
                presetImageRequestSuccess({
                    presetImages: images,
                    serverId,
                    presetId,
                }),
                addModelTypeGroups({
                    modelTypeGroups: modelTypeGroups || [],
                    serverNode: { nodeId: { serverId } } as TreeNode,
                    preset: { id: presetId } as TPreset,
                }),
                addObjectTypeGroups({
                    objectTypeGroups: objectTypeGroups || [],
                    serverNode: { nodeId: { serverId } } as TreeNode,
                    preset: { id: presetId } as TPreset,
                }),
                addEdgeTypeGroups({
                    edgeTypeGroups: edgeTypeGroups || [],
                    serverNode: { nodeId: { serverId } } as TreeNode,
                    preset: { id: presetId } as TPreset,
                }),
                successRequestFolderType({ folderTypes: folderTypes || [], serverId, presetId }),
            ],
        )
        .flat();

    const profilesActions = notEmptyCurrentUserProfiles.map(({ presetId, profile }) =>
        currentUserProfileRequestSuccess(serverId, presetId, profile),
    );

    yield put(batchActions([...metaDataActions, ...profilesActions]));
    yield put(principalRequest(serverId));
    yield put(presetMetaDataRequestSuccess());
}

function* presetMetaDataRequestWithPresetIdHandler({ payload: { nodeId } }: TPresetMetaDataRequestWithPresetIdAction) {
    const presetId: string | undefined = yield select(TreeSelectors.presetById(nodeId));

    if (presetId) {
        yield presetMetaDataRequestHandler(presetMetaDataRequest([presetId]));
    }
}

export function* presetLoadModelTypes(serverId: string, presetId: string) {
    const isModelTypesLoaded: boolean = yield select(ModelTypeSelectors.isLoad(serverId, presetId));
    if (!isModelTypesLoaded) {
        yield presetMetaDataRequestHandler(presetMetaDataRequest([presetId]));
    }
}

export function* presetLoadObjectTypes(serverId: string, presetId: string) {
    const isObjectTypesLoaded: boolean = yield select(ObjectTypeSelectors.isLoaded(serverId, presetId));
    if (!isObjectTypesLoaded) {
        yield presetMetaDataRequestHandler(presetMetaDataRequest([presetId]));
    }
}

function* allPresetsMetaDataRequestHandler() {
    yield put(setScriptContextLoadingStatus(true));
    const serverId = yield select(ServerSelectors.serverId);
    const presetDTOs: Array<PresetDTO> = yield MethodologySettingDaoService.getPresetList(serverId);
    yield put(
        loadPresetsSuccess({
            serverNode: {
                nodeId: { serverId } as NodeId,
            } as TreeNode,
            presets: presetDTOs as TPreset[],
        }),
    );
    const presetIds = presetDTOs.map((preset) => preset.id);
    yield presetMetaDataRequestHandler(presetMetaDataRequest(presetIds));
    yield put(setScriptContextLoadingStatus(false));
}

export function* notationSaga() {
    yield takeEvery(PRESET_META_DATA_REQUEST, presetMetaDataRequestHandler);
    yield takeEvery(ALL_PRESETS_META_DATA_REQUEST, allPresetsMetaDataRequestHandler);
    yield takeEvery(PRESET_META_DATA_REQUEST_WITH_PRESET_ID, presetMetaDataRequestWithPresetIdHandler);
}

import React, { FC } from 'react';
import { Symbol } from '../../../../serverapi/api';
import { Table } from 'antd';
import SymbolItem from './SymbolItem.component';
import { TExpandableSymbol } from './NavigatorSymbols.types';
import theme from './NavigatorSymbols.scss';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { RenderExpandIconProps } from 'rc-table/lib/interface';

type TSymbolsList = {
    symbols: Symbol[] | TExpandableSymbol[];
    onSymbolDrag: (draggedSymbol: Symbol) => void;
    expandMode: boolean;
    searchString: string;
};

const SymbolsList: FC<TSymbolsList> = (props) => {
    const { symbols, onSymbolDrag, expandMode, searchString } = props;
    if (!symbols) {
        return null;
    }

    const expandConfig = {
        expandedRowRender: (symbol: TExpandableSymbol) =>
            symbol.nestedSymbols.map((nSymbol) => (
                <SymbolItem key={nSymbol.id} symbol={nSymbol} onSymbolDrag={onSymbolDrag} searchString={searchString} />
            )),
        rowExpandable: (symbol: TExpandableSymbol) => symbol.nestedSymbols.length > 0,
        expandIconColumnIndex: 2,
        expandIcon: ({ expanded, onExpand, record }: RenderExpandIconProps<TExpandableSymbol>) => {
            if (!record.nestedSymbols.length) {
                return null;
            }

            return expanded ? (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
            );
        },
    };

    return (
        <Table
            showHeader={false}
            pagination={false}
            rowKey={(symbol: Symbol) => symbol.id}
            dataSource={symbols}
            columns={[
                {
                    dataIndex: 1,
                    render: (t, symbol: Symbol) => (
                        <SymbolItem symbol={symbol} onSymbolDrag={onSymbolDrag} searchString={searchString} />
                    ),
                },
            ]}
            className={theme.symbolTable}
            expandable={expandMode ? expandConfig : undefined}
        />
    );
};

export default SymbolsList;

import {NodeId} from '../serverapi/api';
import {FILE_DOWNLOAD, FILE_OPEN} from '../actionsTypes/uploader.actionTypes';
import { TFileDownloadAction, TFileOpenAction } from './file.actions.types';

export const fileDownload = (nodeId: NodeId): TFileDownloadAction => ({
    type: FILE_DOWNLOAD,
    payload: {
        nodeId
    }
});

export const fileOpen = (nodeId: NodeId): TFileOpenAction => ({
    type: FILE_OPEN,
    payload: {
        nodeId
    }
});


import { NodeId, Symbol } from '../../../../serverapi/api';
import { MxConstants, MxGraph, MxStencil, MxStencilRegistry, MxUtils } from '../../../../mxgraph/mxgraph';
import { DEFAULT_SYMBOL_STYLE } from '../../../../services/SymbolsService';
import { TExpandableSymbol } from './NavigatorSymbols.types';
import { TreeNode } from '../../../../models/tree.types';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { isNil, max, min } from 'lodash-es';
import { LocalesService } from '@/services/LocalesService';

export function createMxGraphIcon(divGraph: HTMLDivElement, symbol: Symbol) {
    const div: HTMLDivElement = divGraph;

    if (!div) {
        return;
    }

    div.innerHTML = '';
    const graph = new MxGraph(div);

    div.style.display = 'block';
    div.style.width = '39px';
    div.style.height = '39px';
    div.style.overflow = 'hidden';
    div.style.border = '0px';

    graph.setCellsSelectable(false);
    graph.setCellsLocked(true);

    // // tslint:disable-next-line
    const style = { ...DEFAULT_SYMBOL_STYLE };
    style[MxConstants.STYLE_SHAPE] = symbol.id;
    graph.getStylesheet().putCellStyle(symbol.id, style);

    const xml = MxUtils.parseXml(symbol.graphical);
    const mxStencil = new MxStencil(xml.firstChild);
    MxStencilRegistry.addStencil(symbol.id, mxStencil);
    graph.insertVertex(
        graph.getDefaultParent(),
        symbol.id,
        '',
        0,
        0,
        36,
        36,
        `${symbol.id};fillColor=none;strokeColor=black;${symbol.style}`,
    );
    graph.enabled = false;
}

export const transformToExpandable = (symbols: Symbol[]) => {
    const expandableSymbols = symbols.reduce((result: TExpandableSymbol[], symbol: Symbol) => {
        const rootSymbol = result.find((eSymbol) => eSymbol.objectType === symbol.objectType);
        if (rootSymbol) {
            rootSymbol.nestedSymbols.push(symbol);
        } else {
            result.push({ ...symbol, nestedSymbols: [] });
        }

        return result;
    }, []);

    return expandableSymbols;
};

export const getSelectedSlice = (
    nodes: TreeNode[],
    selectedNode1?: { nodeId: NodeId },
    selectedNode2?: { nodeId: NodeId },
) => {
    if (!selectedNode1 || !selectedNode2 || !nodes || !nodes.length) {
        return [];
    }

    const getNodeIndex = (n) => {
        return nodes.findIndex((node) => compareNodeIds(n.nodeId, node.nodeId));
    };

    const rangeIndexes = [getNodeIndex(selectedNode1), getNodeIndex(selectedNode2)];
    if (rangeIndexes?.some(isNil)) {
        return [];
    }

    return nodes.slice(min(rangeIndexes), max(rangeIndexes)! + 1);
};

export const getFoundSymbols = (
    searchString: string,
    symbols: Symbol[] | TExpandableSymbol[],
): Symbol[] | TExpandableSymbol[] => {
    if (!searchString) return symbols;

    return symbols.filter((symbol) => {
        const symbolName = LocalesService.internationalStringToString(symbol.multilingualName) || symbol.name || '';
        return symbolName.toLowerCase().includes(searchString.toLowerCase());
    });
};

export const getFoundExpandableSymbols = (searchString: string, symbols: TExpandableSymbol[]): TExpandableSymbol[] => {
    const result: TExpandableSymbol[] = [];

    symbols.forEach((symbol) => {
        const isSymbolFound: boolean = !!getFoundSymbols(searchString, [symbol]).length;

        if (symbol.nestedSymbols.length) {
            const nestedSymbolsFiltered: TExpandableSymbol[] | Symbol[] = getFoundSymbols(
                searchString,
                symbol.nestedSymbols,
            );
            if (isSymbolFound || nestedSymbolsFiltered.length) {
                result.push({ ...symbol, nestedSymbols: nestedSymbolsFiltered });
            }
        } else if (isSymbolFound) {
            result.push(symbol);
        }
    });

    return result;
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'DashboardSelectTableDialog.cancel',
        defaultMessage: 'Отмена',
    },
    apply: {
        id: 'DashboardSelectTableDialog.apply',
        defaultMessage: 'Применить',
    },
    title: {
        id: 'DashboardSelectTableDialog.Title',
        defaultMessage: 'Выбор таблицы данных',
    },
});

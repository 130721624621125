import { CONTEXT_MENU_ACTION, CONTEXT_MENU_DIVIDER } from '../../../actionsTypes/tree.actionTypes';
import { TSpriteSymbol } from '../../../models/spriteSymbol.types';

export enum FileFolderType {
    NONE = 'NONE',
    TEMP = 'TEMP',
    COMMON = 'COMMON',
    REPOSITORY = 'REPOSITORY',
    USER_PRIVATE = 'USER_PRIVATE',
}

export enum TreeItemType {
    Server = 'SERVER',
    Repository = 'DB',
    Model = 'MODEL',
    ModelTemplate = 'MODEL_TEMPLATE',
    ObjectDefinition = 'OBJECT',
    Folder = 'FOLDER',
    Script = 'SCRIPT',
    ScriptFolder = 'SCRIPT_FOLDER',
    Wiki = 'WIKI',
    Matrix = 'MATRIX',
    Report = 'REPORT',
    AdminTool = 'ADMIN_TOOL',
    SimulationModeling = 'SIMULATION',
    File = 'FILE',
    FileFolder = 'FILE_FOLDER',
    Default = 'DEFAULT',
    Spreadsheet = 'SPREADSHEET',
    Kanban = 'KANBAN',
    EdgeDefinition = 'EDGE',
    Dashboard = 'DASHBOARD',
    UserAccount = 'USER_ACCOUNT',
}

export enum TreeItemContext {
    Default = 'DEFAULT',
    CreateDecompositionDialog = 'CREATE_DECOMPOSITION_DIALOG',
    CreateModelDialog = 'CREATE_MODEL_DIALOG',
    CreateScriptDialog = 'CREATE_SCRIPT_DIALOG',
    TwoModelsSelected = 'TWO_MODELS_SELECTED',
    MoreThanOneElementSelected = 'MORE_THAN_ONE_ELEMENT_SELECTED',
}

export function parseTreeItemType(str: string): TreeItemType {
    const nameKey = Object.keys(TreeItemType).find((key) => TreeItemType[key] === str);
    if (!nameKey) {
        throw new Error(`Cannot find Tree type ${str}`);
    }

    return TreeItemType[nameKey];
}

export enum TreeItemContextMenuAction {
    OPEN = 'OPEN',
    EDIT = 'EDIT',
    RENAME = 'RENAME',
    EDIT_ATTRIBUTES = 'EDIT_ATTRIBUTES',
    DELETE = 'DELETE',
    ERASE = 'ERASE',
    RESTORE = 'RESTORE',
    ADD_TO_FAVORITES = 'ADD_TO_FAVORITES',
    REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES',
    CREATE = 'CREATE',
    ADD_OBJECT = 'ADD_OBJECT',
    ADD_FOLDER = 'ADD_FOLDER',
    ADD_MODEL = 'ADD_MODEL',
    CLONE_MODEL = 'CLONE_MODEL',
    ADD_SCRIPT = 'ADD_SCRIPT',
    ADD_FILE = 'ADD_FILE',
    ADD_DASHBOARD = 'ADD_DASHBOARD',
    REPLACE = 'REPLACE',
    ADD_SCRIPT_FOLDER = 'ADD_SCRIPT_FOLDER',
    ADD_FILE_FOLDER = 'ADD_FILE_FOLDER',
    ADD_WIKI = 'ADD_WIKI',
    PROPERTIES = 'PROPERTIES',
    REFRESH = 'REFRESH',
    COLLAPSE = 'COLLAPSE',
    ADD_DB = 'ADD_DB',
    SERVER_DELETE = 'SERVER_DELETE',
    SWITCH_ACTIVE_SERVER_PROFILE = 'SWITCH_ACTIVE_SERVER_PROFILE',
    CONNECT = 'CONNECT',
    EXPORT = 'EXPORT',
    EXPORT_DOCX = 'EXPORT_DOCX',
    EXPORT_BPMN = 'EXPORT_BPMN',
    IMPORT = 'IMPORT',
    IMPORT_VISIO = 'IMPORT_VISIO',
    IMPORT_ARIS = 'IMPORT_ARIS',
    EXECUTE_SCRIPT = 'EXECUTE_SCRIPT',
    SCHEDULE_SCRIPT = 'SCHEDULE_SCRIPT',
    SUBSCRIBE = 'SUBSCRIBE',
    UNSUBSCRIBE = 'UNSUBSCRIBE',

    SCRIPT_DOCUMENTATION = 'SCRIPT_DOCUMENTATION',
    SCRIPT_DOCUMENTATION_SCRIPTS = 'SCRIPT_DOCUMENTATION_SCRIPTS',
    SCRIPT_DOCUMENTATION_API_DOCX = 'SCRIPT_DOCUMENTATION_API_DOCX',
    SCRIPT_DOCUMENTATION_API_HTML = 'SCRIPT_DOCUMENTATION_API_HTML',

    ADD_SIMULATION_MODELING = 'ADD_SIMULATION_MODELING',
    MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
    OPEN_SCRIPT_RESULT = 'OPEN_SCRIPT_RESULT',
    OPEN_SCRIPT_SCHEDULER = 'OPEN_SCRIPT_SCHEDULER',
    DB_SEARCH = 'DB_SEARCH',
    DB_SEARCH_DELETED_ELEMENTS = 'DB_SEARCH_DELETED_ELEMENTS',
    COPY_ITEM_LINK = 'COPY_ITEM_LINK',
    DOWNLOAD_FILE = 'DOWNLOAD_FILE',

    ADD_SPREADSHEET = 'ADD_SPREADSHEET',
    ADDITIONAL_TOOLS = 'ADDITIONAL_TOOLS',
    CHANGE_ENTITY_TYPE = 'CHANGE_ENTITY_TYPE',
    FIND_DUPLICATES = 'FIND_DUPLICATES',
    HISTORY_LOG = 'HISTORY_LOG',
    COMPARE_MODELS = 'COMPARE_MODELS',
    COPY_DATABASE = 'COPY_DATABASE',
    CREATE_MODEL_TEMPLATE = 'CREATE_MODEL_TEMPLATE',
    COMPARE = 'COMPARE',
}

export const LICENSE_FORBIDDEN_ACTIONS = [
    TreeItemContextMenuAction.CREATE,
    TreeItemContextMenuAction.RENAME,
    TreeItemContextMenuAction.DELETE,
    TreeItemContextMenuAction.ERASE,
    TreeItemContextMenuAction.REPLACE,
    TreeItemContextMenuAction.CLONE_MODEL,
    TreeItemContextMenuAction.REPLACE,
    TreeItemContextMenuAction.ADD_DB,
    TreeItemContextMenuAction.ADD_FOLDER,
    TreeItemContextMenuAction.ADD_SCRIPT_FOLDER,
    TreeItemContextMenuAction.ADD_MODEL,
    TreeItemContextMenuAction.ADD_OBJECT,
    TreeItemContextMenuAction.ADD_WIKI,
    TreeItemContextMenuAction.ADD_FILE,
    TreeItemContextMenuAction.ADD_FILE_FOLDER,
    TreeItemContextMenuAction.ADD_SPREADSHEET,
    TreeItemContextMenuAction.ADD_SIMULATION_MODELING,
    TreeItemContextMenuAction.IMPORT,
    TreeItemContextMenuAction.IMPORT_ARIS,
    TreeItemContextMenuAction.IMPORT_VISIO,
    TreeItemContextMenuAction.COPY_DATABASE,
    TreeItemContextMenuAction.CLONE_MODEL,
];

export const DELETED_NODE_FORBIDDEN_ACTIONS = [
    TreeItemContextMenuAction.EDIT,
    TreeItemContextMenuAction.EDIT_ATTRIBUTES,
    TreeItemContextMenuAction.CREATE,
    // ???
    TreeItemContextMenuAction.ADD_TO_FAVORITES,
    TreeItemContextMenuAction.REMOVE_FROM_FAVORITES,
    TreeItemContextMenuAction.ADD_DASHBOARD,
    TreeItemContextMenuAction.ADD_DB,
    TreeItemContextMenuAction.ADD_FOLDER,
    TreeItemContextMenuAction.ADD_FILE,
    TreeItemContextMenuAction.ADD_FILE_FOLDER,
    TreeItemContextMenuAction.ADD_MODEL,
    TreeItemContextMenuAction.ADD_OBJECT,
    TreeItemContextMenuAction.ADD_SCRIPT,
    TreeItemContextMenuAction.ADD_SIMULATION_MODELING,
    TreeItemContextMenuAction.ADD_SPREADSHEET,
    TreeItemContextMenuAction.ADD_SCRIPT_FOLDER,
    TreeItemContextMenuAction.ADD_WIKI,
    TreeItemContextMenuAction.CLONE_MODEL,
    TreeItemContextMenuAction.REPLACE,
    TreeItemContextMenuAction.SERVER_DELETE,
    TreeItemContextMenuAction.SWITCH_ACTIVE_SERVER_PROFILE,
    TreeItemContextMenuAction.CONNECT,
    TreeItemContextMenuAction.EXPORT,
    TreeItemContextMenuAction.EXPORT_BPMN,
    TreeItemContextMenuAction.IMPORT,
    TreeItemContextMenuAction.IMPORT_VISIO,
    TreeItemContextMenuAction.IMPORT_ARIS,
    TreeItemContextMenuAction.SUBSCRIBE,
    TreeItemContextMenuAction.UNSUBSCRIBE,
    TreeItemContextMenuAction.MANAGE_PERMISSIONS,
    TreeItemContextMenuAction.ADDITIONAL_TOOLS,
    TreeItemContextMenuAction.CHANGE_ENTITY_TYPE,
    TreeItemContextMenuAction.HISTORY_LOG,
    TreeItemContextMenuAction.RENAME,
    TreeItemContextMenuAction.CLONE_MODEL,
    TreeItemContextMenuAction.CREATE_MODEL_TEMPLATE,
];

interface IContextMenuItem {
    type: string;
    titleAction?: TreeItemContextMenuAction;
    icon?: TSpriteSymbol;
    formattedMessageId?: string;
    subMenu?: TreeContextMenuActionItem[] | [];
}

export class TreeContextMenuActionItem implements IContextMenuItem {
    readonly type: typeof CONTEXT_MENU_ACTION = CONTEXT_MENU_ACTION;

    constructor(
        readonly titleAction: TreeItemContextMenuAction,
        readonly icon: TSpriteSymbol,
        readonly formattedMessageId: string,
        readonly subMenu: TreeContextMenuActionItem[] = [],
    ) {
        this.titleAction = titleAction;
        this.icon = icon;
        this.formattedMessageId = formattedMessageId;
        this.subMenu = subMenu;
    }
}

export class TreeItemContextMenuDivider implements IContextMenuItem {
    readonly type: typeof CONTEXT_MENU_DIVIDER = CONTEXT_MENU_DIVIDER;
}

import { useIntl } from 'react-intl';
import React from 'react';
import messages from '../../FormatPanel.messages';
import theme from './FontBlock.scss';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { AlignmentLeft } from '../AlignmentBtns/AlignmentLeft.component';
import { AlignmentCenter } from '../AlignmentBtns/AlignmentCenter.component';
import { AlignmentRight } from '../AlignmentBtns/AlignmentRight.component';
import { FontFamily } from '../FontFamily/FontFamily.component';
import { FontSize } from '../FontSize/FontSize.component';

export const FontBlock = () => {
    const intl = useIntl();

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.font)}</div>
            <div className={theme.fontContainer}>
                <div className={theme.fontFamilyContainer}>
                    <FontFamily />
                </div>
                <div className={theme.fontSizeContainer}>
                    <FontSize />
                </div>
            </div>
            <div className={theme.alignContainer}>
                <ButtonGroup showBorder withoutDividerSpace>
                    <AlignmentLeft />
                    <AlignmentCenter />
                    <AlignmentRight />
                </ButtonGroup>
            </div>
        </div>
    );
};

import { TextAlign, textAlignValues } from '@/utils/configuration';
import TextAlignExtension from '@tiptap/extension-text-align';

const ExtendedTextAlign = TextAlignExtension.extend({
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    textAlign: {
                        default: this.options.defaultAlignment,
                        parseHTML: (element) => {
                            if (textAlignValues.includes(element.style.textAlign as TextAlign)) {
                                return element.style.textAlign;
                            }

                            return this.options.defaultAlignment;
                        },
                        renderHTML: (attributes) => {
                            if (attributes.textAlign === this.options.defaultAlignment) {
                                return {};
                            }

                            return { style: `text-align: ${attributes.textAlign}` };
                        },
                    },
                },
            },
        ];
    },
});

export { ExtendedTextAlign as TextAlign };


import iconSettings from '../../../resources/icons/ic-list-settings.svg';
import iMethodology from '../../../resources/icons/methodology.svg';
import iMonitoring from '../../../resources/icons/monitor.svg';
import iLicense from '../../../resources/icons/key_2.svg';
import iServerSettings from '../../../resources/icons/setting_server.svg';
import iUsersManagment from '../../../resources/icons/ic-group.svg';
import iApprovals from '../../../resources/icons/appr.svg';
import iSafety from '../../../resources/icons/shield.svg';
import iEventAccess from '../../../resources/icons/lightning.svg';
import iNotifications from '../../../resources/icons/ic-subscribe.svg';
import iPublishedModels from '../../../resources/icons/ic-globe-user.svg';
import { TreeNode } from '../../../models/tree.types';
import { TreeItemType } from '../../Tree/models/tree';
import { LocalesService } from '../../../services/LocalesService';
import messages from '../messages/admintool.messages';
import { UserDTO, UserDTOTransitiveAccessesEnum } from '../../../serverapi/api';
import { Locale } from '../../Header/components/Header/header.types';
import { DEFAULT_LOCALE } from '../../../config/config';
import { AdminToolTreeType } from './admintool.types';
import { ExperimentalFeatures } from '@/models/ExperimentalFeatures';

export const ALLUSERS_GROUPID = 5001;

export const SUPERADMIN_LOGIN = 'superadmin';

const addAdminToolTreeChildren = (childrenType: AdminToolTreeType, serverId: string, rootNode, lang: Locale): void => {
    const intl = LocalesService.useIntl(lang);
    rootNode.children.push({
        nodeId: {
            id: childrenType,
            serverId,
            repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
        },
        parentNodeId: {
            id: AdminToolTreeType.ADMIN_TOOL_ROOT,
            repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
            serverId,
        },
        name: intl.formatMessage(messages[childrenType]),
        type: TreeItemType.AdminTool,
        hasChildren: false,
    });
};

export const getAdminToolTreeItems = (
    serverId: string,
    locale: Locale = DEFAULT_LOCALE,
    user?: UserDTO,
): TreeNode[] => {
    const intl = LocalesService.useIntl(locale);

    const access: UserDTOTransitiveAccessesEnum[] = user && user.transitiveAccesses ? user.transitiveAccesses : [];

    const isSuperadmin = user && user.login === SUPERADMIN_LOGIN;
    const check = (need: UserDTOTransitiveAccessesEnum[]) =>
        isSuperadmin || need.filter((needItem) => access.includes(needItem)).length;

    const rootNode = {
        nodeId: {
            id: AdminToolTreeType.ADMIN_TOOL_ROOT,
            serverId,
            repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
        },
        parentNodeId: {
            serverId,
            id: serverId,
            repositoryId: serverId,
        },
        name: intl.formatMessage(messages[AdminToolTreeType.ADMIN_TOOL_ROOT]),
        type: TreeItemType.AdminTool,
        hasChildren: true,
        countChildren: 1,
        children: [] as TreeNode[],
    };

    /**
     * Типы прав пользователя
     * "USERS_EDITOR" | "DB_EDITOR" | "DB_ADMIN" | "SCRIPT_EDITOR" | "SCRIPT_RUNNER" | "META_EDITOR" |
     *  "LICENSE_EDITOR" | "SECURITY_ADMIN" | "LOG_READER" | "SERVER_ADMIN" | 'APPROVAL_ADMIN' | 'EVENT_ACCESS';
     *  */
    if (check(['USERS_EDITOR', 'SERVER_AUDIT'])) {
        addAdminToolTreeChildren(AdminToolTreeType.USER_MANAGMENT, serverId, rootNode, locale);
    }

    if (check(['LICENSE_EDITOR', 'SERVER_AUDIT'])) {
        addAdminToolTreeChildren(AdminToolTreeType.LICENSES, serverId, rootNode, locale);
    }

    if (check(['META_EDITOR'])) {
        addAdminToolTreeChildren(AdminToolTreeType.METHODOLOGY_SETTINGS, serverId, rootNode, locale);
    }

    if (check(['SERVER_ADMIN', 'SERVER_AUDIT'])) {
        addAdminToolTreeChildren(AdminToolTreeType.SERVER_SETTINGS, serverId, rootNode, locale);
        addAdminToolTreeChildren(AdminToolTreeType.MONITORING_TAB, serverId, rootNode, locale);
        if (ExperimentalFeatures.isEmailNotificationsEnabled()) {
            addAdminToolTreeChildren(AdminToolTreeType.NOTIFICATIONS, serverId, rootNode, locale);
        }
    }

    if (check(['SECURITY_ADMIN', 'LOG_READER', 'ACCESS_KEY_ADMIN', 'SERVER_AUDIT'])) {
        addAdminToolTreeChildren(AdminToolTreeType.SAFETY, serverId, rootNode, locale);
    }

    if (check(['EVENT_ACCESS'])) {
        addAdminToolTreeChildren(AdminToolTreeType.EVENT_ACCESS, serverId, rootNode, locale);
    }

    if (check(['APPROVAL_ADMIN', 'SERVER_AUDIT'])) {
        addAdminToolTreeChildren(AdminToolTreeType.APPROVALS_TAB, serverId, rootNode, locale);
    }

    if (check(['SERVER_ADMIN', 'SERVER_AUDIT'])) {
        addAdminToolTreeChildren(AdminToolTreeType.PUBLISHED_MODELS, serverId, rootNode, locale);
    }

    if (check(['META_EDITOR']) && ExperimentalFeatures.isUITemplatesEnabled()) {
        addAdminToolTreeChildren(AdminToolTreeType.UI_TEMPLATES, serverId, rootNode, locale);
    }

    return rootNode.children.length ? [rootNode] : [];
};

export const adminToolsIcons = {
    [AdminToolTreeType.ADMIN_TOOL_ROOT]: iconSettings,
    [AdminToolTreeType.SERVER_SETTINGS]: iServerSettings,
    [AdminToolTreeType.SAFETY]: iSafety,
    [AdminToolTreeType.LICENSES]: iLicense,
    [AdminToolTreeType.USER_MANAGMENT]: iUsersManagment,
    [AdminToolTreeType.METHODOLOGY_SETTINGS]: iMethodology,
    [AdminToolTreeType.UI_TEMPLATES]: iMethodology,
    [AdminToolTreeType.USERDATA_EDITING_TAB]: iUsersManagment,
    [AdminToolTreeType.PRINCIPAL_SERVER_PROFILE_OWNERS_EDITING_TAB]: iUsersManagment,
    [AdminToolTreeType.GROUPDATA_EDITING_TAB]: iUsersManagment,
    [AdminToolTreeType.LICENSEOWNERS_EDITING_TAB]: iLicense,
    [AdminToolTreeType.ACCESS_PERMISSION_OWNERS_EDITING_TAB]: iUsersManagment,
    [AdminToolTreeType.DBACCESS_OWNERS_EDITING_TAB]: iUsersManagment,
    [AdminToolTreeType.MONITORING_TAB]: iMonitoring,
    [AdminToolTreeType.NOTIFICATIONS]: iNotifications,
    [AdminToolTreeType.EVENT_ACCESS]: iEventAccess,
    [AdminToolTreeType.APPROVALS_TAB]: iApprovals,
    [AdminToolTreeType.PUBLISHED_MODELS]: iPublishedModels,
};

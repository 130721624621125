import { defineMessages } from 'react-intl';

export default defineMessages({
    tabTitle: {
        id: 'ModelHistory.tabTitle',
        defaultMessage: 'История изменений',
    },
    restore: {
        id: 'ModelHistory.restore',
        defaultMessage: 'Восстановить',
    },
    compare: {
        id: 'ModelHistory.compare',
        defaultMessage: 'Сравнить',
    },
    todayTimePrefix: {
        id: 'ModelHistory.todayTimePrefix',
        defaultMessage: 'Сегодня',
    },
    yesterdayTimePrefix: {
        id: 'ModelHistory.yesterdayTimePrefix',
        defaultMessage: 'Вчера',
    },
});

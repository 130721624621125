import {
    FETCH_MODEL_FORMAT_PARAM_VALUE,
    FETCH_DELETE_ALL_MODEL_FORMAT_PARAM_VALUES,
} from '../actionsTypes/addedModelFormatParams.actionTypes';
import { CustomMap } from '../utils/map';
import { TReducer } from '../utils/types';
import { TAddedFormatParamsReducerState, TFormatParams } from './addedModelFormatParams.reducer.types';

const initial: TAddedFormatParamsReducerState = new CustomMap();

export const addedModelFormatParamsReducer: TReducer<TAddedFormatParamsReducerState> = (state = initial, action) => {
    switch (action.type) {
        case FETCH_MODEL_FORMAT_PARAM_VALUE: {
            const { value, modelId, paramType } = action.payload;

            const cloneState: TAddedFormatParamsReducerState = state.clone();
            const paramValues: TFormatParams | undefined = cloneState.get(modelId);

            if (!paramValues) {
                cloneState.set(modelId, { [paramType]: [value] });
                return cloneState;
            }

            const oldValues: string[] = paramValues?.[paramType] || [];
            const newValues: string[] = [...oldValues, value];

            cloneState.set(modelId, { ...paramValues, [paramType]: newValues });

            return cloneState;
        }

        case FETCH_DELETE_ALL_MODEL_FORMAT_PARAM_VALUES: {
            const { modelId, paramType } = action.payload;

            const cloneState: TAddedFormatParamsReducerState = state.clone();
            const paramValues: TFormatParams | undefined = cloneState.get(modelId);

            if (!paramValues) {
                cloneState.set(modelId, { [paramType]: [] });
                return cloneState;
            }
            cloneState.set(modelId, { ...paramValues, [paramType]: [] });

            return cloneState;
        }

        default:
            return state;
    }
};

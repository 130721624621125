import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../FormatPanel.messages';
import edgeNames from '@/modules/AdminTools/Methodology/components/Presets/EdgeType/EdgeType.messages';
import theme from './edgeStyles.scss';
import React, { useMemo } from 'react';
import { Select } from '../../../UIKit/components/Select/Select.component';
import { FormatPanelButtonsState } from '../../../../selectors/generalMenu.selectors';
import icStartArrow from '@/resources/icons/toolbar/ic-start-line.svg';
import icEndArrow from '@/resources/icons/toolbar/ic-end-line.svg';
import {
    ICompactableSelectItemValue,
    TCompactableSelectItemType,
} from '../../../MainMenu/components/CompactableSelect/CompactableSelect.types';
import { changeEdgesStyle } from '../../../../actions/editor.actions';
import { Icon } from '../../../UIKit';
import {
    PROP_KEY_EDGE_END_ARROW,
    PROP_KEY_EDGE_START_ARROW,
} from '../../../../models/properties/accessible-properties';
import { EdgeArrow, edgeArrowTypeNames } from '../../../../models/edge-arrow';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { FormatPanelSelectors } from '../../../../selectors/formatPanel.selectors';
import { TSelectedCellElements } from '../../../../services/bll/ToolbarButtonsBllService.types';
import { EditorToolbarSelectors } from '../../../../selectors/editorToolbar.selectors';

type TEdgeStartArrowBlock = {
    arrow: 'startArrow' | 'endArrow';
};

export const EdgeArrowBlock = (props: TEdgeStartArrowBlock) => {
    const { arrow } = props;
    const isStartArrow: boolean = arrow === PROP_KEY_EDGE_START_ARROW;
    const intl = useIntl();
    const locale = useSelector(getCurrentLocale);
    const dispatch = useDispatch();
    const tooltip: string = useSelector(FormatPanelSelectors.getEdgeArrowTooltip(isStartArrow));
    const disabled: boolean = useSelector(FormatPanelSelectors.getEdgeArrowDisabled);
    const { selectedEdges }: TSelectedCellElements = useSelector(EditorToolbarSelectors.getSelectedCellsInActiveGraph);

    const items = useMemo(
        () =>
            edgeArrowTypeNames(locale, intl).map(
                (item) =>
                    ({
                        type: TCompactableSelectItemType.Value,
                        value: item.id,
                        label: item.value[locale],
                        disabled: false,
                    } as ICompactableSelectItemValue<string>),
            ),
        [locale],
    );

    const edgesArrow: EdgeArrow | undefined = useSelector(
        FormatPanelButtonsState[`get${arrow.charAt(0).toUpperCase() + arrow.slice(1)}`],
    );

    const edgeArrowValue: JSX.Element = (
        <div className={theme.valueContainer}>
            <Icon spriteSymbol={isStartArrow ? icStartArrow : icEndArrow} />
            <span className={theme.typeContainer}>
                {edgesArrow !== undefined ? intl.formatMessage(edgeNames[EdgeArrow[edgesArrow]]) : ''}
            </span>
        </div>
    );
    const handleChangeEdgeStyle = (styleKey: string) => (styleValue: string) => {
        const cellIds: string[] = selectedEdges.map((edge) => edge.id);
        dispatch(changeEdgesStyle([styleKey], [styleValue], cellIds));
    };

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>
                {isStartArrow ? intl.formatMessage(messages.startArrow) : intl.formatMessage(messages.endArrow)}
            </div>
            <Select
                onChange={handleChangeEdgeStyle(isStartArrow ? PROP_KEY_EDGE_START_ARROW : PROP_KEY_EDGE_END_ARROW)}
                value={edgeArrowValue}
                disabled={disabled}
                placeholder=""
                originalTheme
                data-test={isStartArrow ? 'format-toolbar_edge-start-arrow' : 'format-toolbar_edge-end-arrow'}
                tooltip={tooltip}
            >
                {items.map((item) => {
                    return <Select.Option value={item.value} label={item.label} />;
                })}
            </Select>
        </div>
    );
};

import React, { ChangeEvent, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { FormInstance } from 'antd';
import { ModelTypeGroup, InternationalString, WikiType } from '../../../../../../../serverapi/api';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { InputId } from '../../../../../../InputId/InputId.component';
import theme from './WikiModelTypeGeneralTab.scss';
import messages from '../WikiModelType.messages';
import { Select } from '../../../../../../UIKit/components/Select/Select.component';
import { Checkbox } from '../../../../../../UIKit/components/Checkbox/Checkbox.component';
import {
    changeWikiModelTypeAllowApprovals,
    changeWikiModelTypeDescription,
    changeWikiModelTypeEnableModel,
    changeWikiModelTypeId,
    changeWikiModelTypeName,
} from '../../../../../../../actions/workspaceTab/editWikiModelTypeWorkspaceTab.actions';

type TWikiModelTypeGeneralTabProps = {
    modelType?: WikiType;
    modelTypeGroups: ModelTypeGroup[];
    createMode: boolean;
    generalForm: FormInstance<any> | undefined;
    onChangeModelTypeGroup: (group: ModelTypeGroup) => void;
};

const WikiModelTypeGeneralTab: FC<TWikiModelTypeGeneralTabProps> = (props) => {
    const dispatch = useDispatch();
    const { createMode, modelType, modelTypeGroups, generalForm } = props;
    const presetId: string = modelType?.presetId || '';
    const modelTypeId: string = modelType?.id || '';
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);

    const currentModelTypeGroup: ModelTypeGroup | undefined = modelTypeGroups.find(
        (group) => group.id === modelType?.groupId,
    );

    const onChangeModelTypeGroup = (groupId: string) => {
        props.onChangeModelTypeGroup(modelTypeGroups.find((group) => group.id === groupId)!);
    };

    const onChangeModelTypeName = useCallback((multilingualName: InternationalString) => {
        dispatch(changeWikiModelTypeName({ multilingualName, presetId, modelTypeId }));
    }, []);

    const onChangeModelTypeId = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeWikiModelTypeId({ presetId, modelTypeId, id: event.target.value.trim() }));
    }, []);

    const onChangeModelTypeDescription = useCallback((multilingualDescription: InternationalString) => {
        dispatch(changeWikiModelTypeDescription({ presetId, modelTypeId, multilingualDescription }));
    }, []);

    const onChangeModelTypeEnableModel = useCallback((enableModel: boolean) => {
        dispatch(changeWikiModelTypeEnableModel({ presetId, modelTypeId, enableModel }));
    }, []);

    const onChangeModelTypeAllowApprovals = useCallback((allowApprovals: boolean) => {
        dispatch(changeWikiModelTypeAllowApprovals({ presetId, modelTypeId, allowApprovals }));
    }, []);

    return (
        <div data-test="model-type-general-tab" className={theme.form}>
            <MultiLangInputDialog
                data-test="model-type-name-input"
                placeholder={intl.formatMessage(messages.name)}
                multiLangValue={modelType?.multilingualName}
                onChange={onChangeModelTypeName}
                label={intl.formatMessage(messages.name)}
                mainInputName="multilingualName"
                mainInputClassName={theme.input}
                generalForm={generalForm}
                required
                autoFocus
            />
            <div className={theme.selectWrapper}>
                <Select
                    label={<span className={theme.selectLabel}>{intl.formatMessage(messages.modelType)}</span>}
                    data-test="model-type-select"
                    value={intl.formatMessage(messages.wiki)}
                    originalTheme
                    disabled
                >
                    {[
                        <Select.Option
                            value={intl.formatMessage(messages.wiki)}
                            label={intl.formatMessage(messages.wiki)}
                        />,
                    ]}
                </Select>
            </div>
            <InputId
                disabled={!createMode}
                value={modelType?.id || ''}
                required
                mainInputName="GUID"
                mainInputClassName={theme.input}
                onChange={onChangeModelTypeId}
            />

            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.description)}
                multiLangValue={modelType?.multilingualDescription}
                onChange={onChangeModelTypeDescription}
                textarea
                label={intl.formatMessage(messages.description)}
                mainInputName="multilingualDescription"
                mainInputClassName={theme.textArea}
                generalForm={generalForm}
                data-test="model-type-description-input"
            />

            <div className={theme.selectWrapper}>
                <div className={theme.customRequired}>
                    <Select
                        label={
                            <span className={theme.selectLabel}>{intl.formatMessage(messages.modelTypeGroups)}</span>
                        }
                        data-test="model-type-group-select"
                        onChange={onChangeModelTypeGroup}
                        value={LocalesService.internationalStringToString(
                            currentModelTypeGroup?.multilingualName,
                            currentLocale,
                        )}
                        originalTheme
                    >
                        {modelTypeGroups.map((group: ModelTypeGroup) => {
                            return (
                                <Select.Option
                                    key={group.id}
                                    value={group.id}
                                    label={LocalesService.internationalStringToString(
                                        group.multilingualName,
                                        currentLocale,
                                    )}
                                />
                            );
                        })}
                    </Select>
                </div>
            </div>

            <div className={theme.checkboxWrapper}>
                <Checkbox
                    status={modelType?.enableModel}
                    onChange={() => onChangeModelTypeEnableModel(!modelType?.enableModel)}
                >
                    {intl.formatMessage(messages.enableModel)}
                </Checkbox>
                <Checkbox
                    status={modelType?.allowApprovals}
                    onChange={() => onChangeModelTypeAllowApprovals(!modelType?.allowApprovals)}
                >
                    {intl.formatMessage(messages.allowApprovals)}
                </Checkbox>
            </div>
        </div>
    );
};

const withMemo = React.memo(WikiModelTypeGeneralTab);

export { withMemo as WikiModelTypeGeneralTab };

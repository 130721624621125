import type { TRichTextEditorToolbarProps } from '@/modules/UIKit/components/TipTapTextEditor/Toolbar/RichTextEditorToolbar.types';
import type { TWikiEditorToolbarProps } from './WikiEditor.types';
import React, { useMemo, FC } from 'react';
import { RichTextEditorToolbar as TipTapTextEditorToolbar } from '../../UIKit/components/TipTapTextEditor/Toolbar/RichTextEditorToolbar.component';

const WikiEditorToolbar: FC<TWikiEditorToolbarProps> = (props) => {
    // TODO удалить после выпиливания старого редактора на draftjs
    const isNewEditor = true;
    const EditorToolbar: React.FC<TRichTextEditorToolbarProps> = useMemo(
        () => (isNewEditor ? TipTapTextEditorToolbar : TipTapTextEditorToolbar),
        [isNewEditor],
    );

    return <EditorToolbar {...props} />;
};

export default WikiEditorToolbar;

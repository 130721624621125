import React, { useContext } from 'react';
import icEraser from 'icons/toolbar/controls/ic-eraser.svg';
import { getSelectionCustomInlineStyle } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import ControlsContext from '../Controls.context';
import { EditorState, Modifier } from 'draft-js';
import { each } from 'lodash-es';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';

const getChangedBlock = (editorState) => {
    let contentState = editorState.getCurrentContent();

    ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH', 'MONOSPACE', 'SUPERSCRIPT', 'SUBSCRIPT'].forEach((style) => {
        contentState = Modifier.removeInlineStyle(contentState, editorState.getSelection(), style);
    });

    const customStyles = getSelectionCustomInlineStyle(editorState, ['FONTSIZE', 'FONTFAMILY', 'COLOR', 'BGCOLOR']);
    each(customStyles, (value) => {
        contentState = Modifier.removeInlineStyle(contentState, editorState.getSelection(), value);
    });

    return EditorState.push(editorState, contentState, 'change-inline-style');
};

const EraserComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState({ stateObserver, getChangedBlock });

    return (
        <ToolbarButton
            icon={icEraser}
            tooltip={intl.formatMessage(messages.eraser)}
            onClick={setCurrentState}
            dataTest="wiki-toolbar-group_eraser-button"
            size="small"
        />
    );
};

export default EraserComponent;

import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { closeDialog } from '../../../actions/dialogs.actions';
import { EdgeInstanceImpl, ObjectDefinitionImpl } from '../../../models/bpm/bpm-model-impl';
import { BPMMxGraph } from '../../../mxgraph/bpmgraph';
import { MxGeometry, MxCell } from '../../../mxgraph/mxgraph';
import { ObjectDefinitionSelectors } from '../../../selectors/objectDefinition.selectors';
import { EdgeType } from '../../../serverapi/api';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './CreateInvisibleEdgeDialog.messages';
import theme from './CreateInvisibleEdgeDialog.scss';
import { TEdgeData, TTableRow } from './CreateInvisibleEdgeDialog.types';
import EdgeTypesTable from './EdgeTypesTable.component';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { TRadioOption } from '@/modules/UIKit/components/Radio/Radio.types';
import { RadioGroup } from '@/modules/UIKit/components/Radio/RadioGroup.component';
import { TInvivsibleEdgeCellIntersection } from '../../../mxgraph/bpmgraph.types';

type TCreateInvisibleEdgeDialog = {
    open: boolean;
    graph: BPMMxGraph;
    invivsibleEdgesData: TInvivsibleEdgeCellIntersection[];
};

const prepareTableData = (
    movedToIntersectTypes: EdgeType[],
    intersectToMovedTypes: EdgeType[],
    movedObjId: string,
    movedObjName: string,
    intersectObjId: string,
    intersectObjName: string,
) => {
    const tableData: TTableRow[] = [];

    movedToIntersectTypes.forEach((edgeType) => {
        if (edgeType?.canBeInvisible) {
            tableData.push({
                fromObject: {
                    id: movedObjId,
                    name: movedObjName,
                },
                edgeType: {
                    id: edgeType.id,
                    name: edgeType.name,
                },
                toObject: {
                    id: intersectObjId,
                    name: intersectObjName,
                },
            });
        }
    });

    intersectToMovedTypes.forEach((edgeType) => {
        if (edgeType?.canBeInvisible) {
            tableData.push({
                fromObject: {
                    id: intersectObjId,
                    name: intersectObjName,
                },
                edgeType: {
                    id: edgeType.id,
                    name: edgeType.name,
                },
                toObject: {
                    id: movedObjId,
                    name: movedObjName,
                },
            });
        }
    });

    return tableData;
};

const CreateInvisibleEdgeDialog: FC<TCreateInvisibleEdgeDialog> = (props) => {
    const { open, graph, invivsibleEdgesData } = props;
    const { serverId, repositoryId } = graph.id;

    const [currentEdgeIndex, setCurrentEdgeIndex] = useState<number>(0);
    const {
        movedToIntersectTypes,
        intersectToMovedTypes,
        cellIntersection: { movedCell: movedObj, intersectedCell: intersectObj },
    } = invivsibleEdgesData[currentEdgeIndex];

    const movedObjDef: ObjectDefinitionImpl | undefined = useSelector(
        ObjectDefinitionSelectors.byId({ serverId, repositoryId, id: movedObj.value.objectDefinitionId }),
    );
    const intersectObjDef: ObjectDefinitionImpl | undefined = useSelector(
        ObjectDefinitionSelectors.byId({ serverId, repositoryId, id: intersectObj.value.objectDefinitionId }),
    );

    const tableData = prepareTableData(
        movedToIntersectTypes,
        intersectToMovedTypes,
        movedObj.id,
        movedObjDef?.name || '',
        intersectObj.id,
        intersectObjDef?.name || '',
    );
    const initEdgeData = {
        fromId: tableData[0].fromObject.id,
        edgeTypeId: tableData[0].edgeType.id,
        toId: tableData[0].toObject.id,
    };

    const [selectedEdgeData, selectEdgeData] = useState<TEdgeData>(initEdgeData);
    const [needCreate, setNeedCreate] = useState(true);
    const dispatch = useDispatch();
    const intl = useIntl();

    useEffect(() => {
        selectEdgeData(initEdgeData);
    }, [currentEdgeIndex]);

    const createEdge = (source: MxCell, target: MxCell) => {
        const allEdgeTypes = [...movedToIntersectTypes, ...intersectToMovedTypes];
        const edgeType = allEdgeTypes.find((type) => type.id === selectedEdgeData.edgeTypeId);
        const style = '';
        const edgeValue = new EdgeInstanceImpl({
            id: uuid(),
            style,
            edgeTypeId: edgeType?.id,
            source,
            target,
            name: '',
            invisible: true,
        });

        const edge: MxCell = new MxCell(edgeValue, new MxGeometry(), style);
        edge.setEdge(true);
        edge.setVisible(false);

        return edge;
    };

    const handleOk = () => {
        if (needCreate) {
            const source = movedObj.id === selectedEdgeData.fromId ? movedObj : intersectObj;
            const target = movedObj.id === selectedEdgeData.toId ? movedObj : intersectObj;
            const parent = graph.model.getParent(source);
            const edge = createEdge(source, target);
            graph.addEdge(edge, parent, source, target);
        }
        if (currentEdgeIndex === invivsibleEdgesData.length - 1) {
            dispatch(closeDialog(DialogType.CREATE_INVISIBLE_EDGE_DIALOG));
        } else setCurrentEdgeIndex(currentEdgeIndex + 1);
    };

    const handleClose = () => {
        dispatch(closeDialog(DialogType.CREATE_INVISIBLE_EDGE_DIALOG));
    };

    const handleCancel = () => {
        if (currentEdgeIndex === invivsibleEdgesData.length - 1) {
            dispatch(closeDialog(DialogType.CREATE_INVISIBLE_EDGE_DIALOG));
        } else setCurrentEdgeIndex(currentEdgeIndex + 1);
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCancel,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: handleOk,
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    const radioOptions: TRadioOption<boolean>[] = [
        {
            title: intl.formatMessage(messages.createEdge),
            value: true,
            dataTest: 'window-creating-invisible-edge_create-edge',
        },
        {
            title: intl.formatMessage(messages.dontCreateEdge),
            value: false,
            dataTest: 'window-creating-invisible-edge_dont-create-edge',
        },
    ];

    return (
        <Dialog
            onOk={handleOk}
            onCancel={handleClose}
            title={intl.formatMessage(messages.title)}
            open={open}
            width="620px"
            footer={footer}
        >
            <div>{intl.formatMessage(messages.description)}</div>
            <div className={theme.radioContainer}>
                <RadioGroup<Boolean>
                    value={needCreate}
                    onChange={() => setNeedCreate((prevState) => !prevState)}
                    options={radioOptions}
                    margin={8}
                />
            </div>
            <EdgeTypesTable selectedEdgeData={selectedEdgeData} onSelect={selectEdgeData} tableData={tableData} />
        </Dialog>
    );
};

export default CreateInvisibleEdgeDialog;

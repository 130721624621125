import { TReducer } from '@/utils/types';
import { TSearchResultsWidgetState } from './searchResultsWidget.reducer.types';
import {
    SEARCH_WIDGET_REQUEST_DATA,
    SEARCH_WIDGET_REQUEST_DATA_SUCCESS,
    SEARCH_WIDGET_SET_SEARCH_DATA,
} from '../actionTypes/searchResultsWidget.actionTypes';

const INITIAL_SEARCH_RESULTS_WIDGET_STATE: TSearchResultsWidgetState = {};

export const searchResultsWidgetReducer: TReducer<TSearchResultsWidgetState> = (
    state = INITIAL_SEARCH_RESULTS_WIDGET_STATE,
    action,
) => {
    switch (action.type) {
        case SEARCH_WIDGET_SET_SEARCH_DATA: {
            const {
                payload: { templateId, widgetId, searchData },
            } = action;

            return {
                ...state,
                [templateId]: {
                    ...(state?.[templateId] || {}),
                    [widgetId]: {
                        ...(state?.[templateId]?.[widgetId] || {}),
                        searchData,
                    },
                },
            };
        }

        case SEARCH_WIDGET_REQUEST_DATA: {
            const {
                payload: { templateId, widgetId },
            } = action;

            return {
                ...state,
                [templateId]: {
                    ...(state?.[templateId] || {}),
                    [widgetId]: {
                        ...(state?.[templateId]?.[widgetId] || {}),
                        loading: true,
                    },
                },
            };
        }

        case SEARCH_WIDGET_REQUEST_DATA_SUCCESS: {
            const {
                payload: { templateId, widgetId },
            } = action;

            return {
                ...state,
                [templateId]: {
                    ...(state?.[templateId] || {}),
                    [widgetId]: {
                        ...(state?.[templateId]?.[widgetId] || {}),
                        loading: false,
                    },
                },
            };
        }

        default:
            return state;
    }
};

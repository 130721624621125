import React, { FC, useMemo } from 'react';
import theme from './ReportEditorTable.scss';
import { Cell, ColumnPinningPosition, flexRender } from '@tanstack/react-table';
import { TReportTableData } from '../ReportEditor.types';
import { getTableCellStyles } from './ReportEditorTable.utils';

type TDraggableTableCellProps = {
    cell: Cell<TReportTableData, unknown>;
    isLastRow: boolean;
    onColumnClick: (columnId: string) => void;
};

export const TableCell: FC<TDraggableTableCellProps> = ({ cell, isLastRow, onColumnClick }) => {
    const { column } = cell;

    const isSelected: boolean = !!column?.columnDef?.meta?.selected;
    const isPinned: ColumnPinningPosition = column.getIsPinned();
    const columnWidth: number = column.getSize();

    const style: React.CSSProperties = useMemo(() => ({ position: 'relative', width: columnWidth }), [columnWidth]);
    const pinningStyle: React.CSSProperties = getTableCellStyles(column, null);

    return (
        <td
            key={cell.id}
            style={isPinned ? pinningStyle : style}
            className={isSelected ? (isLastRow ? theme.lastSelectedCell : theme.selectedCell) : ''}
            onClick={(e) => onColumnClick(column.id)}
        >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
    );
};

export const getTooltipSizes = (
    activeAnchor: HTMLElement | null,
    offset: number,
): {
    maxHeight: number | undefined;
} => {
    const element: DOMRect | undefined = activeAnchor?.getBoundingClientRect();

    return {
        maxHeight: (element?.top || 0) - offset,
    };
};

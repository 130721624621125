import React, { FC, useEffect } from 'react';
import { Symbol } from '../../../../../../../serverapi/api';
import dndStyle from './DndSortTable.scss';
import { Draggable, DropResult } from 'react-beautiful-dnd';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Dimensions, Table } from 'react-virtualized';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { IntlShape } from 'react-intl';
import messages from '../ModelType.messages';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import cx from 'classnames';
import { DndWrapper } from './DndWrapper.component';
import { arrayMove } from './utils';
import { IRowCache } from './SymbolTab.types';
import { ComplexSymbolManager } from '../../../../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { SymbolTypeId } from '../../../../../../../mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { Icon } from '../../../../../../UIKit/components/Icon/Icon.component';
import swimlaneSymbol from '../../../../../../../resources/icons/swimlaneSymbol.svg';
import poolSymbol from '../../../../../../../resources/icons/poolSymbol.svg';
import crossSymbol from '../../../../../../../resources/icons/crossSymbol.svg';
import { TableCellProps } from 'semantic-ui-react';

const HEADER_HEIGHT = 35;
const ROW_HEIGHT = 80;

type TDndSortTable = {
    rowSelection: IRowSelection;
    symbols: Symbol[];
    onChangeSymbolsOrder: (symbols: Symbol[]) => void;
    searchIsActive: boolean;
    onRow: (symbolId: string) => void;
    intl: IntlShape;
    graph: MethodologiesGraph | undefined;
    renderRowActionButtons: (symbol: Symbol) => JSX.Element;
};

interface IRowSelection {
    selectedRowKeys: string[];
    onChange: (keys: string[]) => void;
}

interface ISymbolsCache {
    [key: string]: React.ReactNode;
}

let rowCache: IRowCache = {};
let symbolsCache: ISymbolsCache = {};

const getItemStyle = (style, isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'var(--secondColorActive)' : 'inherit',
    ...draggableStyle,
    ...style,
});

export const DndSortTable: FC<TDndSortTable> = (props) => {
    const { rowSelection, symbols, searchIsActive, intl, graph, renderRowActionButtons, onRow, onChangeSymbolsOrder } =
        props;
    if (!symbols.length) {
        return null;
    }
    const cellHeightCache: CellMeasurerCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: ROW_HEIGHT,
    });

    useEffect(() => {
        return () => {
            symbolsCache = {};
            rowCache = {};
        };
    }, []);

    function headerRenderer({ dataKey, label }) {
        return (
            <React.Fragment key={dataKey}>
                <div className={dndStyle.tableLabel}>{label}</div>
            </React.Fragment>
        );
    }

    function getRowRender(rowProps) {
        const { index, className, style } = rowProps;
        const item = symbols[index];
        rowCache[rowProps.index] = rowProps;

        return (
            <Draggable
                {...rowProps}
                draggableId={item.id}
                index={index}
                key={item.id}
                isDragDisabled={searchIsActive}
                style={style}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={rowProps.key}
                        style={getItemStyle(rowProps.style, snapshot.isDragging, provided.draggableProps.style)}
                        className={cx(className, snapshot.isDragging && dndStyle.dndRow)}
                    >
                        {rowProps.columns}
                    </div>
                )}
            </Draggable>
        );
    }

    function onDragEnd({ source, destination }: DropResult) {
        if (!destination || destination.index === source.index) {
            return;
        }
        const newSymbols = arrayMove(symbols, source.index, destination.index);
        onChangeSymbolsOrder(newSymbols);
    }

    function renderHeaderCheckbox() {
        const allSymbolsIds = symbols.map((s) => s.id);
        const isAllRowSelected = symbols.length === rowSelection.selectedRowKeys.length;
        const isIndeterminate = !isAllRowSelected && rowSelection.selectedRowKeys.length > 0;

        function toggleAllRowSelect() {
            if (isAllRowSelected) {
                rowSelection.onChange([]);
            } else {
                rowSelection.onChange(allSymbolsIds);
            }
        }

        return (
            <Checkbox
                status={isIndeterminate ? 'indeterminate' : isAllRowSelected}
                onChange={toggleAllRowSelect}
                onClick={(e) => e.stopPropagation()}
            />
        );
    }

    function renderRowCheckbox({ rowData }) {
        const isRowSelected = rowSelection.selectedRowKeys.some((id) => id === rowData.id);
        const onCheck = () => {
            onRow(rowData.id);
        };

        return <Checkbox onChange={onCheck} onClick={(e) => e.stopPropagation()} status={isRowSelected} />;
    }

    const renderSymbol = (rowData: TableCellProps, symbolElement: JSX.Element) => {
        const { dataKey, parent, rowIndex } = rowData;

        return (
            <CellMeasurer columnIndex={4} cache={cellHeightCache} parent={parent} key={dataKey} rowIndex={rowIndex}>
                {symbolElement}
            </CellMeasurer>
        );
    };

    function renderSymbolCell(rowData) {
        if (symbolsCache[rowData.rowData.id]) {
            return renderSymbol(rowData, <div>{symbolsCache[rowData.rowData.id]}</div>);
        }

        if (ComplexSymbolManager.getSymbolType(rowData.rowData) === SymbolTypeId.VERTICAL_SWIMLANE) {
            return renderSymbol(
                rowData,
                <div className={dndStyle.swimlaneVerticalSymbol}>
                    <Icon spriteSymbol={swimlaneSymbol} />
                </div>,
            );
        }

        if (ComplexSymbolManager.getSymbolType(rowData.rowData) === SymbolTypeId.HORIZONTAL_SWIMLANE) {
            return renderSymbol(
                rowData,
                <div className={dndStyle.swimlaneHorizontalSymbol}>
                    <Icon spriteSymbol={swimlaneSymbol} />
                </div>,
            );
        }

        if (ComplexSymbolManager.getSymbolType(rowData.rowData) === SymbolTypeId.VERTICAL_POOL) {
            return renderSymbol(
                rowData,
                <div className={dndStyle.poolVerticalSymbol}>
                    <Icon spriteSymbol={poolSymbol} />
                </div>,
            );
        }

        if (ComplexSymbolManager.getSymbolType(rowData.rowData) === SymbolTypeId.HORIZONTAL_POOL) {
            return renderSymbol(
                rowData,
                <div className={dndStyle.poolHorizontalSymbol}>
                    <Icon spriteSymbol={poolSymbol} />
                </div>,
            );
        }

        if (ComplexSymbolManager.getSymbolType(rowData.rowData) === SymbolTypeId.CROSS) {
            return renderSymbol(
                rowData,
                <div className={dndStyle.poolHorizontalSymbol}>
                    <Icon spriteSymbol={crossSymbol} />
                </div>,
            );
        }

        const symbolCell = SymbolToImageConverterGraph.convertSymbol(rowData.rowData, intl, graph);
        symbolsCache[rowData.rowData.id] = symbolCell;

        return renderSymbol(rowData, <div>{symbolCell}</div>);
    }

    function renderSymbolIconCell(rowData: Symbol) {
        const iconOrPlaceholder = rowData.icon ? (
            <img className={dndStyle.icon} src={rowData.icon} />
        ) : (
            intl.formatMessage(messages.noIcon)
        );

        return <div>{iconOrPlaceholder}</div>;
    }

    return (
        <DndWrapper rowCache={rowCache} onDragEnd={onDragEnd} getItemStyle={getItemStyle}>
            <div className={dndStyle.tableContainer}>
                <AutoSizer>
                    {({ width, height }: Dimensions) => (
                        <Table
                            tableId="reactVirtualizedTable"
                            rowIdKey="id"
                            width={width}
                            height={height}
                            headerHeight={HEADER_HEIGHT}
                            rowHeight={cellHeightCache.rowHeight}
                            rowCount={symbols.length}
                            rowGetter={({ index }) => symbols[index]}
                            className={dndStyle.table}
                            rowRenderer={getRowRender}
                        >
                            <Column
                                headerRenderer={renderHeaderCheckbox}
                                width={20}
                                minWidth={17}
                                dataKey="checkbox"
                                cellRenderer={renderRowCheckbox}
                            />
                            <Column
                                headerRenderer={headerRenderer}
                                width={270}
                                dataKey="name"
                                label={intl.formatMessage(messages.name)}
                                className={dndStyle.column}
                            />
                            <Column
                                headerRenderer={headerRenderer}
                                width={300}
                                dataKey="id"
                                label={intl.formatMessage(messages.id)}
                            />
                            <Column
                                headerRenderer={headerRenderer}
                                width={300}
                                dataKey="objectType"
                                label={intl.formatMessage(messages.objectType)}
                            />
                            <Column
                                headerRenderer={headerRenderer}
                                width={200}
                                minWidth={100}
                                dataKey="graphical"
                                label={intl.formatMessage(messages.symbol)}
                                cellRenderer={renderSymbolCell}
                            />
                            <Column
                                headerRenderer={headerRenderer}
                                width={80}
                                minWidth={50}
                                dataKey="icon"
                                label={intl.formatMessage(messages.icon)}
                                cellRenderer={({ rowData }) => renderSymbolIconCell(rowData)}
                            />
                            <Column
                                headerRenderer={headerRenderer}
                                width={150}
                                minWidth={100}
                                dataKey="groupId"
                                className={dndStyle.buttonsColumn}
                                cellRenderer={({ rowData }) => renderRowActionButtons(rowData)}
                            />
                        </Table>
                    )}
                </AutoSizer>
            </div>
        </DndWrapper>
    );
};

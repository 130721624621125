import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Icon } from '../../Icon/Icon.component';
import ArrowDown from '@/resources/icons/UIKit/Arrow_Down.svg';
import theme from './ToolbarDropdown.scss';
import { createPortal } from 'react-dom';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup.component';
import { useClickOutside } from '../../Select/useClickOutside';
import classNames from 'classnames';
import { TToolbarDropdown } from './ToolbarDropdown.types';
import { TOOLTIP_RC_ID } from '../../TooltipRC/TooltipRC.constants';

type TDropdownOptions = null | {
    x: number;
    y: number;
};

export const ToolbarDropdown = (props: TToolbarDropdown) => {
    const { icon, options, disabled, tooltip, onClick, dataTest, hideDropdownOnClickItem } = props;
    const [isActive, setIsActive] = useState<boolean>(false);
    const handleClickHeader = () => {
        if (onClick) {
            onClick();
        }
        setIsActive((prevState) => !prevState);
    };
    const [dropdownOptions, setDropdownOptions] = useState<TDropdownOptions>(null);

    const containerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const selectButtonRef: React.MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const portalRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    const recalculateDropdownOptions = () => {
        const clientRects: DOMRect | undefined = selectButtonRef.current?.getBoundingClientRect();
        const portalRects: DOMRect | undefined = portalRef.current?.getBoundingClientRect();

        if (clientRects && portalRects) {
            setDropdownOptions({
                y: window.innerHeight - clientRects.bottom,
                x:
                    clientRects.left + portalRects.width < window.innerWidth
                        ? clientRects.left
                        : clientRects.right - portalRects.width,
            });
        }
    };

    useClickOutside(
        containerRef,
        () => {
            isActive && setIsActive(false);
        },
        portalRef,
    );

    const hideDropdown = () => {
        setIsActive(false);
    };

    useEffect(() => {
        window.addEventListener('resize', hideDropdown);
        document.addEventListener('scroll', hideDropdown, true);

        return () => {
            window.removeEventListener('resize', hideDropdown);
            document.removeEventListener('scroll', hideDropdown);
        };
    }, []);

    useLayoutEffect(() => {
        if (isActive) recalculateDropdownOptions();
    }, [isActive]);

    return (
        <div className={theme.selectorContainer}>
            <div
                ref={containerRef}
                className={classNames(theme.selectHeader, disabled ? theme.disabled : '')}
                data-tooltip-id={TOOLTIP_RC_ID}
                data-tooltip-content={tooltip}
                data-tooltip-max-width={'350px'}
            >
                <button
                    ref={selectButtonRef}
                    type="button"
                    onClick={handleClickHeader}
                    className={classNames(theme.headerBtn)}
                    disabled={disabled}
                    data-test={dataTest}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                >
                    <Icon spriteSymbol={icon} disabled={disabled} />
                    <Icon className={theme.arrowDown} spriteSymbol={ArrowDown} disabled={disabled} />
                </button>
            </div>
            {isActive &&
                createPortal(
                    <div
                        ref={portalRef}
                        data-test={`${props['data-test']}_dropdown`}
                        className={`${theme.dropdownContainer}`}
                        style={{
                            top: `-${dropdownOptions?.y}px`,
                            left: dropdownOptions?.x,
                            width: 'fit-content',
                        }}
                    >
                        <ButtonGroup
                            onClickItem={hideDropdownOnClickItem ? hideDropdown : undefined}
                            showBorder
                            withoutDividerSpace
                        >
                            {options}
                        </ButtonGroup>
                    </div>,
                    document.body,
                )}
        </div>
    );
};

import { useEffect, useState } from 'react';
import { HORIZONTAL_CELLS_COUNT } from '../utils/consts';

type TUseResizeContainerParams = {
    cellsContainer: React.RefObject<HTMLDivElement>;
};

export const useResizeContainer = ({ cellsContainer }: TUseResizeContainerParams) => {
    const [cellSize, setCellSize] = useState<number>(0);

    useEffect(() => {
        const calcCellSize = (width: number) => {
            const newCellSize = Math.round(width / HORIZONTAL_CELLS_COUNT);
            setCellSize(newCellSize);
        };

        const resizeObserver = new ResizeObserver((entries) => {
            const { width } = entries[0].contentRect;
            calcCellSize(width);
        });

        if (cellsContainer.current) {
            resizeObserver.observe(cellsContainer.current);
        }

        return () => {
            if (cellsContainer.current) {
                resizeObserver.unobserve(cellsContainer.current);
            }
        };
    }, [cellsContainer.current]);

    return cellSize;
};

import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import theme from './tooltipRC.scss';
import { getTooltipSizes } from '../../Utils/tooltip.utils';
import { offset, shift } from '@floating-ui/dom';
import {
    MAX_TOOLTIP_WIDTH,
    MIN_TOOLTIP_HEIGHT,
    MIN_TOOLTIP_WIDTH,
    TOOLTIP_OFFSET,
    TOOLTIP_RC_ID,
    VIEW_OFFSET,
} from './TooltipRC.constants';

// описание параметров из библиотеки https://react-tooltip.com/docs/options
// дополнительно можно к элементу передавать data-tooltip-max-width для задания
// максимальной ширины подсказки

export const TooltipRC = () => {
    const [currentElment, setCurrentElment] = useState<HTMLElement | null>(null);
    const { maxHeight } = getTooltipSizes(currentElment, TOOLTIP_OFFSET + VIEW_OFFSET);

    const maxWidth: string | null | undefined = currentElment?.getAttribute('data-tooltip-max-width');

    const handleWheel = (event: WheelEvent) => {
        const tooltip: Element | null = document.querySelector('.react-tooltip-content-wrapper');
        if (!tooltip) return;

        const hasVerticalScroll: boolean = tooltip?.scrollHeight > tooltip?.clientHeight;
        if (!hasVerticalScroll) return;

        event.preventDefault();
        tooltip.scrollTop = tooltip.scrollTop + event.deltaY;
    };

    const handleOnOpen = () => {
        currentElment?.addEventListener('wheel', handleWheel);
    };
    const handleOnHide = () => {
        currentElment?.removeEventListener('wheel', handleWheel);
    };

    return (
        <Tooltip
            id={TOOLTIP_RC_ID}
            opacity={0.8}
            delayShow={0}
            delayHide={0}
            style={{
                maxWidth: maxWidth ? +maxWidth || maxWidth : MAX_TOOLTIP_WIDTH,
                maxHeight,
                minWidth: MIN_TOOLTIP_WIDTH,
                minHeight: MIN_TOOLTIP_HEIGHT,
            }}
            classNameArrow={theme.arrow}
            className={theme.tooltip}
            render={({ content, activeAnchor }) => {
                if (!content || !activeAnchor) return;
                setCurrentElment(activeAnchor);

                return <>{content}</>;
            }}
            afterShow={handleOnOpen}
            afterHide={handleOnHide}
            middlewares={[shift(), offset(TOOLTIP_OFFSET)]}
        />
    );
};

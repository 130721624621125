import React, { FC } from 'react';
import theme from './WidgetsListSidePanel.scss';
import { NavigatorPanel } from '@/modules/Navigator/components/NavigatorPanel/NavigatorPanel.component';
import { TNavigatorTab } from '@/reducers/navigator.reducer.types';
import { useIntl } from 'react-intl';
import messages from './WidgetsListSidePanel.messages';
import { useDispatch } from 'react-redux';
import { getCellContainerId } from '@/modules/UITemplates/utils/UITemplate.utils';
import { WidgetDTO, WidgetGeometry } from '@/serverapi/api';
import { WidgetTypes } from './WidgetsListSidePanel.types';
import { v4 as uuid } from 'uuid';
import {
    UITemplateEditorAddWidget,
    UITemplateEditorSelectWidget,
} from '@/modules/UITemplates/actions/UITemplateEditor.actions';
import { sidePanelWidgets } from './Widgets.consts';
import { Icon } from '@/modules/UIKit';
import { HORIZONTAL_CELLS_COUNT, WIDGETS_PANEL_WIDTH } from '@/modules/UITemplates/utils/consts';

type TWidgetsListSidePanelProps = {
    templateId: string;
    cellSize: number;
};

export const WidgetsListSidePanel: FC<TWidgetsListSidePanelProps> = ({ templateId: id, cellSize }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const onSymbolDrag = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        widgetName: string,
        widgetType: WidgetTypes,
        widgetWidth: number = 4,
        widgetHeight: number = 4,
    ) => {
        event.preventDefault();
        const newWidgetElement: HTMLDivElement = document.createElement('div');
        newWidgetElement.style.width = widgetWidth * cellSize + 'px';
        newWidgetElement.style.height = widgetHeight * cellSize + 'px';
        newWidgetElement.style.background = 'var(--bright_bg)';
        newWidgetElement.style.color = 'black';
        newWidgetElement.style.position = 'absolute';
        newWidgetElement.style.zIndex = '1000';
        newWidgetElement.style.opacity = '0.5';
        newWidgetElement.style.cursor = 'pointer';
        newWidgetElement.style.display = 'flex';
        newWidgetElement.style.justifyContent = 'center';
        newWidgetElement.style.alignItems = 'center';
        newWidgetElement.innerHTML = widgetName;

        document.body.append(newWidgetElement);

        const halfOfWidth: number = (widgetWidth * cellSize) / 2;
        const halfOfHeight: number = (widgetHeight * cellSize) / 2;

        const moveAt = (pageX, pageY) => {
            newWidgetElement.style.left = pageX - halfOfWidth + 'px';
            newWidgetElement.style.top = pageY - halfOfHeight + 'px';
        };

        moveAt(event.pageX, event.pageY);

        let currentDroppable: Element | null = null;
        let droppableBelow: Element | null = null;
        let canBeDropped: boolean = false;

        const onMouseMove = (event) => {
            if (canBeDropped && droppableBelow) {
                const rect = droppableBelow.getBoundingClientRect();
                let x = 0;
                let y = 0;
                if (rect) {
                    x = event.clientX - rect.left;
                    y = event.clientY - rect.top;
                }
                const tmpx = Math.floor(x / cellSize) * cellSize + rect.left;
                const tmpy = Math.floor(y / cellSize) * cellSize + rect.top;
                newWidgetElement.style.left = `${tmpx - halfOfWidth}px`;
                newWidgetElement.style.top = `${tmpy - halfOfHeight}px`;
            } else {
                moveAt(event.pageX, event.pageY);
            }

            newWidgetElement.style.visibility = 'hidden';
            let elemBelow = document.elementFromPoint(event.clientX, event.clientY);
            newWidgetElement.style.visibility = 'visible';

            if (!elemBelow) return;

            droppableBelow = elemBelow.closest(`#${getCellContainerId(id)}`);

            if (currentDroppable != droppableBelow) {
                if (currentDroppable) {
                    canBeDropped = false;
                }
                currentDroppable = droppableBelow;
                if (currentDroppable) {
                    canBeDropped = true;
                }
            }
        };

        document.addEventListener('mousemove', onMouseMove);

        const dropHandler = (event) => {
            if (canBeDropped && droppableBelow) {
                const rect = droppableBelow.getBoundingClientRect();
                let x = 0;
                let y = 0;
                if (rect) {
                    x = event.clientX - rect.left;
                    y = event.clientY - rect.top;
                }

                const geometry: WidgetGeometry = {
                    x: Math.min(
                        Math.max(Math.floor((x - halfOfWidth) / cellSize), 0),
                        HORIZONTAL_CELLS_COUNT - Math.ceil(widgetWidth),
                    ),
                    y: Math.max(Math.floor((y - halfOfHeight) / cellSize), 0),
                    height: Math.ceil(widgetHeight),
                    width: Math.ceil(widgetWidth),
                };

                const newWidget: WidgetDTO = { id: uuid(), geometry, type: widgetType };
                dispatch(UITemplateEditorAddWidget(id, newWidget));
                dispatch(UITemplateEditorSelectWidget(id, newWidget.id));
            }
        };

        const dropClearHandler = () => {
            newWidgetElement.remove();
            newWidgetElement.onmouseup = null;
            document.removeEventListener('mousemove', onMouseMove);
        };

        newWidgetElement.onmouseup = (event) => {
            dropHandler(event);
            if (canBeDropped && droppableBelow) {
                dropClearHandler();
            }
        };

        newWidgetElement.onmousedown = (event) => {
            dropHandler(event);
            dropClearHandler();
        };
    };

    return (
        <NavigatorPanel
            titleProps={{
                title: intl.formatMessage(messages.title),
            }}
            type={TNavigatorTab.Widgets}
            hideCloseButton={true}
            width={WIDGETS_PANEL_WIDTH}
        >
            <div className={theme.sidePanelBody}>
                {sidePanelWidgets.map(({ name, type, icon, widgetWidth, widgetHeight }) => {
                    return (
                        <div
                            key={type}
                            onDragStart={() => false}
                            className={theme.sidePanelWidget}
                            onMouseDown={(event) => onSymbolDrag(event, name, type, widgetWidth, widgetHeight)}
                        >
                            <Icon spriteSymbol={icon} />
                            {name}
                        </div>
                    );
                })}
            </div>
        </NavigatorPanel>
    );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TxtFileEditor-component__editorContainer__N5Agj{height:100%;-webkit-transform-origin:0 0;transform-origin:0 0}.TxtFileEditor-component__editor__wVnKl{height:100%;overflow:auto;position:relative;outline:none}.TxtFileEditor-component__editor__wVnKl .CodeMirror{height:100%}", "",{"version":3,"sources":["webpack://./src/modules/FileEditor/TxtFileEditor.component.scss"],"names":[],"mappings":"AAAA,iDACI,WAAA,CACA,4BAAA,CAAA,oBAAA,CAGJ,wCACI,WAAA,CACA,aAAA,CACA,iBAAA,CACA,YAAA,CAEQ,oDACI,WAAA","sourcesContent":[".editorContainer {\r\n    height: 100%;\r\n    transform-origin: 0 0;\r\n}\r\n\r\n.editor {\r\n    height: 100%;\r\n    overflow: auto;\r\n    position: relative;\r\n    outline: none;\r\n        :global {\r\n            .CodeMirror {\r\n                height: 100%;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorContainer": "TxtFileEditor-component__editorContainer__N5Agj",
	"editor": "TxtFileEditor-component__editor__wVnKl"
};
export default ___CSS_LOADER_EXPORT___;

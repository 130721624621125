import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { NodeId } from '@/serverapi/api';
import { TSearchDataListItem } from '@/reducers/search.reducer.types';
import { LocalesService } from '@/services/LocalesService';
import theme from './SearchResultWidget.scss';
import modelTypeMessages from '@/models/modelType.messages';
import messages from './SearchResultWidget.messages';
import { openModelOnCanvas } from '@/actions/search.actions';
import { TreeNode } from '@/models/tree.types';
import { navigatorTabSelect } from '@/actions/navigator.actions';
import { moveToDirectAction } from '@/actions/editor.actions';
import { TNavigatorTab } from '@/reducers/navigator.reducer.types';
import { getActiveTabs } from '@/selectors/navigator.selectors';
import { Icon } from '@/modules/UIKit';
import icGo from '@/resources/icons/ic-go.svg';

type TSearchListItemProps = TSearchDataListItem & {
    index: number;
    searchNodeId: NodeId;
    showNodeType?: boolean;
    showElementType?: boolean;
};

export const SearchListItem: FC<TSearchListItemProps> = memo(
    ({ index, path, type, multilingualName, nodeId, itemTypeName, deleted, showElementType, showNodeType }) => {
        const dispatch = useDispatch();
        const intl = useIntl();

        const activeTabs: TNavigatorTab[] = useSelector(getActiveTabs);
        const isTreeTabActive = activeTabs.includes(TNavigatorTab.Tree);

        const handleClick = () => {
            dispatch(openModelOnCanvas({ type, nodeId, multilingualName }));
        };

        const handleMoveTo = () => {
            const treeNode: TreeNode = {
                hasChildren: false,
                nodeId,
                name: LocalesService.internationalStringToString(multilingualName),
                multilingualName,
                type,
                countChildren: 0,
            };

            if (!isTreeTabActive) {
                dispatch(navigatorTabSelect(TNavigatorTab.Tree));
            }

            dispatch(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));
        };

        return (
            <div className={theme.listItemContainer}>
                <div className={theme.listItem} onClick={handleClick}>
                    <div className={theme.header}>
                        <span className={theme.name}>
                            {`${index}. ${LocalesService.internationalStringToString(multilingualName)}`}
                        </span>
                        {showNodeType && (
                            <span className={theme.type}>{`[${intl.formatMessage(modelTypeMessages[type])}]`}</span>
                        )}
                        {showElementType && (
                            <span className={theme.type}>{itemTypeName ? `[${itemTypeName}]` : ''}</span>
                        )}
                        {deleted && (
                            <span className={theme.deleted}>{` [${intl.formatMessage(messages.deleted)}]`}</span>
                        )}
                    </div>
                    <div>
                        <span>{path}</span>
                    </div>
                </div>
                <div className={theme.iconContainer}>
                    <Icon spriteSymbol={icGo} onClick={handleMoveTo} />
                </div>
            </div>
        );
    },
);

import svgService from '@/services/SvgServices';
import { Base64 } from 'js-base64';

const SVG_PREFIX: string = 'data:image/svg+xml;base64,';

export const fromSVGStringToBase64 = (svgString: string): string => `${SVG_PREFIX}${Base64.encode(svgString)}`;

export const updateSVGIconViewBox = (graphical: string | ArrayBuffer | null): string | ArrayBuffer | null => {
    if (typeof graphical !== 'string') return graphical;

    const svgString: string = Base64.atob(graphical.replace(SVG_PREFIX, ''));
    const svg: XMLDocument = svgService.parseFromString(svgString);
    const svgRoot: HTMLElement = svg.documentElement;
    const viewBox: string | null = svgRoot.getAttribute('viewBox');

    if (!viewBox) {
        const width: number = parseInt(svgRoot.getAttribute('width') ?? '0');
        const height: number = parseInt(svgRoot.getAttribute('height') ?? '0');

        svgRoot.setAttribute('viewBox', `0 0 ${width} ${height}`);
    }

    return fromSVGStringToBase64(svgService.serializeToImage(svg).svgString);
};

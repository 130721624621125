import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { DEFAULT_FONT_COLOR } from './Controls.constants';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { ColorResult, SketchPicker } from 'react-color';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';
import icTextColor from '@/resources/icons/MainMenuText-Color.svg';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('textStyle').color || DEFAULT_FONT_COLOR;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setColor(value).run();

export const FontColor = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { value, setCurrentState } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const selectFontColorHandler = (colorObject: ColorResult) => {
        setCurrentState(colorObject.hex);
    };

    return (
        <ToolbarButton
            icon={icTextColor}
            dataTest="wiki-toolbar-group_font-color-button"
            overlay={<SketchPicker color={value} onChange={selectFontColorHandler} disableAlpha />}
            tooltip={intl.formatMessage(messages.fontColor)}
            size="small"
        />
    );
};

import React, { Children, ReactNode, useRef } from 'react';
import theme from './ResizableToolbar.scss';
import { DividerComponent } from '../../TipTapTextEditor/Toolbar/controls/Divider.component';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup.component';
import { OpenFormatBtn } from './editorBtns/OpenFormatBtn/OpenFormatBtn.component';
import { useToolbarResize } from '../Toolbars/hooks/useToolbarResize';
import { HiddenElementsDropdown } from '../HiddenElementsDropdown/HiddenElementsDropdown.component';
import { TButtonGroup } from '../ButtonGroup/ButtonGroup.types';
import classNames from 'classnames';
import { FORMAT_BUTTON_LEFT_OFFSET } from './ResizableToolbar.constants';

type TToolbarElement = React.ReactElement<TButtonGroup, typeof ButtonGroup>;

type TResizableToolbarProps = {
    withFormatBtn?: boolean;
    children: (TToolbarElement | null)[] | TToolbarElement | null;
};

export const ResizableToolbar = (props: TResizableToolbarProps) => {
    const { withFormatBtn, children } = props;
    const toolbarRef = useRef<HTMLDivElement | null>(null);
    const additionalBtnsRef = useRef<HTMLDivElement | null>(null);
    const { toolbarWidth, countOfHiddenGroups } = useToolbarResize(toolbarRef, !!withFormatBtn);
    const toolbarElements: ReactNode[] = Children.toArray(children);

    return (
        <div className={theme.toolbarContainer} ref={toolbarRef} data-test="main-menu-toolbar">
            <div className={classNames(theme.mainButtons, countOfHiddenGroups === undefined && theme.hiddenToolbar)}>
                {toolbarElements.map((group, index) => {
                    const hideElement: boolean = index + 1 > toolbarElements.length - (countOfHiddenGroups || 0);
                    return (
                        <>
                            <div className={hideElement ? theme.hiddenGroup : undefined}>{group}</div>
                            <div
                                className={classNames(
                                    theme.dividerContainer,
                                    hideElement ? theme.hiddenGroup : undefined,
                                )}
                            >
                                {index !== toolbarElements.length - 1 ? <DividerComponent /> : null}
                            </div>
                        </>
                    );
                })}
            </div>
            <div className={classNames(theme.additionalButtons)} ref={additionalBtnsRef}>
                {countOfHiddenGroups && toolbarRef.current ? (
                    <ButtonGroup>
                        <HiddenElementsDropdown
                            hiddenGroups={toolbarElements.slice(-countOfHiddenGroups) as JSX.Element[]}
                            containerWidth={toolbarWidth}
                        />
                    </ButtonGroup>
                ) : null}
                {withFormatBtn ? (
                    <div style={{ marginLeft: countOfHiddenGroups ? `${FORMAT_BUTTON_LEFT_OFFSET}px` : 0 }}>
                        <ButtonGroup>
                            <OpenFormatBtn />
                        </ButtonGroup>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

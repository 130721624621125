import { TFoundInConfig } from "./AipConfig.types";
import { TFoundInPreset } from "./AipPreset.types";
import { TWord } from "./common.types";

type TFoundNowhere = {
    source: string;
}

export enum TProcessedItemGroup {
     PRESET = 'PRESET',
     CONFIG = 'CONFIG',
     NOWHERE = 'NOWHERE'
};

export type TProcessedConfigItem = { type: TProcessedItemGroup.CONFIG, value: TFoundInConfig };
export type TProcessedPresetItem = { type: TProcessedItemGroup.PRESET, value: TFoundInPreset };
export type TProcessedNowhereItem = { type: TProcessedItemGroup.NOWHERE, value: TFoundNowhere };

export type TSourceQueryItemInProcessing = TProcessedConfigItem | TProcessedPresetItem | TWord;

export type TProcessedSourceQueryItem =
    TProcessedPresetItem |
    TProcessedConfigItem |
    TProcessedNowhereItem;

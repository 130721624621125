import { defineMessages } from 'react-intl';

export default defineMessages({
    add: {
        id: 'AddNewValueBtn.add',
        defaultMessage: 'Добавить новое значение',
    },
    clear: {
        id: 'AddNewValueBtn.clear',
        defaultMessage: 'Удалить все добавленные значения',
    },
});

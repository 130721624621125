import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openPropertyOnCanvas } from '../../../../../../actions/objectProperty.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import icProperties from '@/resources/icons/MainMenuProperties.svg';

export const PropertyButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getPropertiesBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getPropertiesBtnTooltip);

    return (
        <ToolbarButton
            icon={icProperties}
            size="small"
            dataTest="graph-general-toolbar_property-button"
            disabled={disabled}
            onClick={() => dispatch(openPropertyOnCanvas())}
            tooltip={tooltip}
        />
    );
};

// @test-ignore_ru
import { AipToolbox } from '@/components/aip/bll/AipToolbox';
import  {
    TAipPresetData,
    TAipPresetGroup,
    TFoundAipPresetItem,
    TFoundInPreset,
    TAipPresetItem,
    TSearchGroupsFilter
} from './types/AipPreset.types';
import { StringsEqualityToolbox } from './StringsEqualityToolbox';


export class AipPreset {

    private aipPreset: TAipPresetData;

    private synonyms: TAipPresetData | undefined;

    private isInitialized: boolean = false;

    get() {
        return this.aipPreset;
    }


    whetherHasBeenInitialized(): boolean {
        return this.isInitialized;
    }


    init(data: TAipPresetData) {
        if (this.isInitialized) return;
        this.aipPreset = data;
        this.synonyms = this.initSynonyms();
        this.isInitialized = true;
    }


    /*
        Метод находит среди типов элементов пресета всю переданную строку или или какую-то её начальную часть.
        В процессе работы от искомой строки постепенно отрезается последнее слово до тех пор, пока оставшаяся часть не будет найдена
        среди типов пресета. Результат содержит как найденный в пресете тип, так и исходную строку, которую в соответствии с нашими алгоритмами
        мы признали совпадающей с найденной (у них могут не совпадать окончания, регистр и т.п., но они обе могут пригодиться)
    */
    find(str: string, searchGroups?: TSearchGroupsFilter): TFoundInPreset | null {
        // массив со всеми словами строкИ, пробелы по краям слов отрезаны, пустых строк нет
        const words: string[] = AipToolbox.getWordsFromString(str);
        const len: number = words.length;
        const aipPreset = this.aipPreset;

        if (!len || !aipPreset) return null;

        // пробуем искать строку из всех слов, на каждом шаге отрезая последнее слово до тех пор пока что-то не найдём.
        // отрезаем именно с конца потому что именно там могут быть ненужные слова. Также именно по такому алгоритму мы найдём самый
        // длинный элемент из возможных.
        for (let i: number = len; i > 0; i--) {
            const str: string = words.slice(0, i).join(' ');
            const dataInPreset: TFoundInPreset | null = this.findInternal(str, searchGroups);
            if (dataInPreset) return dataInPreset;
        }
        return null;
    }


    private findInternal(searchTarget: string, searchGroups?: TSearchGroupsFilter): TFoundInPreset | null {
        // хранилища типа TAipPresetData, в которых выполняется поиск. Пока у нас их 2: пресет и синонимы
        const aipPreset: TAipPresetData = this.aipPreset;
        const synonyms: TAipPresetData | undefined = this.synonyms;
        if (!aipPreset) return null; // возможно надо эксепшен выбросить, потому что эта ситуация ненормальная

        const storages: TAipPresetData[] = [ aipPreset ];
        if (synonyms) storages.push(synonyms);

        // находим в хранилищах всё что можно
        const allFoundItems: TFoundAipPresetItem[] = this.findItemsInStorages(searchTarget, storages, searchGroups);

        if (!allFoundItems.length) return null;

        // дистанция Левенштейна для самого лучшего совпадения с целевым элементом (минимальная дистанция)
        const bestMatchDistance: number = Math.min.apply(null, allFoundItems.map((val: TFoundAipPresetItem) => val.distance));

        // из найденных элементов оставляем только элементы с наилучшей дистанцией до искомой строки
        const theBestFoundItems: TFoundAipPresetItem[] = allFoundItems.filter((item: TFoundAipPresetItem) => item.distance === bestMatchDistance);

        // формируем итоговый результат поиска из найденных значений
        return this.buildSearchResult(theBestFoundItems, searchTarget);
    }


    private findItemsInStorages(target: string, storages: Array<TAipPresetData>, searchGroups?: TSearchGroupsFilter): TFoundAipPresetItem[] {
        if (!storages.length) return [];
        return storages
            .map((storage: TAipPresetData) => {
                // оставляем в каждом из хранилищ только нужные группы в случае если указан searchGroups
                if (!searchGroups) return storage;
                const initial: TAipPresetData = {};
                return Object.entries(storage).reduce((acc: TAipPresetData, [ group, values ]: [ TAipPresetGroup, TAipPresetItem[] ]) => {
                    if (searchGroups.includes(group)) acc[group] = values;
                    return acc;
                }, initial);
            })
            .map((storage: TAipPresetData) => {
                // оставляем в каждом из хранилищ только значения, похожие на искомое
                const initial: TAipPresetData = {};
                return Object.entries(storage).reduce((acc: TAipPresetData, [ group, values ]: [ TAipPresetGroup, TAipPresetItem[] ]) => {
                    acc[group] = values.filter((value: TAipPresetItem) => {
                        // значение из пресета (тип модели, объекта, атрибута, связи и т.п.)
                        const txt: string | undefined = value.multilingualName?.ru;
                        if (!txt) return false;
                        const maxDistance = StringsEqualityToolbox.getMaximumAcceptableDistance(target, group);
                        return StringsEqualityToolbox.stringsMatchCheck(target, txt, maxDistance);
                    })
                    return acc;
                }, initial);
            })
            .map((storage: TAipPresetData) => {
                // преобразуем все оставшиеся в хранилищах значения в массивы с промежуточными результатами поиска
                const result: TFoundAipPresetItem[] = [];
                return Object.entries(storage).reduce((acc: TFoundAipPresetItem[], [ group, values ]: [ TAipPresetGroup, TAipPresetItem[] ] ) => {
                    acc = acc.concat(values.map((val: TAipPresetItem) => {
                        const txt: string = val.multilingualName?.ru || '';
                        return { group, item: val, distance: StringsEqualityToolbox.distanceOfLevenshtein(txt, target) };
                    }));
                    return acc;
                }, result);
            })
            .flat();
    }


    private buildSearchResult(foundItems: TFoundAipPresetItem[], source: string): TFoundInPreset | null {
        if (!foundItems.length) return null;
        const firstItem: TFoundAipPresetItem = foundItems[0];

        const initialValue: TFoundInPreset = {
            items: [ firstItem ],
            found: firstItem.item.multilingualName?.ru || '', // TODO по идее тут может быть не только RU, надо бы как-то брать тот язык на котором сделан запрос.
            source: source
        };

        return foundItems
            .slice(1)
            .reduce((acc: TFoundInPreset, item: TFoundAipPresetItem) => {
                const group: TAipPresetGroup = item.group;
                if (acc.items[0].group === group) {
                    acc.items.push(item);
                }
                else {
                    if (!acc.alternate) acc.alternate = [];
                    acc.alternate.push(item);
                }
                return acc;
            }, initialValue);
    }


    private initSynonyms(): TAipPresetData | undefined {
        const modelTypes: TAipPresetItem[] = [
            {
                id: '2c92808766edb7780166f290a33e000d',
                multilingualName: { ru: 'FAD'} // "Диаграмма окружения функции (FAD)"
            },
            {
                id: 'eEPCChart',
                multilingualName: { ru: 'EPC'} // "Событийная цепочка процессов (EPC)"
            },
            {
                id: 'm_bpmn',
                multilingualName: { ru: 'BPMN'} // "BPMN 2.0"
            },
            {
                id: 'psdChart',
                multilingualName: { ru: 'PSD'} // "Диаграмма выбора сценария процесса (PSD)"
            },
            {
                id: 'valueAddedDiagram1Level',
                multilingualName: { ru: 'VAD'} // "Диаграмма цепочки добавленной стоимости (VAD)"
            },
            {
                id: '2c9d8085675575050167834dfc6100ea',
                multilingualName: { ru: 'КПР'} // "Диаграмма ключевых показателей результативности (КПР)"
            }
        ];
        const aipPresetModelTypes: TAipPresetItem[] | undefined = this.aipPreset.modelTypes;
        if (!aipPresetModelTypes) return undefined;
        return {
            modelTypes: modelTypes.filter((item: TAipPresetItem) => aipPresetModelTypes.some((a: TAipPresetItem) => a.id === item.id)),
        }
    }
};

export const aipPreset = new AipPreset();

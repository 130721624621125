// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextStyleAndDirection__textStyleAndDirection__P_hJb{display:-webkit-box;display:-ms-flexbox;display:flex}.TextStyleAndDirection__container___wjQ7{padding:16px;border-bottom:1px solid var(--panelDivider)}.TextStyleAndDirection__blockName__HXLQL{font-size:14px;font-weight:400;font-family:\"Segoe UI\",Tahoma,Geneva,Verdana,sans-serif;line-height:22px;padding-bottom:8px}.TextStyleAndDirection__textStyle__VTUJW,.TextStyleAndDirection__direction__cGfK2{display:-webkit-box;display:-ms-flexbox;display:flex;border-radius:4px;width:-webkit-max-content;width:-moz-max-content;width:max-content}.TextStyleAndDirection__textStyle__VTUJW{margin-right:8px}", "",{"version":3,"sources":["webpack://./src/modules/FormatPanel/components/TextStyleAndDirection/TextStyleAndDirection.scss"],"names":[],"mappings":"AAAA,qDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGJ,yCACI,YAAA,CACA,2CAAA,CAGJ,yCACI,cAAA,CACA,eAAA,CACA,uDAAA,CACA,gBAAA,CACA,kBAAA,CAGJ,kFAEI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,iBAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CAEJ,yCACI,gBAAA","sourcesContent":[".textStyleAndDirection {\r\n    display: flex;\r\n}\r\n\r\n.container {\r\n    padding: 16px;\r\n    border-bottom: 1px solid var(--panelDivider);\r\n}\r\n\r\n.blockName {\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    line-height: 22px;\r\n    padding-bottom: 8px;\r\n}\r\n\r\n.textStyle,\r\n.direction {\r\n    display: flex;\r\n    border-radius: 4px;\r\n    width: max-content;\r\n}\r\n.textStyle {\r\n    margin-right: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textStyleAndDirection": "TextStyleAndDirection__textStyleAndDirection__P_hJb",
	"container": "TextStyleAndDirection__container___wjQ7",
	"blockName": "TextStyleAndDirection__blockName__HXLQL",
	"textStyle": "TextStyleAndDirection__textStyle__VTUJW",
	"direction": "TextStyleAndDirection__direction__cGfK2"
};
export default ___CSS_LOADER_EXPORT___;

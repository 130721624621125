import { RightOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { moveToDirectAction } from '../../../actions/editor.actions';
import { openModelOnCanvas } from '../../../actions/search.actions';
import messages from './DbSearchListItem.messages';
import modelTypeMessages from '../../../models/modelType.messages';
import moveToMessages from '../../MainMenu/components/GraphGeneralToolbar/ToolbarItems/MoveToSelect/MoveToDropdown.messages';
import { TreeNode } from '../../../models/tree.types';
import { TSearchDataListItem } from '../../../reducers/search.reducer.types';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { HightLighted } from '../../UIKit/H.O.C/HightLighted/HightLighted.hoc';
import style from './DbSearchListItem.scss';
import { LocalesService } from '../../../services/LocalesService';
import { navigatorTabSelect } from '../../../actions/navigator.actions';
import { TNavigatorTab } from '../../../reducers/navigator.reducer.types';
import { getActiveTabs } from '../../../selectors/navigator.selectors';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { NodeId } from '@/serverapi/api';

type TDbSearchListItemProps = TSearchDataListItem & {
    index: number;
    searchNodeId: NodeId;
};

const DbSearchListItem: FC<TDbSearchListItemProps> = ({
    searchNodeId,
    index,
    path,
    type,
    multilingualName,
    nodeId,
    itemTypeName,
    deleted,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const activeTabs: TNavigatorTab[] = useSelector(getActiveTabs);
    const isTreeTabActive = activeTabs.includes(TNavigatorTab.Tree);
    const searchText = useSelector(SearchSelectors.getSearchText(searchNodeId)).trim();

    const handleClick = () => {
        dispatch(openModelOnCanvas({ type, nodeId, multilingualName }));
    };

    const handleMoveTo = () => {
        const treeNode: TreeNode = {
            hasChildren: false,
            nodeId,
            name: LocalesService.internationalStringToString(multilingualName),
            multilingualName,
            type,
            countChildren: 0,
        };

        if (!isTreeTabActive) {
            dispatch(navigatorTabSelect(TNavigatorTab.Tree));
        }

        dispatch(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));
    };

    return (
        <div className={style.listItemContainer}>
            <div className={style.listItem} onClick={handleClick}>
                <div className={style.header}>
                    <span className={style.name}>
                        {`${index}. `}
                        <HightLighted searchText={searchText}>
                            {LocalesService.internationalStringToString(multilingualName)}
                        </HightLighted>
                    </span>
                    <span className={style.type}>{`[${intl.formatMessage(modelTypeMessages[type])}]`}</span>
                    <span className={style.type}>{itemTypeName ? `[${itemTypeName}]` : ''}</span>
                    {deleted && <span className={style.deleted}>{` [${intl.formatMessage(messages.deleted)}]`}</span>}
                </div>
                <div>
                    <span>{path}</span>
                </div>
            </div>
            <Button onClick={handleMoveTo}>
                {intl.formatMessage(moveToMessages.title)}
                <RightOutlined className={style.rightOutlinedIcon} />
            </Button>
        </div>
    );
};

const withMemo = React.memo(DbSearchListItem);

export { withMemo as DbSearchListItem };

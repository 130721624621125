import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import {
    ReportNode,
    NodeId,
    AttributeValue,
    ReportColumnData,
    AttributeType,
    PrincipalDescriptor,
    ReportDataFillingTypeEnum,
    SearchRequest,
    SearchResult,
    GeneralModel,
    ObjectDefinitionNode,
    EdgeDefinitionNode,
    FolderNode,
    Symbol,
} from '../../../serverapi/api';
import { TReportState } from '../reducers/report.reducer.types';
import { TExtendedNode } from '@/selectors/types/nodesSelector.types';
import { mapNodeToSystemAttributeValues } from '@/mxgraph/overlays/BPMMxCellOverlay.utils';
import { storageValueToString } from '@/modules/ObjectPropertiesDialog/components/utils';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import modelTypeMessages from '@/models/modelType.messages';
import { systemAttributeTypes } from '@/utils/constants/systemAttributes.const';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import { TCurrentUserProfile } from '@/reducers/userProfile.reducer.types';
import { ProfileBllService } from '@/services/bll/ProfileBllService';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { TReportEditor } from '../reducers/reportEditor.reducer.types';
import { TNodeState } from '@/reducers/entities/TNodeState';
import { SearchElementTypeSelectors } from '@/modules/Search/selectors/searchElementType.selectors';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { SymbolSelectors } from '@/selectors/symbol.selectors';
import { CustomMap } from '@/utils/map';
import { TSymbolStateKey } from '@/reducers/symbol.reducer.types';
import {
    TFilterType,
    TNumericFilter,
    TNumericFilterRules,
    TReportFilterData,
    TReportFilters,
} from '../reducers/reportEditor.reducer.types';
import { TReportTableData } from '../ReportEditor/ReportEditor.types';

const reportStateSelector = (state: TRootState): TReportState => state.report;
const reportEditorStateSelector = (state: TRootState): TNodeState<TReportEditor> => state.reportEditor.editors;
const reportNodes = (state: TRootState): TNodeState<TExtendedNode> => state.reportEditor.nodes;
const getRootState = (state: TRootState) => state;
export namespace ReportSelectors {
    export const byId = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, ReportNode | undefined>(reportStateSelector, (state) =>
            state.get(reportNodeId),
        );

    export const byIds = (nodeIds: NodeId[]) =>
        createSelector<TRootState, TReportState, ReportNode[]>(reportStateSelector, (state) => {
            const reportArr: ReportNode[] = nodeIds
                .map((reportNodeId) => {
                    const report: ReportNode | undefined = state.get(reportNodeId);
                    return report;
                })
                .filter((element): element is ReportNode => !!element);

            return reportArr;
        });

    export const getAccessibleSystemAttributeTypes = (reportId: NodeId) =>
        createSelector<TRootState, ReportNode | undefined, AttributeType[]>(byId(reportId), (report) => {
            const reportColumns: ReportColumnData[] = report?.reportData?.columns || [];

            return systemAttributeTypes.filter(
                (attrType) =>
                    !reportColumns.some(
                        (column) => column.attributeType === 'SYSTEM' && column.attributeTypeId === attrType.id,
                    ),
            );
        });

    export const getUnusedAttributeTypes = (reportId: NodeId) =>
        createSelector<
            TRootState,
            ReportNode | undefined,
            TCurrentUserProfile | undefined,
            AttributeType[],
            AttributeType[]
        >(
            byId(reportId),
            UserProfileSelectors.selectUserProfileByNodeId(reportId),
            AttributeTypeSelectors.allInPresetByNodeIdSorted(reportId),
            (report, profile, attributeTypes) => {
                const reportColumns: ReportColumnData[] = report?.reportData?.columns || [];

                return attributeTypes.filter(
                    (attrType) =>
                        ProfileBllService.isAttributeViewable(profile, attrType.id) &&
                        !reportColumns.some(
                            (column) => column.attributeType === 'USER' && column.attributeTypeId === attrType.id,
                        ),
                );
            },
        );

    export const getAccessibleAttributeTypes = (reportId: NodeId) =>
        createSelector<TRootState, TCurrentUserProfile | undefined, AttributeType[], AttributeType[]>(
            UserProfileSelectors.selectUserProfileByNodeId(reportId),
            AttributeTypeSelectors.allInPresetByNodeIdSorted(reportId),
            (profile, attributeTypes) =>
                attributeTypes.filter((attrType) => ProfileBllService.isAttributeViewable(profile, attrType.id)),
        );

    export const getNodesForReport = (reportId: NodeId) =>
        createSelector<TRootState, TRootState, ReportNode | undefined, SearchResult[], string, Locale, TExtendedNode[]>(
            getRootState,
            byId(reportId),
            searchResults(reportId),
            TreeSelectors.presetById(reportId),
            getCurrentLocale,
            (rootState, report, searchResults, presetId, locale) => {
                if (!report?.reportData) return [];

                const fillingType: ReportDataFillingTypeEnum = report.reportData.fillingType;

                if (fillingType === 'AUTO') {
                    const searchNodes: TExtendedNode[] = searchResults.map((searchResult) => {
                        return {
                            ...searchResult,
                            name: LocalesService.internationalStringToString(searchResult.multilingualName, locale),
                            type: searchResult.nodeType,
                            presetId,
                        } as TExtendedNode;
                    });

                    return searchNodes;
                } else {
                    const manuallyFilledNodes: NodeId[] = report.reportData.manuallyFilledNodes || [];
                    const nodesWithPresetId: TExtendedNode[] =
                        getNodesWithPresetIdByIds(manuallyFilledNodes)(rootState);

                    return nodesWithPresetId;
                }
            },
        );

    const getSystemAttributeValueForColumn = (
        systemAttributeValues: AttributeValue[],
        reportColumn: ReportColumnData,
        node: TExtendedNode,
        symbols: CustomMap<TSymbolStateKey, Symbol>,
        principals: PrincipalDescriptor[],
        locale: Locale,
    ): string => {
        const intl = LocalesService.useIntl();

        const attribute = systemAttributeValues.find((value) => value.typeId === reportColumn.attributeTypeId);

        let stringAttributeValue = storageValueToString(attribute, locale, {
            system: true,
            attributeTypes: systemAttributeTypes,
            principals,
        });

        if (reportColumn.attributeTypeId === 'nodeType') {
            stringAttributeValue = modelTypeMessages[node.type] && intl.formatMessage(modelTypeMessages[node.type]);
        }

        if (reportColumn.attributeTypeId === 'symbolId') {
            const symbol: Symbol | undefined = symbols.get({
                presetId: node.presetId,
                symbolId: (node as ObjectDefinitionNode).idSymbol || '',
                serverId: node.nodeId.serverId,
            });

            if (symbol) {
                stringAttributeValue = LocalesService.internationalStringToString(symbol.multilingualName, locale);
            }
        }

        if (
            (reportColumn.attributeTypeId === 'modelTypeId' &&
                (node.type === 'MODEL' ||
                    node.type === 'MATRIX' ||
                    node.type === 'REPORT' ||
                    node.type === 'WIKI' ||
                    node.type === 'DASHBOARD')) ||
            (reportColumn.attributeTypeId === 'objectTypeId' && node.type === 'OBJECT') ||
            (reportColumn.attributeTypeId === 'edgeTypeId' && node.type === 'EDGE') ||
            (reportColumn.attributeTypeId === 'folderTypeId' && node.type === 'FOLDER')
        ) {
            stringAttributeValue = node.elementTypeName || '';
        }

        return stringAttributeValue;
    };

    const checkFilter = (
        filter: TReportFilterData,
        stringAttributeValue: string,
        attribute: AttributeValue | undefined,
    ): boolean => {
        const { filterType, data }: TReportFilterData = filter;
        const attributeValueIsEmpty: boolean = stringAttributeValue === '';
        let filtered: boolean = false;

        if (filterType === TFilterType.NUMERIC || filterType === TFilterType.DATE) {
            const { rule, value1, value2 } = data as TNumericFilter;

            // если нет правила или значения, фильтр не срабатывает
            if (!rule || !value1) return false;

            // если есть правило и значение, но атрибут пустой, тогда его не отображаем
            if (attributeValueIsEmpty) {
                return true;
            }

            const attributeValue: number = Number(attribute?.value);

            switch (rule) {
                case TNumericFilterRules.BETWEEN: {
                    filtered = !!value1 && !!value2 && !(attributeValue > value1 && attributeValue < value2);
                    break;
                }
                case TNumericFilterRules.LESS: {
                    filtered = attributeValue >= value1;
                    break;
                }
                case TNumericFilterRules.GREATER: {
                    filtered = attributeValue <= value1;
                    break;
                }
                case TNumericFilterRules.EQUALS: {
                    filtered = attributeValue !== value1;
                    break;
                }
            }
        }

        if (filterType === TFilterType.STRING) {
            const values: string[] = data as string[];

            if (values.length === 0) return false;

            filtered = !values.includes(stringAttributeValue) || stringAttributeValue === '';
        }

        return filtered;
    };

    export const getReportTableData = (reportId: NodeId) =>
        createSelector<
            TRootState,
            TExtendedNode[],
            ReportNode | undefined,
            string,
            string,
            AttributeType[],
            PrincipalDescriptor[],
            Locale,
            CustomMap<TSymbolStateKey, Symbol>,
            TReportFilters,
            TReportTableData[]
        >(
            getNodesForReport(reportId),
            byId(reportId),
            searchValue(reportId),
            TreeSelectors.presetById(reportId),
            getAccessibleAttributeTypes(reportId),
            PrincipalsSelectors.getAll,
            getCurrentLocale,
            SymbolSelectors.all,
            getFilters(reportId),
            (
                nodesForReport,
                report,
                filterValue,
                reportPresetId,
                accessibleAttributeTypes,
                principals,
                locale,
                symbols,
                filters,
            ) => {
                const reportColumns: ReportColumnData[] = report?.reportData?.columns || [];

                const tableData: TReportTableData[] = nodesForReport.map((node) => {
                    const id: string = `${node.nodeId.repositoryId}_${node.nodeId.id}`;
                    const tableRow: TReportTableData = {
                        id,
                        nodeId: node.nodeId,
                        found: false,
                        filtered: false,
                        attributes: {},
                    };

                    const systemAttributeValues: AttributeValue[] = mapNodeToSystemAttributeValues(node, undefined);

                    reportColumns.forEach((reportColumn) => {
                        let stringAttributeValue = '';
                        let attribute: AttributeValue | undefined;
                        const dataKey: string = `${reportColumn.attributeType}_${reportColumn.attributeTypeId}`;

                        if (reportColumn.attributeType === 'SYSTEM') {
                            stringAttributeValue = getSystemAttributeValueForColumn(
                                systemAttributeValues,
                                reportColumn,
                                node,
                                symbols,
                                principals,
                                locale,
                            );
                        }

                        if (reportColumn.attributeType === 'USER') {
                            attribute = node.attributes?.find(
                                (attribute) => attribute.typeId === reportColumn.attributeTypeId,
                            );
                            if (attribute && node.presetId === reportPresetId) {
                                stringAttributeValue = storageValueToString(attribute, locale, {
                                    system: false,
                                    attributeTypes: accessibleAttributeTypes,
                                    principals: principals,
                                });
                            }
                        }

                        tableRow.attributes[dataKey] = stringAttributeValue;

                        if (!tableRow.found) {
                            tableRow.found =
                                filterValue !== '' &&
                                stringAttributeValue.toLowerCase().includes(filterValue.toLowerCase());
                        }

                        // проверяем фильтры
                        const filter: TReportFilterData | undefined = filters[reportColumn.columnId];
                        if (filter && !tableRow.filtered) {
                            tableRow.filtered = checkFilter(filter, stringAttributeValue, attribute);
                        }
                    });

                    return tableRow;
                });

                return tableData;
            },
        );

    export const fillingType = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, ReportDataFillingTypeEnum>(reportStateSelector, (state) => {
            return state.get(reportNodeId)?.reportData?.fillingType || 'MANUAL';
        });

    export const searchRequests = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, SearchRequest[]>(reportStateSelector, (state) => {
            if (state.get(reportNodeId)?.reportData?.fillingType === 'AUTO') {
                return state.get(reportNodeId)?.reportData?.searchRequests || [];
            }

            return [];
        });

    export const searchResults = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, SearchResult[]>(reportEditorStateSelector, (state) => {
            return state.get(reportNodeId)?.searchResults || [];
        });

    export const isLoading = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, boolean>(
            reportEditorStateSelector,
            (state) => !!state.get(reportNodeId)?.loading,
        );

    export const searchValue = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, string>(
            reportEditorStateSelector,
            (state) => state.get(reportNodeId)?.searchValue || '',
        );

    export const selectedColumnId = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, string | undefined>(
            reportEditorStateSelector,
            (state) => state.get(reportNodeId)?.selectedColumnId,
        );

    export const isUnsaved = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, boolean>(reportEditorStateSelector, (state) => {
            if (state.get(reportNodeId)?.unsaved === undefined) return true;

            return !!state.get(reportNodeId)?.unsaved;
        });

    const getNodesWithPresetIdByIds = (nodeIds: NodeId[]) =>
        createSelector<TRootState, TRootState, TNodeState<TExtendedNode>, TExtendedNode[]>(
            getRootState,
            reportNodes,
            (rootState: TRootState, nodeState: TNodeState<TExtendedNode>) => {
                const result: TExtendedNode[] = [];
                nodeIds.forEach((nodeId) => {
                    const node: TExtendedNode | undefined = nodeState.byNodeId.get(nodeId);
                    if (node) {
                        const presetId: string = TreeSelectors.presetById(nodeId)(rootState);
                        const elementTypeName: string = SearchElementTypeSelectors.getElementTypeName(
                            node.nodeId.serverId,
                            presetId,
                            node.elementTypeId || '',
                            node.type as TreeItemType,
                        )(rootState);
                        const elementTypeId: string =
                            node?.elementTypeId ||
                            (node as EdgeDefinitionNode)?.edgeTypeId ||
                            (node as ObjectDefinitionNode)?.objectTypeId ||
                            (node as GeneralModel)?.modelTypeId ||
                            (node as FolderNode)?.folderType ||
                            '';
                        const nodeWithPresetId: TExtendedNode = { ...node, presetId, elementTypeId, elementTypeName };
                        result.push(nodeWithPresetId);
                    }
                });

                return result;
            },
        );

    export const getFilters = (reportNodeId: NodeId) =>
        createSelector<TRootState, TNodeState<TReportEditor>, TReportFilters>(
            reportEditorStateSelector,
            (state) => state.get(reportNodeId)?.columnFilters || {},
        );

    export const getFilterValue = (reportNodeId: NodeId, columnId: string, filterType: TFilterType) =>
        createSelector<TRootState, TNodeState<TReportEditor>, TNumericFilter | string[]>(
            reportEditorStateSelector,
            (state) => {
                const data: string[] | TNumericFilter | undefined =
                    state.get(reportNodeId)?.columnFilters?.[columnId]?.data;

                return filterType === TFilterType.STRING ? data || [] : data || {};
            },
        );
}

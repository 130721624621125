import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { DiagramElement, MatrixLane, MatrixNode, NodeId } from '@/serverapi/api';
import { MatrixSelectors } from './matrix.selectors';
import { getSelectedLanesWithChildren } from '@/modules/Matrix/utils/Matrix.utils';
import { getCopiedElements } from '../../../selectors/editor.selectors';
import { HeaderType } from '../MatrixEditor/Matrix.types';
import {
    TMatrixEditorState,
    TMatrixCellData,
    TSelectedHeadersCells,
    TMatrixEditor,
} from '../reducers/matrixEditor.reducer.types';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { LocalesService } from '../../../services/LocalesService';
import messages from '@/modules/MainMenu/components/MatrixEditorToolbar/MatrixEditorToolbar.messages';

const matrixEditorStateSelector = (state: TRootState): TMatrixEditorState => state.matrixEditor;

export namespace MatrixEditorSelectors {
    export const getSelectedHeaderCells = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, TSelectedHeadersCells>(
            matrixEditorStateSelector,
            (state) => state.get(nodeId)?.selectedHeaderCells || { type: HeaderType.column, ids: [] },
        );

    export const getSelectedCells = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, string[]>(
            matrixEditorStateSelector,
            (state) => state.get(nodeId)?.selectedCells || [],
        );

    export const isMatrixUnsaved = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(
            matrixEditorStateSelector,
            (state) => !!state.get(nodeId)?.unsaved,
        );

    export const isCopyEnabled = (nodeId: NodeId) =>
        createSelector<TRootState, TSelectedHeadersCells, MatrixNode | undefined, boolean>(
            getSelectedHeaderCells(nodeId),
            MatrixSelectors.byId(nodeId),
            (selectedHeadersCells, matrix) => {
                if (!matrix?.content) return false;
                const { type, ids } = selectedHeadersCells;
                const lanes: MatrixLane[] = type === HeaderType.row ? matrix.content.rows : matrix.content.columns;

                const isOnlyEmptyLanes: boolean = !ids.some((id) => lanes.find((lane) => lane.id === id)?.text);
                if (isOnlyEmptyLanes) {
                    return false;
                }

                const lastFilledIndex: number = lanes.findLastIndex((lane) => lane.text);
                const filledLanes: MatrixLane[] = lanes.slice(0, lastFilledIndex + 1);
                const lanesToCopyWithChildren: MatrixLane[] = getSelectedLanesWithChildren(ids, filledLanes, true);

                return !!lanesToCopyWithChildren.length;
            },
        );

    export const isPasteEnabled = (nodeId: NodeId) =>
        createSelector<
            TRootState,
            TSelectedHeadersCells,
            MatrixLane[] | DiagramElement[],
            MatrixNode | undefined,
            boolean
        >(
            getSelectedHeaderCells(nodeId),
            getCopiedElements,
            MatrixSelectors.byId(nodeId),
            (selectedHeaderCells, copiedElements, matrix) => {
                if (!matrix?.content) return false;

                const isAutomatic =
                    (matrix.content.rowSettings.isAutomatic && selectedHeaderCells.type === HeaderType.row) ||
                    (matrix.content.columnSettings.isAutomatic && selectedHeaderCells.type === HeaderType.column);

                return !!selectedHeaderCells.ids.length && !!copiedElements.length && !isAutomatic;
            },
        );

    export const getCellData = (nodeId: NodeId, cellId: string) =>
        createSelector<TRootState, TMatrixEditorState, TMatrixCellData | undefined>(
            matrixEditorStateSelector,
            (state) => state.get(nodeId)?.cellsData?.[cellId],
        );

    export const isLoadingCellData = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(
            matrixEditorStateSelector,
            (state) => !!state.get(nodeId)?.isLoadingCellData,
        );

    export const isHeaderCellToggled = (matrixNodeId: NodeId, headerCellId: string, type: HeaderType) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(matrixEditorStateSelector, (state) => {
            if (type === HeaderType.column) {
                return !!state.get(matrixNodeId)?.toggledColumnsIds.includes(headerCellId);
            } else {
                return !!state.get(matrixNodeId)?.toggledRowsIds.includes(headerCellId);
            }
        });

    export const getToggledHeaderCellsIds = (matrixNodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, { toggledColumnsIds: string[]; toggledRowsIds: string[] }>(
            matrixEditorStateSelector,
            (state) => {
                const matrix: TMatrixEditor | undefined = state.get(matrixNodeId);
                if (matrix) {
                    const { toggledColumnsIds, toggledRowsIds } = matrix;
                    return { toggledColumnsIds, toggledRowsIds };
                }
                return { toggledColumnsIds: [], toggledRowsIds: [] };
            },
        );
    export const isRowPastEnabled = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(matrixEditorStateSelector, (state) => {
            const selectedHeaderCells: TSelectedHeadersCells | undefined = state.get(nodeId)?.selectedHeaderCells;
            return selectedHeaderCells?.type === HeaderType.row && selectedHeaderCells.ids.length === 1;
        });

    export const isColPastEnabled = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(matrixEditorStateSelector, (state) => {
            const selectedHeaderCells: TSelectedHeadersCells | undefined = state.get(nodeId)?.selectedHeaderCells;
            return selectedHeaderCells?.type === HeaderType.column && selectedHeaderCells.ids.length === 1;
        });

    export const getOpenSettingsDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            return isReadMode;
        },
    );

    export const getOpenSettingsTooltip = createSelector<TRootState, boolean, string>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const intl = LocalesService.useIntl();
            return isReadMode
                ? intl.formatMessage(messages.onlyInEditModeAvailable)
                : intl.formatMessage(messages.openSettings);
        },
    );

    export const getRefreshDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            return !isReadMode;
        },
    );

    export const getRefreshTooltip = createSelector<TRootState, boolean, string>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const intl = LocalesService.useIntl();
            return isReadMode
                ? intl.formatMessage(messages.refreshMatrix)
                : intl.formatMessage(messages.onlyInReadModeAvailable);
        },
    );

    export const getPasteDisabled = (nodeId: NodeId) =>
        createSelector<TRootState, boolean, boolean, boolean>(
            TabsSelectors.checkIsReadModeInActiveTab,
            isPasteEnabled(nodeId),
            (isReadMode, isPasteEnabled) => {
                return isReadMode || !isPasteEnabled;
            },
        );

    export const getPasteTooltip = (nodeId: NodeId) =>
        createSelector(
            getSelectedHeaderCells(nodeId),
            getCopiedElements,
            MatrixSelectors.byId(nodeId),
            TabsSelectors.checkIsReadModeInActiveTab,
            (selectedHeaderCells, copiedElements, matrix, isReadMode) => {
                const intl = LocalesService.useIntl();
                if (isReadMode) return intl.formatMessage(messages.onlyInEditModeAvailable);

                const isAutomatic: boolean | undefined =
                    (matrix?.content?.rowSettings.isAutomatic && selectedHeaderCells.type === HeaderType.row) ||
                    (matrix?.content?.columnSettings.isAutomatic && selectedHeaderCells.type === HeaderType.column);

                if (isAutomatic) return intl.formatMessage(messages.forbiddenByAutomatic);

                if (!copiedElements.length) return intl.formatMessage(messages.clipboardIsEmpty);

                if (!selectedHeaderCells.ids.length) return intl.formatMessage(messages.selectHeaderToPaste);

                return intl.formatMessage(messages.paste);
            },
        );

    export const getCopyDisabled = (nodeId: NodeId) =>
        createSelector<TRootState, boolean, boolean, boolean>(
            TabsSelectors.checkIsReadModeInActiveTab,
            isCopyEnabled(nodeId),
            (isReadMode, isCopyEnabled) => {
                return isReadMode || !isCopyEnabled;
            },
        );

    export const getCopyTooltip = (nodeId: NodeId) =>
        createSelector(
            getCopyDisabled(nodeId),
            TabsSelectors.checkIsReadModeInActiveTab,
            (isCopyDisabled, isReadMode) => {
                const intl = LocalesService.useIntl();
                if (isReadMode) return intl.formatMessage(messages.onlyInEditModeAvailable);

                return isCopyDisabled
                    ? intl.formatMessage(messages.selectHeadersToCopy)
                    : intl.formatMessage(messages.copy);
            },
        );

    export const getRowPasteDisabled = (nodeId: NodeId) =>
        createSelector<TRootState, boolean, boolean, boolean>(
            TabsSelectors.checkIsReadModeInActiveTab,
            isRowPastEnabled(nodeId),
            (isReadMode, isPasteEnabled) => {
                return isReadMode || !isPasteEnabled;
            },
        );

    export const getRowPasteTopTooltip = (nodeId: NodeId) =>
        createSelector<TRootState, boolean, boolean, string>(
            getRowPasteDisabled(nodeId),
            TabsSelectors.checkIsReadModeInActiveTab,
            (isPasteDisabled, isReadMode) => {
                const intl = LocalesService.useIntl();
                if (isReadMode) return intl.formatMessage(messages.onlyInEditModeAvailable);

                return isPasteDisabled
                    ? intl.formatMessage(messages.selectRow)
                    : intl.formatMessage(messages.pasteRowTop);
            },
        );

    export const getRowPasteBottomTooltip = (nodeId: NodeId) =>
        createSelector<TRootState, boolean, boolean, string>(
            getRowPasteDisabled(nodeId),
            TabsSelectors.checkIsReadModeInActiveTab,
            (isPasteDisabled, isReadMode) => {
                const intl = LocalesService.useIntl();
                if (isReadMode) return intl.formatMessage(messages.onlyInEditModeAvailable);

                return isPasteDisabled
                    ? intl.formatMessage(messages.selectRow)
                    : intl.formatMessage(messages.pasteRowBtm);
            },
        );

    export const getColPasteDisabled = (nodeId: NodeId) =>
        createSelector<TRootState, boolean, boolean, boolean>(
            TabsSelectors.checkIsReadModeInActiveTab,
            isColPastEnabled(nodeId),
            (isReadMode, isPasteEnabled) => {
                return isReadMode || !isPasteEnabled;
            },
        );

    export const getColPasteLeftTooltip = (nodeId: NodeId) =>
        createSelector<TRootState, boolean, boolean, string>(
            getColPasteDisabled(nodeId),
            TabsSelectors.checkIsReadModeInActiveTab,
            (isPasteDisabled, isReadMode) => {
                const intl = LocalesService.useIntl();
                if (isReadMode) return intl.formatMessage(messages.onlyInEditModeAvailable);

                return isPasteDisabled
                    ? intl.formatMessage(messages.selectColumn)
                    : intl.formatMessage(messages.pasteColLeft);
            },
        );

    export const getColPasteRightTooltip = (nodeId: NodeId) =>
        createSelector(
            getColPasteDisabled(nodeId),
            TabsSelectors.checkIsReadModeInActiveTab,
            (isPasteDisabled, isReadMode) => {
                const intl = LocalesService.useIntl();
                if (isReadMode) return intl.formatMessage(messages.onlyInEditModeAvailable);

                return isPasteDisabled
                    ? intl.formatMessage(messages.selectColumn)
                    : intl.formatMessage(messages.pasteColRight);
            },
        );

    export const getGetImageTooltip = () => {
        const intl = LocalesService.useIntl();
        return intl.formatMessage(messages.getImage);
    };
}

import icUnderline from 'icons/toolbar/controls/ic-font-underline.svg';
import React, { SyntheticEvent, useContext } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import type { Editor } from '@tiptap/react';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { StateObserver } from '../../common/sharedState.class';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';

const getFocusedBlock = (editor: Editor) => {
    return editor.isActive('underline');
};

const getChangedBlock = (editor: Editor) => {
    return editor.chain().focus().toggleUnderline().run();
};

export const UnderlineText = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);

    const { setCurrentState, value } = useSharedState<boolean, SyntheticEvent<HTMLElement, Event>>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <ToolbarButton
            icon={icUnderline}
            dataTest="wiki-toolbar-group_font-inline-style_UNDERLINE"
            tooltip={intl.formatMessage(messages.textUnderline)}
            onClick={setCurrentState}
            size="small"
            isActive={value}
        />
    );
};

import React from 'react';
import { Input } from 'antd';
import theme from './styles/AssistantInterfacePanel.scss';
import closeIconBlack from '../../resources/icons/closeIcon.svg';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Icon } from '@/modules/UIKit';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchResult, setAipSearchData } from './actions/assistantInterface.actions';
import classNames from 'classnames';
import { InternationalString, NodeId } from '@/serverapi/api';
import messages from './/messages/AssistantInterfacePanel.messages';
import { AssistantInterfaceSearchSelectors } from './selectors/assistantInterfaceSearch.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode } from '@/models/tree.types';
import { useIntl } from 'react-intl';
import { Spinner } from '@/modules/Spinner/Spinner.component';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { moveToDirectAction } from '@/actions/editor.actions';
import { LocalesService } from '@/services/LocalesService';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { TSearchDataListItem } from './reducers/searchAssistantInterface.reducer.types';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';

type TAssistantInterfacePanel = {
    setIsVisibleAipPannel: (value: boolean) => void;
};

// у корневых элементов значение поля id совпадает со значеним поля repositoryId
const buildRootNodeId = (someNodeId: NodeId | undefined): NodeId | undefined => {
    return someNodeId ? {...someNodeId, id: someNodeId.repositoryId} : undefined;
}

export const AssistantInterfacePanel = ({ setIsVisibleAipPannel }: TAssistantInterfacePanel) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);

    // nodeId элемента в активной вкладке
    const activeTabNodeId: NodeId | undefined = activeTab?.nodeId;

    const selectedTreeNode: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode());
    // nodeId элемента, выбранного в дереве
    const selectedTreeItemNodeId: NodeId | undefined = selectedTreeNode ? selectedTreeNode.nodeId : undefined;

    // nodeId корневого узла активной вкладки
    const firstNodeId = buildRootNodeId(activeTabNodeId);
    // nodeId корневого узла выбранного в дереве элемента
    const secondNodeId = buildRootNodeId(selectedTreeItemNodeId);

    // корневые элементы
    const firstItem = useSelector(TreeSelectors.itemById(firstNodeId));
    const secondItem = useSelector(TreeSelectors.itemById(secondNodeId));

    // выбираем один из двух по порядку, в том случае если это база данных
    const nodeId: NodeId | undefined = firstItem && firstItem.type === 'DB' ? firstItem.nodeId : (secondItem && secondItem.type === 'DB'? secondItem.nodeId : undefined);

    const [inputValue, setInputValue] = React.useState<string>('');
    const searchData: TSearchDataListItem[] = useSelector(AssistantInterfaceSearchSelectors.getSearchResult(nodeId?.id || ''));
    const isLoading: boolean = useSelector(AssistantInterfaceSearchSelectors.isLoading(nodeId?.id || ''));
    const isShowingHelp: boolean = useSelector(AssistantInterfaceSearchSelectors.isShowingHelp(nodeId?.id || ''));

    // если ни один из корневых элементов не является базой данных - не открываем помощник
    if (!nodeId) {
        dispatch(showNotificationByType(NotificationType.HOW_TO_OPEN_AIP));
        setIsVisibleAipPannel(false);
        return null;
    }

    const help = () => {
        dispatch(getSearchResult('?', [], 'ALL_INCLUDE_DELETED', [], nodeId));
    };

    const clearField = () => {
        setInputValue('');
        dispatch(setAipSearchData({ id: nodeId.id, searchResult: [], isLoading: false }))
    };

    const handleSearch = () => {
        dispatch(getSearchResult(inputValue, [], 'ALL_INCLUDE_DELETED', [], nodeId));
    };

    const handleItemClick = (nodeId: NodeId, name: InternationalString, type: TreeItemType) => {
        const treeNode: TreeNode = {
            hasChildren: false,
            nodeId,
            name: LocalesService.internationalStringToString(name),
            multilingualName: name,
            type,
            countChildren: 0,
        };
        // (переход в дереве), событие потом можно заменить
        dispatch(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSearch();
        }
    };

    const handleClosePanel = () => {
        setIsVisibleAipPannel(false);
    };


    const searchResultsBlock = (): JSX.Element => {
        return <>
            <div className={theme.header}>{intl.formatMessage(messages.searchResult)}</div>
            <Spinner loading={isLoading}>
            <div className={theme.text}>
                <ul className={theme.resultList}>
                    {searchData.map((item) => {
                        const itemName: string = LocalesService.internationalStringToString(item.multilingualName);
                        return (
                            <li
                                key={item.nodeId.id}
                                onClick={() =>
                                    handleItemClick(item.nodeId, item.multilingualName, item.type)
                                }
                                // изменение цвета выводимого текста
                                className={classNames(theme.resultItem, { [theme.error]: item.deleted })}
                                title={itemName}
                            >
                                {itemName}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Spinner>
        </>
    }

    const helpTextBlock = () => {
        return <div className={theme.helpText}>{intl.formatMessage(messages.howto)}</div>
    }

    const foundResultsTotal = () : JSX.Element => {
        return <div className={theme.resultsFooter}>
          {intl.formatMessage(messages.resultsCount)} {searchData.length} {intl.formatMessage(messages.results)}
        </div>
    }


    return (
        <div className={theme.aipPannel}>
            <div className={theme.resultBlock}>
                <div className={theme.close}>
                    <Icon onClick={handleClosePanel} spriteSymbol={closeIconBlack} />
                </div>
                {isShowingHelp ? helpTextBlock() : searchResultsBlock()}
            </div>
            {isShowingHelp ? '' : foundResultsTotal()}
            <div className={theme.searchBlock}>
                <Input.TextArea
                    onKeyDown={handleKeyDown}
                    autoFocus
                    placeholder={intl.formatMessage(messages.placeholder)}
                    className={theme.textArea}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    onChange={handleChange}
                    value={inputValue}
                    bordered={true}
                />
                <div className={theme.buttonsGroup}>
                    <Button visualStyle={"primary"} size="small" onClick={help}>
                        {intl.formatMessage(messages.help)}
                    </Button>
                    <Button visualStyle="primary" size="small" onClick={clearField}>
                        {intl.formatMessage(messages.clear)}
                    </Button>
                    <Button visualStyle="primary" size="small" onClick={handleSearch}>
                        {intl.formatMessage(messages.search)}
                    </Button>
                </div>
            </div>
        </div>
    );
};

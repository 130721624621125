import { put, select, takeEvery } from 'redux-saga/effects';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { v4 as uuid } from 'uuid';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { SPREADSHEET_DIALOG_SUBMIT_DATA } from '../actionsTypes/spreadsheetDialog.actionTypes';
import { spreadsheetDialogSubmitResult } from '../actions/spreadsheetDialog.actions';
import { TSpreadsheetDialogSubmitDataAction } from '../actions/spreadsheetDialog.actions.types';
import messages from '../modules/SpreadsheetDialog/SpreadsheetDialog.messages';
import { TWorkspaceTab } from '../models/tab.types';
import { workspaceAddTab } from '../actions/tabs.actions';
import { NodeId } from '../serverapi/api';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { ISpreadsheetNode } from '../models/bpm/bpm-model-impl.types';
import { EditorMode } from '../models/editorMode';
import { SpreadsheetDaoService } from '../services/dao/SpreadsheetDaoService';
import { treeItemChildAdd } from '../actions/tree.actions';
import { TreeNode } from '../models/tree.types';
import { spreadsheetUpdate } from '../actions/entities/spreadsheet.actions';
import { recentAddModel } from '../actions/recent.actions';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';

function* handleContextMenuAction({ payload }: TTreeItemContextMenuAction) {
    const { nodeId: parentNodeId, type, action } = payload;

    if (
        (type === TreeItemType.Folder || type === TreeItemType.Repository) &&
        action === TreeItemContextMenuAction.ADD_SPREADSHEET
    ) {
        yield put(openDialog(DialogType.SPREADSHEET_DIALOG, { parentNodeId, type: TreeItemType.Spreadsheet }));
    }
}

function* handleSpreadsheetDialogSubmitData({ payload }: TSpreadsheetDialogSubmitDataAction) {
    const {
        parentNodeId,
        spreadsheetName,
        parentNodeId: { repositoryId, serverId },
    } = payload;
    if (!parentNodeId) {
        yield put(spreadsheetDialogSubmitResult('error', messages.spreadsheetCreateError));

        return;
    }

    const nodeId: NodeId = {
        id: uuid(),
        repositoryId,
        serverId,
    };
    const requestBody = {
        body: {
            nodeId,
            parentNodeId,
            serverId,
            type: TreeItemType.Spreadsheet,
            name: spreadsheetName,
            cells: [],
        } as ISpreadsheetNode,
    };
    const spreadsheet: ISpreadsheetNode = yield SpreadsheetDaoService.saveSpreadsheet(serverId, requestBody);
    const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
        title: spreadsheet.name,
        type: WorkSpaceTabTypes.SPREADSHEET,
        nodeId,
        mode: EditorMode.Edit,
        content: spreadsheet,
    };
    const newTreeNode: TreeNode = {
        nodeId,
        name: spreadsheetName,
        parentNodeId,
        type: TreeItemType.Spreadsheet,
        hasChildren: false,
        countChildren: 0,
    };

    yield put(treeItemChildAdd({ parentNodeId, child: [newTreeNode] }));
    yield put(spreadsheetUpdate(spreadsheet));
    yield put(workspaceAddTab(workspaceTab));
    yield put(
        recentAddModel({
            nodeId,
            type: TreeItemType.Spreadsheet,
            parentId: spreadsheet.parentNodeId,
            createdAt: (new Date()).toISOString(),
            title: spreadsheet.name,
            modelTypeId: WorkSpaceTabTypes.SPREADSHEET,
            modelTypeName: LocalesService.useIntl(yield select(getCurrentLocale)).formatMessage(messages.spreadsheet),
            messageDescriptor: messages.spreadsheet,
        }),
    );
    yield put(closeDialog(DialogType.SPREADSHEET_DIALOG));
}

export function* spreadsheetDialogSagaInit() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleContextMenuAction);
    yield takeEvery(SPREADSHEET_DIALOG_SUBMIT_DATA, handleSpreadsheetDialogSubmitData);
}

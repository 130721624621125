import { CSSProperties } from 'react';
import { TReportTableData } from '../ReportEditor.types';
import { Column, Row } from '@tanstack/react-table';
import { CSS, Transform } from '@dnd-kit/utilities';
import theme from './ReportEditorTable.scss';

export const getTableCellStyles = (
    column: Column<TReportTableData>,
    transform: Transform | null,
    isDragging?: boolean,
): CSSProperties => {
    const isPinned = column.getIsPinned();

    if (isPinned) {
        return {
            borderRight: '1px solid var(--Neutral100)',
            borderLeft: '1px solid var(--Neutral100)',
            right: `${column.getAfter('right')}px`,
            opacity: 1,
            position: 'sticky',
            width: column.getSize(),
            zIndex: 5,
            borderRadius: 0,
        };
    }

    return {
        position: 'relative',
        width: column.getSize(),
        zIndex: isDragging ? 2 : 1,
        opacity: isDragging ? 1 : 0.8,
        transform: CSS.Translate.toString(transform),
        transition: 'width transform 0.2s ease-in-out',
    };
};

export const getRowClassName = (row: Row<TReportTableData>): string => {
    const isSelected: boolean = row.getIsSelected();

    return isSelected ? theme.selected : '';
};

export const isElementInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right + 280 <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

import { Form, Input, Typography } from 'antd';
import React, { useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import { MailServerStatus, SystemPropertiesMailPasswordStatusEnum } from 'src/serverapi/api';
import { CheckStatus, SystemPropertiesFormItemNames, TPropsWithFormRef } from '../../SystemProperties.types';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { FormItemInputWithLabel } from '../FormItemInputWithLabelComponent/FormItemInputWithLabel.component';
import { SystemPropertiesDaoService } from '../../../../../services/dao/SystemPropertiesDaoService';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

export const MailSettings = (props: TPropsWithFormRef) => {
    const intl = useIntl();
    const [mailServerStatus, setMailServerStatus] = useState<CheckStatus | undefined>(undefined);
    const [mailServerStatusErrorText, setMailServerStatusErrorText] = useState<string | undefined>(undefined);

    const { formRef } = props;

    const mailProtocol = useSelector(SystemPropertiesSelectors.getMailProtocol);
    const mailHost = useSelector(SystemPropertiesSelectors.getMailHost);
    const mailPort = useSelector(SystemPropertiesSelectors.getMailPort);
    const mailUser = useSelector(SystemPropertiesSelectors.getMailUser);
    const mailPassword = useSelector(SystemPropertiesSelectors.getMailPassword);
    const mailFrom = useSelector(SystemPropertiesSelectors.getMailFrom);
    const mailSsl = useSelector(SystemPropertiesSelectors.getMailSsl);
    const mailSslProtocols = useSelector(SystemPropertiesSelectors.getMailSslProtocol);
    const mailPasswordStatus = useSelector(SystemPropertiesSelectors.getMailPasswordStatus);

    const { Text } = Typography;

    const getPasswordStatusMessage = (status: SystemPropertiesMailPasswordStatusEnum | undefined): string => {
        let descriptor: MessageDescriptor = messages.mailPasswdNone;
        if (status === 'SAVE') {
            descriptor = messages.mailPasswdSave;
        } else if (status === 'SAFE') {
            descriptor = messages.mailPasswdSafe;
        }

        return intl.formatMessage(descriptor);
    };

    const handleCheckMailService = () => {
        const form = formRef.current;

        setMailServerStatus(CheckStatus.validating);
        try {
            SystemPropertiesDaoService.checkMail(form?.getFieldsValue()).then((result: MailServerStatus) => {
                if (result.mailStatus === 'SUCCESS') {
                    setMailServerStatus(CheckStatus.success);
                } else {
                    setMailServerStatus(CheckStatus.error);
                    setMailServerStatusErrorText(result.error);
                }
            });
        } catch (error) {
            setMailServerStatus(CheckStatus.error);
        }
    };

    return (
        <div className={theme.formTab}>
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.mailProtocol}
                initialValue={mailProtocol}
                label={intl.formatMessage(messages.mailProtocol)}
            />
            <Form.Item className={theme.formItem} label={intl.formatMessage(messages.mailHost)}>
                <Form.Item name={SystemPropertiesFormItemNames.mailHost} initialValue={mailHost} noStyle>
                    <Input />
                </Form.Item>
                {mailServerStatus === CheckStatus.success && (
                    <Text style={{ color: 'green' }} strong>
                        {intl.formatMessage(messages.works)}
                    </Text>
                )}
                {mailServerStatus === CheckStatus.error && mailServerStatusErrorText && (
                    <Text type="danger">{mailServerStatusErrorText}</Text>
                )}
                {mailServerStatus === CheckStatus.error && !mailServerStatusErrorText && (
                    <Text type="danger">{intl.formatMessage(messages.notWork)}</Text>
                )}
                {mailServerStatus === CheckStatus.validating && (
                    <Text type="secondary">{`${intl.formatMessage(messages.check)}...`}</Text>
                )}
            </Form.Item>
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.mailPort}
                initialValue={mailPort}
                label={intl.formatMessage(messages.mailPort)}
            />

            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.mailUser}
                initialValue={mailUser}
                label={intl.formatMessage(messages.mailUser)}
            />
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.mailPassword}
                initialValue={mailPassword}
                label={intl.formatMessage(messages.mailPassword)}
                help={getPasswordStatusMessage(mailPasswordStatus)}
                autoComplete="new-password"
            />
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.mailFrom}
                initialValue={mailFrom}
                label={intl.formatMessage(messages.mailFrom)}
            />
            <Form.Item
                className={theme.formItem}
                valuePropName="checked"
                name={SystemPropertiesFormItemNames.mailSsl}
                initialValue={mailSsl}
                label={intl.formatMessage(messages.mailSsl)}
            >
                <Checkbox />
            </Form.Item>
            <Form.Item
                name={SystemPropertiesFormItemNames.mailSslProtocols}
                className={theme.formItem}
                initialValue={mailSslProtocols}
                label={intl.formatMessage(messages.mailSslProtocols)}
            >
                <Input />
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 16, offset: 8 },
                }}
            >
                <Button dataTest="mail_check-server-btn" onClick={handleCheckMailService}>{intl.formatMessage(messages.mailCheckServer)}</Button>
            </Form.Item>
        </div>
    );
};

import {
    AutoSizer,
    Column,
    Table,
    TableCellProps,
    ColumnProps,
    CellMeasurerCache,
    CellMeasurer,
} from 'react-virtualized';
import { omit } from 'lodash-es';
import React, { useState, FC } from 'react';
import { useIntl } from 'react-intl';
import { TSpriteSymbol } from '../../../../../../models/spriteSymbol.types';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import { DeleteButton, EditButton, RowButtons } from '../../../../Button/RowButtons';
import messages from '../../../messages/Presets.messages';
import theme from './GroupedTypesTable.scss';
import icFolder from '../../../../../../resources/icons/group.svg';
import icArrowRight from '../../../../../../resources/icons/ic-ribbon-arrow-right.svg';
import cx from 'classnames';
import { KanbanBoardType } from '../../../../../../serverapi/api';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import {
    TCheckBoxRowProps,
    TChild,
    TCommonType,
    TGroup,
    TRowData,
    TTableDataType,
    TTableRowRecord,
} from './GroupedTypesTable.types';
import { LocalesService } from '../../../../../../services/LocalesService';
import { isKanbanBoardType } from './KanbanCardEditor.utils';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { createMessageForDelete } from './metodologyElementsDeleteMessages.utils';
import { Alert } from 'antd';
import { TCheckboxStatus } from '@/modules/UIKit/components/Checkbox/Checkbox.types';

type TDefineMessages = {
    id: string;
    defaultMessage: string;
};

type TTableRowRecordComponentProps = {
    types: TChild[];
    selectedTypes?: TChild[];
    typeGroups: TCommonType[];
    selectedTypeGroups?: TCommonType[];
    searchFilter?: string;
    actionsDisabled: boolean;
    icon?: TSpriteSymbol;
    hideGUIDColumn?: boolean;
    columns?: ColumnProps[];
    deleteElMessage?: TDefineMessages;
    deleteGroupMessage?: TDefineMessages;
    titleDeleteMessage?: TDefineMessages;
    isTableDisabled?: boolean;
    disableHeader?: boolean;
    disableCheckBoxColumn?: boolean;
    rowHeight?: number;
    groupRowHeight?: number;
    minTableWidth?: number;
    rowClassName?: string;
    containerClassName?: string;
    onSelectType?: (checkedTypes: TCommonType[]) => void;
    onSelectGroup?: (group: TCommonType[]) => void;
    onDeleteType?: (row: TCommonType) => void;
    onDeleteKanbanType?: (row: KanbanBoardType) => void;
    onDeleteGroup?: (row: TCommonType) => void;
    onEditType?: (row) => void;
    onEditKanbanType?: (row) => void;
    onEditGroup?: (row: TCommonType) => void;
};

const ROW_HEIGHT = 47;
const MIN_TABLE_WIDTH = 656;

export const GroupedTypesTable: FC<TTableRowRecordComponentProps> = (props) => {
    const intl = useIntl();
    const {
        types,
        selectedTypes = [],
        typeGroups,
        selectedTypeGroups = [],
        deleteElMessage,
        deleteGroupMessage,
        titleDeleteMessage,
        searchFilter,
        isTableDisabled,
        actionsDisabled,
        columns = [],
        hideGUIDColumn,
        icon,
        disableHeader,
        rowHeight = ROW_HEIGHT,
        groupRowHeight = rowHeight,
        minTableWidth = MIN_TABLE_WIDTH,
        rowClassName,
        containerClassName,
        disableCheckBoxColumn,
        onDeleteGroup,
        onDeleteType,
        onEditGroup,
        onEditType,
        onSelectType,
        onSelectGroup,
        onDeleteKanbanType,
        onEditKanbanType,
    } = props;

    const [expandedIds, setExpandedIds] = useState<string[]>([]);
    const currentLocale = useSelector(getCurrentLocale);

    const selectedIds = selectedTypes.map((type) => type.id);
    const selectedGroupsIds = selectedTypeGroups.map((typesGroup) => typesGroup.id);

    const setSelectedTypes = (newIds: string[]) => {
        if (onSelectType) {
            const selectedTypes = types.filter((type) => newIds.includes(type.id));
            onSelectType(selectedTypes);
        }
    };

    const setSelectedGroups = (newIds: string[]) => {
        if (onSelectGroup) {
            const selectedGroups = typeGroups.filter((group) => newIds.includes(group.id));
            onSelectGroup(selectedGroups);
        }
    };

    const searchElements = (type: TTableDataType): boolean => {
        const name = LocalesService.internationalStringToString(type.multilingualName, currentLocale);
        const description = LocalesService.internationalStringToString(type.multilingualDescription, currentLocale);
        const searchText = searchFilter?.toLowerCase().trim() || '';
        const searchFields = [name, description, type.id];
        if (!type.isGroup && type.groupId) searchFields.push(type.groupId);
        const found = searchFields.some((field) => field?.toLowerCase().trim().includes(searchText));

        return found;
    };

    const allData: TTableDataType[] = [];
    const typesByGroupId: { [id: string]: TTableDataType[] } = {};
    typeGroups.forEach((typeGroup) => {
        // типы определенной группы отфильтрванные поиском
        const groupTypes = types.filter((type) => type.groupId === typeGroup.id && searchElements(type));
        typesByGroupId[typeGroup.id] = groupTypes;

        if (searchFilter?.length) {
            // если что-то ввели в поиск и есть совпадения с элементами в группе, добавляем группу в список таблицы
            if (groupTypes.length) {
                allData.push({ ...typeGroup, isGroup: true, children: groupTypes });
            } else {
                // если что-то ввели в поиск, но совпадений с элементами группы нет, проверяем группу на совпадение с поиском
                if (searchElements(typeGroup)) allData.push({ ...typeGroup, isGroup: true, children: groupTypes });
            }
        } else {
            // если поиск пустой, просто добавляем группу
            allData.push({ ...typeGroup, isGroup: true, children: groupTypes });
        }
        if (expandedIds.includes(typeGroup.id)) {
            allData.push(...groupTypes);
        }
    });

    // buttons for columns start
    const editButton = (rowData: TTableRowRecord<TTableDataType>) => (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (rowData.isGroup) {
            onEditGroup && onEditGroup(rowData as TGroup);
        } else {
            if (isKanbanBoardType(rowData)) {
                const kanbanModelType: KanbanBoardType = omit(rowData, ['name']) as unknown as KanbanBoardType;
                onEditKanbanType && onEditKanbanType(kanbanModelType);
            } else {
                onEditType && onEditType(rowData);
            }
        }
    };

    const deleteButton = (rowData: TTableRowRecord<TTableDataType>) => () => {
        if (rowData.isGroup) {
            onDeleteGroup && onDeleteGroup(rowData as TGroup);
        } else {
            if (isKanbanBoardType(rowData)) {
                const kanbanModelType: KanbanBoardType = omit(rowData, ['name']) as unknown as KanbanBoardType;
                onDeleteKanbanType && onDeleteKanbanType(kanbanModelType);
            } else {
                onDeleteType && onDeleteType(rowData);
            }
        }
    };

    const renderRowButtons = ({ rowData }: TableCellProps) => {
        const { children, isGroup } = rowData;
        const elementDeleteMessage =
            deleteElMessage &&
            createMessageForDelete(intl.formatMessage(deleteElMessage), children ? children : [rowData]);
        const groupDeleteMessage =
            isGroup && deleteGroupMessage && createMessageForDelete(intl.formatMessage(deleteGroupMessage), [rowData]);
        const deleteContent =
            groupDeleteMessage || elementDeleteMessage ? (
                <Alert
                    message={
                        <>
                            {groupDeleteMessage}
                            {elementDeleteMessage}
                        </>
                    }
                    type="warning"
                />
            ) : (
                ''
            );

        return (
            <RowButtons
                disabled={actionsDisabled}
                buttons={[
                    (onEditType && onEditGroup && EditButton.build(editButton(rowData), actionsDisabled)) || undefined!,
                    (onDeleteType &&
                        onDeleteGroup &&
                        DeleteButton.build(
                            deleteButton(rowData),
                            actionsDisabled,
                            undefined,
                            undefined,
                            intl.formatMessage(titleDeleteMessage || messages.deleteDefaultMessage),
                            deleteContent,
                        )) ||
                        undefined!,
                ].filter((btn) => !!btn)}
            />
        );
    };

    const renderNameColumn = (props: TableCellProps): React.ReactNode => {
        const { rowData, columnIndex, dataKey, parent, rowIndex } = props;
        const { multilingualName, isGroup, id } = rowData;
        const isExpanded = expandedIds.includes(id);
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={columnIndex}
                key={`${dataKey}_${rowIndex}`}
                parent={parent}
                rowIndex={rowIndex}
                index={rowIndex}
            >
                {() => (
                    <div className={isGroup ? theme.contentName : theme.contentChild}>
                        {!isGroup ? (
                            icon && <Icon className={theme.notExpandableIcon} spriteSymbol={icon} />
                        ) : (
                            <div className={theme.expandIcon}>
                                <Icon
                                    spriteSymbol={icArrowRight}
                                    className={cx(theme.togglerIcon, isExpanded && theme.togglerIcon_expanded)}
                                    dataTest={
                                        isExpanded
                                            ? 'grouped-types-table_row-expanded'
                                            : 'grouped-types-table_row-collapsed'
                                    }
                                />
                                <Icon className={theme.groupIcon} spriteSymbol={icFolder} />
                            </div>
                        )}
                        <div className={isTableDisabled ? theme.rowDisabled : undefined}>
                            {LocalesService.internationalStringToString(multilingualName, currentLocale)}
                        </div>
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const renderGuidColumn = ({ rowData: { id } }: TableCellProps) => (
        <div className={isTableDisabled ? theme.rowDisabled : undefined}>{id}</div>
    );

    const onRow = ({ index, rowData }: TRowData) => {
        if (rowData.isGroup) {
            checkId(rowData.id, expandedIds, setExpandedIds);
        } else {
            checkBoxHandler(rowData);
        }
    };

    // добавляем id в выбранные или убираем из выбранных, если он уже был выбран
    const checkId = (newId: string, selectedIds: string[], setter: (value: React.SetStateAction<string[]>) => void) => {
        const isChecked = selectedIds.some((id) => newId === id);
        if (isChecked) {
            setter(selectedIds.filter((id) => id !== newId));
        } else {
            setter([...selectedIds, newId]);
        }

        return !isChecked;
    };

    const checkBoxHandler = (rowData: TTableDataType) => {
        if (rowData.isGroup && rowData.children.length === 0) return;

        if (rowData.isGroup) {
            const isGroupSelected = checkId(rowData.id, selectedGroupsIds, setSelectedGroups);
            // если выбрали группу, также выбираем всех членов группы
            if (isGroupSelected) {
                const unselectedIds = rowData.children.reduce((res, type) => {
                    if (!selectedIds.includes(type.id)) return [...res, type.id];

                    return res;
                }, []);
                setSelectedTypes([...selectedIds, ...unselectedIds]);
            } else {
                // если сняли выделение с группы, снимаем выделение со всех ее членов
                const newSelectedIds = selectedIds.filter(
                    (selectedId) => !rowData.children.some((type) => type.id === selectedId),
                );

                setSelectedTypes(newSelectedIds);
            }
        } else {
            // иначе выбираем или снимаем выбор с элеменета
            const isElementSelected = checkId(rowData.id, selectedIds, setSelectedTypes);
            const isElemGroupSelected = !!rowData?.groupId && selectedGroupsIds.includes(rowData.groupId);

            // если сняли выделение с элемента, но его группа была ранее выделена, снимаем выделение с группы
            if (!isElementSelected && isElemGroupSelected) {
                setSelectedGroups(selectedGroupsIds.filter((id) => id !== rowData.groupId));
            }
        }
    };

    const getRowCheckBoxStatus = (rowData: TTableDataType | TGroup): TCheckboxStatus => {
        if (!rowData.isGroup) {
            return selectedGroupsIds.includes(rowData.id) || selectedIds.includes(rowData.id);
        }

        const { children } = rowData;

        if (children.length === 0) return false;

        const selectedChilds = children.filter((child) => selectedIds.includes(child.id));

        if (selectedChilds.length === children.length) return true;
        if (selectedChilds.length > 0 && selectedChilds.length < children.length) return 'indeterminate';

        return false;
    };

    const checkBoxColumnRenderer = ({ rowData }: TCheckBoxRowProps) => {
        const status:TCheckboxStatus = getRowCheckBoxStatus(rowData);

        return (
            <Checkbox
                status={status}
                onChange={() => checkBoxHandler(rowData)}
                onClick={(e) => e.stopPropagation()}
            />
        );
    };
    const isAllSelected = types.length === selectedIds.length;
    const isAnySelected = selectedIds.length > 0;

    const headerCheckBoxHandler = () => {
        //если все элементы были выделены, снимаем выделение с них
        if (isAllSelected) {
            setSelectedTypes([]);
            setSelectedGroups([]);
        } else {
            // иначе выделяем все группы и элементы
            setSelectedGroups(typeGroups.map((group) => group.id));
            const allTypesIds = Object.values(typesByGroupId)
                .flat()
                .map((type) => type.id);
            setSelectedTypes(allTypesIds);
        }
    };

    const headerCheckBoxRenderer = () => {
        const checkStatus: TCheckboxStatus = isAllSelected === true;

        return (
            <Checkbox
                status={isAnySelected && !isAllSelected ? 'indeterminate' : checkStatus}
                onChange={headerCheckBoxHandler}
                disabled={allData.length === 0}
            />
        );
    };
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: Math.min(rowHeight, groupRowHeight),
    });

    const rowGetter = ({ index }) => allData[index];

    return (
        <div className={containerClassName || theme.tableContainer}>
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        width={Math.max(width, minTableWidth)}
                        height={height}
                        overscanRowCount={0}
                        rowHeight={({ index }) => {
                            const isGroup = allData[index]?.isGroup;
                            const calculatedHeight = Math.max(
                                isGroup ? groupRowHeight : rowHeight,
                                cache.rowHeight({ index }),
                            );
                            return calculatedHeight;
                        }}
                        headerHeight={rowHeight}
                        rowCount={allData.length}
                        rowGetter={rowGetter}
                        onRowClick={onRow}
                        rowClassName={({ index }) => {
                            const currentRow = allData[index];
                            const isGroup = currentRow?.isGroup;

                            const isSelected =
                                selectedIds.includes(currentRow?.id) || selectedGroupsIds.includes(currentRow?.id);

                            return cx(rowClassName || theme.tableRow, theme.hover, {
                                groupElem: isGroup,
                                rowElem: !isGroup,
                                [theme.selected]: isSelected,
                            });
                        }}
                        headerClassName={theme.tableHeader}
                        disableHeader={disableHeader}
                    >
                        {!disableCheckBoxColumn && (
                            <Column
                                maxWidth={40}
                                minWidth={40}
                                width={40}
                                dataKey="check"
                                key="check"
                                headerRenderer={headerCheckBoxRenderer}
                                cellRenderer={checkBoxColumnRenderer}
                                className={theme.tableCheckBoxColumn}
                                headerClassName={theme.tableCheckBoxHeaderColumn}
                            />
                        )}
                        <Column
                            width={width}
                            dataKey="name"
                            key="name"
                            label={intl.formatMessage(messages.name)}
                            cellRenderer={renderNameColumn}
                            headerClassName={theme.tableHeaderColumn}
                            className={theme.tableColumn}
                            minWidth={170}
                        />
                        {!hideGUIDColumn && (
                            <Column
                                width={width}
                                dataKey="ID"
                                key="ID"
                                label={intl.formatMessage(messages.id)}
                                cellRenderer={renderGuidColumn}
                                headerClassName={theme.tableHeaderColumn}
                                className={theme.tableColumn}
                                minWidth={170}
                            />
                        )}
                        {columns.map((columnProps) => {
                            return (
                                <Column
                                    {...columnProps}
                                    key={columnProps.dataKey}
                                    dataKey={columnProps.dataKey}
                                    width={width}
                                    headerClassName={columnProps.headerClassName || theme.tableHeaderColumn}
                                    className={theme.tableColumn}
                                    cellRenderer={(props) => {
                                        const { columnIndex, dataKey, parent, rowIndex } = props;
                                        const renderResult = columnProps.cellRenderer
                                            ? columnProps.cellRenderer(props)
                                            : props.cellData;

                                        return (
                                            <CellMeasurer
                                                cache={cache}
                                                columnIndex={columnIndex}
                                                key={`${dataKey}_${rowIndex}`}
                                                parent={parent}
                                                rowIndex={rowIndex}
                                                index={rowIndex}
                                            >
                                                <div style={{ display: 'flex' }}> {renderResult}</div>
                                            </CellMeasurer>
                                        );
                                    }}
                                />
                            );
                        })}
                        {!actionsDisabled && (
                            <Column
                                width={75}
                                maxWidth={75}
                                minWidth={75}
                                dataKey="actionButtons"
                                key="actionButtons"
                                label=""
                                cellRenderer={renderRowButtons}
                                className={cx(theme.tableColumn, theme.actionButtonsColumn)}
                            />
                        )}
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
};

import { useAutoSave } from '@/hooks/useAutoSave';
import { EditorMode } from '@/models/editorMode';
import { TWorkspaceTab } from '@/models/tab.types';
import { Icon } from '@/modules/UIKit';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import addIcon from '@/resources/icons/Add_Other.svg';
import noData from '@/resources/icons/noData.svg';
import icOpen from '@/resources/icons/ic-open.svg';
import { NodeId, ReportColumnData, ReportData, ReportDataFillingTypeEnum, ReportNode } from '@/serverapi/api';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchNodesWithAttributes,
    reportChangeColumnOrder,
    reportCreateNewColumn,
    reportDropNodes,
    reportOpenElement,
    reportSaveRequest,
    reportSearchRequest,
    reportSelectColumn,
    reportSetSearchValue,
} from '../actions/report.actions';
import { ReportEditorSidePanel } from './ReportEditorSidebar/ReportEditorSidePanel.component';
import { ReportSelectors } from '../selectors/report.selectors';
import theme from './ReportEditor.scss';
import messages from './ReportEditor.messages';
import { resetSearchData } from '@/actions/search.actions';
import { Spinner } from '@/modules/Spinner/Spinner.component';
import { ReportEditorFilterBar } from './ReportEditorFilterBar/ReportEditorFilterBar.component';
import { isEqual } from 'lodash-es';
import { LOCK_TIMEOUT } from '@/utils/consts';
import { relock } from '@/actions/lock.actions';
import { useRelock } from '@/hooks/useRelock';
import { TReportTableData } from './ReportEditor.types';
import { ColumnDef, createColumnHelper, RowData } from '@tanstack/react-table';
import { ReportEditorTable } from './ReportTable/ReportEditorTable.component';
import { EllipsisText } from './EllipsisText/EllipsisText.component';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { ADD_COLUMN_ID, CHECKBOX_COLUMN_ID, OPEN_ELEMENT_COLUMN_ID } from './ReportTable/ReportEditorTable.const';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { CommentsSelectors } from '@/selectors/comments.selectors';
import { CommentsPanelContainer } from '@/modules/Comments/containers/CommentsPanel.container';

declare module '@tanstack/react-table' {
    interface HeaderContext<TData extends RowData, TValue> {
        listeners?: SyntheticListenerMap;
        attributes?: DraggableAttributes;
    }
}
import { ReportEditorToolbar } from './ReportEditorToolbar/ReportEditorToolbar.component';

type TReportEditorProps = {
    tab: TWorkspaceTab;
};

const columnHelper = createColumnHelper<TReportTableData>();

export const ReportEditor: FC<TReportEditorProps> = ({ tab: { nodeId, mode } }) => {
    const isTabActive: boolean = useSelector(TabsSelectors.isTabActive(nodeId));
    const report: ReportNode | undefined = useSelector(ReportSelectors.byId(nodeId));
    const reportData: ReportData | undefined = report?.reportData;
    const isReadMode: boolean = mode === EditorMode.Read;
    const reportColumns: ReportColumnData[] =
        reportData?.columns.sort((col1, col2) => col1.orderNumber - col2.orderNumber) || [];
    const manuallyFilledNodes: NodeId[] = reportData?.manuallyFilledNodes || [];
    const selectedColumnId: string | undefined = useSelector(ReportSelectors.selectedColumnId(nodeId));
    const loading: boolean = useSelector(ReportSelectors.isLoading(nodeId));
    const fillingType: ReportDataFillingTypeEnum = useSelector(ReportSelectors.fillingType(nodeId));
    const searchValue: string = useSelector(ReportSelectors.searchValue(nodeId));
    const commentsEnabledSchemesIds: NodeId[] = useSelector(CommentsSelectors.commentsEnabledSchemesIds);
    const isShowCommentsPanel: boolean = commentsEnabledSchemesIds.some((id) => compareNodeIds(id, nodeId));

    const [rowSelection, setRowSelection] = useState<{ [id: string]: boolean }>({});
    const [columnOrder, setColumnOrder] = useState<string[]>([]);

    const tableData: TReportTableData[] = useSelector(ReportSelectors.getReportTableData(nodeId), isEqual);

    const filteredTableData: TReportTableData[] = useMemo(
        () => tableData.filter((data) => (searchValue === '' ? !data.filtered : !data.filtered && data.found)),
        [tableData, searchValue],
    );

    const dispatch = useDispatch();
    const intl = useIntl();

    const saveReportHandler = () => {
        if (report) dispatch(reportSaveRequest(report));
    };

    useAutoSave({ isEditMode: !isReadMode, nodeId, dependences: [report], callback: saveReportHandler });
    useRelock(!isReadMode, nodeId, 'REPORT');

    useEffect(() => {
        const columnOrder: string[] = reportColumns.map((c) => c.columnId);

        setColumnOrder((prevColOrder) => {
            if (isEqual(columnOrder, prevColOrder)) return prevColOrder;
            return columnOrder;
        });
    }, [reportColumns]);

    useEffect(() => {
        if (reportData?.fillingType === 'MANUAL') {
            dispatch(fetchNodesWithAttributes(manuallyFilledNodes, true));
        }

        return () => {
            dispatch(resetSearchData(nodeId));
        };
    }, []);

    useEffect(() => {
        const interval: number = window.setInterval(
            () => !isReadMode && dispatch(relock(nodeId, 'REPORT')),
            LOCK_TIMEOUT,
        );

        return () => {
            clearInterval(interval);
        };
    }, [isReadMode]);

    useEffect(() => {
        if (reportData?.searchRequests && reportData.fillingType === 'AUTO') {
            dispatch(reportSearchRequest(nodeId, reportData.searchRequests));
        }
        setRowSelection({});
    }, [reportData?.searchRequests, reportData?.fillingType]);

    useEffect(() => {
        if (isReadMode) {
            setRowSelection({});
        }
    }, [isReadMode]);

    const handleAddColumn = () => {
        dispatch(reportCreateNewColumn(nodeId));
    };

    const handleSetSearchValue = (searchValue: string) => {
        dispatch(reportSetSearchValue(nodeId, searchValue));
    };

    const columns: ColumnDef<TReportTableData, string>[] = useMemo(() => {
        const tableColumns: ColumnDef<TReportTableData, string>[] = reportColumns
            .filter((reportColumn: ReportColumnData) => !isReadMode || reportColumn.visibilityEnabled)
            .map((reportColumn: ReportColumnData) => {
                const dataKey: string = `${reportColumn.attributeType}_${reportColumn.attributeTypeId}`;

                return columnHelper.accessor(`attributes.${dataKey}`, {
                    header: ({ listeners, attributes }) => (
                        <EllipsisText
                            text={reportColumn.columnName || ''}
                            isHeader={true}
                            maxLinesCount={3}
                            dndListeners={listeners}
                            dndAttributes={attributes}
                        />
                    ),

                    id: reportColumn.columnId,
                    meta: {
                        selected: reportColumn.columnId === selectedColumnId,
                    },
                    cell: (props) => <EllipsisText text={props.getValue()} />,
                });
            });

        if (!isReadMode) {
            // колонка с кнопкой добавления новой колонки
            tableColumns.push(
                columnHelper.display({
                    id: ADD_COLUMN_ID,
                    header: () => (
                        <div className={theme.addButtonContainer}>
                            <Button icon={addIcon} visualStyle="text" onClick={handleAddColumn} />
                        </div>
                    ),
                    cell: ({ row }) => (
                        <div className={theme.actionsContainer}>
                            <Button
                                visualStyle={{ type: 'text' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(reportOpenElement(row.original.nodeId, nodeId));
                                }}
                                icon={icOpen}
                                size="small"
                            />
                        </div>
                    ),
                    size: 60,
                    minSize: 60,
                    maxSize: 60,
                    enablePinning: true,
                    enableResizing: false,
                }),
            );

            // колонка с чекбоксом
            tableColumns.unshift(
                columnHelper.display({
                    id: CHECKBOX_COLUMN_ID,
                    header: ({ table }) => (
                        <div className={theme.checkboxContainer} onClick={table.getToggleAllRowsSelectedHandler()}>
                            <Checkbox
                                status={
                                    table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                        ? 'indeterminate'
                                        : table.getIsAllRowsSelected()
                                }
                                allowIndeterminate
                            />
                        </div>
                    ),
                    cell: ({ row }) => (
                        <div className={theme.checkboxContainer} onClick={row.getToggleSelectedHandler()}>
                            <Checkbox status={row.getIsSelected()} />
                        </div>
                    ),
                    size: 40,
                    minSize: 40,
                    maxSize: 40,
                    enablePinning: true,
                    enableResizing: false,
                }),
            );
        } else {
            tableColumns.push(
                columnHelper.display({
                    id: OPEN_ELEMENT_COLUMN_ID,
                    cell: ({ row }) => (
                        <div className={theme.actionsContainer}>
                            <Button
                                visualStyle={{ type: 'text' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(reportOpenElement(row.original.nodeId, nodeId));
                                }}
                                icon={icOpen}
                                size="small"
                            />
                        </div>
                    ),
                    size: 60,
                    minSize: 60,
                    maxSize: 60,
                    enablePinning: true,
                    enableResizing: false,
                }),
            );
        }

        return tableColumns;
    }, [isReadMode, reportColumns, selectedColumnId]);

    const currentColumn: ReportColumnData | undefined = reportColumns.find(
        (column) => column.columnId === selectedColumnId,
    );

    const onDropHandler = (event: React.DragEvent) => {
        const droppedNodeId: NodeId = JSON.parse(event.dataTransfer.getData('nodeId'));
        dispatch(reportDropNodes(nodeId, droppedNodeId));
    };

    const onColumnClickHandler = useCallback(
        (columnId: string) => {
            if (isReadMode) return;

            if (columnId && columnId !== ADD_COLUMN_ID && columnId !== CHECKBOX_COLUMN_ID) {
                dispatch(reportSelectColumn(nodeId, columnId));
            }
        },
        [isReadMode],
    );

    const onColumnOrderChange = useCallback((columnOrder: string[]) => {
        dispatch(reportChangeColumnOrder(nodeId, columnOrder));
    }, []);

    const disableDelete: boolean = reportColumns.filter((col) => col.columnName).length < 2;

    const selectedNodeIds: NodeId[] = useMemo(
        () =>
            filteredTableData
                .filter((row, index) => typeof row.id === 'string' && Object.keys(rowSelection).includes(String(index)))
                .map((row) => row.nodeId as NodeId),
        [filteredTableData, rowSelection],
    );

    const showSidePanel: boolean = !isReadMode || isShowCommentsPanel;

    const showDeleteBtn: boolean = fillingType === 'MANUAL';

    return (
        <>
            <ReportEditorToolbar selectedNodeIds={selectedNodeIds} showDeleteBtn={showDeleteBtn} />
            <div className={theme.container}>
                <div className={showSidePanel ? theme.colContainerWithSidePanel : theme.colContainer}>
                    <ReportEditorFilterBar
                        reportNodeId={nodeId}
                        searchValue={searchValue}
                        reportColumns={reportColumns}
                        tableData={tableData}
                        setSearchValue={handleSetSearchValue}
                    />
                    <div
                        className={`${theme.rowContainer} ${filteredTableData.length === 0 ? theme.noData : ''} `}
                        onDrop={onDropHandler}
                        onDragOver={(event: React.DragEvent) => {
                            if (!isReadMode && fillingType !== 'AUTO') {
                                event.preventDefault();
                            }
                        }}
                    >
                        {columns.length !== 0 &&
                            filteredTableData.length !== 0 &&
                            //https://github.com/TanStack/table/issues/5537
                            isTabActive && (
                                <ReportEditorTable
                                    isReadMode={isReadMode}
                                    rowSelection={rowSelection}
                                    data={filteredTableData}
                                    columns={columns}
                                    columnOrder={columnOrder}
                                    setColumnOrder={setColumnOrder}
                                    setRowSelection={setRowSelection}
                                    onColumnClick={onColumnClickHandler}
                                    onColumnOrderChange={onColumnOrderChange}
                                />
                            )}
                        {filteredTableData.length === 0 && (
                            <div className={theme.noDataWrapper}>
                                <Spinner loading={loading}>
                                    <Icon spriteSymbol={noData} className={theme.noDataIcon} />
                                    <div>{intl.formatMessage(messages.noData)}</div>
                                </Spinner>
                            </div>
                        )}
                        {columns.length === 0 && filteredTableData.length !== 0 && (
                            <div className={theme.noDataWrapper}>
                                <Icon spriteSymbol={noData} className={theme.noDataIcon} />
                                <div>{intl.formatMessage(messages.allColumnsHidden)}</div>
                            </div>
                        )}
                    </div>
                </div>
                {!isReadMode && !isShowCommentsPanel && (
                    <ReportEditorSidePanel
                        disableDelete={disableDelete}
                        reportNodeId={nodeId}
                        currentColumn={currentColumn}
                    />
                )}
                {isShowCommentsPanel && <CommentsPanelContainer modelNodeId={nodeId} />}
            </div>
        </>
    );
};

import React, { FC, useEffect, useState } from 'react';
import {
    SchedulerTimeSettingsMonthsEnum,
    SchedulerTimeSettingsSchedulerPeriodEnum,
    SchedulerTimeSettingsWeekDaysEnum,
} from '@/serverapi/api';
import theme from './DatetimeSchedulerSettings.scss';
import { DatePicker, Select } from 'antd';
import { Icon } from '../UIKit';
import messages from './DatetimeSchedulerSettings.messages';
import icCalendar from '../../resources/icons/icCalendar.svg';
import icArrowDownDark from '../../resources/icons/icArrowDownDark.svg';
import { dateTimeFormat, momentDateToTimestamp, timestampToMomentDate } from '@/utils/date.time.utils';
import dayjs from 'dayjs';
import {
    SchedulerMonthsEnum,
    SchedulerPeriodEnum,
    SchedulerWeekDaysEnum,
    TDatetimeSchedulerSettingsProps,
} from './DatetimeSchedulerSettings.types';
import {
    months,
    repeatEverySecondsOptions,
    stopRepeatAfterSecondsOptions,
    weekDays,
    ALL,
    LAST,
} from './DatetimeSchedulerSettings.utils';
import { useIntl } from 'react-intl';
import { DailySchedulerPeriod } from './DailySchedulerPeriod.component';
import { WeeklySchedulerPeriod } from './WeeklySchedulerPeriod.component';
import { MonthlySchedulerPeriod } from './MonthlySchedulerPeriod.component';
import { DatePickerProvider } from '@/modules/UIKit/H.O.C/DatePickerProvider/DatePickerProvider.hoc';
import classnames from 'classnames';
import { TRadioOption } from '../UIKit/components/Radio/Radio.types';
import { RadioGroup } from '../UIKit/components/Radio/RadioGroup.component';
import { Checkbox } from '../UIKit/components/Checkbox/Checkbox.component';

export const DatetimeSchedulerSettings: FC<TDatetimeSchedulerSettingsProps> = (props) => {
    const { settings, setSettings } = props;
    const intl = useIntl();
    const [initialRepeatEverySeconds, setInitialRepeatEverySeconds] = useState<number | undefined>(undefined);
    const [initialStopRepeatAfterSeconds, setInitialStopRepeatAfterSeconds] = useState<number | undefined>(undefined);

    useEffect(() => {
        const isUniqueRepeatEverySeconds = repeatEverySecondsOptions.every(
            (option) => option.valueInSeconds !== settings.repeatEverySeconds,
        );
        const isUniquestopRepeatAfterSeconds = stopRepeatAfterSecondsOptions.every(
            (option) => option.valueInSeconds !== settings.stopRepeatAfterSeconds,
        );

        if (isUniqueRepeatEverySeconds) {
            setInitialRepeatEverySeconds(settings.repeatEverySeconds);
        }
        if (isUniquestopRepeatAfterSeconds) {
            setInitialStopRepeatAfterSeconds(settings.stopRepeatAfterSeconds);
        }
    }, []);

    const onSchedulerPeriodChange = (value: SchedulerPeriodEnum) => {
        const schedulerPeriod: SchedulerTimeSettingsSchedulerPeriodEnum = value;
        setSettings({ ...settings, schedulerPeriod });
    };

    const onStartTimeChange = (date: dayjs.Dayjs) => {
        const startTime = momentDateToTimestamp(date);
        if (startTime) setSettings({ ...settings, startTime });
    };

    const onEndTimeToggle = (checked: boolean) => {
        const endTime = checked ? momentDateToTimestamp(dayjs())! : undefined;
        setSettings({ ...settings, endTime });
    };

    const onEndTimeChange = (date: dayjs.Dayjs) => {
        const endTime = momentDateToTimestamp(date);
        if (endTime) setSettings({ ...settings, endTime });
    };

    const onRepeatEverySecondsChange = (repeatEverySeconds: number | null) => {
        setSettings({ ...settings, repeatEverySeconds: repeatEverySeconds || undefined });
    };

    const onStopRepeatAfterSecondsChange = (stopRepeatAfterSeconds: number | null) => {
        setSettings({ ...settings, stopRepeatAfterSeconds: stopRepeatAfterSeconds || undefined });
    };

    const onRepeatDailyChange = (repeatDaily: number) => {
        setSettings({ ...settings, repeatDaily });
    };

    const onRepeatWeeklyChange = (repeatWeekly: number) => {
        setSettings({ ...settings, repeatWeekly });
    };

    const onWeekDaysChange = (changedDays: (SchedulerWeekDaysEnum | typeof ALL)[] | undefined) => {
        if (changedDays?.includes(ALL)) {
            return changedDays?.length === settings.weekDays?.length
                ? setSettings({
                      ...settings,
                      weekDays: changedDays.filter((day) => day !== ALL) as
                          | SchedulerTimeSettingsWeekDaysEnum[]
                          | undefined,
                  })
                : setSettings({ ...settings, weekDays });
        }

        if (changedDays?.length === settings.weekDays?.length) return setSettings({ ...settings, weekDays: [] });

        setSettings({ ...settings, weekDays: changedDays as SchedulerTimeSettingsWeekDaysEnum[] | undefined });
    };

    const onMonthsChange = (changedMonths: (SchedulerMonthsEnum | typeof ALL)[] | undefined) => {
        if (changedMonths?.includes(ALL)) {
            return changedMonths?.length === settings.months?.length
                ? setSettings({
                      ...settings,
                      months: changedMonths.filter((month) => month !== ALL) as
                          | SchedulerTimeSettingsMonthsEnum[]
                          | undefined,
                  })
                : setSettings({ ...settings, months });
        }

        if (changedMonths?.length === settings.months?.length) return setSettings({ ...settings, months: [] });

        setSettings({ ...settings, months: changedMonths as SchedulerTimeSettingsMonthsEnum[] | undefined });
    };

    const onDaysChange = (changedDays: (string | typeof LAST)[] | undefined) => {
        setSettings({
            ...settings,
            days: changedDays?.filter((day) => day !== LAST).map((day) => +day),
            lastDay: changedDays?.includes(LAST),
        });
    };

    const onWeekNumbersChange = (changedWeekNumbers: (string | typeof LAST)[] | undefined) => {
        setSettings({
            ...settings,
            weekNumber: changedWeekNumbers?.filter((week) => week !== LAST).map((week) => +week),
            lastWeek: changedWeekNumbers?.includes(LAST),
        });
    };

    const repeatEverySeconds = initialRepeatEverySeconds
        ? [
              ...repeatEverySecondsOptions,
              {
                  timeUnitName: 'seconds',
                  timeUnitValue: initialRepeatEverySeconds,
                  valueInSeconds: initialRepeatEverySeconds,
              },
          ]
        : repeatEverySecondsOptions;

    const stopRepeatAfterSeconds = initialStopRepeatAfterSeconds
        ? [
              ...stopRepeatAfterSecondsOptions,
              {
                  timeUnitName: 'seconds',
                  timeUnitValue: initialStopRepeatAfterSeconds,
                  valueInSeconds: initialStopRepeatAfterSeconds,
              },
          ]
        : stopRepeatAfterSecondsOptions;

    const renderMainParamsSettings = (period: SchedulerTimeSettingsSchedulerPeriodEnum) => {
        let component;

        switch (period) {
            case SchedulerPeriodEnum.DAILY:
                component = (
                    <DailySchedulerPeriod
                        repeatDaily={settings.repeatDaily}
                        onRepeatDailyChange={onRepeatDailyChange}
                    />
                );
                break;
            case SchedulerPeriodEnum.WEEKLY:
                component = (
                    <WeeklySchedulerPeriod
                        repeatWeekly={settings.repeatWeekly}
                        weekDays={settings.weekDays}
                        onRepeatWeeklyChange={onRepeatWeeklyChange}
                        onWeekDaysChange={onWeekDaysChange}
                    />
                );
                break;
            case SchedulerPeriodEnum.MONTHLY:
                component = (
                    <MonthlySchedulerPeriod
                        weekDays={settings.weekDays}
                        onWeekDaysChange={onWeekDaysChange}
                        months={settings.months}
                        onMonthsChange={onMonthsChange}
                        days={settings.days}
                        lastDay={settings.lastDay}
                        onDaysChange={onDaysChange}
                        onWeekNumbersChange={onWeekNumbersChange}
                        lastWeek={settings.lastWeek}
                        weekNumber={settings.weekNumber}
                    />
                );
                break;
            default:
                component = null;
                break;
        }

        return component;
    };

    const hasSchedulerRepeatError =
        settings.repeatEverySeconds && settings.stopRepeatAfterSeconds
            ? settings.repeatEverySeconds > settings.stopRepeatAfterSeconds
            : false;

    const radioOptions: TRadioOption<SchedulerTimeSettingsSchedulerPeriodEnum>[] = [
        {
            title: intl.formatMessage(messages.singleCase),
            value: SchedulerPeriodEnum.SINGLE_CASE,
            dataTest: 'schedule-script-dialog_single-case-radio',
        },
        {
            title: intl.formatMessage(messages.daily),
            value: SchedulerPeriodEnum.DAILY,
            dataTest: 'schedule-script-dialog_daily-radio',
        },
        {
            title: intl.formatMessage(messages.weekly),
            value: SchedulerPeriodEnum.WEEKLY,
            dataTest: 'schedule-script-dialog_weekly-radio',
        },
        {
            title: intl.formatMessage(messages.monthly),
            value: SchedulerPeriodEnum.MONTHLY,
            dataTest: 'schedule-script-dialog_monthly-radio',
        },
    ];

    return (
        <div className={theme.container}>
            <div className={theme.title}>{intl.formatMessage(messages.params)}</div>
            <div className={theme.mainParams}>
                <RadioGroup<SchedulerTimeSettingsSchedulerPeriodEnum>
                    onChange={onSchedulerPeriodChange}
                    value={settings.schedulerPeriod}
                    options={radioOptions}
                    margin={8}
                />
                <div className={theme.mainParamsSettings}>
                    <div className={theme.periodSettings}>
                        <div className={theme.periodSettingsTitle}>{intl.formatMessage(messages.start)}</div>
                        <DatePickerProvider>
                            <DatePicker
                                suffixIcon={<Icon spriteSymbol={icCalendar} />}
                                placeholder={intl.formatMessage(messages.selectDatetime)}
                                showTime
                                format={dateTimeFormat}
                                value={timestampToMomentDate(settings.startTime)}
                                onChange={onStartTimeChange}
                                allowClear={false}
                                data-test="schedule-script-dialog_date-time-input"
                            />
                        </DatePickerProvider>
                    </div>
                    {renderMainParamsSettings(settings.schedulerPeriod)}
                </div>
            </div>
            <div className={theme.title}>{intl.formatMessage(messages.additionalParams)}</div>
            <div className={theme.additionalParams}>
                <div className={classnames(theme.repeatSettings, hasSchedulerRepeatError ? theme.highlightError : '')}>
                    <div className={theme.repeatEvery}>
                        <div className={theme.repeatTimeLabel}>{intl.formatMessage(messages.repeatEvery)}</div>
                        <Select
                            suffixIcon={<Icon spriteSymbol={icArrowDownDark} />}
                            style={{ width: '150px' }}
                            onChange={onRepeatEverySecondsChange}
                            value={settings.repeatEverySeconds || null}
                            data-test="schedule-script-dialog_select_repeat-task-every"
                        >
                            {repeatEverySeconds.map((option) => {
                                return (
                                    <Select.Option value={option.valueInSeconds} key={option.valueInSeconds}>
                                        {option.timeUnitValue} {intl.formatMessage(messages[option.timeUnitName])}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className={theme.stopRepeatAfter}>
                        <div className={theme.repeatDuringLabel}>{intl.formatMessage(messages.while)}</div>
                        <Select
                            suffixIcon={<Icon spriteSymbol={icArrowDownDark} />}
                            style={{ width: '150px' }}
                            onChange={onStopRepeatAfterSecondsChange}
                            value={settings.stopRepeatAfterSeconds || null}
                            data-test="schedule-script-dialog_select_repeat-task-during"
                        >
                            {stopRepeatAfterSeconds.map((option) => {
                                return (
                                    <Select.Option value={option.valueInSeconds} key={option.valueInSeconds}>
                                        {option.timeUnitValue} {intl.formatMessage(messages[option.timeUnitName])}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                {hasSchedulerRepeatError ? (
                    <div className={theme.errorContainer}>{intl.formatMessage(messages.repeatEveryError)}</div>
                ) : null}
                <div className={theme.endTimeSettings}>
                    <Checkbox
                        status={!!settings.endTime}
                        onChange={onEndTimeToggle}
                        dataTest="schedule-script-dialog_validity-period-checkbox"
                        fixedWidth
                    >
                        <span className={theme.endTimeSettingsTitle}>{intl.formatMessage(messages.endTime)}</span>
                    </Checkbox>
                    {settings.endTime ? (
                        <DatePickerProvider>
                            <DatePicker
                                suffixIcon={<Icon spriteSymbol={icCalendar} />}
                                placeholder={intl.formatMessage(messages.selectDatetime)}
                                showTime
                                format={dateTimeFormat}
                                value={timestampToMomentDate(settings.endTime)}
                                onChange={onEndTimeChange}
                                allowClear={false}
                            />
                        </DatePickerProvider>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

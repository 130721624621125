import { Alignment } from '../models/alignment';
import { ButtonEditLabelState } from '../models/buttonEditLabelState';
import { EdgeArrow } from '../models/edge-arrow';
import { EdgeStyle } from '../models/edge-style';
import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';

const getState = (state: TRootState) => state.generalMenu;

export const getGeneralMenuButtonsState = createSelector(getState, (state) => state.buttonEditLabelState);

export const getGeneralMenuEdgeTypeSelectorState = createSelector(getState, (state) => state.edgeTypeSelectorState);

export const getGeneralAvailableEdgeTypeSelector = createSelector(
    getState,
    (state) => state.edgeTypeSelectorState.availableTypes,
);

export namespace FormatPanelButtonsState {
    export const getIsFontBoldSelected = createSelector<TRootState, ButtonEditLabelState, boolean>(
        getGeneralMenuButtonsState,
        (state) => state.isFontBoldSelected,
    );

    export const getIsFontItalicSelected = createSelector<TRootState, ButtonEditLabelState, boolean>(
        getGeneralMenuButtonsState,
        (state) => state.isFontItalicSelected,
    );

    export const getIsFontUnderlineSelected = createSelector<TRootState, ButtonEditLabelState, boolean>(
        getGeneralMenuButtonsState,
        (state) => state.isFontUnderlineSelected,
    );

    export const getAlignment = createSelector<TRootState, ButtonEditLabelState, Alignment>(
        getGeneralMenuButtonsState,
        (state) => state.alignment,
    );

    export const getFontSize = createSelector<TRootState, ButtonEditLabelState, string>(
        getGeneralMenuButtonsState,
        (state) => state.fontSize,
    );

    export const getFontFamily = createSelector<TRootState, ButtonEditLabelState, string>(
        getGeneralMenuButtonsState,
        (state) => state.fontFamily,
    );

    export const getHorizontal = createSelector<TRootState, ButtonEditLabelState, boolean | undefined>(
        getGeneralMenuButtonsState,
        (state) => state.horizontal,
    );

    export const getLineType = createSelector<TRootState, ButtonEditLabelState, EdgeStyle | undefined>(
        getGeneralMenuButtonsState,
        (state) => state.lineType,
    );

    export const getStartArrow = createSelector<TRootState, ButtonEditLabelState, EdgeArrow | undefined>(
        getGeneralMenuButtonsState,
        (state) => state.startArrow,
    );

    export const getEndArrow = createSelector<TRootState, ButtonEditLabelState, EdgeArrow | undefined>(
        getGeneralMenuButtonsState,
        (state) => state.endArrow,
    );

    export const getEdgeTypeId = createSelector<TRootState, ButtonEditLabelState, string | undefined>(
        getGeneralMenuButtonsState,
        (state) => state.edgeTypeId,
    );

    export const getFontColor = createSelector<TRootState, ButtonEditLabelState, string | undefined>(
        getGeneralMenuButtonsState,
        (state) => state.fontColor,
    );

    export const getFillColor = createSelector<TRootState, ButtonEditLabelState, string | undefined>(
        getGeneralMenuButtonsState,
        (state) => state.fillColor,
    );
}

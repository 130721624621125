import {
    AttributeType,
    AttributeTypeGroup,
    EdgeType,
    EdgeTypeGroup,
    FolderType,
    ImportPresetRequest,
    ImportPresetResponse,
    KanbanBoardType,
    KanbanCardType,
    MatrixType,
    ModelType,
    ModelTypeGroup,
    ObjectType,
    ObjectTypeGroup,
    PresetDTO,
    PresetImage,
    PresetImageGroup,
    PresetProfile,
    PresetTypesAcl,
    ReportType,
    ServerProfile,
    Symbol,
    TreeNodeType,
    WikiType,
} from '../../serverapi/api';
import { apiBundle } from '../api/api-bundle';
import { TPreset } from '../../models/preset.types';
import { TPresetProfileEntityTypes } from '../../modules/AdminTools/Methodology/components/Presets/PresetProfile/presetProfile.types';
import { TPresetRequestDataArray, TPresetRequestDataObjectType } from './MethodologySettingDaoService.types';

export class MethodologySettingDaoService {
    public static async getEdgeTypes(serverId: string, presetId: string): Promise<EdgeType[]> {
        const edgeTypes: EdgeType[] = await apiBundle(serverId).edgeType.byPresetId({ presetId });

        return edgeTypes;
    }

    public static async getModelTypeGroups(serverId: string, presetId: string): Promise<ModelTypeGroup[]> {
        const modelTypeGroups: ModelTypeGroup[] = await apiBundle(serverId).modelTypeGroup.byPresetId({ presetId });

        return modelTypeGroups;
    }

    public static async getObjectTypeGroups(serverId: string, presetId: string): Promise<ObjectTypeGroup[]> {
        const objectTypeGroups: ObjectTypeGroup[] = await apiBundle(serverId).objectTypeGroup.byPresetId({ presetId });

        return objectTypeGroups;
    }

    public static async getEdgeTypeGroups(serverId: string, presetId: string): Promise<ObjectTypeGroup[]> {
        const edgeTypeGroups: EdgeTypeGroup[] = await apiBundle(serverId).edgeTypeGroup.byPresetId({
            presetId,
        });

        return edgeTypeGroups;
    }

    public static async getAttributeTypeGroups(serverId: string, presetId: string): Promise<AttributeTypeGroup[]> {
        const attributeTypeGroups: AttributeTypeGroup[] = await apiBundle(serverId).attributeTypeGroup.byPresetId({
            presetId,
        });

        return attributeTypeGroups;
    }

    public static async getModelTypes(serverId: string, presetId: string): Promise<ModelType[]> {
        const modelTypes: ModelType[] = await apiBundle(serverId).modelType.byPresetId({
            presetId,
        });

        return modelTypes;
    }

    public static async getModelType(serverId: string, presetId: string, modelTypeId: string): Promise<ModelType> {
        const modelType: ModelType = await apiBundle(serverId).modelType.getModelType({
            modelTypeId,
            presetId,
        });

        return modelType;
    }

    public static async getServerProfiles(serverId: string): Promise<ServerProfile[]> {
        const serverProfiles: ServerProfile[] = await apiBundle(serverId).serverProfile.getAll();

        return serverProfiles;
    }

    public static async getPresetProfiles(serverId: string, presetId: string): Promise<PresetProfile[]> {
        const presetProfiles: PresetProfile[] = await apiBundle(serverId).profile.getProfiles({ presetId });

        return presetProfiles;
    }

    public static async getObjectTypes(serverId: string, presetId: string): Promise<ObjectType[]> {
        const objectTypes: ObjectType[] = await apiBundle(serverId).objectType.byPresetId({ presetId });

        return objectTypes;
    }

    public static async getPresetList(serverId: string): Promise<PresetDTO[]> {
        const presetDTOs: PresetDTO[] = await apiBundle(serverId).preset.list();

        return presetDTOs;
    }

    public static async getTreeNodeTypes(serverId: string, presetId: string): Promise<TreeNodeType[]> {
        const treeNodeTypes: TreeNodeType[] = await apiBundle(serverId).treeNodeType.byPresetId({ presetId });

        return treeNodeTypes;
    }

    public static async setDefaultPreset(serverId: string, presetId: string): Promise<void> {
        await apiBundle(serverId).preset.makeDefault({ presetId });
    }

    public static async getPresetCopy(serverId: string, sourceId: string, body?: PresetDTO): Promise<PresetDTO> {
        const newPreset: PresetDTO = await apiBundle(serverId).preset.copy({ sourceId, body });

        return newPreset;
    }

    public static async getGivenModelTypeGroup(
        serverId: string,
        presetId: string,
        modelTypeGroupId: string,
    ): Promise<ModelTypeGroup> {
        const givenModelTypeGroup: ModelTypeGroup = await apiBundle(serverId).modelTypeGroup.get({
            presetId,
            modelTypeGroupId,
        });

        return givenModelTypeGroup;
    }

    public static async setModelTypeGroupBulkSave(serverId: string, modelTypeGroups: ModelTypeGroup[]): Promise<void> {
        await apiBundle(serverId).modelTypeGroup.bulkSave({ body: modelTypeGroups });
    }

    public static async getGivenEdgeTypeGroup(
        serverId: string,
        presetId: string,
        edgeTypeGroupId: string,
    ): Promise<EdgeTypeGroup> {
        const givenEdgeTypeGroup: EdgeTypeGroup = await apiBundle(serverId).edgeTypeGroup.get({
            presetId,
            edgeTypeGroupId,
        });

        return givenEdgeTypeGroup;
    }

    public static async setEdgeTypeGroupBulkSave(serverId: string, edgeTypeGroups: EdgeTypeGroup[]): Promise<void> {
        await apiBundle(serverId).edgeTypeGroup.bulkSave({ body: edgeTypeGroups });
    }

    public static async getGivenAttributeTypeGroup(
        serverId: string,
        presetId: string,
        attributeTypeGroupId: string,
    ): Promise<AttributeTypeGroup> {
        const givenAttributeTypeTypeGroup: AttributeTypeGroup = await apiBundle(serverId).attributeTypeGroup.get({
            presetId,
            attributeTypeGroupId,
        });

        return givenAttributeTypeTypeGroup;
    }

    public static async setAttributeTypeGroupBulkSave(
        serverId: string,
        attributeTypeGroups: AttributeTypeGroup[],
    ): Promise<void> {
        await apiBundle(serverId).attributeTypeGroup.bulkSave({ body: attributeTypeGroups });
    }

    public static async getPresetSave(serverId: string, preset: TPreset): Promise<PresetDTO> {
        const presetDTO: PresetDTO = await apiBundle(serverId).preset.save({ body: preset });

        return presetDTO;
    }

    public static async treeNodeTypeBulkSave(serverId: string, treeNodeTypes: TreeNodeType[]): Promise<void> {
        await apiBundle(serverId).treeNodeType.bulkSave({ body: treeNodeTypes });
    }

    public static async getAclsByType(
        serverId: string,
        presetId: string,
        profileId: string,
        type: TPresetProfileEntityTypes,
    ): Promise<PresetTypesAcl[]> {
        const acls: PresetTypesAcl[] = await apiBundle(serverId).profile.getAcls({ presetId, type, profileId });

        return acls;
    }

    public static async getSymbolsByPresetId(serverId: string, presetId: string): Promise<Symbol[]> {
        const symbols: Symbol[] = await apiBundle(serverId).symbol.byPresetId({ presetId });

        return symbols;
    }

    public static async getAttributeTypeByPresetId(serverId: string, presetId: string): Promise<AttributeType[]> {
        const attributeTypes: AttributeType[] = await apiBundle(serverId).attributeType.byPresetId({ presetId });

        return attributeTypes;
    }

    public static async deleteProfile(serverId: string, presetId: string, profileId: string): Promise<void> {
        await apiBundle(serverId).profile.deleteProfile({ presetId, profileId });
    }

    public static async deleteSymbols(serverId: string, symbolsToRemove: Symbol[]): Promise<void> {
        await apiBundle(serverId).symbol._delete({ body: symbolsToRemove });
    }

    public static async modelTypeBulkDelete(serverId: string, modelTypes: ModelType[]): Promise<void> {
        await apiBundle(serverId).modelType.bulkDelete({ body: modelTypes });
    }

    public static async modelTypeGroupBulkDelete(serverId: string, modelTypeGroups: ModelTypeGroup[]): Promise<void> {
        await apiBundle(serverId).modelTypeGroup.bulkDelete({ body: modelTypeGroups });
    }

    public static async attributeTypeBulkDelete(serverId: string, attributeTypes: AttributeType[]): Promise<void> {
        await apiBundle(serverId).attributeType.bulkDelete({ body: attributeTypes });
    }

    public static async deletePreset(serverId: string, presetId: string): Promise<void> {
        await apiBundle(serverId).preset.deletePreset({ presetId });
    }

    public static async presetDoImportTypes(
        serverId: string,
        importParams: { type: string; presetId: string; body?: ImportPresetRequest | undefined },
    ): Promise<ImportPresetResponse> {
        return apiBundle(serverId).preset.importTypes(importParams);
    }

    public static async presetDoImport(
        serverId: string,
        importParams: { body?: ImportPresetRequest | undefined },
    ): Promise<ImportPresetResponse> {
        return apiBundle(serverId).preset.importPreset(importParams);
    }

    //
    // public static async uploadUserTmpFile(serverId: string, file: File): Promise<void> {
    //     await apiBundle(serverId).fileTmpStorage.uploadUserTmpFile({ body: { file } });
    // }

    public static async convertSvgToShape(serverId: string, fileName: string): Promise<Symbol> {
        const symbolResp: Symbol = await apiBundle(serverId).symbolEditor.convertSvgToShape({ fileName });

        return symbolResp;
    }

    public static async symbolSave(serverId: string, symbol: Symbol): Promise<Symbol> {
        const symbolResp: Symbol = await apiBundle(serverId).symbol.save({ body: symbol });

        return symbolResp;
    }

    public static async createSymbol(serverId: string, symbol: Symbol): Promise<Symbol> {
        const symbolResp: Symbol = await apiBundle(serverId).symbol.create({ body: symbol });

        return symbolResp;
    }

    public static async getFolderTypes(serverId: string, presetId: string): Promise<FolderType[]> {
        const folderTypes: FolderType[] = await apiBundle(serverId).folderType.byPresetId({ presetId });

        return folderTypes;
    }

    public static async getPresetImages(serverId: string, presetId: string): Promise<PresetImage[]> {
        const presetImages: PresetImage[] = await apiBundle(serverId).presetImage.byPresetId({ presetId });

        return presetImages;
    }

    public static async getPresetImageGroups(serverId: string, presetId: string): Promise<PresetImageGroup[]> {
        const presetImageGroups: PresetImageGroup[] = await apiBundle(serverId).presetImageGroup.byPresetId({
            presetId,
        });

        return presetImageGroups;
    }

    public static async getKanbanBoardTypes(serverId: string, presetId: string): Promise<KanbanBoardType[]> {
        const kanbanBoardTypes: KanbanBoardType[] = await apiBundle(serverId).kanbanBoardType.byPresetId({
            presetId,
        });
        return kanbanBoardTypes;
    }

    public static async getKanbanCardTypes(serverId: string, presetId: string): Promise<KanbanCardType[]> {
        const kanbanCardTypes: KanbanCardType[] = await apiBundle(serverId).kanbanCardType.byPresetId({
            presetId,
        });
        return kanbanCardTypes;
    }

    public static async getMatrixTypes(serverId: string, presetId: string): Promise<MatrixType[]> {
        const matrixTypes: MatrixType[] = await apiBundle(serverId).matrixType.byPresetId({
            presetId,
        });
        return matrixTypes;
    }

    public static async getReportTypes(serverId: string, presetId: string): Promise<ReportType[]> {
        const reportTypes: ReportType[] = await apiBundle(serverId).reportType.byPresetId({
            presetId,
        });
        return reportTypes;
    }

    public static async getWikiTypes(serverId: string, presetId: string): Promise<WikiType[]> {
        const wikiTypes: WikiType[] = await apiBundle(serverId).wikiType.byPresetId({
            presetId,
        });

        return wikiTypes;
    }

    private static convertPresetRequestArrayToObject(
        requestArray: TPresetRequestDataArray,
    ): TPresetRequestDataObjectType {
        const presetRequestDataObject: TPresetRequestDataObjectType = {
            edgeTypes: requestArray[0],
            modelTypeGroups: requestArray[1],
            objectTypeGroups: requestArray[2],
            edgeTypeGroups: requestArray[3],
            attributeTypeGroups: requestArray[4],
            modelTypes: requestArray[5],
            serverProfiles: requestArray[6],
            presetProfiles: requestArray[7],
            objectTypes: requestArray[8],
            symbols: requestArray[9],
            attributeTypes: requestArray[10],
            treeNodeTypes: requestArray[11],
            folderTypes: requestArray[12],
            presetImages: requestArray[13],
            presetImageGroups: requestArray[14],
            kanbanModelTypes: requestArray[15],
            kanbanCardTypes: requestArray[16],
            matrixModelTypes: requestArray[17],
            reportModelTypes: requestArray[18],
            wikiModelTypes: requestArray[19],
        };

        return presetRequestDataObject;
    }

    public static async getEditPresetRequestData(serverId: string, presetId: string) {
        // Неизвестная ошибка TS2558, больше 10 елементов массива Promise.all не типизирует
        // @ts-ignore
        const requestArray: TPresetRequestDataArray = await Promise.all<
            // @ts-ignore
            EdgeType[],
            ModelTypeGroup[],
            ObjectTypeGroup[],
            EdgeTypeGroup[],
            AttributeTypeGroup[],
            ModelType[],
            ServerProfile[],
            PresetProfile[],
            ObjectType[],
            Symbol[],
            AttributeType[],
            FolderType[],
            PresetImage[],
            PresetImageGroup[],
            KanbanBoardType[],
            KanbanCardType[],
            MatrixType[],
            ReportType[],
            WikiType[]
        >([
            this.getEdgeTypes(serverId, presetId),
            this.getModelTypeGroups(serverId, presetId),
            this.getObjectTypeGroups(serverId, presetId),
            this.getEdgeTypeGroups(serverId, presetId),
            this.getAttributeTypeGroups(serverId, presetId),
            this.getModelTypes(serverId, presetId),
            this.getServerProfiles(serverId),
            this.getPresetProfiles(serverId, presetId),
            this.getObjectTypes(serverId, presetId),
            this.getSymbolsByPresetId(serverId, presetId),
            this.getAttributeTypeByPresetId(serverId, presetId),
            this.getTreeNodeTypes(serverId, presetId),
            this.getFolderTypes(serverId, presetId),
            this.getPresetImages(serverId, presetId),
            this.getPresetImageGroups(serverId, presetId),
            this.getKanbanBoardTypes(serverId, presetId),
            this.getKanbanCardTypes(serverId, presetId),
            this.getMatrixTypes(serverId, presetId),
            this.getReportTypes(serverId, presetId),
            this.getWikiTypes(serverId, presetId),
        ]);

        return this.convertPresetRequestArrayToObject(requestArray);
    }

    public static async createModelTypeGroup(serverId: string, modelTypeGroup: ModelTypeGroup): Promise<void> {
        await apiBundle(serverId).modelTypeGroup.create({ body: modelTypeGroup });
    }

    public static async createEdgeTypeGroup(serverId: string, edgeTypeGroup: EdgeTypeGroup): Promise<void> {
        await apiBundle(serverId).edgeTypeGroup.create({ body: edgeTypeGroup });
    }

    public static async createAttributeTypeGroup(
        serverId: string,
        attributeTypeGroup: AttributeTypeGroup,
    ): Promise<void> {
        await apiBundle(serverId).attributeTypeGroup.create({ body: attributeTypeGroup });
    }
}

import React, { FC, useEffect } from 'react';
import {
    ArisImportDescriptor,
    ArisImportDescriptorConflictResolutionStrategyEnum,
    ArisImportDescriptorFileLocationEnum,
    NodeId,
} from '../../../../../serverapi/api';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    setArisAttachingEdgesToSymbolBorder,
    setArisImportFileLocation,
    setArisResolveConflictStrategy,
} from '../../../../../actions/importAris.actions';
import { OptionData } from 'rc-select/es/interface';
import { useIntl } from 'react-intl';
import messages from '../../messages/ImportAris.messages';
import style from './ParamsStep.scss';
import { ImportArisSelectors } from '../../../../../selectors/importAris.selectors';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

type TParamStepProps = {
    nodeId: NodeId;
};

const { Option } = Select;

const ParamsStep: FC<TParamStepProps> = ({ nodeId }) => {
    const { serverId, repositoryId, id } = nodeId;

    const dispatch = useDispatch();
    const intl = useIntl();

    const descriptor: ArisImportDescriptor = useSelector(
        ImportArisSelectors.getArisDescriptor(serverId, repositoryId, id),
    );

    const setInitialImportParams = () => {
        dispatch(setArisImportFileLocation({ nodeId, fileLocation: 'COPY_FOLDER' }));
        dispatch(setArisResolveConflictStrategy({ nodeId, conflictResolutionStrategy: 'CLONE' }));
    };

    useEffect(() => {
        if (!descriptor.fileLocation || !descriptor.conflictResolutionStrategy) {
            setInitialImportParams();
        }
    }, []);

    const fileLocationOptions: { [key in ArisImportDescriptorFileLocationEnum]: string } = {
        COPY_FOLDER: intl.formatMessage(messages.copyFolderLocation),
        MODEL: intl.formatMessage(messages.modelLocation),
    };

    const conflictResolutionStrategyOptions: { [key in ArisImportDescriptorConflictResolutionStrategyEnum]: string } = {
        LEAVE: intl.formatMessage(messages.conflictResolveLeave),
        REPLACE: intl.formatMessage(messages.conflictResolveReplace),
        CLONE: intl.formatMessage(messages.conflictResolveClone),
    };

    const setLocationImportFiles = (value: string, option: OptionData) => {
        const fileLocation = option.key as ArisImportDescriptorFileLocationEnum;
        dispatch(setArisImportFileLocation({ nodeId, fileLocation }));
    };

    const setResolveImportConflict = (value: string, option: OptionData) => {
        const conflictResolutionStrategy = option.key as ArisImportDescriptorConflictResolutionStrategyEnum;
        dispatch(setArisResolveConflictStrategy({ nodeId, conflictResolutionStrategy }));
    };

    const setAttachingEdgesToSymbolBorder = (checked: boolean) => {
        dispatch(setArisAttachingEdgesToSymbolBorder({ nodeId, attachingEdgesToSymbolBorder: checked }));
    };

    return (
        <div className={style.container}>
            <div className={style.paramsItem}>
                <p className={style.paramsItem_description}>{intl.formatMessage(messages.objectImportPlace)}</p>
                <Select
                    className={style.select}
                    onSelect={setLocationImportFiles}
                    defaultValue={fileLocationOptions.COPY_FOLDER}
                >
                    <Option key="MODEL" value={fileLocationOptions.MODEL}>
                        {fileLocationOptions.MODEL}
                    </Option>
                    <Option key="COPY_FOLDER" value={fileLocationOptions.COPY_FOLDER}>
                        {fileLocationOptions.COPY_FOLDER}
                    </Option>
                </Select>
            </div>
            <div className={style.paramsItem}>
                <p className={style.paramsItem_description}>{intl.formatMessage(messages.conflictResolveStrategy)}</p>
                <Select
                    className={style.select}
                    onSelect={setResolveImportConflict}
                    defaultValue={conflictResolutionStrategyOptions.CLONE}
                >
                    <Option key="LEAVE" value={conflictResolutionStrategyOptions.LEAVE}>
                        {conflictResolutionStrategyOptions.LEAVE}
                    </Option>
                    <Option key="REPLACE" value={conflictResolutionStrategyOptions.REPLACE}>
                        {conflictResolutionStrategyOptions.REPLACE}
                    </Option>
                    <Option key="CLONE" value={conflictResolutionStrategyOptions.CLONE}>
                        {conflictResolutionStrategyOptions.CLONE}
                    </Option>
                </Select>
            </div>
            <div className={style.paramsCheckbox}>
                <Checkbox onChange={setAttachingEdgesToSymbolBorder}>
                    {intl.formatMessage(messages.attachingEdgesToSymbolBorder)}
                </Checkbox>
            </div>
        </div>
    );
};

export default ParamsStep;

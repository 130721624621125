import { WrappedComponentProps } from 'react-intl';
import { AttributeValuePrincipal, PrincipalAttributeTypeSettingsPrincipalTypeEnum, PrincipalDescriptor } from '../../../../serverapi/api';

export type TPrincipalAttributeDialogProps = WrappedComponentProps & {
    open: boolean;
    attributeName: string;
    attributeValue: AttributeValuePrincipal;
    serverId: string;
    groups: PrincipalDescriptor[];
    users: PrincipalDescriptor[];
    disabled?: boolean;
    principalType?: PrincipalAttributeTypeSettingsPrincipalTypeEnum;
    onSubmit: (value: AttributeValuePrincipal) => void;
    onCancel: () => void;
};

export enum FilterOptions {
    ALL,
    ACTIVE,
    BLOCKED,
    DELETED,
}

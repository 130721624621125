import type { Editor } from '@tiptap/react';
import icListIndentDescrease from 'icons/toolbar/controls/ic-indent-decrease.svg';
import { useIntl } from 'react-intl';
import { SyntheticEvent, useContext } from 'react';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { StateObserver } from '../../common/sharedState.class';
import messages from '../CommonToolbar.messages';
import React from 'react';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';

const BULLET_LIST_ITEM = 'bullet-list-item';

const ORDERED_LIST_ITEM = 'ordered-list-item';

const TASK_LIST_ITEM = 'task-list-item';

type TListsValue = {
    [key: string]: boolean;
};

const itemsIdMap = {
    [BULLET_LIST_ITEM]: 'listItem',
    [ORDERED_LIST_ITEM]: 'listItem',
    [TASK_LIST_ITEM]: 'taskItem',
};

const getActiveLists = (editor: Editor) => ({
    [BULLET_LIST_ITEM]: editor.isActive('bulletList'),
    [ORDERED_LIST_ITEM]: editor.isActive('orderedList'),
    [TASK_LIST_ITEM]: editor.isActive('taskList'),
});

const getChangedBlock = (editor: Editor) => {
    const activeLists = getActiveLists(editor);
    const activeListItemKey: string = Object.keys(activeLists).find((key) => activeLists[key]) || 'listItem';

    return editor.chain().focus().liftListItem(itemsIdMap[activeListItemKey]).run();
};

const getIsOutdentDisabled = (editor: Editor | undefined): boolean => {
    if (!editor) {
        return false;
    }

    const canLiftListItem = editor.can().liftListItem('listItem');
    const canLiftTaskItem = editor.can().liftListItem('taskItem');

    return !canLiftListItem && !canLiftTaskItem;
};

export const LiftListItem = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, editor } = useSharedState<TListsValue, SyntheticEvent<HTMLElement, Event>>({
        stateObserver,
        getChangedBlock,
    });
    const isOutdentDisabled: boolean = getIsOutdentDisabled(editor);

    return (
        <ToolbarButton
            icon={icListIndentDescrease}
            tooltip={intl.formatMessage(messages.liftListItem)}
            onClick={setCurrentState}
            dataTest={'wiki-toolbar-group_lists-button_outdent'}
            disabled={isOutdentDisabled}
            size="small"
        />
    );
};

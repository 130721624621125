import { NodeId } from '@/serverapi/api';

export type TDbSearchDialogProps = {
    nodeId: NodeId;
    type: SearchDialogType;
    open?: boolean;
};

export enum SearchDialogType {
    Report = 'ReportDialog',
    Widget = 'WidgetDialog',
}

import React from 'react';
import { SpaceAction, SpaceActionBtns } from '../../../../../../models/insertionSpace';
import messages from './InsertSpaceDropdown.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { processSpaceAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { ToolbarDropdown } from '../../../../../UIKit/components/Toolbar/ToolbarDropdown/ToolbarDropdown.component';

export const InsertSpaceDropdown = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const tooltip: string = useSelector(EditorToolbarSelectors.getInsertSpaceDropdownTooltip);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getInsertSpaceDropdownDisabled);
    const actions: JSX.Element[] = Object.keys(SpaceAction).map((key) => {
        return (
            <ToolbarButton
                icon={SpaceActionBtns[key]}
                tooltip={intl.formatMessage(messages[SpaceAction[key]])}
                onClick={() => dispatch(processSpaceAction(SpaceAction[key]))}
                dataTest={`general-toolbar_insert-space-btn${key}`}
            />
        );
    });

    return (
        <ToolbarDropdown
            options={actions}
            icon={SpaceActionBtns.InsertHorizontal}
            size="small"
            disabled={disabled}
            tooltip={tooltip}
            dataTest="general-toolbar_insert-space-btn"
        />
    );
};

import React from 'react';
import { ResizableToolbar } from '../../../UIKit/components/Toolbar/ResizableToolbar/ResizableToolbar.component';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { EditModeButton } from '../GraphGeneralToolbar/ToolbarItems/EditModeButton/EditModeButton';

export const KanbanEditorToolbar = () => {
    return (
        <ResizableToolbar>
            <ButtonGroup>
                <EditModeButton />
            </ButtonGroup>
        </ResizableToolbar>
    );
};

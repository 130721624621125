import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';
import { LabelStyle } from '../../../../models/labelStyle';
import { FormatPanelSelectors } from '../../../../selectors/formatPanel.selectors';
import { Select } from '../../../UIKit/components/Select/Select.component';
import { fontFamilyOptions } from '../../../../utils/configuration';
import { FormatPanelButtonsState } from '../../../../selectors/generalMenu.selectors';

export const FontFamily = () => {
    const dispatch = useDispatch();

    const disabled: boolean = useSelector(FormatPanelSelectors.getFormatButtonsDisabled);
    const tooltip: string = useSelector(FormatPanelSelectors.getFormatButtonsTooltip(''));

    const fontFamily: string | undefined = useSelector(FormatPanelButtonsState.getFontFamily);

    const onChange = (labelStyle: LabelStyle) => (value: string) => {
        dispatch(generalMenuLabelStyleChangeAction(labelStyle, value, false));
    };

    return (
        <Select
            onChange={onChange(LabelStyle.fontFamily)}
            value={fontFamily}
            disabled={disabled}
            placeholder=""
            originalTheme
            data-test="object-toolbar_font-family-select"
            tooltip={tooltip}
        >
            {fontFamilyOptions.map((fontFamily) => {
                return (
                    <Select.Option
                        value={fontFamily.value}
                        label={<span style={fontFamily.style}>{fontFamily.value}</span>}
                    />
                );
            })}
        </Select>
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'UITemplatesTable.name',
        defaultMessage: 'Наименование',
    },
    updatedAt: {
        id: 'UITemplatesTable.updatedAt',
        defaultMessage: 'Дата обновления',
    },
});

import { LabelStyle } from '../models/labelStyle';
import { ButtonEditLabelState } from '../models/buttonEditLabelState';
import { TEdgeTypeSelectorState } from '../models/edgeTypeSelectorState.types';
import {
    GENERAL_MENU_LABEL_STYLE_CHANGE,
    GENERAL_MENU_TOGGLE_STYLE_BUTTONS,
    GENERAL_MENU_UPDATE_AVAILABLE_EDGE_TYPES,
} from '../actionsTypes/generalMenu.actionTypes';
import {
    TGeneralMenuLabelStyleChangeAction,
    TGeneralMenuToggleStyleButtonsAction,
    TGeneralMenuUpdateAvailableEdgeTypes,
} from './generalMenu.actions.types';

export const generalMenuLabelStyleChangeAction = (
    action: LabelStyle,
    value: number | string | boolean,
    isActive?: boolean,
): TGeneralMenuLabelStyleChangeAction => ({
    type: GENERAL_MENU_LABEL_STYLE_CHANGE,
    payload: {
        action,
        value,
        isActive,
    },
});

export const generalMenuToggleStyleButtonsAction = (
    buttonEditLabelState: ButtonEditLabelState,
): TGeneralMenuToggleStyleButtonsAction => ({
    type: GENERAL_MENU_TOGGLE_STYLE_BUTTONS,
    payload: {
        buttonEditLabelState,
    },
});

export const generalMenuUpdateAvailableEdgeTypesAction = (
    edgeTypeSelectorState: TEdgeTypeSelectorState,
): TGeneralMenuUpdateAvailableEdgeTypes => ({
    type: GENERAL_MENU_UPDATE_AVAILABLE_EDGE_TYPES,
    payload: {
        edgeTypeSelectorState,
    },
});

import { useDispatch, useSelector } from 'react-redux';
import { MatrixEditorSelectors } from '../../../../Matrix/selectors/matrixEditor.selectors';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../serverapi/api';
import { ToolbarButton } from '../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import React from 'react';
import { matrixPastEmptyLane } from '../../../../Matrix/actions/matrixEditor.actions';
import icPasteRowTop from '@/resources/icons/toolbar/pastRowTop.svg';

export const PasteEmptyLaneTop = () => {
    const dispatch = useDispatch();
    const activeTabNodeId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const disabled: boolean = useSelector(MatrixEditorSelectors.getRowPasteDisabled(activeTabNodeId));
    const tooltip: string = useSelector(MatrixEditorSelectors.getRowPasteTopTooltip(activeTabNodeId));

    const pastEmptyLane = (pastOnTheOtherSide?: boolean) => {
        dispatch(matrixPastEmptyLane(activeTabNodeId, pastOnTheOtherSide));
    };

    return (
        <ToolbarButton
            icon={icPasteRowTop}
            size="small"
            disabled={disabled}
            onClick={() => pastEmptyLane()}
            tooltip={tooltip}
        />
    );
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { ToolbarButton } from '../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import icGridBtn from '@/resources/icons/toolbar/dashboard-grid-button.svg';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { NodeId } from '../../../../../serverapi/api';
import { DashboardToolbarSelectors } from '../../../../../selectors/DashboardToolbar.selectors';

export const GridButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(DashboardToolbarSelectors.getGridButtonDisabled);
    const tooltip: string = useSelector(DashboardToolbarSelectors.getGridButtonTooltip);
    const activeTabId: NodeId = useSelector(TabsSelectors.getActiveTabId);

    const handleGridButton = () => {
        dispatch(openDialog(DialogType.DASHBOARD_CELLS_COUNT_DIALOG, { nodeId: activeTabId }));
    };

    return (
        <ToolbarButton
            icon={icGridBtn}
            size="small"
            dataTest={'dashboard-toolbar_edit-grid-button'}
            disabled={disabled}
            onClick={handleGridButton}
            tooltip={tooltip}
        />
    );
};

export const calcRightOffset = (buttonRef: HTMLButtonElement): number => {
    const bodyWidth: number = parseInt(getComputedStyle(document.body).width);
    const buttonRight: number = buttonRef.getBoundingClientRect().right;

    return bodyWidth - buttonRight;
};

export const calcNewRow = (portalRef: React.MutableRefObject<HTMLDivElement | null>): boolean[] => {
    const dropdownChildren: HTMLCollection | undefined = portalRef.current?.children;
    const childrenRect: DOMRect[] = Array.from(dropdownChildren || []).map((child) => child.getBoundingClientRect());
    const result: boolean[] = childrenRect.map((childRect, index) => {
        if (childRect.top !== childrenRect[index - 1]?.top) return true;
        return false;
    });

    return result;
};

export const calcDropdownWidth = (
    portalDiv: HTMLDivElement,
    containerWidth: number | undefined,
    rightOffset: number,
) => {
    const dropdownChildren: HTMLCollection = portalDiv.children;
    const childrenRect: number[] = Array.from(dropdownChildren).map((child) => child.getBoundingClientRect().width);
    const paddingRight: number = portalDiv ? +getComputedStyle(portalDiv).paddingRight.slice(0, -2) : 8;
    const menuWidth: number = (containerWidth || 500) - rightOffset;
    const dropdownWidth: number = (() => {
        let width: number = 0;
        for (let i = 0; i < childrenRect.length; i++) {
            width = width + childrenRect[i];
            if (width > menuWidth - paddingRight) {
                width = width - childrenRect[i];
                break;
            }
        }
        return width;
    })();

    return dropdownWidth;
};

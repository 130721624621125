import { Property } from 'csstype';
import React, { useRef, useState } from 'react';

type TCoordinates = {
    x: number;
    y: number;
};

type TUseMoveDialogReturnValue = {
    clickCaptureHandlers: {
        onMouseDownCapture: React.MouseEventHandler;
        onMouseUpCapture: React.MouseEventHandler;
        onMouseMove: React.MouseEventHandler;
    };
    style: {
        cursor: Property.Cursor;
        transform: Property.Transform;
    };
};

const isDialogHeader = (el: Element): boolean => {
    if (el.classList.contains('ant-modal-header')) {
        return true;
    }

    if (el.parentElement) {
        return isDialogHeader(el.parentElement);
    }

    return false;
};

export const useMoveModalDialog = (): TUseMoveDialogReturnValue => {
    const [currentTransition, setCurrentTransition] = useState<TCoordinates>({ x: 0, y: 0 });
    const [cursor, setCursor] = useState<Property.Cursor>('auto');

    const savedPositionRef = useRef<TCoordinates>({ x: 0, y: 0 });
    const startDragPositionRef = useRef<TCoordinates>({ x: 0, y: 0 });
    const isCaptureMouseMove = useRef<boolean>(false);
    const requestAnimationFrameIdRef = useRef<number>(0);

    const onMouseMove = (e: React.MouseEvent): void => {
        if (!isCaptureMouseMove.current) return;

        cancelAnimationFrame(requestAnimationFrameIdRef.current);

        requestAnimationFrameIdRef.current = requestAnimationFrame(() => {
            const offsetX: number = e.clientX - startDragPositionRef.current.x;
            const offsetY: number = e.clientY - startDragPositionRef.current.y;

            setCurrentTransition({
                x: savedPositionRef.current.x + offsetX,
                y: savedPositionRef.current.y + offsetY,
            });
        });
    };

    const onMouseDownCapture = (e: React.MouseEvent): void => {
        if (!isDialogHeader(e.target as Element)) return;

        isCaptureMouseMove.current = true;

        document.body.style.userSelect = 'none';

        startDragPositionRef.current.x = e.clientX;
        startDragPositionRef.current.y = e.clientY;

        setCursor('grabbing');
    };

    const onMouseUpCapture = (): void => {
        if (!isCaptureMouseMove.current) return;

        cancelAnimationFrame(requestAnimationFrameIdRef.current);

        isCaptureMouseMove.current = false;

        document.body.style.userSelect = 'auto';

        savedPositionRef.current.x = currentTransition.x;
        savedPositionRef.current.y = currentTransition.y;

        setCursor('auto');
    };

    return {
        clickCaptureHandlers: {
            onMouseDownCapture,
            onMouseUpCapture,
            onMouseMove,
        },
        style: {
            transform: `translate(${currentTransition.x}px, ${currentTransition.y}px)`,
            cursor,
        },
    };
};

import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../UIKit/components/Select/Select.component';
import messages from './audit.messages';
import style from './ActionsAudit.scss';
import { accessTypes } from '../../../actions/audit.actions.types';
import { setAuditFilteredAccesses } from '../../../actions/audit.actions';
import { ActionAuditSelectors } from '../../../selectors/actionAudit.selector';

export const TradeSecretPersonalDataFilter = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const filteredEccesses: accessTypes[] = useSelector(ActionAuditSelectors.filteredAccesses);
    const onChangeByAccess = (accesses: accessTypes[]) => dispatch(setAuditFilteredAccesses(accesses));

    useEffect(() => {
        return () => {
            dispatch(setAuditFilteredAccesses([]));
        };
    }, []);

    return (
        <div className={style.selectContainer} data-test="security_audit_access-filtration">
            <Select
                value={intl.formatMessage(messages.byAccess)}
                isMultiSelect
                onChange={onChangeByAccess}
                dropdownClassName={style.selContainer}
            >
                {Object.values(accessTypes).map((access) => (
                    <Select.Option
                        key={access}
                        checked={filteredEccesses?.includes(access)}
                        value={access}
                        label={intl.formatMessage(messages[access])}
                    />
                ))}
            </Select>
        </div>
    );
};

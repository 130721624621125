export enum EditingTabMode {
    EDIT = 'edit',
    ADD = 'add',
}

export enum AdminToolTreeType {
    ADMIN_TOOL_ROOT = 'admin-tools-root-id',
    SERVER_SETTINGS = 'server-settings-id',
    USER_MANAGMENT = 'user-management-id',
    LICENSES = 'licenses-id',
    METHODOLOGY_SETTINGS = 'methodology-settings-id',
    SAFETY = 'safety-id',
    USERDATA_EDITING_TAB = 'user_data_editing_tab',
    GROUPDATA_EDITING_TAB = 'group_data_editing_tab',
    LICENSEOWNERS_EDITING_TAB = 'license_owners_editing_tab',
    ACCESS_PERMISSION_OWNERS_EDITING_TAB = 'accesspermisson_owners_editing_tab',
    DBACCESS_OWNERS_EDITING_TAB = 'dbaccess_owners_editing_tab',
    MONITORING_TAB = 'AdminTools.monitoring.tabName',
    NOTIFICATIONS = 'notifications',
    PRINCIPAL_SERVER_PROFILE_OWNERS_EDITING_TAB = 'principal_server_profile_owners_editing_tab',
    EVENT_ACCESS = 'event-access-id',
    APPROVALS_TAB = 'approvals-id',
    PUBLISHED_MODELS = 'published-models',
    UI_TEMPLATES = 'ui-templates',
}

export enum TAdminToolsParamsKeys {
    LICENSE_DISTRIBUTION = 'licensesDistribution',
    ACCESS_PERMISSIONS = 'accessesRights',
    DB_ACCESS = 'dbAccess',
    SERVER_PROFILES = 'serverProfiles',
}

export type TLicensesDistributionParams = {
    groupId: number;
};

export type TAccessPermissionsParams = TLicensesDistributionParams;

export type TServerProfilesParams = TLicensesDistributionParams;

export type TDBAccessParams = TLicensesDistributionParams;

export type TAdminToolsParams = {
    licensesDistribution?: TLicensesDistributionParams;
    accessesRights?: TAccessPermissionsParams;
    dbAccess?: TDBAccessParams;
    serverProfiles?: TServerProfilesParams;
};

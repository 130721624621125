// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonGroup__container__FNcFl{border-radius:4px;border:1px solid var(--ButtonGroupBorder);background-color:var(--ButtonGroupnBackground);display:-webkit-box;display:-ms-flexbox;display:flex;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.ButtonGroup__container__FNcFl button+button{margin-left:0}.ButtonGroup__container__FNcFl.ButtonGroup__hideBorder__Y2GRW{border:none !important}.ButtonGroup__btmItem__d_V5E:first-child button{border-top-left-radius:4px;border-bottom-left-radius:4px}.ButtonGroup__btmItem__d_V5E:last-child button{border-top-right-radius:4px;border-bottom-right-radius:4px}.ButtonGroup__btnItemDivider__B7MEM+.ButtonGroup__btnItemDivider__B7MEM{margin-left:4px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Toolbar/ButtonGroup/ButtonGroup.scss"],"names":[],"mappings":"AAAA,+BAII,iBAAA,CACA,yCAAA,CACA,8CAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CAPA,6CACI,aAAA,CASR,8DACI,sBAAA,CAGJ,gDACI,0BAAA,CACA,6BAAA,CAEJ,+CACI,2BAAA,CACA,8BAAA,CAGJ,wEACI,eAAA","sourcesContent":[".container {\r\n    button + button {\r\n        margin-left: 0;\r\n    }\r\n    border-radius: 4px;\r\n    border: 1px solid var(--ButtonGroupBorder);\r\n    background-color: var(--ButtonGroupnBackground);\r\n    display: flex;\r\n    width: fit-content;\r\n}\r\n\r\n.container.hideBorder {\r\n    border: none !important;\r\n}\r\n\r\n.btmItem:first-child button {\r\n    border-top-left-radius: 4px;\r\n    border-bottom-left-radius: 4px;\r\n}\r\n.btmItem:last-child button {\r\n    border-top-right-radius: 4px;\r\n    border-bottom-right-radius: 4px;\r\n}\r\n\r\n.btnItemDivider + .btnItemDivider {\r\n    margin-left: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ButtonGroup__container__FNcFl",
	"hideBorder": "ButtonGroup__hideBorder__Y2GRW",
	"btmItem": "ButtonGroup__btmItem__d_V5E",
	"btnItemDivider": "ButtonGroup__btnItemDivider__B7MEM"
};
export default ___CSS_LOADER_EXPORT___;

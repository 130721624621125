import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_FONT_SIZE_OPTION } from './Controls.constants';
import messages from '../CommonToolbar.messages';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../../../Toolbar/ResizableToolbar/ButtonGroups/groups.scss';
import { Select } from '../../../Select/Select.component';
import { fontSizeOptions } from '../../../../../../utils/configuration';
import classNames from 'classnames';
import { ClearValueButton } from '../../../Toolbar/ClearValuesBtn/ClearValuesButton.component';
import { TValue } from '../../../Toolbar/ResizableToolbar/ButtonGroups/toolbarSelect.types';
import { StateObserver } from '../../common/sharedState.class';
import { AddNewValueButton } from '../../../Toolbar/AddNewValueBtn/AddNewValueButton.component';
import { useDispatch, useSelector } from 'react-redux';
import { AddedModelParamsSelectors } from '../../../../../../selectors/addedModelFormatParams.selectors';
import { FormatParamValues } from '../../../../../../reducers/addedModelFormatParams.reducer.types';
import {
    addModelFormatParamValue,
    deleteAllModelFormatParamValues,
} from '../../../../../../actions/addedModelFormatParams.actions';

const getFocusedBlock = (editor: Editor) =>
    editor.getAttributes('textStyle').fontSize || DEFAULT_FONT_SIZE_OPTION.value;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setFontSize(value).run();

const validateNumber = (rule: {}, value: string, callback: (msg?: string) => void) => {
    const pattern: RegExp = /^\d+$/;
    if (!pattern.test(value) || value === '0') {
        callback('not number');
    } else {
        callback();
    }
};

export const FontSize = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const addedFontSizers: TValue[] = useSelector(
        AddedModelParamsSelectors.getAddedParamsByActiveModelId(FormatParamValues.fontSizes),
    );

    const handleAdd = (value: string) => {
        dispatch(addModelFormatParamValue(value, FormatParamValues.fontSizes));
    };

    const handleDeleteAll = () => {
        dispatch(deleteAllModelFormatParamValues(FormatParamValues.fontSizes));
    };

    const onChangeHandler = (value: string) => {
        if (['del', 'add'].includes(value)) return;
        setCurrentState(value);
    };

    return (
        <Select
            onChange={onChangeHandler}
            value={value || DEFAULT_FONT_SIZE_OPTION.label}
            placeholder=""
            originalTheme
            data-test="wiki-toolbar-group_select-font-size-button"
            wrapperClassName={classNames(theme.fontSizeContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            tooltip={intl.formatMessage(messages.fontSize)}
        >
            {[
                ...fontSizeOptions,
                ...addedFontSizers,
                !!addedFontSizers.length && {
                    value: 'del',
                    label: <ClearValueButton onClicked={handleDeleteAll} />,
                },
                {
                    value: 'add',
                    label: (
                        <AddNewValueButton
                            onAddClicked={(value) => handleAdd(value)}
                            title={intl.formatMessage(messages.formLabel)}
                            validationRules={[
                                { required: true, message: intl.formatMessage(messages.formValidationMessageRequired) },
                                {
                                    validator: validateNumber,
                                    message: intl.formatMessage(messages.formValidationMessageNumberOnly),
                                },
                            ]}
                        />
                    ),
                },
            ]
                .filter((option): option is TValue => !!option)
                .map((style) => {
                    return <Select.Option value={style.value} label={style.label} />;
                })}
        </Select>
    );
};

import { ColProperties, RowData } from 'x-data-spreadsheet';
import { SpreadsheetCell, SpreadsheetStyle } from '../serverapi/api';
import { ISpreadsheetNode } from '../models/bpm/bpm-model-impl.types';
import { TCols, TRows, TSpreadsheet } from '../modules/Spreadsheet/spreadsheet.types';

export const getSpreadsheetStyle = (data: TSpreadsheet): SpreadsheetStyle => {
    const style: SpreadsheetStyle = {
        columns: Object.entries(data.cols || {})
            .filter((col) => (col[1] as ColProperties).width)
            .map((col) => ({
                index: +col[0],
                width: (col[1] as ColProperties).width,
            })),
        rows: Object.entries(data.rows || {})
            .filter((row) => row[1].height)
            .map((row) => ({
                index: +row[0],
                height: row[1].height,
            })),
    };

    return style;
};

export const getCellDataFromSpreadsheetRows = (rows: TRows): SpreadsheetCell[] => {
    const result: SpreadsheetCell[] = [];
    const arrOfRows: [string, number | RowData][] = Object.entries(rows);
    for (const [rowIndex, row] of arrOfRows) {
        if (rowIndex !== 'len' && typeof row !== 'number') {
            for (const colIndex in row.cells) {
                if (Object.prototype.hasOwnProperty.call(row.cells, `${colIndex}`)) {
                    const cell = {
                        row: +rowIndex,
                        column: +colIndex,
                        value: row.cells[colIndex].text,
                    };
                    result.push(cell);
                }
            }
        }
    }

    return result;
};

export const getRowDataFromSpreadsheet = (spreadsheet: ISpreadsheetNode | undefined): TRows => {
    if (!spreadsheet) return {};
    const { cells, style } = spreadsheet;
    const result: TRows = {};
    cells.forEach((cell) => {
        result[cell.row] ??= { cells: {} };
        result[cell.row].cells[cell.column] ??= { text: cell.value };
    });
    if (style?.rows)
        style.rows.forEach((style) => {
            if (result[style.index]) {
                result[style.index].height = style.height;
            } else {
                result[style.index] = { cells: {} };
                result[style.index].height = style.height;
            }
        });

    return result;
};

export const getColDataFromSpreadsheet = (spreadsheet: ISpreadsheetNode | undefined): TCols => {
    return (
        spreadsheet?.style?.columns?.reduce((acc, el) => {
            return { ...acc, [el.index]: { width: el.width } };
        }, {}) || {}
    );
};

export const getDataFromSpreadsheet = (spreadsheet: ISpreadsheetNode | undefined): TSpreadsheet => {
    return {
        rows: getRowDataFromSpreadsheet(spreadsheet),
        cols: getColDataFromSpreadsheet(spreadsheet),
    };
};

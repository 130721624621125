import * as React from 'react';
import { GroupDTO } from '../../../../serverapi/api';
import formMessages from '../../../../models/formDefault.messages';
import messages from '../../UserManagement/messages/userManagment.messages';
import { useIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { ALLUSERS_GROUPID } from '../../data/admintool';
import theme from './AssignUserGroupsDialog.scss';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { SearchInput } from '../../../UIKit/components/Select/SearchInput.component';
import { useDispatch, useSelector } from 'react-redux';
import { GroupsSelectors } from '../../../../selectors/groups.selectors';
import { UsersSelectors } from '../../../../selectors/users.selectors';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { saveUsersGroupsChanges } from '../../../../actions/users.actions';
import { ChangeEvent, useEffect, useState } from 'react';
import { fetchAllGroupsRequest } from '../../../../actions/groups.actions';
import { MetaDataSelectors } from '../../../../selectors/admintools.selectors';
import { uniq } from 'lodash-es';
import { TableUIKit } from '../../../UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '../../../UIKit/components/Table/TableUIKit.types';
import { Spin } from 'antd';

export const AssignUserGroupsDialog = (props) => {
    const { userId } = props;

    const intl = useIntl();
    const dispatch = useDispatch();

    const groups: GroupDTO[] = useSelector(GroupsSelectors.getAllGroups);
    const usersGroups: GroupDTO[] = useSelector(UsersSelectors.getUserGroups(userId));
    const serverId: string = useSelector(MetaDataSelectors.getCurrentServerId);
    const isLoadingGroups: boolean = useSelector(GroupsSelectors.getIsLoadingGroups);

    const [selectedRowKeys, setSelectedRowKeys] = useState(
        usersGroups.length ? usersGroups.map((g) => g.id) : [ALLUSERS_GROUPID],
    );
    const [filter, setFilter] = useState<string>('');

    useEffect(() => {
        dispatch(fetchAllGroupsRequest(serverId));
    }, []);

    const onClose = () => {
        dispatch(closeDialog(DialogType.EDIT_ASSIGN_USER_GROUPS));
    };

    const onSubmit = (usersGroups: GroupDTO[]) => {
        dispatch(saveUsersGroupsChanges(usersGroups, userId));
        dispatch(closeDialog(DialogType.EDIT_ASSIGN_USER_GROUPS));
    };

    const handleSubmit = () => {
        if (selectedRowKeys.length) {
            const newGroups: GroupDTO[] = groups.filter((g) => g.id && selectedRowKeys.includes(g.id));
            onSubmit(newGroups);
        }
    };

    const filterHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    };

    const handleCheckUsers = (rows: TTableData[], checked: boolean) => {
        const newGroups: number[] = groups
            .filter((group) => rows.find((row) => row.id === group.id))
            .map((group) => group.id);
        const conditionForALLUsersGroup: boolean =
            rows.length > 1 &&
            !!rows.find((row) => row.id === ALLUSERS_GROUPID) &&
            !checked &&
            !rows.every((row) => row.checked);
        if (checked || conditionForALLUsersGroup) {
            setSelectedRowKeys(uniq([...selectedRowKeys, ...newGroups, ALLUSERS_GROUPID]));
        } else {
            setSelectedRowKeys(
                selectedRowKeys.filter((key) => !rows.find((row) => row.id === key) || key === ALLUSERS_GROUPID),
            );
        }
    };

    const columns: TColumn[] = [
        {
            title: intl.formatMessage(messages.titleCol),
            dataKey: 'name',
            withoutSorter: true,
        },
    ];

    const tableData: TTableData[] = groups
        .filter((group) => group.groupName.toLowerCase().includes(filter.toLowerCase()))
        .map((group) => ({
            id: group.id,
            name: group.groupName,
            checked: !!selectedRowKeys.find((key) => key === group.id) || group.id === ALLUSERS_GROUPID,
            disabled: group.id === ALLUSERS_GROUPID,
        }));

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(formMessages.cancelButton),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(formMessages.choiceButtonLabel),
                    visualStyle: 'primary',
                    dataTest: 'user-managment_add-user-to-group-submite',
                },
            ]}
        />
    );

    return (
        <Dialog
            className={theme.dialog}
            onOk={handleSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.inGroups)}
            open
            width="480px"
            footer={footer}
        >
            <div className={theme.container}>
                <div className={theme.searchContainer}>
                    <SearchInput
                        searchValue={filter}
                        onSearch={filterHandler}
                        showSearch
                        originalTheme
                        allowClear
                        onClear={() => setFilter('')}
                    />
                </div>
                {isLoadingGroups ? (
                    <div className={theme.spinnerContainer}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <div className={theme.tableWrapper}>
                        <TableUIKit columns={columns} tableData={tableData} withCkeckBox checkRows={handleCheckUsers} />
                    </div>
                )}
            </div>
        </Dialog>
    );
};

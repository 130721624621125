import type { TArea, TAreas } from '../FloatingAttributes.types';
import type { StyledAttributeType } from '../../../models/bpm/bpm-model-impl';
import type { AttributeTypeStyle, AttributeTypeStyleFormatEnum, InternationalString } from '../../../serverapi/api';
import type { TFloatingAttributesActions } from '../actions/FloatingAttributes.actions.types';
import type {
    TFloatingAttributesPanelState,
    TReplacementTextStatusMap,
    TRulesHasNotValueStatusMap,
    TStyles,
    TStylesAttribute,
} from './FloatingAttributes.reducer.types';
import { v4 as uuid } from 'uuid';
import {
    FLOATING_ATTRIBUTES_SET_STATE,
    FLOATING_ATTRIBUTES_SWITCH_PRESET_STYLES,
    FLOATING_ATTRIBUTES_SELECT_AREA,
    FLOATING_ATTRIBUTES_ADD_STYLE,
    FLOATING_ATTRIBUTES_SELECT_STYLE,
    FLOATING_ATTRIBUTES_CHANGE_TYPE,
    FLOATING_ATTRIBUTES_REMOVE_STYLE,
    FLOATING_ATTRIBUTES_CHANGE_RULES,
    FLOATING_ATTRIBUTES_CHANGE_FORMAT,
    FLOATING_ATTRIBUTES_CHANGE_TEXT_STYLE,
    FLOATING_ATTRIBUTES_CHANGE_IMAGE_STYLE,
    FLOATING_ATTRIBUTES_CLEAR_STATE,
    FLOATING_ATTRIBUTES_CHANGE_AREA_ATTRIBUTE_WRAP,
} from '../actionsTypes/FloatingAttributes.actionTypes';
import { Areas, StyleFormat } from '../FloatingAttributes.constants';
import BPMMxCellOverlay, { HORIZONTAL_LOCATION, VERTICAL_LOCATION } from '../../../mxgraph/overlays/BPMMxCellOverlay';
import { UNKNOWN_ATTRIBUTE_TYPE } from '../../../utils/consts';
import { isUmlAttribute } from '../../../modules/Uml/UmlEdgeAttributesConst';
import { findHasNotValueRule, getAvailableAttributeTypes, getUpdatedAttributeTypes } from '../FloatingAttributes.utils';
import { compareDiscriminators } from '../../../mxgraph/overlays/BPMMxCellOverlay.utils';
import { LocalesService } from '@/services/LocalesService';

type TReducer<S> = (state: S, action: TFloatingAttributesActions) => S;

const getKey = (a?: string, b?: string) => `${a}_${b}`;

const getEmptyAreas = (disabledAreas: string[] = []): TAreas => {
    const areas = {} as TAreas;

    VERTICAL_LOCATION.forEach((vName) =>
        HORIZONTAL_LOCATION.forEach((hName) => {
            const key = getKey(vName, hName);

            const area: TArea = {
                attributes: [],
                filled: false,
                attributeWrap: false,
                disabled: disabledAreas.includes(key),
            };

            areas[key] = area;
        }),
    );

    return areas;
};

const mapAttributeTypesToComponentInnerData = (
    attributes: StyledAttributeType[] = [],
    disabledAreas: string[] = [],
): {
    areas: TAreas;
    styles: TStyles;
    stylesAttribute: TStylesAttribute;
    replacementTextStatusMap: TReplacementTextStatusMap;
    rulesHasNotValueStatusMap: TRulesHasNotValueStatusMap;
} => {
    const areas = getEmptyAreas(disabledAreas);
    const styles = {};
    const stylesAttribute = {};
    const replacementTextStatusMap = {};
    const rulesHasNotValueStatusMap = {};

    attributes.forEach((attr: StyledAttributeType) => {
        attr.styles?.forEach((style) => {
            const key = getKey(style?.v, style?.h);

            if (!areas[key]) return;

            const styleId = style?.id || uuid();
            const replacementText = style?.replacementText || {} as InternationalString;

            styles[styleId] = style;
            stylesAttribute[styleId] = attr;
            replacementTextStatusMap[styleId] = LocalesService.areAnyFieldFilled(replacementText);
            rulesHasNotValueStatusMap[styleId] = findHasNotValueRule(style.rules);

            const area: TArea = areas[key];
            area.filled = true;
            area.attributes.push(attr);
            area.attributeWrap = style.attributeWrap || area.attributeWrap;
        });
    });

    return { areas, styles, stylesAttribute, replacementTextStatusMap, rulesHasNotValueStatusMap };
};

const getAreaStyles = (selectedAreaKey: string, styles: TStyles) => {
    const [v, h] = selectedAreaKey.split('_') || ['left', 'bottom'];

    return Object.keys(styles).reduce<string[]>((acc, key) => {
        if (styles[key].v === v && styles[key].h === h) acc.push(key);

        return acc;
    }, []);
};

const getDefaultStyle = (selectedAreaKey: string | null): AttributeTypeStyle => {
    const [v, h]: string[] = typeof selectedAreaKey === 'string' ? selectedAreaKey.split('_') : ['left', 'top'];

    return {
        id: uuid(),
        v,
        h,
        x: 0,
        y: 0,
        style: BPMMxCellOverlay.defaultTextStyle,
        imageId: '',
        format: StyleFormat.TEXT as AttributeTypeStyleFormatEnum,
        rules: [],
        attributeWrap: false,
    };
};

export const floatingAttributesInitialState: TFloatingAttributesPanelState = {
    areas: getEmptyAreas(),
    availableAttributeTypes: [],
    inited: false,
    selectedAreaKey: Areas.CENTER_MIDDLE,
    selectedAreaStyles: [],
    selectedStyle: null,
    styles: {},
    stylesAttribute: {},
    presetId: undefined,
    replacementTextStatusMap: {},
    rulesHasNotValueStatusMap: {},
    useUniqueStyledAttributeTypes: false,
};

export const floatingAttributesPanelReducer: TReducer<TFloatingAttributesPanelState> = (
    state = floatingAttributesInitialState,
    action,
): TFloatingAttributesPanelState => {
    switch (action.type) {
        case FLOATING_ATTRIBUTES_SET_STATE: {
            const {
                ignorePresetStyles,
                useUniqueStyledAttributeTypes = false,
                disabledAreas,
                presetId,
            } = action.payload;
            const styledAttributeTypes = action.payload.styledAttributeTypes;
            const readOnlyAttributeTypes = action.payload.readOnlyAttributeTypes || [];
            const readOnlyAttributeStylesWithFlag = ignorePresetStyles
                ? []
                : readOnlyAttributeTypes?.map((a) => ({ ...a, readOnly: true }));
            const availableAttributeTypes = styledAttributeTypes.filter(
                (a) => a.id !== UNKNOWN_ATTRIBUTE_TYPE.id && !isUmlAttribute(a.id),
            );
            const updatedEditableAttributeTypes = getUpdatedAttributeTypes(
                availableAttributeTypes,
                useUniqueStyledAttributeTypes,
                state.styles,
                state.stylesAttribute,
                state.replacementTextStatusMap,
                state.rulesHasNotValueStatusMap,
                true,
            );
            const { areas } = mapAttributeTypesToComponentInnerData(
                [...readOnlyAttributeStylesWithFlag, ...updatedEditableAttributeTypes],
                disabledAreas,
            );
            const { styles, stylesAttribute, replacementTextStatusMap, rulesHasNotValueStatusMap } =
                mapAttributeTypesToComponentInnerData(updatedEditableAttributeTypes);
            const selectedAreaStyles = getAreaStyles(state.selectedAreaKey || '', styles);
            const selectedStyle = selectedAreaStyles[0] || null;

            return {
                ...state,
                areas,
                availableAttributeTypes: updatedEditableAttributeTypes,
                inited: true,
                styles,
                stylesAttribute,
                selectedAreaStyles,
                selectedStyle,
                presetId,
                replacementTextStatusMap,
                rulesHasNotValueStatusMap,
                useUniqueStyledAttributeTypes: !!useUniqueStyledAttributeTypes,
            };
        }

        case FLOATING_ATTRIBUTES_SWITCH_PRESET_STYLES: {
            const { ignorePresetStyles, useUniqueStyledAttributeTypes = false, disabledAreas } = action.payload;
            const styledAttributeTypes = action.payload.styledAttributeTypes;
            const readOnlyAttributeTypes = action.payload.readOnlyAttributeTypes || [];
            const readOnlyAttributeStylesWithFlag = ignorePresetStyles
                ? []
                : readOnlyAttributeTypes?.map((a) => ({ ...a, readOnly: true }));
            const availableAttributeTypes = styledAttributeTypes.filter(
                (a) => a.id !== UNKNOWN_ATTRIBUTE_TYPE.id && !isUmlAttribute(a.id),
            );
            const updatedEditableAttributeTypes = getUpdatedAttributeTypes(
                availableAttributeTypes,
                useUniqueStyledAttributeTypes,
                state.styles,
                state.stylesAttribute,
                state.replacementTextStatusMap,
                state.rulesHasNotValueStatusMap,
                false,
            );
            const { areas } = mapAttributeTypesToComponentInnerData(
                [...readOnlyAttributeStylesWithFlag, ...updatedEditableAttributeTypes],
                disabledAreas,
            );

            return {
                ...state,
                areas,
            };
        }

        case FLOATING_ATTRIBUTES_SELECT_AREA: {
            if (action.payload === state.selectedAreaKey) return state;

            const selectedAreaStyles = getAreaStyles(action.payload, state.styles);
            const selectedStyle = selectedAreaStyles[0] || null;

            return {
                ...state,
                selectedAreaKey: action.payload,
                selectedAreaStyles,
                selectedStyle,
            };
        }

        case FLOATING_ATTRIBUTES_ADD_STYLE: {
            const availableAttributeTypes = getAvailableAttributeTypes(state);

            if (!availableAttributeTypes.length) return state;

            const newStyleAttribute: StyledAttributeType | undefined = availableAttributeTypes[0];
            const newStyle = getDefaultStyle(state.selectedAreaKey);

            const selectedArea = state.areas[state.selectedAreaKey];
            const updatedSelectedArea = {
                ...selectedArea,
                filled: true,
                attributes: [...selectedArea.attributes, newStyleAttribute],
            };

            return {
                ...state,
                areas: { ...state.areas, [state.selectedAreaKey]: updatedSelectedArea },
                styles: {
                    ...state.styles,
                    [newStyle.id as string]: newStyle,
                },
                stylesAttribute: {
                    ...state.stylesAttribute,
                    [newStyle.id as string]: newStyleAttribute,
                },
                selectedAreaStyles: [...state.selectedAreaStyles, newStyle.id as string],
                selectedStyle: newStyle.id as string,
                replacementTextStatusMap: {
                    ...state.replacementTextStatusMap,
                    [newStyle.id as string]: false,
                },
            };
        }

        case FLOATING_ATTRIBUTES_SELECT_STYLE: {
            if (action.payload === state.selectedStyle) return state;

            const selectedStyle = state.selectedAreaStyles?.find((style) => style === action.payload);

            if (!selectedStyle) return state;

            return {
                ...state,
                selectedStyle,
            };
        }

        case FLOATING_ATTRIBUTES_CHANGE_TYPE: {
            if (!state.selectedStyle) return state;
            const { attributeId, attributeDiscriminator } = action.payload;
            const newSelectedStyleAttribute: StyledAttributeType | undefined = state.availableAttributeTypes.find(
                (a) => a.id === attributeId && compareDiscriminators(a.attributeDiscriminator, attributeDiscriminator),
            );

            if (!newSelectedStyleAttribute) return state;

            const selectedArea: TArea = state.areas[state.selectedAreaKey];
            const selectedAttributeId = state.stylesAttribute[state.selectedStyle].id;
            const selectedAttributeIndex = selectedArea.attributes.findIndex(
                (el) => !el.readOnly && el.id === selectedAttributeId,
            );
            const updatedAttributes = [
                ...selectedArea.attributes?.slice(0, selectedAttributeIndex),
                newSelectedStyleAttribute,
                ...selectedArea.attributes?.slice(selectedAttributeIndex + 1),
            ];
            const updatedStyle = { ...state.styles[state.selectedStyle], rules: [] };
            const updatedSelectedArea = {
                ...selectedArea,
                attributes: updatedAttributes,
            };

            return {
                ...state,
                areas: { ...state.areas, [state.selectedAreaKey]: updatedSelectedArea },
                styles: {
                    ...state.styles,
                    [updatedStyle.id as string]: updatedStyle,
                },
                stylesAttribute: {
                    ...state.stylesAttribute,
                    [state.selectedStyle]: newSelectedStyleAttribute,
                },
                rulesHasNotValueStatusMap: {
                    ...state.rulesHasNotValueStatusMap,
                    [state.selectedStyle]: false,
                },
            };
        }

        case FLOATING_ATTRIBUTES_REMOVE_STYLE: {
            if (!state.selectedStyle) return state;

            const updatedStyles = { ...state.styles };
            const updatedStylesAttribute = { ...state.stylesAttribute };
            const updatedReplacementTextStatusMap = { ...state.replacementTextStatusMap };
            const updatedSelectedAreaStyles = state.selectedAreaStyles.filter((el) => el !== state.selectedStyle);
            const updatedSelectedStyle = updatedSelectedAreaStyles[0] || null;

            delete updatedStyles[state.selectedStyle];
            delete updatedStylesAttribute[state.selectedStyle];
            delete updatedReplacementTextStatusMap[state.selectedStyle];

            const selectedArea: TArea = state.areas[state.selectedAreaKey];
            const selectedAttributeId = state.stylesAttribute[state.selectedStyle].id;
            const selectedAttributeIndex = selectedArea.attributes.findIndex(
                (el) => !el.readOnly && el.id === selectedAttributeId,
            );
            const updatedAttributes = [
                ...selectedArea.attributes?.slice(0, selectedAttributeIndex),
                ...selectedArea.attributes?.slice(selectedAttributeIndex + 1),
            ];
            const updatedSelectedArea = {
                ...selectedArea,
                filled: !!updatedAttributes.length,
                attributes: updatedAttributes,
            };

            return {
                ...state,
                areas: { ...state.areas, [state.selectedAreaKey]: updatedSelectedArea },
                styles: updatedStyles,
                stylesAttribute: updatedStylesAttribute,
                selectedAreaStyles: updatedSelectedAreaStyles,
                selectedStyle: updatedSelectedStyle,
                replacementTextStatusMap: updatedReplacementTextStatusMap,
            };
        }

        case FLOATING_ATTRIBUTES_CHANGE_RULES: {
            if (!state.selectedStyle) return state;

            const updatedStyle = { ...state.styles[state.selectedStyle], rules: action.payload };
            const isHasNotValueRule = findHasNotValueRule(action.payload);

            return {
                ...state,
                styles: {
                    ...state.styles,
                    [updatedStyle.id as string]: updatedStyle,
                },
                rulesHasNotValueStatusMap: {
                    ...state.rulesHasNotValueStatusMap,
                    [updatedStyle.id as string]: isHasNotValueRule,
                },
            };
        }

        case FLOATING_ATTRIBUTES_CHANGE_FORMAT: {
            if (!state.selectedStyle) return state;

            const selectedStyle = state.styles[state.selectedStyle];

            if (selectedStyle.format === action.payload) return state;

            const updatedStyle: AttributeTypeStyle = {
                ...state.styles[state.selectedStyle],
                format: action.payload,
                style:
                    (action.payload === StyleFormat.TEXT && BPMMxCellOverlay.defaultTextStyle) ||
                    (action.payload === StyleFormat.IMAGE &&
                        BPMMxCellOverlay.stringifyImageStyle(BPMMxCellOverlay.getDefaultImageStyle(selectedStyle))) ||
                    '',
            };

            return {
                ...state,
                styles: {
                    ...state.styles,
                    [updatedStyle.id as string]: updatedStyle,
                },
            };
        }

        case FLOATING_ATTRIBUTES_CHANGE_TEXT_STYLE: {
            if (!state.selectedStyle) return state;

            const { style, replacementText, useReplacementText } = action.payload;

            const selectedStyle = state.styles[state.selectedStyle];

            if (
                selectedStyle.format !== StyleFormat.TEXT ||
                (selectedStyle.style === style &&
                    selectedStyle.replacementText === replacementText &&
                    state.replacementTextStatusMap[state.selectedStyle] === useReplacementText)
            )
                return state;

            const updatedStyle = {
                ...selectedStyle,
                style: style || selectedStyle.style,
                replacementText: replacementText || selectedStyle.replacementText,
            };

            return {
                ...state,
                styles: {
                    ...state.styles,
                    [updatedStyle.id as string]: updatedStyle,
                },
                replacementTextStatusMap: {
                    ...state.replacementTextStatusMap,
                    [updatedStyle.id as string]:
                        typeof useReplacementText === 'boolean'
                            ? useReplacementText
                            : state.replacementTextStatusMap[state.selectedStyle],
                },
            };
        }

        case FLOATING_ATTRIBUTES_CHANGE_IMAGE_STYLE: {
            if (!state.selectedStyle) return state;

            const { style, imageId } = action.payload;

            const selectedStyle = state.styles[state.selectedStyle];

            if (
                selectedStyle.format !== StyleFormat.IMAGE ||
                (selectedStyle.style === style && selectedStyle.imageId === imageId)
            )
                return state;

            const updatedStyle = {
                ...selectedStyle,
                style: style || selectedStyle.style,
                imageId: imageId || selectedStyle.imageId,
            };

            return {
                ...state,
                styles: {
                    ...state.styles,
                    [updatedStyle.id as string]: updatedStyle,
                },
            };
        }

        case FLOATING_ATTRIBUTES_CLEAR_STATE: {
            return floatingAttributesInitialState;
        }

        case FLOATING_ATTRIBUTES_CHANGE_AREA_ATTRIBUTE_WRAP: {
            const { attributeWrap } = action.payload;
            const { areas, selectedAreaKey, selectedAreaStyles } = state;

            const updatedAreas: TAreas = {
                ...areas,
                [selectedAreaKey]: {
                    ...areas[selectedAreaKey],
                    attributeWrap,
                },
            };

            const updatedStyles = { ...state.styles };

            selectedAreaStyles.forEach((styleId) => {
                updatedStyles[styleId].attributeWrap = attributeWrap;
            });

            return {
                ...state,
                styles: updatedStyles,
                areas: updatedAreas,
            };
        }

        default:
            return state;
    }
};

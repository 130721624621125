import React, { FC } from 'react';
import { Col } from 'antd';
import { useIntl } from 'react-intl';
import icDashboardTable from '../../../../resources/icons/dashboard_table.svg';
import { openDialog } from '@/actions/dialogs.actions';
import { useDispatch, useSelector } from 'react-redux';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import messages from '../WidgetSettingsPanel.messages';
import theme from '../WidgetSettingsPanel.scss';
import { NodeId } from '@/serverapi/api';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { SpreadsheetSelectors } from '@/selectors/entities/spreadsheet.selectors';

type TSourceTableSelectProps = {
    nodeId?: NodeId;
    tableNodeId?: NodeId;
    widgetId?: string;
};

export const SourceTableSelect: FC<TSourceTableSelectProps> = ({ nodeId, tableNodeId, widgetId }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const tableName: string | undefined = useSelector(SpreadsheetSelectors.getSpreadsheetNameByNodeId(tableNodeId));

    const selectTableHandler = () => {
        if (nodeId && widgetId)
            dispatch(
                openDialog(DialogType.DASHBOARD_SELECT_TABLE_DIALOG, {
                    nodeId,
                    widgetId,
                }),
            );
    };

    return (
        <Col className={theme.inputContainer}>
            <div className={theme.label}>{intl.formatMessage(messages.dataTable)}</div>
            <div className={theme.sourceTableSelect}>
                {tableName || intl.formatMessage(messages.selectDataTable)}
                <Button
                    onClick={() => selectTableHandler()}
                    icon={icDashboardTable}
                    visualStyle="text"
                    dataTest="dashboard_widget-settings-panel_input-data-table"
                />
            </div>
        </Col>
    );
};

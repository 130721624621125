import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'SettingsSidePanel.title',
        defaultMessage: 'Настройки',
    },
    ID: {
        id: 'SettingsSidePanel.ID',
        defaultMessage: 'ID',
    },
    templateName: {
        id: 'SettingsSidePanel.templateName',
        defaultMessage: 'Название шаблона',
    },
    description: {
        id: 'SettingsSidePanel.description',
        defaultMessage: 'Описание',
    },
    namePlaceholder: {
        id: 'SettingsSidePanel.namePlaceholder',
        defaultMessage: 'Введите имя',
    },
    descriptionPlaceholder: {
        id: 'SettingsSidePanel.descriptionPlaceholder',
        defaultMessage: 'Введите описание',
    },
    tab: {
        id: 'SettingsSidePanel.tab',
        defaultMessage: 'Вкладка',
    },
    NONE: {
        id: 'SettingsSidePanel.NONE',
        defaultMessage: 'Нет',
    },
    HORIZONTAL: {
        id: 'SettingsSidePanel.HORIZONTAL',
        defaultMessage: 'Горизонтальная',
    },
    VERTICAL: {
        id: 'SettingsSidePanel.VERTICAL',
        defaultMessage: 'Вертикальная',
    },
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResizableToolbar__toolbarContainer__m4LfQ{display:-webkit-box;display:-ms-flexbox;display:flex;height:40px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;border-bottom:1px solid var(--SecondaryNeutral200);padding:6px 8px}.ResizableToolbar__mainButtons__WItCD{display:-webkit-box;display:-ms-flexbox;display:flex;overflow:hidden}.ResizableToolbar__additionalButtons__KjeCB{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.ResizableToolbar__hiddenGroup___HuKd,.ResizableToolbar__hiddenToolbar__WVmyx{opacity:0;pointer-events:none}.ResizableToolbar__dividerContainer__jksgY{display:-webkit-box;display:-ms-flexbox;display:flex}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Toolbar/ResizableToolbar/ResizableToolbar.scss"],"names":[],"mappings":"AAAA,2CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,WAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,kDAAA,CACA,eAAA,CAGJ,sCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,eAAA,CAGJ,4CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAGJ,8EAEI,SAAA,CACA,mBAAA,CAEJ,2CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA","sourcesContent":[".toolbarContainer {\r\n    display: flex;\r\n    height: 40px;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    border-bottom: 1px solid var(--SecondaryNeutral200);\r\n    padding: 6px 8px;\r\n}\r\n\r\n.mainButtons {\r\n    display: flex;\r\n    overflow: hidden;\r\n}\r\n\r\n.additionalButtons {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.hiddenGroup,\r\n.hiddenToolbar {\r\n    opacity: 0;\r\n    pointer-events: none;\r\n}\r\n.dividerContainer {\r\n    display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarContainer": "ResizableToolbar__toolbarContainer__m4LfQ",
	"mainButtons": "ResizableToolbar__mainButtons__WItCD",
	"additionalButtons": "ResizableToolbar__additionalButtons__KjeCB",
	"hiddenGroup": "ResizableToolbar__hiddenGroup___HuKd",
	"hiddenToolbar": "ResizableToolbar__hiddenToolbar__WVmyx",
	"dividerContainer": "ResizableToolbar__dividerContainer__jksgY"
};
export default ___CSS_LOADER_EXPORT___;

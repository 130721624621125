/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { changeWidgetGeometry, selectWidget } from '@/actions/dashboard.actions';
import { TDashboardCellsCount } from '@/modules/Dashboard/Dashboard.types';
import { NodeId, WidgetGeometry } from '@/serverapi/api';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import theme from './DashboardWidgetContainer.scss';
import { SpreadsheetSelectors } from '@/selectors/entities/spreadsheet.selectors';
import { ISpreadsheetNode } from '@/models/bpm/bpm-model-impl.types';
import { initChartData, widgetComponents } from '../widgets/widgetsMapper';
import { SummaryOperations, TWidgetSource, TDashboardWidgets, TDataMappers } from '../widgets/widget.types';
import {
    calcWidgetPosition,
    calcWidgetSize,
    calcWidgetGeometry,
    getDashboardSelectorKey,
    checkWikiEditorClick,
} from '../dashboardUtils';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { Resizers } from './Resizers.component';
import { LocalesService } from '@/services/LocalesService';

type TDashboardWidget = {
    widget: TDashboardWidgets;
    cellSize: number;
    nodeId: NodeId;
    disable: boolean;
    cellsCount: TDashboardCellsCount;
    selected: boolean;
    setIsWidgetResizing: (isResizing: boolean) => void;
    setIsMouseDownOnWidget: (isMouseDownOnWidget: boolean) => void;
};

export const DashboardWidgetContainer: FC<TDashboardWidget> = (props) => {
    const {
        disable,
        cellSize,
        nodeId,
        selected,
        cellsCount: { horizontalCount, verticalCount },
        widget,
        setIsWidgetResizing,
        setIsMouseDownOnWidget,
    } = props;

    const {
        id,
        type,
        name,
        geometry: { width = 1, x = 1, y = 1, height = 1 },
    } = widget;

    const source: TWidgetSource | undefined = 'source' in widget ? widget.source : undefined;
    const summaryOperation: SummaryOperations | undefined =
        'summaryOperation' in widget ? widget.summaryOperation : undefined;

    const [hover, setHover] = useState<boolean>(false);
    const [widgetWidth, setWidgetWidth] = useState<number>(cellSize * width);
    const [widgetHeight, setWidgetHeight] = useState<number>(cellSize * height);
    const [widgetX, setWidgetX] = useState<number>(cellSize * x);
    const [widgetY, setWidgetY] = useState<number>(cellSize * y);
    const [data, setData] = useState<TDataMappers>(initChartData);

    const sizeParams = { width, height, verticalCount, horizontalCount, cellSize };
    const posParams = { x, y, verticalCount, horizontalCount, cellSize };

    const widgetRef = useRef<HTMLDivElement>(null);

    const serverId: string = useSelector(ServerSelectors.serverId);
    const spreadsheet: ISpreadsheetNode | undefined = useSelector(
        SpreadsheetSelectors.byId(source?.id && { ...source.id, serverId }),
    );

    const { widgetComponent: WidgetComponent, dataMapper } = widgetComponents[type];

    const dispatch = useDispatch();

    useEffect(() => {
        if (spreadsheet?.cells && source) {
            const sourceData = dataMapper(spreadsheet.cells, source, summaryOperation);
            setData(sourceData);
        } else {
            setData(initChartData);
        }
    }, [spreadsheet, source, summaryOperation]);

    useEffect(() => {
        setWidgetX(cellSize * x);
        setWidgetY(cellSize * y);
        setWidgetWidth(cellSize * width);
        setWidgetHeight(cellSize * height);
    }, [cellSize, x, y, width, height]);

    useEffect(() => {
        updateGeometry();
    }, [horizontalCount, verticalCount]);

    const updateGeometry = () => {
        const widgetContainer = widgetRef.current;
        if (widgetContainer) {
            const newWidgetGeometry: WidgetGeometry = calcWidgetGeometry(
                widgetContainer,
                cellSize,
                verticalCount,
                horizontalCount,
            );

            dispatch(changeWidgetGeometry(nodeId, id, newWidgetGeometry));
        }
    };

    const dndHandler = (dndStartEvent: React.MouseEvent<HTMLElement | HTMLElement, MouseEvent>) => {
        setIsMouseDownOnWidget(true);
        const target = dndStartEvent.target as HTMLElement;

        if (checkWikiEditorClick(target)) return;

        const widgetContainer = widgetRef.current;
        if (!widgetContainer) return;
        if (!disable) {
            let currentDroppable: Element | null = null;
            let droppableBelow: Element | null = null;
            let canBeDropped: boolean = false;

            const onMouseMove = (dndMoveEvent: MouseEvent) => {
                const shiftX = dndMoveEvent.clientX - dndStartEvent.clientX;
                const shiftY = dndMoveEvent.clientY - dndStartEvent.clientY;

                if (canBeDropped && droppableBelow) {
                    setWidgetX(calcWidgetPosition(widgetX + shiftX, true, sizeParams));
                    setWidgetY(calcWidgetPosition(widgetY + shiftY, false, sizeParams));
                }

                widgetContainer.hidden = true;
                const elemBelow = document.elementFromPoint(dndMoveEvent.clientX, dndMoveEvent.clientY);
                widgetContainer.hidden = false;

                if (!elemBelow) return;

                droppableBelow = elemBelow.closest(`#${getDashboardSelectorKey(nodeId)}`);

                if (currentDroppable !== droppableBelow) {
                    if (currentDroppable) {
                        canBeDropped = false;
                    }
                    currentDroppable = droppableBelow;
                    if (currentDroppable) {
                        canBeDropped = true;
                    }
                }
            };

            const mouseUpHandler = () => {
                if (canBeDropped && droppableBelow) {
                    updateGeometry();
                }
                document.removeEventListener('mousemove', onMouseMove);
            };

            if ((dndStartEvent.target as HTMLElement).id !== 'sizer') {
                document.addEventListener('mousemove', onMouseMove);
                document.addEventListener('mouseup', mouseUpHandler, { once: true });
            }
        } else {
            widgetContainer.onmousedown = () => {
                return false;
            };
        }
    };

    const resizeHandle = (resizerClickEvent: React.MouseEvent<HTMLDivElement, MouseEvent>, sizerId: number) => {
        setIsWidgetResizing(true);
        const onMouseMove = (resizerMoveEvent: MouseEvent) => {
            const shiftX = resizerMoveEvent.clientX - resizerClickEvent.clientX;
            const shiftY = resizerMoveEvent.clientY - resizerClickEvent.clientY;
            const checkX = parseFloat(widgetRef.current?.style.left || '0') !== 0;
            const checkY = parseFloat(widgetRef.current?.style.top || '0') !== 0;
            const checkWidth = parseFloat(widgetRef.current?.style.width || '0') !== cellSize;
            const checkHeight = parseFloat(widgetRef.current?.style.height || '0') !== cellSize;

            switch (sizerId) {
                case 1: {
                    checkWidth && setWidgetX(calcWidgetPosition(widgetX + shiftX, true, sizeParams));
                    checkHeight && setWidgetY(calcWidgetPosition(widgetY + shiftY, false, sizeParams));
                    checkX && setWidgetWidth(calcWidgetSize(widgetWidth - shiftX, true, posParams));
                    checkY && setWidgetHeight(calcWidgetSize(widgetHeight - shiftY, false, posParams));
                    break;
                }
                case 2:
                    checkHeight && setWidgetY(calcWidgetPosition(widgetY + shiftY, false, sizeParams));
                    setWidgetWidth(calcWidgetSize(widgetWidth + shiftX, true, posParams));
                    checkY && setWidgetHeight(calcWidgetSize(widgetHeight - shiftY, false, posParams));
                    break;
                case 3:
                    checkWidth && setWidgetX(calcWidgetPosition(widgetX + shiftX, true, sizeParams));
                    checkX && setWidgetWidth(calcWidgetSize(widgetWidth - shiftX, true, posParams));
                    setWidgetHeight(calcWidgetSize(widgetHeight + shiftY, false, posParams));
                    break;
                case 4:
                    setWidgetWidth(calcWidgetSize(widgetWidth + shiftX, true, posParams));
                    setWidgetHeight(calcWidgetSize(widgetHeight + shiftY, false, posParams));
                    break;
                default:
                    break;
            }
        };

        const mouseUpHandler = () => {
            updateGeometry();
            document.removeEventListener('mousemove', onMouseMove);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', mouseUpHandler, { once: true });
    };

    const onClickHandler = () => {
        if (!disable && !selected) {
            dispatch(selectWidget(nodeId, id));
        }
        setIsMouseDownOnWidget(false);
    };

    const showSizers = (hover || selected) && !disable;

    return (
        <div
            ref={widgetRef}
            className={cx(theme.dashboardWidget, showSizers && theme.dashboardWidgetHover)}
            style={{ width: widgetWidth - 0.5, height: widgetHeight - 0.5, left: widgetX + 0.5, top: widgetY + 0.5 }}
            onMouseOver={() => !disable && setHover(true)}
            onMouseOut={() => !disable && setHover(false)}
            onMouseDown={dndHandler}
            onDragStart={() => false}
            onClick={onClickHandler}
            data-test="dashboard-editor_container_widget"
        >
            {WidgetComponent && (
                <WidgetComponent
                    selected={selected}
                    widget={widget}
                    data={data}
                    title={LocalesService.internationalStringToString(name)}
                    dashboardNodeId={nodeId}
                />
            )}
            {showSizers && <Resizers resizeHandle={resizeHandle} />}
        </div>
    );
};

import {
    AttributeTypeApi,
    AttributeTypeGroupApi,
    AuditApi,
    AuthorizationApi,
    CommentApi,
    EdgeTypeApi,
    EdgeTypeGroupApi,
    ErrorLoggerApi,
    FileApi,
    FileTmpStorageApi,
    GroupsApi,
    IAttributeTypeApi,
    IAttributeTypeGroupApi,
    IAuditApi,
    IAuthorizationApi,
    ICommentApi,
    IEdgeTypeApi,
    IEdgeTypeGroupApi,
    IErrorLoggerApi,
    IFileApi,
    IFileTmpStorageApi,
    IGroupsApi,
    ILicenseApi,
    IMatrixApi,
    IModelApi,
    IModelTypeApi,
    IModelTypeGroupApi,
    IObjectsApi,
    IObjectTypeApi,
    IObjectTypeGroupApi,
    IPresetApi,
    IPrincipalsApi,
    IPrincipalSearchApi,
    IProfileApi,
    IServerProfileApi,
    IRepositoryApi,
    ISchedulerApi,
    IScriptApi,
    ISearchApi,
    ISecurityManagementApi,
    ISimulationApi,
    ISymbolEditorApi,
    ISymbolsApi,
    ISystemPropertyApi,
    ITreeApi,
    ITreeNodeTypeApi,
    IUsersApi,
    IVersionApi,
    IVisioApi,
    IWikiApi,
    IWikiTypeApi,
    ITransferBpmnApi,
    IArisApi,
    LicenseApi,
    MatrixApi,
    ModelApi,
    ModelTypeApi,
    ModelTypeGroupApi,
    ObjectsApi,
    ObjectTypeApi,
    ObjectTypeGroupApi,
    PresetApi,
    PrincipalsApi,
    PrincipalSearchApi,
    ProfileApi,
    ServerProfileApi,
    RepositoryApi,
    SchedulerApi,
    ScriptApi,
    SearchApi,
    SecurityManagementApi,
    SimulationApi,
    SymbolEditorApi,
    SymbolsApi,
    SystemPropertyApi,
    TransferApi,
    TreeApi,
    TreeNodeTypeApi,
    UsersApi,
    VersionApi,
    VisioApi,
    WikiApi,
    WikiTypeApi,
    TransferBpmnApi,
    ArisApi,
    SpreadsheetApi,
    ISpreadsheetApi,
    FolderTypeApi,
    IFolderTypeApi,
    AgreementApi,
    IAgreementApi,
    IPresetImageApi,
    PresetImageApi,
    IPresetImageGroupApi,
    PresetImageGroupApi,
    KanbanApi,
    IKanbanApi,
    KanbanBoardTypeApi,
    IKanbanBoardTypeApi,
    KanbanCardTypeApi,
    IKanbanCardTypeApi,
    EventApi,
    IEventApi,
    EdgesApi,
    IEdgesApi,
    ITreeNotationApi,
    TreeNotationApi,
    IApprovalApi,
    ApprovalApi,
    IDashboardApi,
    DashboardApi,
    ISessionsApi,
    SessionsApi,
    FavoritesApi,
    IFavoritesApi,
    INotificationTemplateApi,
    NotificationTemplateApi,
    IMessagesApi,
    MessagesApi,
    INotificationApi,
    NotificationApi,
    TreeActionsApi,
    ApprovalTemplateApi,
    IApprovalTemplateApi,
    IOperationApi,
    OperationApi,
    AttributeQueryTypeApi,
    LockApi,
    PersonalApi,
    IScriptExecutionApi,
    ScriptExecutionApi,
    IMatrixTypeApi,
    MatrixTypeApi,
    AipApi,
    ScriptOperationApi,
    IScriptOperationApi,
    MergeApi,
    IModelTemplateApi,
    ModelTemplateApi,
    ReportTypeApi,
    IReportTypeApi,
    IReportApi,
    ReportApi,
    IUITemplateApi,
    UITemplateApi,
} from '../../serverapi/api';

export const TMP_DOWNLOAD_PATH = 'api/file-tmp-storage';
export const FILE_DOWNLOAD_PATH = 'api/file/download';

export class ApiBundle {
    public repository: IRepositoryApi = new RepositoryApi(fetch, this.url);
    public tree: ITreeApi = new TreeApi(fetch, this.url);
    public treeActions: TreeActionsApi = new TreeActionsApi(fetch, this.url);
    public treeNodeType: ITreeNodeTypeApi = new TreeNodeTypeApi(fetch, this.url);
    public treeNotation: ITreeNotationApi = new TreeNotationApi(fetch, this.url);
    public model: IModelApi = new ModelApi(fetch, this.url);
    public dashboard: IDashboardApi = new DashboardApi(fetch, this.url);
    public modelType: IModelTypeApi = new ModelTypeApi(fetch, this.url);
    public modelTypeGroup: IModelTypeGroupApi = new ModelTypeGroupApi(fetch, this.url);
    public objectTypeGroup: IObjectTypeGroupApi = new ObjectTypeGroupApi(fetch, this.url);
    public edgeTypeGroup: IEdgeTypeGroupApi = new EdgeTypeGroupApi(fetch, this.url);
    public attributeTypeGroup: IAttributeTypeGroupApi = new AttributeTypeGroupApi(fetch, this.url);
    public objectType: IObjectTypeApi = new ObjectTypeApi(fetch, this.url);
    public objectsDefinition: IObjectsApi = new ObjectsApi(fetch, this.url);
    public attributeType: IAttributeTypeApi = new AttributeTypeApi(fetch, this.url);
    public transfer: TransferApi = new TransferApi(fetch, this.url);
    public transferBpmn: ITransferBpmnApi = new TransferBpmnApi(fetch, this.url);
    public securityManagement: ISecurityManagementApi = new SecurityManagementApi(fetch, this.url);
    public principalSearch: IPrincipalSearchApi = new PrincipalSearchApi(fetch, this.url);
    public script: IScriptApi = new ScriptApi(fetch, this.url);
    public scriptOperation: IScriptOperationApi = new ScriptOperationApi(fetch, this.url);
    public scriptExecutor: IScriptExecutionApi = new ScriptExecutionApi(fetch, this.url);
    public wiki: IWikiApi = new WikiApi(fetch, this.url);
    public wikiType: IWikiTypeApi = new WikiTypeApi(fetch, this.url);
    public comment: ICommentApi = new CommentApi(fetch, this.url);
    public matrix: IMatrixApi = new MatrixApi(fetch, this.url);
    public report: IReportApi = new ReportApi(fetch, this.url);
    public symbol: ISymbolsApi = new SymbolsApi(fetch, this.url);
    public license: ILicenseApi = new LicenseApi(fetch, this.url);
    public user: IUsersApi = new UsersApi(fetch, this.url);
    public session: ISessionsApi = new SessionsApi(fetch, this.url);
    public group: IGroupsApi = new GroupsApi(fetch, this.url);
    public version: IVersionApi = new VersionApi(fetch, this.url);
    public principal: IPrincipalsApi = new PrincipalsApi(fetch, this.url);
    public preset: IPresetApi = new PresetApi(fetch, this.url);
    public symbolEditor: ISymbolEditorApi = new SymbolEditorApi(fetch, this.url);
    public errorLogger: IErrorLoggerApi = new ErrorLoggerApi(fetch, this.url);
    public systemProperty: ISystemPropertyApi = new SystemPropertyApi(fetch, this.url);
    public audit: IAuditApi = new AuditApi(fetch, this.url);
    public edgeType: IEdgeTypeApi = new EdgeTypeApi(fetch, this.url);
    public authorization: IAuthorizationApi = new AuthorizationApi(fetch, this.url);
    public search: ISearchApi = new SearchApi(fetch, this.url);
    public simulation: ISimulationApi = new SimulationApi(fetch, this.url);
    public visio: IVisioApi = new VisioApi(fetch, this.url);
    public file: IFileApi = new FileApi(fetch, this.url);
    public fileTmpStorage: IFileTmpStorageApi = new FileTmpStorageApi(fetch, this.url);
    public profile: IProfileApi = new ProfileApi(fetch, this.url);
    public scheduler: ISchedulerApi = new SchedulerApi(fetch, this.url);
    public serverProfile: IServerProfileApi = new ServerProfileApi(fetch, this.url);
    public aris: IArisApi = new ArisApi(fetch, this.url);
    public spreadsheet: ISpreadsheetApi = new SpreadsheetApi(fetch, this.url);
    public folderType: IFolderTypeApi = new FolderTypeApi(fetch, this.url);
    public agreements: IAgreementApi = new AgreementApi(fetch, this.url);
    public presetImage: IPresetImageApi = new PresetImageApi(fetch, this.url);
    public presetImageGroup: IPresetImageGroupApi = new PresetImageGroupApi(fetch, this.url);
    public kanban: IKanbanApi = new KanbanApi(fetch, this.url);
    public kanbanBoardType: IKanbanBoardTypeApi = new KanbanBoardTypeApi(fetch, this.url);
    public kanbanCardType: IKanbanCardTypeApi = new KanbanCardTypeApi(fetch, this.url);
    public matrixType: IMatrixTypeApi = new MatrixTypeApi(fetch, this.url);
    public reportType: IReportTypeApi = new ReportTypeApi(fetch, this.url);
    public event: IEventApi = new EventApi(fetch, this.url);
    public edges: IEdgesApi = new EdgesApi(fetch, this.url);
    public approval: IApprovalApi = new ApprovalApi(fetch, this.url);
    public approvalTemplates: IApprovalTemplateApi = new ApprovalTemplateApi(fetch, this.url);
    public favorites: IFavoritesApi = new FavoritesApi(fetch, this.url);
    public notificationTemplate: INotificationTemplateApi = new NotificationTemplateApi(fetch, this.url);
    public messages: IMessagesApi = new MessagesApi(fetch, this.url);
    public notifications: INotificationApi = new NotificationApi(fetch, this.url);
    public operations: IOperationApi = new OperationApi(fetch, this.url);
    public attributeQuery: AttributeQueryTypeApi = new AttributeQueryTypeApi(fetch, this.url);
    public nodeLock: LockApi = new LockApi(fetch, this.url);
    public personal: PersonalApi = new PersonalApi(fetch, this.url);
    public aip: AipApi = new AipApi(fetch, this.url);
    public mergeApi: MergeApi = new MergeApi(fetch, this.url);
    public modelTemplate: IModelTemplateApi = new ModelTemplateApi(fetch, this.url);
    public UITemplates: IUITemplateApi = new UITemplateApi(fetch, this.url);

    constructor(private url: string) {}
}

const API_BUNDLE_MAP: {
    [id: string]: ApiBundle;
} = {};

export function apiBundle(serverId: string, serverUrl?: string): ApiBundle {
    if (!!API_BUNDLE_MAP[serverId] && !serverUrl) {
        return API_BUNDLE_MAP[serverId];
    }
    if (!serverUrl) {
        throw new Error('missed server url');
    }
    const serverApiBundle = new ApiBundle(serverUrl);
    API_BUNDLE_MAP[serverId] = serverApiBundle;

    return serverApiBundle;
}

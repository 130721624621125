import { TAipPresetData, TAipPresetItem } from "./types/AipPreset.types";
import { TPresetMetaDataArrayItem, TReducedOriginalPresetModelTypeItem } from "./types/PresetMetadataToAipPresetDataAdapter.types";
import { AipAlias, ModelType, PresetMetaData } from "@/serverapi/api";
import { uniqBy } from 'lodash-es';

export class PresetMetadataToAipPresetDataAdapter {

    convert(originalPreset: PresetMetaData, aliases: AipAlias[]): TAipPresetData {
        const attributeTypes: TAipPresetItem[] = this.turnArrayIntoArrayOfTAipPresetItem(originalPreset.attributeTypes);
        const folderTypes: TAipPresetItem[] = this.turnArrayIntoArrayOfTAipPresetItem(originalPreset.folderTypes);
        const objectTypes: TAipPresetItem[] = this.turnArrayIntoArrayOfTAipPresetItem(originalPreset.objectTypes);
        const modelTypes: TReducedOriginalPresetModelTypeItem[] = this.filterModelTypes(originalPreset.modelTypes);

        return {
            aliasTypes: aliases.map((a: AipAlias) => {return { id: a.id, multilingualName: { ru: a.name.ru } } }),
            attributeTypes: attributeTypes.slice(),
            edgeTypes: this.getEdgeTypesFromModelTypes(modelTypes),
            folderTypes: folderTypes.slice(),
            modelTypes: this.removeEdgeTypesFromModelTypes(modelTypes),
            objectTypes: objectTypes.slice()
        };
    }


    private turnArrayIntoArrayOfTAipPresetItem(array: TPresetMetaDataArrayItem[] | undefined): TAipPresetItem[] {
        if (!array) return [];
        return array.map((item: TPresetMetaDataArrayItem) => {
            return { id: item.id, multilingualName: Object.assign({}, item.multilingualName) };
        });
    }


    private filterModelTypes(array: ModelType[] | undefined): TReducedOriginalPresetModelTypeItem[] {
        if (!array) return [];
        return array.map((item: ModelType) => {
            return {
                id: item.id,
                multilingualName: Object.assign({}, item.multilingualName),
                edgeTypes: this.turnArrayIntoArrayOfTAipPresetItem(item.edgeTypes)
            };
        });
    }


    private getEdgeTypesFromModelTypes(mt: TReducedOriginalPresetModelTypeItem[]): TAipPresetItem[] {
        return uniqBy(mt.flatMap((item: TReducedOriginalPresetModelTypeItem) => item.edgeTypes ), 'id');
    }


    private removeEdgeTypesFromModelTypes(mt: TReducedOriginalPresetModelTypeItem[]): TAipPresetItem[] {
        return mt.map((item: TReducedOriginalPresetModelTypeItem) => {
            return {
                id: item.id,
                multilingualName: {...item.multilingualName}
            };
        });
    }
}

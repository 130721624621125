import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icUpdate from '../../../../../../resources/icons/ic-update.svg';
import { updateAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';

export const UpdateButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getUpdateBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getUpdateBtnTooltip);

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icUpdate}
            size="small"
            onClick={() => dispatch(updateAction())}
            tooltip={tooltip}
            dataTest="general-toolbar_refresh-btn"
        />
    );
};

import { TCheckboxStatus } from "./Checkbox.types";

export const getNextStatus = (currentStatus: TCheckboxStatus, allowIndeterminate?: boolean): TCheckboxStatus => {
    if (allowIndeterminate) {
        if (currentStatus === 'indeterminate') {
            return false;
        } else if (currentStatus) {
            return 'indeterminate';
        } else {
            return true;
        }
    }

    return !currentStatus;
};

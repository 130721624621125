import icAddHorizontal from '@/resources/icons/ic-ribbon-add-horz.svg';
import icAddVertical from '@/resources/icons/ic-ribbon-add-vert.svg';
import icDeleteVertical from '@/resources/icons/ic-delete-space-vert.svg';
import icDeleteHorizontal from '@/resources/icons/ic-delete-space-horiz.svg';
import { TSVGIcon } from '../modules/UIKit/components/Button/Button.types';

export enum SpaceAction {
    InsertHorizontal = 'InsertHorizontal',
    InsertVervical = 'InsertVertical',
    DeleteHorizontal = 'DeleteHorizontal',
    DeleteVertical = 'DeleteVertical',
}

export const SpaceActionBtns: {
    [key in keyof typeof SpaceAction]: TSVGIcon;
} = {
    InsertHorizontal: icAddHorizontal,
    InsertVervical: icAddVertical,
    DeleteHorizontal: icDeleteHorizontal,
    DeleteVertical: icDeleteVertical,
};

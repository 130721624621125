import { Alignment } from './alignment';
import { EdgeArrow } from './edge-arrow';
import { EdgeStyle } from './edge-style';

/**
 * Created by kolpakovd on 10/28/2017.
 * Class for state of buttons in general menu, which respond for edit text inside of labels
 */
export class ButtonEditLabelState {
    isFontBoldSelected: boolean = false;
    isFontItalicSelected: boolean = false;
    isFontUnderlineSelected: boolean = false;
    alignment: Alignment = Alignment.CenterHorz;
    fontSize: string = '';
    fontFamily: string = '';
    horizontal: boolean | undefined;
    lineType: EdgeStyle | undefined;
    startArrow: EdgeArrow | undefined;
    endArrow: EdgeArrow | undefined;
    edgeTypeId: string | undefined;
    fontColor: string | undefined;
    fillColor: string | undefined;
}

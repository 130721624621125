import {
    NodeId,
    ReportColumnData,
    ReportDataFillingTypeEnum,
    ReportNode,
    SearchRequest,
    SearchResult,
} from '@/serverapi/api';
import {
    TFetchNodesSuccessAction,
    TFetchNodesWithAttributesAction,
    TReportAddColumnAction,
    TReportAddNodesAction,
    TReportChangeColumnFilterVisibilityAction,
    TReportChangeColumnOrderAction,
    TReportChangeColumnVisibilityAction,
    TReportChangeFillingTypeAction,
    TReportClearSearchRequestAction,
    TReportClearSelectColumnAction,
    TReportColumnData,
    TReportCreateAction,
    TReportCreateNewColumnAction,
    TReportDeleteColumnAction,
    TReportDeleteNodeshAction,
    TReportDropNodesAction,
    TReportOpenAction,
    TReportOpenByNodeIdAction,
    TReportOpenElementAction,
    TReportRefreshAction,
    TReportRequestSuccessAction,
    TReportSaveRequestAction,
    TReportSaveRequestFailureAction,
    TReportSaveRequestSuccessAction,
    TReportSearchRequestAction,
    TReportSearchRequestFailureAction,
    TReportSearchRequestSuccessAction,
    TReportSelectColumnAction,
    TReportSetColumnDataAction,
    TReportSetColumnFilterAction,
    TReportSetSearchRequestsAction,
    TReportSetSearchValueAction,
} from './report.actions.types';
import {
    REPORT_CREATE,
    REPORT_OPEN,
    REPORT_SAVE_REQUEST,
    REPORT_SAVE_REQUEST_SUCCESS,
    REPORT_SAVE_REQUEST_FAILURE,
    REPORT_OPEN_BY_NODE_ID,
    REPORT_REQUEST_SUCCESS,
    REPORT_ADD_NODES,
    REPORT_DROP_NODES,
    REPORT_SET_COLUMN_DATA,
    REPORT_DELETE_COLUMN,
    REPORT_ADD_COLUMN,
    REPORT_SELECT_COLUMN,
    REPORT_CLEAR_SELECT_COLUMN,
    REPORT_CHANGE_FILLING_TYPE,
    REPORT_SET_SEARCH_REQUESTS,
    REPORT_SEARCH_REQUEST,
    REPORT_SEARCH_REQUEST_SUCCESS,
    REPORT_CLEAR_SEARCH_REQUEST,
    REPORT_SEARCH_REQUEST_FAILURE,
    REPORT_REFRESH,
    REPORT_DELETE_NODES,
    REPORT_CREATE_NEW_COLUMN,
    REPORT_SET_FILTER_VALUE,
    FETCH_NODES_WITH_ATTRIBUTES_REQUEST,
    FETCH_NODES_WITH_ATTRIBUTES_REQUEST_SUCCESS,
    REPORT_CHANGE_COLUMN_VISIBILITY,
    REPORT_CHANGE_COLUMN_FILTER_VISIBILITY,
    REPORT_SET_COLUMN_FILTER,
    REPORT_CHANGE_COLUMN_ORDER,
    REPORT_OPEN_ELEMENT,
} from './report.actionTypes';
import { EditorMode } from '@/models/editorMode';
import { TExtendedNode } from '@/selectors/types/nodesSelector.types';
import { TFilterType, TNumericFilter } from '../reducers/reportEditor.reducer.types';

export const reportCreate = (report: ReportNode): TReportCreateAction => ({
    type: REPORT_CREATE,
    payload: {
        report,
    },
});

export const reportSaveRequest = (report: ReportNode): TReportSaveRequestAction => ({
    type: REPORT_SAVE_REQUEST,
    payload: {
        report,
    },
});

export const reportSaveRequestSuccess = (report: ReportNode): TReportSaveRequestSuccessAction => ({
    type: REPORT_SAVE_REQUEST_SUCCESS,
    payload: {
        report,
    },
});

export const reportSaveRequestFailure = (): TReportSaveRequestFailureAction => ({
    type: REPORT_SAVE_REQUEST_FAILURE,
});

export const reportOpen = (report: ReportNode, mode?: EditorMode): TReportOpenAction => ({
    type: REPORT_OPEN,
    payload: {
        report,
        mode,
    },
});

export const reportOpenByNodeId = (nodeId: NodeId): TReportOpenByNodeIdAction => ({
    type: REPORT_OPEN_BY_NODE_ID,
    payload: { reportNodeId: nodeId },
});

export const reportRequestSuccess = (report: ReportNode): TReportRequestSuccessAction => ({
    type: REPORT_REQUEST_SUCCESS,
    payload: {
        report,
    },
});

export const reportDropNodes = (reportNodeId: NodeId, initDroppedNodeId: NodeId): TReportDropNodesAction => ({
    type: REPORT_DROP_NODES,
    payload: {
        reportNodeId,
        initDroppedNodeId,
    },
});

export const reportAddNodes = (reportNodeId: NodeId, addedNodeIds: NodeId[]): TReportAddNodesAction => ({
    type: REPORT_ADD_NODES,
    payload: {
        reportNodeId,
        addedNodeIds,
    },
});

export const reportSetColumnData = (
    reportNodeId: NodeId,
    columnId: string,
    data: TReportColumnData,
): TReportSetColumnDataAction => ({
    type: REPORT_SET_COLUMN_DATA,
    payload: {
        reportNodeId,
        columnId,
        data,
    },
});

export const reportDeleteColumn = (reportNodeId: NodeId, columnId: string): TReportDeleteColumnAction => ({
    type: REPORT_DELETE_COLUMN,
    payload: {
        reportNodeId,
        columnId,
    },
});

export const reportAddColumn = (reportNodeId: NodeId, newColumn: ReportColumnData): TReportAddColumnAction => ({
    type: REPORT_ADD_COLUMN,
    payload: {
        reportNodeId,
        newColumn,
    },
});

export const reportChangeColumnOrder = (
    reportNodeId: NodeId,
    columnOrder: string[],
): TReportChangeColumnOrderAction => ({
    type: REPORT_CHANGE_COLUMN_ORDER,
    payload: {
        reportNodeId,
        columnOrder,
    },
});

export const reportCreateNewColumn = (reportNodeId: NodeId): TReportCreateNewColumnAction => ({
    type: REPORT_CREATE_NEW_COLUMN,
    payload: {
        reportNodeId,
    },
});

export const reportSelectColumn = (reportNodeId: NodeId, columnId: string): TReportSelectColumnAction => ({
    type: REPORT_SELECT_COLUMN,
    payload: {
        reportNodeId,
        columnId,
    },
});

export const reportClearSelectColumn = (reportNodeId: NodeId): TReportClearSelectColumnAction => ({
    type: REPORT_CLEAR_SELECT_COLUMN,
    payload: {
        reportNodeId,
    },
});

export const reportChangeFillingType = (
    reportNodeId: NodeId,
    fillingType: ReportDataFillingTypeEnum,
): TReportChangeFillingTypeAction => ({
    type: REPORT_CHANGE_FILLING_TYPE,
    payload: {
        reportNodeId,
        fillingType,
    },
});

export const reportSetSearchRequests = (
    reportNodeId: NodeId,
    searchRequests: SearchRequest[],
): TReportSetSearchRequestsAction => ({
    type: REPORT_SET_SEARCH_REQUESTS,
    payload: {
        reportNodeId,
        searchRequests,
    },
});

export const reportSearchRequest = (
    reportNodeId: NodeId,
    searchRequests: SearchRequest[],
): TReportSearchRequestAction => ({
    type: REPORT_SEARCH_REQUEST,
    payload: {
        reportNodeId,
        searchRequests,
    },
});

export const reportSearchRequestSuccess = (
    reportNodeId: NodeId,
    searchResults: SearchResult[],
): TReportSearchRequestSuccessAction => ({
    type: REPORT_SEARCH_REQUEST_SUCCESS,
    payload: {
        reportNodeId,
        searchResults,
    },
});

export const reportSearchRequestFailure = (reportNodeId: NodeId): TReportSearchRequestFailureAction => ({
    type: REPORT_SEARCH_REQUEST_FAILURE,
    payload: {
        reportNodeId,
    },
});

export const reportClearSearchRequest = (reportNodeId: NodeId): TReportClearSearchRequestAction => ({
    type: REPORT_CLEAR_SEARCH_REQUEST,
    payload: {
        reportNodeId,
    },
});

export const reportRefresh = (reportNodeId: NodeId): TReportRefreshAction => ({
    type: REPORT_REFRESH,
    payload: {
        reportNodeId,
    },
});

export const reportDeleteNodes = (reportNodeId: NodeId, nodeIds: NodeId[]): TReportDeleteNodeshAction => ({
    type: REPORT_DELETE_NODES,
    payload: {
        reportNodeId,
        nodeIds,
    },
});

export const reportSetSearchValue = (reportNodeId: NodeId, searchValue: string): TReportSetSearchValueAction => ({
    type: REPORT_SET_FILTER_VALUE,
    payload: {
        reportNodeId,
        searchValue,
    },
});

export const fetchNodesWithAttributes = (nodeIds: NodeId[], overwrite?: boolean): TFetchNodesWithAttributesAction => {
    return {
        type: FETCH_NODES_WITH_ATTRIBUTES_REQUEST,
        payload: {
            nodeIds,
            overwrite,
        },
    };
};

export const fetchNodesWithAttributesSuccess = (
    nodes: TExtendedNode[],
    overwrite?: boolean,
): TFetchNodesSuccessAction => ({
    type: FETCH_NODES_WITH_ATTRIBUTES_REQUEST_SUCCESS,
    payload: { nodes, overwrite },
});

export const reportChangeColumnVisibility = (
    reportNodeId: NodeId,
    columnId: string,
): TReportChangeColumnVisibilityAction => ({
    type: REPORT_CHANGE_COLUMN_VISIBILITY,
    payload: {
        reportNodeId,
        columnId,
    },
});

export const reportChangeColumnFilterVisibility = (
    reportNodeId: NodeId,
    columnId: string,
): TReportChangeColumnFilterVisibilityAction => ({
    type: REPORT_CHANGE_COLUMN_FILTER_VISIBILITY,
    payload: {
        reportNodeId,
        columnId,
    },
});

export const reportSetColumnFilter = (
    reportNodeId: NodeId,
    columnId: string,
    filterType: TFilterType,
    data: string[] | TNumericFilter,
): TReportSetColumnFilterAction => ({
    type: REPORT_SET_COLUMN_FILTER,
    payload: {
        reportNodeId,
        columnId,
        filterType,
        data,
    },
});

export const reportOpenElement = (nodeId: NodeId, reportNodeId: NodeId): TReportOpenElementAction => ({
    type: REPORT_OPEN_ELEMENT,
    payload: {
        nodeId,
        reportNodeId,
    },
});

import { INIT_HISTORY_TAB } from '../actionsTypes/historyTab.actionTypes';
import { NodeId } from '../serverapi/api';
import { TInitHistoryTabAction } from './historyTab.actions.types';

export const initHistoryTabAction: (nodeId: NodeId) => TInitHistoryTabAction = (nodeId) => ({
    type: INIT_HISTORY_TAB,
    payload: {
        nodeId,
    },
});

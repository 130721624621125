// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuerySelect__container__KyYiN{display:-webkit-box;display:-ms-flexbox;display:flex}.QuerySelect__selectContainer__re7JI{width:100%}.QuerySelect__selectContainer__re7JI button{padding:0px 8px 0px 4px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/QuerySelect/QuerySelect.scss"],"names":[],"mappings":"AAAA,+BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGJ,qCACI,UAAA,CACA,4CACI,uBAAA","sourcesContent":[".container {\r\n    display: flex;\r\n}\r\n\r\n.selectContainer {\r\n    width: 100%;\r\n    button {\r\n        padding: 0px 8px 0px 4px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "QuerySelect__container__KyYiN",
	"selectContainer": "QuerySelect__selectContainer__re7JI"
};
export default ___CSS_LOADER_EXPORT___;

import { defineMessages } from 'react-intl';

export default defineMessages({
    objectDefinitionFormName: {
        id: 'ObjectDefinitionDialog.objectDefinitionFormName',
        defaultMessage: 'Новый объект',
    },
    pathLabel: {
        id: 'ObjectDefinitionDialog.pathLabel',
        defaultMessage: 'Расположение',
    },
    pathPlaceholder: {
        id: 'ObjectDefinitionDialog.pathPlaceholder',
        defaultMessage: 'Название сервера/Название базы данных/Папка',
    },
    pathRequiredError: {
        id: 'ObjectDefinitionDialog.pathRequiredError',
        defaultMessage: 'Путь должен быть заполнен',
    },
    objectTypeLabel: {
        id: 'ObjectDefinitionDialog.objectTypeLabel',
        defaultMessage: 'Тип объекта',
    },
    objectTypePlaceHolder: {
        id: 'ObjectDefinitionDialog.objectTypePlaceHolder',
        defaultMessage: 'Тип объекта',
    },
    objectTypeRequiredError: {
        id: 'ObjectDefinitionDialog.objectTypeRequiredError',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    objectDefinitionNameLabel: {
        id: 'ObjectDefinitionDialog.objectDefinitionNameLabel',
        defaultMessage: 'Имя объекта',
    },
    objectDefinitionNamePlaceholder: {
        id: 'ObjectDefinitionDialog.objectDefinitionNamePlaceholder',
        defaultMessage: 'Имя нового объекта',
    },

    objectDefinitionNameRequiredError: {
        id: 'ObjectDefinitionDialog.objectDefinitionNameRequiredError',
        defaultMessage: 'Это поле обязательно для заполнения',
    },

    objectDefinitionFormConfirmButton: {
        id: 'ObjectDefinitionDialog.objectDefinitionFormConfirmButton',
        defaultMessage: 'Создать',
    },
    objectDefinitionFormDeclineButton: {
        id: 'ObjectDefinitionDialog.objectDefinitionFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    objectDefinitionCreateError: {
        id: 'ObjectDefinitionDialog.objectDefinitionCreateError',
        defaultMessage: 'Не удалось создать объект',
    },
    objectDefinitionSaveError: {
        id: 'ObjectDefinitionDialog.objectDefinitionSaveError',
        defaultMessage: 'Не удалось сохранить объект',
    },
    objectDefinitionAccessDeniedError: {
        id: 'ObjectDefinitionDialog.objectDefinitionAccessDeniedError',
        defaultMessage: 'Недостаточно прав доступа',
    },
    objectDefinitionFailDeleteError: {
        id: 'ObjectDefinitionDialog.objectDefinitionFailDeleteError',
        defaultMessage: 'Невозможно удалить объект',
    },
    noAvailableObjectTypeError: {
        id: 'ObjectDefinitionDialog.noAvailableObjectTypeError',
        defaultMessage: 'В методологии нет доступных типов объектов. Выберите другую методологию или внесите необходимые изменения в текущую методологию',
    },
});

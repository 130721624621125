import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from '../../UIKit/components/Checkbox/Checkbox.component';
import { ApprovalTemplatesSelectors } from '@/selectors/approvalTemplates.selectors';

type TRowCheckBoxProps = {
    id: string;
};

export const RowCheckBox = (props: TRowCheckBoxProps): JSX.Element => {
    const { id } = props;
    const isChecked: boolean = useSelector(ApprovalTemplatesSelectors.isApprovalTemplatesChecked(id));

    return (
        <Checkbox
            status={isChecked}
        />
    );
};

import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { recentAddModel } from '../actions/recent.actions';
import {
    SIMULATION_DELETE,
    SIMULATION_MODELING_ADD,
    SIMULATION_MODELING_GET_REPORT,
    SIMULATION_MODELING_OPEN,
    SIMULATION_MODELING_RUN,
    SIMULATION_MODELING_SUBMIT_NODE,
    SIMULATION_SETTINGS_SAVE,
    SIMULATION_UPDATE,
} from '../actionsTypes/simulationModeling.actionTypes';
import {
    addReportToState,
    openSimulationModeling,
    simulationRunsRequestSuccess,
    simulationSettingsRequestSuccess,
} from '../actions/simulationModeling.actions';
import {
    TSimulationUpdateAction,
    TSimulationDeleteAction,
    TSimulationSettingsSaveAction,
    TOpenSimulationModelingAction,
    TRunSimulationAction,
    TSubmitSimulationNodeAction,
    TGetReportSimulationAction,
} from '../actions/simulationModeling.actions.types';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../actionsTypes/tabs.actionTypes';
import { workspaceAddTab, workspaceRemoveTab } from '../actions/tabs.actions';
import { TWorkspaceTabsRemoveAction } from '../actions/tabs.actions.types';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemChildAdd } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { EditorMode } from '../models/editorMode';
import { TServerEntity } from '../models/entities.types';
import { defaultWorkspaceTabActions } from '../models/tab';
import { ISimulationModelingWorkspaceParams, TWorkspaceTab } from '../models/tab.types';
import { TreeNode } from '../models/tree.types';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import messages from '../modules/SimulationModeling/messages/SimulationModeling.messages';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { TreeSelectors } from '../selectors/tree.selectors';
import { NodeId, NodeTypeEnum, SimulationNode, SimulationRun, SimulationSettings } from '../serverapi/api';
import { LocalesService } from '../services/LocalesService';
import { setServerIdToNodeInterface, setServerIdToSimulationRun } from '../utils/nodeId.utils';
import { SimulationModelingDaoService } from '../services/dao/SimulationModelingDaoService';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';

function* handleOpenSimulationModeling({ payload }: TOpenSimulationModelingAction) {
    const {
        nodeId,
        nodeId: { serverId, id, repositoryId },
    } = payload;
    try {
        const server: TServerEntity = yield select(ServerSelectors.server(serverId));
        const simulationRuns: Array<SimulationRun> = yield call(() =>
            server.api.simulation.getResults({ repositoryId, simulationId: id }),
        );
        const simulationSettings: SimulationSettings = yield call(() =>
            server.api.simulation.getSettings({ repositoryId, simulationId: id }),
        );

        yield put(
            simulationRunsRequestSuccess({
                simulationNodeId: nodeId,
                simulationRuns: simulationRuns.map((sr) => setServerIdToSimulationRun(sr, serverId)),
            }),
        );
        yield put(simulationSettingsRequestSuccess({ simulationNodeId: nodeId, simulationSettings }));

        let simulationNode: SimulationNode = yield select(TreeSelectors.itemById(nodeId));

        if (!simulationNode) {
            simulationNode = yield SimulationModelingDaoService.getSimulationModeling(nodeId);
        }

        const methodologySettingTab: TWorkspaceTab = {
            title: simulationNode?.name,
            nodeId,
            mode: EditorMode.Read,
            type: WorkSpaceTabTypes.SIMULATION_MODELING,
            params: {
                nodeId,
            } as ISimulationModelingWorkspaceParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        yield put(workspaceAddTab(methodologySettingTab));
        yield put(
            recentAddModel({
                nodeId: simulationNode.nodeId,
                type: TreeItemType.SimulationModeling,
                parentId: simulationNode.parentNodeId,
                createdAt: new Date().toISOString(),
                title: simulationNode.name,
                modelTypeId: simulationNode.type,
                modelTypeName: LocalesService.useIntl(yield select(getCurrentLocale)).formatMessage(
                    messages.simulationModel,
                ),
                messageDescriptor: messages.simulationModel,
            }),
        );
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

function* handleTabClose(action: TWorkspaceTabsRemoveAction) {
    const { workspaceTab } = action.payload;
    if (workspaceTab) {
        yield put(workspaceRemoveTab(workspaceTab));
    }
}

function* handleRunSimulation(action: TRunSimulationAction) {
    const { simulation } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(simulation.id?.serverId || ''));
    if (server) {
        const simulationRun: SimulationRun = yield SimulationModelingDaoService.runSimulation(simulation);
        yield put(addReportToState(simulationRun));
    }
}

function* handleSaveSimulation(action: TSimulationUpdateAction) {
    const { simulation } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(simulation.id?.serverId || ''));
    if (server) {
        yield SimulationModelingDaoService.saveSimulationParams(simulation);
    }
    yield put(closeDialog(DialogType.SAVE_SIMULATION_PARAMS_DIALOG));
}

function* handleDeleteSimulation(action: TSimulationDeleteAction) {
    const simulation = action.payload.simulationRun;

    const server: TServerEntity = yield select(ServerSelectors.server(simulation.id?.serverId || ''));
    if (server) {
        yield call(() =>
            server.api.simulation.deleteSimulationRun({
                repositoryId: simulation.simulationId.repositoryId,
                simulationId: simulation.simulationId.id,
                id: simulation.id.id,
            }),
        );
    }
}

function* handleSaveSimulationSettings(action: TSimulationSettingsSaveAction) {
    const { simulationId, simulationSettings } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(simulationId.serverId || ''));

    if (server) {
        const simulationNode: SimulationNode = yield select(TreeSelectors.itemById(simulationId));

        yield call(() =>
            server.api.simulation.save({
                body: {
                    ...simulationNode,
                    settings: simulationSettings,
                },
            }),
        );
    }
}

function* handleContextMenuAction({ payload }: TTreeItemContextMenuAction) {
    const { action, nodeId: parentNodeId } = payload;

    if (action === TreeItemContextMenuAction.ADD_SIMULATION_MODELING) {
        yield put(openDialog(DialogType.SIMULATION_DIALOG, { parentNodeId, type: TreeItemType.SimulationModeling }));
    }
}

function* handleSubmitNode({ payload }: TSubmitSimulationNodeAction) {
    const { parentNodeId, name } = payload;
    const server: TServerEntity = yield select(ServerSelectors.server(parentNodeId.serverId));

    const newSimulationNode: SimulationNode = {
        nodeId: {
            ...parentNodeId,
            id: uuid(),
        } as NodeId,
        type: TreeItemType.SimulationModeling as NodeTypeEnum,
        parentNodeId,
        settings: {
            algorithm: 'SIMPLE',
            attributeRoles: [],
            decompositionDepth: 1,
            modelTypeSymbolRoles: [],
        },
        name,
    } as SimulationNode;
    const simulationNode: SimulationNode = yield call(() =>
        server.api.simulation.save({
            body: newSimulationNode,
        }),
    );
    setServerIdToNodeInterface(simulationNode, parentNodeId.serverId);

    yield all([
        put(closeDialog(DialogType.SIMULATION_DIALOG)),
        put(treeItemChildAdd({ parentNodeId, child: [{ ...simulationNode, hasChildren: false } as TreeNode] })),
        put(openSimulationModeling(newSimulationNode.nodeId)),
    ]);
}

function* handleGetReportSimulation(action: TGetReportSimulationAction) {
    const { simulation } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(simulation.id?.serverId || ''));
    if (server) {
        const report: SimulationRun = yield SimulationModelingDaoService.getReportOfSimulation(
            server.id,
            simulation.simulationId.repositoryId,
            simulation.simulationId.id,
            simulation.id.id,
        );
        yield put(addReportToState(report));
    }
}

export function* simulationModelingSagaInit() {
    yield takeEvery(SIMULATION_MODELING_OPEN, handleOpenSimulationModeling);
    yield takeEvery(SIMULATION_MODELING_RUN, handleRunSimulation);
    yield takeEvery(SIMULATION_UPDATE, handleSaveSimulation);
    yield takeEvery(SIMULATION_MODELING_ADD, handleSaveSimulation);
    yield takeEvery(SIMULATION_DELETE, handleDeleteSimulation);
    yield takeEvery(SIMULATION_SETTINGS_SAVE, handleSaveSimulationSettings);
    yield takeEvery(SIMULATION_MODELING_SUBMIT_NODE, handleSubmitNode);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabClose);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleContextMenuAction);
    yield takeEvery(SIMULATION_MODELING_GET_REPORT, handleGetReportSimulation);
}

import { useIntl } from 'react-intl';
import { MxCell } from '../../../../mxgraph';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../FormatPanel.messages';
import lineTypeMessages from '@/models/edge-style.messages';
import theme from './edgeStyles.scss';
import React, { useMemo } from 'react';
import { Select } from '../../../UIKit/components/Select/Select.component';
import { FormatPanelButtonsState } from '../../../../selectors/generalMenu.selectors';
import icRibbonLineType from '@/resources/icons/toolbar/ic-line-type.svg';
import {
    ICompactableSelectItemValue,
    TCompactableSelectItemType,
} from '../../../MainMenu/components/CompactableSelect/CompactableSelect.types';
import { changeEdgesStyle } from '../../../../actions/editor.actions';
import { Icon } from '../../../UIKit';
import { EdgeStyle, EdgeStyleDescriptor, edgeStylesNames } from '../../../../models/edge-style';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { FormatPanelSelectors } from '../../../../selectors/formatPanel.selectors';
import { filterSelectedCells } from '../../utils/format.utils';
import { TSelectedCellElements } from '../../../../services/bll/ToolbarButtonsBllService.types';
import { EditorToolbarSelectors } from '../../../../selectors/editorToolbar.selectors';

export const LineTypeBlock = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const locale = useSelector(getCurrentLocale);
    const lineType: EdgeStyle | undefined = useSelector(FormatPanelButtonsState.getLineType);
    const tooltip: string = useSelector(FormatPanelSelectors.getLineTypeTooltip);

    const disabled: boolean = useSelector(FormatPanelSelectors.getLineTypeDisabled);
    const { rawSelectedCells }: TSelectedCellElements = useSelector(
        EditorToolbarSelectors.getSelectedCellsInActiveGraph,
    );
    const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
    const selectedEdges: MxCell[] = selectedCells.filter((cell) => cell.isEdge());

    const items: ICompactableSelectItemValue<string>[] = useMemo(
        () =>
            edgeStylesNames(locale, intl).map(
                (item) =>
                    ({
                        type: TCompactableSelectItemType.Value,
                        value: item.id,
                        label: item.value[locale],
                        disabled: false,
                    } as ICompactableSelectItemValue<string>),
            ),
        [locale],
    );
    const handleChangeLineType = (value: string) => {
        const { styleKeys, styleValues } = EdgeStyleDescriptor.byValue(Number(value));
        const cellIds: string[] = selectedEdges.map((edge) => edge.id);
        dispatch(changeEdgesStyle(styleKeys, styleValues, cellIds));
    };

    const edgeLineValue: JSX.Element = (
        <div className={theme.valueContainer}>
            <Icon spriteSymbol={icRibbonLineType} />
            <span className={theme.typeContainer}>
                {lineType !== undefined ? intl.formatMessage(lineTypeMessages[EdgeStyle[lineType]]) : ''}
            </span>
        </div>
    );

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.lineType)}</div>
            <Select
                onChange={handleChangeLineType}
                value={edgeLineValue}
                disabled={disabled}
                placeholder=""
                originalTheme
                tooltip={tooltip}
                data-test="format-toolbar_edge-line"
            >
                {items.map((type) => {
                    return <Select.Option value={type.value} label={type.label} />;
                })}
            </Select>
        </div>
    );
};

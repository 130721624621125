// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spreadsheet__spreadsheetContainer__Z8gGm{width:100%;height:calc(100% - 40px)}.Spreadsheet__container__xdAv2{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/modules/Spreadsheet/Spreadsheet.scss"],"names":[],"mappings":"AAAA,0CACI,UAAA,CACA,wBAAA,CAEJ,+BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".spreadsheetContainer {\r\n    width: 100%;\r\n    height: calc(100% - 40px);\r\n}\r\n.container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spreadsheetContainer": "Spreadsheet__spreadsheetContainer__Z8gGm",
	"container": "Spreadsheet__container__xdAv2"
};
export default ___CSS_LOADER_EXPORT___;

export enum TNavigatorTab {
    Tree = 'Tree',
    Symbols = 'Symbols',
    Properties = 'Properties',
    Outline = 'Outline',
    Comments = 'Comments',
    ReportSettings = 'ReportSettings',
    Format = 'Format',
    Widgets = 'Widgets',
}

export type TNavigatorState = {
    activeTabs: Array<TNavigatorTab>;
};

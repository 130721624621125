import { v4 as uuid } from 'uuid';
import { put, select, takeEvery } from 'redux-saga/effects';
import {
    MODEL_DIALOG_INIT,
    MODEL_DIALOG_PARENT_NODE_ID_CHANGED,
    MODEL_DIALOG_SUBMIT_DATA,
} from '../actionsTypes/modelDialog.actionTypes';
import { modelDialogInit, modelDialogSetState } from '../actions/modelDialog.actions';
import { TModelDialogInitAction, TModelDialogSubmitData } from '../actions/modelDialog.actions.types';
import { getTreeItems, TreeSelectors } from '../selectors/tree.selectors';
import { ModelDialogSelectors } from '../selectors/modelDialog.selectors';
import { TTreeEntityState, TreeNode } from '../models/tree.types';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemCollapseAll, treeItemsExpandWithoutLoad } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { isUndefined } from 'is-what';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { ModelNode, NodeId, KanbanBoardType, MatrixType, ReportType, WikiType } from '../serverapi/api';
import { IWikiNode } from '../models/bpm/bpm-model-impl.types';
import { wikiCreate } from '../actions/entities/wiki.actions';
import { matrixCreate } from '../modules/Matrix/actions/matrix.actions';
import { getChainFromChildToParent } from '../services/utils/treeService.utils';
import { modelDiagramCreate } from '../actions/save.actions';
import { presetMetaDataRequest } from '../actions/notation.actions';
import { KanbanModelTypeSelectors } from '../selectors/kanbanModelType.selectors';
import { kanbanCreate } from '../actions/entities/kanban.actions';
import { MatrixModelTypeSelectors } from '@/selectors/matrixModelType.selectors';
import { ReportModelTypeSelectors } from '@/selectors/reportModelType.selectors';
import { reportCreate } from '@/modules/Report/actions/report.actions';
import { WikiModelTypeSelectors } from '../selectors/wikiModelType.selectors';

function* handleOpenDialog({ payload: { nodeId, type, action } }: TTreeItemContextMenuAction) {
    if (
        (type === TreeItemType.Folder || type === TreeItemType.Repository || type === TreeItemType.Model) &&
        action === TreeItemContextMenuAction.ADD_MODEL
    ) {
        yield put(
            modelDialogInit({
                parentNodeId: nodeId,
            }),
        );
    }
}

function* handleModelDialogInit({
    payload: {
        parentNodeId,
        parentNodeId: { serverId },
        openedSelectNode,
    },
}: TModelDialogInitAction) {
    yield put(treeItemCollapseAll(DialogType.MODEL_DIALOG));
    if (parentNodeId && parentNodeId.id) {
        const nodes: { [id: string]: TreeNode } = yield select(
            getTreeItems(parentNodeId.serverId, parentNodeId.repositoryId),
        );
        const ids: NodeId[] = getChainFromChildToParent(nodes, parentNodeId.id)
            .map((n) => n.nodeId)
            .splice(1);
        ids.push({ id: serverId, repositoryId: serverId, serverId } as NodeId);
        yield put(treeItemsExpandWithoutLoad(ids, DialogType.MODEL_DIALOG));
    }

    const treeItem: TTreeEntityState = yield select(TreeSelectors.itemById(parentNodeId));

    if (treeItem && treeItem.presetId) {
        yield put(presetMetaDataRequest([treeItem.presetId]));
    }

    yield put(openDialog(DialogType.MODEL_DIALOG, { serverId, openedSelectNode }));
}

function* handleModelDialogParentNodeIdChanged({
    payload: {
        parentNodeId,
        parentNodeId: { serverId, repositoryId },
    },
}: TModelDialogInitAction) {
    if (parentNodeId) {
        const nodes = yield select(getTreeItems(serverId, repositoryId));
        const previousInitialDataId = yield select(ModelDialogSelectors.getFormInitData);
        let isLoadModelTypeRequired = true;

        // if parentNode changed in context one server no need reload model types
        if (!isUndefined(previousInitialDataId)) {
            const previousContext = nodes[previousInitialDataId.parentNodeId];
            isLoadModelTypeRequired = previousContext ? serverId !== previousContext.serverId : true;
        }

        if (isLoadModelTypeRequired) {
            yield put(modelDialogSetState({ parentNodeId }));
        }
    }
}

export function* handleModelDialogSubmit({ payload }: TModelDialogSubmitData) {
    const {
        parentNodeId,
        parentNodeId: { repositoryId, serverId },
        modelName,
        modelTypeId,
        templateId,
    } = payload;

    const presetId: string = yield select(TreeSelectors.presetById(parentNodeId));
    const kanbanType: KanbanBoardType | undefined = yield select(KanbanModelTypeSelectors.byId(modelTypeId, presetId));
    const matrixType: MatrixType | undefined = yield select(MatrixModelTypeSelectors.byId(modelTypeId, presetId));
    const reportType: ReportType | undefined = yield select(ReportModelTypeSelectors.byId(modelTypeId, presetId));
    const wikiType: WikiType | undefined = yield select(WikiModelTypeSelectors.byId(modelTypeId, presetId));
    const isMatrixType: boolean = !!(matrixType || modelTypeId === 'matrix');

    const model: ModelNode = {
        nodeId: {
            id: uuid(),
            repositoryId,
            serverId,
        },
        parentNodeId,
        name: modelName,
        modelTypeId,
        type: TreeItemType.Model,
    };

    if (kanbanType) {
        model.type = TreeItemType.Kanban;
        yield put(kanbanCreate(model));

        return;
    }

    if (isMatrixType) {
        model.type = TreeItemType.Matrix;
        yield put(matrixCreate(model));

        return;
    }

    if (reportType) {
        model.type = TreeItemType.Report;
        yield put(reportCreate(model));

        return;
    }

    if (wikiType) {
        model.type = TreeItemType.Wiki;
        yield put(wikiCreate(model as IWikiNode));

        return;
    }

    yield put(modelDiagramCreate(modelTypeId, modelName, parentNodeId, presetId, templateId));
}

export function* modelDialogSagaInit() {
    yield takeEvery(MODEL_DIALOG_INIT, handleModelDialogInit);
    yield takeEvery(MODEL_DIALOG_PARENT_NODE_ID_CHANGED, handleModelDialogParentNodeIdChanged);
    yield takeEvery(MODEL_DIALOG_SUBMIT_DATA, handleModelDialogSubmit);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenDialog);
}

import {
    addApprovalTemplates,
    deleteApprovalTemplatesFromStore,
    editApprovalTemplate,
} from '@/actions/approvalTemplates.actions';
import {
    TDeleteApprovalTemplatesAction,
    TEditExistingApprovalTemplateAction,
    TSubmitApprovalTemplateAction,
} from '@/actions/approvalTemplates.actions.types';
import { closeDialog, openDialog } from '@/actions/dialogs.actions';
import {
    CREATE_APPROVAL_TEMPLATE,
    DELETE_APPROVAL_TEMPLATES,
    EDIT_EXISTING_APPROVAL_TEMPLATE,
    GET_APPROVAL_TEMPLATES,
    SUBMIT_APPROVAL_TEMPLATE,
} from '@/actionsTypes/approvalTemplates.actionTypes';
import { TApprovalDialogTemplateContainerProps } from '@/modules/ApprovalDialog/ApprovalDialog.types';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { ApprovalTemplatesSelectors } from '@/selectors/approvalTemplates.selectors';
import { ApprovalTemplateDTO, InternationalString } from '@/serverapi/api';
import { ApprovalTemplateDaoService } from '@/services/dao/ApprovalTemplateDAOService';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

function* handleCreateApprovalTemplate() {
    const newApprovalTemplate: ApprovalTemplateDTO = {
        id: uuid(),
        approvalName: {} as InternationalString,
        name: {} as InternationalString,
    };

    yield put(editApprovalTemplate({ approvalTemplate: newApprovalTemplate }));

    yield put(
        openDialog(DialogType.APPROVAL_TEMPLATE_DIALOG, {
            isCreateMode: true,
        } as TApprovalDialogTemplateContainerProps),
    );
}

function* handleSubmitApprovalTemplate({ payload }: TSubmitApprovalTemplateAction) {
    const { approvalTemplate, isCreateMode } = payload;
    let newApprovalTemplate: ApprovalTemplateDTO;

    if (!isCreateMode) {
        newApprovalTemplate = yield ApprovalTemplateDaoService.save(approvalTemplate);
    } else {
        newApprovalTemplate = yield ApprovalTemplateDaoService.create(approvalTemplate);
    }

    yield put(addApprovalTemplates({ approvalTemplates: [newApprovalTemplate] }));
    yield put(closeDialog(DialogType.APPROVAL_TEMPLATE_DIALOG));
}

function* handleDeleteApprovalTemplate({ payload }: TDeleteApprovalTemplatesAction) {
    const { approvalTemplateIds } = payload;
    yield all(
        approvalTemplateIds.map((approvalTemplateId: string) => ApprovalTemplateDaoService.delete(approvalTemplateId)),
    );
    yield put(deleteApprovalTemplatesFromStore({ approvalTemplateIds }));

    yield put(closeDialog(DialogType.APPROVAL_TEMPLATE_DIALOG));
}

function* handleEditExistingApprovalTemplate({ payload }: TEditExistingApprovalTemplateAction) {
    const { approvalTemplateId } = payload;

    const approvalTemplate: ApprovalTemplateDTO | undefined = yield select(
        ApprovalTemplatesSelectors.byApprovalTemplateId(approvalTemplateId),
    );

    if (!approvalTemplate) return;

    yield put(editApprovalTemplate({ approvalTemplate }));

    yield put(
        openDialog(DialogType.APPROVAL_TEMPLATE_DIALOG, {
            isCreateMode: false,
        } as TApprovalDialogTemplateContainerProps),
    );
}

function* handleGetApprovalTemplates() {
    const approvalTemplates = yield ApprovalTemplateDaoService.getApprovalTemplates();
    
    yield put(addApprovalTemplates({ approvalTemplates }));
}

export function* approvalTemplateSaga() {
    yield takeEvery(CREATE_APPROVAL_TEMPLATE, handleCreateApprovalTemplate);
    yield takeEvery(SUBMIT_APPROVAL_TEMPLATE, handleSubmitApprovalTemplate);
    yield takeEvery(DELETE_APPROVAL_TEMPLATES, handleDeleteApprovalTemplate);
    yield takeEvery(EDIT_EXISTING_APPROVAL_TEMPLATE, handleEditExistingApprovalTemplate);
    yield takeEvery(GET_APPROVAL_TEMPLATES, handleGetApprovalTemplates);
}

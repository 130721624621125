import { fontFamilyOptions, fontSizeOptions, lineHeightOptions } from '@/utils/configuration';

export const DEFAULT_FONT_COLOR = 'rgba(0, 0, 0, 0.65)';

export const DEFAULT_BG_COLOR = 'rgb(255, 255, 255)';

export const DEFAULT_FONT_FAMILY_OPTION = fontFamilyOptions[0];

export const DEFAULT_FONT_SIZE_OPTION = fontSizeOptions[5];

export const DEFAULT_LINE_HEIGHT_OPTION = lineHeightOptions[2];

export const DEFAULT_INDENT = 0;

export const DEFAULT_TEXT_ALIGN = 'left';

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { treeExportNode } from '@/actions/tree.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { DialogFooterButtons } from '@/modules/UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { NodeId } from '@/serverapi/api';
import messages from './ExportRepositoryDialog.messages';
import theme from './ExportRepositoryDialog.scss';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

type TExportRepositoryDialogProps = {
    nodeId: NodeId;
    name: string;
};

export const ExportRepositoryDialog = ({ nodeId, name }: TExportRepositoryDialogProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [exportUsersPermissions, setExportUsersPermissions] = useState<boolean>(false);
    const [exportGroupsPermissions, setExportGroupsPermissions] = useState<boolean>(false);

    const onCancel = () => {
        dispatch(closeDialog(DialogType.EXPORT_REPOSITORY_DIALOG));
    };

    const onSubmit = () => {
        dispatch(
            treeExportNode({
                nodeId,
                name,
                exportRequest: {
                    decompositionsSetting: 'ALL_DECOMPOSITIONS',
                    exportUsersPermissions,
                    exportGroupsPermissions,
                },
            }),
        );
        onCancel();
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancel,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.submitButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            open
            onOk={onSubmit}
            onCancel={onCancel}
            title={intl.formatMessage(messages.dialogTitle)}
            footer={footer}
        >
            <div className={theme.form}>
                <div className={theme.formItem}>
                    <label>
                        <span>{intl.formatMessage(messages.exportUsersPermissions)}</span>
                        <Checkbox
                            checked={exportUsersPermissions}
                            onChange={() => setExportUsersPermissions(!exportUsersPermissions)}
                        />
                    </label>
                </div>

                <div className={theme.formItem}>
                    <label>
                        <span>{intl.formatMessage(messages.exportGroupsPermissions)}</span>
                        <Checkbox
                            checked={exportGroupsPermissions}
                            onChange={() => setExportGroupsPermissions(!exportGroupsPermissions)}
                        />
                    </label>
                </div>
            </div>
        </Dialog>
    );
};

import * as React from 'react';
import { useIntl } from 'react-intl';
import { ShapeStyle, ShapeStyleIcons } from '../../../../../../models/Symbols.constants';
import messages from './ShapeDropdown.messages';
import icShape from '@/resources/icons/icShape.svg';
import { useDispatch, useSelector } from 'react-redux';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { dragShapeAction } from '../../../../../../actions/editor.actions';
import { getShape } from '../../../../../../models/Symbols.utils';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { ToolbarDropdown } from '../../../../../UIKit/components/Toolbar/ToolbarDropdown/ToolbarDropdown.component';

export const ShapeDropdown = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getAddShapeDropdownDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getaddShapeDropdownTooltip);

    const shapeButtons: JSX.Element[] = Object.keys(ShapeStyle).map((key) => {
        return (
            <ToolbarButton
                icon={ShapeStyleIcons[key]}
                tooltip={intl.formatMessage(messages[ShapeStyle[key]])}
                onClick={() => dispatch(dragShapeAction(getShape(ShapeStyle[key])))}
                dataTest={`general-panel-paste_${ShapeStyle[key]}`}
            />
        );
    });

    return (
        <ToolbarDropdown
            hideDropdownOnClickItem
            icon={icShape}
            options={shapeButtons}
            size="small"
            dataTest="general-panel-paste_shapes"
            disabled={disabled}
            tooltip={tooltip}
        />
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    paragraphStyle: {
        id: 'CommonToolbar.paragraphStyle',
        defaultMessage: 'Стиль абзаца',
    },
    fontFamily: {
        id: 'CommonToolbar.fontFamily',
        defaultMessage: 'Шрифт',
    },
    fontSize: {
        id: 'CommonToolbar.fontSize',
        defaultMessage: 'Размер шрифта',
    },
    fontColor: {
        id: 'CommonToolbar.fontColor',
        defaultMessage: 'Цвет текста',
    },
    textAlign: {
        id: 'CommonToolbar.textAlign',
        defaultMessage: 'Выравнивание текста',
    },
    textLeft: {
        id: 'CommonToolbar.textLeft',
        defaultMessage: 'Текст слева',
    },
    textCenter: {
        id: 'CommonToolbar.textCenter',
        defaultMessage: 'Текст по центру',
    },
    textRight: {
        id: 'CommonToolbar.textRight',
        defaultMessage: 'Текст справа',
    },
    textJustify: {
        id: 'CommonToolbar.textJustify',
        defaultMessage: 'Текст по ширине',
    },
    colorFilling: {
        id: 'CommonToolbar.colorFilling',
        defaultMessage: 'Заливка цвета',
    },
    indent: {
        id: 'CommonToolbar.indent',
        defaultMessage: 'Увеличить отступ',
    },
    outdent: {
        id: 'CommonToolbar.outdent',
        defaultMessage: 'Уменьшить отступ',
    },
    bulletList: {
        id: 'CommonToolbar.bulletList',
        defaultMessage: 'Маркированный список',
    },
    orderedList: {
        id: 'CommonToolbar.orderedList',
        defaultMessage: 'Нумерованный список',
    },
    taskList: {
        id: 'CommonToolbar.taskList',
        defaultMessage: 'Чек-лист',
    },
    sinkListItem: {
        id: 'CommonToolbar.sinkListItem',
        defaultMessage: 'Увеличить вложенность списка',
    },
    liftListItem: {
        id: 'CommonToolbar.liftListItem',
        defaultMessage: 'Уменьшить вложенность списка',
    },
    macros: {
        id: 'CommonToolbar.macros',
        defaultMessage: 'Макросы',
    },
    codeBlock: {
        id: 'CommonToolbar.codeBlock',
        defaultMessage: 'Блок кода',
    },
    blockquote: {
        id: 'CommonToolbar.blockquote',
        defaultMessage: 'Цитата',
    },
    tableOfContents: {
        id: 'CommonToolbar.tableOfContents',
        defaultMessage: 'Оглавление',
    },
    links: {
        id: 'CommonToolbar.links',
        defaultMessage: 'Ссылки',
    },
    internalLink: {
        id: 'CommonToolbar.internalLink',
        defaultMessage: 'Внутренняя ссылка',
    },
    externalLink: {
        id: 'CommonToolbar.externalLink',
        defaultMessage: 'Ссылка на сайт',
    },
    insertImage: {
        id: 'CommonToolbar.insertImage',
        defaultMessage: 'Вставить изображение',
    },
    insertImageFile: {
        id: 'CommonToolbar.insertImageFile',
        defaultMessage: 'Вставить файл изображения',
    },
    insertExternalImage: {
        id: 'CommonToolbar.insertExternalImage',
        defaultMessage: 'Вставить изображение по ссылке',
    },
    insertModelPreview: {
        id: 'CommonToolbar.insertModelPreview',
        defaultMessage: 'Вставить снимок модели',
    },
    insertTable: {
        id: 'CommonToolbar.insertTable',
        defaultMessage: 'Вставить таблицу',
    },
    eraser: {
        id: 'CommonToolbar.eraser',
        defaultMessage: 'Очистить форматирование',
    },
    textIndentation: {
        id: 'CommonToolbar.textIndentation',
        defaultMessage: 'Отступы',
    },
    lineHeight: {
        id: 'CommonToolbar.lineHeight',
        defaultMessage: 'Междустрочный интервал',
    },
    textBold: {
        id: 'CommonToolbar.textBold',
        defaultMessage: 'Жирный текст',
    },
    textItalic: {
        id: 'CommonToolbar.textItalic',
        defaultMessage: 'Наклонный текст',
    },
    textUnderline: {
        id: 'CommonToolbar.textUnderline',
        defaultMessage: 'Подчеркнутый текст',
    },
    moreInlineStyles: {
        id: 'CommonToolbar.moreInlineStyles',
        defaultMessage: 'Больше стилей текста',
    },
    textStrikeThrough: {
        id: 'CommonToolbar.textStrikeThrough',
        defaultMessage: 'Перечёркнутый текст',
    },
    subscript: {
        id: 'CommonToolbar.subscript',
        defaultMessage: 'Нижний индекс',
    },
    superscript: {
        id: 'CommonToolbar.superscript',
        defaultMessage: 'Верхний индекс',
    },
    monospace: {
        id: 'CommonToolbar.monospace',
        defaultMessage: 'Моноширинный шрифт',
    },
    lineHeightMessageRequired: {
        id: 'CommonToolbar.lineHeightMessageRequired',
        defaultMessage: 'Межстрочный интервал не должен быть пустым',
    },
    lineHeightMessageNumberOnly: {
        id: 'CommonToolbar.lineHeightMessageNumberOnly',
        defaultMessage: 'Межстрочный интервал должен состоять из положительных десятичных чисел',
    },
    formValidationMessageRequired: {
        id: 'CommonToolbar.formValidationMessageRequired',
        defaultMessage: 'Размер шрифта не должен быть пустым',
    },
    formValidationMessageNumberOnly: {
        id: 'CommonToolbar.formValidationMessageNumberOnly',
        defaultMessage: 'Размер шрифта должен состоять из положительных чисел',
    },
    formLabel: {
        id: 'CommonToolbar.formLabel',
        defaultMessage: 'Размер шрифта (pt)',
    },
    paragraph: {
        id: 'CommonToolbar.paragraph',
        defaultMessage: 'Абзац',
    },
    heading1: {
        id: 'CommonToolbar.heading1',
        defaultMessage: 'Заголовок 1',
    },
    heading2: {
        id: 'CommonToolbar.heading2',
        defaultMessage: 'Заголовок 2',
    },
    heading3: {
        id: 'CommonToolbar.heading3',
        defaultMessage: 'Заголовок 3',
    },
    heading4: {
        id: 'CommonToolbar.heading4',
        defaultMessage: 'Заголовок 4',
    },
    heading5: {
        id: 'CommonToolbar.heading5',
        defaultMessage: 'Заголовок 5',
    },
    heading6: {
        id: 'CommonToolbar.heading6',
        defaultMessage: 'Заголовок 6',
    },
});

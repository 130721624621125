import { TUITemplatesState } from './UITemplates.reducer.types';
import { LOAD_UI_TEMPLATES_SUCCESS } from '../actionTypes/UITemplates.actionTypes';
import { TReducer } from '@/utils/types';

const INITIAL_UI_TEMPLATES_STATE: TUITemplatesState = { templates: [] };

export const UITemplatesReducer: TReducer<TUITemplatesState> = (state = INITIAL_UI_TEMPLATES_STATE, action) => {
    switch (action.type) {
        case LOAD_UI_TEMPLATES_SUCCESS: {
            const {
                payload: { UITemplates },
            } = action;

            return {
                ...state,
                templates: UITemplates,
            };
        }

        default:
            return state;
    }
};

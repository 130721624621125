import type { Editor } from '@tiptap/react';
import icCheckList from 'icons/toolbar/controls/ic-check-list.svg';
import { useIntl } from 'react-intl';
import { SyntheticEvent, useContext } from 'react';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { StateObserver } from '../../common/sharedState.class';
import messages from '../CommonToolbar.messages';
import React from 'react';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';

const getFocusedBlock = (editor: Editor) => editor.isActive('taskList');

const getChangedBlock = (editor: Editor) => {
    return editor.chain().focus().toggleTaskList().run();
};

export const TaskList = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<boolean, SyntheticEvent<HTMLElement, Event>>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    return (
        <ToolbarButton
            icon={icCheckList}
            tooltip={intl.formatMessage(messages.taskList)}
            onClick={setCurrentState}
            dataTest={'wiki-toolbar-group_lists-button_checkList'}
            isActive={value}
            size="small"
        />
    );
};

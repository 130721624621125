import React from 'react';
import { EditModeButton } from '@/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/EditModeButton/EditModeButton';
import { OpenSettings } from './Buttons/OpenSettings.component';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { Refresh } from './Buttons/Refresh.component';
import { Paste } from './Buttons/Paste.component';
import { Copy } from './Buttons/Copy.component';
import { PasteEmptyLaneTop } from './Buttons/PasteEmptyLaneTop.component';
import { PasteEmptyLaneBottom } from './Buttons/PasteEmptyLaneBottom.component';
import { PasteColLeft } from './Buttons/PasteColLeft.component';
import { PasteColRight } from './Buttons/PasteColRight.component';
import { ResizableToolbar } from '../../../UIKit/components/Toolbar/ResizableToolbar/ResizableToolbar.component';
import { UndoButton } from '../GraphGeneralToolbar/ToolbarItems/UndoBtn/UndoButton.component';
import { RedoButton } from '../GraphGeneralToolbar/ToolbarItems/RedoBtn/RedoButton.component';
import { GetImage } from './Buttons/GetImage/GetImage.component';

export const MatrixEditorToolbar = () => {
    return (
        <ResizableToolbar>
            <ButtonGroup>
                <EditModeButton />
            </ButtonGroup>
            <ButtonGroup>
                <UndoButton />
                <RedoButton />
            </ButtonGroup>
            <ButtonGroup>
                <OpenSettings />
            </ButtonGroup>
            <ButtonGroup>
                <Refresh />
            </ButtonGroup>
            <ButtonGroup>
                <Paste />
                <Copy />
            </ButtonGroup>
            <ButtonGroup>
                <PasteEmptyLaneTop />
                <PasteEmptyLaneBottom />
            </ButtonGroup>
            <ButtonGroup>
                <PasteColLeft />
                <PasteColRight />
            </ButtonGroup>
            <ButtonGroup>
                <GetImage />
            </ButtonGroup>
        </ResizableToolbar>
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'WidgetsListSidePanel.title',
        defaultMessage: 'Виджеты',
    },
    searchResults: {
        id: 'WidgetsListSidePanel.searchResults',
        defaultMessage: 'Результаты поиска',
    },
});

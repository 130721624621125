import React, { useState } from 'react';
import { Table } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { SymbolConstants } from '../../../../../../../models/Symbols.constants';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { EdgeType, ModelEdgeDefinition, ModelType, ObjectType, Symbol } from '../../../../../../../serverapi/api';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import messages from '../ModelType.messages';
import presetsTheme from '../../Presets.scss';
import theme from './ModelAddEdgeDialog.component.scss';
import { Button } from '../../../../../../UIKit/components/Button/Button.component';
import { Select } from '../../../../../../UIKit/components/Select/Select.component';
import { SearchInput } from '../../../../../../UIKit/components/Select/SearchInput.component';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { TCheckboxStatus } from '@/modules/UIKit/components/Checkbox/Checkbox.types';

type TEdgesTabIntlProps = {
    modelType: ModelType;
    graph?: MethodologiesGraph;
    availableSymbols: Symbol[];
    availableEdgeType: EdgeType[];
    edgeDefinition?: ModelEdgeDefinition;
};

type TEdgesTabIntlActionProps = {
    onSave: (modelTypeEdge: ModelEdgeDefinition[]) => void;
    onCancel: () => void;
};

type TModelEdgeDialogIntlFullProps = TEdgesTabIntlProps & TEdgesTabIntlActionProps & WrappedComponentProps;

enum Step {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

const ModelAddEdgeDialog = (props: TModelEdgeDialogIntlFullProps) => {
    const EndpointType = {
        SYMBOL: props.intl.formatMessage(messages.symbol),
        OBJECT_TYPE: props.intl.formatMessage(messages.objectType),
        ANY: props.intl.formatMessage(messages.any),
    };

    type TEndpointType = typeof EndpointType.SYMBOL | typeof EndpointType.OBJECT_TYPE | typeof EndpointType.ANY;

    const sourceSymbol = props.edgeDefinition?.source || (props.availableSymbols && props.availableSymbols[0]?.id);
    const sourceObject =
        props.edgeDefinition?.sourceObject || (props.modelType?.objectTypes && props.modelType.objectTypes[0]?.id);
    const availableEdge = props.edgeDefinition?.edgeType || (props.availableEdgeType && props.availableEdgeType[0]?.id);
    const symbols = props.availableSymbols;
    const edgeTypes: EdgeType[] = props.availableEdgeType.sort((a, b) => a.name.localeCompare(b.name));

    const [step, setStep] = useState<Step>(Step.ONE);
    const [symbolFilter, setSymbolFilter] = useState<string>('');
    const [newSymbols, setNewSymbols] = useState<Symbol[]>([]);
    const [selectedSource, setSelectedSource] = useState<string>(sourceSymbol);
    const [selectedEdgeType, setSelectedEdgeType] = useState<string>(availableEdge);
    const [searchedEdgeType, setSearchedEdgeType] = useState<EdgeType[]>(edgeTypes);
    const [selectedSourceObject, setSelectedSourceObject] = useState<string>(sourceObject);
    const [inputValue, setInputValue] = React.useState('');

    const [sourceType, setSourceType] = useState<TEndpointType>(
        props.edgeDefinition?.sourceObject
            ? EndpointType.OBJECT_TYPE
            : props.edgeDefinition?.anySourceAllowed
            ? EndpointType.ANY
            : EndpointType.SYMBOL,
    );
    const [targetType, setTargetType] = useState<TEndpointType>(
        props.edgeDefinition?.destinationObject
            ? EndpointType.OBJECT_TYPE
            : props.edgeDefinition?.anyTargetAllowed
            ? EndpointType.ANY
            : EndpointType.SYMBOL,
    );

    const selectSource = (source: string) => {
        sourceType === EndpointType.SYMBOL && setSelectedSource(source);
        sourceType === EndpointType.OBJECT_TYPE && setSelectedSourceObject(source);
    };

    const getSelectedSourceBySelectedEndpointType = (data: Symbol[]) => {
        const selectedElement: Symbol | undefined = data.find((s: Symbol) =>
            sourceType === EndpointType.OBJECT_TYPE ? s.objectType === selectedSourceObject : s.id === selectedSource,
        );

        if (!selectedElement) return '';

        return SymbolToImageConverterGraph.convertSymbol(selectedElement, props.intl, props.graph);
    };

    const renderTitleRowActionButtons = () => {
        const selectedAllSymbols: boolean = newSymbols.length === symbols.length;
        const status: TCheckboxStatus = newSymbols.length && newSymbols.length < symbols.length ? 'indeterminate' : selectedAllSymbols;

        return (
            <div>
                <Checkbox
                    onChange={(status?: TCheckboxStatus) => {
                        if (status) {
                            setNewSymbols([...symbols]);
                        } else {
                            setNewSymbols([]);
                        }
                    }}
                    status={status}
                />
            </div>
        );
    };

    const renderDialogRowActionButtons = (symbol: Symbol, selectedTypeValue: TEndpointType) => {
        const field = selectedTypeValue === EndpointType.OBJECT_TYPE ? 'objectType' : 'id';
        const status = Boolean(newSymbols.find((s) => s[field] === symbol[field]));

        return (
            <div data-test={`edge-target-type-checkbox-value_${symbol.name}`}>
                <Checkbox
                    onChange={(status: boolean) => {
                        if (status) {
                            setNewSymbols([...newSymbols, symbol]);
                        } else {
                            setNewSymbols(newSymbols.filter((s) => s[field] !== symbol[field]));
                        }
                    }}
                    status={status}
                />
            </div>
        );
    };

    const filter = (symbol: Symbol, filter?: string) => {
        if (!filter) {
            return true;
        }

        const filterL = filter.toLowerCase();

        return symbol.name?.toLocaleLowerCase().includes(filterL) || symbol.id?.toLocaleLowerCase().includes(filterL);
    };

    const renderEndpoint = (selectedTypeValue: TEndpointType, onChangeType, title) => {
        const dialogColumns = [
            {
                title: () => renderTitleRowActionButtons(),
                width: 40,
                dataIndex: 'groupId',
                key: 'groupId-dialog',
                render: (value: string, record: Symbol) => renderDialogRowActionButtons(record, selectedTypeValue),
            },
            {
                title: props.intl.formatMessage(messages.symbol),
                dataIndex: 'graphical',
                key: 'graphical-symbol',
                render: (value: string, record: Symbol) => (
                    <div> {SymbolToImageConverterGraph.convertSymbol(record, props.intl, props.graph)} </div>
                ),
            },
        ];

        const data =
            selectedTypeValue === EndpointType.OBJECT_TYPE
                ? props.modelType.objectTypes
                      .reduce((acc: ObjectType[], ot) => {
                          if (!acc.some((objectType) => objectType.id === ot.id)) {
                              acc.push(ot);
                          }

                          return acc;
                      }, [])
                      .map((ot) => {
                          return {
                              ...ot,
                              ...SymbolConstants.ENDPOINT_SYMBOL,
                              objectType: ot.id,
                          } as Symbol;
                      })
                : symbols;

        const handleChangeType = (endpointType: TEndpointType) => {
            onChangeType(endpointType);

            setNewSymbols([]);
        };

        return (
            <div className={theme.selectEndPoint}>
                <Select
                    label={title}
                    data-test="edge-source-type-select"
                    value={selectedTypeValue}
                    onChange={handleChangeType}
                    originalTheme
                >
                    {Object.keys(EndpointType).map((s) => (
                        <Select.Option
                            data-test="edge-source-type-select-option"
                            key={EndpointType[s]}
                            value={EndpointType[s]}
                            label={EndpointType[s]}
                        />
                    ))}
                </Select>

                <div className={theme.selectedEndPointContainer}>
                    {selectedTypeValue !== EndpointType.ANY &&
                        (step === Step.ONE ? (
                            <Select
                                value={getSelectedSourceBySelectedEndpointType(data)}
                                originalTheme
                                dropdownClassName={theme.svgSelectDropdown}
                                onChange={selectSource}
                                data-test="edge-source-symbol-select"
                                wrapperClassName={theme.svgSelect}
                            >
                                {data.map((symbol: Symbol) => {
                                    const field = selectedTypeValue === EndpointType.OBJECT_TYPE ? 'objectType' : 'id';

                                    return (
                                        <Select.Option
                                            value={symbol[field]}
                                            key={symbol[field]}
                                            data-test={`edge-source-symbol-select-option_${symbol.name}`}
                                            label={SymbolToImageConverterGraph.convertSymbol(
                                                symbol,
                                                props.intl,
                                                props.graph,
                                            )}
                                        />
                                    );
                                })}
                            </Select>
                        ) : (
                            <div className={presetsTheme.creationTitle}>
                                <SearchInput
                                    data-test="edge-source-symbol_input"
                                    searchValue={symbolFilter}
                                    onSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setSymbolFilter(e.target.value)
                                    }
                                    showSearch
                                    originalTheme
                                />
                                <Table
                                    rowKey={({ id, objectType }: Symbol) => `${id + objectType}`}
                                    columns={dialogColumns}
                                    dataSource={data.filter((symbol: Symbol) => filter(symbol, symbolFilter))}
                                    size="small"
                                    scroll={{ y: 250 }}
                                    pagination={false}
                                />
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    const handleNext = () => setStep(step + 1);

    const handlePrev = () => setStep(step - 1);

    const handleOk = () => {
        // назначение объкт или символ
        if (targetType === EndpointType.SYMBOL || targetType === EndpointType.OBJECT_TYPE) {
            const newEdge = newSymbols.map((symbol: Symbol) => {
                return {
                    id: props.edgeDefinition?.id || uuid(),
                    modelTypeId: props.modelType.id,
                    edgeType: selectedEdgeType,
                    destination: targetType === EndpointType.SYMBOL ? symbol.id : undefined,
                    source: sourceType === EndpointType.SYMBOL ? selectedSource : undefined,
                    destinationObject: targetType === EndpointType.OBJECT_TYPE ? symbol.objectType : undefined,
                    sourceObject: sourceType === EndpointType.OBJECT_TYPE ? selectedSourceObject : undefined,
                    anySourceAllowed: sourceType === EndpointType.ANY,
                    anyTargetAllowed: false, // targetType === EndpointType.ANY
                };
            });
            if (!newEdge.length) {
                return;
            }
            props.onSave(newEdge);
        } else {
            // назначение любой
            const newEdge = [
                {
                    id: props.edgeDefinition?.id || uuid(),
                    modelTypeId: props.modelType.id,
                    edgeType: selectedEdgeType,
                    destination: undefined,
                    source: sourceType === EndpointType.SYMBOL ? selectedSource : undefined,
                    destinationObject: undefined,
                    sourceObject: sourceType === EndpointType.OBJECT_TYPE ? selectedSourceObject : undefined,
                    anySourceAllowed: sourceType === EndpointType.ANY,
                    anyTargetAllowed: targetType === EndpointType.ANY,
                },
            ];
            props.onSave(newEdge);
        }

        props.onCancel();
    };

    const handleSearch = (e: string) =>
        setSearchedEdgeType(edgeTypes.filter((edgeType) => edgeType.name.toLowerCase().includes(e.toLowerCase())));

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: props.onCancel,
                    value: props.intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'ok',
                    onClick: handleOk,
                    value: props.intl.formatMessage(messages.addButton),
                    visualStyle: 'primary',
                    disabled: !newSymbols.length && targetType !== EndpointType.ANY,
                },
            ]}
        />
    );

    const getSelectEdgeTypeValue = () => {
        const edgeType = searchedEdgeType.find((e) => e.id === selectedEdgeType);

        if (!edgeType) return '';

        return (
            <div className={theme.selectEdge}>
                <div className={theme.selectEdgeText} data-test={`edge-type-select-option-${edgeType.name}`}>
                    <span>{edgeType.name}</span>
                </div>
                <div className={theme.selectEdgeImg}>
                    {SymbolToImageConverterGraph.convertEdge(edgeType, props.intl, props.graph)}
                </div>
            </div>
        );
    };

    return (
        <Dialog
            width="400px"
            open
            onOk={handleOk}
            onCancel={props.onCancel}
            title={props.intl.formatMessage(messages.addEdges)}
            footer={footer}
            className={theme.dialog}
        >
            <div className={theme.edgeTypeDialogSelectRow}>
                {step === Step.ONE && (
                    <div>{renderEndpoint(sourceType, setSourceType, props.intl.formatMessage(messages.source))}</div>
                )}
                <div className={step === Step.TWO ? theme.selectEndPoint : theme.hidden}>
                    <Select
                        label={props.intl.formatMessage(messages.edgeType)}
                        data-test="add-edge-type_step-2_select"
                        value={getSelectEdgeTypeValue()}
                        wrapperClassName={theme.svgSelect}
                        dropdownClassName={theme.svgSelectDropdown}
                        originalTheme
                        showSearch
                        searchValue={inputValue}
                        onSearch={(e) => {
                            handleSearch(e);
                            setInputValue(e);
                        }}
                        onChange={(val) => val && setSelectedEdgeType(val)}
                    >
                        {searchedEdgeType.map((edgeType) => {
                            return (
                                <Select.Option
                                    key={edgeType.id}
                                    value={edgeType.id}
                                    data-test={`add-edge-type-select-option-${edgeType.name}`}
                                    label={
                                        <div className={theme.selectOption}>
                                            {edgeType.name}
                                            {SymbolToImageConverterGraph.convertEdge(edgeType, props.intl, props.graph)}
                                        </div>
                                    }
                                />
                            );
                        })}
                    </Select>
                </div>
                {step === Step.THREE && (
                    <div>{renderEndpoint(targetType, setTargetType, props.intl.formatMessage(messages.target))}</div>
                )}
                <div>
                    {step !== Step.ONE && (
                        <Button onClick={handlePrev}>{props.intl.formatMessage(messages.back)}</Button>
                    )}
                    {step !== Step.THREE && (
                        <Button onClick={handleNext}>{props.intl.formatMessage(messages.next)}</Button>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

const ModelEdgeDialogTabIntl = injectIntl(ModelAddEdgeDialog);

export { ModelEdgeDialogTabIntl as ModelAddEdgeDialog };

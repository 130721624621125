import { TReducer } from '@/utils/types';
import { TObjectConnectionsState } from './objectConnections.reducer.types';
import {
    OBJECT_CONNECTIONS_DELETE,
    OBJECT_CONNECTIONS_LOAD_SUCCESS,
} from '../actionsTypes/objectConnections.actionTypes';

const initial: TObjectConnectionsState = {
    byRepositoryId: {},
};

export const objectConnectionsReducer: TReducer<TObjectConnectionsState> = (state = initial, action) => {
    switch (action.type) {
        case OBJECT_CONNECTIONS_LOAD_SUCCESS: {
            const {
                payload: { connections, nodeId },
            } = action;
            const stateClone: TObjectConnectionsState = { ...state };

            if (!stateClone.byRepositoryId[nodeId.repositoryId]) stateClone.byRepositoryId[nodeId.repositoryId] = {};

            stateClone.byRepositoryId[nodeId.repositoryId][nodeId.id] = [...connections];

            return stateClone;
        }

        case OBJECT_CONNECTIONS_DELETE: {
            const {
                payload: { nodeId },
            } = action;
            const stateClone: TObjectConnectionsState = { ...state };
            delete stateClone.byRepositoryId[nodeId.repositoryId]?.[nodeId.id];

            return stateClone;
        }

        default:
            return state;
    }
};

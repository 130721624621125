import React from 'react';
import icLock from '@/resources/icons/MainMenuLock.svg';
import icUnLock from '@/resources/icons/MainMenuUnLock.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { EditorToolbarSelectors } from '../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { EditorMode } from '../../../../../models/editorMode';
import { dashboardEditorModeChanged } from '../../../../../actions/dashboard.actions';

export const DashboardEditModeButton = () => {
    const dispatch = useDispatch();
    const isReadMode: boolean = useSelector(TabsSelectors.checkIsReadModeInActiveTab);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getEditorModeDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getEditorModeTooltip);

    const handleModeChange = () => {
        dispatch(dashboardEditorModeChanged(isReadMode ? EditorMode.Edit : EditorMode.Read));
    };

    return (
        <ToolbarButton
            isActive={!isReadMode}
            icon={isReadMode ? icLock : icUnLock}
            size="small"
            dataTest={'dashboard-toolbar_edit-model-button'}
            disabled={disabled}
            onClick={handleModeChange}
            tooltip={tooltip}
        />
    );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WidgetsContainer__widgetsContainer__wgBq4{display:-webkit-box;display:-ms-flexbox;display:flex;height:100%}.WidgetsContainer__cellsContainer__guXOj{width:100%;min-height:100%;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;position:relative}.WidgetsContainer__cellsBackground__SSU88{background:linear-gradient(var(--Neutral500), transparent 1px),linear-gradient(90deg, var(--Neutral500), transparent 1px);border-bottom:1px solid var(--Neutral300);border-radius:0}.WidgetsContainer__scrollContainer___htNx{position:relative;width:100%;height:100%;overflow-y:auto;overflow-x:hidden}", "",{"version":3,"sources":["webpack://./src/modules/UITemplates/components/CreateUITemplateTab/WidgetsContainer/WidgetsContainer.scss"],"names":[],"mappings":"AAAA,2CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,WAAA,CAGJ,yCACI,UAAA,CACA,eAAA,CACA,kBAAA,CAAA,mBAAA,CAAA,WAAA,CACA,iBAAA,CAGJ,0CACI,yHAAA,CAEA,yCAAA,CACA,eAAA,CAGJ,0CACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".widgetsContainer {\r\n    display: flex;\r\n    height: 100%;\r\n}\r\n\r\n.cellsContainer {\r\n    width: 100%;\r\n    min-height: 100%;\r\n    flex-grow: 1;\r\n    position: relative;\r\n}\r\n\r\n.cellsBackground {\r\n    background: linear-gradient(var(--Neutral500), transparent 1px),\r\n        linear-gradient(90deg, var(--Neutral500), transparent 1px);\r\n    border-bottom: 1px solid var(--Neutral300);\r\n    border-radius: 0;\r\n}\r\n\r\n.scrollContainer {\r\n    position: relative;\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetsContainer": "WidgetsContainer__widgetsContainer__wgBq4",
	"cellsContainer": "WidgetsContainer__cellsContainer__guXOj",
	"cellsBackground": "WidgetsContainer__cellsBackground__SSU88",
	"scrollContainer": "WidgetsContainer__scrollContainer___htNx"
};
export default ___CSS_LOADER_EXPORT___;

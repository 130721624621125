import { useIntl } from 'react-intl';
import messages from '../../FormatPanel.messages';
import { useDispatch, useSelector } from 'react-redux';
import theme from './FontAndFillingColor.scss';
import { ColorResult } from 'react-color';
import { changeFontColor } from '../../../../actions/editor.actions';
import { ColorPicker } from '../../../UIKit/components/ColorPicker/ColorPicker.component';
import { FormatPanelButtonsState } from '../../../../selectors/generalMenu.selectors';
import React from 'react';
import { FormatPanelSelectors } from '../../../../selectors/formatPanel.selectors';

export const FontColorBlock = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentColor: string | undefined = useSelector(FormatPanelButtonsState.getFontColor);
    const disabled: boolean = useSelector(FormatPanelSelectors.getFormatButtonsDisabled);
    const tooltip: string = useSelector(FormatPanelSelectors.getFormatButtonsTooltip(''));

    const handleFontColorChange = (color: ColorResult) => {
        dispatch(changeFontColor(color.hex));
    };

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.fontColor)}</div>
            <ColorPicker
                currentColor={currentColor}
                onChange={handleFontColorChange}
                disabled={disabled}
                dataTest={'object-toolbar_font-text-color'}
                tooltip={tooltip}
            />
        </div>
    );
};

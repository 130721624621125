import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../messages/CommonToolbar.messages';
import { CanvasSettings } from '../../../../../../selectors/canvas-settings.selectors';
import { GridTypeIcons, GridTypeOnCanvas } from '../../GridTypeOnCanvasConstant';
import { toggleGrid } from '../../../../../../actions/canvas-settings.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { ToolbarDropdown } from '../../../../../UIKit/components/Toolbar/ToolbarDropdown/ToolbarDropdown.component';

export const SwitchGridLayoutDropdown = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const gridType: string | undefined = useSelector(CanvasSettings.gridType);
    const tooltip: string = useSelector(EditorToolbarSelectors.getGridDropdownTooltip);
    const gridButtons: JSX.Element[] = Object.keys(GridTypeOnCanvas).map((key) => {
        return (
            <ToolbarButton
                icon={GridTypeIcons[key]}
                dataTest={`graph-general-toolbar_context-menu_${key}`}
                tooltip={intl.formatMessage(messages[key])}
                onClick={() => dispatch(toggleGrid({ gridType: GridTypeOnCanvas[key] }))}
                isActive={GridTypeOnCanvas[key] === gridType}
            />
        );
    });

    return (
        <ToolbarDropdown
            options={gridButtons}
            icon={GridTypeIcons[gridType || ''] || GridTypeIcons['noGrid']}
            size="small"
            tooltip={tooltip}
            dataTest="graph-general-toolbar_grid-on-button"
        />
    );
};

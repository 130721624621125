import React from 'react';
import { CellMeasurer, TableCellProps } from 'react-virtualized';
import { EllipsisText } from '../../../AdminTools/Methodology/components/MethodologySettingPage/EllipsisText/EllipsisText.component';
import { CellMeasurerCacheInterface } from 'react-virtualized/dist/es/CellMeasurer';
import { NodeId } from '@/serverapi/api';
import theme from './TableUIKitComponent.scss';

type TColumnCellProps = {
    index: number;
    cellProps: TableCellProps;
    cache: CellMeasurerCacheInterface;
    value: React.ReactNode | NodeId;
    rowLineHeight: number;
};

export const ColumnCell = (props: TColumnCellProps) => {
    const { index, cellProps, cache, value, rowLineHeight } = props;
    const { dataKey, parent, rowIndex } = cellProps;

    return (
        <CellMeasurer cache={cache} key={dataKey} parent={parent} rowIndex={rowIndex} columnIndex={index}>
            <div className={`${theme.cell}`} style={{ lineHeight: `${rowLineHeight}px` }}>
                <div>
                    {typeof value === 'string' ? (
                        <EllipsisText text={value} />
                    ) : (
                        <span>{value as React.ReactNode}</span>
                    )}
                </div>
            </div>
        </CellMeasurer>
    );
};

/* eslint-disable no-bitwise */
import type { TEdgeTypeSelectorState } from '../models/edgeTypeSelectorState.types';
import type { BPMMxGraph } from '../mxgraph/bpmgraph';
import { MxCell, MxUndoableEdit, MxConstants, MxUtils, MxEvent, MxGeometry, MxPoint } from '../mxgraph/mxgraph';
import { ButtonEditLabelState } from '../models/buttonEditLabelState';
import { isUndefined } from 'is-what';
import { availableAlignments } from '../sagas/editor.saga.constants';
import { EdgeType, DiagramElement, DiagramElementTypeEnum } from '../serverapi/api';
import { initialEdgeTypeSelectorState } from '../models/edgeTypeSelectorState';
import { EntityEnum, TSelectedElement } from '../models/navigatorPropertiesSelectorState.types';
import { NotationHelper } from '../services/utils/NotationHelper';
import { BPMMxGraphModel } from '../mxgraph/BPMMxGraphModel.class';
import { BPMMxConstants } from '../mxgraph/bpmgraph.constants';
import { EditorMode } from '../models/editorMode';
import { getNewEdgeStyle, getShapeId, getShapeType } from './css.utils';
import { Alignment } from '../models/alignment';
import { intersectionWith } from 'lodash-es';
import { getCopyableCells } from '@/services/bll/BpmMxEditorBLLService';
import { SymbolType } from '@/models/Symbols.constants';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { EXCLUDE_EDGE_VIEW_STYLE_PARAMS } from './constants/css.utils.constants';
import {
    getCellsDirection,
    getEdgesArrow,
    getEdgesType,
    getElementsFillingColor,
    getElementsFontColor,
    getLinesType,
} from '../modules/FormatPanel/utils/format.utils';
import { ModelTypes } from '@/models/ModelTypes';

export enum CellTypes {
    Object = 'object',
    Edge = 'edge',
    Shape = 'shape',
    Label = 'label',
}

export const isIgnoredUndoableEdit = (edit: MxUndoableEdit): boolean => {
    let ignoreEdit = false;

    for (let i = 0; i < edit.changes.length; i++) {
        if (
            edit.changes[i].cell !== undefined &&
            MxUtils.indexOfStylename(edit.changes[i].cell.getStyle(), 'undoable=0') !== -1
        ) {
            ignoreEdit = true;
        }
        if (
            edit.changes[i].child !== undefined &&
            MxUtils.indexOfStylename(edit.changes[i].child.getStyle(), 'undoable=0') !== -1
        ) {
            ignoreEdit = true;
        }
    }

    if (edit.changes.length === 0) {
        ignoreEdit = true;
    }

    return ignoreEdit;
};

export const isLabelChange = (edit: MxUndoableEdit) => {
    const diffList = edit.changes;
    const diff = diffList[0];

    return diffList.length === 1 && diff.value === diff.previous;
};

export const hasForbiddenSymbols = (cellValue: any, sourceCells: MxCell[]) =>
    sourceCells.some((el) => cellValue.psdCellMetaInfo?.allowedSymbols.indexOf(el.getValue().symbolId) === -1);

export const setIgnoredEdit = (graph: BPMMxGraph, cell: MxCell): void => {
    graph.setCellStyles(BPMMxConstants.STYLE_UNDOABLE, '0', [cell]);
};

export const handleSelectedEvent = (arrayCell: MxCell[], graph: BPMMxGraph): ButtonEditLabelState => {
    // on selection group of cells we setup active buttons in general menu according rules bellow:
    // for FONT_STYLE: we search for all applied styles and select all appropriate buttons in general menu (BUI)
    // on press on buttons we will toggle styles
    // for STYLE_ALIGN: we search for all applied styles if we found just one style, we will select appropriate button
    // in general menu, in other case will select - nothing, on press to align buttons will
    // apply one style for all elements
    const result = new ButtonEditLabelState();
    let countAlignStyle: number = 0;
    let countFontSize: number = 0;
    let countFontFamily: number = 0;
    let lastAlignStyle: string;
    let lastFontSize: string = '';
    let lastFontFamily: string = '';
    const COUNT_POSSIBLE_DIFFERENT_STYLES: number = 1;
    const selectedCellsWithoutImagesAndComments = arrayCell.filter(
        (cell) => cell.getValue().type !== 'CommentMarker' && !cell.getValue().imageId,
    );
    result.horizontal = getCellsDirection(selectedCellsWithoutImagesAndComments);
    result.lineType = getLinesType(selectedCellsWithoutImagesAndComments);
    result.startArrow = getEdgesArrow(selectedCellsWithoutImagesAndComments, 'startArrow');
    result.endArrow = getEdgesArrow(selectedCellsWithoutImagesAndComments, 'endArrow');
    result.edgeTypeId = getEdgesType(selectedCellsWithoutImagesAndComments);
    result.fontColor = getElementsFontColor(selectedCellsWithoutImagesAndComments);
    result.fillColor = getElementsFillingColor(selectedCellsWithoutImagesAndComments);

    if (selectedCellsWithoutImagesAndComments.length > 0) {
        selectedCellsWithoutImagesAndComments.forEach((cell: MxCell) => {
            const currentStyles: any[] = graph.getCellStyle(cell); // tslint:disable-line:no-any
            let currentFontStyle: number = currentStyles[MxConstants.STYLE_FONTSTYLE];
            const currentAlignStyle: string = currentStyles[MxConstants.STYLE_ALIGN];
            let currentFontSize: string = currentStyles[MxConstants.STYLE_FONTSIZE];
            let currentFontFontFamily: string = currentStyles[MxConstants.STYLE_FONTFAMILY];
            if (isUndefined(currentFontStyle)) {
                currentFontStyle = 0;
            }
            if (isUndefined(currentFontSize)) {
                currentFontSize = MxConstants.DEFAULT_FONTSIZE;
            }
            if (isUndefined(currentFontFontFamily)) {
                [currentFontFontFamily] = MxConstants.DEFAULT_FONTFAMILY.split(',');
            }
            /* tslint:disable:no-bitwise */
            result.isFontBoldSelected =
                (currentFontStyle & MxConstants.FONT_BOLD) === 0 ? result.isFontBoldSelected : true;
            result.isFontItalicSelected =
                (currentFontStyle & MxConstants.FONT_ITALIC) === 0 ? result.isFontItalicSelected : true;
            result.isFontUnderlineSelected =
                (currentFontStyle & MxConstants.FONT_UNDERLINE) === 0 ? result.isFontUnderlineSelected : true;
            /* tslint:enable:no-bitwise */
            if (currentFontSize !== lastFontSize) {
                countFontSize++;
                lastFontSize = currentFontSize;
            }

            if (currentAlignStyle !== lastAlignStyle) {
                countAlignStyle++;
                lastAlignStyle = currentAlignStyle;
            }

            if (currentFontFontFamily !== lastFontFamily) {
                countFontFamily++;
                lastFontFamily = currentFontFontFamily;
            }
        });
        if (countAlignStyle === COUNT_POSSIBLE_DIFFERENT_STYLES) {
            const keyAlignment = Object.keys(availableAlignments).filter((key: string) => {
                return availableAlignments[key] === lastAlignStyle;
            })[0];
            result.alignment = parseInt(keyAlignment, 10);
        } else {
            result.alignment = Alignment.CenterHorz;
        }
        if (countFontSize === COUNT_POSSIBLE_DIFFERENT_STYLES) {
            result.fontSize = lastFontSize;
        } else {
            result.fontSize = '';
        }
        if (countFontFamily === COUNT_POSSIBLE_DIFFERENT_STYLES) {
            result.fontFamily = lastFontFamily;
        } else {
            result.fontFamily = '';
        }
    }

    return result;
};

export const getAvailableEdgeTypesForEvent = (cells: MxCell[], graph: BPMMxGraph): TEdgeTypeSelectorState => {
    // TODO: implement "smart" type switching
    const { modelType, id } = graph;
    const selectedEdges = cells.filter((c) => c.isEdge());
    const result = { ...initialEdgeTypeSelectorState };
    const types: (EdgeType | null)[][] = selectedEdges.map((selectedEdge) => {
        if (selectedEdge && selectedEdge.source && selectedEdge.target && modelType) {
            return (
                NotationHelper.getEdgeTypeBySourceAndDestination(
                    selectedEdge.source.value,
                    selectedEdge.target.value,
                    id.serverId,
                    modelType,
                ) || []
            );
        }

        return [];
    });

    result.availableTypes = intersectionWith(...types, (a: EdgeType | null, b: EdgeType | null) => a && a.id === b?.id);

    return result;
};

export const graphContainsEvent = (evt: PointerEvent, graph: BPMMxGraph) => {
    if (!graph.container) return false;

    const x = MxEvent.getClientX(evt);
    const y = MxEvent.getClientY(evt);
    const offset = MxUtils.getOffset(graph.container);
    const origin = MxUtils.getScrollOrigin();

    // Checks if event is inside the bounds of the graph container
    return (
        x >= offset.x - origin.x &&
        y >= offset.y - origin.y &&
        x <= offset.x - origin.x + graph.container.offsetWidth &&
        y <= offset.y - origin.y + graph.container.offsetHeight
    );
};

export const applyFontStyle = (arrayCell: MxCell[], graph: BPMMxGraph, fontStyle: number, apply: boolean) => {
    graph.getModel().beginUpdate();

    try {
        if (graph.cellEditor.isContentEditing()) {
            switch (fontStyle) {
                case MxConstants.FONT_BOLD:
                    document.execCommand('bold', false);
                    break;
                case MxConstants.FONT_ITALIC:
                    document.execCommand('italic', false);
                    break;
                case MxConstants.FONT_UNDERLINE:
                    document.execCommand('underline', false);
                    break;
                default:
                    break;
            }
        }

        arrayCell.forEach((cell: MxCell) => {
            const currentStyles: any[] = graph.getCellStyle(cell);
            let currentFontStyle: number = currentStyles[MxConstants.STYLE_FONTSTYLE];

            if (isUndefined(currentFontStyle)) {
                currentFontStyle = 0;
            }

            let calculatedFontStyle;

            if (apply) {
                calculatedFontStyle = currentFontStyle | fontStyle;
            } else {
                calculatedFontStyle = currentFontStyle & ~fontStyle;
            }

            graph.setCellStyles(MxConstants.STYLE_FONTSTYLE, calculatedFontStyle.toString(), [cell]);
        });
    } finally {
        graph.getModel().endUpdate();
    }
};

const getJumpedNeighborIndex = (arrayCell: MxCell[], currentIndex: number, ascending: boolean) => {
    const currentCell = arrayCell[currentIndex];
    const currentCellId = currentCell.value.type === CellTypes.Label ? currentCell.value.mainCellId : currentCell.id;
    const nextCell = ascending ? arrayCell[currentIndex + 1] : arrayCell[currentIndex - 1];

    if (!nextCell) {
        return null;
    }

    const nextCellId = nextCell.value.type === CellTypes.Label ? nextCell.value.mainCellId : nextCell.id;

    if (ascending) {
        return currentCellId === nextCellId ? currentIndex + 2 : currentIndex + 1;
    }

    return currentCellId === nextCellId ? currentIndex - 2 : currentIndex - 1;
};

/**
 *
 * Возращает индекс слоя для ячейки
 *
 * @param cell
 * @param parent
 * @param orderIndex
 * @param lift переместить на слой выше
 *
 */
const getCellLayerIndex = (cell: MxCell, parent: MxCell, orderIndex: number, lift: boolean): number | undefined => {
    const currentIndex = parent.getIndex(cell);
    const jumpedNeighborIndex = getJumpedNeighborIndex(parent.children, currentIndex, lift);

    if (!jumpedNeighborIndex) {
        return;
    }

    const jumpedNeighbor: MxCell | undefined = parent.children[jumpedNeighborIndex];

    if (!jumpedNeighbor) {
        return;
    }

    const step: number = [CellTypes.Shape, CellTypes.Edge].includes(jumpedNeighbor.getValue().type) ? 1 : 2;

    let index: number | undefined;

    if (lift) {
        index = currentIndex + step + orderIndex;
    } else {
        index = currentIndex - step - orderIndex;
        index = index < 0 ? 0 : index;
    }

    return index;
};

/**
 *
 * Перемещает ячейки и их лейблы на слой выше
 *
 * @param cells
 * @param graph
 * @param lift переместить на слой выше
 *
 */
export const changeCellsLayerIndex = (cells: MxCell[], graph: BPMMxGraph, lift: boolean) => {
    graph.getModel().beginUpdate();

    try {
        const model = graph.getModel();

        for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            const parent = model.getParent(cell);
            const index = getCellLayerIndex(cell, parent, i, lift);

            if (!(typeof index === 'number')) {
                continue;
            }

            const symbolCells = ComplexSymbolManager.getSymbolCells([cell]);

            symbolCells.forEach((symbolCell, symbolIndex) => model.add(parent, symbolCell, index + symbolIndex));
        }
    } finally {
        graph.getModel().endUpdate();
    }
};

type TSimpleDiagramElementWithLabels = {
    id: string;
    type: string;
};

const transformToSimpleDiagramElementWithLabels = (dElements: DiagramElement[]): TSimpleDiagramElementWithLabels[] => {
    // в массиве DiagramElement у объектов нет лейблов, поэтому нужно их добавить чтобы индексы совпадали с model.cells
    const simpleElements: TSimpleDiagramElementWithLabels[] = [];
    dElements.forEach(({ id, type }) => {
        simpleElements.push({ id, type });
        if (type === CellTypes.Object) {
            simpleElements.push({ id, type: CellTypes.Label });
        }
    });

    return simpleElements;
};

export const changeEdgeLayerIndex = (graph: BPMMxGraph, dElements: DiagramElement[]) => {
    const simpleElements = transformToSimpleDiagramElementWithLabels(dElements);
    const model = graph.getModel();
    //BPM-10379
    //После иморта в PSD модель пришли edge с parentId = '1' (потеряли свой нормальный parentId)
    //Из-за этого, функция changeEdgeLayerIndex переносила их в позиции колонок, которые они не должны были занимать
    //Из-за этого неправильно считался index для вставки символов в ячейки PSD
    if (graph.modelType?.id === ModelTypes.PSD_MODEL) {
        return;
    }

    const arrayCell = Object.values<MxCell>(model.cells).filter((cell) => cell.value);

    arrayCell.forEach((cell) => {
        if (cell.value.type === CellTypes.Edge) {
            const elIndex = simpleElements.findIndex((el) => el.id === cell.id && el.type === cell.value.type);
            const parent = model.getParent(cell);
            model.add(parent, cell, elIndex);
        }
    });
};

export const getCellEntityType = (cell?: MxCell): EntityEnum | undefined => {
    if (!cell) return undefined;

    const cellType: DiagramElementTypeEnum = cell.getValue()?.type;
    // Проверка если shape является картинкой для отображения свойств картинки в панели свойств
    const isImage: boolean = !!cell.getValue()?.imageId;

    switch (cellType) {
        case CellTypes.Object:
            return EntityEnum.OBJECT;
        case CellTypes.Edge:
            return EntityEnum.EDGE;
        case CellTypes.Shape:
            return isImage ? EntityEnum.FILE : undefined;
        default:
            return undefined;
    }
};

export const getLastSelectionObjectDefinitionForEvent = (
    cells: MxCell[] | null,
    graph: BPMMxGraph,
): TSelectedElement => {
    const lastCell: MxCell | undefined = cells?.at(-1);
    const cellType: string | undefined = lastCell?.getValue()?.type;

    if (!lastCell || cellType === 'layout') {
        return {};
    }

    const cell = cellType === CellTypes.Label ? graph.model.getCell(lastCell.getValue()?.mainCellId) : lastCell;

    return {
        cellId: cell.id,
    };
};

export const calculateRelativeCoordinatesForFoldedElement = (
    newParent: MxCell,
    previousParent: MxCell,
    graph: BPMMxGraph,
    point: MxPoint,
) => {
    const parentState = graph.view.getState(newParent);
    const oldState = graph.view.getState(previousParent);
    const o1 = parentState.origin;
    const o2 = oldState.origin;
    const dx = o2.x - o1.x;
    const dy = o2.y - o1.y;
    const geo = new MxGeometry(point.x, point.y);
    geo.translate(dx, dy);
    point.x = Math.max(0, geo.x);
    point.y = Math.max(0, geo.y);

    return point;
};

export const copySizesToCell = (targetCell: MxCell | undefined, sourceCell: MxCell | undefined, graph: BPMMxGraph) => {
    // если кликнули на пустое место на холсте то targetCell не будет
    if (!targetCell?.value || !sourceCell?.value) {
        return;
    }
    const availableTypes = [CellTypes.Object, CellTypes.Label, CellTypes.Shape];
    const sourceType = sourceCell.value.type;
    const targetType = targetCell.value.type;

    if (!availableTypes.includes(targetType) || !availableTypes.includes(sourceType)) {
        return;
    }

    if (targetType !== sourceType || targetCell.complexSymbolTypeId) {
        return;
    }

    const model = graph.getModel();
    const sourceGeo = model.getGeometry(sourceCell);
    const targetGeo = model.getGeometry(targetCell);
    targetGeo.height = sourceGeo.height;
    targetGeo.width = sourceGeo.width;

    model.setGeometry(targetCell, targetGeo);
};

export const getRelativeLabelPos = (cell: MxCell, label: MxCell, model: BPMMxGraphModel) => {
    const labelGeo = model.getGeometry(label);
    const cellGeo = model.getGeometry(cell);

    return {
        x: labelGeo.x - cellGeo.x,
        y: labelGeo.y - cellGeo.y,
    };
};

export const copyPositionToLabel = (
    targetCell: MxCell | undefined,
    targetLabel: MxCell | undefined,
    sourceCell: MxCell | undefined,
    sourceLabel: MxCell | undefined,
    graph: BPMMxGraph,
) => {
    // если кликнули на пустое место на холсте то targetCell не будет
    if (!targetCell?.value || !targetLabel || !sourceCell?.value || !sourceLabel) {
        return;
    }

    if (targetCell.value.type !== CellTypes.Object || sourceCell.value.type !== CellTypes.Object) {
        return;
    }

    const model = graph.getModel();
    const sourceLabelRelativePos = getRelativeLabelPos(sourceCell, sourceLabel, model);
    const targetLabelGeo = model.getGeometry(targetLabel);
    const targetCellGeo = model.getGeometry(targetCell);

    targetLabelGeo.x = targetCellGeo.x + sourceLabelRelativePos.x;
    targetLabelGeo.y = targetCellGeo.y + sourceLabelRelativePos.y;

    model.setGeometry(targetLabel, targetLabelGeo);
};

export const copyStylesToCell = (targetCell: MxCell | undefined, sourceCell: MxCell | undefined) => {
    if (!targetCell?.getValue?.() || !sourceCell?.getValue?.()) {
        return;
    }

    const availableTypes = [CellTypes.Object, CellTypes.Label, CellTypes.Shape, CellTypes.Edge];
    const targetType = targetCell.getValue().type;
    const sourceType = sourceCell.getValue().type;
    const targetStyle = targetCell.getStyle();
    const sourceStyle = sourceCell.getStyle();
    const targetShapeType = getShapeType(targetStyle);
    const targetShapeId = getShapeId(targetStyle);
    const sourceShapeId = getShapeId(sourceStyle);

    let sourceStyleWithTargetSymbol: string = '';

    if (!availableTypes.includes(targetType) || !availableTypes.includes(sourceType)) {
        return;
    }

    if (targetType !== sourceType || targetCell.complexSymbolTypeId) {
        return;
    }

    if (targetType === CellTypes.Object || targetType === CellTypes.Label) {
        sourceStyleWithTargetSymbol = sourceStyle.replace(
            sourceCell.getValue().symbolId,
            targetCell.getValue().symbolId,
        );
    } else if (targetShapeType === CellTypes.Shape) {
        if (isImageCell(targetCell)) {
            return;
        }

        sourceStyleWithTargetSymbol = sourceStyle.replace(sourceShapeId, targetShapeId).replace('strokeColor=none', '');
    } else if (targetShapeType === 'text' && targetType !== CellTypes.Edge) {
        if (!sourceStyle.includes(';strokeColor=none')) {
            sourceStyleWithTargetSymbol = sourceStyle.replace(sourceShapeId, ';strokeColor=none');
        } else {
            sourceStyleWithTargetSymbol = sourceStyle;
        }
    } else if (targetType === CellTypes.Edge) {
        sourceStyleWithTargetSymbol =
            getNewEdgeStyle(sourceStyle, targetStyle, EXCLUDE_EDGE_VIEW_STYLE_PARAMS) || targetStyle;
    }

    targetCell.setStyle(sourceStyleWithTargetSymbol);
};

export const isGraphOnEditMode = (graph: BPMMxGraph): boolean => {
    return graph.mode === EditorMode.Edit;
};

export function checkIfElementsAreRestrictedForCut(graph: BPMMxGraph): boolean {
    const selectedCells = graph.getSelectionCells();
    const anyMarkerSelected: boolean = selectedCells.some((cell) => cell.getValue()?.type === 'CommentMarker');
    const copyableCells = getCopyableCells(graph, selectedCells);

    if (anyMarkerSelected || copyableCells.length === 0) {
        return true;
    }

    return false;
}

export class CancelablePromise {
    private controller = new AbortController();

    public cancel() {
        this.controller.abort();
    }

    public get(cb) {
        new Promise<void>((resolve, reject) => {
            const listener = () => {
                this.controller.signal.removeEventListener('abort', listener);

                return reject();
            };
            this.controller.signal.addEventListener('abort', listener);
            cb && cb();

            return resolve();
        });
    }
}

export const pointToPercent = (point: MxPoint, target?: MxCell) => {
    if (!target) return point;

    const trgGeo: MxGeometry = target.getGeometry();

    if (!trgGeo) {
        return point;
    }

    return new MxPoint(point.x / trgGeo.width, point.y / trgGeo.height);
};

export const percentToPoint = (percentPoint: MxPoint, target?: MxCell) => {
    if (!target) return percentPoint;

    const trgGeo: MxGeometry = target.getGeometry();

    if (!trgGeo) {
        return percentPoint;
    }

    return new MxPoint(percentPoint.x * trgGeo.width, percentPoint.y * trgGeo.height);
};

export const isCommentCell = (cell: MxCell): boolean => {
    return cell?.value?.type === SymbolType.COMMENT;
};

export const showCellBox = (graph: BPMMxGraph, cell: MxCell): void => {
    MxUtils.setCellStyles(graph.getModel(), [cell], MxConstants.STYLE_STROKECOLOR, graph.labelBorderColor);
    MxUtils.setCellStyles(graph.getModel(), [cell], MxConstants.STYLE_DASHED, '1');
    MxUtils.setCellStyles(graph.getModel(), [cell], MxConstants.STYLE_STROKEWIDTH, '1');
};

export const hideCellBox = (graph: BPMMxGraph, cell: MxCell): void => {
    MxUtils.setCellStyles(graph.getModel(), [cell], MxConstants.STYLE_STROKECOLOR, 'none');
    MxUtils.setCellStyles(graph.getModel(), [cell], MxConstants.STYLE_DASHED, null);
    MxUtils.setCellStyles(graph.getModel(), [cell], MxConstants.STYLE_STROKEWIDTH, null);
};

export const isImageCell = (cell: MxCell): boolean => {
    const style: string = cell.getStyle();
    const shapeId: string = getShapeId(style);

    return shapeId.includes(PictureSymbolConstants.SHAPE_IMAGE);
};

import { useDispatch, useSelector } from 'react-redux';
import { MatrixEditorSelectors } from '../../../../Matrix/selectors/matrixEditor.selectors';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../serverapi/api';
import { ToolbarButton } from '../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import React from 'react';
import { refreshMatrix } from '../../../../Matrix/actions/matrix.actions';
import icRefreshMatrix from '@/resources/icons/ic-update.svg';

export const Refresh = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(MatrixEditorSelectors.getRefreshDisabled);
    const tooltip: string = useSelector(MatrixEditorSelectors.getRefreshTooltip);
    const activeTabNodeId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const refreshMatrixHandler = () => {
        dispatch(refreshMatrix(activeTabNodeId));
    };

    return (
        <ToolbarButton
            icon={icRefreshMatrix}
            size="small"
            disabled={disabled}
            onClick={refreshMatrixHandler}
            tooltip={tooltip}
            dataTest="graph-general-toolbar_refresh-matrix"
        />
    );
};

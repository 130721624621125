import { ISearchRuleWithValueId } from '@/modules/Search/AttributeFilter/AttributeFilter.types';

import {  AIP_SEARCH_ALIASES_REQUEST, AIP_SEARCH_ALIASES_REQUEST_SUCCESS, AIP_SEARCH_CREATE_ALIAS_REQUEST, AIP_SEARCH_DELETE_BY_ID_ALIAS_REQUEST, GET_AIP_SEARCH_RESULT, SET_AIP_SEARCH_DATA } from '../actionsTypes/assistantInterface.actionTypes';
import { TGetSearchResultAction, TSetAipSearchDataAction, TSearchDataPayload, TBpmAipSearchGetAllAliasesAction, TAipSearchAliasesSuccessAction, TAipSearchCreateAliasRequestAction, TAipSearchDeleteByIdAliasRequestAction } from './assistantInterface.actions.types';
import { AipAlias, NodeId, SearchRequestNodeTypesEnum, SearchRequestSearchVisibilityEnum } from '@/serverapi/api';

export const getSearchResult = (
    searchText: string,
    searchRules: ISearchRuleWithValueId[],
    searchVisibility: SearchRequestSearchVisibilityEnum,
    searchNodeTypes: SearchRequestNodeTypesEnum[],
    nodeId: NodeId,
): TGetSearchResultAction => ({
    type: GET_AIP_SEARCH_RESULT,
    payload: { searchText, searchRules, searchVisibility, searchNodeTypes, nodeId },
});

export const setAipSearchData = (payload: TSearchDataPayload): TSetAipSearchDataAction => ({
    payload,
    type: SET_AIP_SEARCH_DATA,
});

export const getAipSearchAliases = (): TBpmAipSearchGetAllAliasesAction => ({
    type: AIP_SEARCH_ALIASES_REQUEST
});

export const getAipSearchAliasesRequestSuccess = (aliases: AipAlias[]): TAipSearchAliasesSuccessAction => ({
    type: AIP_SEARCH_ALIASES_REQUEST_SUCCESS,
    payload: { aliases }
});

export const createAipSearchAlias = (alias: AipAlias): TAipSearchCreateAliasRequestAction => ({
    type: AIP_SEARCH_CREATE_ALIAS_REQUEST,
    payload: { alias }
});

export const deleteByIdAipSearchAlias = (id: string): TAipSearchDeleteByIdAliasRequestAction => ({
    type: AIP_SEARCH_DELETE_BY_ID_ALIAS_REQUEST,
    payload: { id }
});
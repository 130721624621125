import React from 'react';
import { ToolbarButton } from '../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import icCenter from '@/resources/icons/ic-ribbon-text-center.svg';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';
import { LabelStyle } from '../../../../models/labelStyle';
import { FormatPanelButtonsState } from '../../../../selectors/generalMenu.selectors';
import { MxConstants } from '../../../../mxgraph/mxgraph';
import messages from '../../FormatPanel.messages';
import { Alignment } from '../../../../models/alignment';
import { FormatPanelSelectors } from '../../../../selectors/formatPanel.selectors';

export const AlignmentCenter = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const onChangeAlign = (value: any, isActive: boolean) => {
        dispatch(generalMenuLabelStyleChangeAction(LabelStyle.alignmentStyle, value, isActive));
    };
    const disabled: boolean = useSelector(FormatPanelSelectors.getFormatButtonsDisabled);
    const tooltip: string = useSelector(
        FormatPanelSelectors.getFormatButtonsTooltip(intl.formatMessage(messages.textCenter)),
    );
    const alignment: Alignment = useSelector(FormatPanelButtonsState.getAlignment);

    return (
        <ToolbarButton
            isActive={alignment === Alignment.CenterVert}
            disabled={disabled}
            icon={icCenter}
            dataTest="object-toolbar_text-align_center"
            tooltip={tooltip}
            onClick={() => onChangeAlign(MxConstants.ALIGN_CENTER, alignment === Alignment.CenterVert)}
        />
    );
};

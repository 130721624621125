import React, { ChangeEvent, KeyboardEvent } from 'react';
import newTheme from './Select.scss';
import likeAnAntdTheme from './SelectLikeAnAntd.scss';
import cx from 'classnames';
import { DebounceInput } from 'react-debounce-input';

type TSearchInputAllowClearProps = {
    allowClear: true;
    onClear: () => void;
};

type TSearchInputNotAllowClearProps = {
    allowClear?: false;
};

type TSearchInputProps = (TSearchInputAllowClearProps | TSearchInputNotAllowClearProps) & {
    showSearch?: boolean;
    // todo: после перехода на новые стили этот пропс будет не нужен
    originalTheme?: boolean;
    onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    searchValue: string;
    dataTest?: string;
    autoFocus?: boolean;
    placeholder?: string;
    debounceTimeout?: number;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

export const SearchInput = (props: TSearchInputProps) => {
    const {
        showSearch,
        originalTheme,
        searchValue,
        dataTest,
        autoFocus,
        placeholder,
        debounceTimeout,
        onSearch,
        onFocus,
        onBlur,
    } = props;

    const theme = originalTheme ? newTheme : likeAnAntdTheme;

    const handleClearInput = () => {
        onSearch({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const input: HTMLInputElement = e.target as HTMLInputElement;
        // Убираем пробел в самом начале инпута
        if (e.key === ' ' && input.selectionStart === 0 && input.selectionEnd === searchValue.length) {
            e.preventDefault();
            handleClearInput();
        }
    };

    return (
        <>
            {showSearch && (
                <div className={theme.inputContainer}>
                    <span className={theme.inputIconContainer}>
                        <svg
                            className={cx(theme.inputIcon, {
                                [theme.inputSearchIcon]: !!searchValue.length && props.allowClear,
                            })}
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="Seacrh">
                                <path
                                    id="Union"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9.82264 10.8833C8.89957 11.5841 7.74835 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1C9.53757 1 12 3.46243 12 6.5C12 7.74835 11.5841 8.89957 10.8833 9.82264L14 12.9393C14.2929 13.2322 14.2929 13.7071 14 14C13.7071 14.2929 13.2322 14.2929 12.9393 14L9.82264 10.8833ZM10.5 6.5C10.5 8.70914 8.70914 10.5 6.5 10.5C4.29086 10.5 2.5 8.70914 2.5 6.5C2.5 4.29086 4.29086 2.5 6.5 2.5C8.70914 2.5 10.5 4.29086 10.5 6.5Z"
                                    fill="#AFAFAF"
                                />
                            </g>
                        </svg>

                        {searchValue.length && props.allowClear ? (
                            <svg
                                className={cx(theme.inputIcon, theme.inputClearIcon)}
                                onClick={props.onClear}
                                fillRule="evenodd"
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="close-circle"
                                width="14"
                                height="14"
                                fill="#bababa"
                            >
                                <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm127.98 274.82h-.04l-.08.06L512 466.75 384.14 338.88c-.04-.05-.06-.06-.08-.06a.12.12 0 00-.07 0c-.03 0-.05.01-.09.05l-45.02 45.02a.2.2 0 00-.05.09.12.12 0 000 .07v.02a.27.27 0 00.06.06L466.75 512 338.88 639.86c-.05.04-.06.06-.06.08a.12.12 0 000 .07c0 .03.01.05.05.09l45.02 45.02a.2.2 0 00.09.05.12.12 0 00.07 0c.02 0 .04-.01.08-.05L512 557.25l127.86 127.87c.04.04.06.05.08.05a.12.12 0 00.07 0c.03 0 .05-.01.09-.05l45.02-45.02a.2.2 0 00.05-.09.12.12 0 000-.07v-.02a.27.27 0 00-.05-.06L557.25 512l127.87-127.86c.04-.04.05-.06.05-.08a.12.12 0 000-.07c0-.03-.01-.05-.05-.09l-45.02-45.02a.2.2 0 00-.09-.05.12.12 0 00-.07 0z"></path>
                            </svg>
                        ) : null}
                    </span>

                    <DebounceInput
                        autoFocus={autoFocus}
                        className={theme.input}
                        onChange={onSearch}
                        value={searchValue}
                        type="text"
                        data-test={dataTest}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        onKeyDown={onKeyDown}
                        debounceTimeout={debounceTimeout || 0}
                    />
                </div>
            )}
        </>
    );
};

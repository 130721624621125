import React, { FC, useEffect, useRef } from 'react';
import theme from './CreateUITemplateTab.scss';
import messages from './CreateUITemplateTab.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetsListSidePanel } from './WidgetsListSidePanel/WidgetsListSidePanel.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { SettingsSidePanel } from './SettingsSidePanel/SettingsSidePanel.component';
import { TWorkspaceTab } from '@/models/tab.types';
import { UITemplateDTO, WidgetDTO } from '@/serverapi/api';
import { saveUITemplate } from '../../actions/UITemplates.actions';
import { Form } from 'antd';
import { useKeydownHandler } from '../../hooks/useKeydownHandler';
import { useResizeContainer } from '../../hooks/useResizeContainer';
import { TSettingsPanelFormValues } from './CreateUITemplateTab.types';
import { WidgetsContainer } from './WidgetsContainer/WidgetsContainer.component';
import { UITemplateEditorSelectors } from '../../selectors/UITemplateEditor.selectors';
import { UITemplateEditorCloseTab, UITemplateEditorSelectWidget } from '../../actions/UITemplateEditor.actions';
import { renderWidgetSidePanel } from '../Widgets/widgetsMap';
import { ServerSelectors } from '@/selectors/entities/server.selectors';

type TCreateUITemplateTabProps = {
    tab: TWorkspaceTab;
};

export const CreateUITemplateTab: FC<TCreateUITemplateTabProps> = ({
    tab: {
        nodeId,
        nodeId: { id: templateId },
    },
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const newTemplate: UITemplateDTO | undefined = useSelector(UITemplateEditorSelectors.UITemplateById(templateId));
    const selectedWidget: WidgetDTO | undefined = useSelector(UITemplateEditorSelectors.selectedWidget(templateId));
    const serverId = useSelector(ServerSelectors.serverId);

    const cellsContainer = useRef<HTMLDivElement>(null);

    const [generalForm] = Form.useForm<TSettingsPanelFormValues>();

    useKeydownHandler({ nodeId, selectedWidget });
    const cellSize: number = useResizeContainer({ cellsContainer });

    useEffect(() => {
        return () => cancelHandler();
    }, []);

    if (!newTemplate) return null;

    const saveTemplateHandler = () => {
        dispatch(UITemplateEditorSelectWidget(templateId, ''));

        generalForm
            .validateFields()
            .then(() => {
                if (newTemplate) {
                    dispatch(saveUITemplate(nodeId, newTemplate));
                }
            })
            .catch(() => undefined);
    };

    const cancelHandler = () => {
        if (newTemplate) {
            dispatch(UITemplateEditorCloseTab(nodeId, newTemplate.id));
        }
    };

    return (
        <div className={theme.container}>
            <div className={theme.header}>
                <Button visualStyle="primary" size="small" onClick={saveTemplateHandler}>
                    {intl.formatMessage(messages.save)}
                </Button>
                <Button onClick={cancelHandler} size="small">
                    {intl.formatMessage(messages.cancel)}
                </Button>
            </div>
            <div className={theme.bodyContainer}>
                <WidgetsListSidePanel templateId={templateId} cellSize={cellSize} />
                <WidgetsContainer templateId={templateId} cellSize={cellSize} cellsContainer={cellsContainer} />
                <SettingsSidePanel hide={!!selectedWidget} generalForm={generalForm} UITemplate={newTemplate} />
                {selectedWidget &&
                    renderWidgetSidePanel(selectedWidget.type, { serverId, templateId, widget: selectedWidget })}
            </div>
        </div>
    );
};

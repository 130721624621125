import {
    MODEL_CREATE,
    NOT_SAVE_UNSAVED_DECOMPOSITION_MODELS,
    SAVE_MODEL,
    SAVE_MODEL_COMMON,
    SAVE_MODEL_FAIL,
    CONTINUE_DECOMPOSITION_SAGA,
    SAVE_UNSAVED_DECOMPOSITION_MODELS,
} from '../actionsTypes/save.actionTypes';
import { ModelNode, NodeId } from '../serverapi/api';
import {
    TContinueDecompositionSagaAction,
    TSaveModelCommonAction,
    TSaveModelFailAction,
    TSaveModelAction,
    TSaveUnsavedDecompositionModelsAction,
    TNotSaveUnsavedDecompositionModelsAction,
    TSaveModelPayload,
    TModelDiagramCreateAction,
} from './save.actions.types';

export const continueDecompositionSaga = (model: ModelNode): TContinueDecompositionSagaAction => ({
    type: CONTINUE_DECOMPOSITION_SAGA,
    payload: { model },
});

export const saveModelFail = (): TSaveModelFailAction => ({
    type: SAVE_MODEL_FAIL,
    payload: {},
});

export const saveModel = (payload: TSaveModelPayload): TSaveModelAction => ({
    type: SAVE_MODEL,
    payload,
});

export const saveUnsavedDecompositionModel = (): TSaveUnsavedDecompositionModelsAction => ({
    type: SAVE_UNSAVED_DECOMPOSITION_MODELS,
    payload: {},
});

export const notSaveUnsavedDecompositionModel = (): TNotSaveUnsavedDecompositionModelsAction => ({
    type: NOT_SAVE_UNSAVED_DECOMPOSITION_MODELS,
    payload: {},
});

export const saveModelCommon = (graphId: string): TSaveModelCommonAction => ({
    type: SAVE_MODEL_COMMON,
    payload: {
        graphId,
    },
});

export const modelDiagramCreate = (modelTypeId: string, modelName: string,  parentNodeId: NodeId, presetId: string, templateId?: string): TModelDiagramCreateAction => ({
    type: MODEL_CREATE,
    payload: {
        modelTypeId, modelName, parentNodeId, presetId, templateId,
    }
});
import { TWorkspaceTab, TWorkspaceTabItemParams } from '../models/tab.types';
import { NodeId } from '../serverapi/api';
import { TreeNode } from '../models/tree.types';
import {
    OPEN_CONTENT_LOADING_TAB,
    WORKSPACE_REMOVE_MULTIPLE_TABS,
    WORKSPACE_TABS_ACTIVATE,
    WORKSPACE_TABS_ADD,
    WORKSPACE_TABS_CHANGE,
    WORKSPACE_TABS_CHANGE_TITLE,
    WORKSPACE_TABS_COPY_TO_CLIPBOARD,
    WORKSPACE_TABS_ORDER_CHANDGED,
    WORKSPACE_TABS_REMOVE,
    WORKSPACE_TABS_REMOVE_BY_NODE_ID,
    WORKSPACE_TABS_REMOVE_REQUEST,
    WORKSPACE_TABS_SET_ACTION,
    WORKSPACE_TABS_SET_PARAMS,
} from '../actionsTypes/tabs.actionTypes';
import {
    TCopyToClipboardAction,
    TCopyToClipboardPayload,
    TOpenContentLoadingTabAction,
    TWorkspaceTabsActivateAction,
    TWorkspaceTabsAddAction,
    TWorkspaceTabsChangeAction,
    TWorkspaceTabsChangeTitleAction,
    TWorkspaceTabsRemoveAction,
    TWorkspaceTabsRemoveByNodeIdAction,
    TWorkspaceTabsRemoveMultipleAction,
    TWorkspaceTabsRemoveRequestAction,
    TWorkspaceTabsSetAction,
    TWorkspaceTabsSetOrder,
    TWorkspaceTabsSetParams,
    TWorkspaceTabsSetPayload,
} from './tabs.actions.types';

export const workspaceTabsSetOrder = (workspaceTabs: TWorkspaceTab[]): TWorkspaceTabsSetOrder => ({
    type: WORKSPACE_TABS_ORDER_CHANDGED,
    payload: {
        workspaceTabs,
    },
});

export const workspaceAddTab = (workspaceTab: TWorkspaceTab): TWorkspaceTabsAddAction => ({
    type: WORKSPACE_TABS_ADD,
    payload: {
        workspaceTab,
    },
});

// отличается от workspaceRemoveTab тем что предварительно может быть выполнена сага, например для сохранения данных
export const workspaceRemoveTabRequest = (workspaceTab: TWorkspaceTab): TWorkspaceTabsRemoveRequestAction => ({
    type: WORKSPACE_TABS_REMOVE_REQUEST,
    payload: {
        workspaceTab,
    },
});

export const workspaceRemoveTab = (workspaceTab: TWorkspaceTab): TWorkspaceTabsRemoveAction => ({
    type: WORKSPACE_TABS_REMOVE,
    payload: {
        workspaceTab,
    },
});

export const workspaceRemoveMultipleTabs = (workspaceTabs: TWorkspaceTab[]): TWorkspaceTabsRemoveMultipleAction => ({
    type: WORKSPACE_REMOVE_MULTIPLE_TABS,
    payload: {
        workspaceTabs,
    },
});

export const workspaceRemoveTabByNodeId = (nodeId: NodeId): TWorkspaceTabsRemoveByNodeIdAction => ({
    type: WORKSPACE_TABS_REMOVE_BY_NODE_ID,
    payload: {
        nodeId,
    },
});

export const workspaceActivateTab = (workspaceTab: TWorkspaceTab): TWorkspaceTabsActivateAction => ({
    type: WORKSPACE_TABS_ACTIVATE,
    payload: {
        workspaceTab,
    },
});

export const workspaceChangeTabTitle = (
    workspaceTab: TWorkspaceTab,
    title: string,
): TWorkspaceTabsChangeTitleAction => ({
    type: WORKSPACE_TABS_CHANGE_TITLE,
    payload: {
        workspaceTab,
        title,
    },
});

export const workspaceChangeTab = (workspaceTab: TWorkspaceTab): TWorkspaceTabsChangeAction => ({
    type: WORKSPACE_TABS_CHANGE,
    payload: {
        workspaceTab,
    },
});

export const workspaceTabSetAction = (payload: TWorkspaceTabsSetPayload): TWorkspaceTabsSetAction => ({
    type: WORKSPACE_TABS_SET_ACTION,
    payload,
});

export const workspaceTabSetParams = (
    nodeId: NodeId,
    params: Partial<TWorkspaceTabItemParams>,
): TWorkspaceTabsSetParams => ({
    type: WORKSPACE_TABS_SET_PARAMS,
    payload: {
        nodeId,
        params,
    },
});

export const openContenLoadingTab = (node: TreeNode): TOpenContentLoadingTabAction => ({
    type: OPEN_CONTENT_LOADING_TAB,
    payload: { node },
});

export const copyToClipboard = (value: TCopyToClipboardPayload['value']): TCopyToClipboardAction => ({
    type: WORKSPACE_TABS_COPY_TO_CLIPBOARD,
    payload: { value },
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GetImage__container___EXWI{border-radius:4px}.GetImage__container___EXWI:hover{background-color:var(--ToolbarBtnBackgroundHover)}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/MatrixEditorToolbar/Buttons/GetImage/GetImage.scss"],"names":[],"mappings":"AAAA,4BACI,iBAAA,CACA,kCACI,iDAAA","sourcesContent":[".container {\r\n    border-radius: 4px;\r\n    &:hover {\r\n        background-color: var(--ToolbarBtnBackgroundHover);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GetImage__container___EXWI"
};
export default ___CSS_LOADER_EXPORT___;

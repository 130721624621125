import { InternationalString, WidgetGeometry } from '@/serverapi/api';

export enum WidgetTypes {
    SearchResult = 'SEARCH_RESULT',
}

export type TWidget = {
    id: string;
    name: InternationalString;
    type: WidgetTypes;
    geometry: WidgetGeometry;
};

export type TSidePanelWidget = {
    id: number;
    name: string;
    type: WidgetTypes;
    icon: {
        content: string;
        id: string;
        node: Node;
        viewBox: string;
    };
    widgetWidth?: number;
    widgetHeight?: number;
};

import { AipPreset } from './AipPreset';
import { TAipPresetGroup, TFoundInPreset } from "./types/AipPreset.types";
import {
    AipSearch,
    SearchRule,
    SearchRuleAttributeTypeEnum,
    SearchRuleQueryRuleEnum
} from "@/serverapi/api";
import { TProcessedConfigItem, TProcessedItemGroup, TProcessedNowhereItem, TProcessedPresetItem, TProcessedSourceQueryItem } from "./types/SourceQueryProcessor.types";
import { TBuildSearchRuleReturnValue, TStems } from "./types/SearchRuleBuilder.types";
import { TAipConfigTokenId, TFoundTokenGroup } from "./types/AipConfig.types";
import { AipToolbox } from './AipToolbox';


class SearchRuleBuilder {

    build(request: AipSearch, queryPart: TProcessedSourceQueryItem[], aipPreset: AipPreset, stems: TStems): TBuildSearchRuleReturnValue {
        const firstQueryItem: TProcessedSourceQueryItem = queryPart[0];

        // правила (SearchRule) могут быть только для групп элементов таких типов
        const aipPresetGroupsNames: TAipPresetGroup[] = this.getAvailableGroupsNames();
        const firstItemType: TProcessedItemGroup = firstQueryItem.type;

        switch(firstItemType) {
            case TProcessedItemGroup.CONFIG: {
                return this.handleConfigFirstQueryPart(request, queryPart, aipPreset, stems);
            }
            case TProcessedItemGroup.PRESET: {
                const value: TFoundInPreset = firstQueryItem.value as TFoundInPreset;
                let group: TAipPresetGroup = value.items[0].group;

                if (aipPresetGroupsNames.includes(group)) {
                    return {
                        rules: this.makeSearchRulesForSearchBySystemAttribute(group, value.items.map(val => val.item.id)),
                        wereUsed: 1
                    }
                }
                return {
                    rules: this.makeSearchRulesForSearchByName(firstQueryItem.value.source, stems),
                    wereUsed: 1
                };
            }
            case TProcessedItemGroup.NOWHERE: {
                return {
                    rules: this.makeSearchRulesForSearchByName(firstQueryItem.value.source, stems),
                    wereUsed: 1
                };
            }
        }
    }


    private handleConfigFirstQueryPart(request: AipSearch, queryPart: TProcessedSourceQueryItem[], aipPreset: AipPreset, stems: TStems): TBuildSearchRuleReturnValue {
        const firstQueryItem: TProcessedSourceQueryItem = queryPart[0];

        // такого не может быть, так как в эту функцию приходят именно такие запросы, но нужно чтобы TS не нервничал
        if (firstQueryItem.type !== TProcessedItemGroup.CONFIG) return { rules: null, wereUsed: 1 };

        const secondQueryItem: TProcessedSourceQueryItem | undefined = queryPart[1];
        const thirdQueryItem: TProcessedSourceQueryItem | undefined = queryPart[2];

        // правила (SearchRule) могут быть только для групп элементов таких типов
        const aipPresetGroupsNames: TAipPresetGroup[] = this.getAvailableGroupsNames();

        const firstItemAipConfigGroup: TFoundTokenGroup = firstQueryItem.value.aipConfigGroup;

        const firstItemTokens: string[] = firstQueryItem.value.tokens;
        if (firstItemAipConfigGroup == 'WITH_SOMETHING') {
            // если в запросе есть часть 'WITH_SOMETHING' то после неё обязано что-то быть, иначе запрос неверный
            // TODO тут надо будет как-то ошибку обработать
            if (!secondQueryItem) return { rules: null, wereUsed: 1 };
            let res: TBuildSearchRuleReturnValue | null;
            switch(secondQueryItem.type) {
                case TProcessedItemGroup.PRESET: {
                    res = this.handleConfigWithSomethingFirstAndPresetSecond(aipPresetGroupsNames, firstItemTokens, secondQueryItem, thirdQueryItem, stems);
                    break;
                }
                case TProcessedItemGroup.CONFIG: {
                    res = this.handleConfigWithSomethingFirstAndConfigSecond(aipPresetGroupsNames, firstItemTokens, secondQueryItem, thirdQueryItem, stems);
                    break;
                }
                case TProcessedItemGroup.NOWHERE: {
                    res = this.handleConfigWithSomethingFirstAndNowhereSecond(firstItemTokens, secondQueryItem, stems);
                    break;
                }
            }
            return res ? res : { rules: null, wereUsed: 1 };
        }
        if (firstItemAipConfigGroup === 'PREPOSITIONS') {
            const prepositionToken: TAipConfigTokenId = firstQueryItem.value.tokens[0];
            let res: TBuildSearchRuleReturnValue | null = null;
            switch (prepositionToken) {
                case 'WITH': {
                    res = this.handleConfigPrepositionFirstAndNowhereSecond(prepositionToken, secondQueryItem, thirdQueryItem);
                    break;
                }
            }
            return res ? res : {rules: null, wereUsed: 1};
        }
        return {rules: null, wereUsed: 1};
    }


    // обработка запросов вида НАЙДЕНО-В-КОНФИГЕ НАЙДЕНО-В-ПРЕСЕТЕ, а именно:
    // с именем ЗНАЧЕНИЕ_ИЗ_ПРЕСЕТА
    // с типом ЗНАЧЕНИЕ_ИЗ_ПРЕСЕТА
    // с атрибутом ЗНАЧЕНИЕ_ИЗ_ПРЕСЕТА
    // со значением ЗНАЧЕНИЕ_ИЗ_ПРЕСЕТА
    private handleConfigWithSomethingFirstAndPresetSecond( aipPresetGroupsNames: TAipPresetGroup[], firstItemTokens: string[],
            secondQueryItem: TProcessedPresetItem, thirdQueryItem: TProcessedSourceQueryItem, stems: TStems): TBuildSearchRuleReturnValue | null {

        const grp: TAipPresetGroup = secondQueryItem.value.items[0].group;

        if (this.areEqual(firstItemTokens, ['WITH', 'NAME'])) {
            return {
                rules: this.makeSearchRulesForSearchByName(secondQueryItem.value.source, stems),
                wereUsed: 2
            };
        }

        // следом за конструкцией "с типом" должен быть тип из пресета (то есть secondQueryItem.type === TProcessedItemGroup.PRESET), причём такой,
        // чтобы соответствовал одному из типов искомых элементов. Если это не так - это ошибка.
        // TODO тут надо будет как-то ошибку обработать
        if (this.areEqual(firstItemTokens, ['WITH', 'TYPE']) && !aipPresetGroupsNames.includes(grp)) return { rules: null, wereUsed: 1 };

        if (this.areEqual(firstItemTokens, ['WITH', 'ATTRIBUTE'])) {
            // следом за конструкцией "с атрибутом" идёт тип атрибута из пресета
            if (secondQueryItem.value.items[0].group === TAipPresetGroup.attributeTypes) {
                const attributeTypeId: string = secondQueryItem.value.items[0].item.id;
                if (!thirdQueryItem || thirdQueryItem.type !== TProcessedItemGroup.NOWHERE) {
                    return { rules: this.makeSearchRulesForSearchByAttribute(attributeTypeId, []), wereUsed: 2};
                }
                // это часть конструкции 'с атрибутом АТРИБУТ_ИЗ_ПРЕСЕТА ЗНАЧЕНИЕ'
                return { rules: this.makeSearchRulesForSearchByAttribute(attributeTypeId, [thirdQueryItem.value.source ]), wereUsed: 3};
            }
        }
        if (this.areEqual(firstItemTokens, ['WITH', 'ATTRIBUTE'])) {
            return {
                rules: this.makeSearchRulesForSearchBySystemAttribute(grp, secondQueryItem.value.items.map(val => val.item.id)),
                wereUsed: 2
            }
        }
        return null;
    }

    // обработка запросов вида НАЙДЕНО-В-КОНФИГЕ НАЙДЕНО-В-КОНФИГЕ, а именно:
    // с атрибутом ИМЯ
    // с атрибутом ТИП
    // другие варианты возможны, но они бессмысленные, поэтому в их случае будем возвращать null
    private handleConfigWithSomethingFirstAndConfigSecond(aipPresetGroupsNames: TAipPresetGroup[], firstItemTokens: string[],
            secondQueryItem: TProcessedConfigItem, thirdQueryItem: TProcessedSourceQueryItem, stems: TStems): TBuildSearchRuleReturnValue | null {
        // при втором элементе подзапроса из конфига первым может быть только "с атрибутом"
        if (!this.areEqual(firstItemTokens, ['WITH', 'ATTRIBUTE'])) return { rules: null, wereUsed: 1 };

        const configGroup: TFoundTokenGroup = secondQueryItem.value.aipConfigGroup;
        const configToken: string = secondQueryItem.value.tokens[0];
        // проверяем что это конструкция 'с атрибутом ТИП ЗНАЧЕНИЕ'
        if (configGroup === 'ATTRIBUTES' && configToken === 'TYPE') {
            // после конструкции "c атрибутом ТИП" не указан тип из пресета
            if (thirdQueryItem.type !== TProcessedItemGroup.PRESET) return { rules: null, wereUsed: 1 };
            const aipPresetGroup: TAipPresetGroup = thirdQueryItem.value.items[0].group;

            // после конструкции 'с атрибутом тип' должен идти только тип того, что может быть целевым объектом поиска.
            if (!aipPresetGroupsNames.includes(aipPresetGroup)) return { rules: null, wereUsed: 1 };
            // это конструкция 'с атрибутом тип ТИП_ИЗ_ПРЕСЕТА'
            return { rules: this.makeSearchRulesForSearchBySystemAttribute(aipPresetGroup, thirdQueryItem.value.items.map(val => val.item.id)), wereUsed: 3 };
        }

        // проверяем что это конктрукция 'с атрибутом ИМЯ ЗНАЧЕНИЕ'
        if (configGroup === 'ATTRIBUTES' && configToken === 'NAME') {
            // пока значение может быть только в блоке с type === TProcessedItemGroup.NOWHERE
            if (!(thirdQueryItem.type === TProcessedItemGroup.NOWHERE)) return { rules: null, wereUsed: 1 };

            return { rules: this.makeSearchRulesForSearchByName(thirdQueryItem.value.source, stems), wereUsed: 2 };
        }

        // TODO тут надо будет как-то ошибку обработать
        return null;
    }

    // обработка запросов вида НАЙДЕНО-В-КОНФИГЕ НЕ-НАЙДЕНО-НИГДЕ, а именно:
    // с именем ИМЯ
    // с атрибутом АТРИБУТ
    // ничего другого не может быть тем что не найдено нигде, поэтому в таких случаях будем возвращать null
    private handleConfigWithSomethingFirstAndNowhereSecond( firstItemTokens: string[], secondQueryItem: TProcessedNowhereItem, stems: TStems): TBuildSearchRuleReturnValue | null {
        if (this.areEqual(firstItemTokens, ['WITH', 'NAME'])) {
            return {
                rules: this.makeSearchRulesForSearchByName(secondQueryItem.value.source, stems),
                wereUsed: 2
            };
        }

        if (this.areEqual(firstItemTokens, ['WITH', 'ATTRIBUTE'])) {
            return {
                rules: this.makeSearchRulesForSearchByAttribute(secondQueryItem.value.source,  []),
                wereUsed: 2
            };
        }

        return null;
    }


    // обработка запросов вида НАЙДЕНО-В-КОНФИГЕ ЧТО-ТО-ДРУГОЕ. НАЙДЕНО-В-КОНФИГЕ содержит какой-то из допустимых предлогов:
    // с именем ИМЯ
    // с атрибутом АТРИБУТ
    // ничего другого не может быть тем что не найдено нигде, поэтому в таких случаях будем возвращать null
    private handleConfigPrepositionFirstAndNowhereSecond(prepositionToken: TAipConfigTokenId, secondQueryItem: TProcessedSourceQueryItem, thirdQueryItem: TProcessedSourceQueryItem): TBuildSearchRuleReturnValue | null {
        // пока рассматриваем только предлог "С". Если найден не он - это что-то не то
        if (prepositionToken !== 'WITH') return null;

        // после предлога "С" не может быть типа из конфига
        if (secondQueryItem.type !== TProcessedItemGroup.PRESET) return { rules: null, wereUsed: 1 };

        // после предлога "С" может быть только тип атрибута из пресета.
        if (secondQueryItem.value.items[0].group !== TAipPresetGroup.attributeTypes) return { rules: null, wereUsed: 1 };

        const attributeTypeId: string = secondQueryItem.value.items[0].item.id;
        if (!thirdQueryItem) {
            // это конструкции 'с АТРИБУТ_ИЗ_ПРЕСЕТА', значения атрибута не указаны
            return { rules: this.makeSearchRulesForSearchByAttribute(attributeTypeId, []), wereUsed: 3};
        }

        if (thirdQueryItem.type === TProcessedItemGroup.NOWHERE) {
            // это конструкции 'с АТРИБУТ_ИЗ_ПРЕСЕТА ЗНАЧЕНИЕ'
            return { rules: this.makeSearchRulesForSearchByAttribute(attributeTypeId, [ thirdQueryItem.value.source ]), wereUsed: 3};
        }

        // Если мы дошли до этого места значит третий элемент надо воспринимать, как значение атрибута.
        const values: string[] | null = [ thirdQueryItem.value.source];
        return { rules: this.makeSearchRulesForSearchByAttribute(attributeTypeId, values), wereUsed: 3};
    }


    private makeSearchRulesForSearchByName(name: string, stems: TStems): SearchRule[] {
        const words: string[] = AipToolbox.getWordsFromString(name); //.split(' ').map((val: string) => val.trim().toLowerCase());

        return words
            .map((word: string) => word in stems ? stems[word] : word)
            .map((stem: string) => this.makeSearchRule("SYSTEM", "name", "CONTAINS", [ stem ]));
    }


    private makeSearchRulesForSearchBySystemAttribute(group: TAipPresetGroup,  values: string[]): SearchRule[] {
        const mapping: Partial<Record<TAipPresetGroup, string>> = {
            [ TAipPresetGroup.aliasTypes ] : 'aliasTypeId',
            [ TAipPresetGroup.folderTypes ] : 'folderTypeId',
            [ TAipPresetGroup.modelTypes ] : 'modelTypeId',
            [ TAipPresetGroup.objectTypes ] : 'objectTypeId'
        }
        const attributeTypeId: string | undefined = mapping[group];
        // попытка найти то что мы не ищем в принципе (ребро или атрибут).
        if (!attributeTypeId) return [];

        return [ this.makeSearchRule('SYSTEM', attributeTypeId, 'EQUALS', values) ];
    }


    private makeSearchRulesForSearchByAttribute(attributeTypeId: string,  values: string[]): SearchRule[] {
        return [ this.makeSearchRule('USER', attributeTypeId, values.length ? 'CONTAINS' : 'HAS_VALUE', values) ];
    }


    private makeSearchRule(attributeType: SearchRuleAttributeTypeEnum, attributeTypeId: string, queryRule: SearchRuleQueryRuleEnum, values: string[]): SearchRule {
        return  {
            attributeType: attributeType,
            attributeTypeId: attributeTypeId,
            queryRule: queryRule,
            values: values
        }
    }


    // смотрит типы узлов которые планируется найти и находит соответствующие им значения TAipPresetGroup
    private getAvailableGroupsNames(): TAipPresetGroup[] {
        return [TAipPresetGroup.aliasTypes, TAipPresetGroup.folderTypes, TAipPresetGroup.modelTypes, TAipPresetGroup.objectTypes ];
    }


    private areEqual(tokens1: string[], tokens2: string[]): boolean {
        return tokens1.join('') === tokens2.join('');
    }
};

export const searchRuleBuilder = new SearchRuleBuilder();

import { FORMAT_PANEL_CLOSE, FORMAT_PANEL_OPEN } from '../actionsTypes/formatPanel.actionsTypes';
import { NodeId } from '../serverapi/api';
import { TFormatPanelCloseAction, TFormatPanelOpenAction } from './formatPanel.actions.types';

export const formatPanelOpen = (modelId: NodeId): TFormatPanelOpenAction => ({
    type: FORMAT_PANEL_OPEN,
    payload: {
        modelId,
    },
});

export const formatPanelClose = (modelId: NodeId): TFormatPanelCloseAction => ({
    type: FORMAT_PANEL_CLOSE,
    payload: {
        modelId,
    },
});

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dashboard__dashboardEditorContainer__J__81{width:100%;height:100%;overflow-y:scroll;overflow-x:hidden}.Dashboard__dashboardEditor__ELGj7{width:100%;position:relative;overflow:hidden}.Dashboard__dashboardBackground__DbAgN{background:linear-gradient(var(--Neutral500), transparent 1px),linear-gradient(90deg, var(--Neutral500), transparent 1px);border-bottom:1px solid var(--Neutral300);border-radius:0}", "",{"version":3,"sources":["webpack://./src/modules/Dashboard/Dashboard.scss"],"names":[],"mappings":"AAAA,4CACI,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CAGJ,mCACI,UAAA,CACA,iBAAA,CACA,eAAA,CAGJ,uCACI,yHAAA,CAEA,yCAAA,CACA,eAAA","sourcesContent":[".dashboardEditorContainer {\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow-y: scroll;\r\n    overflow-x: hidden;\r\n}\r\n\r\n.dashboardEditor {\r\n    width: 100%;\r\n    position: relative;\r\n    overflow: hidden;\r\n}\r\n\r\n.dashboardBackground {\r\n    background: linear-gradient(var(--Neutral500), transparent 1px),\r\n        linear-gradient(90deg, var(--Neutral500), transparent 1px);\r\n    border-bottom: 1px solid var(--Neutral300);\r\n    border-radius: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardEditorContainer": "Dashboard__dashboardEditorContainer__J__81",
	"dashboardEditor": "Dashboard__dashboardEditor__ELGj7",
	"dashboardBackground": "Dashboard__dashboardBackground__DbAgN"
};
export default ___CSS_LOADER_EXPORT___;

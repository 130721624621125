// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateUITemplateTab__container__BDfjw{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;height:100%}.CreateUITemplateTab__header__h2yq6{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;padding:8px;grid-gap:8px;gap:8px;border-bottom:1px solid var(--BorderSecondaryNeutral200);height:42px}.CreateUITemplateTab__bodyContainer__Qqzqg{display:-webkit-box;display:-ms-flexbox;display:flex;height:calc(100% - 42px);width:100%}", "",{"version":3,"sources":["webpack://./src/modules/UITemplates/components/CreateUITemplateTab/CreateUITemplateTab.scss"],"names":[],"mappings":"AAAA,uCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,UAAA,CACA,WAAA,CAGJ,oCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CAAA,OAAA,CACA,wDAAA,CACA,WAAA,CAGJ,2CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CACA,UAAA","sourcesContent":[".container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.header {\r\n    display: flex;\r\n    width: 100%;\r\n    padding: 8px;\r\n    gap: 8px;\r\n    border-bottom: 1px solid var(--BorderSecondaryNeutral200);\r\n    height: 42px;\r\n}\r\n\r\n.bodyContainer {\r\n    display: flex;\r\n    height: calc(100% - 42px);\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateUITemplateTab__container__BDfjw",
	"header": "CreateUITemplateTab__header__h2yq6",
	"bodyContainer": "CreateUITemplateTab__bodyContainer__Qqzqg"
};
export default ___CSS_LOADER_EXPORT___;

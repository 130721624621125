import type {
    DiagramElement,
    InternationalString,
    MatrixCell,
    MatrixLane,
    NodeId,
    NodeTypeEnum,
    Symbol,
} from '../serverapi/api';
import type { WhiteboardGraph } from '@/mxgraph/WhiteboardGraph';
import type { MxCell, MxPoint } from '../mxgraph/mxgraph';
import { Alignment } from '../models/alignment';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { EditorMode } from '../models/editorMode';
import { Grouping } from '../models/grouping';
import { SpaceAction } from '../models/insertionSpace';
import { MoveTo } from '../models/move-to';
import { MoveLayer } from '../models/movelayer';
import { PickOut } from '../models/pick-out';
import { KeyCodes } from '../utils/keys';
import { TreeNode } from '../models/tree.types';
import {
    CHANGE_SYMBOL_FOR_CELL,
    EDITOR_ADD_BPMN_TABLE_ROW,
    EDITOR_ADD_TABLE_COLUMN,
    EDITOR_ADD_TABLE_ROW,
    EDITOR_ALIGN,
    EDITOR_DISTRIBUTE,
    EDITOR_CELL_ADDED,
    EDITOR_CELL_COLOR_PICKED,
    EDITOR_CHANGE_EDGE_COLOR,
    EDITOR_CHANGE_EDGE_STYLE,
    EDITOR_CHANGE_EDGE_TYPE,
    EDITOR_CHANGE_FONT_COLOR,
    EDITOR_CHANGE_MODEL,
    EDITOR_SET_CELLS_DEFAULT_STYLE,
    EDITOR_COPY,
    EDITOR_CREATED,
    EDITOR_DELETE,
    EDITOR_DESTROY,
    EDITOR_DRAG_SHAPE,
    EDITOR_DROP_PASTE_PREVIEW,
    EDITOR_ESCAPE,
    EDITOR_FORMAT_BY_EXAMPLE,
    EDITOR_GROUPING,
    EDITOR_INIT,
    EDITOR_LABEL_CHANGED,
    EDITOR_MODE_CHANGED,
    EDITOR_MODE_CHANGED_PREPARE,
    EDITOR_MOVE_LAYER,
    EDITOR_MOVE_OBJECT,
    EDITOR_MOVE_TO,
    EDITOR_MOVE_TO_DIRECT,
    EDITOR_SINGLE_CELL_SELECTED,
    EDITOR_PASTE,
    EDITOR_PICK_OUT,
    EDITOR_REMOVE_BPMN_TABLE_ROW,
    EDITOR_REMOVE_TABLE_COLUMN,
    EDITOR_REMOVE_TABLE_ROW,
    EDITOR_SELECT_ALL,
    EDITOR_SET_FOCUS_AND_START_EDIT_LABEL,
    EDITOR_SET_OBJECT_TO_CELL,
    EDITOR_SET_REPOSITORY_ID_WHERE_COPIED_FROM,
    EDITOR_SET_COPIED_ELEMENTS,
    EDITOR_UPDATE,
    EDITOR_ZOOM_IN,
    EDITOR_ZOOM_OUT,
    EDITOR_ZOOM_TO,
    EDITOR_DROP_FILE_NODE,
    DELETE_SELECTED_CELLS_FROM_ACTIVE_GRAPH,
    EDITOR_CHANGE_EDGE_MULTILINGUAL_NAME,
    EDITOR_DRAG_NODE,
    EDITOR_SELECTION_MODEL_CHANGE,
    EDITOR_PROCESS_SPACE_ACTION,
    EDITOR_CUT,
    EDITOR_COPY_WHITEBOARD,
    EDITOR_PASTE_WHITEBOARD,
    CREATE_NEW__OBJECT_NAME,
    OPEN_SEARCH_ACTION,
    OPEN_ELEMENT_PROPERTIES,
    OPEN_BD_SEARCH_ACTION,
    EDITOR_SELECT_SYMBOL_TO_REPLACE_ON_PASTE_SUBMIT,
    COPY_MODEL_LINK,
    MODEL_UNPUBLISH,
    MODEL_PUBLISH,
    OPEN_EDGE_TYPES_MENU,
} from '../actionsTypes/editor.actionTypes';

export type TEditorDestroyAction = {
    type: typeof EDITOR_DESTROY;
    payload: TEditorDestroyPayload;
};

export type TEditorAlignAction = {
    type: typeof EDITOR_ALIGN;
    payload: {
        alignment: Alignment;
    };
};

export type TEditorDistributeAction = {
    type: typeof EDITOR_DISTRIBUTE;
    payload: {
        isHorizontal: boolean;
    };
};

export type TEditorPickOutAction = {
    type: typeof EDITOR_PICK_OUT;
    payload: {
        pickOut: PickOut;
    };
};

export type TEditorMoveToDirectAction = {
    type: typeof EDITOR_MOVE_TO_DIRECT;
    payload: {
        treeNode: TreeNode;
        treeName?: string;
    };
};

export type TEditorMoveToAction = {
    type: typeof EDITOR_MOVE_TO;
    payload: {
        moveTo: MoveTo;
    };
};

export type TEditorMoveAction = {
    type: typeof EDITOR_MOVE_LAYER;
    payload: {
        movelayer: MoveLayer;
    };
};
export type TCopyModelLinkAction = {
    type: typeof COPY_MODEL_LINK;
    payload: {
        nodeId: NodeId;
    };
};

export type TModelUnpublishAction = {
    type: typeof MODEL_UNPUBLISH;
    payload: {
        nodeId: NodeId;
    };
};

export type TModelPublishAction = {
    type: typeof MODEL_PUBLISH;
    payload: {
        nodeId: NodeId;
    };
};

export type TEditorCopyAction = {
    type: typeof EDITOR_COPY;
};

export type TEditorCopyWhiteboardAction = {
    type: typeof EDITOR_COPY_WHITEBOARD;
    payload: {
        graph: WhiteboardGraph;
    };
};

export type TEditorCutAction = {
    type: typeof EDITOR_CUT;
};

export enum PasteMode {
    INSTANCE,
    DEFINITION,
    VARIANT,
}

export type TEditorPasteAction = {
    type: typeof EDITOR_PASTE;
    payload: {
        pasteMode: PasteMode;
    };
};

export type TEditorPasteWhiteboardAction = {
    type: typeof EDITOR_PASTE_WHITEBOARD;
    payload: {
        graph: WhiteboardGraph;
    };
};

export type TEditorCreatedPayload = {
    nodeId: NodeId;
    name: string;
    modelTypeId?: string;
};

export type TEditorCreatedAction = {
    type: typeof EDITOR_CREATED;
    payload: TEditorCreatedPayload;
};

export type TEditorUpdateAction = {
    type: typeof EDITOR_UPDATE;
};

export type TEditorChangeModelAction = {
    type: typeof EDITOR_CHANGE_MODEL;
    payload: {
        graphId: string;
    };
};

export type TEditorDragShapeAction = {
    type: typeof EDITOR_DRAG_SHAPE;
    payload: {
        symbol: Symbol;
    };
};

export type TEditorInitPayload = {
    nodeId: NodeId;
};

export type TEditorDestroyPayload = {
    nodeId: NodeId;
};

export type TEditorInitAction = {
    type: typeof EDITOR_INIT;
    payload: TEditorInitPayload;
};

export type TEditorFormatByExample = {
    type: typeof EDITOR_FORMAT_BY_EXAMPLE;
};

export type TEditorSetCellDefaultStyle = {
    type: typeof EDITOR_SET_CELLS_DEFAULT_STYLE;
};

export type TEditorSelectAllAction = {
    type: typeof EDITOR_SELECT_ALL;
};

export type TEditorDeletePayload = {
    cellsForDelete: MxCell[];
    graphId: NodeId;
    checkProfile: boolean;
    checkCellIsEdited: boolean;
};

export type TEditorDeleteAction = {
    type: typeof EDITOR_DELETE;
    payload: TEditorDeletePayload;
};

export type TDeleteSelectedCellsFromActiveGraphPayload = {
    checkProfile: boolean;
};

export type TDeleteSelectedCellsFromActiveGraphAction = {
    type: typeof DELETE_SELECTED_CELLS_FROM_ACTIVE_GRAPH;
};

export type TEditorMoveObjectAction = {
    type: typeof EDITOR_MOVE_OBJECT;
    payload: {
        key: KeyCodes;
    };
};

export type TOpenSearchAction = {
    type: typeof OPEN_SEARCH_ACTION;
};

export type TEditorEscapeAction = {
    type: typeof EDITOR_ESCAPE;
};

export type TEditorProcessSpaceAction = {
    type: typeof EDITOR_PROCESS_SPACE_ACTION;
    payload: {
        action: SpaceAction;
    };
};

export type TEditorChangeFontColorAction = {
    type: typeof EDITOR_CHANGE_FONT_COLOR;
    payload: {
        color: string;
    };
};

export type TEditorChangeEdgeMultilingualName = {
    type: typeof EDITOR_CHANGE_EDGE_MULTILINGUAL_NAME;
    payload: {
        graphId: NodeId;
        edgeId: string;
        name: InternationalString | undefined;
    };
};

export type TEditorChangeEdgeType = {
    type: typeof EDITOR_CHANGE_EDGE_TYPE;
    payload: {
        type: string;
    };
};

export type TEditorChangeEdgeColorAction = {
    type: typeof EDITOR_CHANGE_EDGE_COLOR;
    payload: {
        color: string;
    };
};

export type TEditorChangeEdgeStyleAction = {
    type: typeof EDITOR_CHANGE_EDGE_STYLE;
    payload: {
        keys: Array<string>;
        values: Array<string | null>;
        cellIds: string[];
    };
};

export type TSetRepositoryIdWhereCopiedFromAction = {
    type: typeof EDITOR_SET_REPOSITORY_ID_WHERE_COPIED_FROM;
    payload: {
        repositoryId: string;
        serverId: string;
        nodeType: NodeTypeEnum;
    };
};

export type TEditorSetCopiedElementsAction = {
    type: typeof EDITOR_SET_COPIED_ELEMENTS;
    payload: {
        elements: DiagramElement[] | MatrixLane[];
        matrixCells?: MatrixCell[];
    };
};

export type TEditorGropingAction = {
    type: typeof EDITOR_GROUPING;
    payload: {
        grouping: Grouping;
    };
};

export type TEditorCellAdded = {
    type: typeof EDITOR_CELL_ADDED;
    payload: {
        cellId: string;
        graphId: NodeId;
    };
};

export type TEditorCellColorPicked = {
    type: typeof EDITOR_CELL_COLOR_PICKED;
    payload: {
        color: string;
    };
};

export type TEditorModeChangedPrepareAction = {
    type: typeof EDITOR_MODE_CHANGED_PREPARE;
    payload: {
        mode: EditorMode;
    };
};

export type TEditorSetObjectToCell = {
    type: typeof EDITOR_SET_OBJECT_TO_CELL;
    payload: {
        cellId: string;
        object: ObjectDefinitionImpl;
        isDeletable: boolean;
    };
};

export type TEditorModeChangedAction = {
    type: typeof EDITOR_MODE_CHANGED;
    payload: {
        mode: EditorMode;
    };
};

export type TEditorOpenBdSearchAction = {
    type: typeof OPEN_BD_SEARCH_ACTION;
};

export type TEditorLabelChanged = {
    type: typeof EDITOR_LABEL_CHANGED;
    payload: {
        graphId: NodeId;
        cellId: string;
        newLabel?: string;
    };
};

export type TEditorSetFocusAndStartEditLabel = {
    type: typeof EDITOR_SET_FOCUS_AND_START_EDIT_LABEL;
    payload: {
        cellId: string;
    };
};

export type TEditorSingleCellSelected = {
    type: typeof EDITOR_SINGLE_CELL_SELECTED;
};

export type TEditorRemoveTableRowAction = {
    type: typeof EDITOR_REMOVE_TABLE_ROW;
    payload: {
        cellId: string;
    };
};

export type TEditorRemoveTableColumnAction = {
    type: typeof EDITOR_REMOVE_TABLE_COLUMN;
    payload: {
        cellId: string;
    };
};

export type TEditorAddTableColumnAction = {
    type: typeof EDITOR_ADD_TABLE_COLUMN;
    payload: {
        cellId: string;
    };
};

export type TEditorAddBpmnTableRowAction = {
    type: typeof EDITOR_ADD_BPMN_TABLE_ROW;
    payload: {
        cellId: string;
    };
};

export type TEditorRemoveBpmnTableRowAction = {
    type: typeof EDITOR_REMOVE_BPMN_TABLE_ROW;
    payload: {
        cellId: string;
    };
};

export type TChangeSymbolForCellAction = {
    type: typeof CHANGE_SYMBOL_FOR_CELL;
    payload: {
        cellId: string;
        graphId: NodeId;
        symbol: Symbol;
    };
};

export type TZoomToAction = {
    type: typeof EDITOR_ZOOM_TO;
    payload: number;
};

export type TEditorAddTableRowAction = {
    type: typeof EDITOR_ADD_TABLE_ROW;
    payload: {
        cellId: string;
    };
};

export type TZoomAction = {
    type: typeof EDITOR_ZOOM_IN | typeof EDITOR_ZOOM_OUT;
    payload: null;
};

export type TEditorDropPastePreviewAction = {
    type: typeof EDITOR_DROP_PASTE_PREVIEW;
    payload: {
        elements: DiagramElement[];
        target: string;
        dropPoint: MxPoint;
        pasteMode: PasteMode;
    };
};

export type TCreateNewObjectName = {
    type: typeof CREATE_NEW__OBJECT_NAME;
    payload: {
        cellId: string;
        object: ObjectDefinitionImpl;
        newObjectName: string;
    };
};

export type TDropFileNodeAction = {
    type: typeof EDITOR_DROP_FILE_NODE;
    payload: {
        nodeId: NodeId;
    };
};

export type TEditorDragNodePayload = {
    draggedNode: TreeNode | undefined;
    creatableSymbols: Symbol[];
};

export type TEditorDragNodeAction = {
    type: typeof EDITOR_DRAG_NODE;
    payload: TEditorDragNodePayload;
};

export type TEditorSelectionModelChangePayload = {
    nodeId: NodeId;
    cellsIds: string[];
};

export type TEditorSelectionModelChangeAction = {
    type: typeof EDITOR_SELECTION_MODEL_CHANGE;
    payload: TEditorSelectionModelChangePayload;
};

export type TOpenElementPropertiesAction = {
    type: typeof OPEN_ELEMENT_PROPERTIES;
};

export type TEditorSelectSymbolToReplaceOnPasteSubmitPayload = {
    pasteMode: PasteMode;
    selectedSymbol: Symbol;
    copiedElements: DiagramElement[];
    copiedElementsIdsToReplace: string[];
};

export type TEditorSelectSymbolToReplaceOnPasteSubmitAction = {
    type: typeof EDITOR_SELECT_SYMBOL_TO_REPLACE_ON_PASTE_SUBMIT;
    payload: TEditorSelectSymbolToReplaceOnPasteSubmitPayload;
};

export type TOpenEdgeTypesMenuPayload = {
    event: KeyboardEvent;
};

export type TOpenEdgeTypesMenuAction = {
    type: typeof OPEN_EDGE_TYPES_MENU;
    payload: TOpenEdgeTypesMenuPayload;
};

export type TEditorAction =
    | TEditorCreatedAction
    | TEditorInitAction
    | TEditorCellAdded
    | TEditorDestroyAction
    | TEditorCopyAction
    | TEditorPasteAction
    | TEditorAlignAction
    | TEditorDistributeAction
    | TEditorMoveAction
    | TEditorSelectAllAction
    | TEditorDeleteAction
    | TEditorGropingAction
    | TEditorProcessSpaceAction
    | TEditorEscapeAction
    | TEditorLabelChanged
    | TEditorSetFocusAndStartEditLabel
    | TEditorChangeEdgeMultilingualName
    | TEditorChangeEdgeType
    | TEditorChangeEdgeColorAction
    | TEditorSetObjectToCell
    | TCreateNewObjectName
    | TSetRepositoryIdWhereCopiedFromAction
    | TEditorSetCopiedElementsAction
    | TEditorSingleCellSelected
    | TEditorAddTableRowAction
    | TEditorRemoveTableRowAction
    | TEditorAddTableColumnAction
    | TEditorRemoveTableColumnAction
    | TEditorCellColorPicked
    | TEditorChangeModelAction
    | TEditorModeChangedAction
    | TEditorPickOutAction
    | TEditorMoveToAction
    | TEditorUpdateAction
    | TChangeSymbolForCellAction
    | TZoomAction
    | TZoomToAction
    | TDropFileNodeAction
    | TEditorMoveToDirectAction
    | TEditorDragNodeAction
    | TEditorSelectionModelChangeAction
    | TOpenElementPropertiesAction
    | TEditorOpenBdSearchAction
    | TEditorSelectSymbolToReplaceOnPasteSubmitAction
    | TOpenEdgeTypesMenuAction;

import type { TCommentAttributes } from '../../extensions/comment.types';
import React, { createRef } from 'react';
import ReactDOM from 'react-dom/client';

type TTooltipRef = {
    hideTooltip: () => void;
};

export class CommentTooltipClass {
    delay: number;
    containerName: string;
    tooltipComponent: React.ElementType;
    commentData: TCommentAttributes | null;
    root: ReactDOM.Root;
    div: HTMLDivElement | null;
    destroyed: boolean;
    isFullTooltipMode: boolean;
    lastX: number;
    lastY: number;
    thread: number | null;

    constructor(delay: number, containerName: string, tooltipComponent: React.ElementType) {
        this.delay = delay;
        this.containerName = containerName;
        this.tooltipComponent = tooltipComponent;
        this.close = this.close.bind(this);
        this.setFullTooltipMode = this.setFullTooltipMode.bind(this);
        this.resize = this.resize.bind(this);
    }

    commentTooltipRef = createRef<TTooltipRef>();

    init() {
        this.destroyed = false;
        this.thread = null;
        this.commentData = null;

        if (document.body != null) {
            this.div = document.createElement('div');
            this.div.className = 'mxTooltip';
            this.div.style.visibility = 'hidden';

            document.body.appendChild(this.div);
        }
    }

    setFullTooltipMode(isFullTooltipMode: boolean) {
        this.isFullTooltipMode = isFullTooltipMode;
    }

    // TODO реализовать
    resize() {
        if (!this.div) {
            return;
        }

        // const [newTop, newLeft] = getTooltipContainerCoordinates(this.graph.container, this.div);

        // this.div.style.top = `${newTop}px`;
        // this.div.style.left = `${newLeft}px`;
    }

    close() {
        this.commentData = null;

        if (this.div != null) {
            this.div.style.visibility = 'hidden';
            this.div.innerHTML = '';
        }
    }

    hideTooltip() {
        const node = this.div?.querySelector?.(`#${this.containerName}`);

        if (!node) {
            this.close();
        }
    }

    mouseUp(x: number, y: number) {
        this.commentTooltipRef.current?.hideTooltip();
        this.setFullTooltipMode(false);
        this.reset(null, x, y);
        this.hideTooltip();
    }

    mouseMove(commentData: TCommentAttributes | null, x: number, y: number, target: HTMLElement) {
        if (x !== this.lastX || y !== this.lastY) {
            this.reset(commentData || null, x, y, target);

            if (!this.isFullTooltipMode) {
                this.hideTooltip();
            }
        }

        this.lastX = x;
        this.lastY = y;
    }

    resetTimer() {
        if (this.thread !== null) {
            window.clearTimeout(this.thread);
            this.thread = null;
        }
    }

    reset(commentData: TCommentAttributes | null, x: number, y: number, target?: HTMLElement) {
        this.resetTimer();

        const isShown = !!this.div && this.div.style.visibility === 'visible';

        if (
            target &&
            commentData !== null &&
            (!isShown || (isShown && this.commentData?.commentId !== commentData.commentId))
        ) {
            this.thread = window.setTimeout(() => {
                this.show(commentData, x, y, target);
                this.commentData = commentData;
            }, this.delay);
        }
    }

    show(commentData: TCommentAttributes, x: number, y: number, target: HTMLElement) {
        if (this.div == null) {
            this.init();
        }

        if (!this.div) {
            return;
        }

        const Comment = this.tooltipComponent;
        const { left, top } = target.getBoundingClientRect();

        this.div.style.position = 'absolute';
        this.div.style.left = `${left - 14}px`;
        this.div.style.top = `${top - 14}px`;
        this.div.style.background = 'white';
        this.div.style.zIndex = '0';
        this.div.style.visibility = 'visible';
        this.div.style.cursor = 'pointer';
        this.div.style.width = '344px';
        this.div.style.borderRadius = '8px';
        this.div.style.borderWidth = '0px';
        this.div.style.padding = '12px';
        this.div.style.fontFamily = 'Segoe UI';
        this.div.style.display = 'block';

        this.root = ReactDOM.createRoot(this.div);

        const maxTooltipHeight = `${window.innerHeight * 0.8}` || 'auto';

        return this.root.render(
            <Comment
                ref={this.commentTooltipRef}
                container={this.div}
                commentId={commentData.commentId}
                maxTooltipHeight={maxTooltipHeight}
                onClose={this.close}
                onResize={this.resize}
                setFullTooltipMode={this.setFullTooltipMode}
            />,
        );
    }

    destroy() {
        if (!this.destroyed) {
            if (this.div != null && this.div.parentNode != null) {
                this.div.parentNode.removeChild(this.div);
            }

            this.destroyed = true;
            this.div = null;
            this.commentData = null;
        }
    }
}

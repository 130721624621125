// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditImportVisioPageHeaderDialogComponent__dialogContainer__ubipQ{padding:20px;background-color:#fff;border-radius:10px;font-family:\"Segoe UI\";font-size:14px;color:var(--Neutral800);line-height:32px}.EditImportVisioPageHeaderDialogComponent__row__b3XoJ{display:-webkit-box;display:-ms-flexbox;display:flex;margin-bottom:10px}.EditImportVisioPageHeaderDialogComponent__row__b3XoJ>span{min-width:32%}.EditImportVisioPageHeaderDialogComponent__row__b3XoJ>span:first-child{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:8px}.EditImportVisioPageHeaderDialogComponent__typeSelect___kFG8{width:200px}", "",{"version":3,"sources":["webpack://./src/modules/Import/Visio/components/EditImportVisioPageHeaderDialogComponent/EditImportVisioPageHeaderDialogComponent.scss"],"names":[],"mappings":"AAAA,kEACI,YAAA,CAEA,qBAAA,CACA,kBAAA,CAEA,sBAAA,CACA,cAAA,CACA,uBAAA,CACA,gBAAA,CAGJ,sDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kBAAA,CAEA,2DACI,aAAA,CAGJ,uEACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,gBAAA,CAIR,6DACI,WAAA","sourcesContent":[".dialogContainer {\r\n    padding: 20px;\r\n    \r\n    background-color: white;\r\n    border-radius: 10px;\r\n\r\n    font-family: 'Segoe UI';\r\n    font-size: 14px;\r\n    color: var(--Neutral800);\r\n    line-height: 32px;\r\n}\r\n\r\n.row {\r\n    display: flex;\r\n    margin-bottom: 10px;\r\n\r\n    & > span {\r\n        min-width: 32%;\r\n    }\r\n\r\n    & > span:first-child {\r\n        display: flex;\r\n        align-items: center;\r\n        margin-right: 8px;\r\n    }\r\n}\r\n\r\n.typeSelect {\r\n    width: 200px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": "EditImportVisioPageHeaderDialogComponent__dialogContainer__ubipQ",
	"row": "EditImportVisioPageHeaderDialogComponent__row__b3XoJ",
	"typeSelect": "EditImportVisioPageHeaderDialogComponent__typeSelect___kFG8"
};
export default ___CSS_LOADER_EXPORT___;

import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import {
    ExtraButtonProperties,
    InternationalString,
    LdapProperties,
    SystemKeycloakSettings,
    SystemProperties,
    SystemPropertiesActiveAuthorizationMethodsEnum,
    SystemPropertiesKerberosReceiveGroupsFromLdapEnum,
    SystemPropertiesKerberosUpdateUserInfoFromLdapEnum,
    SystemPropertiesMailPasswordStatusEnum,
} from '../serverapi/api';
import { AuthorizationMethod } from '@/models/security/authorizationMethod.types';

const systemPropertiesStateSelector = (state: TRootState) => state.systemProperties;

export namespace SystemPropertiesSelectors {
    export const getSystemProperties = createSelector<TRootState, SystemProperties, SystemProperties>(
        systemPropertiesStateSelector,
        (state) => {
            return state;
        },
    );

    export const getAppHost = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.appDbHost;
        },
    );

    export const getAppDBName = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.appDbName;
        },
    );

    export const getAppDbPort = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.appDbPort;
        },
    );

    export const getAppDbUser = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.appDbUser;
        },
    );

    export const getAppDbUrl = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.appDbUrl;
        },
    );

    export const getModelAutoSaveTimeout = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.modelAutoSaveTimeout;
        },
    );

    export const getModelVersionSaveTimeout = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.modelVersionSaveTimeout;
        },
    );

    export const getUserSessionInactivity = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.userSessionInactivity;
        },
    );

    export const getUserSessionLengthMax = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.userSessionLengthMax;
        },
    );

    export const getKerberosDebugStatus = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.kerberosDebugStatus;
        },
    );

    export const getKerberosKrb5Path = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.kerberosKrb5Path;
        },
    );

    export const getScriptEngineUrl = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.scriptEngineUrl;
        },
    );

    export const getServerCallbackUrl = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.serverCallbackUrl;
        },
    );

    export const getUiUrl = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.uiUrl;
        },
    );

    export const getImageUrl = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.imageUrl;
        },
    );

    export const getFileTempStoragePath = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.fileTempStoragePath;
        },
    );

    export const getServerUrl = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.serverUrl;
        },
    );

    export const getServerName = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.serverName;
        },
    );

    export const getServerIp = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.serverIp;
        },
    );

    export const getServerPort = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.serverPort;
        },
    );
    export const getServerTime = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.serverTime;
        },
    );

    export const getMailProtocol = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.mailProtocol;
        },
    );
    export const getMailHost = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.mailHost;
        },
    );

    export const getMailPort = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.mailPort;
        },
    );

    export const getMailUser = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.mailUser;
        },
    );

    export const getMailPassword = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.mailPassword;
        },
    );

    export const getMailFrom = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.mailFrom;
        },
    );

    export const getMailSsl = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.mailSsl;
        },
    );

    export const getMailSslProtocol = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.mailSslProtocols;
        },
    );

    export const getMailPasswordStatus = createSelector<
        TRootState,
        SystemProperties,
        SystemPropertiesMailPasswordStatusEnum | undefined
    >(systemPropertiesStateSelector, (state) => {
        return state?.mailPasswordStatus;
    });

    export const getIsOpenNavigator = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.isOpenNavigator;
        },
    );

    export const getIsEnLocaleAllowed = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.isEnLocaleAllowed;
        },
    );

    export const getIsGreetingLogo = createSelector<TRootState, SystemProperties, string | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.greetingLogo;
        },
    );

    export const getExtraButtonProperties = createSelector<
        TRootState,
        SystemProperties,
        ExtraButtonProperties[] | undefined
    >(systemPropertiesStateSelector, (state) => {
        return state?.extraButtonProperties;
    });

    export const getExtraButtonPropertiesPanelWidth = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.extraButtonPropertiesPanelWidth;
        },
    );

    export const getIsActiveHidingUsers = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.isActiveHidingUsers;
        },
    );

    export const getExperimentalFeatures = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.experimentalFeatures;
        },
    );

    export const getIsActiveAuthorizationSettings = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.isActiveAuthorizationSettings;
        },
    );

    export const getActiveAuthorizationMethods = createSelector<
        TRootState,
        SystemProperties,
        SystemPropertiesActiveAuthorizationMethodsEnum[]
    >(systemPropertiesStateSelector, (state) => {
        return state?.activeAuthorizationMethods || [];
    });

    export const hasKerberosAuthorizationMethod = createSelector<
        TRootState,
        SystemPropertiesActiveAuthorizationMethodsEnum[],
        boolean
    >(getActiveAuthorizationMethods, (state) => {
        return state.includes(AuthorizationMethod.KERBEROS);
    });

    export const geBlockAuthTime = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.blockAuthTime;
        },
    );

    export const getMaxFailAuthAttempts = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.maxFailAuthAttempts;
        },
    );
    export const minPasswordLength = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.minPasswordLength;
        },
    );

    export const passwordMinDurationTime = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.passwordMinDurationTime;
        },
    );

    export const passwordMaxDurationTime = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.passwordMaxDurationTime;
        },
    );

    export const countOfLastUsedPasswords = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.countOfLastUsedPasswords;
        },
    );

    export const isActiveUpperAndLowerCase = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.isActiveUpperAndLowerCase;
        },
    );

    export const isActiveNumbers = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.isActiveNumbers;
        },
    );

    export const isActiveSpecialCharacters = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.isActiveSpecialCharacters;
        },
    );

    export const getIsActiveSilaAgreement = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.isActiveSilaAgreement;
        },
    );

    export const getIsActiveAgreement = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.isActiveAgreement;
        },
    );

    export const getSilaAgreement = createSelector<TRootState, SystemProperties, InternationalString | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.silaAgreement;
        },
    );

    export const getAgreement = createSelector<TRootState, SystemProperties, InternationalString | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.agreement;
        },
    );

    export const getLdapSettings = createSelector<TRootState, SystemProperties, LdapProperties[] | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.ldapSettings;
        },
    );

    export const getMessageStorageDays = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.messageStorageDays;
        },
    );

    export const getTmpFilesStorageDays = createSelector<TRootState, SystemProperties, number | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.tmpFilesStorageDays;
        },
    );

    export const getKeycloakSettings = createSelector<TRootState, SystemProperties, SystemKeycloakSettings | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.keycloakSettings;
        },
    );

    export const getKerberosReceiveGroupsFromLdap = createSelector<
        TRootState,
        SystemProperties,
        SystemPropertiesKerberosReceiveGroupsFromLdapEnum | undefined
    >(systemPropertiesStateSelector, (state) => {
        return state?.kerberosReceiveGroupsFromLdap;
    });

    export const getKerberosUpdateUserInfoFromLdap = createSelector<
        TRootState,
        SystemProperties,
        SystemPropertiesKerberosUpdateUserInfoFromLdapEnum | undefined
    >(systemPropertiesStateSelector, (state) => {
        return state?.kerberosUpdateUserInfoFromLdap;
    });

    export const getGreetingText = createSelector<TRootState, SystemProperties, InternationalString | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.greetingText;
        },
    );

    export const getPasswordChangePermission = createSelector<TRootState, SystemProperties, boolean | undefined>(
        systemPropertiesStateSelector,
        (state) => {
            return state?.passwordChangePermission;
        },
    );
}

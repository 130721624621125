import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openDecompositionOnCanvas } from '../../../../../../actions/objectProperty.actions';
import icDecomposition from '../../../../../../resources/icons/decomposition.svg';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';

export const DecompositionButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getDecompositionBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getDecompositionBtnTooltip);

    return (
        <ToolbarButton
            icon={icDecomposition}
            size="small"
            dataTest="graph-general-toolbar_decomposition-button"
            disabled={disabled}
            onClick={() => dispatch(openDecompositionOnCanvas())}
            tooltip={tooltip}
        />
    );
};

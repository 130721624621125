// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltipRC__arrow__zS33i{border-radius:0}.tooltipRC__tooltip__sOJZQ{background-color:var(--secondaryNeutral900-3-UIKIT) !important;border-radius:2px !important;z-index:9999;display:-webkit-box;display:-ms-flexbox;display:flex;padding:4px 8px 6px !important}.tooltipRC__tooltip__sOJZQ ::-webkit-scrollbar-track{background-color:var(--secondaryNeutral900-3-UIKIT)}.tooltipRC__tooltip__sOJZQ ::-webkit-scrollbar-thumb{border:none}.tooltipRC__tooltip__sOJZQ ::-webkit-scrollbar{width:5px}.tooltipRC__tooltip__sOJZQ .react-tooltip-content-wrapper{word-wrap:anywhere;overflow-y:auto;overflow-x:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;font-size:14px;line-height:22px;font-family:Segoe UI}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/TooltipRC/tooltipRC.scss"],"names":[],"mappings":"AAAA,yBACI,eAAA,CAGJ,2BACI,8DAAA,CACA,4BAAA,CACA,YAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,8BAAA,CACA,qDACI,mDAAA,CAEJ,qDACI,WAAA,CAEJ,+CACI,SAAA,CAGA,0DACI,kBAAA,CACA,eAAA,CACA,iBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,cAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":[".arrow {\r\n    border-radius: 0;\r\n}\r\n\r\n.tooltip {\r\n    background-color: var(--secondaryNeutral900-3-UIKIT) !important;\r\n    border-radius: 2px !important;\r\n    z-index: 9999;\r\n    display: flex;\r\n    padding: 4px 8px 6px !important;\r\n    ::-webkit-scrollbar-track {\r\n        background-color: var(--secondaryNeutral900-3-UIKIT);\r\n    }\r\n    ::-webkit-scrollbar-thumb {\r\n        border: none;\r\n    }\r\n    ::-webkit-scrollbar {\r\n        width: 5px;\r\n    }\r\n    :global {\r\n        .react-tooltip-content-wrapper {\r\n            overflow-wrap: anywhere;\r\n            overflow-y: auto;\r\n            overflow-x: hidden;\r\n            display: flex;\r\n            font-size: 14px;\r\n            line-height: 22px;\r\n            font-family: Segoe UI;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": "tooltipRC__arrow__zS33i",
	"tooltip": "tooltipRC__tooltip__sOJZQ"
};
export default ___CSS_LOADER_EXPORT___;

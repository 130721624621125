import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import iFolder from '../../../resources/icons/ic-tree-folder.svg';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import theme from './SearchPath.scss';
import messages from './SearchPath.messages';
import { NodeId } from '@/serverapi/api';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TSearchPathDialogProps } from '../SearchPathDialog/SearchPathDialog.types';

type TSearchPathProps = {
    searchNodeId: NodeId;
};

export const SearchPath: FC<TSearchPathProps> = ({ searchNodeId }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const paths: string[] = useSelector(SearchSelectors.getSearchPaths(searchNodeId));

    const handleClick = () => {
        const dialogProps: TSearchPathDialogProps = { searchNodeId };
        dispatch(openDialog(DialogType.SEARCH_PATH, dialogProps));
    };

    return (
        <div className={theme.searchPath} onClick={handleClick}>
            <div className={theme.icon}>
                <Icon spriteSymbol={iFolder} />
            </div>
            <div className={theme.textContainer}>
                <h3>{`${intl.formatMessage(messages.header)}:`}</h3>
                <div className={theme.pathsContainer}>
                    {paths.map((path, index) => (
                        <div key={`${path}_${index}`} className={theme.path}>
                            {path}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

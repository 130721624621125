import React from 'react';
import { ToolbarButton } from '../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import icSettingsMatrix from '@/resources/icons/toolbar/ic-settingmatrix.svg';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../serverapi/api';
import { MatrixEditorSelectors } from '../../../../Matrix/selectors/matrixEditor.selectors';

export const OpenSettings = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(MatrixEditorSelectors.getOpenSettingsDisabled);
    const tooltip: string = useSelector(MatrixEditorSelectors.getOpenSettingsTooltip);
    const activeTabNodeId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const openSettings = () => {
        dispatch(openDialog(DialogType.MATRIX_SETTINGS_DIALOG, { nodeId: activeTabNodeId }));
    };

    return (
        <ToolbarButton
            icon={icSettingsMatrix}
            size="small"
            disabled={disabled}
            onClick={openSettings}
            tooltip={tooltip}
            dataTest="graph-general-toolbar_open-matrix-settings"
        />
    );
};

import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { UITemplateDTO } from '../../../serverapi/api';
import { TUITemplatesState } from '../reducers/UITemplates.reducer.types';
import { LocalesService } from '@/services/LocalesService';

const UITemplateStateSelector = (state: TRootState): TUITemplatesState => state.UITemplates;

export namespace UITemplatesSelectors {
    const allUITemplates = createSelector<TRootState, TUITemplatesState, UITemplateDTO[]>(
        UITemplateStateSelector,
        (state) => state.templates,
    );

    export const filteredUITemplates = (searchValue: string) =>
        createSelector<TRootState, UITemplateDTO[], UITemplateDTO[]>(allUITemplates, (UITemplates) =>
            UITemplates.filter((template) =>
                LocalesService.internationalStringToString(template.name).toLowerCase().includes(searchValue),
            ),
        );
}

import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import { ColorResult, SketchPicker } from 'react-color';
import { ControlsContext } from '../Controls.context';
import icFillColor from '@/resources/icons/MainMenuFill-Color.svg';
import { DEFAULT_BG_COLOR } from './Controls.constants';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('textStyle').backgroundColor || DEFAULT_BG_COLOR;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setBackgroundColor(value).run();

export const BGColor = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const selectFillColorHandler = (colorObject: ColorResult) => {
        setCurrentState(colorObject.hex);
    };

    return (
        <ToolbarButton
            icon={icFillColor}
            dataTest="wiki-toolbar-group_font-bgColor-button"
            overlay={<SketchPicker color={value} onChange={selectFillColorHandler} disableAlpha />}
            tooltip={intl.formatMessage(messages.colorFilling)}
            size="small"
        />
    );
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../ApprovalsTable/approvalTable.scss';
import { Checkbox } from '../../UIKit/components/Checkbox/Checkbox.component';
import { ApprovalTemplatesSelectors } from '@/selectors/approvalTemplates.selectors';
import { changeCheckAllApprovalTemplates } from '@/actions/approvalTemplates.actions';

type THeadCheckBoxProps = {
    tableDataLength: number;
};

export const HeadCheckBox = (props: THeadCheckBoxProps): JSX.Element => {
    const dispatch = useDispatch();
    const { tableDataLength } = props;
    const checkedIds: string[] = useSelector(ApprovalTemplatesSelectors.getCheckedApprovalTemplatesIds);

    return (
        <div className={theme.headerCheckBoxContainer}>
            <Checkbox
                status={!!tableDataLength && tableDataLength === checkedIds.length}
                onChange={() => {
                    dispatch(changeCheckAllApprovalTemplates());
                }}
            />
        </div>
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    openSettings: {
        id: 'MatrixEditorToolbar.openSettings',
        defaultMessage: 'Открыть настройки матрицы',
    },
    refreshMatrix: {
        id: 'MatrixEditorToolbar.refreshMatrix',
        defaultMessage: 'Обновить матрицу',
    },
    paste: {
        id: 'MatrixEditorToolbar.paste',
        defaultMessage: 'Вставить',
    },
    copy: {
        id: 'MatrixEditorToolbar.copy',
        defaultMessage: 'Копировать',
    },
    pasteRowBtm: {
        id: 'MatrixEditorToolbar.pasteRowBtm',
        defaultMessage: 'Вставить строку снизу',
    },
    pasteRowTop: {
        id: 'MatrixEditorToolbar.pasteRowTop',
        defaultMessage: 'Вставить строку сверху',
    },
    pasteColLeft: {
        id: 'MatrixEditorToolbar.pasteColLeft',
        defaultMessage: 'Вставить столбец слева',
    },
    pasteColRight: {
        id: 'MatrixEditorToolbar.pasteColRight',
        defaultMessage: 'Вставить столбец справа',
    },
    getImage: {
        id: 'MatrixEditorToolbar.getImage',
        defaultMessage: 'Получить изображение',
    },
    onlyInEditModeAvailable: {
        id: 'MatrixEditorToolbar.onlyInEditModeAvailable',
        defaultMessage: 'Доступно только в режиме редактирования',
    },
    onlyInReadModeAvailable: {
        id: 'MatrixEditorToolbar.onlyInReadModeAvailable',
        defaultMessage: 'Доступно только в режиме чтения',
    },
    selectElementInEditMode: {
        id: 'MatrixEditorToolbar.selectElementInEditMode',
        defaultMessage: 'Выберите элемент в режиме редактирования',
    },
    clipboardIsEmpty: {
        id: 'MatrixEditorToolbar.clipboardIsEmpty',
        defaultMessage: 'Буфер обмена пуст',
    },
    forbiddenByAutomatic: {
        id: 'MatrixEditorToolbar.forbiddenByAutomatic',
        defaultMessage: 'Не доступно в автоматизированном режиме заполнения',
    },
    selectHeaderToPaste: {
        id: 'MatrixEditorToolbar.selectHeaderToPaste',
        defaultMessage: 'Выберите заголовок для вставки',
    },
    selectHeadersToCopy: {
        id: 'MatrixEditorToolbar.selectHeadersToCopy',
        defaultMessage: 'Выберите непустой блок ячеек',
    },
    selectColumn: {
        id: 'MatrixEditorToolbar.selectColumn',
        defaultMessage: 'Выберите столбец',
    },
    selectRow: {
        id: 'MatrixEditorToolbar.selectRow',
        defaultMessage: 'Выберите строку',
    },
});

import {
    UI_TEMPLATE_EDITOR_CLOSE_TAB,
    UI_TEMPLATE_EDITOR_ADD_WIDGET,
    UI_TEMPLATE_EDITOR_CHANGE_ACTIVE_TAB,
    UI_TEMPLATE_EDITOR_DELETE_WIDGET,
    UI_TEMPLATE_EDITOR_SELECT_WIDGET,
    UI_TEMPLATE_EDITOR_UPDATE_WIDGET,
    UI_TEMPLATE_EDITOR_ADD_NEW_TEMPLATE,
    UI_TEMPLATE_EDITOR_UPDATE_TEMPLATE,
    UI_TEMPLATE_EDITOR_UPDATE_TABS,
} from '../actionTypes/UITemplateEditor.actionTypes';
import {
    TUITemplateEditorCloseTabAction,
    TUITemplateEditorAddWidgetAction,
    TUITemplateEditorChangeActiveTabAction,
    TUITemplateEditorDeleteWidgetAction,
    TUITemplateEditorAddNewTemplateAction,
    TUITemplateEditorSelectWidgetAction,
    TUITemplateEditorUpdateWidgetAction,
    TUITemplateEditorUpdateTemplateAction,
    TUITemplateEditorUpdateTabsAction,
} from './UITemplateEditor.actions.types';
import { NodeId, UITemplateDTO, UITemplateTabDTO, WidgetDTO } from '@/serverapi/api';

export const UITemplateEditorAddNewTemplate = (UITemplate: UITemplateDTO): TUITemplateEditorAddNewTemplateAction => ({
    type: UI_TEMPLATE_EDITOR_ADD_NEW_TEMPLATE,
    payload: {
        UITemplate,
    },
});

export const UITemplateEditorCloseTab = (tabNodeId: NodeId, UITemplateId: string): TUITemplateEditorCloseTabAction => ({
    type: UI_TEMPLATE_EDITOR_CLOSE_TAB,
    payload: {
        tabNodeId,
        UITemplateId,
    },
});

export const UITemplateEditorUpdateTemplate = (
    UITemplateId: string,
    UITemplateUpdates: Partial<UITemplateDTO>,
): TUITemplateEditorUpdateTemplateAction => ({
    type: UI_TEMPLATE_EDITOR_UPDATE_TEMPLATE,
    payload: {
        UITemplateId,
        UITemplateUpdates,
    },
});

export const UITemplateEditorUpdateTabs = (
    UITemplateId: string,
    newTabs: UITemplateTabDTO[],
): TUITemplateEditorUpdateTabsAction => ({
    type: UI_TEMPLATE_EDITOR_UPDATE_TABS,
    payload: {
        UITemplateId,
        newTabs,
    },
});

export const UITemplateEditorAddWidget = (templateId: string, widget: WidgetDTO): TUITemplateEditorAddWidgetAction => ({
    type: UI_TEMPLATE_EDITOR_ADD_WIDGET,
    payload: {
        UITemplateId: templateId,
        widget,
    },
});

export const UITemplateEditorDeleteWidget = (
    templateId: string,
    widgetId: string,
): TUITemplateEditorDeleteWidgetAction => ({
    type: UI_TEMPLATE_EDITOR_DELETE_WIDGET,
    payload: {
        UITemplateId: templateId,
        widgetId,
    },
});

export const UITemplateEditorSelectWidget = (
    templateId: string,
    widgetId: string,
): TUITemplateEditorSelectWidgetAction => ({
    type: UI_TEMPLATE_EDITOR_SELECT_WIDGET,
    payload: {
        UITemplateId: templateId,
        widgetId,
    },
});

export const UITemplateEditorUpdateWidget = (
    templateId: string,
    widget: WidgetDTO,
): TUITemplateEditorUpdateWidgetAction => ({
    type: UI_TEMPLATE_EDITOR_UPDATE_WIDGET,
    payload: {
        UITemplateId: templateId,
        widget,
    },
});

export const UITemplateEditorChangeActiveTab = (
    templateId: string,
    newActiveTabId: string,
): TUITemplateEditorChangeActiveTabAction => ({
    type: UI_TEMPLATE_EDITOR_CHANGE_ACTIVE_TAB,
    payload: {
        UITemplateId: templateId,
        newActiveTabId,
    },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
    dialogTitle: {
        id: 'ExportRepositoryDialog.dialogTitle',
        defaultMessage: 'Параметры экспорта',
    },
    cancelButton: {
        id: 'ExportRepositoryDialog.cancelButton',
        defaultMessage: 'Отмена',
    },
    submitButton: {
        id: 'ExportRepositoryDialog.submitButton',
        defaultMessage: 'Продолжить',
    },
    exportUsersPermissions: {
        id: 'ExportRepositoryDialog.exportUsersPermissions',
        defaultMessage: 'Экспортировать права пользователей:',
    },
    exportGroupsPermissions: {
        id: 'ExportRepositoryDialog.exportGroupsPermissions',
        defaultMessage: 'Экспортировать права групп пользователей:',
    },
});

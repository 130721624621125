import { EditModeButton } from '@/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/EditModeButton/EditModeButton';
import React from 'react';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { ResizableToolbar } from '../../../UIKit/components/Toolbar/ResizableToolbar/ResizableToolbar.component';
import { RefreshButton } from './Buttons/Refresh.component';
import { DeleteButton } from './Buttons/Delete.component';
import { NodeId } from '../../../../serverapi/api';

type TReportEditorToolbar = {
    selectedNodeIds: NodeId[];
    showDeleteBtn?: boolean;
};

export const ReportEditorToolbar = (props: TReportEditorToolbar) => {
    const { selectedNodeIds, showDeleteBtn } = props;

    return (
        <ResizableToolbar>
            {[
                <ButtonGroup>
                    <EditModeButton />
                </ButtonGroup>,
                <ButtonGroup>
                    <RefreshButton />
                </ButtonGroup>,
            ].concat(
                showDeleteBtn
                    ? [
                          <ButtonGroup>
                              <DeleteButton selectedNodeIds={selectedNodeIds} />
                          </ButtonGroup>,
                      ]
                    : [],
            )}
        </ResizableToolbar>
    );
};

import { Modal, Tooltip } from 'antd';
import theme from './DuplicatesTable.scss';
import { v4 as uuid } from 'uuid';
import React, { Dispatch, FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import messages from './messages/duplicatesTable.messages';
import icProperty from '../../../resources/icons/icProperty.svg';
import icGo from '../../../resources/icons/ic-go.svg';
import { Icon } from '@/modules/UIKit/components/Icon/Icon.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { useDispatch, useSelector } from 'react-redux';
import { moveToDirectAction } from '@/actions/editor.actions';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { treeFilter } from '@/actions/tree.actions';
import { viewModelProperties } from '@/actions/modelProperty.actions';
import { clearStatuses, mergeDataRequest } from '@/actions/findDuplicates.actions';
import { checkCombiningProcess, getLastStatuses } from '@/selectors/findDuplicates.selector';
import { showNotification } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { DuplicatesElementInfo, DuplicatesGroup, MergeObjectStatus, NodeId } from '@/serverapi/api';
import {
    CheckboxType,
    TElementInfoWithStatus,
    TGroupItems,
    TGroupItemUpdater,
    TNewSelectedItemsByGroup,
} from '../types/searchDuplicates.types';
import { TreeNode } from '@/models/tree.types';
import { IntlShape, useIntl } from 'react-intl';
import {
    getConsolidationInfo,
    getOperationStatusText,
    processStrategy,
    sortElementsByCreatedAt,
    successStatuses,
} from '../utils/utils';
import { TMergeDuplicatesRequest } from '@/reducers/findDuplicates.types';
import { getActiveGraph } from '@/selectors/editor.selectors';
import { compareNodeIds } from '@/utils/nodeId.utils';

type TDuplicatesTableProps = {
    filteredGroups: DuplicatesGroup[];
    nodeId: NodeId;
    strategy: string;
    mergeAttributes: boolean;
    mergeDecomposition: boolean;
    deleteSecondary: boolean;
    duplicatesGroups: DuplicatesGroup[];
};

export const DuplicatesTable: FC<TDuplicatesTableProps> = ({
    filteredGroups,
    nodeId,
    strategy,
    mergeAttributes,
    mergeDecomposition,
    deleteSecondary,
    duplicatesGroups
}) => {
    const dispatch: Dispatch<any> = useDispatch();
    const [selectedItemsByGroup, setSelectedItemsByGroup] = useState<TNewSelectedItemsByGroup>({});
    const activeGraphId: NodeId | undefined = useSelector(getActiveGraph);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentGroupId, setCurrentGroupId] = useState<string | null>(null);
    const [selectedForCombineCount, setSelectedForCombineCount] = useState<number>(0);
    const statuses: MergeObjectStatus[] = useSelector(getLastStatuses(nodeId));
    const combiningInProcess: boolean = useSelector(checkCombiningProcess());
    const isFirstSkip: MutableRefObject<boolean> = useRef(true);
    const intl: IntlShape = useIntl();

    useEffect(() => {
        if (compareNodeIds(activeGraphId, nodeId) && statuses.length) {
            checkOperationStatuses();
        }
    }, [statuses]);

    useEffect(() => {
        return () => {
            dispatch(clearStatuses(nodeId));
        };
    }, []);

    useEffect(() => {
        setSelectedItemsByGroup((prevState: TNewSelectedItemsByGroup) =>
            processStrategy(prevState, duplicatesGroups, strategy, isFirstSkip),
        );
    }, [strategy]);

    const checkOperationStatuses = () => {
        const hasErrors: boolean = statuses.some((el: MergeObjectStatus) => !successStatuses.includes(el.status || ''));

        if (hasErrors) {
            dispatch(showNotification({ id: uuid(), type: NotificationType.CONSOLIDATION_ERROR }));
        } else {
            dispatch(showNotification({ id: uuid(), type: NotificationType.CONSOLIDATION_SUCCESS }));
        }
    };
    
    const updateGroupItems = (groupId: string, groupItemUpdater: TGroupItemUpdater) => {
        setSelectedItemsByGroup((prevState: TNewSelectedItemsByGroup) => {
            const updatedGroup: TGroupItems = { ...prevState[groupId] };

            Object.keys(updatedGroup).forEach((elementId) => {
                updatedGroup[elementId] = groupItemUpdater(updatedGroup[elementId], elementId);
            });

            return {
                ...prevState,
                [groupId]: updatedGroup,
            };
        });
    };
    const handleCheckboxChange = (groupId: string, selectedId: string, type: CheckboxType) => {
        updateGroupItems(groupId, (item, currentElementId) => ({
            ...item,
            [type]: currentElementId === selectedId ? !item[type] : type === CheckboxType.Main ? false : item.combine,
        }));
    };
    const openPropertiesHandler = (id: string) => {
        dispatch(viewModelProperties({ ...nodeId, id }));
    };
    const moveToHandler = (element: DuplicatesElementInfo) => {
        dispatch(treeFilter(true, [TreeItemType.ObjectDefinition]));
        dispatch(moveToDirectAction({ nodeId: { ...nodeId, id: element.id } } as TreeNode, NAVIGATOR_STRUCTURE));
    };

    const generateTableData = useCallback(
        (group: DuplicatesGroup, groupId: string) => {
            const sortedElements: DuplicatesElementInfo[] = sortElementsByCreatedAt(group.duplicatesElementInfo);

            return sortedElements.map((element: DuplicatesElementInfo) => {
                const id: string = element.id || uuid();
                const isCombine: boolean = !!selectedItemsByGroup[groupId]?.[id]?.combine;
                const isMain: boolean = !!selectedItemsByGroup[groupId]?.[id]?.main;
                const operationStatus: string = (element as TElementInfoWithStatus).status
                    ? getOperationStatusText(intl, (element as TElementInfoWithStatus).status)
                    : '';
                return {
                    id: element.id,
                    combine: (
                        <div className={theme.combineContainer}>
                            <Checkbox
                                status={isCombine}
                                onChange={() => handleCheckboxChange(groupId, id, CheckboxType.Combine)}
                            />
                        </div>
                    ),
                    mainObject: (
                        <div className={theme.combineContainer}>
                            <Checkbox
                                status={isMain}
                                onChange={() => handleCheckboxChange(groupId, id, CheckboxType.Main)}
                            />
                        </div>
                    ),
                    path: element.path,
                    instanceCount: element.instanceCount || 0,
                    createdAt: element.createdAt ? new Date(element.createdAt).toLocaleString('ru-RU') : '',
                    createdBy: element.createdBy,
                    updatedAt: element.updatedAt ? new Date(element.updatedAt).toLocaleString('ru-RU') : '',
                    updatedBy: element.updatedBy,
                    blocked: element.userEditDisabled
                        ? intl.formatMessage(messages.yes)
                        : intl.formatMessage(messages.no),
                    status: operationStatus,
                    icons: (
                        <div className={theme.iconsContainer}>
                            <span onClick={() => openPropertiesHandler(id)}>
                                <Icon spriteSymbol={icProperty} />
                            </span>
                            <span onClick={() => moveToHandler(element)}>
                                <Icon spriteSymbol={icGo} />
                            </span>
                        </div>
                    ),
                    checked: !!selectedItemsByGroup[groupId]?.[id]?.main,
                };
            });
        },
        [selectedItemsByGroup],
    );

    const columns = [
        { title: intl.formatMessage(messages.combine), dataKey: 'combine' },
        { title: intl.formatMessage(messages.main), dataKey: 'mainObject' },
        { title: intl.formatMessage(messages.destination), dataKey: 'path' },
        { title: intl.formatMessage(messages.instanceCount), dataKey: 'instanceCount' },
        { title: intl.formatMessage(messages.createdAt), dataKey: 'createdAt' },
        { title: intl.formatMessage(messages.createdBy), dataKey: 'createdBy' },
        { title: intl.formatMessage(messages.updatedAt), dataKey: 'updatedAt' },
        { title: intl.formatMessage(messages.updatedBy), dataKey: 'updatedBy' },
        { title: intl.formatMessage(messages.blocked), dataKey: 'blocked' },
        { title: intl.formatMessage(messages.status), dataKey: 'status' },
        { title: '', dataKey: 'icons' },
    ];

    const handleConsolidate = (groupId: string) => {
        setCurrentGroupId(groupId);
        const selectedCount: number = Object.values(selectedItemsByGroup[groupId] || {}).filter(
            (item) => item.combine,
        ).length;
        setSelectedForCombineCount(selectedCount);
        setShowModal(true);
    };

    const handleSubmit = () => {
        setShowModal(false);

        if (!currentGroupId) return;
        const selectedGroupItems: TGroupItems = selectedItemsByGroup[currentGroupId];

        const primaryId: string | undefined = Object.keys(selectedGroupItems).find(
            (id: string) => selectedGroupItems[id].main,
        );
        const secondaryIds: string[] = Object.keys(selectedGroupItems).filter(
            (id: string) => selectedGroupItems[id].combine && id !== primaryId,
        );

        const mergeRequest: TMergeDuplicatesRequest = {
            repositoryId: nodeId.repositoryId,
            primaryId: primaryId,
            secondaryIds: secondaryIds,
            mergeAttribute: mergeAttributes,
            mergeDecomposition: mergeDecomposition,
            deleteSecondary: deleteSecondary,
        };

        dispatch(mergeDataRequest(mergeRequest, nodeId));
    };

    return (
        <div>
            {filteredGroups.map((group: DuplicatesGroup) => {
                const firstDuplicatesElement: DuplicatesElementInfo = group.duplicatesElementInfo[0];
                const groupId: string =
                    group.duplicatesElementInfo.length > 0 ? firstDuplicatesElement.id || uuid() : uuid();

                const { canConsolidate, consolidateTip } = getConsolidationInfo(
                    intl,
                    selectedItemsByGroup,
                    groupId,
                    combiningInProcess,
                );
                return (
                    <div className={`${theme.duplicatesTable}`} key={groupId}>
                        <div className={theme.duplicatesInfo}>
                            <span className={theme.duplicatesObjectInfo}>{intl.formatMessage(messages.name)}</span>
                            {firstDuplicatesElement?.name}
                            <br />
                            <span className={theme.duplicatesObjectInfo}>{intl.formatMessage(messages.type)}</span>
                            {firstDuplicatesElement?.typeId}
                        </div>
                        <div className={theme.consolidateButtonWrapper}>
                            <Tooltip key={groupId} mouseLeaveDelay={0} title={!canConsolidate ? consolidateTip : ''}>
                                <span>
                                    <Button
                                        key="consolidate"
                                        disabled={!canConsolidate}
                                        onClick={() => handleConsolidate(groupId)}
                                        size="large"
                                    >
                                        {intl.formatMessage(messages.combine)}
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        <div className={theme.duplicatesTableContainer}>
                            <table className={theme.duplicatesTable}>
                                <thead>
                                    <tr>
                                        {columns.map((column) => (
                                            <th key={column.dataKey}>{column.title}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {generateTableData(group, groupId).map((row) => (
                                        <tr key={row.id} className={row.checked ? theme.mainRow : ''}>
                                            {columns.map((column) => (
                                                <td key={column.dataKey}>{row[column.dataKey]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            })}
            <Modal
                title={intl.formatMessage(messages.combineConfirmation)}
                open={showModal}
                onOk={handleSubmit}
                onCancel={() => setShowModal(false)}
                okText={intl.formatMessage(messages.combine)}
                cancelText={intl.formatMessage(messages.cancel)}
            >
                <p>
                    {intl.formatMessage(messages.combineConfirmationQuestion)} {`${selectedForCombineCount}?`}
                </p>
            </Modal>
        </div>
    );
};

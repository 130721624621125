import React, { KeyboardEvent, ChangeEvent, useState, useRef, useEffect, useId, useLayoutEffect } from 'react';
import { Icon } from '../Icon/Icon.component';
import newTheme from './Select.scss';
import likeAnAntdTheme from './SelectLikeAnAntd.scss';
import ArrowDown from '../../../../resources/icons/UIKit/Arrow_Down.svg';
import emptyData from '../../../../resources/icons/UIKit/emptyData.svg';
import { useFocus } from './useFocus';
import { Option } from './Option.component';
import { useClickOutside } from './useClickOutside';
import { TDropdownAlignment, TDropdownPosition, TIconBtnView, TOptionProps } from './Select.types';
import { SearchInput } from './SearchInput.component';
import { ClearButton } from './ClearButton.component';
import messages from './Select.messages';
import { useIntl } from 'react-intl';
import { useFirstRender } from '@/utils/useFirstRender';
import { createPortal } from 'react-dom';
import { calculateDropdownPosition, findNextActiveOption, getDropdownTop } from './utils/select.utils';
import { TOOLTIP_RC_ID } from '../TooltipRC/TooltipRC.constants';

type TSelectChildren = React.ReactElement<TOptionProps, typeof Option>;

type TControlledMultiSelectProps = {
    isMultiSelect: true;
    isControlledMultiSelect: true;
    onChange?: (value?: string) => void;
};

type TMultiSelectProps = {
    isMultiSelect: true;
    isControlledMultiSelect?: false;
    onChange?: (values?: string[]) => void;
};

type TSelectProps = {
    isMultiSelect?: false;
    isControlledMultiSelect?: false;
    onChange?: (value?: string) => void;
};

type TSelectFullProps = (TMultiSelectProps | TControlledMultiSelectProps | TSelectProps) & {
    label?: JSX.Element | string;
    value?: JSX.Element | string;
    placeholder?: string;
    showSearch?: boolean;
    allowClear?: boolean;
    errorTrigger?: boolean;
    required?: boolean;
    allowSelectAll?: boolean;
    children: TSelectChildren[];
    dropdownClassName?: string;
    selectTextClassName?: string;
    // todo: после перехода на новые стили этот пропс будет не нужен
    originalTheme?: boolean;
    onSearch?: (value: string) => void;
    searchValue?: string;
    'data-test'?: string;
    wrapperClassName?: string;
    dropdownWidth?: number;
    disabled?: boolean;
    dropdownAlignment?: TDropdownAlignment;
    iconBtnView?: TIconBtnView;
    tooltip?: string;
};

/**
 * когда перейдем на новые стили из figma нужно будет убрать из импорта SelectLikeAnAntd.scss
 * стили будут только из Select.scss
 */

/**
 * Кастомный селект с возможностью добавить строку поиска, кнопку "Очистить" и чекбоксы
 * есть возможность управления с клавиатуры, дропдаун можно стилизовать.
 * В качестве children передается массив Select.Option
 *
 * @param {JSX.Element | string | undefined} label текст над селектом
 * @param {JSX.Element | string | undefined} value значение внутри хедера селекта
 * @param {string | undefined} placeholder текст внутри селекта, если ничего не выбрано
 * @param {false | undefined} isMultiSelect в этом режиме onChange принимает массив + добавляются чекбоксы
 * @param {boolean | undefined} isControlledMultiSelect в этом режиме onChange принимает одно выбранное значение
 * @param {boolean | undefined} showSearch добавляет поиск
 * @param {boolean | undefined} allowClear добавляет кнопку удаления выбранных значений
 * @param {boolean | undefined} required обязательный выбор
 * @param {boolean | undefined} errorTrigger пропс необходимый для триггера проверки обязательного поля
 * @param {boolean | undefined} allowSelectAll добавляет чекбокс для возможности выбора всех опций
 * @param {TSelectChildren[]} children массив Select.Option
 * @param {string | undefined} selectTextClassName возможность стилизовать текст внутри селекта
 * @param {string | undefined} dropdownClassName возможность стилизовать дропдаун
 * @param {((value?: string) => void) | undefined} onChange функция, срабатывает при выборе значений
 * @param {((value: string) => void) | undefined} onSearch функция, которой можно заменить дефолтный поиск
 * @param {string | undefined} searchValue значение поля поиска, которым можно заменить дефолтное
 * @param {string | undefined} `data-test` идентификатор для тестов
 * @param {string | undefined} wrapperClassName класс для контейнера селекта
 * @param {number | undefined} dropdownWidth ширина дропдауна
 * @param {'left' | 'right' | undefined} dropdownAlignment выравниванме дробдауна: по левому краю/по правому краю
 * @param {TIconBtnView | undefined} iconBtnView хедер в виде иконки.
 * @param {string | undefined} tooltip текст подсказки.
 *
 */
export const Select = (props: TSelectFullProps) => {
    const {
        label,
        value,
        isMultiSelect,
        isControlledMultiSelect,
        showSearch,
        allowClear,
        dropdownClassName,
        selectTextClassName,
        required,
        originalTheme,
        searchValue,
        placeholder,
        errorTrigger,
        wrapperClassName,
        dropdownWidth,
        allowSelectAll,
        disabled,
        dropdownAlignment,
        iconBtnView,
        tooltip,
    } = props;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [isActive, setIsActive] = useState<boolean>(false);
    const [allOptionSelected, setAllOptionSelected] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [activeOption, setActiveOption] = useState<number | null>(null);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [selectButtonRef, setFocusOnSelectButton] = useFocus<HTMLButtonElement>(null);
    const [dropdownPosition, setDropdownPosition] = useState<TDropdownPosition | null>(null);
    const isFirstRender = useFirstRender();
    const theme = originalTheme ? newTheme : likeAnAntdTheme;
    const selectTextCN = selectTextClassName || theme.selectText;
    const optionsContainerId = useId();
    const dropdownContainerId = useId();

    const containerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const portalRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    const intl = useIntl();

    useEffect(() => {
        const newSelectedOptions: string[] = [];
        props.children.forEach((child) => {
            if (child.props.checked) newSelectedOptions.push(child.props.value);
        });
        setSelectedOptions(newSelectedOptions);
    }, [props.children]);

    useEffect(() => {
        if (required && !value && !isActive && !isFirstRender) {
            setError(true);
        } else {
            setError(false);
        }
    }, [isActive, value, errorTrigger]);

    useEffect(() => {
        setAllOptionSelected(selectedOptions.length === props.children.length);
    }, [selectedOptions]);

    useClickOutside(
        containerRef,
        () => {
            setActiveOption(null);
            setIsActive(false);
        },
        portalRef,
    );

    const recalculateDropdownOptions = () => {
        const selectBtnElement: HTMLButtonElement | null = selectButtonRef.current;

        if (!selectBtnElement) return;

        const selectBtnRectangle: DOMRect = selectBtnElement.getBoundingClientRect();
        const paddingRight: number = parseInt(getComputedStyle(selectBtnElement).paddingRight);
        const { innerWidth, innerHeight } = window;
        const dropdown: HTMLDivElement | null = portalRef.current;

        const { width, x: posX } = calculateDropdownPosition(
            selectBtnRectangle,
            paddingRight,
            innerWidth,
            dropdownWidth,
            dropdownAlignment,
            !!iconBtnView,
        );

        const dropdownHeight: number = dropdown?.children[0].getBoundingClientRect().height || 0;

        const { top, isDownDirection } = getDropdownTop({
            selectBtnRectangle,
            innerHeight: innerHeight,
            dropdownHeight,
            iconBtnView: !!iconBtnView,
        });

        setDropdownPosition({ width: width, x: posX, y: top, isDownDirection: isDownDirection });
    };
    const onScrollListener = (event: Event) => {
        const optionsContainer = document.getElementById(optionsContainerId);
        const dropdownContainer = document.getElementById(dropdownContainerId);
        if (event.target && event.target !== optionsContainer && event.target !== dropdownContainer) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', recalculateDropdownOptions);
        document.addEventListener('scroll', onScrollListener, true);

        return () => {
            window.removeEventListener('resize', recalculateDropdownOptions);
            document.removeEventListener('scroll', onScrollListener);
        };
    }, []);

    const handleClickHeader = () => {
        setIsActive((prevState) => !prevState);
    };

    useLayoutEffect(() => {
        recalculateDropdownOptions();
    }, [isActive, isFirstRender]);

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setActiveOption(null);

        if (props.onSearch) {
            props.onSearch(e.target.value);
        } else {
            setSearchFilter(e.target.value.toLowerCase());
        }
    };

    const selectAllOptionValue = 'selectAllOptionValue';

    const onChange = (val: string | undefined) => {
        if (!props.onChange) return;

        if (isMultiSelect && isControlledMultiSelect) {
            props.onChange(val);

            return;
        }

        if (isMultiSelect && !isControlledMultiSelect) {
            let newSelectedOptions: string[] = [];

            if (val === undefined) {
                props.onChange(newSelectedOptions);
                setSelectedOptions(newSelectedOptions);
                return;
            }

            if (val === selectAllOptionValue) {
                if (!allOptionSelected) {
                    newSelectedOptions = props.children.map((option) => option.props.value);
                }
            } else {
                newSelectedOptions = selectedOptions.includes(val)
                    ? selectedOptions.filter((option) => option !== val)
                    : [...selectedOptions, val];
            }

            props.onChange(newSelectedOptions);
            setSelectedOptions(newSelectedOptions);
        } else {
            props.onChange(val);
            setIsActive(false);
        }
    };

    const selectAllOption: TSelectChildren = (
        <Option
            key="selectAllOption"
            label={intl.formatMessage(messages.selectAll)}
            value={selectAllOptionValue}
            checked={allOptionSelected}
            showCheckbox={isMultiSelect}
        />
    );

    const getSearchedOptions = (): TSelectChildren[] => {
        let result = [...props.children];
        if (searchFilter)
            result = props.children.filter((option) =>
                typeof option.props.label === 'string' ? option.props.label.toLowerCase().includes(searchFilter) : true,
            );

        if (isMultiSelect && allowSelectAll) result.unshift(selectAllOption);

        return result;
    };

    const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (!isActive) return;

        if (e.code === 'Escape') {
            setIsActive(false);
            setActiveOption(null);
            setFocusOnSelectButton();
        }

        if (e.code === 'ArrowDown') {
            e.preventDefault();
            setActiveOption((prevState) => {
                const optionsDisaibled: boolean[] = props.children.map((child) => !!child.props.optionDisaibled);
                return findNextActiveOption(prevState, optionsDisaibled, 'down');
            });
        }

        if (e.code === 'ArrowUp') {
            setActiveOption((prevState) => {
                const optionsDisaibled: boolean[] = props.children.map((child) => !!child.props.optionDisaibled);
                return findNextActiveOption(prevState, optionsDisaibled, 'up');
            });
        }

        if (activeOption !== null && isMultiSelect && e.code === 'Space') {
            e.preventDefault();
            onChange(getSearchedOptions()[activeOption].props.value);
        }

        if (activeOption !== null && !isMultiSelect && e.code === 'Enter') {
            e.preventDefault();

            if (props.children[activeOption].props.optionDisaibled) return;

            onChange(getSearchedOptions()[activeOption].props.value);
        }
    };

    const searchedOptions: TSelectChildren[] = getSearchedOptions();

    const selectedOptionsContainer = () => {
        const selectedLabels: (string | JSX.Element)[] = [];
        props.children.forEach((child) => {
            if (child.props.checked) selectedLabels.push(child.props.label);
        });

        return (
            <div className={theme.selectedLabelsContainer}>
                {selectedLabels.map((selectedLabel, index) => {
                    return (
                        <div key={`selected-label-${index}`} className={theme.selectedLabelItem}>
                            {selectedLabel}
                        </div>
                    );
                })}
            </div>
        );
    };

    const showSelectedOptions = selectedOptions.length && !value;
    const currentPlaceholder = placeholder ?? intl.formatMessage(messages.defaultPlaceholder);

    return (
        <div
            data-test={`${props['data-test']}_container`}
            ref={containerRef}
            onKeyDown={onKeyPress}
            className={`${theme.selectContainer} ${wrapperClassName}`}
        >
            {label && <div className={`${theme.label} ${required ? theme.required : ''}`}>{label}</div>}
            <button
                ref={selectButtonRef}
                type="button"
                data-test={`${props['data-test']}_button`}
                className={`${theme.selectButton} ${isActive ? theme.selectButtonActive : ''} ${
                    error ? theme.requiredButton : ''
                } ${iconBtnView ? theme.iconBtnView : ''} ${disabled ? theme.disabled : ''}`}
                onClick={handleClickHeader}
                disabled={disabled}
                data-tooltip-id={TOOLTIP_RC_ID}
                data-tooltip-content={tooltip}
                data-tooltip-max-width={'none'}
                data-tooltip-place={!dropdownPosition?.isDownDirection && isActive ? 'left' : 'top'}
            >
                <div
                    className={`${selectTextCN} ${value ? '' : theme.placeholder} ${iconBtnView ? theme.iconView : ''}`}
                >
                    {iconBtnView ? (
                        <Icon spriteSymbol={iconBtnView.icon} />
                    ) : showSelectedOptions ? (
                        selectedOptionsContainer()
                    ) : (
                        <span>{value || currentPlaceholder}</span>
                    )}
                </div>
                <Icon className={theme.arrowDown} spriteSymbol={ArrowDown} />
            </button>

            {error && <div className={theme.error}>{intl.formatMessage(messages.error)}</div>}
            {isActive &&
                createPortal(
                    <div
                        ref={portalRef}
                        className={`${theme.dropdownContainer} ${dropdownClassName}`}
                        style={{
                            top: `-${dropdownPosition?.y}px`,
                            left: dropdownPosition?.x,
                            width: dropdownPosition?.width,
                        }}
                    >
                        <div
                            className={theme.dropdown}
                            data-test={`${props['data-test']}_dropdown`}
                            id={dropdownContainerId}
                        >
                            <SearchInput
                                showSearch={showSearch}
                                originalTheme={originalTheme}
                                onSearch={onSearch}
                                searchValue={searchValue !== undefined ? searchValue : searchFilter}
                                autoFocus
                            />
                            <ClearButton allowClear={allowClear} originalTheme={originalTheme} onChange={onChange} />
                            <div className={theme.optionsContainer} role="toolbar" id={optionsContainerId}>
                                {searchedOptions.length ? (
                                    searchedOptions.map((child: TSelectChildren, id: number): TSelectChildren => {
                                        const isOptionDiasbled: boolean = !!child.props.optionDisaibled;
                                        const optionWithSelectProps = {
                                            ...child,
                                            props: {
                                                ...child.props,
                                                showCheckbox: isMultiSelect,
                                                onChange: isOptionDiasbled ? undefined : onChange,
                                                isActive: id === activeOption,
                                            },
                                        };

                                        return (
                                            <div
                                                data-test={`sort-by-type_${child.props.value}`}
                                                key={child.props.value}
                                                className={
                                                    isOptionDiasbled
                                                        ? theme.disabledOptionContainer
                                                        : theme.activeOptionContainer
                                                }
                                            >
                                                {optionWithSelectProps}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className={theme.emptyDataContainer}>
                                        <Icon spriteSymbol={emptyData} className={theme.emptyDataIcon} />
                                        {intl.formatMessage(messages.emptyData)}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>,
                    document.body,
                )}
        </div>
    );
};

Select.Option = Option;

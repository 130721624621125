import { takeEvery, select, put, call } from 'redux-saga/effects';
import {
    FETCH_ALL_GROUPS_REQUEST,
    OPEN_EDITING_GROUPDATA_TAB,
    FETCH_GROUP_BYID_REQUEST,
    DELETE_GROUP,
    SUBMIT_GROUP_DATA,
    ADD_USERS_TO_GROUP_REQUEST,
    REMOVE_USERS_FROM_GROUP,
} from '../actionsTypes/groups.actionTypes';
import {
    fetchAllGroupsSuccess,
    fetchGroupByIdSuccess,
    editGroupDataBegin,
    deleteGroupSuccess,
    editGroupDataEnd,
    setIsLoadingGroups,
} from '../actions/groups.actions';
import {
    TOpenEdititngGroupDataTabAction,
    TFetchGroupByIdAction,
    TDeleteGroupAction,
    TSubmitGroupDataAction,
    TAddUsersToGroupRequestAction,
    TRemoveUsersFromGroupAction,
} from '../actions/groups.actions.types';
import { MetaDataSelectors } from '../selectors/admintools.selectors';
import { GroupDTO, NodeId, UserDTO } from '../serverapi/api';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTab, TWorkspaceTabItemParams } from '../models/tab.types';
import { EditorMode } from '../models/editorMode';
import { AdminToolTreeType, EditingTabMode } from '../modules/AdminTools/data/admintool.types';
import { workspaceAddTab, workspaceRemoveTabRequest } from '../actions/tabs.actions';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { fetchUsersByGroupIdSuccess, fetchUsersByGroupIdRequest } from '../actions/users.actions';
import { closeDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import groupMessages from '../modules/AdminTools/messages/groups.messages';
import { groupService } from '../services/GroupService';
import { userService } from '../services/dao/UsersDAOService';
import { ServerErrorType } from '../models/serverErrorType';
import { showNotificationByType } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { LocalesService } from '../services/LocalesService';

function* handleFetchGroupsRequest() {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    yield put(setIsLoadingGroups(true));
    try {
        const groups: GroupDTO[] = yield call(() => groupService().getAll({ serverId }));
        if (groups) {
            yield put(fetchAllGroupsSuccess({ groups, serverId }));
        }
    } finally {
        yield put(setIsLoadingGroups(false));
    }
}

function* handleFetchGroupByIdRequest({ payload: { groupId } }: TFetchGroupByIdAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    const groupData: GroupDTO = yield call(() => groupService().get({ id: groupId, serverId }));
    if (groupData) {
        yield put(fetchGroupByIdSuccess({ groupData, serverId }));
    }
    yield put(fetchUsersByGroupIdRequest({ groupId, serverId }));
}

function* handleOpenGroupDataEditingTab({ payload: { groupData, mode } }: TOpenEdititngGroupDataTabAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    const intl = LocalesService.useIntl();

    if (groupData.id !== 0) {
        const actualGroupData: GroupDTO = yield call(() => groupService().get({ id: groupData.id, serverId }));
        yield put(editGroupDataBegin(actualGroupData));
    } else {
        yield put(editGroupDataBegin(groupData));
    }
    const contentLoadingPageTab: TWorkspaceTab = {
        title: mode === EditingTabMode.EDIT ? `${groupData.groupName}` : intl.formatMessage(groupMessages.newGroupName),
        nodeId: { id: `${groupData.id}`, repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT, serverId },
        type: WorkSpaceTabTypes.GROUPDATA_EDITING_TAB,
        mode: EditorMode.Read,
        params: {
            closable: false,
            serverId,
        } as TWorkspaceTabItemParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* hadleDeleteGroupRequest({ payload: { groupId } }: TDeleteGroupAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    yield call(() => groupService().delete({ id: groupId, serverId }));
    yield put(deleteGroupSuccess({ serverId, groupId }));
}

function* handleSubmitGroupData({ payload: { groupData, isNewGroup } }: TSubmitGroupDataAction) {
    const serverId = yield select(MetaDataSelectors.getCurrentServerId);
    let groupDataSaved: GroupDTO | null = null;
    try {
        if (isNewGroup) {
            groupDataSaved = yield call(() =>
                groupService().create({
                    group: groupData,
                    serverId,
                }),
            );
        } else {
            groupDataSaved = yield call(() =>
                groupService().save({
                    group: groupData,
                    serverId,
                }),
            );
        }
    } catch (e) {
        if (e.status === ServerErrorType.DUPLICATE_ENTITY) {
            yield put(showNotificationByType(NotificationType.GROUP_ID_CONFLICT));
        } else {
            throw e;
        }
    }

    if (groupDataSaved && groupDataSaved.id) {
        yield put(fetchGroupByIdSuccess({ groupData: groupDataSaved, serverId }));
        yield put(editGroupDataEnd(groupDataSaved.id));
        const groupTab: NodeId = {
            id: `${groupData.id}`,
            repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
            serverId,
        } as NodeId;

        const tab: TWorkspaceTab = yield select(TabsSelectors.byId(groupTab));
        yield put(workspaceRemoveTabRequest(tab));
    }
}

function* handleAddUsersToGroupRequest({ payload: { serverId, groupId, usersIds } }: TAddUsersToGroupRequestAction) {
    yield call(() => userService().addByGroup({ groupId, usersIds: { ids: usersIds }, serverId }));
    const users: UserDTO[] = yield call(() => userService().getByGroup({ groupId, serverId }));
    if (users) {
        yield put(fetchUsersByGroupIdSuccess({ users, groupId, serverId }));
    }
    yield put(closeDialog(DialogType.ADD_USER_TO_GROUP));
}

function* handleRemoveUsersFromGroupRequest({ payload: { serverId, groupId, usersIds } }: TRemoveUsersFromGroupAction) {
    for (let index = 0; index < usersIds.length; index++) {
        yield call(() => userService().removeByGroup({ groupId, userId: usersIds[index], serverId }));
    }
    const users: UserDTO[] = yield call(() => userService().getByGroup({ groupId, serverId }));
    if (users) {
        yield put(fetchUsersByGroupIdSuccess({ users, groupId, serverId }));
    }
    yield put(closeDialog(DialogType.REMOVE_USER_FROM_GROUP));
}

export function* groupsSaga() {
    yield takeEvery(FETCH_ALL_GROUPS_REQUEST, handleFetchGroupsRequest);
    yield takeEvery(FETCH_GROUP_BYID_REQUEST, handleFetchGroupByIdRequest);
    yield takeEvery(OPEN_EDITING_GROUPDATA_TAB, handleOpenGroupDataEditingTab);
    yield takeEvery(DELETE_GROUP, hadleDeleteGroupRequest);
    yield takeEvery(SUBMIT_GROUP_DATA, handleSubmitGroupData);
    yield takeEvery(ADD_USERS_TO_GROUP_REQUEST, handleAddUsersToGroupRequest);
    yield takeEvery(REMOVE_USERS_FROM_GROUP, handleRemoveUsersFromGroupRequest);
}

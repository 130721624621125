import React from 'react';
import { NavigatorPanelButton } from '../../NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { SeachIcon } from '../../NavigatorPanel/icons/SearchIcon.component';
import { useDispatch, useSelector } from 'react-redux';
import { chamgeNavigatorTreeSeachStatus } from '../../../../../actions/navigatorTreeSearch.actions';
import { NavigatorTreeSearchSelector } from '../../../../../selectors/navigatorTreeSearch.selectors';
import messages from '../../../messages/Navigator.messages';
import { useIntl } from 'react-intl';
import { Tooltip } from '../../../../UIKit/components/Tooltip/Tooltip.component';

export const TreeSearch = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const isActive: boolean = useSelector(NavigatorTreeSearchSelector.getIsSearchActive);

    const handleClick = () => {
        dispatch(chamgeNavigatorTreeSeachStatus(isActive ? false : true));
    };

    return (
        <Tooltip tooltip={{ title: intl.formatMessage(messages.search), position: 'top', offset: 5 }}>
            <>
                <NavigatorPanelButton
                    dataTest="navigator-panel_search_icon"
                    IconComponent={SeachIcon}
                    active={isActive}
                    onClick={handleClick}
                />
            </>
        </Tooltip>
    );
};

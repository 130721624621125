import { TReducer } from '../../utils/types';
import { TDashboardState } from './dashboard.reducer.types';
import {
    DASHBOARD_ADD_NEW_DATASET,
    DASHBOARD_ADD_WIDGET,
    DASHBOARD_CHANGE_CELL_SIZE,
    DASHBOARD_CHANGE_WIDGET_GEOMETRY,
    DASHBOARD_DELETE_DATASET,
    DASHBOARD_DELETE_WIDGET,
    DASHBOARD_INIT,
    DASHBOARD_SELECT_WIDGET,
    DASHBOARD_SET_CELLS_COUNT,
    DASHBOARD_SET_WIDGET_BG,
    DASHBOARD_SET_WIDGET_BORDER,
    DASHBOARD_SET_WIDGET_NAME,
    DASHBOARD_SET_WIDGET_SOURCE,
    DASHBOARD_SET_WIDGET_TEXT,
    SUMMARY_WIDGET_SET_OPERATION,
} from '../../actionsTypes/dashboard.actionTypes';
import { TDashboard } from '@/modules/Dashboard/Dashboard.types';
import { TNodeState } from './TNodeState';
import { TDashboardWidgets, TChartDataset, ISummaryWidget } from '@/modules/Dashboard/widgets/widget.types';

const INITIAL_STATE = new TNodeState<TDashboard>();

export const dashboardReducer: TReducer<TDashboardState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DASHBOARD_INIT: {
            const {
                payload: { nodeId, dashboard },
            } = action;

            return state.set(nodeId, { ...dashboard, selectedWidgetId: '' });
        }

        case DASHBOARD_ADD_WIDGET: {
            const {
                payload: { widget, nodeId },
            } = action;
            const { id } = widget;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets: TDashboardWidgets[] = existDashboard.widgets?.filter((widget) => widget.id !== id) || [];
            widgets.push(widget);

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case DASHBOARD_DELETE_WIDGET: {
            const {
                payload: { widgetId, nodeId },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets: TDashboardWidgets[] =
                existDashboard.widgets?.filter((widget) => widget.id !== widgetId) || [];

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case DASHBOARD_CHANGE_CELL_SIZE: {
            const {
                payload: { cellSize, nodeId },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            return state.set(nodeId, { ...existDashboard, cellSize });
        }

        case DASHBOARD_SET_CELLS_COUNT: {
            const {
                payload: { verticalCount, horizontalCount, nodeId },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            return state.set(nodeId, { ...existDashboard, verticalCount, horizontalCount });
        }

        case DASHBOARD_CHANGE_WIDGET_GEOMETRY: {
            const {
                payload: { nodeId, widgetId, geometry },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets = (existDashboard.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, geometry };
                }

                return widget;
            });

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case DASHBOARD_SELECT_WIDGET: {
            const {
                payload: { nodeId, widgetId },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            return state.set(nodeId, { ...existDashboard, selectedWidgetId: widgetId });
        }

        case DASHBOARD_SET_WIDGET_NAME: {
            const {
                payload: { nodeId, widgetId, name },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets = (existDashboard.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, name };
                }

                return widget;
            });

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case DASHBOARD_SET_WIDGET_BG: {
            const {
                payload: { nodeId, widgetId, bgColor },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets = (existDashboard.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, bgColor };
                }

                return widget;
            });

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case DASHBOARD_SET_WIDGET_BORDER: {
            const {
                payload: { nodeId, widgetId, border },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets = (existDashboard.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, border };
                }

                return widget;
            });

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case DASHBOARD_SET_WIDGET_TEXT: {
            const {
                payload: { nodeId, widgetId, text },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets = (existDashboard.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, text };
                }

                return widget;
            });

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case DASHBOARD_SET_WIDGET_SOURCE: {
            const {
                payload: { nodeId, widgetId, source },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets = (existDashboard.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, source };
                }

                return widget;
            });

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case SUMMARY_WIDGET_SET_OPERATION: {
            const {
                payload: { nodeId, widgetId, summaryOperation },
            } = action;
            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets: TDashboardWidgets[] = (existDashboard.widgets || []).map((widget) => {
                if (widget.id === widgetId) {
                    return { ...widget, summaryOperation } as ISummaryWidget;
                }

                return widget;
            });

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case DASHBOARD_ADD_NEW_DATASET: {
            const {
                payload: { nodeId, widgetId },
            } = action;
            const newDataset: TChartDataset = { col1: '', col2: '', legend: '' };

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets = (existDashboard.widgets || []).map((widget) => {
                if (widget.id === widgetId && 'source' in widget) {
                    const dataset: TChartDataset[] = widget.source?.dataset || [];
                    return { ...widget, source: { ...widget.source, dataset: [...dataset, newDataset] } };
                }

                return widget;
            });

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        case DASHBOARD_DELETE_DATASET: {
            const {
                payload: { nodeId, widgetId, index },
            } = action;

            const existDashboard: TDashboard | undefined = state.get(nodeId);

            if (!existDashboard) return state;

            const widgets = (existDashboard.widgets || []).map((widget) => {
                if (widget.id === widgetId && 'source' in widget) {
                    const dataset: TChartDataset[] = widget.source?.dataset || [];
                    if (dataset[index]) dataset.splice(index, 1);

                    return { ...widget, source: { ...widget.source, dataset } };
                }

                return widget;
            });

            return state.set(nodeId, { ...existDashboard, widgets });
        }

        default: {
            return state;
        }
    }
};

import React, { FC, useState } from 'react';
import theme from './UITemplatesTab.scss';
import messages from './UITemplatesTab.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { UITemplatesSelectors } from '../../selectors/UITemplates.selectors';
import { UITemplateDTO } from '@/serverapi/api';
import { SearchInput } from '@/modules/UIKit/components/Select/SearchInput.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { UITemplatesTable } from './UITemplatesTable/UITemplatesTable.component';
import { openUITemplateEditorTab } from '../../actions/UITemplates.actions';
import { UITemplatesPreview } from './UITemplatesPreview/UITemplatesPreview.component';

export const UITemplatesTab: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');

    const filteredUITemplates: UITemplateDTO[] = useSelector(UITemplatesSelectors.filteredUITemplates(searchValue));
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== searchValue) {
            setSearchValue(e.target.value.trim().toLowerCase());
        }
    };

    const createNewTemplate = () => {
        dispatch(openUITemplateEditorTab());
    };

    const selectedTemplate: UITemplateDTO | undefined = filteredUITemplates.find(
        (template) => template.id === selectedTemplateId,
    );

    return (
        <div className={theme.container}>
            <div className={theme.headerContainer}>
                <SearchInput
                    showSearch
                    originalTheme
                    allowClear
                    searchValue={searchValue}
                    onSearch={handleSearch}
                    onClear={() => setSearchValue('')}
                />
                <div className={theme.buttonContainer}>
                    <Button visualStyle="primary" onClick={createNewTemplate}>
                        {intl.formatMessage(messages.newTemplate)}
                    </Button>
                </div>
            </div>
            <div className={theme.bodyContainer}>
                <div className={theme.tableContainer}>
                    <UITemplatesTable
                        selectedTemplateId={selectedTemplateId}
                        UITemplates={filteredUITemplates}
                        setSelectedTemplateId={setSelectedTemplateId}
                    />
                </div>
                <div className={theme.previewContainer}>
                    <div className={theme.title}>{intl.formatMessage(messages.preview)}</div>
                    <div className={theme.preview}>
                        {!!selectedTemplate && <UITemplatesPreview template={selectedTemplate} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

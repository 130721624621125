import React, { FC } from 'react';
import icRibbonClose from '../../../resources/icons/closeIcon.svg';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { selectWidget, setWidgetName } from '@/actions/dashboard.actions';
import { useIntl } from 'react-intl';
import messages from './WidgetSettingsPanel.messages';
import theme from './WidgetSettingsPanel.scss';
import { widgetComponents } from '../widgets/widgetsMapper';
import { TDashboardWidgets } from '../widgets/widget.types';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { InternationalString } from '@/serverapi/api';

export const WidgetSettingsPanel: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [generalForm] = Form.useForm();
    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const currentWidget: TDashboardWidgets | undefined = useSelector(
        DashboardSelector.selectedWidget(activeTab?.nodeId),
    );

    const closePanelHandler = () => {
        if (activeTab) dispatch(selectWidget(activeTab.nodeId, ''));
    };

    const onChangeTemplateName = (name: InternationalString) => {
        if (activeTab && currentWidget) {
            dispatch(setWidgetName(activeTab.nodeId, currentWidget.id, name));
        }
    };

    const SourceInputComponent = currentWidget && widgetComponents[currentWidget.type].sourceInputComponent;

    return (
        <div className={theme.widgetSettingsPanelContainer} data-test="dashboard_widget-settings-panel">
            <Row className={theme.header}>
                <div className={theme.title}>{intl.formatMessage(messages.settings)}</div>
                <Icon className={theme.closeIcon} spriteSymbol={icRibbonClose} onClick={closePanelHandler} />
            </Row>
            <Col className={theme.body}>
                <Col className={theme.inputContainer}>
                    <Form autoComplete="off" form={generalForm} layout="vertical" className={theme.form}>
                        <MultiLangInputDialog
                            generalForm={generalForm}
                            mainInputName="widgetName"
                            multiLangValue={currentWidget?.name}
                            maxLength={150}
                            onChange={onChangeTemplateName}
                            label={intl.formatMessage(messages.name)}
                            data-test="dashboard_widget-settings-panel_input-title"
                        />
                    </Form>
                </Col>
                {SourceInputComponent && <SourceInputComponent />}
            </Col>
        </div>
    );
};

// @test-ignore_ru
import { AipToolbox } from './AipToolbox';
import {
    TFoundInConfig,
    TFoundTokenGroup,
    TAipConfigTokenId,
    TAipConfigTokenProperties
} from './types/AipConfig.types';


export class AipConfig {

    private readonly tokensInfo: TAipConfigTokenProperties[] = [
        {
            id: 'HELP',
            group: 'COMMANDS',
            synonyms: ['ПОМОГИ', 'СПАСИ', 'ПОМОЩЬ', 'СПРАВКА', '?']
        },
        {
            id: 'FIND',
            group: 'COMMANDS',
            synonyms: [
                "ИСКАТЬ", "ИЩИ",
                "НАЙТИ", "НАЙДИ",
                "ПОИСК"
            ]
        },
        {
            id: 'ELEMENT',
            group: 'ITEMS',
            synonyms: [ "ЭЛЕМЕНТ", "ЭЛЕМЕНТЫ", "ЭЛЕМЕНТОМ", "ЭЛЕМЕНТАМИ", "ЭЛЕМЕНТОВ" ]
        },
        {
            id: 'ALIAS',
            group: 'ITEMS',
            canBeSearchTarget: true,
            synonyms: [
                "АЛИАС", "АЛИАСЫ", "АЛИАСОМ", "АЛИАСАМИ", "АЛИАСОВ",
                "ПСЕВДОНИМ", "ПСЕВДОНИМЫ", "ПСЕВДОНИМОМ", "ПСЕВДОНИМАМИ", "ПСЕВДОНИМОВ"
            ]
        },
        {
            id: "DECOMPOSITION",
            group: 'ITEMS',
            synonyms: [ "ДЕКОМПОЗИЦИЯ", "ДЕКОМПОЗИЦИЮ", "ДЕКОМПОЗИЦИИ", "ДЕКОМПОЗИЦИЕЙ", "ДЕКОМПОЗИЦИЯМИ" ]
        },
        {
            id: 'EDGE',
            group: 'ITEMS',
            synonyms: [
                "РЕБРО", "РЁБРА", "РЕБРА", "РЁБЕР", "РЕБРОМ",
                "СВЯЗЬ", "СВЯЗИ", "СВЯЗЕЙ", "СВЯЗЬЮ", "СВЯЗЯМИ"
            ]
        },
        {
            id: 'FOLDER',
            group: 'ITEMS',
            canBeSearchTarget: true,
            synonyms: [
                "ДИРЕКТОРИЯ", "ДИРЕКТОРИЮ", "ДИРЕКТОРИИ", "ДИРЕКТОРИЙ", "ДИРЕКТОРИЕЙ", "ДИРЕКТОРИЯМИ",
                "ПАПКА", "ПАПКУ", "ПАПКИ", "ПАПОК", "ПАПКОЙ", "ПАПКАМИ",
                "КАТАЛОГ", "КАТАЛОГА", "КАТАЛОГОВ", "КАТАЛОГИ", "КАТАЛОГОМ", "КАТАЛОГАМИ"
            ]
        },
        {
            id: 'MODEL',
            group: 'ITEMS',
            canBeSearchTarget: true,
            synonyms: [
                "МОДЕЛЬ", "МОДЕЛИ", "МОДЕЛЕЙ", "МОДЕЛЬЮ", "МОДЕЛЯМИ",
                "ПРОЦЕСС", "ПРОЦЕССЫ", "ПРОЦЕССОВ", "ПРОЦЕССОМ", "ПРОЦЕССАМИ",
                "ДИАГРАММА", "ДИАГРАММУ", "ДИАГРАММЫ", "ДИАГРАММ", "ДИАГРАММОЙ", "ДИАГРАММАМИ"
            ]
        },
        {
            id: 'OBJECT_DEFINITION',
            group: 'ITEMS',
            canBeSearchTarget: true,
            synonyms: [
                "ОБЪЕКТ", "ОБЪЕКТЫ", "ОБЪЕКТОВ", "ОБЪЕКТОМ", "ОБЪЕКТАМИ",
                "ОПРЕДЕЛЕНИЕ", "ОПРЕДЕЛЕНИЯ", "ОПРЕДЕЛЕНИЙ", "ОПРЕДЕЛЕНИЕМ", "ОПРЕДЕЛЕНИЯМИ"
            ]
        },
        {
            id: 'OBJECT_INSTANCE',
            group: 'ITEMS',
            synonyms: [ "ЭКЗЕМПЛЯР", "ЭКЗЕМПЛЯРА", "ЭКЗЕМПЛЯРОВ", "ЭКЗЕМПЛЯРЫ", "ЭКЗЕМПЛЯРОМ", "ЭКЗЕМПЛЯРАМИ" ]
        },
        {
            id: 'ATTRIBUTE',
            group: 'ATTRIBUTES',
            synonyms: [ "АТРИБУТ", "АТРИБУТОМ", "АТРИБУТАМИ", "АТРИБУТА" ]
        },
        {
            id: 'TYPE',
            group: 'ATTRIBUTES',
            synonyms: [ "ТИП", "ТИПА", "ТИПОМ", "ТИПЫ", "ТИПОВ" ]
        },
        {
            id: 'NAME',
            group: 'ATTRIBUTES',
            synonyms: [ "ИМЯ", "ИМЕНИ", "ИМЕНЕМ", "ИМЕНА", "ИМЁН", "ИМЕН" ]
        },
        {
            id: 'ID',
            group: 'ATTRIBUTES',
            synonyms: [ "ИД", "ИДЕНТИФИКАТОРЫ", "ИДЕНТИФИКАТОРОВ", "ИДЕНТИФИКАТОРОМ" ]
        },
        {
            id: 'VALUE',
            group: 'ATTRIBUTES',
            synonyms: [ "ЗНАЧЕНИЕ", "ЗНАЧЕНИЯ", "ЗНАЧЕНИЙ", "ЗНАЧЕНИЕМ" ]
        },
        {
            id: 'EQUAL',
            group: 'ADJECTIVES',
            synonyms: [ "РАВНО", "РАВНЫМ" ]
        },
        {
            id: 'LINKED',
            group: 'ADJECTIVES',
            synonyms: [
                "СВЯЗАННЫЕ", "СВЯЗАННЫЙ", "СВЯЗАННАЯ", "СВЯЗАННОЕ", "СВЯЗАННУЮ", "СВЯЗАННОГО", "СВЯЗАННЫХ", "СВЯЗАН", "СВЯЗАНА", "СВЯЗАНО", "СВЯЗАНЫ",
                "СОЕДИНЁННЫЕ", "СОЕДИНЁННЫЙ", "СОЕДИНЁННАЯ", "СОЕДИНЁННОЕ", "СОЕДИНЁННУЮ", "СОЕДИНЁННОГО", "СОЕДИНЕННЫЕ", "СОЕДИНЕННЫЙ", "СОЕДИНЕННАЯ",
                    "СОЕДИНЕННОЕ", "СОЕДИНЕННУЮ", "СОЕДИНЕННОГО", "СОЕДИНЕН", "СОЕДИНЁН", "СОЕДИНЕНА", "СОЕДИНЕНО", "СОЕДИНЕНЫ"
            ]
        },
        {
            id: 'WITH',
            group: 'PREPOSITIONS',
            synonyms: [ "С", "СО" ]
        },
        {
            id: 'TO',
            group: 'PREPOSITIONS',
            synonyms: [ "НА" ]
        },
        {
            id: 'AND',
            group: 'CONJUNCTIONS',
            synonyms: [ "И" ]
        },
        {
            id: 'THAT',
            group: 'CONJUNCTIONS',
            synonyms: [ "КОТОРЫЙ", "КОТОРАЯ", "КОТОРОЕ", "КОТОРЫЕ" ]
        }
    ];


    findMultiwordItem(str: string): TFoundInConfig | undefined {
        const sourceWords: string[] = AipToolbox.getWordsFromString(str);

        const wordsAndMaybeTokens: string[] = sourceWords.map(word => {
            const tokenProperties: TAipConfigTokenProperties | undefined = this.findTokenPropertiesBySynonym(word);
            return tokenProperties ? tokenProperties.id : word;
        });

        const len: number = sourceWords.length;

        // если в группе меньше двух слов, значит это явно не то что нам нужно
        if (len < 2) return undefined;

        // это, возможно, группа токенов, нужно проверить первую пару слов в каждой из двух групп чтобы понять не группа ли это
        let result: TFoundInConfig | undefined;
        result = AipConfig.findTokenGroupHelper('WITH_SOMETHING', sourceWords, wordsAndMaybeTokens);
        if (result) return result;
        result = AipConfig.findTokenGroupHelper('LINKED_WITH', sourceWords, wordsAndMaybeTokens);
        if (result) return result;
        result = AipConfig.findTokenGroupHelper('AND_VALUE', sourceWords, wordsAndMaybeTokens);
        if (result) return result;
        return undefined;
    }


    findSinglewordItem(str: string): TFoundInConfig | undefined {
        const firstWord: string = AipToolbox.getWordsFromString(str)[0];
        if (!firstWord) return undefined;
        const tokenProperties: TAipConfigTokenProperties | undefined = this.findTokenPropertiesBySynonym(firstWord);
        return tokenProperties ? { aipConfigGroup: tokenProperties?.group, tokens: [ tokenProperties?.id ], source: firstWord } : undefined;
    }


    private findTokenPropertiesBySynonym(synonym: string): TAipConfigTokenProperties | undefined {
        synonym = synonym.toUpperCase();
        return this.tokensInfo.find((info: TAipConfigTokenProperties) => info.synonyms.includes(synonym));
    }


    private static findTokenGroupHelper(groupType: TFoundTokenGroup, sourceWords: string[], wordsAndMaybeTokens: string[]): TFoundInConfig | undefined {
        let group: TAipConfigTokenId[][];
        switch (groupType) {
            case 'WITH_SOMETHING':
                group = [
                    [ 'WITH', 'ATTRIBUTE' ],
                    [ 'WITH', 'TYPE' ],
                    [ 'WITH', 'NAME' ],
                    [ 'WITH', 'VALUE' ]
                ];
                break;
            case 'LINKED_WITH':
                group = [
                    [ 'LINKED', 'WITH' ],
                    [ 'LINKED', 'TO' ]
                ];
                break;
            case 'AND_VALUE':
                group = [
                    [ 'AND', 'VALUE' ]
                ];
                break;
            default: group = [];
        }

        const tokens = group.find(([token0, token1]: string[]) => token0 === wordsAndMaybeTokens[0] && token1 === wordsAndMaybeTokens[1]);
        if (!tokens) return undefined;
        return {
            aipConfigGroup: groupType,
            tokens: tokens.slice(),
            source: sourceWords.slice(0, tokens.length).join(' ')
        };
    }
};

export const aipConfig = new AipConfig();

import {
    MATRIX_SELECT_HEADER_CELLS,
    MATRIX_CLEAR_SELECTED_CELLS,
    MATRIX_SELECT_CELLS,
    MATRIX_PAST_OBJECTS,
    MATRIX_GET_EDGES_LIST,
    MATRIX_ADD_CELL_DATA,
    MATRIX_DELETE_EDGE_DEFINITION,
    MATRIX_START_LOADING_CELL_DATA,
    MATRIX_HANDLE_DECOMPOSITION_CLICK,
    MATRIX_DELETE_OBJECT_HEADERS,
    MATRIX_COPY_LANES,
    MATRIX_TOGGLE_HEADER_CELL,
    MATRIX_MOVE_TOGGLED_HEADER_CELLS,
    MATRIX_PAST_EMPTY_LANE,
    MATRIX_GET_IMAGE,
} from '@/modules/Matrix/actionTypes/matrixEditor.actionTypes';
import { ModelAssignment, Node, NodeId } from '@/serverapi/api';
import { HeaderType } from '../MatrixEditor/Matrix.types';
import { TMatrixCellData } from '../reducers/matrixEditor.reducer.types';

export type TMatrixSelectHeaderCellsAction = {
    type: typeof MATRIX_SELECT_HEADER_CELLS;
    payload: {
        matrixNodeId: NodeId;
        type: HeaderType;
        ids: string[];
    };
};

export type TMatrixSelectCellsAction = {
    type: typeof MATRIX_SELECT_CELLS;
    payload: {
        matrixNodeId: NodeId;
        ids: string[];
    };
};

export type TMatrixToggleHeaderCellAction = {
    type: typeof MATRIX_TOGGLE_HEADER_CELL;
    payload: {
        matrixNodeId: NodeId;
        id: string;
        type: HeaderType;
    };
};

export type TMatrixMoveToggledHeaderCellsAction = {
    type: typeof MATRIX_MOVE_TOGGLED_HEADER_CELLS;
    payload: {
        matrixNodeId: NodeId;
        ids: string[];
        targetType: HeaderType;
    };
};

export type TMatrixClearSelectedCellsAction = {
    type: typeof MATRIX_CLEAR_SELECTED_CELLS;
    payload: {
        matrixNodeId: NodeId;
    };
};

export type TMatrixPastObjectsAction = {
    type: typeof MATRIX_PAST_OBJECTS;
    payload: {
        matrixNodeId: NodeId;
    };
};

export type TMatrixPastEmptyLaneAction = {
    type: typeof MATRIX_PAST_EMPTY_LANE;
    payload: {
        matrixNodeId: NodeId;
        pastOnTheOtherSide?: boolean;
    };
};

export type TMatrixCopyLanesAction = {
    type: typeof MATRIX_COPY_LANES;
    payload: {
        matrixNodeId: NodeId;
    };
};

export type TMatrixGetEdgesListAction = {
    type: typeof MATRIX_GET_EDGES_LIST;
    payload: {
        matrixNodeId: NodeId;
        cellId: string;
    };
};

export type TMatrixStartLoadingCellDataAction = {
    type: typeof MATRIX_START_LOADING_CELL_DATA;
    payload: {
        matrixNodeId: NodeId;
    };
};

export type TMatrixAddCellDataAction = {
    type: typeof MATRIX_ADD_CELL_DATA;
    payload: {
        matrixNodeId: NodeId;
        cellId: string;
        cellData: TMatrixCellData;
    };
};

export type TMatrixDeleteEdgeDefinitionAction = {
    type: typeof MATRIX_DELETE_EDGE_DEFINITION;
    payload: {
        matrixNodeId: NodeId;
        deleteNodeId: NodeId;
        cellId: string;
    };
};

export type TMatrixDecompositionIconClickAction = {
    type: typeof MATRIX_HANDLE_DECOMPOSITION_CLICK;
    payload: {
        matrixNodeId: NodeId;
        edgeDefinitionId: string;
        modelAssignments: ModelAssignment[];
    };
};

export type TDeleteMatrixObjectHeaders = {
    type: typeof MATRIX_DELETE_OBJECT_HEADERS;
    payload: {
        nodes: Node[];
    };
};

export enum MatrixImageFormat {
    SVG,
    PNG,
    JPG,
    PDF,
    BUFFER,
}

export type TMatrixGetImage = {
    type: typeof MATRIX_GET_IMAGE;
    payload: {
        matrixNodeId: NodeId;
        format: MatrixImageFormat;
    };
};

export type TMatrixEditorAction =
    | TMatrixSelectHeaderCellsAction
    | TMatrixClearSelectedCellsAction
    | TMatrixSelectCellsAction
    | TMatrixPastObjectsAction
    | TMatrixGetEdgesListAction
    | TMatrixAddCellDataAction
    | TMatrixDeleteEdgeDefinitionAction
    | TMatrixStartLoadingCellDataAction
    | TMatrixDecompositionIconClickAction
    | TDeleteMatrixObjectHeaders
    | TMatrixToggleHeaderCellAction
    | TMatrixMoveToggledHeaderCellsAction
    | TMatrixPastEmptyLaneAction
    | TMatrixGetImage;

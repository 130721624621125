import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Icon } from '../../Icon/Icon.component';
import theme from './HiddenElementsDropdown.scss';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { DividerComponent } from '../../TipTapTextEditor/Toolbar/controls/Divider.component';
import { THiddenElementsDropdown } from '../ToolbarElements.types';
import messages from '@/modules/MainMenu/messages/CommonToolbar.messages';
import { useIntl } from 'react-intl';
import icDots from '@/resources/icons/MainMenuDots.svg';
import { calcDropdownWidth, calcNewRow, calcRightOffset } from './utils/dropdown.utils';
import { TOOLTIP_RC_ID } from '../../TooltipRC/TooltipRC.constants';

export const HiddenElementsDropdown = (props: THiddenElementsDropdown) => {
    const { disabled, hiddenGroups = [], containerWidth } = props;

    const intl = useIntl();

    const [isActive, setIsActive] = useState<boolean>(false);
    const [topY, setTopY] = useState<number>(0);
    const [dropdownWidth, setDropdownWidth] = useState<number>(0);
    const [dropdownNewRows, setDropdownNewRows] = useState<boolean[]>([]);
    const [rightOffset, setRightOffset] = useState<number>(0);

    const buttonRef: React.MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const portalRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    const tooltip: string = isActive
        ? intl.formatMessage(messages.hideButtons)
        : intl.formatMessage(messages.showButtons);

    useEffect(() => {
        if (!isActive || !buttonRef.current) return;
        const rightOffset: number = calcRightOffset(buttonRef.current);
        setRightOffset(rightOffset);
    }, [isActive]);

    useEffect(() => {
        document.addEventListener('scroll', windowWheelHandle, true);

        return () => {
            document.removeEventListener('scroll', windowWheelHandle);
        };
    }, []);

    useEffect(() => {
        if (!buttonRef.current || !isActive) {
            return;
        }
        const clientRects: DOMRect = buttonRef.current.getBoundingClientRect();
        setTopY(window.innerHeight - clientRects.bottom);
    }, [isActive]);

    useLayoutEffect(() => {
        if (!buttonRef.current || !isActive) {
            return;
        }
        recalculateDropdownOptions();
    }, [containerWidth, isActive]);

    useEffect(() => {
        if (!buttonRef.current || !isActive) {
            return;
        }
        setDropdownNewRows(calcNewRow(portalRef));
    }, [isActive, dropdownWidth]);

    const windowWheelHandle = (event: WheelEvent) => {
        setIsActive(false);
    };

    const handleClickHeader = () => {
        setIsActive(!isActive);
    };

    const recalculateDropdownOptions = () => {
        if (!portalRef.current?.children) {
            return;
        }
        const newDropdownWidth: number = calcDropdownWidth(portalRef.current, containerWidth, rightOffset);
        setDropdownWidth(newDropdownWidth);
    };

    return (
        <>
            <div
                className={classNames(theme.dropdownHeader, disabled ? theme.disabled : '', isActive && theme.active)}
                data-tooltip-id={TOOLTIP_RC_ID}
                data-tooltip-content={tooltip}
            >
                <button
                    ref={buttonRef}
                    type="button"
                    onClick={handleClickHeader}
                    className={classNames(theme.headerBtn, isActive && theme.active)}
                    disabled={disabled}
                    data-test="main-menu-show-hidden-Btns"
                >
                    <Icon spriteSymbol={icDots} disabled={disabled} />
                </button>
            </div>
            {isActive &&
                createPortal(
                    <div
                        ref={portalRef}
                        data-test={`${props['data-test']}_dropdown`}
                        className={theme.dropdownContainer}
                        style={{
                            top: `-${topY}px`,
                            maxWidth: dropdownWidth ? `${dropdownWidth + 16}px` : 'auto',
                            right: `${rightOffset}px`,
                        }}
                    >
                        {hiddenGroups?.map((group, index) => {
                            return (
                                <div
                                    className={classNames(
                                        theme.hideGroupContainer,
                                        dropdownNewRows[index] && theme.newRow,
                                    )}
                                >
                                    <div className={theme.dividerContainer}>
                                        <DividerComponent />
                                    </div>
                                    {group}
                                    <div className={theme.dividerContainer}>
                                        <DividerComponent />
                                    </div>
                                </div>
                            );
                        })}
                    </div>,
                    document.body,
                )}
        </>
    );
};

import {
    CLEAR_CONTAINMENT_ALLOWED_SYMBOL_IDS,
    SET_CONTAINMENT_ALLOWED_SYMBOL_IDS,
} from '@/actionsTypes/containmentAllowedSymbolIds.actionTypes';
import {
    TSetContainmentAllowedSymbolIdsAction,
    TClearContainmentAllowedSymbolIdsAction,
} from './containmentAllowedSymbolIds.actions.types';

export const setContainmentAllowedSymbolIds = (symbolIds: string[]): TSetContainmentAllowedSymbolIdsAction => ({
    type: SET_CONTAINMENT_ALLOWED_SYMBOL_IDS,
    payload: { symbolIds },
});

export const clearContainmentAllowedSymbolIds = (): TClearContainmentAllowedSymbolIdsAction => ({
    type: CLEAR_CONTAINMENT_ALLOWED_SYMBOL_IDS,
});


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InsertionDropdown__container___whH4,.InsertionDropdown__disabledContainer__z4maF{border-radius:4px}.InsertionDropdown__container___whH4:hover,.InsertionDropdown__disabledContainer__z4maF:hover{background-color:var(--ToolbarBtnBackgroundHover)}.InsertionDropdown__disabledContainer__z4maF{background:rgba(0,0,0,.04)}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/InsertionButton/InsertionDropdown.scss"],"names":[],"mappings":"AAAA,kFAEI,iBAAA,CACA,8FACI,iDAAA,CAIR,6CACI,0BAAA","sourcesContent":[".container,\r\n.disabledContainer {\r\n    border-radius: 4px;\r\n    &:hover {\r\n        background-color: var(--ToolbarBtnBackgroundHover);\r\n    }\r\n}\r\n\r\n.disabledContainer {\r\n    background: rgba(0, 0, 0, 0.04);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "InsertionDropdown__container___whH4",
	"disabledContainer": "InsertionDropdown__disabledContainer__z4maF"
};
export default ___CSS_LOADER_EXPORT___;

import { Form, Table } from 'antd';
import React, { FC } from 'react';
import { NodeDefinitionInstanceInfo, NodeId } from '../../../serverapi/api';
import { v4 as uuid } from 'uuid';
import theme from './ObjectPropertiesDialog.scss';
import messages from '../messages/ObjectPropertiesDialog.messages';
import { useIntl } from 'react-intl';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { sortByAlpha } from '@/utils/sortByAlpha';

type TConnectedObjectsProps = {
    parentObjectsInfo: NodeDefinitionInstanceInfo[];
    toNodeId: (id: string) => NodeId;
};

type TTableRow = {
    key: string;
    objectName: string;
    objectNodeId: NodeId;
    elementType: string;
};

export const ConnectedObjects: FC<TConnectedObjectsProps> = (props) => {
    const { parentObjectsInfo, toNodeId } = props;
    const intl = useIntl();

    const tableData: TTableRow[] = parentObjectsInfo?.length
        ? parentObjectsInfo
              .sort((a, b) => sortByAlpha(a.nodeName, b.nodeName) || sortByAlpha(a.elementType, b.elementType))
              .filter((parent) => parent.nodeType === 'OBJECT')
              .map((parent) => {
                  const { elementType, nodeName = '' } = parent;
                  const objectNodeId: NodeId = toNodeId(parent.nodeId);

                  return {
                      key: uuid(),
                      objectName: nodeName,
                      objectNodeId,
                      elementType: elementType || '',
                  };
              }, [])
        : [];

    const connectedObjectsColumns = [
        {
            title: intl.formatMessage(messages.objectName),
            dataIndex: 'objectName',
            width: '50%',
        },
        {
            title: intl.formatMessage(messages.objectType),
            dataIndex: 'elementType',
            width: '50%',
        },
    ];

    return (
        <FormGroup className={theme.formGroup}>
            <Form.Item>
                <Table
                    dataSource={tableData}
                    className={theme.table}
                    columns={connectedObjectsColumns}
                    size="middle"
                    bordered
                    pagination={false}
                    rowClassName={theme.attribute}
                    scroll={{
                        y: 'max-content',
                        x: 'max-content',
                    }}
                />
            </Form.Item>
        </FormGroup>
    );
};

import React from 'react';
import { TTabComponentProps } from '../Workspace/Workspace.types';
import { IWorkspaceTabImageFileViewerParams } from '@/models/tab.types';
import theme from './ImageFileViewer.component.scss';

export const ImageFileViewer = (ownProps: TTabComponentProps) => {
    const { src } = ownProps.tab.params as IWorkspaceTabImageFileViewerParams;

    return (
        <div className={theme.container}>
            <img
                className={theme.image}
                src={src}
            />
        </div>
    );
}

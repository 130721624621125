import { defineMessages } from 'react-intl';

export default defineMessages({
    selectMinThreeElementsOnCanvas: {
        id: 'EditorBtns.selectMinThreeElementsOnCanvas',
        defaultMessage: 'Выберите от трех символов на холсте',
    },
    Left: {
        id: 'EditorBtns.Left',
        defaultMessage: 'По левому краю',
    },
    CenterVert: {
        id: 'EditorBtns.CenterVert',
        defaultMessage: 'По центру',
    },
    Right: {
        id: 'EditorBtns.Right',
        defaultMessage: 'По правому краю',
    },
    Top: {
        id: 'EditorBtns.Top',
        defaultMessage: 'По верхнему краю',
    },
    CenterHorz: {
        id: 'EditorBtns.CenterHorz',
        defaultMessage: 'По середине',
    },
    Bottom: {
        id: 'EditorBtns.Bottom',
        defaultMessage: 'По нижнему краю',
    },
    DistributeHor: {
        id: 'EditorBtns.DistributeHor',
        defaultMessage: 'Распределить по горизонтали',
    },
    DistributeVert: {
        id: 'EditorBtns.DistributeVert',
        defaultMessage: 'Распределить по вертикали',
    },
});

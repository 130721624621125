export const ADD_STORE_EDIT_WIKI_MODEL_TYPE_WORKSPACE_TAB = 'ADD_STORE_EDIT_WIKI_MODEL_TYPE_WORKSPACE_TAB';

export const CHANGE_WIKI_MODEL_TYPE_NAME = 'CHANGE_WIKI_MODEL_TYPE_NAME';

export const CHANGE_WIKI_MODEL_TYPE_ID = 'CHANGE_WIKI_MODEL_TYPE_ID';

export const CHANGE_WIKI_MODEL_TYPE_DESCRIPTION = 'CHANGE_WIKI_MODEL_TYPE_DESCRIPTION';

export const CHANGE_WIKI_MODEL_TYPE_GROUP = 'CHANGE_WIKI_MODEL_TYPE_GROUP';

export const CHANGE_WIKI_MODEL_TYPE_ENABLE_MODEL = 'CHANGE_WIKI_MODEL_TYPE_ENABLE_MODEL';

export const WIKI_MODEL_TYPE_ADD_ATTRIBUTE_TYPES = 'WIKI_MODEL_TYPE_ADD_ATTRIBUTE_TYPES';

export const WIKI_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES = 'WIKI_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES';

export const WIKI_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS = 'WIKI_MODEL_TYPE_REMOVE_ATTRIBUTE_TYPES_GROUPS';

export const CHANGE_WIKI_MODEL_TYPE_ALLOW_APPROVALS = 'CHANGE_WIKI_MODEL_TYPE_ALLOW_APPROVALS';

import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { TFormatPanel } from '../reducers/formatPanel.reducer.types';
import { NodeId } from '../serverapi/api';
import { compareNodeIds } from '../utils/nodeId.utils';
import { NotationHelper } from '../services/utils/NotationHelper';
import { MxCell } from '../mxgraph/mxgraph';
import { filterSelectedCells, getFormatBtnTooltip } from '../modules/FormatPanel/utils/format.utils';
import { TSelectedCellElements } from '../services/bll/ToolbarButtonsBllService.types';
import { EditorToolbarSelectors } from './editorToolbar.selectors';
import { TabsSelectors } from './tabs.selectors';
import { TreeSelectors } from './tree.selectors';
import { LocalesService } from '../services/LocalesService';
import { getGeneralMenuEdgeTypeSelectorState } from './generalMenu.selectors';
import { TEdgeTypeSelectorState } from '../models/edgeTypeSelectorState.types';
import { SequenceEdgeTypesId } from '../mxgraph/SequenceGraph/SequenceConstants';
import messages from '../modules/FormatPanel/FormatPanel.messages';
import { EdgesForChangeSelectors } from './edgesForChangeType.selectors';
import { EditorMode } from '../models/editorMode';
import { EdgeInstanceImpl } from '../models/bpm/bpm-model-impl';

export namespace FormatPanelSelectors {
    const getState = (state: TRootState) => state.formatPanel;

    export const getOpenFormatModelIs = createSelector<TRootState, TFormatPanel, NodeId[]>(
        getState,
        (state) => state.modelsWithOpenFormatPanel,
    );

    export const isFormatPanelOpenByModelId = (modelId: NodeId) =>
        createSelector(
            getOpenFormatModelIs,
            (modelIds) => !!modelIds.find((openFormatModelId) => compareNodeIds(openFormatModelId, modelId)),
        );

    export const getFormatButtonsDisabled = (state: TRootState): boolean => {
        const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
        const presetId: string = TreeSelectors.presetById(activeTabId)(state);
        const isReadMode: boolean = TabsSelectors.checkIsReadModeInActiveTab(state);
        const { rawSelectedCells }: TSelectedCellElements = EditorToolbarSelectors.getSelectedCellsInActiveGraph(state);
        const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
        const isEntityEditable: boolean = NotationHelper.isEntityEditable(activeTabId, presetId, selectedCells);

        const disabled: boolean = !isEntityEditable || isReadMode;

        return disabled;
    };

    export const getFormatButtonsTooltip = (tooltip: string) =>
        createSelector<TRootState, boolean, boolean, string>(
            TabsSelectors.checkIsReadModeInActiveTab,
            getFormatButtonsDisabled,
            (isReadMode, disabled) => {
                return getFormatBtnTooltip(disabled, isReadMode, tooltip);
            },
        );

    export const getLineTypeDisabled = (state: TRootState): boolean => {
        const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
        const presetId: string = TreeSelectors.presetById(activeTabId)(state);
        const isReadMode: boolean = TabsSelectors.checkIsReadModeInActiveTab(state);
        const { rawSelectedCells }: TSelectedCellElements = EditorToolbarSelectors.getSelectedCellsInActiveGraph(state);
        const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
        const isEntityEditable: boolean = NotationHelper.isEntityEditable(activeTabId, presetId, selectedCells);
        const edgeTypeSelectorState: TEdgeTypeSelectorState = getGeneralMenuEdgeTypeSelectorState(state);
        const hasAvailableTypes: boolean = edgeTypeSelectorState.availableTypes.length >= 1;
        const someSelectedEdgesAreRecursiveMessage: boolean = edgeTypeSelectorState.availableTypes.some(
            (type) => type.id === SequenceEdgeTypesId.RECURSIVE_MESSAGE,
        );
        const edgeEditDisabled: boolean = !isEntityEditable;
        const disabled: boolean =
            edgeEditDisabled || someSelectedEdgesAreRecursiveMessage || !hasAvailableTypes || isReadMode;

        return disabled;
    };

    export const getLineTypeTooltip = (state: TRootState): string => {
        const intl = LocalesService.useIntl();
        const isReadMode: boolean = TabsSelectors.checkIsReadModeInActiveTab(state);
        const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
        const presetId: string = TreeSelectors.presetById(activeTabId)(state);
        const { rawSelectedCells }: TSelectedCellElements = EditorToolbarSelectors.getSelectedCellsInActiveGraph(state);
        const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
        const isEntityEditable: boolean = NotationHelper.isEntityEditable(activeTabId, presetId, selectedCells);
        const edgeEditDisabled: boolean = !isEntityEditable;

        if (isReadMode) return intl.formatMessage(messages.isReadMode);

        return edgeEditDisabled ? intl.formatMessage(messages.selectEdge) : '';
    };

    export const getEdgeArrowDisabled = (state: TRootState): boolean => {
        const isReadMode: boolean = TabsSelectors.checkIsReadModeInActiveTab(state);
        const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
        const presetId: string = TreeSelectors.presetById(activeTabId)(state);
        const { rawSelectedCells, selectedEdges }: TSelectedCellElements =
            EditorToolbarSelectors.getSelectedCellsInActiveGraph(state);
        const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
        const isEntityEditable: boolean = NotationHelper.isEntityEditable(activeTabId, presetId, selectedCells);

        const edgeEditDisabled: boolean = !isEntityEditable;
        const edgeTypeSelectorState: TEdgeTypeSelectorState = getGeneralMenuEdgeTypeSelectorState(state);
        const hasAvailableTypes: boolean = edgeTypeSelectorState.availableTypes.length >= 1;
        const selectedEdgeInstances: EdgeInstanceImpl[] = selectedEdges.map((edge) => edge.getValue());
        const tooltipMessage: string = EdgesForChangeSelectors.getTooltipMessage(
            selectedEdgeInstances,
            hasAvailableTypes,
            EditorMode.Edit,
        )(state);
        const isEdgesBlocked: boolean = !!tooltipMessage;
        const disabled: boolean = edgeEditDisabled || isEdgesBlocked || !hasAvailableTypes || isReadMode;

        return disabled;
    };

    export const getEdgeArrowTooltip = (isStartArrow: boolean) => {
        return (state: TRootState): string => {
            const intl = LocalesService.useIntl();
            const isReadMode: boolean = TabsSelectors.checkIsReadModeInActiveTab(state);
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const presetId: string = TreeSelectors.presetById(activeTabId)(state);
            const { rawSelectedCells, selectedEdges }: TSelectedCellElements =
                EditorToolbarSelectors.getSelectedCellsInActiveGraph(state);
            const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
            const isEntityEditable: boolean = NotationHelper.isEntityEditable(activeTabId, presetId, selectedCells);
            const edgeEditDisabled: boolean = !isEntityEditable;
            const edgeTypeSelectorState: TEdgeTypeSelectorState = getGeneralMenuEdgeTypeSelectorState(state);
            const hasAvailableTypes: boolean = edgeTypeSelectorState.availableTypes.length >= 1;
            const selectedEdgeInstances: EdgeInstanceImpl[] = selectedEdges.map((edge) => edge.getValue());
            const tooltipMessage: string = EdgesForChangeSelectors.getTooltipMessage(
                selectedEdgeInstances,
                hasAvailableTypes,
                EditorMode.Edit,
            )(state);
            const selectedEdgesLength: number = selectedEdges.length;

            const isEdgesBlocked: boolean = !!tooltipMessage;
            if (isReadMode) return intl.formatMessage(messages.isReadMode);
            const createTooltipMessage = (edgeDefinitionWarning?: string): string =>
                (edgeEditDisabled && intl.formatMessage(messages.selectEdge)) ||
                (isEdgesBlocked && edgeDefinitionWarning) ||
                '';

            const edgeTooltip: string =
                createTooltipMessage(
                    isStartArrow
                        ? intl.formatMessage(messages.edgeDefinitionStartArrowWarning)
                        : intl.formatMessage(messages.edgeDefinitionEndArrowWarning),
                ) || '';

            return selectedEdgesLength ? edgeTooltip : intl.formatMessage(messages.selectEdge);
        };
    };
}

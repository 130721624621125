import { WrappedComponentProps } from 'react-intl';
import { Symbol, ObjectType, InternationalString } from '@/serverapi/api';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { TreeNode } from '@/models/tree.types';
import { TPreset } from '@/models/preset.types';

export type TConstraintsPoint = {
    id: string;
    coordinatesX: string;
    coordinatesY: string;
    name: string;
};

type TSymbolEditorTabProps = {
    symbol: Symbol;
    preset: TPreset;
    serverNode: TreeNode;
    isNewSymbol: boolean;
    objectType: ObjectType;
    graphical?: string;
    currentLocale: Locale;
};

export type TSymbolEditorTabActionProps = {
    onCancel: () => void;
    submitSymbol: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
    openFileUploadDialog: (preset: TPreset, serverNode: TreeNode, symbol: Symbol) => void;
};

export type TSymbolEditorTabFullProps = TSymbolEditorTabProps & TSymbolEditorTabActionProps & WrappedComponentProps;

export enum previousActionEnum {
    NONE,
    ADD,
    DELETE,
}

export type TSymbolHeaderForm = {
    id: string;
    synonymsIds: string[] | undefined;
    symbolType: string;
    multilingualName: InternationalString | undefined;
    containmentBlocked: boolean | undefined;
};

import { useIntl } from 'react-intl';
import React from 'react';
import messages from '../../FormatPanel.messages';
import theme from './TextStyleAndDirection.scss';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { Bold } from '../TextStyles/Bold.component';
import { Italic } from '../TextStyles/Italic.component';
import { Underline } from '../TextStyles/Underline.component';
import { Horizontal } from '../DirectionBtns/Horizontal.component';
import { Vertical } from '../DirectionBtns/Vertical.component';

export const TextStyleAndDirectionBlock = () => {
    const intl = useIntl();

    return (
        <div className={theme.container}>
            <div className={theme.blockName}>{intl.formatMessage(messages.textStyleAndDirection)}</div>
            <div className={theme.textStyleAndDirection}>
                <div className={theme.textStyle}>
                    <ButtonGroup showBorder withoutDividerSpace>
                        <Bold />
                        <Italic />
                        <Underline />
                    </ButtonGroup>
                </div>
                <div className={theme.direction} data-test="object-toolbar_text-direction">
                    <ButtonGroup showBorder withoutDividerSpace>
                        <Horizontal />
                        <Vertical />
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    PickDefault: {
        id: 'MainMenu.PickDefault',
        defaultMessage: 'Выделить',
    },
    PickOutAll: {
        id: 'MainMenu.PickOutAll',
        defaultMessage: 'Все',
    },
    ReversePickOut: {
        id: 'MainMenu.ReversePickOut',
        defaultMessage: 'Обратное выделение',
    },
    PickOutByObjectType: {
        id: 'MainMenu.PickOutByObjectType',
        defaultMessage: 'Все этого типа объекта',
    },
    PickOutBySymbol: {
        id: 'MainMenu.PickOutBySymbol',
        defaultMessage: 'Все этого типа символа',
    },
});

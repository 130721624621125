import { InternationalString, ModelAssignment } from '@/serverapi/api';
import { ObjectDefinitionImpl } from '../../../models/bpm/bpm-model-impl';
import { TCurrentUserProfile } from '../../../reducers/userProfile.reducer.types';
import { MatrixLane } from '../../../serverapi/api';
import { TTreeEntityState } from '@/models/tree.types';

export type TResizeData = {
    start: number;
    size: number;
    id: string;
};

export type THeaderResizeData = {
    isResizing: boolean;
    direction?: HeaderResizeDirections;
};

export type TColResizeData = {
    id?: string;
    isResizing: boolean;
};

export type TRowResizeData = {
    id?: string;
    isResizing: boolean;
};

export type TMainHeaderLanes = {
    showTooltip: boolean;
    lanes: JSX.Element[];
    topPadding: number;
    sidePadding: number;
};

export type TCellSymbol = {
    symbol: string | JSX.Element;
    color: string;
    description: InternationalString | undefined;
};

export enum EdgeSymbolType {
    custom = 'CUSTOM',
    icon = 'ICON',
    userIcon = 'USER_ICON',
}

export enum IconType {
    plus = 'PLUS',
    minus = 'MINUS',
    check = 'CHECK',
    cross = 'CROSS',
}

export enum HeaderType {
    row = 'row',
    column = 'column',
}

export enum HeaderCellsContextActions {
    levelUp = 'levelUp',
    levelDown = 'levelDown',
    copy = 'copy',
    past = 'past',
}

export enum CellsContextActions {
    symbolsSettings = 'symbolsSettings',
    createDefinition = 'createDefinition',
    edgesList = 'edgesList',
}

export enum HeaderResizeDirections {
    horizontal = 'horizontal',
    vertical = 'vertical',
    both = 'both',
}

export type TEdgeIdWithType = {
    edgeId: string;
    edgeTypeName: string;
    edgeTypeId: string;
    isEdgeInctance: boolean;
    isOutgoingEdge: boolean;
    modelAssignments?: ModelAssignment[];
};

type TAclRightType = 'delete' | 'read' | 'update';

export type TAclRightsType =
    | [TAclRightType]
    | [TAclRightType, TAclRightType]
    | [TAclRightType, TAclRightType, TAclRightType];

export type TCheckForbiddenByProfile = {
    selectedHeaders: MatrixLane[];
    userProfile: TCurrentUserProfile | undefined;
    objectDefinitions: {
        [id: string]: ObjectDefinitionImpl | TTreeEntityState;
    };
    rights: TAclRightsType;
};

export type TObjectDefinitionsId = { rowObjectDefinitionId: string; colObjectDefinitionId: string };

import { createSelector } from 'reselect';
import { EdgeType, NodeId, ObjectModelConnections, ObjectType } from '../serverapi/api';
import { TRootState } from '../reducers/root.reducer.types';
import { TObjectConnectionsState } from '../reducers/objectConnections.reducer.types';
import { TObjectConnectionsData } from '../modules/ObjectPropertiesDialog/components/ObjectConnections/ObjectConnections.types';
import { TreeSelectors } from './tree.selectors';
import { EdgeTypeSelectors } from './edgeType.selectors';
import { ObjectTypeSelectors } from './objectType.selectors';
import { getObjectConnectionsData } from '../services/bll/ObjectConnectionsBLLService';

const objectConnectionsStateSelector = (state: TRootState) => state.objectConnections;

export namespace ObjectConnectionsSelectors {
    export const byNodeId = (nodeId: NodeId) =>
        createSelector<TRootState, TObjectConnectionsState, ObjectModelConnections[] | undefined>(
            objectConnectionsStateSelector,
            (state): ObjectModelConnections[] | undefined => {
                return state.byRepositoryId[nodeId.repositoryId]?.[nodeId.id];
            },
        );
    export const getObjectConnectionsDataByNodeId = (nodeId: NodeId) => {
        return (state: TRootState): TObjectConnectionsData[] => {
            const presetId: string = TreeSelectors.presetById(nodeId)(state);
            const edgeTypeList: EdgeType[] = EdgeTypeSelectors.listByPresetId(nodeId.serverId, presetId)(state);
            const connections: ObjectModelConnections[] | undefined =
                ObjectConnectionsSelectors.byNodeId(nodeId)(state);
            if (!connections) return [];
            const objTypes: ObjectType[] = ObjectTypeSelectors.byIds({
                presetId: presetId,
                objectTypeIds: connections
                    .flatMap((connection) => connection.connections?.map((edge) => edge.connectedObjectTypeId))
                    .filter((objectTypeId): objectTypeId is string => !!objectTypeId),
                serverId: nodeId.serverId,
            })(state).filter((type): type is ObjectType => !!type);

            const tableData: TObjectConnectionsData[] = getObjectConnectionsData(connections, edgeTypeList, objTypes);

            return tableData;
        };
    };
}

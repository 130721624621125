import React, { FC, memo, useMemo, useRef, useState } from 'react';
import theme from './Widget.scss';
import { useDispatch } from 'react-redux';
import { WidgetDTO, WidgetGeometry } from '@/serverapi/api';
import { fixWidgetGeometry, getCellContainerId } from '@/modules/UITemplates/utils/UITemplate.utils';
import { UITemplateEditorSelectWidget, UITemplateEditorUpdateWidget } from '@/modules/UITemplates/actions/UITemplateEditor.actions';
import { throttle } from 'lodash-es';
import { Resizers } from './Resizers.component';
import cx from 'classnames';
import { renderWidget } from '../../Widgets/widgetsMap';
import { isEqual } from 'lodash-es';
import { HORIZONTAL_CELLS_COUNT } from '@/modules/UITemplates/utils/consts';

type TWidgetProps = {
    templateId: string;
    widget: WidgetDTO;
    cellSize: number;
    selected: boolean;
    preview?: boolean;
};

export const Widget: FC<TWidgetProps> = memo(
    ({ preview, selected, templateId, cellSize, widget, widget: { id, geometry } }) => {
        const { height = 0, width = 0, x = 0, y = 0 } = geometry;
        const dispatch = useDispatch();

        const [hover, setHover] = useState<boolean>(false);
        const [resizing, setResizing] = useState<boolean>(false);
        const [isMoved, setIsMoved] = useState(false);
        const widgetRef = useRef<HTMLDivElement>(null);
        const animationFrameId = useRef<number>(0);

        const isValid: boolean = height > 0 && width > 0 && cellSize > 0;

        const updateWidget = throttle((widget: WidgetDTO) => {
            dispatch(UITemplateEditorUpdateWidget(templateId, widget));
        }, 50);

        const updateWidgetGeometry = (newGeometry: WidgetGeometry) => {
            if (!isEqual(newGeometry, geometry)) {
                const fixedGeometry: WidgetGeometry = fixWidgetGeometry(newGeometry, geometry);
                updateWidget({ ...widget, geometry: fixedGeometry });
            }
        };

        const dndHandler = (dndStartEvent: React.MouseEvent<HTMLElement | HTMLElement, MouseEvent>) => {
            const widgetContainer = widgetRef.current;
            if (!widgetContainer) return;

            let currentDroppable: Element | null = null;
            let droppableBelow: Element | null = null;
            let canBeDropped: boolean = false;

            const onWidgetMove = (moveEvent: MouseEvent) => {
                setIsMoved(true);
                const shiftX = Math.round((moveEvent.clientX - dndStartEvent.clientX) / cellSize);
                const shiftY = Math.round((moveEvent.clientY - dndStartEvent.clientY) / cellSize);

                if (
                    canBeDropped &&
                    droppableBelow &&
                    geometry.x !== undefined &&
                    geometry.y !== undefined &&
                    geometry.width !== undefined
                ) {
                    const newGeometry: WidgetGeometry = {
                        ...geometry,
                        x: Math.min(geometry.x + shiftX, HORIZONTAL_CELLS_COUNT - geometry.width),
                        y: geometry.y + shiftY,
                    };

                    updateWidgetGeometry(newGeometry);
                }

                widgetContainer.hidden = true;
                const elemBelow = document.elementFromPoint(moveEvent.clientX, moveEvent.clientY);
                widgetContainer.hidden = false;

                if (!elemBelow) {
                    return;
                }

                droppableBelow = elemBelow.closest(`#${getCellContainerId(templateId)}`);

                if (currentDroppable !== droppableBelow) {
                    if (currentDroppable) {
                        canBeDropped = false;
                    }
                    currentDroppable = droppableBelow;
                    if (currentDroppable) {
                        canBeDropped = true;
                    }
                }

                const container = document.getElementById(`${getCellContainerId(templateId)}`);
                const scrollThreshold = (geometry.height || 4) * cellSize;

                if (container) {
                    const containerRect = container.getBoundingClientRect();

                    if (moveEvent.clientY > containerRect.bottom - scrollThreshold) {
                        container.style.height = `${container.scrollHeight + scrollThreshold}px`;
                    }
                }
            };

            const onMouseMove = (mouseMoveEvent: MouseEvent) => {
                if (animationFrameId.current) {
                    cancelAnimationFrame(animationFrameId.current);
                }

                animationFrameId.current = requestAnimationFrame(() => onWidgetMove(mouseMoveEvent));
            };

            const mouseUpHandler = () => {
                document.removeEventListener('mousemove', onMouseMove);
                if (animationFrameId.current) {
                    cancelAnimationFrame(animationFrameId.current);
                }
            };

            if ((dndStartEvent.target as HTMLElement).id !== 'sizer') {
                document.addEventListener('mousemove', onMouseMove);
                document.addEventListener('mouseup', mouseUpHandler, { once: true });
            }
        };

        const resizeHandle = (resizerClickEvent: React.MouseEvent<HTMLDivElement, MouseEvent>, sizerId: number) => {
            setResizing(true);
            resizerClickEvent.stopPropagation();

            const onMouseMove = (resizerMoveEvent: MouseEvent) => {
                const shiftX: number = Math.round((resizerMoveEvent.clientX - resizerClickEvent.clientX) / cellSize);
                const shiftY: number = Math.round((resizerMoveEvent.clientY - resizerClickEvent.clientY) / cellSize);

                let newGeometry: WidgetGeometry = { ...geometry };

                switch (sizerId) {
                    case 1: {
                        newGeometry = {
                            x: x + shiftX,
                            y: y + shiftY,
                            width: width - shiftX,
                            height: height - shiftY,
                        };

                        break;
                    }
                    case 2:
                        newGeometry = {
                            x,
                            y: y + shiftY,
                            width: Math.min(width + shiftX, HORIZONTAL_CELLS_COUNT - x),
                            height: height - shiftY,
                        };

                        break;
                    case 3:
                        newGeometry = {
                            x,
                            y,
                            width: Math.min(width + shiftX, HORIZONTAL_CELLS_COUNT - x),
                            height: height + shiftY,
                        };
                        break;
                    case 4:
                        newGeometry = {
                            x: x + shiftX,
                            y,
                            width: width - shiftX,
                            height: height + shiftY,
                        };

                        break;
                    default:
                        break;
                }

                updateWidgetGeometry(newGeometry);
            };

            const mouseUpHandler = (e: MouseEvent) => {
                setResizing(false);
                document.removeEventListener('mousemove', onMouseMove);
            };

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', mouseUpHandler, { once: true });
        };

        const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();

            if (isMoved) {
                setIsMoved(false);
                return;
            }

            if (!selected) {
                dispatch(UITemplateEditorSelectWidget(templateId, id));
            }
        };

        const showSizers: boolean = !preview && (hover || selected || resizing);

        const widgetComponent: JSX.Element = useMemo(() => renderWidget(templateId, widget), [widget]);

        return (
            <div
                ref={widgetRef}
                className={cx(theme.widgetContainer, !preview && selected && theme.widgetHover)}
                style={{
                    width: width * cellSize - 0.5,
                    height: height * cellSize - 0.5,
                    left: x * cellSize + 0.5,
                    top: y * cellSize + 0.5,
                }}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                onMouseDown={dndHandler}
                onDragStart={() => false}
                onClickCapture={onClickHandler}
            >
                {isValid && (
                    <>
                        <div className={cx(theme.noPointerContainer, preview && theme.preview)}>{widgetComponent}</div>
                        {showSizers && <Resizers resizeHandle={resizeHandle} />}
                    </>
                )}
            </div>
        );
    },
);

import { TSearchActions } from '@/actions/search.actions.types';
import {
    RESET_SEARCH_DATA,
    SEARCH_SET_LOADING_STATUS,
    SET_ROOT_SEARCH_NODE_ID,
    SET_SEARCH_DATA,
    SET_SEARCH_NODE_TYPES,
    SET_SEARCH_RULES,
    SET_SEARCH_TEXT,
    SET_SEARCH_VISIBILITY,
} from '../actionsTypes/search.actionTypes';
import { TSearchReducerState, TSearchState, TSearcParams } from './search.reducer.types';
import { REPORT_CLEAR_SEARCH_REQUEST } from '@/modules/Report/actions/report.actionTypes';
import { TNodeState } from './entities/TNodeState';
import { addIfExist } from '@/utils/redux.utils';

const initial: TSearchReducerState = new TNodeState();

export const initSearchParams: TSearcParams = {
    rootSearchNodeIds: [],
    nodeTypes: [],
    searchRules: [],
    searchText: '',
    searchVisibility: 'NOT_DELETED',
};

export const SearchReducer = (state: TSearchReducerState = initial, action: TSearchActions) => {
    switch (action.type) {
        case SET_SEARCH_DATA: {
            const { searchNodeId, searchResult, searchParams, foundElementsCount, searchPaths } = action.payload;

            const existedState: TSearchState | undefined = state.get(searchNodeId);

            if (existedState) {
                return state.set(searchNodeId, {
                    ...existedState,
                    searchResult: searchResult || existedState.searchResult,
                    searchParams: searchParams || existedState.searchParams,
                    searchPaths: searchPaths || existedState.searchPaths,
                    foundElementsCount: addIfExist(foundElementsCount, existedState.foundElementsCount),
                });
            }

            return state.set(searchNodeId, {
                searchParams,
                foundElementsCount,
                searchResult: searchResult || [],
                searchPaths: searchPaths || [],
                isLoading: false,
            });
        }

        case SEARCH_SET_LOADING_STATUS: {
            const { searchNodeId, isLoading } = action.payload;

            const existedState: TSearchState | undefined = state.get(searchNodeId);

            if (existedState) {
                return state.set(searchNodeId, {
                    ...existedState,
                    isLoading,
                });
            }

            return state;
        }

        case RESET_SEARCH_DATA: {
            const { searchNodeId: nodeId } = action.payload;

            return state.delete(nodeId);
        }

        case SET_SEARCH_RULES: {
            const { nodeId, searchRules } = action.payload;

            const existedState: TSearchState | undefined = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    searchParams: {
                        ...(existedState.searchParams || initSearchParams),
                        searchRules,
                    },
                });
            }

            return state;
        }

        case SET_SEARCH_TEXT: {
            const { nodeId, searchText } = action.payload;

            const existedState: TSearchState | undefined = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    searchParams: {
                        ...(existedState.searchParams || initSearchParams),
                        searchText,
                    },
                });
            }

            return state;
        }

        case SET_SEARCH_NODE_TYPES: {
            const { nodeId, searchNodeTypes } = action.payload;

            const existedState: TSearchState | undefined = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    searchParams: {
                        ...(existedState.searchParams || initSearchParams),
                        nodeTypes: searchNodeTypes,
                    },
                });
            }

            return state;
        }

        case SET_SEARCH_VISIBILITY: {
            const { nodeId, visibility } = action.payload;

            const existedState: TSearchState | undefined = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    searchParams: {
                        ...(existedState.searchParams || initSearchParams),
                        searchVisibility: visibility,
                    },
                });
            }

            return state;
        }

        case SET_ROOT_SEARCH_NODE_ID: {
            const { searchNodeId: nodeId, rootSearchNodeIds: rootSearchNodeId } = action.payload;

            const existedState: TSearchState | undefined = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    searchParams: {
                        ...(existedState.searchParams || initSearchParams),
                        rootSearchNodeIds: rootSearchNodeId,
                    },
                });
            }

            return state;
        }

        case REPORT_CLEAR_SEARCH_REQUEST: {
            const { reportNodeId } = action.payload;

            const existedState: TSearchState | undefined = state.get(reportNodeId);

            if (existedState) {
                return state.set(reportNodeId, {
                    ...existedState,
                    searchResult: [],
                });
            }

            return state;
        }

        default: {
            return state;
        }
    }
};

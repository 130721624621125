import React from 'react';
import { TableHeaderProps } from 'react-virtualized';
import { SortIndicator } from '../../../AdminTools/Methodology/components/MethodologySettingPage/SortIndicator.component';
import theme from './TableUIKitComponent.scss';

type TColumnHeaderProps = {
    data: TableHeaderProps;
    label: string | JSX.Element;
    withoutSorter?: boolean;
};

export const ColumnHeader = (props: TColumnHeaderProps) => {
    const { data, label, withoutSorter } = props;
    const { sortDirection, sortBy, dataKey } = data;

    return (
        <div className={`${theme.sortableColumn} ${theme.headerCell}`}>
            <span>{label}</span>
            {!withoutSorter ? <SortIndicator sortDirection={sortDirection} isActive={dataKey === sortBy} /> : null}
        </div>
    );
};

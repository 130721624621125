import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { TSearchResultsWidgetState } from '../reducers/searchResultsWidget.reducer.types';
import { TSearchDataListItem } from '@/reducers/search.reducer.types';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { NodeId } from '@/serverapi/api';
import { SearchElementTypeSelectors } from '@/modules/Search/selectors/searchElementType.selectors';

const searchResultsWidgetStateSelector = (state: TRootState): TSearchResultsWidgetState => state.searchResultsWidget;

export namespace SearchResultsWidgetSelectors {
    export const searchData = (templateId: string, widgetId: string) =>
        createSelector<TRootState, TSearchResultsWidgetState, TSearchDataListItem[]>(
            searchResultsWidgetStateSelector,
            (state) => state?.[templateId]?.[widgetId]?.searchData || [],
        );

    export const filtredSearchData = (templateId: string, widgetId: string, nodeId: NodeId) =>
        createSelector<TRootState, TSearchDataListItem[], string | undefined, TSearchDataListItem[]>(
            searchData(templateId, widgetId),
            TreeSelectors.presetById(nodeId),
            (searchData, presetId: string) =>
                SearchElementTypeSelectors.addItemTypeNameToData(searchData, nodeId, presetId),
        );

    export const loading = (templateId: string, widgetId: string) =>
        createSelector<TRootState, TSearchResultsWidgetState, boolean>(
            searchResultsWidgetStateSelector,
            (state) => !!state?.[templateId]?.[widgetId]?.loading,
        );
}

import type {
    TCreateAttributeTypeGroupAction,
    TEditAttributeTypeGroupAction,
    TSubmitAttributeTypeGroupAction,
} from '../actions/attributeTypeGroup.actions.types';
import type {
    TCreateEdgeTypeGroupAction,
    TEditEdgeTypeGroupAction,
    TSubmitEdgeTypeGroupAction,
} from '../actions/edgeTypeGroup.actions.types';
import type {
    TCopyPresetAction,
    TCreateFolderTypeAction,
    TCreateModelTypeGroupAction,
    TDeletePresetAction,
    TEditFolderTypeAction,
    TEditPresetRequestAction,
    TExportExcelPresetAction,
    TExportPresetAction,
    TImportPresetRequestAction,
    TMakeDefaultAction,
    TOpenMethodologyTabAction,
    TOpenSymbolEditorTabAction,
    TSubmitModelTypeGroupAction,
    TSubmitPresetAction,
    TSubmitPresetCopyAction,
    TUpdateModelTypeFloatingAttributesStylesAction,
} from '../actions/methodologySetting.actions.types';
import type { TDeleteModelTypeGroupRequestAction, TEditModelTypeGroupAction } from '../actions/modelType.actions.types';
import type {
    TCreateSymbolRequestAction,
    TEditModelTypeEdgeAttributeTypeStyleAction,
    TEditModelTypeSymbolAttributeTypeStyleAction,
    TSubmitSymbolRequestAction,
    TUploadSymbolSVGRequestAction,
} from '../actions/symbol/symbolEditor.actions.types';
import type { TWorkspaceTabsRemoveAction } from '../actions/tabs.actions.types';
import type { TServerEntity } from '../models/entities.types';
import type { TPreset } from '../models/preset.types';
import type {
    IWorkspaceTabItemFolderTypeEditorParams,
    IWorkspaceTabItemMethodologyParams,
    IWorkspaceTabItemOpenSymbolEditorParams,
    IWorkspaceTabItemPresetCopyParams,
    IWorkspaceTabItemPresetParams,
    TWorkspaceTab,
} from '../models/tab.types';
import type { TreeNode } from '../models/tree.types';
import type { TExecutingProcess } from '../reducers/statusBar.reducer.types';
import type {
    AttributeType,
    AttributeTypeGroup,
    EdgeType,
    EdgeTypeAttributeTypeStyleDTO,
    EdgeTypeGroup,
    ExportPresetResponse,
    FolderType,
    ImportPresetRequest,
    ImportPresetRequestImportPresetResolutionEnum,
    ImportPresetResponse,
    ModelType,
    ModelTypeGroup,
    NodeId,
    ObjectType,
    ObjectTypeGroup,
    PresetDTO,
    PresetImage,
    PresetImageGroup,
    RepositoryNode,
    Symbol,
    SymbolAttributeTypeStyleDTO,
} from '../serverapi/api';
import type {
    TEdgeTypeFloatingAttributesDialogProperties,
    TSymbolFloatingAttributesDialogProperties,
} from '../modules/FloatingAttributes/FloatingAttributes.types';
import type { TPresetRequestDataObjectType } from '../services/dao/MethodologySettingDaoService.types';
import type { TPresetId } from '../modules/AdminTools/Methodology/components/Presets/TPresetId.types';
import { notification } from 'antd';
import { all, call, put, select, takeEvery, CallEffect } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash-es';
import { attributeTypesRequestSuccess } from '../actions/attributeType.actions';
import { addAttributeTypeGroups, submitAttributeTypeGroup } from '../actions/attributeTypeGroup.actions';
import {
    CREATE_ATTRIBUTE_TYPE_GROUP,
    EDIT_ATTRIBUTE_TYPE_GROUP,
    SUBMIT_ATTRIBUTE_TYPE_GROUP,
} from '../actionsTypes/attributeTypeGroup.actionTypes';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { edgeTypeRequestSuccess } from '../actions/edgeType.actions';
import {
    CREATE_EDGE_TYPE_GROUP,
    EDIT_EDGE_TYPE_GROUP,
    SUBMIT_EDGE_TYPE_GROUP,
} from '../actionsTypes/edgeTypeGroup.actionTypes';
import { addEdgeTypeGroups, submitEdgeTypeGroup } from '../actions/edgeTypeGroup.actions';
import { importPresetDialogRequestDone } from '../actions/importDialog.actions';
import {
    COPY_PRESET,
    CREATE_FOLDER_TYPE,
    CREATE_MODEL_TYPE_GROUP,
    DELETE_PRESET,
    EDIT_FOLDER_TYPE,
    EDIT_PRESET_REQUEST,
    EXPORT_EXCEL_PRESET,
    EXPORT_PRESET,
    GET_PRESET_LIST,
    IMPORT_PRESET_REQUEST,
    MAKE_DEFAULT,
    OPEN_ADD_PRESET,
    OPEN_METHODOLOGY_TAB,
    OPEN_SYMBOL_EDITOR_TAB,
    OVERLAY_UPDATE_STYLE,
    SUBMIT_MODEL_TYPE_GROUP,
    SUBMIT_PRESET,
    SUBMIT_PRESET_COPY,
} from '../actionsTypes/methodologySetting.actionTypes';
import {
    addModelTypeGroups,
    deletePresetSuccess,
    editPresetRequestSuccess,
    loadPresetsSuccess,
    submitPresetSuccess,
    presetCopyLoadingFinish,
    submitModelTypeGroup,
    changeDefaultPreset,
} from '../actions/methodologySetting.actions';
import { DELETE_MODEL_TYPE_GROUP_REQUEST, EDIT_MODEL_TYPE_GROUP } from '../actionsTypes/modelType.actionTypes';
import { deleteModelTypeGroupRequest, deleteModelTypeGroupSuccess } from '../actions/modelType.actions';
import { presetMetaDataRequest } from '../actions/notation.actions';
import { showNotification, showNotificationByType } from '../actions/notification.actions';
import { objectTypeRequestSuccess } from '../actions/objectType.actions';
import { addObjectTypeGroups } from '../actions/objectTypeGroup.actions';
import { setProcessIndicator } from '../actions/statusBar.actions';
import { symbolsRequestSuccess } from '../actions/symbol.actions';
import { initSymbolEditor, symbolSVGConvertRequestSuccess } from '../actions/symbol/symbolEditor.actions';
import {
    CREATE_SYMBOL_REQUEST,
    EDIT_MODEL_TYPE_EDGE_ATTRIBUTE_TYPE_STYLE,
    EDIT_MODEL_TYPE_SYMBOL_ATTRIBUTE_TYPE_STYLE,
    SUBMIT_SYMBOL_REQUEST,
    UPLOAD_SYMBOL_SVG_REQUEST,
} from '../actionsTypes/symbol/symbolEditor.actionTypes';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../actionsTypes/tabs.actionTypes';
import { workspaceAddTab, workspaceRemoveTab, workspaceRemoveTabByNodeId } from '../actions/tabs.actions';
import { treeNodeTypeRequestSuccess } from '../actions/treeNodeType.actions';
import { EditorMode } from '../models/editorMode';
import { MEDIUM_NOTIFICATION_DURATION, NotificationType } from '../models/notificationType';
import { defaultWorkspaceTabActions } from '../models/tab';
import messages from '../modules/AdminTools/Methodology/messages/MethodologySetting.messages';
import AppNotificationsMessages from '../modules/App/messages/AppNotifications.messages';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import ProcessIndicatorMessages from '../modules/StatusBar/components/ProcessIndicator/ProcessIndicator.messages';
import { TreeItemType } from '../modules/Tree/models/tree';
import { METHODOLOGY_SETTINGS_TYPES_ARR } from '../modules/Workspace/workspaceTab.constants';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { ProcessType } from '../reducers/statusBar.reducer.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { ModelTypeSelectors } from '../selectors/modelType.selectors';
import { ObjectTypeSelectors } from '../selectors/objectType.selectors';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { TreeSelectors } from '../selectors/tree.selectors';
import { DefaultId, PresetElementTransferType } from '../serverapi/api';
import { LocalesService } from '../services/LocalesService';
import { TMP_DOWNLOAD_PATH } from '../services/api/api-bundle';
import {
    getAllModelTypes,
    getAllModelTypesSuccess,
    submitModelType,
} from '../actions/presetSettings/presetSettingsModelType.actions';
import { saveKanbanModelTypeChanges, saveModelTypeChanges } from './presetSettings/presetSettingsModelTypes.saga';
import { ModelTypeGroupSelectors } from '../selectors/modelTypeGroup.selectors';
import { MethodologySettingDaoService } from '../services/dao/MethodologySettingDaoService';
import { serverProfileSetState } from '../actions/serverProfile.actions';
import { getPresetProfileSuccess } from '../actions/presetProfile.actions';
import { RepositoryDAOService } from '../services/dao/RepositoryDAOService';
import { TTreeNodeTypeRequestAction } from '../actions/treeNodeType.actions.types';
import { TREE_NODE_TYPE_REQUEST } from '../actionsTypes/treeNodeType.actionTypes';
import { FileDaoService } from '../services/dao/FileDaoService';
import { successRequestFolderType } from '../actions/folderType.actions';
import { FolderTypeModelsConverter } from '../utils/folderType.utils';
import { folderTypeEditorInit } from '../actions/folderTypeEditor.actions';
import { ServerErrorType } from '../models/serverErrorType';
import { addPresetImageGroups } from '../actions/presetSettings/presetImageGroup.actions';
import { presetImageRequestSuccess } from '../actions/presetSettings/presetImage.actions';
import { PresetImageSelectors } from '../selectors/presetSettings/presetImage.selectors';
import { getAllKanbanModelTypesSuccess } from '../actions/presetSettings/presetSettingsKanbanModelType.actions';
import { KanbanDaoService } from '../services/dao/KanbanDaoService';
import { getAllKanbanCardTypes } from '../actions/presetSettings/presetSettingsKanbanCardType.actions';
import { PresetSettingsKanbanModelTypeSelectors } from '../selectors/presetSettings/presetSettingsKanbanModelType.selectors';
import { BrowserDaoService } from '../services/dao/BrowserDaoService';
import { principalRequest } from '../actions/principal.actions';
import { batchActions } from '@/actions/rootReducer.actions';
import { getAllMatrixModelTypesSuccess } from '@/actions/presetSettings/presetSettingsMatrixModelType.actions';
import { saveMatrixModelTypeChanges } from './presetSettings/presetSettingsMatrixModelType.saga';
import { PresetSelectors } from '@/selectors/preset.selectors';
import { getAllReportModelTypesSuccess } from '@/actions/presetSettings/presetSettingsReportModelType.actions';
import { saveReportModelTypeChanges } from './presetSettings/presetSettingsReportModelType.saga';
import { isSvgImage } from '@/utils/image.utils';
import { getAllWikiModelTypesSuccess } from '../actions/presetSettings/presetSettingsWikiModelType.actions';
import { saveWikiModelTypeChanges } from './presetSettings/presetSettingsWikiModelType.saga';

function* loadMethodologiesList() {
    const serverId = yield select(ServerSelectors.serverId);
    const repositories: Array<RepositoryNode> = yield RepositoryDAOService.getRepositories(serverId);
    const presetDTOs: Array<PresetDTO> = yield MethodologySettingDaoService.getPresetList(serverId);
    const presets: Array<TPreset> = presetDTOs.map((preset) => {
        return {
            ...preset,
            repositories: repositories.filter((r) => r.presetId === preset.id),
        };
    });
    yield put(
        loadPresetsSuccess({
            serverNode: {
                nodeId: { serverId } as NodeId,
            } as TreeNode,
            presets,
        }),
    );
}

function* openMethodologySetting({ payload }: TOpenMethodologyTabAction) {
    const { serverNode } = payload;
    if (serverNode) {
        yield* loadMethodologiesList();
        const methodologySettingTab: TWorkspaceTab = {
            title: serverNode.name,
            nodeId: serverNode.nodeId,

            type: WorkSpaceTabTypes.METHODOLOGY_SETTINGS,
            mode: EditorMode.Read,
            params: {
                serverNode,
            } as IWorkspaceTabItemMethodologyParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        yield put(workspaceAddTab(methodologySettingTab));
    }
}

function* handleAddPreset({ payload }: TOpenMethodologyTabAction) {
    const { serverNode } = payload;
    if (serverNode) {
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));
        const currentLocale = LocalesService.getLocale();
        const preset: TPreset = {
            name: intl.formatMessage(messages.newPreset),
            multilingualName: { [currentLocale]: intl.formatMessage(messages.newPreset) },
            id: uuid(),
            repositories: [],
            updatedAt: new Date(),
        };
        const contentLoadingPageTab: TWorkspaceTab & TPresetId = {
            title: intl.formatMessage(messages.newPreset),
            nodeId: {
                ...serverNode.nodeId,
                id: preset.id,
            },
            type: WorkSpaceTabTypes.ADD_PRESET_TAB,
            mode: EditorMode.Read,
            presetId: preset.id,
            params: {
                serverNode,
                editablePreset: preset,
            } as IWorkspaceTabItemPresetParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        yield put(workspaceAddTab(contentLoadingPageTab));
    }
}

function* handleEditPresetRequest({ payload }: TEditPresetRequestAction) {
    const { serverNode, preset } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const tabs: TWorkspaceTab[] = yield select(TabsSelectors.getTabList);

    for (const tabItem of tabs) {
        if (tabItem.content?.type === TreeItemType.Model || tabItem.content?.type === TreeItemType.Matrix) {
            if (instancesBPMMxGraphMap.get(tabItem.nodeId)?.mode === EditorMode.Edit) {
                const presetId: string = yield select(TreeSelectors.presetById(tabItem.nodeId));

                if (presetId === preset.id && tabItem.nodeId.serverId === serverId) {
                    notification.warning({
                        message: intl.formatMessage(AppNotificationsMessages.concurrentEditOfNotationAndModelWarn),
                        description: intl.formatMessage(
                            AppNotificationsMessages.concurrentEditOfNotationWarnDescription,
                        ),
                        duration: MEDIUM_NOTIFICATION_DURATION,
                    });

                    yield put(editPresetRequestSuccess());

                    return;
                }
            }
        }
    }

    if (serverNode) {
        const presetRequestDataObject: TPresetRequestDataObjectType =
            yield MethodologySettingDaoService.getEditPresetRequestData(serverId, preset.id);
        const {
            edgeTypes,
            modelTypeGroups,
            objectTypeGroups,
            edgeTypeGroups,
            attributeTypeGroups,
            modelTypes,
            serverProfiles,
            presetProfiles,
            objectTypes,
            symbols,
            attributeTypes,
            treeNodeTypes,
            folderTypes,
            presetImages,
            presetImageGroups,
            kanbanModelTypes,
            kanbanCardTypes,
            matrixModelTypes,
            reportModelTypes,
            wikiModelTypes,
        } = presetRequestDataObject;

        const convertedFolderTypes: FolderType[] = folderTypes.map((folderType) => {
            const { validNodeTypes, validModelTypes } = folderType;
            const { nodeTypesWithoutTransmittable, modelTypesWithTransmittable } =
                FolderTypeModelsConverter.convertFromDto(validNodeTypes || [], validModelTypes || []);

            return {
                ...folderType,
                validNodeTypes: nodeTypesWithoutTransmittable,
                validModelTypes: modelTypesWithTransmittable,
            };
        });

        if (presetProfiles) {
            yield put(getPresetProfileSuccess({ presetProfiles, presetId: preset.id, serverId }));
        }

        const requestDataActions = [
            serverProfileSetState(serverProfiles, serverId),
            objectTypeRequestSuccess({ serverId, presetId: preset.id, objectTypes }),
            presetImageRequestSuccess({ serverId, presetId: preset.id, presetImages }),
            symbolsRequestSuccess(serverId, symbols, preset.id),
            edgeTypeRequestSuccess(serverId, edgeTypes, preset.id),
            successRequestFolderType({ folderTypes: convertedFolderTypes, serverId, presetId: preset.id }),
            addModelTypeGroups({ modelTypeGroups, serverNode, preset }),

            addObjectTypeGroups({
                objectTypeGroups,
                serverNode,
                preset,
            }),
            addPresetImageGroups({
                presetImageGroups,
                serverNode,
                preset,
            }),
            addEdgeTypeGroups({
                edgeTypeGroups,
                serverNode,
                preset,
            }),
            addAttributeTypeGroups({
                attributeTypeGroups,
                serverNode,
                preset,
            }),
            attributeTypesRequestSuccess(serverId, preset.id, attributeTypes),
            getAllModelTypesSuccess({ modelTypes, presetId: preset.id, serverId }),
            getAllKanbanModelTypesSuccess({ kanbanModelTypes, presetId: preset.id }),
            getAllKanbanCardTypes({ kanbanCardTypes, presetId: preset.id }),
            getAllMatrixModelTypesSuccess({ matrixModelTypes, presetId: preset.id }),
            getAllReportModelTypesSuccess({ reportModelTypes, presetId: preset.id }),
            getAllWikiModelTypesSuccess({ wikiModelTypes, presetId: preset.id }),
            treeNodeTypeRequestSuccess(serverId, treeNodeTypes, preset.id),
            principalRequest(serverId),
        ];

        yield put(batchActions(requestDataActions));

        const availableTreeNodeTypes: TreeItemType[] =
            preset.id === DefaultId.DEFAULT_PRESET_ID
                ? [
                      TreeItemType.FileFolder,
                      TreeItemType.Folder,
                      TreeItemType.Repository,
                      TreeItemType.ScriptFolder,
                      TreeItemType.Script,
                  ]
                : [TreeItemType.Folder, TreeItemType.File, TreeItemType.SimulationModeling, TreeItemType.Repository];

        const contentLoadingPageTab: TWorkspaceTab & TPresetId = {
            title: LocalesService.internationalStringToString(preset.multilingualName),
            nodeId: {
                ...serverNode.nodeId,
                id: preset.id,
            },
            type: WorkSpaceTabTypes.ADD_PRESET_TAB,
            mode: EditorMode.Read,
            presetId: preset.id,
            params: {
                serverNode,
                editablePreset: preset,
                modelTypeGroups,
                availableTreeNodeTypes,
            } as IWorkspaceTabItemPresetParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        yield put(editPresetRequestSuccess());
        yield put(workspaceAddTab(contentLoadingPageTab));
    }
}

function* handleCopyPreset({ payload }: TCopyPresetAction) {
    const { serverNode, preset } = payload;
    if (serverNode) {
        const objectTypes: ObjectType[] = yield MethodologySettingDaoService.getObjectTypes(
            serverNode.nodeId.serverId,
            preset.id,
        );
        const { serverId } = serverNode.nodeId;
        const symbols: Symbol[] = yield MethodologySettingDaoService.getSymbolsByPresetId(serverId, preset.id);
        yield put(presetMetaDataRequest([preset.id]));
        yield put(objectTypeRequestSuccess({ serverId, presetId: preset.id, objectTypes }));
        yield put(submitPresetSuccess({ serverNode, preset }));
        yield put(symbolsRequestSuccess(serverNode.nodeId.serverId, symbols, preset.id));
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));

        const contentLoadingPageTab: TWorkspaceTab = {
            title: intl.formatMessage(messages.cpPreset, { name: preset.name }),
            nodeId: {
                ...serverNode.nodeId,
                id: preset.id,
            },
            type: WorkSpaceTabTypes.COPY_PRESET_TAB,
            mode: EditorMode.Read,
            params: {
                serverNode,
                preset,
            } as IWorkspaceTabItemPresetCopyParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        yield put(workspaceAddTab(contentLoadingPageTab));
    }
}

function* handleEditModelTypeGroup(action: TEditModelTypeGroupAction) {
    const { serverNode, preset, modelTypeGroup } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const getSubmitAction = (modelTypeGroups: ModelTypeGroup[]) =>
        submitModelTypeGroup({ serverNode, modelTypeGroups, preset, createMode: false });

    yield put(
        openDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG, {
            elementName: intl.formatMessage(messages.modelFolderType),
            elemenTypeGroup: modelTypeGroup,
            createMode: false,
            getSubmitAction,
        }),
    );
}

function* handleEditEdgeTypeGroup(action: TEditEdgeTypeGroupAction) {
    const { serverNode, preset, edgeTypeGroup } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const getSubmitAction = (edgeTypeGroups: EdgeTypeGroup[]) =>
        submitEdgeTypeGroup({
            serverNode,
            edgeTypeGroups,
            preset,
            createMode: false,
        });

    yield put(
        openDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG, {
            elementName: intl.formatMessage(messages.edgeFolderType),
            elemenTypeGroup: edgeTypeGroup,
            createMode: false,
            getSubmitAction,
        }),
    );
}

function* handleEditAttributeTypeGroup(action: TEditAttributeTypeGroupAction) {
    const { serverNode, preset, attributeTypeGroup } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const getSubmitAction = (attributeTypeGroups: AttributeTypeGroup[]) =>
        submitAttributeTypeGroup({
            serverNode,
            attributeTypeGroups,
            preset,
            createMode: false,
        });

    yield put(
        openDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG, {
            elementName: intl.formatMessage(messages.attributeFolderType),
            elemenTypeGroup: attributeTypeGroup,
            createMode: false,
            getSubmitAction,
        }),
    );
}

function* handleEditSymbol(action: TOpenSymbolEditorTabAction) {
    const { serverNode, preset, objectTypeId, symbolTypeId } = action.payload;
    let { symbol } = action.payload;
    const objectType: ObjectType | undefined = yield select(
        ObjectTypeSelectors.byId({ objectTypeId, presetId: preset.id, serverId: serverNode.nodeId.serverId }),
    );

    if (!objectType) {
        yield put(showNotificationByType(NotificationType.OBJECT_TO_EDIT_NOT_FOUND));

        return;
    }
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const isNewSymbol: boolean = !symbol;
    if (!symbol) {
        symbol = {
            id: uuid(),
            presetId: preset.id,
            name: intl.formatMessage(messages.newSymbol),
            labelWidth: 100,
            labelHeight: 40,
            showLabel: true,
            labelStyle: 'strokeColor=none;silaText=1;fillColor=none;',
            style: 'silaText=1;',
            objectType: objectTypeId,
            symbolTypeId,
            width: 100,
            height: 100,
        } as Symbol;
    }
    yield put(
        initSymbolEditor({
            symbol,
            serverId: serverNode.nodeId.serverId,
        }),
    );
    const contentLoadingPageTab: TWorkspaceTab & TPresetId = {
        title: `${intl.formatMessage(messages.typeSymbol)}: ${symbol.name}`,
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${symbol.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_SYMBOL_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            symbol,
            serverNode,
            preset,
            isNewSymbol,
            objectType,
        } as IWorkspaceTabItemOpenSymbolEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* handleCreateModelTypeGroup(action: TCreateModelTypeGroupAction) {
    const { serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const modelTypeGroup: ModelTypeGroup = {
        id: uuid(),
        presetId: preset.id,
        name: intl.formatMessage(messages.newGroup),
        description: '',
    };

    const getSubmitAction = (modelTypeGroups: ModelTypeGroup[]) =>
        submitModelTypeGroup({ serverNode, modelTypeGroups, preset, createMode: true });

    yield put(
        openDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG, {
            elementName: intl.formatMessage(messages.modelFolderType),
            elemenTypeGroup: modelTypeGroup,
            createMode: true,
            getSubmitAction,
        }),
    );
}

function* handleCreateEdgeTypeGroup(action: TCreateEdgeTypeGroupAction) {
    const { serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const edgeTypeGroup: EdgeTypeGroup = {
        id: uuid(),
        presetId: preset.id,
        name: intl.formatMessage(messages.newGroup),
        description: '',
    };

    const getSubmitAction = (edgeTypeGroups: EdgeTypeGroup[]) =>
        submitEdgeTypeGroup({
            serverNode,
            edgeTypeGroups,
            preset,
            createMode: true,
        });

    yield put(
        openDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG, {
            elementName: intl.formatMessage(messages.edgeFolderType),
            elemenTypeGroup: edgeTypeGroup,
            createMode: true,
            getSubmitAction,
        }),
    );
}

function* handleCreatAttributeTypeGroup(action: TCreateAttributeTypeGroupAction) {
    const { serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const attributeTypeGroup: AttributeTypeGroup = {
        id: uuid(),
        presetId: preset.id,
        name: intl.formatMessage(messages.newGroup),
        description: '',
    };

    const getSubmitAction = (attributeTypeGroups: AttributeTypeGroup[]) =>
        submitAttributeTypeGroup({
            serverNode,
            attributeTypeGroups,
            preset,
            createMode: true,
        });

    yield put(
        openDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG, {
            elementName: intl.formatMessage(messages.attributeFolderType),
            elemenTypeGroup: attributeTypeGroup,
            createMode: true,
            getSubmitAction,
        }),
    );
}

function* handleEditFolderType(action: TEditFolderTypeAction) {
    const { serverNode, folderType, preset } = action.payload;
    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);
    const nodeId = {
        ...serverNode.nodeId,
        id: `${serverNode.nodeId.serverId}_${preset.id}_${folderType.id}`,
    };
    const title = `${intl.formatMessage(messages.folderType)}: ${LocalesService.internationalStringToString(
        folderType.multilingualName,
        currentLocale,
    )}`;
    const editFolderTypeTab: TWorkspaceTab = {
        title,
        type: WorkSpaceTabTypes.EDIT_FOLDER_TYPE_TAB,
        nodeId,
        mode: EditorMode.Read,
        params: {
            folderType,
            preset,
            serverNode,
            createMode: false,
            nodeId,
        } as IWorkspaceTabItemFolderTypeEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(workspaceAddTab(editFolderTypeTab));
}

function* handleCreateFolderType(action: TCreateFolderTypeAction) {
    const { serverNode, preset } = action.payload;
    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);
    const folderType: FolderType = {
        id: uuid(),
        presetId: preset.id,
        multilingualName: { [currentLocale]: intl.formatMessage(messages.newFolderType) },
        multilingualDescription: {},
        graphical: '',
        allowAnyFolderType: true,
        allowAnyModelType: true,
        allowAnyNodeType: true,
        allowAnyObjectType: true,
        validModelTypes: [],
        validObjectTypes: [],
        validFolderTypes: [],
        validNodeTypes: [],
        attributeTypes: [],
    };
    const nodeId = {
        ...serverNode.nodeId,
        id: `${serverNode.nodeId.serverId}_${preset.id}_${folderType.id}`,
    };
    const createFolderTypeTab: TWorkspaceTab = {
        title: intl.formatMessage(messages.createFolderType),
        type: WorkSpaceTabTypes.EDIT_FOLDER_TYPE_TAB,
        nodeId,
        mode: EditorMode.Read,
        params: {
            folderType,
            preset,
            serverNode,
            createMode: true,
            nodeId,
        } as IWorkspaceTabItemFolderTypeEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };

    yield put(folderTypeEditorInit({ folderType }));
    yield put(workspaceAddTab(createFolderTypeTab));
}

function* handleTabClose(action: TWorkspaceTabsRemoveAction) {
    const { workspaceTab } = action.payload;
    if (!workspaceTab) {
        return;
    }
    const isMethodologySettingsTab = METHODOLOGY_SETTINGS_TYPES_ARR.find((s) => s === workspaceTab.type);
    if (isMethodologySettingsTab) {
        yield put(workspaceRemoveTab(workspaceTab));
    }
}

function* handleMakeDefault({ payload }: TMakeDefaultAction) {
    const { serverNode, preset } = payload;
    if (serverNode) {
        const defaultPreset: TPreset | undefined = yield select(
            PresetSelectors.defaultPresetByServerId(serverNode.nodeId.serverId),
        );
        yield MethodologySettingDaoService.setDefaultPreset(serverNode.nodeId.serverId, preset.id);
        yield put(
            changeDefaultPreset({
                defaultPresetId: defaultPreset?.id || '',
                newDefaultPresetId: preset.id,
                serverId: serverNode.nodeId.serverId,
            }),
        );
    }
}

function* handleSubmitPresetCopy({ payload }: TSubmitPresetCopyAction) {
    const { serverNode, preset, tabNodeId } = payload;
    const presetCopy = {
        ...preset,
        id: uuid(),
        prime: false,
    };

    try {
        if (serverNode) {
            const newPreset: TPreset = yield MethodologySettingDaoService.getPresetCopy(
                serverNode.nodeId.serverId,
                preset.id,
                presetCopy,
            );
            yield put(submitPresetSuccess({ serverNode, preset: newPreset }));
            yield put(workspaceRemoveTabByNodeId(tabNodeId));
        }
    } finally {
        yield put(presetCopyLoadingFinish());
    }
}

function* handleSubmitModelTypeGroup({ payload }: TSubmitModelTypeGroupAction) {
    const { serverNode, modelTypeGroups, createMode } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (serverId) {
        if (createMode) {
            yield MethodologySettingDaoService.createModelTypeGroup(serverId, modelTypeGroups[0]).catch((err) => {
                if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                    throw Error(intl.formatMessage(messages.idAlreadyTaken));
                } else {
                    throw err;
                }
            });
        } else {
            yield MethodologySettingDaoService.setModelTypeGroupBulkSave(serverId, modelTypeGroups);
        }
    }

    yield put(closeDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG));
}

function* handleSubmitEdgeTypeGroup({ payload }: TSubmitEdgeTypeGroupAction) {
    const { serverNode, edgeTypeGroups, createMode } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (serverId) {
        if (createMode) {
            yield MethodologySettingDaoService.createEdgeTypeGroup(serverId, edgeTypeGroups[0]).catch((err) => {
                if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                    throw Error(intl.formatMessage(messages.idAlreadyTaken));
                } else {
                    throw err;
                }
            });
        } else {
            yield MethodologySettingDaoService.setEdgeTypeGroupBulkSave(serverId, edgeTypeGroups);
        }
    }
    yield put(closeDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG));
}

function* handleSubmitAttributeTypeGroup({ payload }: TSubmitAttributeTypeGroupAction) {
    const { serverNode, attributeTypeGroups, createMode } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (serverId) {
        if (createMode) {
            yield MethodologySettingDaoService.createAttributeTypeGroup(serverId, attributeTypeGroups[0]).catch(
                (err) => {
                    if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                        throw Error(intl.formatMessage(messages.idAlreadyTaken));
                    } else {
                        throw err;
                    }
                },
            );
        } else {
            yield MethodologySettingDaoService.setAttributeTypeGroupBulkSave(serverId, attributeTypeGroups);
        }
    }
    yield put(closeDialog(DialogType.METHODOLOGY_GROUP_CREATE_DIALOG));
}

function* handleSubmitTreeNodeTypes({ payload }: TTreeNodeTypeRequestAction) {
    const { serverId, presetId, treeNodeTypes } = payload;
    yield MethodologySettingDaoService.treeNodeTypeBulkSave(serverId, treeNodeTypes);
    yield put(treeNodeTypeRequestSuccess(serverId, treeNodeTypes, presetId));
}

function* handleSubmitPreset({ payload }: TSubmitPresetAction) {
    const { serverNode, preset, closeWindow, presetProfilesIds, presetId, tabNodeId } = payload;

    const { serverId } = serverNode.nodeId;

    try {
        if (closeWindow) {
            yield put(workspaceRemoveTabByNodeId(tabNodeId));
        }

        if (serverNode) {
            const presetDTO: PresetDTO = yield MethodologySettingDaoService.getPresetSave(serverId, preset);

            yield saveKanbanModelTypeChanges(serverId, presetId);
            yield saveMatrixModelTypeChanges(presetId);
            yield saveReportModelTypeChanges(presetId);
            yield saveWikiModelTypeChanges(presetId);
            yield saveModelTypeChanges(serverId, presetId);

            yield handleDeleteModelTypeGroup(
                deleteModelTypeGroupRequest({
                    modelTypeGroups: yield select(
                        ModelTypeGroupSelectors.deletedModelTypeGroups({ presetId, serverId }),
                    ),
                    serverNode,
                }),
            );
            yield all(
                presetProfilesIds.map((profileId) =>
                    MethodologySettingDaoService.deleteProfile(serverId, presetId, profileId),
                ),
            );
            yield put(submitPresetSuccess({ serverNode, preset: { ...preset, ...presetDTO } }));
        }

        if (closeWindow) yield put(showNotificationByType(NotificationType.RESTART_TO_APPLY_CHANGES));
    } catch {
        yield put(submitPresetSuccess({ serverNode, preset: { ...preset } }));
    }
}

function* handleDeleteModelTypeGroup({ payload }: TDeleteModelTypeGroupRequestAction) {
    const { modelTypeGroups, serverNode } = payload;
    if (modelTypeGroups.length > 0) {
        const { presetId } = modelTypeGroups[0];
        const { serverId } = serverNode.nodeId;
        const modelTypes: ModelType[] = (yield select(ModelTypeSelectors.getAllModelTypes(serverId, presetId))).filter(
            (mt) =>
                modelTypeGroups.some(
                    (mtg) => mtg.id === mt.modelTypeGroup?.id && mtg.presetId === mt.modelTypeGroup?.presetId,
                ),
        );
        yield MethodologySettingDaoService.modelTypeBulkDelete(serverId, modelTypes);
        yield MethodologySettingDaoService.modelTypeGroupBulkDelete(serverId, modelTypeGroups);

        const kanbanModelTypes = (yield select(PresetSettingsKanbanModelTypeSelectors.listByPresetId(presetId))).filter(
            (mt) => modelTypeGroups.some((mtg) => mtg.id === mt.groupId),
        );
        const effects: CallEffect<void>[] = [];
        if (kanbanModelTypes.length) {
            kanbanModelTypes.forEach((type) => {
                effects.push(call(() => KanbanDaoService.deleteKanbanBoardType(serverId, presetId, type.id)));
            });
        }

        yield all(effects);
    }
    yield put(deleteModelTypeGroupSuccess(payload));
}

function* handleDeletePreset({ payload }: TDeletePresetAction) {
    const { serverNode, preset } = payload;
    const { serverId } = serverNode.nodeId;
    if (serverNode && preset) {
        yield MethodologySettingDaoService.deletePreset(serverId, preset.id);
        yield put(deletePresetSuccess({ serverNode, preset }));
    }
}

function* handleExportPreset({ payload }: TExportPresetAction) {
    const { preset, serverId, type, ids } = payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const locale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(locale);
    const process: TExecutingProcess = {
        id: uuid(),
        name: intl.formatMessage(ProcessIndicatorMessages.processExportPreset),
        type: ProcessType.EXPORT,
    };

    yield put(setProcessIndicator(true, process));

    try {
        const response: ExportPresetResponse = yield call(() => {
            const exportByPresetParams = { presetId: preset.id };
            const exportParams = { body: ids?.map((id) => ({ id, presetId: preset.id })) || [], presetId: preset.id };

            if (type) {
                return ids?.length
                    ? server.api.preset.exportTypesByIds({ ...exportParams, type })
                    : server.api.preset.exportTypes({ ...exportByPresetParams, type });
            }

            return server.api.preset.exportPreset(exportByPresetParams);
        });
        const exportDialogOptions: Electron.SaveDialogOptions = {
            title: intl.formatMessage(messages.saveExportedFile),
            defaultPath: `${LocalesService.internationalStringToString(preset.multilingualName)}${
                type !== undefined ? intl.formatMessage(messages[PresetElementTransferType[type]]) : ''
            }.sme`,
            filters: [
                { name: 'Sila Methodology Export file', extensions: ['sme'] },
                { name: 'ZIP file', extensions: ['zip'] },
                { name: 'XML file', extensions: ['xml'] },
                { name: 'All files', extensions: ['*'] },
            ],
        };
        BrowserDaoService.downloadFile(
            `${server.url}/${TMP_DOWNLOAD_PATH}/${response.resultFileName}`,
            exportDialogOptions,
        );
    } finally {
        yield put(setProcessIndicator(false, process));
    }
}

function* handleExportExcelPreset({ payload }: TExportExcelPresetAction) {
    const { preset, serverId } = payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const locale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(locale);
    const process: TExecutingProcess = {
        id: uuid(),
        name: intl.formatMessage(ProcessIndicatorMessages.processExportPreset),
        type: ProcessType.EXPORT,
    };

    yield put(setProcessIndicator(true, process));

    try {
        const response: ExportPresetResponse = yield call(() => {
            return server.api.preset.exportPresetToExcel({ presetId: preset.id });
        });
        const exportDialogOptions: Electron.SaveDialogOptions = {
            title: intl.formatMessage(messages.saveExportedFile),
            defaultPath: `${LocalesService.internationalStringToString(preset.multilingualName)}.xlsx`,
            filters: [
                { name: 'Excel file', extensions: ['xlsx'] },
                { name: 'All files', extensions: ['*'] },
            ],
        };
        BrowserDaoService.downloadFile(
            `${server.url}/${TMP_DOWNLOAD_PATH}/${response.resultFileName}`,
            exportDialogOptions,
        );
    } finally {
        yield put(setProcessIndicator(false, process));
    }
}

function* doImport(
    fileName: string,
    serverNode: TreeNode,
    preset: TPreset,
    resolveStrategy?: ImportPresetRequestImportPresetResolutionEnum,
    type?: PresetElementTransferType,
) {
    const { serverId } = serverNode.nodeId;
    const importParams: { type?: string; presetId: string; body?: ImportPresetRequest | undefined } = {
        body: { importPresetResolution: resolveStrategy || 'NOT_SELECTED', fileName },
        presetId: preset?.id,
    };
    if (type) {
        return yield MethodologySettingDaoService.presetDoImportTypes(serverId, { ...importParams, type });
    }

    return yield MethodologySettingDaoService.presetDoImport(serverId, importParams);
}

function* handleUploadSymbolSVGRequest({ payload }: TUploadSymbolSVGRequestAction) {
    const { file, serverNode, symbol } = payload;
    const { serverId } = serverNode.nodeId;

    if (file && !isSvgImage(file)) {
        yield put(showNotificationByType(NotificationType.INCORRECT_SYMBOL_TYPE));

        return;
    }

    if (file) {
        const symbolUUID = (yield FileDaoService.uploadTmpFile(serverId, file)).result;
        const symbolResp: Symbol = yield MethodologySettingDaoService.convertSvgToShape(serverId, symbolUUID);
        yield put(
            symbolSVGConvertRequestSuccess({
                symbol: {
                    ...symbol,
                    graphical: symbolResp.graphical,
                } as Symbol,
                serverId,
            }),
        );
    }
}

function* handleSubmitSymbolRequest({ payload }: TSubmitSymbolRequestAction) {
    const { serverNode, symbol, preset, tabNodeId } = payload;
    const { serverId } = serverNode.nodeId;

    if (tabNodeId) yield put(workspaceRemoveTabByNodeId(tabNodeId));
    yield MethodologySettingDaoService.symbolSave(serverId, symbol);
    yield put(symbolsRequestSuccess(serverId, [symbol], preset.id));
}

function* handleCreateSymbolRequest({ payload }: TCreateSymbolRequestAction) {
    const { serverNode, symbol, preset, tabNodeId } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (tabNodeId) yield put(workspaceRemoveTabByNodeId(tabNodeId));
    yield MethodologySettingDaoService.createSymbol(serverId, symbol).catch((err) => {
        if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
            throw Error(intl.formatMessage(messages.idAlreadyTaken));
        } else {
            throw err;
        }
    });
    yield put(symbolsRequestSuccess(serverId, [symbol], preset.id));
}

function* handleImportPresetRequest({ payload }: TImportPresetRequestAction) {
    const { file, serverNode, resolveStrategy, process, type, preset } = payload;
    const presetId = preset?.id;
    const { serverId } = serverNode.nodeId;
    const locale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(locale);
    const fileName: string =
        file?.name || payload.fileName || intl.formatMessage(AppNotificationsMessages.importProcess);
    const proc: TExecutingProcess = process || {
        id: uuid(),
        name: fileName,
        type: ProcessType.IMPORT,
    };
    try {
        if (!process) {
            yield put(setProcessIndicator(true, proc));
        }
        const fileUUID: string =
            (file && (yield FileDaoService.uploadTmpFile(serverId, file)).result) || payload.fileUUID;
        if (fileUUID) {
            const response: ImportPresetResponse = yield call(() =>
                doImport(fileUUID, serverNode, preset, resolveStrategy, type),
            );
            if (response.status === 'OK') {
                if (!type) yield* loadMethodologiesList();

                yield put(
                    showNotification({
                        id: uuid(),
                        type: NotificationType.IMPORT_FILE_SUCCESS,
                    }),
                );
            } else if (response.status === 'NEED_RESOLVE') {
                yield put(
                    openDialog(DialogType.IMPORT_PRESET_DIALOG, {
                        serverNode,
                        process,
                        fileName,
                        fileUUID,
                        conflictingPreset: response.existingPreset,
                        type,
                    }),
                );
            } else if (response.status === 'ERROR_IMPORT_DEFAULT') {
                yield put(importPresetDialogRequestDone(proc));
                yield put(
                    showNotification({
                        id: uuid(),
                        data: { message: intl.formatMessage(AppNotificationsMessages.importDefaultPresetFail) },
                        type: NotificationType.IMPORT_FILE_FAIL,
                    }),
                );
            } else if (response.status === 'ERROR_READ_TMP_FILE') {
                yield put(importPresetDialogRequestDone(proc));
                yield put(
                    showNotification({
                        id: uuid(),
                        data: { message: intl.formatMessage(AppNotificationsMessages.importFileReadFail) },
                        type: NotificationType.IMPORT_FILE_FAIL,
                    }),
                );
            } else {
                switch (type) {
                    case PresetElementTransferType.model: {
                        if (preset) {
                            yield put(getAllModelTypes({ presetId, serverId }));
                            const modelTypeGroups: Array<ModelTypeGroup> =
                                yield MethodologySettingDaoService.getModelTypeGroups(serverId, presetId);
                            yield put(
                                addModelTypeGroups({
                                    modelTypeGroups,
                                    serverNode,
                                    preset,
                                }),
                            );
                        }
                        break;
                    }
                    case PresetElementTransferType.object: {
                        if (preset) {
                            const objectTypes: ObjectType[] = yield MethodologySettingDaoService.getObjectTypes(
                                serverId,
                                presetId,
                            );
                            const symbols: Symbol[] = yield MethodologySettingDaoService.getSymbolsByPresetId(
                                serverId,
                                presetId,
                            );
                            yield put(
                                objectTypeRequestSuccess({
                                    serverId,
                                    presetId,
                                    objectTypes,
                                }),
                            );
                            const objectTypeGroups: Array<ObjectTypeGroup> =
                                yield MethodologySettingDaoService.getObjectTypeGroups(serverId, presetId);
                            yield put(
                                addObjectTypeGroups({
                                    objectTypeGroups,
                                    serverNode,
                                    preset,
                                }),
                            );
                            yield put(symbolsRequestSuccess(serverId, symbols, presetId));
                        }
                        break;
                    }
                    case PresetElementTransferType.edge: {
                        if (preset) {
                            const edgeTypes: EdgeType[] = yield MethodologySettingDaoService.getEdgeTypes(
                                serverId,
                                presetId,
                            );
                            yield put(edgeTypeRequestSuccess(serverId, edgeTypes, presetId));
                            const edgeTypeGroups: Array<EdgeTypeGroup> =
                                yield MethodologySettingDaoService.getEdgeTypeGroups(serverId, presetId);
                            yield put(
                                addEdgeTypeGroups({
                                    edgeTypeGroups,
                                    preset,
                                    serverNode,
                                }),
                            );
                        }
                        break;
                    }
                    case PresetElementTransferType.attribute: {
                        if (preset) {
                            const attributeTypes: AttributeType[] =
                                yield MethodologySettingDaoService.getAttributeTypeByPresetId(serverId, presetId);
                            yield put(attributeTypesRequestSuccess(serverId, presetId, attributeTypes));
                            const attributeTypeGroups: Array<AttributeTypeGroup> =
                                yield MethodologySettingDaoService.getAttributeTypeGroups(serverId, presetId);
                            yield put(
                                addAttributeTypeGroups({
                                    attributeTypeGroups,
                                    serverNode,
                                    preset,
                                }),
                            );
                        }
                        break;
                    }
                    case PresetElementTransferType.image: {
                        if (preset) {
                            const presetImages: PresetImage[] = yield MethodologySettingDaoService.getPresetImages(
                                serverId,
                                presetId,
                            );
                            yield put(presetImageRequestSuccess({ serverId, presetId, presetImages }));
                            const presetImageGroups: Array<PresetImageGroup> =
                                yield MethodologySettingDaoService.getPresetImageGroups(serverId, presetId);
                            yield put(
                                addPresetImageGroups({
                                    presetImageGroups,
                                    serverNode,
                                    preset,
                                }),
                            );
                        }
                        break;
                    }
                    default: {
                        const repositories: Array<RepositoryNode> = yield RepositoryDAOService.getRepositories(
                            serverId,
                        );
                        const presetDTOs: Array<PresetDTO> = yield MethodologySettingDaoService.getPresetList(serverId);
                        const presets: Array<TPreset> = presetDTOs.map((p) => {
                            return {
                                ...p,
                                repositories: repositories.filter((r) => r.presetId === p.id),
                            };
                        });
                        yield put(loadPresetsSuccess({ serverNode, presets }));
                        break;
                    }
                }
            }
            yield put(setProcessIndicator(false, proc));
        }
    } catch (e) {
        yield put(setProcessIndicator(false, proc));
        yield put(importPresetDialogRequestDone(proc));
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType.IMPORT_FILE_FAIL,
            }),
        );
        console.error(e);
    }
    yield put(setProcessIndicator(false, proc));
}

function* handleOpenSymbolAttributeTypeStyleEditor({ payload }: TEditModelTypeSymbolAttributeTypeStyleAction) {
    const { modelType, serverNode, symbol, definitionAttributes, instanceAttributes } = payload;
    const presetStyles = modelType?.symbolAttributeStyles.filter((at) => at.symbolId === symbol.id);
    const presetImages: PresetImage[] = yield select(
        PresetImageSelectors.listAllByPreset(serverNode.nodeId.serverId, modelType.presetId),
    );

    const props: TSymbolFloatingAttributesDialogProperties = {
        presetStyles,
        definitionAttributes,
        instanceAttributes,
        serverNode,
        symbol,
        modelType,
        presetImages,
    };
    const objectType: ObjectType | undefined = yield select(
        ObjectTypeSelectors.byId({
            objectTypeId: symbol.objectType,
            presetId: symbol.presetId,
            serverId: serverNode.nodeId.serverId,
        }),
    );

    if (!objectType) {
        yield put(showNotificationByType(NotificationType.OBJECT_TO_EDIT_NOT_FOUND));

        return;
    }

    yield put(openDialog(DialogType.EDIT_SYMBOL_FLOATING_ATTRIBUTES_DIALOG, props));
}

function* handleOpenEdgeTypeAttributeTypeStyleEditor({ payload }: TEditModelTypeEdgeAttributeTypeStyleAction) {
    const { modelType, serverNode, edgeType, definitionAttributes, instanceAttributes } = payload;
    const presetStyles: EdgeTypeAttributeTypeStyleDTO[] = modelType.edgeAttributeStyles;
    const presetStylesByEdgeId: EdgeTypeAttributeTypeStyleDTO[] = presetStyles.filter(
        (style) => style.edgeTypeId === edgeType.id,
    );
    const presetImages: PresetImage[] = yield select(
        PresetImageSelectors.listAllByPreset(serverNode.nodeId.serverId, modelType.presetId),
    );

    const dialogProperties: TEdgeTypeFloatingAttributesDialogProperties = {
        presetStyles: presetStylesByEdgeId,
        definitionAttributes,
        instanceAttributes,
        serverNode,
        edgeType,
        modelType,
        presetImages,
        useUniqueStyledAttributeTypes: true,
    };

    yield put(openDialog(DialogType.EDIT_EDGE_TYPE_FLOATING_ATTRIBUTES_DIALOG, dialogProperties));
}

/**
 * Обновить стили типов атрибутов в пресете
 * @param payload
 */
function* handleUpdateFloatingAttributesTypeStyles({ payload }: TUpdateModelTypeFloatingAttributesStylesAction) {
    const { attributeTypes, serverNode, edgeType, symbol, modelType } = payload;
    const { serverId } = serverNode.nodeId;
    const { presetId } = modelType;

    if (isEmpty(attributeTypes)) return;

    const attributeTypesWithStyles = attributeTypes.filter((attributeType) => attributeType.styles?.length);
    if (symbol) {
        modelType.symbolAttributeStyles = modelType.symbolAttributeStyles
            .filter((style) => style.symbolId !== symbol.id)
            .concat(
                attributeTypesWithStyles.map(
                    (attributeType) =>
                        ({
                            styles: attributeType.styles,
                            attributeTypeId: attributeType.id,
                            symbolId: symbol.id,
                            attributeDiscriminator: attributeType.attributeDiscriminator,
                        } as SymbolAttributeTypeStyleDTO),
                ),
            );
    }

    if (edgeType) {
        modelType.edgeAttributeStyles = modelType.edgeAttributeStyles
            .filter((style) => style.edgeTypeId !== edgeType.id)
            .concat(
                attributeTypesWithStyles.map(
                    (attributeType) =>
                        ({
                            attributeStyle: attributeType.styles?.[0],
                            attributeTypeId: attributeType.id,
                            edgeTypeId: edgeType.id,
                            attributeDiscriminator: attributeType.attributeDiscriminator,
                        } as EdgeTypeAttributeTypeStyleDTO),
                ),
            );
    }

    yield put(
        submitModelType({
            modelTypes: [modelType],
            createMode: false,
            needTabClose: false,
            presetId,
            serverId,
        }),
    );
}

export function* methodologySettingSagaInit() {
    yield takeEvery(OPEN_METHODOLOGY_TAB, openMethodologySetting);
    yield takeEvery(OPEN_ADD_PRESET, handleAddPreset);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabClose);
    yield takeEvery(EDIT_MODEL_TYPE_GROUP, handleEditModelTypeGroup);
    yield takeEvery(EDIT_EDGE_TYPE_GROUP, handleEditEdgeTypeGroup);
    yield takeEvery(EDIT_ATTRIBUTE_TYPE_GROUP, handleEditAttributeTypeGroup);
    yield takeEvery(EDIT_PRESET_REQUEST, handleEditPresetRequest);
    yield takeEvery(SUBMIT_PRESET, handleSubmitPreset);
    yield takeEvery(COPY_PRESET, handleCopyPreset);
    yield takeEvery(SUBMIT_PRESET_COPY, handleSubmitPresetCopy);
    yield takeEvery(DELETE_PRESET, handleDeletePreset);
    yield takeEvery(CREATE_MODEL_TYPE_GROUP, handleCreateModelTypeGroup);
    yield takeEvery(CREATE_EDGE_TYPE_GROUP, handleCreateEdgeTypeGroup);
    yield takeEvery(CREATE_ATTRIBUTE_TYPE_GROUP, handleCreatAttributeTypeGroup);
    yield takeEvery(SUBMIT_MODEL_TYPE_GROUP, handleSubmitModelTypeGroup);
    yield takeEvery(SUBMIT_EDGE_TYPE_GROUP, handleSubmitEdgeTypeGroup);
    yield takeEvery(SUBMIT_ATTRIBUTE_TYPE_GROUP, handleSubmitAttributeTypeGroup);
    yield takeEvery(DELETE_MODEL_TYPE_GROUP_REQUEST, handleDeleteModelTypeGroup);
    yield takeEvery(OPEN_SYMBOL_EDITOR_TAB, handleEditSymbol);
    yield takeEvery(MAKE_DEFAULT, handleMakeDefault);
    yield takeEvery(EXPORT_PRESET, handleExportPreset);
    yield takeEvery(EXPORT_EXCEL_PRESET, handleExportExcelPreset);
    yield takeEvery(IMPORT_PRESET_REQUEST, handleImportPresetRequest);
    yield takeEvery(UPLOAD_SYMBOL_SVG_REQUEST, handleUploadSymbolSVGRequest);
    yield takeEvery(SUBMIT_SYMBOL_REQUEST, handleSubmitSymbolRequest);
    yield takeEvery(CREATE_SYMBOL_REQUEST, handleCreateSymbolRequest);
    yield takeEvery(EDIT_MODEL_TYPE_SYMBOL_ATTRIBUTE_TYPE_STYLE, handleOpenSymbolAttributeTypeStyleEditor);
    yield takeEvery(EDIT_MODEL_TYPE_EDGE_ATTRIBUTE_TYPE_STYLE, handleOpenEdgeTypeAttributeTypeStyleEditor);
    yield takeEvery(OVERLAY_UPDATE_STYLE, handleUpdateFloatingAttributesTypeStyles);
    yield takeEvery(TREE_NODE_TYPE_REQUEST, handleSubmitTreeNodeTypes);
    yield takeEvery(EDIT_FOLDER_TYPE, handleEditFolderType);
    yield takeEvery(CREATE_FOLDER_TYPE, handleCreateFolderType);
    yield takeEvery(GET_PRESET_LIST, loadMethodologiesList);
}

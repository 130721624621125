import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'AddNewValueDialog.cancel',
        defaultMessage: 'Отмена',
    },
    continueSave: {
        id: 'AddNewValueDialog.apply',
        defaultMessage: 'Применить',
    },
});

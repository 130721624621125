import React, { FC, useEffect } from 'react';
import theme from './ReportEditorTable.scss';
import { ColumnPinningPosition, flexRender, Header, RowData } from '@tanstack/react-table';
import { TReportTableData } from '../ReportEditor.types';
import { horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { getTableCellStyles, isElementInViewport } from './ReportEditorTable.utils';

type TDraggableTableHeaderProps = {
    isReadMode: boolean;
    header: Header<TReportTableData, any>;
    onColumnClick: (columnId: string) => void;
};

declare module '@tanstack/react-table' {
    interface ColumnMeta<TData extends RowData, TValue> {
        selected: boolean;
    }
}

export const DraggableTableHeader: FC<TDraggableTableHeaderProps> = ({ isReadMode, header, onColumnClick }) => {
    const { column } = header;

    const { attributes, isDragging, listeners, transform, node, setNodeRef } = useSortable({
        id: column.id,
        disabled: isReadMode,
        strategy: horizontalListSortingStrategy,
    });

    const isSelected: boolean = !!column?.columnDef?.meta?.selected;
    const isPinned: ColumnPinningPosition = column.getIsPinned();

    const headerProps = !isPinned ? { ...header.getContext(), listeners, attributes } : header.getContext();

    useEffect(() => {
        //setTimeout - костыль для прокрутки к колонке после добваления
        //проблема: если колонка вставляется не в конец таблицы
        //из-за неправильного columnOrder колонка сначала отрисуется в конце таблицы
        //затем просиходит прокрутка в конец таблицы, потому columnOrder пересчитывается
        //и колонка встает на свое место. Необходимо изначально правильно задавать columnOrder
        setTimeout(() => {
            if (isSelected && node.current && !isElementInViewport(node.current)) {
                node.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        }, 0);
    }, [isSelected, node.current]);

    return (
        <th
            ref={setNodeRef}
            key={header.id}
            style={{ ...getTableCellStyles(column, transform, isDragging) }}
            className={isSelected ? theme.selectedHeader : ''}
            onClick={() => onColumnClick(column.id)}
        >
            {flexRender(header.column.columnDef.header, headerProps)}

            {!isPinned && !isDragging && (
                <div
                    className={`${theme.resizer} ${header.column.getIsResizing() ? `${theme.isResizing}` : ''}`}
                    onMouseDown={header.getResizeHandler()}
                    onDoubleClick={header.column.resetSize}
                    onClick={(e) => e.stopPropagation()}
                />
            )}
        </th>
    );
};

import React from 'react';

const CompareModelImage = ({ url, style = {}, className = '' }) => {
    if (!url) {
        return <></>;
    }

    return (
        <div className={className} style={style}>
            <img src={`data:image/svg+xml;base64,${url}`} alt="" />
        </div>
    );
};

export default CompareModelImage;

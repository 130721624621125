import type { IExtendedImageOptions, IBufferedEditorImageOptions, TImageAttributes } from './image.types';
import type { TNodeWithPosition } from './extensions.types';
import type { Editor } from '@tiptap/core';
import { mergeAttributes, ReactNodeViewRenderer } from '@tiptap/react';
import Image from '@tiptap/extension-image';
import { ImageRenderer } from '../renderers/image/ImageRenderer.component';
import { IMAGE_NODES, ImageNodeType } from './constants';
import { WIKI_IMAGE_DEFAULT_HEIGHT, WIKI_IMAGE_DEFAULT_WIDTH } from '../Editor.constants';
import { ImageCopyPastePlugin } from '../plugins/image/ImageCopyPaste.plugin';

type TImageCommands<T> = {
    /**
     * Add an image
     */
    setImage: (attrs: TImageAttributes) => T;
    /**
     * Refresh image node
     */
    refreshImage: (imageId: string, updatedAttributes?: Partial<TImageAttributes>) => T;
    /**
     * Remove image node
     */
    removeImage: (imageId: string) => T;
};

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        [ImageNodeType.EXTERNAL_IMAGE_NODE_NAME]: TImageCommands<ReturnType>;
        [ImageNodeType.FILE_IMAGE_NODE_NAME]: TImageCommands<ReturnType>;
        [ImageNodeType.WIKI_IMAGE_NODE_NAME]: TImageCommands<ReturnType>;
        [ImageNodeType.MODEL_IMAGE_NODE_NAME]: TImageCommands<ReturnType>;
    }
}

const findImageNode = (editor: Editor, imageId: string): TNodeWithPosition | undefined => {
    const images: TNodeWithPosition[] = [];

    editor.view.state.doc.nodesBetween(0, editor.view.state.doc.content.size, (node, pos) => {
        if (IMAGE_NODES.includes(node.type.name as ImageNodeType) && node.attrs['id'] === imageId) {
            images.push({ pos, node });
        }
    });

    return images[0];
};

const ExternalImage = Image.extend<IExtendedImageOptions>({
    name: ImageNodeType.EXTERNAL_IMAGE_NODE_NAME,

    draggable: false,

    addAttributes() {
        return {
            ...this.parent?.(),
            id: {
                default: null,
                parseHTML: (element) => element.getAttribute('id'),
                renderHTML: (attributes) => {
                    if (!attributes.id) {
                        return {};
                    }

                    return {
                        id: attributes.id,
                    };
                },
            },
            width: {
                default: WIKI_IMAGE_DEFAULT_WIDTH,
                parseHTML: (element) => {
                    const widthAttr = element.getAttribute('width');

                    if (!widthAttr) {
                        return WIKI_IMAGE_DEFAULT_WIDTH;
                    }

                    // TODO нужно адаптировать под разные величины
                    // const widthNumber = parseInt(widthAttr, 10);
                    // return !isNaN(widthNumber) ? widthNumber : widthAttr;
                    return widthAttr;
                },
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width,
                    };
                },
            },
            height: {
                default: WIKI_IMAGE_DEFAULT_HEIGHT,
                parseHTML: (element) => {
                    const heightAttr = element.getAttribute('height');

                    if (!heightAttr) {
                        return WIKI_IMAGE_DEFAULT_HEIGHT;
                    }

                    // TODO нужно адаптировать под разные величины
                    // const heightNumber = parseInt(heightAttr, 10);
                    // return !isNaN(heightNumber) ? heightNumber : heightAttr;
                    return heightAttr;
                },
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height,
                    };
                },
            },
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),
            refreshImage:
                (imageId: string, updatedAttributes?: TImageAttributes) =>
                ({ editor }) => {
                    const image = findImageNode(editor, imageId);

                    if (!image) {
                        return false;
                    }

                    const pos = image.pos;
                    const node = image.node;
                    const attrs = { ...node.attrs, ...(updatedAttributes || {}) };
                    const content = [{ type: node.type.name, attrs }];

                    setTimeout(() => {
                        editor.commands.deleteRange({ from: pos, to: pos + node.nodeSize });
                        editor.commands.insertContentAt(pos, content);
                    }, 0);

                    return true;
                },
            removeImage:
                (imageId: string) =>
                ({ editor }) => {
                    const image = findImageNode(editor, imageId);

                    if (!image) {
                        return false;
                    }

                    const pos = image.pos;
                    const node = image.node;

                    setTimeout(() => {
                        editor.commands.deleteRange({ from: pos, to: pos + node.nodeSize });
                    }, 0);

                    return true;
                },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(ImageRenderer, { className: this.options.HTMLAttributes.class });
    },

    parseHTML() {
        return [{ tag: 'img[src]:not([src^="data:"])' }, { tag: `img[data-type="${this.name}"]` }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['img', mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes)];
    },
});

const FileImage = ExternalImage.extend({
    name: ImageNodeType.FILE_IMAGE_NODE_NAME,
});

const WikiImage = ExternalImage.extend({
    name: ImageNodeType.WIKI_IMAGE_NODE_NAME,
});

const BufferedEditorImage = WikiImage.extend<IBufferedEditorImageOptions>({
    name: 'bufferedEditorImage',

    addAttributes() {
        return {
            ...this.parent?.(),
            sourceId: {
                default: null,
                parseHTML: (element) => {
                    return element.getAttribute('source-id');
                },
                renderHTML: (attributes) => {
                    if (!attributes.sourceId) {
                        return {};
                    }

                    return {
                        'source-id': JSON.stringify(attributes.sourceId),
                    };
                },
            },
        };
    },

    addProseMirrorPlugins() {
        const onPaste = this.options.onPaste;
        const sourceId = this.options.sourceId;

        return [
            ImageCopyPastePlugin({
                sourceId,
                originalNodeName: ImageNodeType.WIKI_IMAGE_NODE_NAME,
                bufferedNodeName: this.name,
                onPaste,
                editor: this.editor,
            }),
        ];
    },
});

const ModelImage = ExternalImage.extend({
    name: ImageNodeType.MODEL_IMAGE_NODE_NAME,
});

export { ExternalImage, FileImage, WikiImage, BufferedEditorImage, ModelImage };

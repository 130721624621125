import { OPEN_COMPARE_MODELS_TAB, OPEN_GRAPHICAL_COMPARISON_TAB } from '@/actionsTypes/compareModels.actionTypes';
import {
    TOpenCompareModelsTab,
    TOpenCompareModelsTabPayload,
    TOpenGraphicalComparisonTab,
    TOpenGraphicalComparisonTabPayload,
} from './compareModels.actions.types';

export const openCompareModelsTab = (payload: TOpenCompareModelsTabPayload): TOpenCompareModelsTab => ({
    type: OPEN_COMPARE_MODELS_TAB,
    payload,
});

export const openGraphicalComparisonTab = (
    payload: TOpenGraphicalComparisonTabPayload,
): TOpenGraphicalComparisonTab => ({
    type: OPEN_GRAPHICAL_COMPARISON_TAB,
    payload,
});

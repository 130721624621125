import { TReducer } from '@/utils/types';
import { TNavigatorSymbolsSearchState } from './navigatorSymbolsSearch.reducer.types';
import {
    CHANGE_NAVIGATOR_SYMBOLS_SEARCH_STATUS,
    CHANGE_NAVIGATOR_SYMBOLS_SEARCH_STRING,
} from '@/actionsTypes/navigatorSymbolsSearch.actionTypes';
import { NAVIGATOR_DESELECT_TAB } from '@/actionsTypes/navigator.actionTypes';
import { TNavigatorTab } from './navigator.reducer.types';

export const navigatorSymbolsSearchReducer: TReducer<TNavigatorSymbolsSearchState> = (state = {}, action) => {
    switch (action.type) {
        case CHANGE_NAVIGATOR_SYMBOLS_SEARCH_STATUS: {
            const { repositoryId, id, isActive } = action.payload;

            if (!state[repositoryId]) {
                return {
                    ...state,
                    [repositoryId]: {
                        [id]: {
                            isActive,
                            searchString: '',
                        },
                    },
                };
            }

            return {
                ...state,
                [repositoryId]: {
                    ...state[repositoryId],
                    [id]: {
                        isActive,
                        searchString: '',
                    },
                },
            };
        }

        case CHANGE_NAVIGATOR_SYMBOLS_SEARCH_STRING: {
            const { repositoryId, id, searchString } = action.payload;

            if (state[repositoryId]?.[id]) {
                return {
                    ...state,
                    [repositoryId]: {
                        ...state[repositoryId],
                        [id]: {
                            ...state[repositoryId][id],
                            searchString,
                        },
                    },
                };
            }

            return state;
        }

        case NAVIGATOR_DESELECT_TAB: {
            return action.payload.tab === TNavigatorTab.Symbols ? {} : state;
        }

        default: {
            return state;
        }
    }
};

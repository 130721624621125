import React, { FC, DragEvent } from 'react';
import { Symbol } from '../../../../serverapi/api';
import theme from './NavigatorSymbols.scss';
import { createMxGraphIcon } from './utils';
import { HightLighted } from '@/modules/UIKit/H.O.C/HightLighted/HightLighted.hoc';

type TSymbolItem = {
    symbol: Symbol;
    onSymbolDrag: (draggedSymbol: Symbol) => void;
    searchString: string;
};
const SymbolItem: FC<TSymbolItem> = (props) => {
    const { symbol, onSymbolDrag, searchString } = props;
    const consumeDragAndEmmit = (e: DragEvent<HTMLDivElement>): void => {
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
        onSymbolDrag(symbol);
    };

    // в этом компоненте два драг'н'дроп обработчика
    // первый драг'н'дроп при перетаскивании символа
    // второй драг'н'дроп по клику на символ
    // решено оставить оба т.к. пользователи уже привыкли по разному использовать

    return (
        <div data-test="navigator-symbol_item" key={symbol.id} onDragStart={consumeDragAndEmmit} draggable>
            <div data-test="navigator-symbol_item-box" onClick={() => onSymbolDrag(symbol)} className={theme.symbol}>
                <div data-test="navigator-symbol_item-icon" className={theme.symbolIconContainer}>
                    {symbol.icon && <img alt="" src={symbol.icon} className={theme.symbolIcon} draggable={false} />}
                    {!symbol.icon && <div draggable={false} ref={(d) => createMxGraphIcon(d!, symbol)} />}
                </div>
                {searchString !== '' ? (
                    <div data-test="navigator-symbol_item-name" className={theme.highlightContainer}>
                        <HightLighted searchText={searchString}>{symbol.name}</HightLighted>
                    </div>
                ) : (
                    <span data-test="navigator-symbol_item-name">{symbol.name}</span>
                )}
            </div>
        </div>
    );
};

export default SymbolItem;

import { useEffect, useState } from 'react';
import { getChildrenToHide } from './utils/getChildrenToHide';

type TUseToolbarResizeData = { toolbarWidth: number; countOfHiddenGroups: number | undefined };

export const useToolbarResize = (
    toolbarRef: React.MutableRefObject<HTMLDivElement | null>,
    withFormatBtn: boolean,
): TUseToolbarResizeData => {
    const [toolbarWidth, setToolbarWidth] = useState<number>(0);
    const [countOfHiddenGroups, setCountOfHiddenGroups] = useState<number | undefined>(undefined);
    const handleMenuResize = (entries: ResizeObserverEntry[]) => {
        const [mainButtons] = entries[0].target.children;

        if (!toolbarRef.current) return;
        const paddingsWidth: number =
            parseInt(getComputedStyle(toolbarRef.current).paddingLeft) +
            parseInt(getComputedStyle(toolbarRef.current).paddingLeft);

        const childrenWidths: number[] = Array.from(mainButtons.children).map(
            (child) => child.getBoundingClientRect().width,
        );
        const childrenToHide: number = getChildrenToHide(
            childrenWidths,
            toolbarRef.current.getBoundingClientRect().width - paddingsWidth,
            withFormatBtn,
        );
        setCountOfHiddenGroups(childrenToHide);
        setToolbarWidth(toolbarRef.current.getBoundingClientRect().width || 0);
    };

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            handleMenuResize(entries);
        });
        if (toolbarRef.current) {
            observer.observe(toolbarRef.current);
        }

        return () => {
            toolbarRef.current && observer.unobserve(toolbarRef?.current);
        };
    }, [toolbarRef.current]);

    return { toolbarWidth, countOfHiddenGroups };
};

import { aipConfig, AipConfig } from "./AipConfig";
import { aipPreset, AipPreset } from "./AipPreset";
import { AipToolbox } from "./AipToolbox";
import { TFoundInConfig } from "./types/AipConfig.types";
import { TAipPresetGroup, TFoundInPreset, TSearchGroupsFilter } from "./types/AipPreset.types";
import { TWord } from "./types/common.types";
import { TProcessedItemGroup, TProcessedSourceQueryItem, TSourceQueryItemInProcessing } from "./types/SourceQueryProcessor.types";

class SourceQueryProcessor {

    private aipConfig: AipConfig;

    private aipPreset: AipPreset;


    constructor(config: AipConfig, preset: AipPreset) {
        this.aipConfig = config;
        this.aipPreset = preset;
    }


    process(sourceQuery: string): TProcessedSourceQueryItem[] {
        const words: TWord[] = AipToolbox.getWordsFromString(sourceQuery);
        if (!words.length) return [];
        let intermediateResult: TSourceQueryItemInProcessing[];
        intermediateResult = this.findAndReplaceConfigItems(words, true);
        intermediateResult = this.findAndReplacePresetItems(intermediateResult);
        intermediateResult = this.findAndReplaceConfigItems(intermediateResult, false);
        let result: TProcessedSourceQueryItem[] = this.replaceUntoutchedWords(intermediateResult);
        result = this.removeMeaninglessItems(result);
        return result;
    }


    private findFirstUntoutchedItemIndex(items: TSourceQueryItemInProcessing[]): number {
        // string here is the same as TWord
        return items.findIndex(item => typeof item === 'string');
    }


    private findTheNearestReplacedItemIndex(startsFrom: number, items: TSourceQueryItemInProcessing[]): number {
        // string here is the same as TWord
        return items.findIndex((item, index) => (index >= startsFrom) && (typeof item !== 'string'));
    }


    private replaceUntoutchedWords(items: TSourceQueryItemInProcessing[]): TProcessedSourceQueryItem[] {
        let result: Array<TProcessedSourceQueryItem> = [];
        do {
            const item: TSourceQueryItemInProcessing = items[0];
            if (typeof item !== 'string') {
                // Это или элемент из конфига или элемент из пресета
                if (item.type === 'CONFIG') {
                    result.push({ type: TProcessedItemGroup.CONFIG, value: item.value })
                }
                else {
                    result.push({ type: TProcessedItemGroup.PRESET, value: item.value})
                }
                items = items.slice(1);
            }
            else {
                const sliceFrom: number = 0;
                const index: number = this.findTheNearestReplacedItemIndex(sliceFrom, items);
                const sliceTo: number = index > -1 ? index : items.length;
                const str: string = items.slice(sliceFrom, sliceTo).map(val => val).join(' ');
                const item: TProcessedSourceQueryItem = {
                    type: TProcessedItemGroup.NOWHERE,
                    value: {source: str}
                };
                result.push(item);
                items = items.slice(str.split(' ').length);
            }
        } while(items.length);
        return result;
    }


    private findAndReplacePresetItems(items: TSourceQueryItemInProcessing[]): TSourceQueryItemInProcessing[] {
        let result: TSourceQueryItemInProcessing[] = [];
        do {
            const sliceFrom: number = this.findFirstUntoutchedItemIndex(items);
            if (sliceFrom < 0) {
                // в исходном массиве больше нечего менять, возвращаем уже переработанные элементы и то что ещё не обработали
                return result.concat(items);
            }
            if (sliceFrom > 0) {
                result = result.concat(items.slice(0, sliceFrom));
            }

            const index: number = this.findTheNearestReplacedItemIndex(sliceFrom, items);
            const sliceTo: number = index > -1 ? index : items.length;
            const str: string = items.slice(sliceFrom, sliceTo).map(val => val).join(' ');
            // последний из только что проанализированных элементов
            const last: TSourceQueryItemInProcessing | undefined = result.at(-1);
            let searchGroups: TSearchGroupsFilter | undefined;
            if (last && (typeof last !== 'string') && last.value) {
                if ((('aipPresetGroup' in last.value) && (last.value.aipPresetGroup === 'edgeTypes'))
                    || (('aipConfigGroup' in last.value) && last.value.aipConfigGroup === 'LINKED_WITH')) {
                    // после типа связи или конструкции "связанные с" ищём то что надо только среди объектов и алиасов
                    searchGroups = [TAipPresetGroup.aliasTypes, TAipPresetGroup.objectTypes];
                }
            }
            const info: TFoundInPreset | null = this.aipPreset.find(str, searchGroups);
            const item: TSourceQueryItemInProcessing = info ? { type: TProcessedItemGroup.PRESET, value: info} : items[sliceFrom];
            const sliceStartIndex: number = sliceFrom + (info ? info.source.split(' ').length : 1);
            result.push(item);
            items = items.slice(sliceStartIndex);
        } while(items.length);
        return result;
    }


    private findAndReplaceConfigItems(items: TSourceQueryItemInProcessing[], areMultiwordItems: boolean): TSourceQueryItemInProcessing[] {
        let result: TSourceQueryItemInProcessing[] = [];
        do {
            const sliceFrom: number = this.findFirstUntoutchedItemIndex(items);
            if (sliceFrom < 0) {
                // в исходном массиве больше нечего менять, возвращаем уже переработанные элементы и то что ещё не обработали
                return result.concat(items);
            }
            if (sliceFrom > 0) {
                result = result.concat(items.slice(0, sliceFrom));
            }
            const index: number = this.findTheNearestReplacedItemIndex(sliceFrom, items);
            const sliceTo: number = index > -1 ? index : items.length;
            const str: string = items.slice(sliceFrom, sliceTo).map(val => val).join(' ');

            const info: TFoundInConfig | undefined = areMultiwordItems ? this.aipConfig.findMultiwordItem(str) : this.aipConfig.findSinglewordItem(str);
            const item: TSourceQueryItemInProcessing = info ? { type: TProcessedItemGroup.CONFIG, value: info } : items[sliceFrom];
            const sliceStartIndex: number = sliceFrom + (info ? info.source.split(' ').length : 1);
            result.push(item);
            items = items.slice(sliceStartIndex);
        } while(items.length);
        return result;
    }


    // удаляем первое слово найти если оно есть, удаляем части AND_VALUE если они есть
    private removeMeaninglessItems(items: TProcessedSourceQueryItem[]) {
        return items.filter((item: TProcessedSourceQueryItem, index: number, arr: TProcessedSourceQueryItem[]) => {
            // удаляем фразу "и значением"
            if (item.type === 'CONFIG' && item.value?.aipConfigGroup === 'AND_VALUE') return false;
            // удаляем первое слово "найти" либо его синоним
            if (item.type === 'CONFIG' && item.value?.aipConfigGroup === 'COMMANDS' && item.value.tokens[0] === 'FIND') return false;
            const nextItem: TProcessedSourceQueryItem | undefined = arr[index + 1];
            if (nextItem) {
                // удаляем союз "который" или его формы, если после них стоит блок с именем связи из пресета
                if ((item.type === 'CONFIG' && item.value?.aipConfigGroup === 'CONJUNCTIONS' && item.value.tokens[0] === 'THAT')
                    && (nextItem.type === 'PRESET' && nextItem.value?.items[0].group === TAipPresetGroup.edgeTypes)) return false;
                // удаляем союз "который" или его формы, если после них стоит блок конфига с типом LINKED_WITH
                if ((item.type === 'CONFIG' && item.value?.aipConfigGroup === 'CONJUNCTIONS' && item.value.tokens[0] === 'THAT')
                    && (nextItem.type === 'CONFIG' && nextItem.value?.aipConfigGroup === 'LINKED_WITH')) return false;
            }
            return true;
        });
    }
}


export const sourceQueryProcessor = new SourceQueryProcessor(aipConfig, aipPreset);

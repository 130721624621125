import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { MessageDescriptor, useIntl } from 'react-intl';
import modelTypeMessages from '../../../../models/modelType.messages';
import objPropMessages from '../../../ObjectPropertiesDialog/messages/ObjectPropertiesDialog.messages';
import {
    EntityEnum,
    PropertiesTabKey,
    SymbolViewEnum,
    TNavigatorPropertiesData,
} from '../../../../models/navigatorPropertiesSelectorState.types';
import { PROP_KEY_EDGE_LINE_TYPE } from '../../../../models/properties/accessible-properties';
import { systemAttributeFolderType } from '../../../../models/properties/accessible-properties.constants';
import {
    AttributeValue,
    AttributeValueNode,
    AttributeValuePrincipal,
    AttributeValueUrl,
    ModelAssignment,
    NodeId,
    NodeDefinitionInstanceInfo,
    ParentModelOfObjectDefinition,
    ParentModelOfEdgeDefinition,
    AttributeValueMultiSelect,
    ModelLite,
} from '../../../../serverapi/api';
import { ContentTable } from '../../../ContentTable/components/ContentTable.component';
import { IContentTableCell } from '../../../ContentTable/ContentTable.types';
import { Tabsarea } from '../../../Tabs/components/Tabsarea/Tabsarea.component';
import { TTabsareaTabItem } from '../../../Tabs/components/Tabsarea/Tabsarea.types';
import { TreeItemType } from '../../../Tree/models/tree';
import messages from '../../messages/Navigator.messages';
import { NavigatorPanel } from '../NavigatorPanel/NavigatorPanel.component';
import navPropMessages from './NavigatorProperties.messages';
import theme from './NavigatorProperties.scss';
import { NavigatorEdgeTab } from './NavigatorEdgeTab/NavigatorEdgeTab.component';
import { TNavigatorPropertiesProps } from './NavigatorProperties.types';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import {
    sortProperties,
    storageValueToString,
    trimStringValue,
} from '../../../ObjectPropertiesDialog/components/utils';
import PrincipalAttributeValueComponent from './PrincipalAttributeValue.component';
import { openAttributeLinkAction } from '../../../../actions/openAttributeLink.actions';
import { SymbolView } from './SymbolView.component';
import Tooltip from 'antd/es/tooltip';
import { SelectOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { TNavigatorTab } from '../../../../reducers/navigator.reducer.types';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';
import { TModelTypes } from '@/services/types/NavigatorEdgePropertyBll.types';

const newTabBuidler =
    (intl: any) =>
    (key: PropertiesTabKey, messageDescriptor: MessageDescriptor): TTabsareaTabItem => ({
        id: PropertiesTabKey[key],
        title: intl.formatMessage(messageDescriptor),
    });

const NavigatorProperties = (props: TNavigatorPropertiesProps) => {
    const intl = useIntl();
    const {
        onChangeTabs,
        onModelLinkClicked,
        onChangeSymbolViewMode,
        onObjectClick,
        activeKey,
        propertiesData,
        modelAssignments,
        graphId,
        entityType,
        objectEntries,
        edgeEntries,
        hasEdgeDefinition,
        isEdgeInstance,
        edgeInstanceAttributes,
        nodeId,
        parentObjectsInfo,
        attributeTypes,
        folderTypes = [],
        principals = [],
        symbolViewMode,
        isModelTypeDeleted,
    } = props;

    const dispatch = useDispatch();

    const currentLocale = useSelector(getCurrentLocale);
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const modelTypes: TModelTypes =
        useSelector(ModelTypeSelectors.byServerIdPresetId(nodeId.serverId, presetId)).byId || [];

    const storyTabs = (): TTabsareaTabItem[] => {
        const newTab = newTabBuidler(intl);
        const attributesTab = newTab(PropertiesTabKey.ATTRIBUTES, navPropMessages.tabTitleAttributes);
        const decompositionTab = newTab(PropertiesTabKey.DEFINITION, navPropMessages.tabTitleDefinitions);
        const edgesTab = newTab(PropertiesTabKey.EDGES, navPropMessages.tabTitleEdges);
        const objectInstanceTab = newTab(PropertiesTabKey.OBJECT_INSTANCES, navPropMessages.tabObjectInstances);
        const instanceEdgesTab = newTab(PropertiesTabKey.INSTANCE_EDGES, navPropMessages.tabTitleInstInstanceEdges);
        const edgeInstanceAttributes = newTab(
            PropertiesTabKey.EDGE_INSTANCE_ATTRIBUTES,
            navPropMessages.tabTitleInstanceAttributes,
        );
        const edgeInstances = newTab(PropertiesTabKey.EDGE_INSTANCES, navPropMessages.tabObjectInstances);
        if (entityType === EntityEnum.EDGE) {
            if (hasEdgeDefinition) {
                return isEdgeInstance
                    ? [attributesTab, edgeInstanceAttributes, edgeInstances, decompositionTab]
                    : [attributesTab, edgeInstances, decompositionTab];
            }
            return [attributesTab, edgeInstanceAttributes];
        }
        if (entityType === EntityEnum.OBJECT) {
            if (graphId) {
                return [attributesTab, objectInstanceTab, decompositionTab, edgesTab, instanceEdgesTab];
            } else {
                return [attributesTab, objectInstanceTab, decompositionTab, edgesTab];
            }
        }
        if (
            entityType === EntityEnum.MODEL ||
            entityType === EntityEnum.WIKI ||
            entityType === EntityEnum.SPREADSHEET ||
            entityType === EntityEnum.MATRIX
        ) {
            return [
                attributesTab,
                newTab(PropertiesTabKey.DECOMPOSITION_PARENTS, navPropMessages.decompositionParents),
                newTab(PropertiesTabKey.CONNECTED_OBJECTS, navPropMessages.connectedObjects),
                newTab(PropertiesTabKey.CONNECTED_MODELS, navPropMessages.connectedModels),
            ];
        }

        return [attributesTab];
    };

    const onOpenUrl = (attributeValue: AttributeValueUrl) => dispatch(openAttributeLinkAction(attributeValue));

    const onOpenNode = (value: AttributeValueNode) => dispatch(openAttributeLinkAction(value, nodeId || graphId));

    const propertiesGridData = (
        properties: TNavigatorPropertiesData = {},
        keyFilter: (key: string) => boolean = () => true,
    ): IContentTableCell[][] =>
        sortProperties(properties, keyFilter).map((propertyValue) => {
            const isDeletedType = propertyValue.descriptor.typeId === 'unknown';
            const propertyType = propertyValue.descriptor.type;
            const isDeletedModelType = propertyValue.descriptor.key === 'modelTypeId' && isModelTypeDeleted;
            const title = propertyValue.descriptor.getTitle(EntityEnum[entityType ?? '']);

            const attributeValue: AttributeValue | undefined = propertyValue.value;
            const stringAttributeValue: string =
                storageValueToString(attributeValue, currentLocale, { system: propertyValue.descriptor.system }) ||
                trimStringValue(attributeValue?.value) ||
                '';

            let jsxValue;

            if (propertyType === 'URL' && attributeValue) {
                jsxValue = (
                    <div className={theme.linkWrapper} onClick={() => onOpenUrl(attributeValue)}>
                        {storageValueToString(attributeValue, currentLocale)}
                    </div>
                );
            } else if (propertyType === 'NODE' && attributeValue) {
                jsxValue = (
                    <div className={theme.linkWrapper} onClick={() => onOpenNode(attributeValue)}>
                        {storageValueToString(attributeValue, currentLocale)}
                    </div>
                );
            } else if (propertyType === 'SELECT' && attributeValue) {
                jsxValue =
                    storageValueToString(attributeValue, currentLocale, {
                        system: false,
                        attributeTypes,
                        folderTypes,
                    }) || attributeValue.value;

                if (attributeValue.typeId === systemAttributeFolderType) {
                    // если текущий атрибут является "тип папки" и такой тип папки не найден в методологии,
                    // то отображаем его id который хранится в attributeValue.value.
                    // Если id типа папки пустой, то default.
                    jsxValue = jsxValue || attributeValue.value || 'default';
                }
            } else if (propertyType === 'MULTI_SELECT' && attributeValue) {
                jsxValue =
                    storageValueToString(attributeValue, currentLocale, { system: false, attributeTypes }) ||
                    (attributeValue as AttributeValueMultiSelect).valueIds?.join(', ') ||
                    '';
            } else if (propertyType === 'PRINCIPAL' && attributeValue) {
                jsxValue = (
                    <PrincipalAttributeValueComponent
                        title={title}
                        attributeValue={attributeValue as AttributeValuePrincipal}
                        attributeTypes={attributeTypes}
                        principals={principals}
                        serverId={nodeId?.serverId || graphId?.serverId || ''}
                    />
                );
            } else if (!propertyValue.isAttributeTypeReadable) {
                jsxValue = <span className={theme.noAccessValue}>{stringAttributeValue}</span>;
            } else {
                jsxValue = stringAttributeValue;
            }

            return [
                { content: <span className={cx({ [theme.deletedType]: isDeletedType })}>{title}</span> },
                {
                    content: (
                        <div
                            data-test="properties-panel_cell_attribute-value"
                            className={cx({
                                [theme.multiLineEllipsis]: propertyType === 'MULTI_STRING',
                                [theme.deletedType]: isDeletedModelType,
                            })}
                        >
                            {jsxValue}
                        </div>
                    ),
                },
            ];
        });

    const getLinkedValue = (
        modelNodeId: NodeId,
        text: string,
        type: TreeItemType,
        elementIds?: Array<string | undefined>,
    ) => (
        <div className={theme.modelLink} onClick={() => onModelLinkClicked(modelNodeId, type, elementIds)}>
            {text}
        </div>
    );

    const objectInstancesGridData = (
        currentEntries?: ParentModelOfObjectDefinition[],
    ): Array<Array<IContentTableCell>> => {
        let result: Array<Array<IContentTableCell>> = [];

        if (currentEntries) {
            result = currentEntries
                .filter((objectModelEntry: ParentModelOfObjectDefinition) => !!objectModelEntry.modelId)
                .sort((a, b) => sortByAlpha(a.modelName, b.modelName) || sortByAlpha(a.modelTypeName, b.modelTypeName))
                .map((objectModelEntry: ParentModelOfObjectDefinition) => {
                    const { modelId, modelName, modelTypeName, entryCount, objectInstanceInfoList } = objectModelEntry;
                    const modelLink = getLinkedValue(
                        { ...nodeId, id: modelId! } as NodeId,
                        modelName ? `${modelName} [${modelTypeName}]` : 'Noname object model entry',
                        TreeItemType.Model,
                        objectInstanceInfoList?.map((inst) => inst.elementId),
                    );

                    return [
                        { content: modelLink },
                        {
                            content: (
                                <SymbolView
                                    objectInstanceInfoList={objectInstanceInfoList}
                                    modelId={modelId}
                                    entryCount={entryCount}
                                />
                            ),
                            editable: true,
                        },
                    ];
                });
        }

        return result;
    };

    const edgeInstancesGridData = (currentEntries?: ParentModelOfEdgeDefinition[]): Array<Array<IContentTableCell>> => {
        let result: Array<Array<IContentTableCell>> = [];
        if (currentEntries) {
            result = currentEntries
                .filter((edgeModelEntry: ParentModelOfEdgeDefinition) => !!edgeModelEntry.modelId)
                .map((edgeModelEntry: ParentModelOfEdgeDefinition) => {
                    const { modelId, modelName, modelTypeName, entryCount, edgeInstanceInfoList } = edgeModelEntry;
                    const modelLink = getLinkedValue(
                        { ...nodeId, id: modelId! } as NodeId,
                        modelName
                            ? `${modelName} [${modelTypeName}]`
                            : intl.formatMessage(messages.noNameEdgeModelEntry),
                        TreeItemType.Model,
                        edgeInstanceInfoList?.map((inst) => inst.elementId),
                    );

                    return [
                        { content: modelLink },
                        {
                            content: <SymbolView modelId={modelId} entryCount={entryCount} />,
                            editable: true,
                        },
                    ];
                });
        }

        return result;
    };

    const decompositionParentsGridData = (): Array<Array<IContentTableCell>> => {
        if (!parentObjectsInfo) return [];

        return parentObjectsInfo
            .sort((a, b) => sortByAlpha(a.nodeName, b.nodeName) || sortByAlpha(a.elementType, b.elementType))
            .reduce((acc: IContentTableCell[][], parent: NodeDefinitionInstanceInfo) => {
                const { elementType, nodeName } = parent;
                const objName = elementType ? `${nodeName || ''} (${elementType})` : nodeName;
                if (parent.nodeAllocations?.length) {
                    parent.nodeAllocations?.forEach((model) => {
                        const modelNodeId: NodeId = { ...nodeId, id: model.modelId } as NodeId;

                        acc.push([
                            { content: objName },
                            {
                                content: getLinkedValue(modelNodeId, model.modelName || '', TreeItemType.Model),
                            },
                        ]);
                    });
                } else {
                    acc.push([{ content: objName }, { content: <div>{intl.formatMessage(messages.noInstance)}</div> }]);
                }

                return acc;
            }, []);
    };

    const connectedObjectsGridData = (): Array<Array<IContentTableCell>> => {
        if (!parentObjectsInfo) return [];

        const renderClickedElement = (text: string, nodeId: NodeId) => (
            <div className={theme.modelLink} onClick={() => onObjectClick(nodeId)}>
                {text}
            </div>
        );

        return parentObjectsInfo
            .sort((a, b) => sortByAlpha(a.nodeName, b.nodeName) || sortByAlpha(a.elementType, b.elementType))
            .filter((parent) => parent.nodeType === 'OBJECT')
            .map((parent) => {
                const { elementType, nodeName = '' } = parent;
                const objectNodeId: NodeId = { ...nodeId, id: parent.nodeId } as NodeId;

                return [{ content: renderClickedElement(nodeName, objectNodeId) }, { content: elementType }];
            }, []);
    };

    const connectedModelsGridData = (): Array<Array<IContentTableCell>> => {
        if (!parentObjectsInfo) return [];

        const allocatedModels: ModelLite[] = parentObjectsInfo.reduce(
            (acc: ModelLite[], parent: NodeDefinitionInstanceInfo) => {
                parent.nodeAllocations?.forEach((currentModel) => {
                    if (!acc.some((model) => model.modelId === currentModel.modelId)) {
                        acc.push(currentModel);
                    }
                });

                return acc;
            },
            [],
        );

        return allocatedModels.map((model) => {
            const modelNodeId: NodeId = { ...nodeId, id: model.modelId } as NodeId;
            const modelTypeName: string = model.modelTypeId ? modelTypes[model.modelTypeId]?.name || '' : '';

            return [
                { content: getLinkedValue(modelNodeId, model.modelName || '', TreeItemType.Model) },
                {
                    content: modelTypeName,
                },
            ];
        });
    };

    const modelAssignmentsGridData = (assignments: ModelAssignment[]): Array<Array<IContentTableCell>> => {
        return assignments
            .filter((assignment: ModelAssignment) => !!assignment.modelId)
            .map((assignment: ModelAssignment) => {
                const { modelId, modelName, modelTypeName, nodeType } = assignment;
                const text = modelName || 'Noname assignment';
                const modelNodeId: NodeId = graphId ? { ...graphId, id: modelId! } : { ...nodeId!, id: modelId! };
                const type: TreeItemType = assignment.nodeType as TreeItemType;
                const modelLink = getLinkedValue(modelNodeId, text, type);
                const modelTypeNameLocale =
                    nodeType === TreeItemType.Matrix ||
                    nodeType === TreeItemType.Wiki ||
                    nodeType === TreeItemType.Spreadsheet ||
                    nodeType === TreeItemType.Kanban
                        ? intl.formatMessage(modelTypeMessages[nodeType])
                        : modelTypeName;

                return {
                    modelLink,
                    modelName,
                    modelTypeNameLocale,
                };
            })
            .sort(
                (a, b) =>
                    sortByAlpha(a.modelName, b.modelName) || sortByAlpha(a.modelTypeNameLocale, b.modelTypeNameLocale),
            )
            .map((obj) => {
                const { modelLink, modelTypeNameLocale } = obj;
                return [{ content: modelLink }, { content: modelTypeNameLocale, editable: true }];
            });
    };

    const edgeInstanceAttributesGridData = (attributes: AttributeValue[] = []): IContentTableCell[][] => {
        return attributes.map((attribute) => {
            const attributeType = attributeTypes.find((type) => type.id === attribute.typeId);
            const title = attributeType?.name;
            const stringAttributeValue: string =
                storageValueToString(attribute, currentLocale, { system: false, attributeTypes, principals }) ||
                trimStringValue(attribute.value) ||
                '';
            const isMultiString: boolean = attribute.valueType === 'MULTI_STRING';
            const isLink: boolean = attribute.valueType === 'URL' || attribute.valueType === 'NODE';
            const className: string = (isMultiString && theme.multiLineEllipsis) || (isLink && theme.linkWrapper) || '';
            const onClickLink = () => isLink && dispatch(openAttributeLinkAction(attribute, graphId || nodeId));

            return [
                { content: title },
                {
                    content: (
                        <div
                            data-test="properties-panel_cell_attribute-value"
                            className={className}
                            onClick={onClickLink}
                        >
                            {stringAttributeValue}
                        </div>
                    ),
                },
            ];
        });
    };

    const contentTableData = (): Array<Array<IContentTableCell>> => {
        if (!propertiesData) return [];

        switch (activeKey) {
            case PropertiesTabKey.ATTRIBUTES:
                return propertiesGridData(propertiesData, (k) => k !== PROP_KEY_EDGE_LINE_TYPE);
            case PropertiesTabKey.DEFINITION:
                return modelAssignmentsGridData(modelAssignments);
            case PropertiesTabKey.PROPERTIES:
                return propertiesGridData(propertiesData, (k) => k === PROP_KEY_EDGE_LINE_TYPE);
            case PropertiesTabKey.OBJECT_INSTANCES:
                return objectInstancesGridData(objectEntries);
            case PropertiesTabKey.EDGE_INSTANCES:
                return edgeInstancesGridData(edgeEntries);
            case PropertiesTabKey.DECOMPOSITION_PARENTS:
                return decompositionParentsGridData();
            case PropertiesTabKey.CONNECTED_OBJECTS:
                return connectedObjectsGridData();
            case PropertiesTabKey.CONNECTED_MODELS:
                return connectedModelsGridData();
            case PropertiesTabKey.EDGE_INSTANCE_ATTRIBUTES:
                return edgeInstanceAttributesGridData(edgeInstanceAttributes);
            default:
                return [];
        }
    };

    const objInstIcon = (
        <Tooltip
            mouseLeaveDelay={0}
            className={theme.expandIcon}
            title={intl.formatMessage(objPropMessages.changeMode)}
        >
            {symbolViewMode === SymbolViewEnum.SYMBOLS ? (
                <UnorderedListOutlined className={theme.expandIcon} onClick={onChangeSymbolViewMode} />
            ) : (
                <SelectOutlined className={theme.expandIcon} onClick={onChangeSymbolViewMode} />
            )}
        </Tooltip>
    );

    const contentTableHeader = (): (string | JSX.Element)[] => {
        if (activeKey === PropertiesTabKey.OBJECT_INSTANCES) {
            const objInstancesMessage =
                symbolViewMode === SymbolViewEnum.SYMBOLS ? objPropMessages.symbols : objPropMessages.count;
            const symbolsTitle = (
                <span className={theme.symbolsTitle}>
                    {intl.formatMessage(objInstancesMessage)}
                    {objInstIcon}
                </span>
            );

            return [intl.formatMessage(objPropMessages.modelName), symbolsTitle];
        }

        if (activeKey === PropertiesTabKey.EDGE_INSTANCES) {
            return [intl.formatMessage(objPropMessages.modelName), intl.formatMessage(objPropMessages.count)];
        }

        if (activeKey === PropertiesTabKey.DECOMPOSITION_PARENTS) {
            return [
                intl.formatMessage(navPropMessages.tabColumnObjectTitleName),
                intl.formatMessage(navPropMessages.tabColumnModelTitleName),
            ];
        }

        if (activeKey === PropertiesTabKey.CONNECTED_OBJECTS) {
            return [
                intl.formatMessage(navPropMessages.tabTitleObjectName),
                intl.formatMessage(navPropMessages.tabTitleObjectType),
            ];
        }

        if (activeKey === PropertiesTabKey.CONNECTED_MODELS) {
            return [
                intl.formatMessage(navPropMessages.tabTitleModelName),
                intl.formatMessage(navPropMessages.tabTitleModelType),
            ];
        }

        if (activeKey === PropertiesTabKey.DEFINITION) {
            return [intl.formatMessage(objPropMessages.modelName), intl.formatMessage(objPropMessages.modelType)];
        }

        return [
            intl.formatMessage(navPropMessages.tabColumnTitleName),
            intl.formatMessage(navPropMessages.tabColumnTitleValue),
        ];
    };

    return (
        <NavigatorPanel
            type={TNavigatorTab.Properties}
            titleProps={{
                title: intl.formatMessage(messages.properties),
            }}
        >
            <Tabsarea
                tabs={storyTabs()}
                onChangeTab={(key) => onChangeTabs(PropertiesTabKey[key])}
                activeKey={activeKey}
            >
                {activeKey === PropertiesTabKey.INSTANCE_EDGES || activeKey === PropertiesTabKey.EDGES ? (
                    <NavigatorEdgeTab
                        graphId={graphId}
                        nodeId={nodeId}
                        cellId={props.cellId}
                        showAllEdgesOnModel={activeKey === PropertiesTabKey.EDGES}
                    />
                ) : (
                    <ContentTable data={contentTableData()} headerTitles={contentTableHeader()} />
                )}
            </Tabsarea>
        </NavigatorPanel>
    );
};

export { NavigatorProperties };

import type { Editor } from '@tiptap/react';
import icListIndentIncrease from 'icons/toolbar/controls/ic-indent-increase.svg';
import { useIntl } from 'react-intl';
import { SyntheticEvent, useContext } from 'react';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { StateObserver } from '../../common/sharedState.class';
import messages from '../CommonToolbar.messages';
import React from 'react';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';

const BULLET_LIST_ITEM = 'bullet-list-item';

const ORDERED_LIST_ITEM = 'ordered-list-item';

const TASK_LIST_ITEM = 'task-list-item';

type TListsValue = {
    [key: string]: boolean;
};

const itemsIdMap = {
    [BULLET_LIST_ITEM]: 'listItem',
    [ORDERED_LIST_ITEM]: 'listItem',
    [TASK_LIST_ITEM]: 'taskItem',
};

const getActiveLists = (editor: Editor) => ({
    [BULLET_LIST_ITEM]: editor.isActive('bulletList'),
    [ORDERED_LIST_ITEM]: editor.isActive('orderedList'),
    [TASK_LIST_ITEM]: editor.isActive('taskList'),
});

const getChangedBlock = (editor: Editor) => {
    const activeLists = getActiveLists(editor);
    const activeListItemKey = Object.keys(activeLists).find((key) => activeLists[key]) || 'listItem';

    return editor.chain().focus().sinkListItem(itemsIdMap[activeListItemKey]).run();
};

const getIsIndentDisabled = (editor: Editor | undefined): boolean => {
    if (!editor) {
        return false;
    }

    const canSinkListItem = editor.can().sinkListItem('listItem');
    const canSinkTaskItem = editor.can().sinkListItem('taskItem');

    return !canSinkListItem && !canSinkTaskItem;
};

export const SinkListItem = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, editor } = useSharedState<TListsValue, SyntheticEvent<HTMLElement, Event>>({
        stateObserver,
        getChangedBlock,
    });
    const isIndentDisabled = getIsIndentDisabled(editor);

    return (
        <ToolbarButton
            icon={icListIndentIncrease}
            tooltip={intl.formatMessage(messages.sinkListItem)}
            onClick={setCurrentState}
            dataTest={'wiki-toolbar-group_lists-button_indent'}
            disabled={isIndentDisabled}
            size="small"
        />
    );
};

import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { BPMMxCellHighlight } from '@/mxgraph/handler/BPMMxCellHighlight';
import { BPMMxGraphHandler } from '@/mxgraph/handler/BPMMxGraphHandler';
import { isCommentCell } from '@/utils/bpm.mxgraph.utils';
import { MxCell, MxConstants, MxEvent, MxMouseEvent } from 'MxGraph';

class GridModelGraphHandler extends BPMMxGraphHandler {
    private invalidHightlight: BPMMxCellHighlight | null = null;
    guidesEnabled = true;

    mouseMove(sender: BPMMxGraph, evt: MxMouseEvent) {
        super.mouseMove(sender, evt);

        if (this.target || !this.graph.isMouseDown || !this.highlight) {
            this.invalidHightlight?.hide();
            return;
        }

        const cell = evt.getCell();
        const state = this.graph.getView().getState(cell);

        this.invalidHightlight =
            this.invalidHightlight || new BPMMxCellHighlight(this.graph, MxConstants.INVALID_COLOR, 3);

        this.invalidHightlight.highlight(state);
    }

    isValidDropTarget(target: MxCell | null, me: MouseEvent) {
        return true;
    }

    mouseUp(sender: BPMMxGraph, me: MxMouseEvent) {
        const { target } = this;
        if (Number(this.currentDx) === this.currentDx && Number(this.currentDy) === this.currentDy && !target) {
            this.consumeMouseEvent(MxEvent.MOUSE_UP, me);
            this.reset();

            return;
        }
        super.mouseUp(sender, me);
    }
    moveCells(cells: MxCell[], dx: number, dy: number, clone: boolean, target: MxCell | null, evt?: Event): void {
        if (!target) {
            return;
        }

        const [source] = cells.filter(this.graph.isSupportedCell) || [];

        if (!isCommentCell(source) && !this.graph.isGridValidTarget({ target, source })) {
            return;
        }

        const result = super.moveCells(cells, dx, dy, clone, target, evt);
        const [objectCell] = cells.filter(this.graph.isSupportedCell) || [];

        if (objectCell) {
            const model = this.graph.getModel();
            model.beginUpdate();
            try {
                this.graph.renderEdges(objectCell);
            } finally {
                model.endUpdate();
            }
        }

        if (!source) {
            return result;
        }

        return result;
    }
}

export default GridModelGraphHandler;

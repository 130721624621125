import React from 'react';
import { Checkbox } from '../Checkbox/Checkbox.component';
import theme from './TableUIKitComponent.scss';
import { TCheckboxStatus } from '../Checkbox/Checkbox.types';

type TColumnHeaderWithCheckBoxProps = {
    isChecked: boolean;
    isIndeterminate: boolean;
    handleChange: (status: TCheckboxStatus) => void;
};

export const ColumnHeaderWithCheckBox = (props: TColumnHeaderWithCheckBoxProps): JSX.Element => {
    const { isChecked, isIndeterminate, handleChange } = props;
    const status: TCheckboxStatus = isIndeterminate ? 'indeterminate' : isChecked;

    return (
        <div className={theme.headerCell}>
            <div className={theme.headerCheckBoxContainer}>
                <Checkbox
                    onChange={handleChange}
                    status={status}
                />
            </div>
        </div>
    );
};

import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId, KanbanCardType, Symbol } from '../serverapi/api';
import { TKanbanNodeState } from '../reducers/entities/kanban.reducer.types';
import { KANBAN_COLUMN_ITEM_TYPE, TKanbanSymbolCardItem, TKanbanState } from '@/models/kanban.types';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { KanbanCardTypeSelectors } from './kanbanCardType.selectors';
import { SymbolSelectors } from './symbol.selectors';
import { getNodeName } from '@/modules/Navigator/navigatorUtils/navigatorTreeSearch.utils';

const kanbanStateSelector = (state: TRootState): TKanbanNodeState => state.kanban;

const getNamedSymbolSvg = (svg: string, name: string): string => {
    const regex = /class="cont">([\s\S]*?)<\/div>/;

    if (svg.match(regex)) {
        return svg.replace(regex, `class="cont"> ${name} </div>`);
    }

    return svg;
};

export namespace KanbanSelectors {
    export const byId = (nodeId: NodeId) => createSelector(kanbanStateSelector, (state) => state.byNodeId.get(nodeId));

    export const getKanbanColumnData = (kanbanNodeId: NodeId, presetId: string, columnId: string) =>
        createSelector(
            byId(kanbanNodeId),
            ObjectDefinitionSelectors.byServerIdRepositoryId(kanbanNodeId.serverId, kanbanNodeId.repositoryId),
            KanbanCardTypeSelectors.listAllByPreset(presetId),
            SymbolSelectors.byServerIdPresetId(kanbanNodeId.serverId, presetId),
            (
                kanban: TKanbanState,
                objectDefinitions: {
                    [key: string]: ObjectDefinitionImpl;
                },
                kanbanCardTypes: KanbanCardType[],
                symbols: Symbol[],
            ): TKanbanSymbolCardItem[] => {
                return kanban.columnIdToColumnItemIdsMap[columnId].map((columnItemId) => {
                    const columnItem = kanban.columnItems[columnItemId];
                    const objectDefinition = objectDefinitions[columnItem.objectDefinitionId] || {};

                    if (columnItem.type === KANBAN_COLUMN_ITEM_TYPE.SYMBOL) {
                        const symbol = symbols.find((s) => s.id === columnItem.symbolCardTypeId);
                        return {
                            id: columnItem.id,
                            type: columnItem.type,
                            objectDefinitionId: columnItem.objectDefinitionId,
                            symbolSvg: getNamedSymbolSvg(symbol?.symbolSvg || '', getNodeName(objectDefinition)),
                            allowedColumnIds: columnItem.allowedColumnIds,
                            forbiddenColumnIds: columnItem.forbiddenColumnIds,
                        };
                    }

                    return {
                        id: columnItem.id,
                        type: columnItem.type,
                        objectDefinitionId: columnItem.objectDefinitionId,
                        attributes: objectDefinition.attributes || [],
                        kanbanCardType: kanbanCardTypes.find((card) => card.id === columnItem.symbolCardTypeId),
                        allowedColumnIds: columnItem.allowedColumnIds,
                        forbiddenColumnIds: columnItem.forbiddenColumnIds,
                    };
                });
            },
        );
}

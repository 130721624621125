import { UITemplateTabDTO, WidgetDTO, WidgetGeometry } from '@/serverapi/api';

export const getCellContainerId = (id: string, preview?: boolean) => {
    return `cells_container_${id}${preview ? '_preview' : ''}`;
};

export const fixWidgetGeometry = (newGeometry: WidgetGeometry, prevGeometry: WidgetGeometry): WidgetGeometry => {
    const { x = 0, y = 0, height = 0, width = 0 } = newGeometry;

    let newX: number = x < 0 ? 0 : x;
    const newY: number = y < 0 ? 0 : y;

    return { height, width, x: newX, y: newY };
};

export const getWidgetsByTab = (widgets: WidgetDTO[], tab: UITemplateTabDTO) => {
    return widgets.filter((widget) => tab.widgetsIds.includes(widget.id)) || [];
};

export const getWidgetsContainerHeight = (cellSize: number, widgets: WidgetDTO[]) => {
    let containerHeight = 0;
    widgets.forEach(({ geometry: { height = 0, y = 0 } }) => {
        containerHeight = Math.max(containerHeight, (height + y) * cellSize);
    });

    return containerHeight;
};

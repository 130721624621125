import { defineMessages } from 'react-intl';

export default defineMessages({
    delete: {
        id: 'ActionButtons.delete',
        defaultMessage: 'Удалить',
    },
    edit: {
        id: 'ActionButtons.edit',
        defaultMessage: 'Редактировать',
    },
    deleteQuestion: {
        id: 'ActionButtons.deleteQuestion',
        defaultMessage: 'Вы действительно хотите удалить шаблон "{name}"?',
    },
});

import React, { Dispatch, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntlShape, useIntl } from 'react-intl';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import theme from './SearchDuplicates.scss';
import messages from './messages/duplicatesTable.messages';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { findDuplicatesDataRequest } from '@/actions/findDuplicates.actions';
import { NodeId } from '@/serverapi/api';
import { Divider } from 'antd';

type TSearchDuplicatesProps = {
    nodeId: NodeId;
    isFetchingDuplicatesInProgress: boolean;
}

export const SearchDuplicates: FC<TSearchDuplicatesProps> = ({ nodeId, isFetchingDuplicatesInProgress }) => {
    const path: string = useSelector(TreeSelectors.nodePath(nodeId));
    const [findOnlyChildDuplicates, setFindOnlyChildDuplicates] = useState<boolean>(false);
    const intl: IntlShape = useIntl();
    const dispatch: Dispatch<any> = useDispatch();

    const handleSearch = () => {
        dispatch(findDuplicatesDataRequest(nodeId, findOnlyChildDuplicates));
    };

    const handleChange = () => {
        setFindOnlyChildDuplicates((prev) => !prev);
    };

    return (
        <>
            <p className={theme.path}>{`${intl.formatMessage(messages.path)} ${path}`}</p>
            <div className={theme.container}>
                <Checkbox
                    disabled={isFetchingDuplicatesInProgress}
                    status={findOnlyChildDuplicates}
                    onChange={handleChange}
                >
                    {intl.formatMessage(messages.findOnlyChildDuplicates)}
                </Checkbox>
                <Button
                    key="searchButton"
                    size="large"
                    visualStyle="primary"
                    disabled={isFetchingDuplicatesInProgress}
                    onClick={handleSearch}
                >
                    {intl.formatMessage(messages.search)}
                </Button>
            </div>
            <Divider />
        </>
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    generalSettings: {
        id: 'WikiModelType.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    name: {
        id: 'WikiModelType.name',
        defaultMessage: 'Название',
    },
    description: {
        id: 'WikiModelType.description',
        defaultMessage: 'Описание',
    },
    modelTypeGroups: {
        id: 'WikiModelType.modelTypeGroups',
        defaultMessage: 'Группа типа модели',
    },
    attributes: {
        id: 'WikiModelType.attributes',
        defaultMessage: 'Атрибуты Wiki',
    },
    navigatorTitle: {
        id: 'WikiModelType.navigatorTitle',
        defaultMessage: 'Тип модели',
    },
    enableModel: {
        id: 'WikiModelType.enableModel',
        defaultMessage: 'Видимость типа модели при создании',
    },
    allowApprovals: {
        id: 'WikiModelType.allowApprovals',
        defaultMessage: 'Разрешить согласование типа модели',
    },
    modelType: {
        id: 'WikiModelType.modelType',
        defaultMessage: 'Вид модели',
    },
    wiki: {
        id: 'WikiModelType.wiki',
        defaultMessage: 'Wiki',
    },
});

import React, { useContext, useState } from 'react';
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import { ColorResult, SketchPicker } from 'react-color';
import ControlsContext from '../Controls.context';
import icFillColor from '@/resources/icons/MainMenuFill-Color.svg';
import { DEFAULT_BG_COLOR } from './Controls.constants';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';

const getFocusedBlock = (editorState) => {
    const bgColorStyle = getSelectionCustomInlineStyle(editorState, ['BGCOLOR'])?.BGCOLOR;

    if (bgColorStyle) {
        return bgColorStyle.replace('bgcolor-', '');
    }

    return DEFAULT_BG_COLOR;
};

const getChangedBlock = (editorState, value) => toggleCustomInlineStyle(editorState, 'bgcolor', value);

const BGColorComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState: setCurrentStateForFill } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const [currentFillColor, setCurrentFillColor] = useState(DEFAULT_BG_COLOR);
    const selectFillColorHandler = (colorObject: ColorResult) => {
        const { hex } = colorObject;
        setCurrentFillColor(hex);
        setCurrentStateForFill(hex);
    };

    return (
        <ToolbarButton
            icon={icFillColor}
            dataTest="wiki-toolbar-group_font-bgColor-button"
            tooltip={intl.formatMessage(messages.colorFilling)}
            overlay={<SketchPicker color={currentFillColor} onChange={selectFillColorHandler} disableAlpha />}
            size="small"
        />
    );
};

export default BGColorComponent;

import { defineMessages } from 'react-intl';

export default defineMessages({
    new: {
        id: 'UITemplatesSaga.new',
        defaultMessage: 'Создание шаблона',
    },
    edit: {
        id: 'UITemplatesSaga.edit',
        defaultMessage: 'Редактирование шаблона',
    },
});

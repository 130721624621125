import React from 'react';
import { Input } from 'antd';
import theme from './NavigatorSymbols.scss';
import messages from '../../messages/Navigator.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavigatorSymbolsSearchSelector } from '@/selectors/navigatorSymbolsSearch.selectors';
import { changeNavigatorSymbolsSearchString } from '@/actions/navigatorSymbolsSearch.actions';

type TSymbolsSearchFieldProps = {
    repositoryId: string;
    id: string;
};

export const SymbolsSearchField = (props: TSymbolsSearchFieldProps) => {
    const { repositoryId, id } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const searchString: string = useSelector(NavigatorSymbolsSearchSelector.getSearchString(repositoryId, id));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeNavigatorSymbolsSearchString({ repositoryId, id, searchString: e.target.value }));
    };

    return (
        <div className={theme.inputContainer}>
            <Input
                onChange={handleChange}
                value={searchString}
                autoFocus
                placeholder={`${intl.formatMessage(messages.toSearch)}...`}
            />
        </div>
    );
};


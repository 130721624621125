import { TNavigatorSymbolsSearchState } from '@/reducers/navigatorSymbolsSearch.reducer.types';
import { TRootState } from '@/reducers/root.reducer.types';
import { createSelector } from 'reselect';

export const navigatorSymbolsSearchStateSelector = (state: TRootState) => state.navigatorSymbolsSearch;

export namespace NavigatorSymbolsSearchSelector {
    export const getIsSearchActive = (repositoryId: string, id: string) =>
        createSelector<TRootState, TNavigatorSymbolsSearchState, boolean>(
            navigatorSymbolsSearchStateSelector,
            (state) => state?.[repositoryId]?.[id]?.isActive || false,
        );

    export const getSearchString = (repositoryId: string, id: string) =>
        createSelector<TRootState, TNavigatorSymbolsSearchState, string>(
            navigatorSymbolsSearchStateSelector,
            (state) => state?.[repositoryId]?.[id]?.searchString || '',
        );
}

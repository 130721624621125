export const MAX_TOOLTIP_WIDTH = 250;

export const MIN_TOOLTIP_WIDTH = 32;

export const MIN_TOOLTIP_HEIGHT = 32;

export const TOOLTIP_OFFSET = 6;

export const VIEW_OFFSET = 5;

export const TOOLTIP_RC_ID = 'tooltip_RC_ID';

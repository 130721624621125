import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { PRESET_SETTINGS_INITIAL_WIKI_MODEL_TYPE_SERVER_STATE } from '../../reducers/presetSettings/presetSettingsWikiModelType.reducer';
import {
    IPresetSettingsWikiModelTypeData,
    TPresetSettingsWikiModelTypeState,
} from '../../reducers/presetSettings/presetSettingsWikiModelType.reducer.types';

const wikiModelTypeStateSelector = (state: TRootState) => {
    return state.presetSettings.wikiModelTypes;
};

export namespace PresetSettingsWikiModelTypeSelectors {
    export const byPresetId = (presetId: string) =>
        createSelector<TRootState, TPresetSettingsWikiModelTypeState, IPresetSettingsWikiModelTypeData>(
            wikiModelTypeStateSelector,
            (state) => {
                return state?.byPresetId?.[presetId] || PRESET_SETTINGS_INITIAL_WIKI_MODEL_TYPE_SERVER_STATE;
            },
        );
}

import { TWord } from "./types/common.types";

export class AipToolbox {

    static getWordsFromString(str: string): TWord[] {
        return str
            .trim()
            .replaceAll('\n', ' ')
            .split(' ')
            .map(word => word.trim())
            .filter(word => word);
    }
}

import type { Editor } from '@tiptap/react';
import icListBulleted from 'icons/toolbar/controls/ic-format-list-bulleted.svg';
import { useIntl } from 'react-intl';
import { SyntheticEvent, useContext } from 'react';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { StateObserver } from '../../common/sharedState.class';
import messages from '../CommonToolbar.messages';
import React from 'react';
import { ToolbarButton } from '../../../Toolbar/ToolbarButton/ToolbarButton.component';

const getFocusedBlock = (editor: Editor) => editor.isActive('bulletList');
const getChangedBlock = (editor: Editor) => {
    return editor.chain().focus().toggleBulletList().run();
};

export const BulletList = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<boolean, SyntheticEvent<HTMLElement, Event>>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <ToolbarButton
            icon={icListBulleted}
            tooltip={intl.formatMessage(messages.bulletList)}
            onClick={setCurrentState}
            dataTest={'wiki-toolbar-group_lists-button_bulleted'}
            isActive={value}
            size="small"
        />
    );
};

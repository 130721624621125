import React from 'react';
import { Form } from 'antd';
import theme from './SystemProperties.scss';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';

type TProps = {
    agreementTypeActive: boolean | undefined;
    label: React.ReactNode;
    name: 'isActiveSilaAgreement' | 'isActiveAgreement';
    isFirstLabel?: boolean;
    onChange: () => void;
};

export const MultiLangInputDialogLabel: React.FC<TProps> = ({ agreementTypeActive, label, name, isFirstLabel, onChange }) => {
    return (
        <Form.Item
            initialValue={agreementTypeActive}
            name={name}
            label={label}
            colon={false}
            className={isFirstLabel ? theme.agreementLabelWrapperFirstLabel : theme.agreementLabelWrapper}
            valuePropName="checked"
        >
            <Checkbox onChange={onChange} />
        </Form.Item>
    );
};

import { createSelector } from 'reselect';
import { TreeItemType } from '../modules/Tree/models/tree';
import { TRootState } from '../reducers/root.reducer.types';
import { TSearchDataListItem, TSearchReducerState, TSearcParams } from '../reducers/search.reducer.types';
import { NodeId, SearchRequestNodeTypesEnum, SearchRequestSearchVisibilityEnum } from '../serverapi/api';
import { ISearchRuleWithValueId } from '@/modules/Search/AttributeFilter/AttributeFilter.types';
import { uniq } from 'lodash-es';
import { compareNodes } from '@/models/tree';

const getUniqueTypes = (res: TSearchDataListItem[]): TreeItemType[] => Array.from(new Set(res.map((el) => el.type)));

const searchSelector = (state: TRootState): TSearchReducerState => state.search;
export namespace SearchSelectors {
    export const getSearchPaths = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, string[]>(
            searchSelector,
            (searchState) => searchState.get(searchNodeId)?.searchPaths || [],
        );

    export const getSearchResult = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, TSearchDataListItem[]>(
            searchSelector,
            (searchState) => searchState.get(searchNodeId)?.searchResult || [],
        );

    export const getLoadingStatus = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, boolean>(
            searchSelector,
            (searchState) => !!searchState.get(searchNodeId)?.isLoading,
        );

    export const getFilterSearchResult = (searchNodeId: NodeId, rootSearchNodeIds: NodeId[] = []) =>
        createSelector<TRootState, TSearchDataListItem[], TSearchDataListItem[]>(
            getSearchResult(searchNodeId),
            (searchResults) => {
                const repositoryIdOrder: string[] = uniq(rootSearchNodeIds.map((nodeId) => nodeId.repositoryId));
                const uniqueTypes: TreeItemType[] = getUniqueTypes(searchResults);
                const filterSearchResult: TSearchDataListItem[] = searchResults
                    .filter((el) => !uniqueTypes.length || uniqueTypes.includes(el.type))
                    .sort(compareNodes)
                    .sort(
                        ({ nodeId: { repositoryId: r1 } }, { nodeId: { repositoryId: r2 } }) =>
                            repositoryIdOrder.findIndex((r) => r === r1) - repositoryIdOrder.findIndex((r) => r === r2),
                    );

                return filterSearchResult;
            },
        );

    export const getSearchRules = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, ISearchRuleWithValueId[]>(
            searchSelector,
            (searchState) => searchState.get(searchNodeId)?.searchParams?.searchRules || [],
        );

    export const getSearchNodeTypes = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, SearchRequestNodeTypesEnum[]>(
            searchSelector,
            (searchState) => searchState.get(searchNodeId)?.searchParams?.nodeTypes || [],
        );

    export const getSearchText = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, string>(
            searchSelector,
            (searchState) => searchState.get(searchNodeId)?.searchParams?.searchText || '',
        );

    export const getSearchVisibility = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, SearchRequestSearchVisibilityEnum>(
            searchSelector,
            (searchState) => searchState.get(searchNodeId)?.searchParams?.searchVisibility || 'NOT_DELETED',
        );

    export const getRootSearchNodeIds = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, NodeId[]>(
            searchSelector,
            (searchState) => searchState.get(searchNodeId)?.searchParams?.rootSearchNodeIds || [],
        );

    export const getSearchParams = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, TSearcParams | undefined>(
            searchSelector,
            (searchState) => searchState.get(searchNodeId)?.searchParams,
        );

    export const getFoundElementsCount = (searchNodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, number | undefined>(
            searchSelector,
            (searchState) => searchState.get(searchNodeId)?.foundElementsCount,
        );
}

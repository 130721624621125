import React from 'react';
import { ToolbarButton } from '../../../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import { useDispatch, useSelector } from 'react-redux';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarDropdown } from '../../../../../UIKit/components/Toolbar/ToolbarDropdown/ToolbarDropdown.component';
import { useIntl } from 'react-intl';
import { BpmNodeFilterBase, BpmNodeFilterBaseIcons } from '../../../../../../models/bpmNodeFilterBase';
import { NodeId } from '../../../../../../serverapi/api';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';
import { workspaceTabSetParams } from '../../../../../../actions/tabs.actions';
import messages from '@/modules/MainMenu/messages/CommonToolbar.messages';

export const FiltrationDropdown = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const activeTabNodeId: NodeId | undefined = useSelector(TabsSelectors.getActiveTabId);
    const tooltip: string = useSelector(EditorToolbarSelectors.getFiltrationDropdownTooltip);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getFiltrationDropdownDisabled);
    const nodeFilterBase: BpmNodeFilterBase =
        useSelector(TabsSelectors.getActiveTabNodeFilterBase) || BpmNodeFilterBase.Off;

    const onFilterNodeBaseAction = (nodeFilterBase: BpmNodeFilterBase) => {
        activeTabNodeId && dispatch(workspaceTabSetParams(activeTabNodeId, { nodeFilterBase }));
    };
    const filterButtons: JSX.Element[] = Object.keys(BpmNodeFilterBase)
        .filter((prop) => isNaN(parseInt(prop)))
        .map((key) => {
            return (
                <ToolbarButton
                    icon={BpmNodeFilterBaseIcons[key]}
                    tooltip={intl.formatMessage(messages[`NodeFilter${key}`])}
                    onClick={() => onFilterNodeBaseAction(BpmNodeFilterBase[key])}
                    isActive={nodeFilterBase === BpmNodeFilterBase[key]}
                    dataTest={`main-panel_button-filtering${key}`}
                />
            );
        });

    return (
        <ToolbarDropdown
            options={filterButtons}
            icon={BpmNodeFilterBaseIcons[BpmNodeFilterBase[nodeFilterBase]]}
            size="small"
            tooltip={tooltip}
            disabled={disabled}
            dataTest="main-panel_button-filtering"
        />
    );
};

import { Locale } from '@/modules/Header/components/Header/header.types';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { LifelineSymbolMainClass } from '@/mxgraph/ComplexSymbols/symbols/LifeLine/LifelineSymbolMain.class';
import SequenceUtils from '@/mxgraph/ComplexSymbols/symbols/Sequence/sequence.utils';
import { SequenceEdgeTypesId } from '@/mxgraph/SequenceGraph/SequenceConstants';
import { LocalesService } from '@/services/LocalesService';
import { MxCell, MxPopupMenu } from 'MxGraph';
import messages from '@/modules/Editor/messages/EditorPopupMenu.messages';
import { EdgeType, ModelNode, ModelType, UserDTOAccessesEnum } from '@/serverapi/api';
import { UMLMessage } from '@/mxgraph/ComplexSymbols/symbols/Sequence/UMLMessage.class';
import { changeEdgeType, moveLayerAction, pasteAction, processSpaceAction } from '@/actions/editor.actions';
import { EditorMode } from '@/models/editorMode';
import { noop } from 'lodash-es';
import { MoveLayer } from '@/models/movelayer';
import { EdgesForChangeSelectors } from '@/selectors/edgesForChangeType.selectors';
import { EdgeInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { getStore } from '@/store';
import { ModelTypes } from '@/models/ModelTypes';
import { getCopiedElements } from '@/selectors/editor.selectors';
import { MxClipboard } from '@/mxgraph/mxgraph';
import { ModelSelectors } from '@/selectors/model.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';
import { isUserHasAccess } from '@/selectors/authorization.selectors';
import userAccessRightTypes from '@/models/userAccessRightTypes';
import { SpaceAction } from '@/models/insertionSpace';
import { copyLinkAction } from '@/actions/copyLinkDialog.actions';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { initHistoryTabAction } from '@/actions/historyTab.actions';
import {
    imageToClipboard,
    openImageDownloadSettings,
    printModel,
    saveImage,
    saveImageSvg,
    savePdf,
} from '@/actions/image.actions';
import { scriptSelectDialogInit } from '@/actions/scriptSelectDialog.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { openEdgeManagementDialog } from '@/actions/edgeManagement.actions';
import { ExperimentalFeatures } from '@/models/ExperimentalFeatures';
import { viewModelProperties } from '@/actions/modelProperty.actions';
import { TIsEdgeEnabledReturnValue } from './editorPopupMenu.types';
import { PasteMode } from '@/actions/editor.actions.types';

export const getMode = (graph: BPMMxGraph) => graph.mode || EditorMode.Read;

export const addMenuEdgeTypesForSequence = (
    menu: MxPopupMenu,
    graph: BPMMxGraph,
    cell: MxCell,
    locale: Locale,
    emitter: any,
    mode: EditorMode,
) => {
    const { modelType }: { modelType?: ModelType } = graph;
    const complexSymbol = ComplexSymbolManager.getComplexSymbolInstance(cell) as
        | LifelineSymbolMainClass
        | UMLMessage
        | null;

    if (modelType && complexSymbol?.startConnection) {
        const typeEdge = menu.addItem(
            graph.intl.formatMessage(messages.typeEdge),
            null,
            () => {},
            null,
            '',
            mode === EditorMode.Edit,
        );
        const isUmlMessage: boolean = SequenceUtils.isUmlMessage(cell);

        const availableTypes: EdgeType[] = isUmlMessage
            ? modelType.edgeTypes.filter((edgeType: EdgeType) => edgeType.id === SequenceEdgeTypesId.ASYNCHRON_MESSAGE)
            : modelType.edgeTypes;
        availableTypes.forEach((edgeType: EdgeType) => {
            menu.addItem(
                LocalesService.internationalStringToString(edgeType.multilingualName, locale),
                null,
                () => {
                    complexSymbol.startConnection(cell, edgeType, availableTypes);
                    emitter(changeEdgeType(edgeType.id));
                },
                typeEdge,
                '',
            );
        });
    }
};

export const isAllowForkConnection = (cell?: MxCell) => {
    if (!cell) return false;

    const isRecursiveEdge = cell.value?.edgeTypeId === SequenceEdgeTypesId.RECURSIVE_MESSAGE;
    const isDurationEdge = cell.value?.edgeTypeId === SequenceEdgeTypesId.DURATION;
    const isForkEdge = SequenceUtils.isForkEdge(cell);
    const { source, target } = cell;
    const sourceComplexSymbol = ComplexSymbolManager.getComplexSymbolInstance(source);
    const allowForkConnection = !!(sourceComplexSymbol as LifelineSymbolMainClass)?.startForkConnection;
    const isUmlMessage = SequenceUtils.isUmlMessage(source) || SequenceUtils.isUmlMessage(target);

    return !isRecursiveEdge && !isDurationEdge && !isForkEdge && allowForkConnection && !isUmlMessage;
};

export const addMoveMenu = (menu: MxPopupMenu, graph: BPMMxGraph, emitter: any, mode: EditorMode): void => {
    const moveSubMenu = menu.addItem(
        graph.intl.formatMessage(messages.move),
        null,
        () => noop,
        null,
        '',
        mode === EditorMode.Edit,
        undefined,
        'graph-element_context-menu_MOVE',
    );

    for (const direction in MoveLayer) {
        if (!isNaN(Number(direction))) continue;

        const message = messages[`moveLevel${direction}`];
        menu.addItem(
            message ? graph.intl.formatMessage(message) : direction,
            null,
            () => emitter(moveLayerAction(MoveLayer[direction as keyof typeof MoveLayer])),
            moveSubMenu,
            '',
            true,
            undefined,
            `graph-element_context-menu_options_MOVE-LEVEL-${direction.toUpperCase()}`,
        );
    }
};

export const getSelectedEdges = (graph: BPMMxGraph): EdgeInstanceImpl[] => {
    return graph
        .getSelectionModel()
        .cells.filter((cell) => cell.isEdge())
        .map((edge) => edge.getValue())
        .filter((edgeValue) => edgeValue);
};

export const getTooltipMessage = (graph: BPMMxGraph, edgeTypes: EdgeType[], mode: EditorMode): string => {
    const state = getStore().getState();
    const selectedEdges: EdgeInstanceImpl[] = getSelectedEdges(graph);

    return EdgesForChangeSelectors.getTooltipMessage(selectedEdges, edgeTypes.length >= 1, mode)(state);
};

export const isEdgeEnabled = (
    graph: BPMMxGraph,
    cell: MxCell,
    edgeTypes: EdgeType[],
    mode: EditorMode,
): TIsEdgeEnabledReturnValue => {
    const connectedWithUmlMessage =
        SequenceUtils.isUmlMessage(cell?.target) || SequenceUtils.isUmlMessage(cell?.source);
    const tooltipMessage: string = getTooltipMessage(graph, edgeTypes, mode);
    // если tooltipMessage не пустая строка, то значит получили подсказку по заблокированной кнопке
    // ниже используется как флаг - меню заблокировано если есть подсказка
    const isEdgesBlocked: boolean = !!tooltipMessage;

    if (mode !== EditorMode.Edit)
        return { enabled: false, reason: graph.intl.formatMessage(messages.onlyInEditModeAvailable) };
    if (isEdgesBlocked) return { enabled: false, reason: tooltipMessage };
    if (connectedWithUmlMessage)
        return { enabled: false, reason: graph.intl.formatMessage(messages.UMLMessageEdgeTypeWarning) };

    return { enabled: true };
};

export const addEdgeTypesMenu = (menu: MxPopupMenu, emitter: any, edgeTypes: EdgeType[], submenu?: any): void => {
    edgeTypes.forEach((el: EdgeType) => {
        if (el.id !== SequenceEdgeTypesId.RECURSIVE_MESSAGE) {
            menu.addItem(
                el.localizableName,
                null,
                () => {
                    emitter(changeEdgeType(el.id));
                },
                submenu,
                '',
                true,
                undefined,
                `graph-element_context-menu_edge-type_option`,
            );
        }
    });
};

export const addPasteAsMenu = (
    menu: MxPopupMenu,
    graph: BPMMxGraph,
    emitter: any,
    mode: EditorMode,
    hasCopiedElements: boolean,
    isWhiteBoard: boolean,
): void => {
    const subMenu = menu.addItem(
        graph.intl.formatMessage(messages.pasteAs),
        null,
        () => noop,
        null,
        '',
        mode === EditorMode.Edit && hasCopiedElements,
    );
    menu.addItem(
        graph.intl.formatMessage(messages.pasteAsInstanceCopy),
        null,
        () => emitter(pasteAction(PasteMode.INSTANCE)),
        subMenu,
        '',
        mode === EditorMode.Edit && hasCopiedElements,
    );
    menu.addItem(
        graph.intl.formatMessage(messages.pasteAsDefinitionCopy),
        null,
        () => emitter(pasteAction(PasteMode.DEFINITION)),
        subMenu,
        null,
        mode === EditorMode.Edit && hasCopiedElements && !isWhiteBoard,
    );

    if (ExperimentalFeatures.isElementsVariantsEnabled()) {
        menu.addItem(
            graph.intl.formatMessage(messages.pasteAsVariant),
            null,
            () => emitter(pasteAction(PasteMode.VARIANT)),
            subMenu,
            null,
            mode === EditorMode.Edit && hasCopiedElements && !isWhiteBoard,
        );
    }
};

export const addSpaceMenu = (menu: MxPopupMenu, graph: BPMMxGraph, emitter: any, mode: EditorMode): void => {
    const subSpaceMenu = menu.addItem(
        graph.intl.formatMessage(messages.space),
        null,
        () => noop,
        null,
        '',
        mode === EditorMode.Edit,
    );

    const items: Array<{ title: string; funct: () => void }> = [
        {
            title: graph.intl.formatMessage(messages.insertSpaceHorizontally),
            funct: () => emitter(processSpaceAction(SpaceAction.InsertHorizontal)),
        },
        {
            title: graph.intl.formatMessage(messages.insertSpaceVertical),
            funct: () => emitter(processSpaceAction(SpaceAction.InsertVervical)),
        },
        {
            title: graph.intl.formatMessage(messages.deleteSpaceHorizontally),
            funct: () => emitter(processSpaceAction(SpaceAction.DeleteHorizontal)),
        },
        {
            title: graph.intl.formatMessage(messages.deleteSpaceVertical),
            funct: () => emitter(processSpaceAction(SpaceAction.DeleteVertical)),
        },
    ];

    for (const { title, funct } of items) {
        menu.addItem(title, null, funct, subSpaceMenu, '', mode === EditorMode.Edit);
    }
};

export const addLinkMenuItem = (menu: MxPopupMenu, graph: BPMMxGraph, funct: () => void): void => {
    menu.addItem(
        graph.intl.formatMessage(messages.copyLink),
        null,
        funct,
        null,
        '',
        true,
        undefined,
        'graph-element_context-menu_copy-link',
    );
};

export const addImageMenu = (menu: MxPopupMenu, graph: BPMMxGraph, emitter: any, printable?: boolean): void => {
    const imgSubSpaceMenu = menu.addItem(
        graph.intl.formatMessage(messages.getImage),
        null,
        () => noop,
        null,
        '',
        printable,
        undefined,
        'graph-element_context-menu_save-image',
    );

    const items: Array<{ title: string; funct: () => void }> = [
        {
            title: graph.intl.formatMessage(messages.imagePng),
            funct: () => emitter(saveImage('png', graph.id)),
        },
        {
            title: graph.intl.formatMessage(messages.imageJpeg),
            funct: () => emitter(saveImage('jpeg', graph.id)),
        },
        {
            title: graph.intl.formatMessage(messages.imageSvg),
            funct: () => emitter(saveImageSvg(graph.id)),
        },
        {
            title: graph.intl.formatMessage(messages.imagePdf),
            funct: () => emitter(savePdf(graph.id)),
        },
        {
            title: graph.intl.formatMessage(messages.imagePrint),
            funct: () => emitter(printModel(graph.id)),
        },
        {
            title: graph.intl.formatMessage(messages.imageToClipboard),
            funct: () => emitter(imageToClipboard(graph.id)),
        },
        {
            title: graph.intl.formatMessage(messages.getImageSettings),
            funct: () => emitter(openImageDownloadSettings()),
        },
    ];

    for (const { title, funct } of items) {
        menu.addItem(title, null, funct, imgSubSpaceMenu, '', printable);
    }
};

export const addScriptMenu = (
    menu: MxPopupMenu,
    graph: BPMMxGraph,
    onExecute: () => void,
    onSchedule: () => void,
): void => {
    const scriptSubSpaceMenu = menu.addItem(
        graph.intl.formatMessage(messages.scriptMenuName),
        null,
        () => noop,
        null,
        '',
        true,
        undefined,
        'graph-element_context-menu_EXECUTE_SCRIPT',
    );
    menu.addItem(
        graph.intl.formatMessage(messages.executeScript),
        null,
        onExecute,
        scriptSubSpaceMenu,
        '',
        true,
        undefined,
        'graph-element_context-menu_options_EXECUTE_SCRIPT',
    );
    menu.addItem(
        graph.intl.formatMessage(messages.scheduleScript),
        null,
        onSchedule,
        scriptSubSpaceMenu,
        '',
        true,
        undefined,
        'graph-element_context-menu_options_SCHEDULE_SCRIPT',
    );
};

export const generatePasteMenu = (
    menu: MxPopupMenu,
    graph: BPMMxGraph,
    emitter: any,
    mode: EditorMode,
    printable?: boolean,
) => {
    const isWhiteBoard: boolean = graph?.modelType?.id === ModelTypes.MIND_MAP;

    const state = getStore().getState();
    const hasCopiedElements = getCopiedElements(state).length > 0 || (isWhiteBoard && !MxClipboard.isEmpty());
    const model: ModelNode | undefined = ModelSelectors.byId(graph.id)(state);
    const isDeletedModel: boolean = !!model?.deleted;
    const presetId: string = TreeSelectors.presetById(graph.id)(state);
    const modelType: ModelType | undefined = ModelTypeSelectors.byId(
        { modelTypeId: model?.modelTypeId || '', serverId: graph.id.serverId },
        presetId,
    )(state);
    const isMetaEditor: boolean = isUserHasAccess(userAccessRightTypes.META_EDITOR as UserDTOAccessesEnum)(state);

    menu.autoExpand = true;
    menu.addItem(
        graph.intl.formatMessage(messages.paste),
        null,
        () => emitter(pasteAction(PasteMode.INSTANCE)),
        null,
        '',
        mode === EditorMode.Edit && hasCopiedElements,
        undefined,
        'graph-element_context-menu_paste',
    );

    addPasteAsMenu(menu, graph, emitter, mode, hasCopiedElements, isWhiteBoard);

    addSpaceMenu(menu, graph, emitter, mode);

    menu.addSeparator(null, true);

    addLinkMenuItem(menu, graph, () => emitter(copyLinkAction(graph.id, TreeItemType.Model)));

    menu.addItem(
        graph.intl.formatMessage(messages.historyLog),
        null,
        () => emitter(initHistoryTabAction(graph.id)),
        null,
        '',
        true,
    );

    addImageMenu(menu, graph, emitter, printable);

    // Меню скриптов при клике ПКМ на холсте
    const onExecute = () =>
        emitter(
            scriptSelectDialogInit({
                serverId: graph.bpmMxGraphContext.serverId,
                nodeId: graph.id,
                nodesIdsList: [graph.id],
                modelId: graph.id,
                element: undefined,
                rootType: TreeItemType.ScriptFolder,
                scriptType: TreeItemType.Script,
            }),
        );
    const onSchedule = () =>
        emitter(
            openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, {
                serverId: graph.bpmMxGraphContext.serverId,
                nodeId: graph.id,
                nodesIdsList: [graph.id],
                modelId: graph.id,
                element: undefined,
                rootType: TreeItemType.ScriptFolder,
                scriptType: TreeItemType.Script,
            }),
        );
    addScriptMenu(menu, graph, onExecute, onSchedule);

    menu.addItem(
        graph.intl.formatMessage(messages.edgeManagement),
        null,
        () => emitter(openEdgeManagementDialog()),
        null,
        '',
        mode === EditorMode.Edit,
    );

    if (ExperimentalFeatures.isModelTemplatesEnabled()) {
        menu.addItem(
            graph.intl.formatMessage(messages.createModelTemplate),
            null,
            () =>
                emitter(
                    openDialog(DialogType.CREATE_MODEL_TEMPLATE_DIALOG, {
                        modelId: graph.id.id,
                        repositoryId: graph.id.repositoryId,
                    }),
                ),
            null,
            '',
            !!modelType && !isDeletedModel && isMetaEditor,
        );
    }

    menu.addItem(
        graph.intl.formatMessage(messages.compare),
        null,
        () =>
            emitter(
                openDialog(DialogType.SELECT_MODEL_TO_COMPARE_DIALOG, {
                    nodeId: graph.id,
                }),
            ),
        null,
        '',
        !!modelType && !isDeletedModel && isMetaEditor,
    );

    menu.addSeparator(null, true);

    menu.addItem(
        `${graph.intl.formatMessage(messages.properties)} ${graph.intl.formatMessage(messages.hotKeys)}`,
        null,
        () => emitter(viewModelProperties(graph.id)),
        null,
        '',
        true,
        undefined,
        'properties_graph_context-menu',
    );
};

export const addDecompositionItem = (menu: MxPopupMenu, graph: BPMMxGraph, funct: () => void, enabled: boolean) => {
    menu.addItem(
        graph.intl.formatMessage(messages.decomposition),
        null,
        funct,
        null,
        '',
        enabled,
        undefined,
        'graph-element_context-menu_decomposition',
    );
};

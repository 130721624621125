import { call, put, select } from 'redux-saga/effects';
import {
    DELETE_UI_TEMPLATE,
    OPEN_UI_TEMPLATE_EDITOR_TAB,
    OPEN_UI_TEMPLATES_TAB,
    SAVE_UI_TEMPLATE,
} from '../actionTypes/UITemplates.actionTypes';
import {
    TDeleteUITemplateAction,
    TOpenUITemplateEditorTabAction,
    TOpenUITemplatesTabAction,
    TSaveUITemplateAction,
} from '../actions/UITemplates.actions.types';
import { TWorkspaceTab } from '@/models/tab.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { workspaceAddTab, workspaceRemoveTab, workspaceRemoveTabByNodeId } from '@/actions/tabs.actions';
import { takeEverySafely } from '@/sagas/sagaHelpers';
import { UITemplatesDaoService } from '../dao/UITemplatesDaoService';
import { NodeId, UITemplateDTO, UserDTO } from '@/serverapi/api';
import { loadUITemplatesSuccess } from '../actions/UITemplates.actions';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import messages from './UITemplatesSaga.messages';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { v4 as uuid } from 'uuid';
import { getUser } from '@/selectors/authorization.selectors';
import { getContentLoadingPageTab } from '@/sagas/utils';
import { UI_TEMPLATE_EDITOR_CLOSE_TAB } from '../actionTypes/UITemplateEditor.actionTypes';
import { TUITemplateEditorCloseTabAction } from '../actions/UITemplateEditor.actions.types';
import { UITemplateEditorAddNewTemplate } from '../actions/UITemplateEditor.actions';

function* loadTemplates() {
    try {
        const UITemplates: UITemplateDTO[] = yield call(UITemplatesDaoService.getUITemplates);
        yield put(loadUITemplatesSuccess(UITemplates));

        return UITemplates;
    } catch (e) {
        throw e;
    }
}

function* openUITemplatesTab({ payload }: TOpenUITemplatesTabAction) {
    const { serverNode } = payload;

    const contentLoadingPageTab = yield getContentLoadingPageTab(serverNode.nodeId);
    yield put(workspaceAddTab(contentLoadingPageTab));

    yield call(loadTemplates);

    const UITemplatesTab: TWorkspaceTab = {
        title: serverNode.name,
        nodeId: serverNode.nodeId,
        type: WorkSpaceTabTypes.UI_TEMPLATES,
    };
    yield put(workspaceRemoveTab(contentLoadingPageTab));
    yield put(workspaceAddTab(UITemplatesTab));
}

function* openUITemplateEditorTab({ payload }: TOpenUITemplateEditorTabAction) {
    const { templateId = uuid() } = payload;

    const user: UserDTO | undefined = yield select(getUser);
    const serverId: string = yield select(ServerSelectors.serverId);
    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);

    const nodeId: NodeId = {
        id: templateId,
        repositoryId: WorkSpaceTabTypes.UI_TEMPLATE_EDITOR,
        serverId,
    };

    const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);
    yield put(workspaceAddTab(contentLoadingPageTab));

    const UITemplates: UITemplateDTO[] = yield call(loadTemplates);
    const UITemplate: UITemplateDTO | undefined = UITemplates.find((UITemplate) => UITemplate.id === templateId);

    const UITemplateEditorTab: TWorkspaceTab = {
        title: UITemplate ? intl.formatMessage(messages.edit) : intl.formatMessage(messages.new),
        nodeId: nodeId,
        type: WorkSpaceTabTypes.UI_TEMPLATE_EDITOR,
    };

    const newUITemplate: UITemplateDTO = {
        id: templateId,
        name: UITemplate?.name || {},
        widgets: UITemplate?.widgets || [],
        createdAt: UITemplate?.createdAt || new Date().getTime(),
        createdBy: UITemplate?.createdBy || user?.login || '',
        ...UITemplate,
    };

    yield put(UITemplateEditorAddNewTemplate(newUITemplate));
    yield put(workspaceRemoveTab(contentLoadingPageTab));
    yield put(workspaceAddTab(UITemplateEditorTab));
}

function* saveUITemplate({ payload }: TSaveUITemplateAction) {
    const { UITemplate, tabNodeId } = payload;
    yield call(UITemplatesDaoService.saveUITemplate, UITemplate);
    yield put(workspaceRemoveTabByNodeId(tabNodeId));
    yield call(loadTemplates);
}

function* deleteUITemplate({ payload }: TDeleteUITemplateAction) {
    const { templateId } = payload;
    yield call(UITemplatesDaoService.deleteUITemplate, templateId);
    yield call(loadTemplates);
}

function* deleteNewUITemplate({ payload }: TUITemplateEditorCloseTabAction) {
    const { tabNodeId } = payload;
    yield put(workspaceRemoveTabByNodeId(tabNodeId));
}

export function* UITemplatesSaga() {
    yield takeEverySafely(OPEN_UI_TEMPLATES_TAB, openUITemplatesTab);
    yield takeEverySafely(OPEN_UI_TEMPLATE_EDITOR_TAB, openUITemplateEditorTab);
    yield takeEverySafely(SAVE_UI_TEMPLATE, saveUITemplate);
    yield takeEverySafely(DELETE_UI_TEMPLATE, deleteUITemplate);
    yield takeEverySafely(UI_TEMPLATE_EDITOR_CLOSE_TAB, deleteNewUITemplate);
}

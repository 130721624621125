import {
    ADD_MODEL_FORMAT_PARAM_VALUE,
    DELETE_ALL_MODEL_FORMAT_PARAM_VALUES,
    FETCH_DELETE_ALL_MODEL_FORMAT_PARAM_VALUES,
    FETCH_MODEL_FORMAT_PARAM_VALUE,
} from '../actionsTypes/addedModelFormatParams.actionTypes';
import { FormatParamValues } from '../reducers/addedModelFormatParams.reducer.types';
import { NodeId } from '../serverapi/api';
import {
    TAddModelFormatParamValueAction,
    TDeleteAllModelFormatParamValuesAction,
    TFetchModelFormatParamValueAction,
    TFetchDeleteAllModelFormatParamValuesAction,
} from './addedModelFormatParams.actions.types';

export const addModelFormatParamValue = (
    value: string,
    paramType: FormatParamValues,
): TAddModelFormatParamValueAction => ({
    type: ADD_MODEL_FORMAT_PARAM_VALUE,
    payload: {
        value,
        paramType,
    },
});

export const deleteAllModelFormatParamValues = (
    paramType: FormatParamValues,
): TDeleteAllModelFormatParamValuesAction => ({
    type: DELETE_ALL_MODEL_FORMAT_PARAM_VALUES,
    payload: {
        paramType,
    },
});

export const fetchModelFormatParamValue = (
    value: string,
    modelId: NodeId,
    paramType: FormatParamValues,
): TFetchModelFormatParamValueAction => ({
    type: FETCH_MODEL_FORMAT_PARAM_VALUE,
    payload: {
        value,
        modelId,
        paramType,
    },
});

export const fetchDeleteAllModelFormatParamValues = (
    modelId: NodeId,
    paramType: FormatParamValues,
): TFetchDeleteAllModelFormatParamValuesAction => ({
    type: FETCH_DELETE_ALL_MODEL_FORMAT_PARAM_VALUES,
    payload: {
        modelId,
        paramType,
    },
});

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import theme from './UITemplatesPreview.scss';
import { UITemplateDTO, WidgetDTO } from '@/serverapi/api';
import { Widget } from '../../CreateUITemplateTab/Widget/Widget.component';
import { useResizeContainer } from '@/modules/UITemplates/hooks/useResizeContainer';
import { getWidgetsByTab } from '@/modules/UITemplates/utils/UITemplate.utils';
import { Tab } from '@/modules/UIKit/components/Tabs/UIKitTabs.types';
import { UIKitTabs } from '@/modules/UIKit/components/Tabs/UIKitTabs.component';

type TUITemplatesPreviewProps = {
    template: UITemplateDTO;
};

export const UITemplatesPreview: FC<TUITemplatesPreviewProps> = ({
    template: { id, widgets, tabs: templateTabs = [], tabsType },
}) => {
    const cellsContainer = useRef<HTMLDivElement>(null);
    const [activeTabId, setActiveTabId] = useState<string | undefined>(templateTabs?.[0]?.id);

    const cellSize: number = useResizeContainer({ cellsContainer });

    const onTabClickHandler = (tabId: string) => {
        setActiveTabId(tabId);
    };

    useEffect(() => {
        setActiveTabId(templateTabs?.[0]?.id);
    }, [id, templateTabs]);

    const getContent = useCallback(
        (widgets: WidgetDTO[]) => (
            <div className={theme.scrollContainer} ref={cellsContainer}>
                {widgets.map((widget) => {
                    return (
                        <Widget
                            preview
                            key={widget.id}
                            templateId={id}
                            widget={widget}
                            cellSize={cellSize}
                            selected={false}
                        />
                    );
                })}
            </div>
        ),
        [cellSize, cellsContainer.current],
    );

    const tabs: Tab[] = useMemo(
        () =>
            templateTabs.map((templateTab) => {
                const widgetsByTab: WidgetDTO[] = getWidgetsByTab(widgets, templateTab);

                return {
                    id: templateTab.id,
                    title: templateTab.name,
                    content: getContent(widgetsByTab),
                    onClick: onTabClickHandler,
                };
            }),
        [templateTabs, widgets, getContent],
    );

    return (
        <div className={theme.widgetsContainer}>
            {tabsType ? (
                <UIKitTabs
                    orientation={tabsType === 'HORIZONTAL' ? 'horizontal' : 'vertical'}
                    tabs={tabs}
                    activeTabId={activeTabId}
                />
            ) : (
                getContent(widgets)
            )}
        </div>
    );
};

import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import { MultiLangInputDialog } from '../../../../../modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { MultiLangInputDialogLabel } from '../../MultiLangInputDialogLabel.component';
import { useDispatch, useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { AgreementResetConfirm } from '../AgreementResetConfirm.component';
import { deleteAgreement, deleteSilaAgreement } from '../../../../../actions/agreement.actions';
import { ServerSelectors } from '../../../../../selectors/entities/server.selectors';
import { InternationalString } from '../../../../../serverapi/api';
import { SystemPropertiesFormItemNames, TPropsWithFormRef } from '../../SystemProperties.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

export const LicenseAgreementSettings = (props: TPropsWithFormRef) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { formRef } = props;
    const form = formRef.current;
    const silaAgreement = useSelector(SystemPropertiesSelectors.getSilaAgreement);
    const agreement = useSelector(SystemPropertiesSelectors.getAgreement);
    const [silaAgreementModalDialogVisible, setSilaAgreementModalDialogVisible] = useState<boolean>(false);
    const [agreementModalDialogVisible, setAgreementModalDialogVisible] = useState<boolean>(false);
    const [newAgreement, setNewAgreement] = useState<InternationalString | undefined>(agreement);
    const [newSilaAgreement, setNewSilaAgreement] = useState<InternationalString | undefined>(silaAgreement);
    const handleChangeAgreementDescription = (multilingualDescription: InternationalString) => {
        form?.setFieldValue(SystemPropertiesFormItemNames.agreement, multilingualDescription);
        setNewAgreement(multilingualDescription);
    };

    const handleChangeSilaAgreementDescription = (multilingualDescription: InternationalString) => {
        form?.setFieldValue(SystemPropertiesFormItemNames.silaAgreement, multilingualDescription);
        setNewSilaAgreement(multilingualDescription);
    };

    const isActiveSilaAgreement = useSelector(SystemPropertiesSelectors.getIsActiveSilaAgreement);
    const isActiveAgreement = useSelector(SystemPropertiesSelectors.getIsActiveAgreement);
    const serverId: string = useSelector(ServerSelectors.serverList)[0]?.id;

    const handleOpenConfirmSilaAgreementModal = () => {
        setSilaAgreementModalDialogVisible(true);
    };
    const handleOpenConfirmAgreementModal = () => {
        setAgreementModalDialogVisible(true);
    };

    const handleChangeSilaAgreementActive = () => {
        setIsActiveSilaAgreement(!isActiveSilaAgreementCheck);
    };
    const [isActiveSilaAgreementCheck, setIsActiveSilaAgreement] = useState<boolean | undefined>(isActiveSilaAgreement);

    useEffect(() => {
        if (!isActiveSilaAgreementCheck)
            form?.validateFields([SystemPropertiesFormItemNames.multilingualSilaAgreementDescription]);
    }, [isActiveSilaAgreementCheck]);

    const handleChangeAgreementActive = () => {
        setIsActiveAgreement(!isActiveAgreementCheck);
    };
    const [isActiveAgreementCheck, setIsActiveAgreement] = useState<boolean | undefined>(isActiveAgreement);

    useEffect(() => {
        if (!isActiveAgreementCheck)
            form?.validateFields([SystemPropertiesFormItemNames.multilingualAgreementDescription]);
    }, [isActiveAgreementCheck]);

    const onHideAgreement = () => {
        setAgreementModalDialogVisible(false);
    };
    const onHideSilaAgreement = () => {
        setSilaAgreementModalDialogVisible(false);
    };

    const handleDeleteAgreement = (serverId) => dispatch(deleteAgreement(serverId));
    const handleDeleteSilaAgreement = (serverId) => dispatch(deleteSilaAgreement(serverId));

    return (
        <div className={theme.formTab}>
            {silaAgreementModalDialogVisible && (
                <AgreementResetConfirm
                    contentMessage={intl.formatMessage(messages.resetSilaAgreementConfirm)}
                    onHideAgreement={onHideSilaAgreement}
                    handleDelete={() => handleDeleteSilaAgreement(serverId)}
                />
            )}
            {agreementModalDialogVisible && (
                <AgreementResetConfirm
                    contentMessage={intl.formatMessage(messages.resetAgreementConfirm)}
                    onHideAgreement={onHideAgreement}
                    handleDelete={() => handleDeleteAgreement(serverId)}
                />
            )}
            <div className={theme.agreementVertical}>
                <div className={theme.agreementWrapper}>
                    <MultiLangInputDialog
                        required={isActiveSilaAgreementCheck}
                        placeholder={intl.formatMessage(messages.description)}
                        multiLangValue={newSilaAgreement}
                        onChange={handleChangeSilaAgreementDescription}
                        textarea
                        label={
                            <MultiLangInputDialogLabel
                                agreementTypeActive={isActiveSilaAgreementCheck}
                                label={intl.formatMessage(messages.silaAgreementLabel)}
                                isFirstLabel
                                name={SystemPropertiesFormItemNames.isActiveSilaAgreement}
                                onChange={handleChangeSilaAgreementActive}
                            />
                        }
                        mainInputName={SystemPropertiesFormItemNames.multilingualSilaAgreementDescription}
                        generalForm={formRef.current}
                        autoSize={{ minRows: 8, maxRows: 10 }}
                        isAgreement
                    />
                </div>
                <div className={theme.agrementButtonWrapper}>
                    <Button
                        visualStyle="primary"
                        onClick={handleOpenConfirmSilaAgreementModal}
                    >
                        {intl.formatMessage(messages.resetSilaAgreementBtn)}
                    </Button>
                </div>
                
            </div>
            <div className={theme.agreementVertical}>
                <div className={theme.agreementWrapper}>
                    <MultiLangInputDialog
                        required={isActiveAgreementCheck}
                        placeholder={intl.formatMessage(messages.description)}
                        multiLangValue={newAgreement}
                        onChange={handleChangeAgreementDescription}
                        textarea
                        label={
                            <MultiLangInputDialogLabel
                                agreementTypeActive={isActiveAgreementCheck}
                                label={intl.formatMessage(messages.agreementLabel)}
                                name={SystemPropertiesFormItemNames.isActiveAgreement}
                                onChange={handleChangeAgreementActive}
                            />
                        }
                        mainInputName={SystemPropertiesFormItemNames.multilingualAgreementDescription}
                        generalForm={formRef.current}
                        autoSize={{ minRows: 8, maxRows: 10 }}
                        isAgreement
                    />
                </div>
                <Button visualStyle="primary" onClick={handleOpenConfirmAgreementModal}>
                    {intl.formatMessage(messages.resetAgreementBtn)}
                </Button>
            </div>
            {/* Form.Item name="agreement" создан для хранения значения agreement в форме, визуально не отображается */}
            <Form.Item name={SystemPropertiesFormItemNames.agreement} initialValue={agreement} noStyle />
            {/* Form.Item name="silaAgreement" создан для хранения значения silaAgreement в форме, визуально не отображается */}
            <Form.Item name={SystemPropertiesFormItemNames.silaAgreement} initialValue={silaAgreement} noStyle />
        </div>
    );
};

import { NodeId } from '@/serverapi/api';
import {
    OBJECT_PROPERTY_VIEW,
    OPEN_DECOMPOSITION_ON_CANVAS,
    OPEN_PROPERTY_ON_CANVAS,
} from '../actionsTypes/objectProperty.actionTypes';
import { ObjectPropertiesDialogActiveTab } from '../models/objectPropertiesDialog';
import {
    TObjectPropertyViewAction,
    TOpenDecomposionOnCanvasAction,
    TOpenPropertyOnCanvasAction,
} from './objectProperty.actions.types';

export const objectPropertyView = (
    cellId: string,
    activeTab?: ObjectPropertiesDialogActiveTab,
    graphId?: NodeId,
): TObjectPropertyViewAction => ({
    type: OBJECT_PROPERTY_VIEW,
    payload: {
        cellId,
        activeTab,
        graphId,
    },
});

export const openPropertyOnCanvas = (): TOpenPropertyOnCanvasAction => ({
    type: OPEN_PROPERTY_ON_CANVAS,
});

export const openDecompositionOnCanvas = (): TOpenDecomposionOnCanvasAction => ({
    type: OPEN_DECOMPOSITION_ON_CANVAS,
});

import { TreeNode } from '@/models/tree.types';
import {
    DELETE_UI_TEMPLATE,
    LOAD_UI_TEMPLATES_SUCCESS,
    OPEN_UI_TEMPLATE_EDITOR_TAB,
    OPEN_UI_TEMPLATES_TAB,
    SAVE_UI_TEMPLATE,
    SAVE_UI_TEMPLATE_ERROR,
    SAVE_UI_TEMPLATE_SUCCESS,
} from '../actionTypes/UITemplates.actionTypes';
import {
    TDeleteUITemplateAction,
    TLoadUITemplatesSuccessAction,
    TOpenUITemplateEditorTabAction,
    TOpenUITemplatesTabAction,
    TSaveUITemplateAction,
    TSaveUITemplateErrorAction,
    TSaveUITemplateSuccessAction,
} from './UITemplates.actions.types';
import { NodeId, UITemplateDTO } from '@/serverapi/api';

export const openUITemplatesTab = (node: TreeNode): TOpenUITemplatesTabAction => ({
    type: OPEN_UI_TEMPLATES_TAB,
    payload: {
        serverNode: node,
    },
});

export const openUITemplateEditorTab = (templateId?: string): TOpenUITemplateEditorTabAction => ({
    type: OPEN_UI_TEMPLATE_EDITOR_TAB,
    payload: {
        templateId,
    },
});

export const loadUITemplatesSuccess = (UITemplates: UITemplateDTO[]): TLoadUITemplatesSuccessAction => ({
    type: LOAD_UI_TEMPLATES_SUCCESS,
    payload: {
        UITemplates,
    },
});

export const saveUITemplate = (tabNodeId: NodeId, UITemplate: UITemplateDTO): TSaveUITemplateAction => ({
    type: SAVE_UI_TEMPLATE,
    payload: {
        tabNodeId,
        UITemplate,
    },
});

export const saveUITemplateSuccess = (UITemplate: UITemplateDTO): TSaveUITemplateSuccessAction => ({
    type: SAVE_UI_TEMPLATE_SUCCESS,
    payload: {
        UITemplate,
    },
});

export const saveUITemplateError = (UITemplate: UITemplateDTO): TSaveUITemplateErrorAction => ({
    type: SAVE_UI_TEMPLATE_ERROR,
    payload: {
        UITemplate,
    },
});

export const deleteUITemplate = (templateId: string): TDeleteUITemplateAction => ({
    type: DELETE_UI_TEMPLATE,
    payload: {
        templateId,
    },
});

import React from 'react';
import { ToolbarButton } from '../../../UIKit/components/Toolbar/ToolbarButton/ToolbarButton.component';
import icHorizontal from '@/resources/icons/ic-ribbon-text-horizontal.svg';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { generalMenuLabelStyleChangeAction } from '../../../../actions/generalMenu.actions';
import { LabelStyle } from '../../../../models/labelStyle';
import { FormatPanelButtonsState } from '../../../../selectors/generalMenu.selectors';
import { MxConstants } from '../../../../mxgraph/mxgraph';
import messages from '../../FormatPanel.messages';
import { FormatPanelSelectors } from '../../../../selectors/formatPanel.selectors';

export const Horizontal = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(FormatPanelSelectors.getFormatButtonsDisabled);
    const tooltip: string = useSelector(
        FormatPanelSelectors.getFormatButtonsTooltip(intl.formatMessage(messages.horizontalText)),
    );
    const horizontal: boolean | undefined = useSelector(FormatPanelButtonsState.getHorizontal);
    const onChangeDirection = (value: boolean) => {
        dispatch(generalMenuLabelStyleChangeAction(LabelStyle.textDirection, MxConstants.STYLE_HORIZONTAL, value));
    };

    return (
        <ToolbarButton
            disabled={disabled}
            isActive={horizontal === true}
            icon={icHorizontal}
            dataTest="object-toolbar_text-direction-horizontal"
            tooltip={tooltip}
            onClick={() => onChangeDirection(true)}
        />
    );
};

import React, { ChangeEvent, useState, useRef } from 'react';
import classNames from 'classnames';
import theme from './Checkbox.scss';
import { TCheckboxStatus } from './Checkbox.types';
import { getNextStatus } from './checkboxUtils';
import { TTooltipeSettings } from '../Tooltip/Tooltip.types';
import { Tooltip } from '../Tooltip/Tooltip.component';

type TCheckboxProps = {
    disabled?: boolean;
    children?: React.ReactNode | JSX.Element | string;
    dataTest?: string;
    tooltip?: string | TTooltipeSettings;
    fixedWidth?: boolean;
    onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
};

type TCheckboxDefaultProps = TCheckboxProps & {
    status?: TCheckboxStatus;
    defaultStatus?: TCheckboxStatus;
    allowIndeterminate?: boolean;
    onChange?: (status?: TCheckboxStatus) => void;
};

type TFormItemCheckboxProps = TCheckboxProps & {
    id?: string;
    checked?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const DefaultCheckbox = (props: TCheckboxDefaultProps) => {
    const { status, defaultStatus = false, allowIndeterminate, disabled, children, fixedWidth, onChange, onClick } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    const [internalStatus, setInternalStatus] = useState<TCheckboxStatus>(defaultStatus);
    const currentStatus = status ?? internalStatus;

    const wrapperClassName = classNames(theme.checkboxWrapper, {
        [theme.fixedWidth]: fixedWidth,
    });

    const inputClassName = classNames(theme.checkbox, {
        [theme.indeterminate]: currentStatus === 'indeterminate',
        [theme.fixedWidth]: fixedWidth,
        [theme.disabled]: disabled,
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const nextStatus: TCheckboxStatus = getNextStatus(currentStatus, allowIndeterminate);

        if (onChange) {
            onChange(nextStatus);
        }

        if (status === undefined) {
            setInternalStatus(nextStatus);
        }
    };

    const handleClickByWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
        if ((e.target as HTMLElement).tagName !== 'INPUT' && !disabled) {
            inputRef.current?.click();
        }
    };

    return (
        <div className={wrapperClassName} data-test={props.dataTest} onClick={handleClickByWrapper}>
            <input
                ref={inputRef}
                type="checkbox"
                className={inputClassName}
                checked={currentStatus === true}
                disabled={disabled}
                onChange={handleChange}
                onClick={onClick}
            />
            <div className={disabled ? theme.disabledLabel : theme.label}>
                {children}
            </div>
        </div>
    );
};

// id в чекбокс поступает из name в Form.Item. Если name не задан, то Form.Item нельзя привязать к форме и тогда мы используем наш чекбокс и сами храним состояние
function isTFormItemCheckboxProps(props: TCheckboxDefaultProps | TFormItemCheckboxProps): props is TFormItemCheckboxProps {
    return props.hasOwnProperty('id');
}

// Этот компонент можно будет удалить если мы перестанем использовать antd Form
const FormItemCheckbox = (props: TFormItemCheckboxProps) => {
    const { checked, disabled, children, fixedWidth, id, onChange, onClick } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    const wrapperClassName = classNames(theme.checkboxWrapper, {
        [theme.fixedWidth]: fixedWidth,
    });

    const inputClassName = classNames(theme.checkbox, {
        [theme.disabled]: disabled,
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e);
        }
    };

    const handleClickByWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
        if ((e.target as HTMLElement).tagName !== 'INPUT' && !disabled) {
            inputRef.current?.click();
        }
    };

    return (
        <div className={wrapperClassName} data-test={props.dataTest} onClick={handleClickByWrapper}>
            <input
                ref={inputRef}
                id={id}
                type="checkbox"
                className={inputClassName}
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
                onClick={onClick}
            />
            <div className={disabled ? theme.disabledLabel : theme.label}>
                {children}
            </div>
        </div>
    );
};

/**
 * @param status состояние чекбокса: 'empty', 'checked', 'indeterminate'
 * @param defaultStatus начальное состояние чекбокса
 * @param allowIndeterminate позволяет переключаться между тремя состояниями
 * @param disabled делает чекбокс недоступным для клика
 * @param children текст справа от чекбокса
 * @param onChange вызывается при изменении состояния чекбокса, передает новое состояние
 * @param dataTest атрибут для тестирования
 * @param tooltip текст подсказки, либо объект с текстом подсказки, смещением и направлением
 * @param fixedWidth ограничение ширины по тексту лейбла
 * 
 * @param id поступает из name в Form.Item, самостоятельно не передавать
 * @param checked состояние чекбокса если он используется внутри Form.Item
 */
export const Checkbox = (props: TCheckboxDefaultProps | TFormItemCheckboxProps) => {
    const isFormItem = isTFormItemCheckboxProps(props);
    const checkboxContent = isFormItem ? (
        <FormItemCheckbox {...props} />
    ) : (
        <DefaultCheckbox {...props} />
    );

    return props.tooltip ? (
        <Tooltip tooltip={props.tooltip}>
            <div className={theme.checkboxWithTooltipeContainer}>
                {checkboxContent}
            </div>
        </Tooltip>
    ) : (
        checkboxContent
    );
};

import { defineMessages } from 'react-intl';

export default defineMessages({
    noGrid: {
        id: 'CommonToolbar.grid.dropdown.noGrid',
        defaultMessage: 'Нет',
    },
    dot: {
        id: 'CommonToolbar.grid.dropdown.dotGrid',
        defaultMessage: 'Точка',
    },
    'A4/PORTRAIT': {
        id: 'CommonToolbar.grid.dropdown.A4Portrait',
        defaultMessage: 'A4 портретная',
    },
    'A4/LANDSCAPE': {
        id: 'CommonToolbar.grid.dropdown.A4Landscape',
        defaultMessage: 'A4 альбомная',
    },
    'A3/PORTRAIT': {
        id: 'CommonToolbar.grid.dropdown.A3Portrait',
        defaultMessage: 'A3 портретная',
    },
    'A3/LANDSCAPE': {
        id: 'CommonToolbar.grid.dropdown.A3Landscape',
        defaultMessage: 'A3 альбомная',
    },
    fontFamily: {
        id: 'CommonToolbar.fontFamily',
        defaultMessage: 'Шрифт',
    },

    fontSize: {
        id: 'CommonToolbar.fontSize',
        defaultMessage: 'Размер шрифта',
    },
    fontColor: {
        id: 'CommonToolbar.fontColor',
        defaultMessage: 'Цвет текста',
    },
    copy: {
        id: 'CommonToolbar.copy',
        defaultMessage: 'Копировать',
    },
    cut: {
        id: 'CommonToolbar.cut',
        defaultMessage: 'Вырезать',
    },
    save: {
        id: 'CommonToolbar.save',
        defaultMessage: 'Сохранить историю модели и модель (Ctrl+s)',
    },
    print: {
        id: 'CommonToolbar.print',
        defaultMessage: 'Печать',
    },
    noAccessToPrint: {
        id: 'CommonToolbar.noAccessToPrint',
        defaultMessage: 'Нет прав на печать',
    },
    paste: {
        id: 'CommonToolbar.paste',
        defaultMessage: 'Вставить',
    },
    toBasket: {
        id: 'CommonToolbar.toBasket',
        defaultMessage: 'Удалить',
    },
    format: {
        id: 'CommonToolbar.format',
        defaultMessage: 'Формат по образцу',
    },
    clearFormat: {
        id: 'CommonToolbar.clearFormat',
        defaultMessage: 'Очистить форматирование',
    },
    textBold: {
        id: 'CommonToolbar.textBold',
        defaultMessage: 'Жирный текст',
    },
    textItalic: {
        id: 'CommonToolbar.textItalic',
        defaultMessage: 'Наклонный текст',
    },
    textUnderline: {
        id: 'CommonToolbar.textUnderline',
        defaultMessage: 'Подчеркнутый текст',
    },
    textStrikeThrough: {
        id: 'CommonToolbar.textStrikeThrough',
        defaultMessage: 'Перечёркнутый текст',
    },
    textLeft: {
        id: 'CommonToolbar.textLeft',
        defaultMessage: 'Текст слева',
    },
    textCenter: {
        id: 'CommonToolbar.textCenter',
        defaultMessage: 'Текст по центру',
    },
    textRight: {
        id: 'CommonToolbar.textRight',
        defaultMessage: 'Текст справа',
    },
    toEditMode: {
        id: 'CommonToolbar.toEditMode',
        defaultMessage: 'Перейти в режим редактирования',
    },
    unlockDisabled: {
        id: 'CommonToolbar.unlockDisabled',
        defaultMessage: 'Модель заблокирована для изменения',
    },
    toRunScript: {
        id: 'CommonToolbar.toRunScript',
        defaultMessage: 'Запустить скрипт',
    },
    toReadMode: {
        id: 'CommonToolbar.toReadMode',
        defaultMessage: 'Перейти в режим чтения',
    },
    insertSpace: {
        id: 'CommonToolbar.insertSpace',
        defaultMessage: 'Пространство',
    },
    textDirection: {
        id: 'CommonToolbar.textDirection',
        defaultMessage: 'Направление',
    },
    alignSelect: {
        id: 'CommonToolbar.alignSelect',
        defaultMessage: 'Выровнять',
    },
    moveSelect: {
        id: 'CommonToolbar.moveSelect',
        defaultMessage: 'Переместить',
    },
    pickOut: {
        id: 'CommonToolbar.pickOut',
        defaultMessage: 'Выделить',
    },
    moveTo: {
        id: 'CommonToolbar.moveTo',
        defaultMessage: 'Перейти',
    },
    nodeFilter: {
        id: 'CommonToolbar.nodeFilter',
        defaultMessage: 'Фильтрация',
    },
    colorFilling: {
        id: 'CommonToolbar.colorFilling',
        defaultMessage: 'Заливка цвета',
    },
    edgeColor: {
        id: 'CommonToolbar.edgeColor',
        defaultMessage: 'Цвет связи',
    },
    edgeType: {
        id: 'CommonToolbar.edgeType',
        defaultMessage: 'Тип связи',
    },
    binding: {
        id: 'CommonToolbar.binding',
        defaultMessage: 'Привязка',
    },
    grid: {
        id: 'CommonToolbar.grid',
        defaultMessage: 'Сетка',
    },
    dashboardGrid: {
        id: 'CommonToolbar.dashboardGrid',
        defaultMessage: 'Сетка',
    },
    dashboardGridTooltip: {
        id: 'CommonToolbar.dashboardGridTooltip',
        defaultMessage: 'Настройка сетки',
    },
    subscript: {
        id: 'CommonToolbar.subscript',
        defaultMessage: 'Нижний индекс',
    },
    superscript: {
        id: 'CommonToolbar.superscript',
        defaultMessage: 'Верхний индекс',
    },
    unorderedList: {
        id: 'CommonToolbar.unorderedList',
        defaultMessage: 'Маркированный список',
    },
    orderedList: {
        id: 'CommonToolbar.orderedList',
        defaultMessage: 'Нумерованный список',
    },
    indent: {
        id: 'CommonToolbar.indent',
        defaultMessage: 'Увеличить отступ',
    },
    outdent: {
        id: 'CommonToolbar.outdent',
        defaultMessage: 'Уменьшить отступ',
    },
    nodeFilterDisabled: {
        id: 'CommonToolbar.nodeFilterDisabled',
        defaultMessage: 'Фильтрация выключена',
    },
    nodeFilterOff: {
        id: 'CommonToolbar.NodeFilterOff',
        defaultMessage: 'Выключить',
    },
    nodeFilterSingle: {
        id: 'CommonToolbar.NodeFilterSingle',
        defaultMessage: 'Все экземпляры выбранного объекта',
    },
    nodeFilterMany: {
        id: 'CommonToolbar.NodeFilterMany',
        defaultMessage: 'Все символы совпадающего типа',
    },
    nodeFilterNotSame: {
        id: 'CommonToolbar.NodeFilterNotSame',
        defaultMessage: 'Только выбранный экземпляр',
    },
    nodeFilterInput: {
        id: 'CommonToolbar.NodeFilterInput',
        defaultMessage: 'Входящие',
    },
    nodeFilterOutput: {
        id: 'CommonToolbar.NodeFilterOutput',
        defaultMessage: 'Исходящие',
    },
    labelDownloadImage: {
        id: 'CommonToolbar.labelDownloadImage',
        defaultMessage: 'Изображение',
    },
    labelPasteText: {
        id: 'CommonToolbar.labelPasteText',
        defaultMessage: 'Текст',
    },
    update: {
        id: 'CommonToolbar.update',
        defaultMessage: 'Обновить',
    },
    search: {
        id: 'CommonToolbar.search',
        defaultMessage: 'Поиск',
    },
    textProperty: {
        id: 'CommonToolbar.textProperty',
        defaultMessage: 'Свойства',
    },
    textDecomposition: {
        id: 'CommonToolbar.textDecomposition',
        defaultMessage: 'Декомпозиции',
    },

    setLink: {
        id: 'CommonToolbar.setLink',
        defaultMessage: 'Установить ссылку',
    },
    destroyLink: {
        id: 'CommonToolbar.destroyLink',
        defaultMessage: 'Удалить ссылку',
    },
    insertImage: {
        id: 'CommonToolbar.insertImage',
        defaultMessage: 'Вставить изображение',
    },
    insertTable: {
        id: 'CommonToolbar.insertTable',
        defaultMessage: 'Вставить таблицу',
    },
    insertShape: {
        id: 'CommonToolbar.insertShape',
        defaultMessage: 'Вставить фигуру',
    },
    onlyInReadModeAvailable: {
        id: 'CommonToolbar.onlyInReadModeAvailable',
        defaultMessage: 'Доступно только в режиме чтения',
    },
    onlyInEditModeAvailable: {
        id: 'CommonToolbar.onlyInEditModeAvailable',
        defaultMessage: 'Доступно только в режиме редактирования',
    },
    selectElementOnCanvas: {
        id: 'CommonToolbar.selectElementOnCanvas',
        defaultMessage: 'Выберите элемент на холсте',
    },
    onlyObjectAndEdgeAvailable: {
        id: 'CommonToolbar.onlyObjectAndEdgeAvailable',
        defaultMessage: 'Доступно только для объекта и связи',
    },
    poolOrSwimlaneOrImageUnavailable: {
        id: 'CommonToolbar.poolOrSwimlaneOrImageUnavailable',
        defaultMessage: 'Недоступно для пулов, дорожек и изображений',
    },
    clipboardIsEmpty: {
        id: 'CommonToolbar.clipboardIsEmpty',
        defaultMessage: 'Буфер обмена пуст',
    },
    enableFiltering: {
        id: 'CommonToolbar.enableFiltering',
        defaultMessage: 'Включите фильтрацию',
    },
    selectEdge: {
        id: 'CommonToolbar.selectEdge',
        defaultMessage: 'Выберите связь',
    },
    selectEdgeOrObject: {
        id: 'CommonToolbar.selectEdgeOrObject',
        defaultMessage: 'Выберите объект или связь',
    },
    needLicense: {
        id: 'CommonToolbar.needLicense',
        defaultMessage: 'Для этого действия вам необходима лицензия',
    },
    selectMinTwoObjectsOnCanvas: {
        id: 'CommonToolbar.selectMinTwoObjectsOnCanvas',
        defaultMessage: 'Выберите от двух объектов на холсте',
    },
    sequenceAlignDisabled: {
        id: 'CommonToolbar.sequenceAlignDisabled',
        defaultMessage: 'Выравнивание недоступно на данной диаграмме',
    },
    edgeDefinitionTypeWarning: {
        id: 'CommonToolbar.edgeDefinitionTypeWarning',
        defaultMessage: 'Смена типа невозможна, для связи есть определение',
    },
    edgeDefinitionStartArrowWarning: {
        id: 'CommonToolbar.edgeDefinitionStartArrowWarning',
        defaultMessage: 'Смена начала линии невозможна, для связи есть определение',
    },
    edgeDefinitionEndArrowWarning: {
        id: 'CommonToolbar.edgeDefinitionEndArrowWarning',
        defaultMessage: 'Смена конца линии невозможна, для связи есть определение',
    },
    sequenceActionDisabled: {
        id: 'CommonToolbar.sequenceActionDisabled',
        defaultMessage: 'недоступно на данной диаграмме',
    },
    eraser: {
        id: 'CommonToolbar.eraser',
        defaultMessage: 'Очистить форматирование',
    },
    textAlign: {
        id: 'CommonToolbar.textAlign',
        defaultMessage: 'Выравнивание текста',
    },
    monospace: {
        id: 'CommonToolbar.monospace',
        defaultMessage: 'Моноширинный шрифт',
    },
    checkableList: {
        id: 'CommonToolbar.checkableList',
        defaultMessage: 'Чек-лист',
    },
    lineHeight: {
        id: 'CommonToolbar.lineHeight',
        defaultMessage: 'Междустрочный интервал',
    },
    moreInlineStyles: {
        id: 'CommonToolbar.moreInlineStyles',
        defaultMessage: 'Больше стилей текста',
    },
    startArrow: {
        id: 'CommonToolbar.startArrow',
        defaultMessage: 'Начало линии',
    },
    endArrow: {
        id: 'CommonToolbar.endArrow',
        defaultMessage: 'Конец линии',
    },
    lineType: {
        id: 'CommonToolbar.lineType',
        defaultMessage: 'Тип линии',
    },
    space: {
        id: 'CommonToolbar.space',
        defaultMessage: 'Пространство',
    },
    ToAlign: {
        id: 'CommonToolbar.ToAlign',
        defaultMessage: 'Выровнять',
    },
    toMove: {
        id: 'CommonToolbar.toMove',
        defaultMessage: 'Переместить',
    },
    selectElementInEditMode: {
        id: 'CommonToolbar.selectElementInEditMode',
        defaultMessage: 'Выберите элемент в режиме редактирования',
    },
    PickDefault: {
        id: 'CommonToolbar.PickDefault',
        defaultMessage: 'Выделить',
    },
    goTo: {
        id: 'CommonToolbar.goTo',
        defaultMessage: 'Перейти',
    },
    openFormatPanel: {
        id: 'CommonToolbar.openFormatPanel',
        defaultMessage: 'Открыть панель форматирования',
    },
    shapes: {
        id: 'CommonToolbar.shapes',
        defaultMessage: 'Фигуры',
    },
    NodeFilterOff: {
        id: 'CommonToolbar.NodeFilterOff',
        defaultMessage: 'Выключить',
    },
    NodeFilterSingle: {
        id: 'CommonToolbar.NodeFilterSingle',
        defaultMessage: 'Все экземпляры выбранного объекта',
    },
    NodeFilterMany: {
        id: 'CommonToolbar.NodeFilterMany',
        defaultMessage: 'Все символы совпадающего типа',
    },
    NodeFilterNotSame: {
        id: 'CommonToolbar.NodeFilterNotSame',
        defaultMessage: 'Только выбранный экземпляр',
    },
    showButtons: {
        id: 'CommonToolbar.showButtons',
        defaultMessage: 'Показать кнопки',
    },
    hideButtons: {
        id: 'CommonToolbar.hideButtons',
        defaultMessage: 'Скрыть кнопки',
    },
    undo: {
        id: 'CommonToolbar.undo',
        defaultMessage: 'Отменить',
    },
    redo: {
        id: 'CommonToolbar.redo',
        defaultMessage: 'Повторить',
    },
    decomposition: {
        id: 'CommonToolbar.decomposition',
        defaultMessage: 'Декомпозиция',
    },
    selectElementWithDecomposion: {
        id: 'CommonToolbar.selectElementWithDecomposion',
        defaultMessage: 'Выберите элемент с декомпозицией на холсте',
    },
});

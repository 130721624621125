import { SearchRequest } from '@/serverapi/api';
import {
    TSearchWidgetRequestDataAction,
    TSearchWidgetRequestDataSuccessAction,
    TSearchWidgetSetSearchDataAction,
    TSearchWidgetSetSearchRequestsAction,
} from './searchResultsWidget.actions.types';
import {
    SEARCH_WIDGET_REQUEST_DATA,
    SEARCH_WIDGET_REQUEST_DATA_SUCCESS,
    SEARCH_WIDGET_SET_SEARCH_DATA,
    SEARCH_WIDGET_SET_SEARCH_REQUESTS,
} from '../actionTypes/searchResultsWidget.actionTypes';
import { TSearchDataListItem } from '@/reducers/search.reducer.types';

export const searchWidgetSetSearchRequests = (
    templateId: string,
    widgetId: string,
    searchRequests: SearchRequest[],
): TSearchWidgetSetSearchRequestsAction => ({
    type: SEARCH_WIDGET_SET_SEARCH_REQUESTS,
    payload: {
        templateId,
        widgetId,
        searchRequests,
    },
});

export const searchWidgetSetSearchData = (
    templateId: string,
    widgetId: string,
    searchData: TSearchDataListItem[],
): TSearchWidgetSetSearchDataAction => ({
    type: SEARCH_WIDGET_SET_SEARCH_DATA,
    payload: {
        templateId,
        widgetId,
        searchData,
    },
});

export const searchWidgetSearchRequest = (
    templateId: string,
    widgetId: string,
    searchRequests: SearchRequest[],
): TSearchWidgetRequestDataAction => ({
    type: SEARCH_WIDGET_REQUEST_DATA,
    payload: {
        templateId,
        widgetId,
        searchRequests,
    },
});

export const searchWidgetSearchRequestSuccess = (
    templateId: string,
    widgetId: string,
): TSearchWidgetRequestDataSuccessAction => ({
    type: SEARCH_WIDGET_REQUEST_DATA_SUCCESS,
    payload: {
        templateId,
        widgetId,
    },
});

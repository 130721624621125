import React from 'react';
import icTxt from '../../../resources/icons/icTxt.svg';
import icPdf from '../../../resources/icons/icPdf.svg';
import IcFile from '../../../resources/icons/IcFile.svg';
import icJpg from '../../../resources/icons/icJpg.svg';
import icPng from '../../../resources/icons/icPng.svg';
import icDoc from '../../../resources/icons/icDoc.svg';
import icCsv from '../../../resources/icons/icCsv.svg';
import icXls from '../../../resources/icons/icXls.svg';
import icXml from '../../../resources/icons/icXml.svg';
import icMp4 from '../../../resources/icons/icMp4.svg';
import icZip from '../../../resources/icons/icZip.svg';
import icRar from '../../../resources/icons/icRar.svg';
import icGif from '../../../resources/icons/icGif.svg';
import theme from '../components/CommentCustomTextArea/CommentCustomTextArea.scss';
import { Icon } from '@/modules/UIKit';
import { getOrigin } from '@/utils/url.utils';
import { sortBy } from 'lodash-es';
import { Comment, CommentFileDTO } from '../../../serverapi/api';
import {
    APP_WORKSPACE_CONTAINER_ID,
    HEADER_CONTAINER_ID,
    NAVIGATOR_CONTAINER_ID,
    WORKSPACE_CONTAINER_ID,
} from '../../../mxgraph/components/BPMPopupMenu.types';
import { TTooltipContainerCoordinates } from '../components/Comments.types';

export const MAX_SIZE_IN_BYTES = 50 * 1024 * 1024;

export const getExt = (name: string) => {
    return name.match(/\.([^.]+)$|$/)?.[1];
};

export const getFileLink = (repositoryId: string, commentId: string, fileId: string) =>
    `${getOrigin()}/api/comment/${repositoryId}/${commentId}/file/${fileId}`;

export const renderIcon = (name: string): JSX.Element => {
    let spriteSymbol;

    switch (getExt(name)) {
        case 'pdf':
            spriteSymbol = icPdf;
            break;
        case 'txt':
            spriteSymbol = icTxt;
            break;
        case 'jpg':
            spriteSymbol = icJpg;
            break;
        case 'png':
            spriteSymbol = icPng;
            break;
        case 'doc':
            spriteSymbol = icDoc;
            break;
        case 'csv':
            spriteSymbol = icCsv;
            break;
        case 'xls':
            spriteSymbol = icXls;
            break;
        case 'xml':
            spriteSymbol = icXml;
            break;
        case 'mp4':
            spriteSymbol = icMp4;
            break;
        case 'zip':
            spriteSymbol = icZip;
            break;
        case 'rar':
            spriteSymbol = icRar;
            break;
        case 'gif':
            spriteSymbol = icGif;
            break;
        default:
            spriteSymbol = IcFile;
    }

    return <Icon className={theme.fileIconWrapper} spriteSymbol={spriteSymbol} />;
};

/**
 * сортирует комментарии по дате создания
 * undefined - ранняя дата - поздняя дата
 */
export const sortCommentsByCreatedDate = (comments: Comment[]) => {
    return sortBy(comments, (obj) => new Date(obj?.createdDate || 0));
};

export const getCoordinate = (
    graphSideLength: number,
    offset: number,
    containerSideLength: number,
    containerCoordinate: number,
) => {
    if (graphSideLength + offset < containerSideLength + containerCoordinate) {
        return graphSideLength + offset - containerSideLength;
    }

    return containerCoordinate < offset ? offset : containerCoordinate;
};

export const getTooltipContainerCoordinates = (
    graphContainer: HTMLElement,
    tooltipcontainer: HTMLDivElement,
): TTooltipContainerCoordinates => {
    const { clientHeight, clientWidth, offsetHeight } = graphContainer;
    const headerContainer: HTMLElement | null = document.getElementById(HEADER_CONTAINER_ID);
    const workSpace: HTMLElement | null = document.getElementById(WORKSPACE_CONTAINER_ID);
    const navigatorContainer: HTMLElement | null = document.getElementById(NAVIGATOR_CONTAINER_ID);
    const appWorkSpace: HTMLElement | null = document.getElementById(APP_WORKSPACE_CONTAINER_ID);
    const span: Element | null = document.querySelector(
        `#${APP_WORKSPACE_CONTAINER_ID} > .SplitPane.vertical > .Resizer.vertical`,
    );

    let offsetX: number = 50;
    if (span && navigatorContainer && appWorkSpace) {
        offsetX =
            navigatorContainer.clientWidth +
            ((appWorkSpace && parseInt(getComputedStyle(appWorkSpace).marginLeft, 10)) || 0) +
            (span as HTMLElement).offsetWidth;
    }

    let offsetY: number = 140;
    if (headerContainer && workSpace) {
        offsetY = headerContainer.clientHeight + (workSpace.clientHeight - offsetHeight);
    }
    const { top, left } = tooltipcontainer.getBoundingClientRect();

    return [
        getCoordinate(clientHeight, offsetY, tooltipcontainer.offsetHeight, top),
        getCoordinate(clientWidth, offsetX, tooltipcontainer.offsetWidth, left),
    ];
};

export const sortCommentFilesByName = (commentFiles: CommentFileDTO[]): CommentFileDTO[] => {
    return sortBy(commentFiles, (obj) => obj.name);
};

export const setServerIdToComment = (comment: Comment, serverId: string) => {
    if (!comment.commentId.serverId && serverId) comment.commentId.serverId = serverId;

    return comment;
};

import { Table } from 'antd';
import React, { FC, useState } from 'react';
import theme from '../PresetProfile.scss';
import { TabHeader } from '../../Header/TabHeader.component';
import { useIntl } from 'react-intl';
import messages from '../PresetProfile.messages';
import { useDispatch, useSelector } from 'react-redux';
import { AttributeType, PresetTypesAcl } from '../../../../../../../serverapi/api';
import { setAclRights, setAllAclRights } from '../../../../../../../actions/acl.actions';
import { AclSelectors } from '../../../../../../../selectors/acl.selectors';
import checkIcon from '../../../../../../../resources/icons/ic-acl-checked.svg';
import disabledIcon from '../../../../../../../resources/icons/ic-acl-disabled.svg';
import TableExpandIcon from '../../../../../TableExpandIcon/TableExpandIcon.component';
import { Icon } from '../../../../../../UIKit/components/Icon/Icon.component';
import { AttributeTypeSelectors } from '../../../../../../../selectors/attributeType.selectors';
import { getEntityWithAcl } from '../utils';
import { TAclRightTypes, TEntityPrincipal, TPresetProfileEntityTypes } from '../presetProfile.types';
import icAttribute from '../../../../../../../resources/icons/ic-attribute.svg';
import allowIcon from '../../../../../../../resources/icons/ic-checkbox-allow-color.svg';
import denyIcon from '../../../../../../../resources/icons/ic-checkbox-deny-color.svg';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { Locale } from '../../../../../../Header/components/Header/header.types';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TPresetProfileAttributesTypes = {
    serverId: string;
    presetId: string;
    profileId: string;
};

const PresetProfileAttributesTypes: FC<TPresetProfileAttributesTypes> = (props) => {
    const { serverId, presetId, profileId } = props;
    const type = TPresetProfileEntityTypes.attribute;
    const intl = useIntl();
    const attributeTypes: AttributeType[] = useSelector(AttributeTypeSelectors.allInPreset(serverId, presetId)) || [];
    const dispatch = useDispatch();
    const [searchFilter, setSearchFilter] = useState<string>('');
    const data = useSelector(AclSelectors.allOfTypeInPreset({ serverId, presetId, type, profileId }));
    const acls = data?.acls || [];
    const currentLocale: Locale = useSelector(getCurrentLocale);

    const dataSource = attributeTypes.reduce((groups: TEntityPrincipal[], entityType: AttributeType) => {
        // поиск
        if (entityType.name.toLowerCase().includes(searchFilter.toLowerCase())) {
            const groupIndex = groups.findIndex((group) => group.id === entityType.attributeTypeGroup?.id);
            if (groupIndex === -1) {
                if (entityType.attributeTypeGroup) {
                    const entityTypeAcl = getEntityWithAcl(acls || [], entityType, currentLocale);
                    const entityTypePrincipal = {
                        id: entityType.attributeTypeGroup.id,
                        name: LocalesService.internationalStringToString(
                            entityType.attributeTypeGroup.multilingualName,
                            currentLocale,
                        ),
                        children: [entityTypeAcl],
                    };
                    groups.push(entityTypePrincipal);
                }
            } else {
                const entityTypeAcl = getEntityWithAcl(acls || [], entityType, currentLocale);
                groups[groupIndex].children.push(entityTypeAcl);
            }
        }

        return groups;
    }, []);

    const renderAclFieldByType = (right: TAclRightTypes) => (acl: PresetTypesAcl, record: TEntityPrincipal) => {
        if (record.children) {
            // папка
            return '';
        }

        return (
            <Button
                visualStyle="text"
                onClick={() => {
                    dispatch(
                        setAclRights({
                            presetId,
                            serverId,
                            type,
                            ids: [acl.id],
                            rightKey: right,
                            rightValue: !acl[right],
                            profileId,
                        }),
                    );
                }}
            >
                <Icon spriteSymbol={acl[right] ? allowIcon : denyIcon} className={theme.checkboxIcon} dataTest={`access-${right}_input`} />
            </Button>
        );
    };

    const columns = [
        {
            title: intl.formatMessage(messages.name),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            render: (value: string, record: TEntityPrincipal) => (
                <>
                    {!record.children && (
                        <span className={theme.entityIcon}>
                            <Icon spriteSymbol={icAttribute} />
                        </span>
                    )}
                    <span title={value}>{value}</span>
                </>
            ),
        },
        {
            title: intl.formatMessage(messages.id),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
        },
        {
            title: intl.formatMessage(messages.create),
            dataIndex: 'acl',
            key: 'create',
            width: 110,
            render: renderAclFieldByType('create'),
        },
        {
            title: intl.formatMessage(messages.read),
            dataIndex: 'acl',
            key: 'read',
            width: 110,
            render: renderAclFieldByType('read'),
        },
        {
            title: intl.formatMessage(messages.update),
            dataIndex: 'acl',
            key: 'update',
            width: 120,
            render: renderAclFieldByType('update'),
        },
        {
            title: intl.formatMessage(messages.delete),
            dataIndex: 'acl',
            key: 'delete',
            width: 110,
            render: renderAclFieldByType('delete'),
        },
    ];

    const setAllAcl = (allow: boolean) => () => {
        dispatch(
            setAllAclRights({
                presetId,
                serverId,
                type,
                allow,
                entityTypeIds: attributeTypes.map((attr) => attr.id),
                profileId,
            }),
        );
    };

    return (
        <section className={theme.container}>
            <div className={theme.headerContainer}>
                <TabHeader onSearchChange={setSearchFilter} />
                <div className={theme.btns}>
                    <Button onClick={setAllAcl(true)}>
                        <Icon spriteSymbol={checkIcon} className={theme.buttonIcon} />{intl.formatMessage(messages.allowAll)}
                    </Button>
                    <Button onClick={setAllAcl(false)}>
                        <Icon spriteSymbol={disabledIcon} className={theme.buttonIcon} />{intl.formatMessage(messages.denyAll)}
                    </Button>
                </div>
            </div>
            <Table
                rowKey={(row: TEntityPrincipal) => row.id}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                expandRowByClick
                expandIcon={({ record, onExpand, expandable, expanded }) => (
                    <TableExpandIcon record={record} expandable={expandable} expanded={expanded} onExpand={onExpand} />
                )}
                className={theme.table}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
            />
        </section>
    );
};

export default PresetProfileAttributesTypes;

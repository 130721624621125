import { Table } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import messages from './CreateInvisibleEdgeDialog.messages';
import theme from './CreateInvisibleEdgeDialog.scss';
import Flag from '../../../resources/icons/ic-tree-e-epc-event.svg';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import { TColumnValue, TEdgeData, TTableRow } from './CreateInvisibleEdgeDialog.types';

type TEdgeTypesTable = {
    onSelect: (edgeData: TEdgeData) => void;
    selectedEdgeData: TEdgeData;
    tableData: TTableRow[];
};

const EdgeTypesTable: FC<TEdgeTypesTable> = (props) => {
    const { onSelect, selectedEdgeData, tableData } = props;
    const intl = useIntl();

    return (
        <div className={theme.tableWrapper}>
            <Table
                rowKey={(record: TTableRow) => `${record.fromObject.id}-${record.edgeType.id}`}
                onRow={(record: TTableRow) => ({
                    onClick: () => {
                        onSelect({
                            fromId: record.fromObject.id,
                            edgeTypeId: record.edgeType.id,
                            toId: record.toObject.id,
                        });
                    },
                })}
                dataSource={tableData}
                bordered
                pagination={false}
                size="middle"
            >
                <Table.Column
                    title={intl.formatMessage(messages.fromObject)}
                    dataIndex="fromObject"
                    width="33.33%"
                    key="fromObject"
                    render={(object: TColumnValue, record: TTableRow) => {
                        const rowEdgeData: TEdgeData = {
                            fromId: record.fromObject.id,
                            edgeTypeId: record.edgeType.id,
                            toId: record.toObject.id,
                        };
                        if (JSON.stringify(rowEdgeData) === JSON.stringify(selectedEdgeData)) {
                            return (
                                <div data-test="flag-on-the-object">
                                    <Icon className={theme.flag} spriteSymbol={Flag} />
                                    {object.name}
                                </div>
                            );
                        }

                        return object.name;
                    }}
                />
                <Table.Column
                    title={intl.formatMessage(messages.edgeType)}
                    dataIndex="edgeType"
                    width="33.33%"
                    key="edgeType"
                    render={(edgeType: TColumnValue) => edgeType.name}
                />
                <Table.Column
                    title={intl.formatMessage(messages.toObject)}
                    dataIndex="toObject"
                    width="33.33%"
                    key="toObject"
                    render={(object: TColumnValue) => object.name}
                />
            </Table>
        </div>
    );
};

export default EdgeTypesTable;

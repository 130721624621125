import { defineMessages } from 'react-intl';

export default defineMessages({
    folderFormName: {
        id: 'FolderDialog.folderFormName',
        defaultMessage: 'Новая папка',
    },
    folderNameLabel: {
        id: 'FolderDialog.folderFormLabel',
        defaultMessage: 'Имя папки',
    },
    folderTypeLabel: {
        id: 'FolderDialog.folderType',
        defaultMessage: 'Тип папки',
    },
    folderNamePlaceholder: {
        id: 'FolderDialog.folderFormPlaceholder',
        defaultMessage: 'Имя новой папки',
    },
    folderNameRequiredError: {
        id: 'FolderDialog.folderFormRequiredError',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    folderFormConfirmButton: {
        id: 'FolderDialog.folderFormConfirmButton',
        defaultMessage: 'Создать',
    },
    folderFormDeclineButton: {
        id: 'FolderDialog.folderFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    folderCreateError: {
        id: 'FolderDialog.folderCreateError',
        defaultMessage: 'Не удалось создать папку',
    },
    folderAccessDeniedError: {
        id: 'FolderDialog.folderAccessDeniedError',
        defaultMessage: 'Недостаточно прав доступа',
    },
    defaultFolderType: {
        id: 'FolderDialog.defaultFolderType',
        defaultMessage: 'Стандартная папка',
    }
});
